import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchSubscribeStatusThunk } from "@/redux/subscription/thunk";

export function SubscribeStatusUpdater() {
    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(fetchSubscribeStatusThunk({}));
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return null;
}
