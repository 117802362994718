import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "@/redux/types";
import { ListFetchSuccessPayload } from "./types";

export type GenericThunkAction = ThunkAction<void, RootState, unknown, AnyAction>;

export type GetListResponseFromRestApiClientFunction<ResponseType, Payload> = (
    functionArguments: {
        state: RootState;
        dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
        page: number;
    },
    payload: Payload,
) => Promise<AxiosResponse<ResponseType[]>>;

export function makeFetchMoreListThunkAction<ResponseType, Payload = undefined>(
    requestAction: ActionCreatorWithoutPayload<string>,
    successAction: ActionCreatorWithPayload<ListFetchSuccessPayload<ResponseType>, string>,
    failureAction: ActionCreatorWithPayload<Error, string>,
    {
        pageSelectFunction,
        getResponseListFunction,
    }: {
        pageSelectFunction: (rootState: RootState) => { page: number | null; totalPage: number | null };
        getResponseListFunction: GetListResponseFromRestApiClientFunction<ResponseType, Payload>;
    },
): (payload?: Payload) => GenericThunkAction {
    return (payload) => async (dispatch, getState) => {
        const rootState = getState();

        const { page, totalPage } = pageSelectFunction(rootState);
        const currentPage = page ?? 1;

        if (totalPage && totalPage + 1 < currentPage) {
            return;
        }

        dispatch(requestAction());

        try {
            const reqPromise = getResponseListFunction(
                {
                    state: getState(),
                    dispatch,
                    page: currentPage,
                },
                payload!,
            );

            const minimumDelayPromise = new Promise((resolve) => {
                setTimeout(resolve, 300);
            });

            await Promise.all([reqPromise, minimumDelayPromise]);

            const { data, headers } = await reqPromise;
            const totalCount: number = headers["x-total-count"];

            dispatch(
                successAction({
                    list: data,
                    totalPage: Math.ceil(totalCount / 20),
                    nextPage: currentPage + 1,
                }),
            );
        } catch (e: unknown) {
            if (e instanceof Error) {
                dispatch(failureAction(e));
                return;
            }
            throw e;
        }
    };
}

export type GetResponseFromRestApiClientFunction<ResponseType, Payload> = (
    functionArguments: {
        state: RootState;
        dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
    },
    payload: Payload,
) => Promise<AxiosResponse<ResponseType>>;

export function makeFetchThunkAction<ResponseType, Payload>(
    requestAction: ActionCreatorWithoutPayload<string>,
    successAction: ActionCreatorWithPayload<ResponseType, string>,
    failureAction: ActionCreatorWithPayload<Error, string>,
    {
        getResponseFunction,
    }: {
        getResponseFunction: GetResponseFromRestApiClientFunction<ResponseType, Payload>;
    },
): (payload: Payload) => GenericThunkAction {
    return (payload) => async (dispatch, getState) => {
        dispatch(requestAction());

        try {
            const reqPromise = await getResponseFunction(
                {
                    state: getState(),
                    dispatch,
                },
                payload,
            );

            const minimumDelayPromise = new Promise((resolve) => {
                setTimeout(resolve, 300);
            });

            await Promise.all([reqPromise, minimumDelayPromise]);

            const { data } = await reqPromise;

            dispatch(successAction(data));
        } catch (e: unknown) {
            if (e instanceof Error) {
                dispatch(failureAction(e));
                return;
            }
            throw e;
        }
    };
}
