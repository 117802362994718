import React from "react";
import styled from "@emotion/styled";
import { Button, Col, Form, Input, message, Modal, Row, Switch } from "antd";
import { ShopResponse } from "@ferda/rest-api-client";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import FormItem from "@/components/Admin/FormItem";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import KakaoMap from "@/components/Admin/KakaoMap";
import { Clear, Left, Right } from "@/components/Admin/Float";
import { TotalCountWrapper } from "@/components/Admin/Misc";

const ShopTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const ShopImagePlaceholder = styled.div`
    display: inline-block;
    width: 64px;
    height: 64px;
`;

interface Props {}

export default function Page(props: Props) {
    const history = useHistory();

    const { shopApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalShop, setModalShop] = React.useState<ShopResponse | undefined>();
    const [modalAddLat, setModalAddLat] = React.useState<number | undefined>();
    const [modalAddLong, setModalAddLong] = React.useState<number | undefined>();
    const [modalAddAddress, setModalAddAddress] = React.useState<string | undefined>();

    const [shopList, setShopList] = React.useState<ShopResponse[]>([]);
    const [shopListTotal, setShopListTotal] = React.useState<number>(0);

    const [keyword, setKeyword] = React.useState<string | undefined>();
    const [page, setPage] = React.useState<number>(1);

    const [searchForm] = Form.useForm();
    const [addForm] = Form.useForm();

    const columns = [
        {
            title: "사진",
            key: "image",
            render: (record: ShopResponse) => {
                return {
                    children: record.uploaded_image_sets[0] ? (
                        <img src={record.uploaded_image_sets[0].original_image_url} width={64} height={64} />
                    ) : (
                        <ShopImagePlaceholder />
                    ),
                };
            },
            width: "100px",
        },
        {
            title: "정보",
            key: "info",
            render: (record: ShopResponse) => {
                return {
                    children: (
                        <>
                            <ShopTitle>{record.name}</ShopTitle>
                            <p>
                                {record.address} {record.address_detail}
                            </p>
                        </>
                    ),
                };
            },
        },
        {
            title: "뉴 컵",
            key: "disposableCup",
            render: (record: ShopResponse) => {
                return {
                    children: (
                        <Switch
                            disabled={loading}
                            checked={record.disposable_cup_available}
                            onChange={(value) => {
                                setLoading(true);
                                shopApi
                                    .updateShop(record.id, { disposable_cup_available: value })
                                    .then(() => {
                                        setLoading(false);
                                        setRerender(!rerender);
                                        message.success("뉴 컵 선택 가능 여부를 수정하였습니다.", 1.5);
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        message.error(error.response.data.detail, 1.5);
                                    });
                            }}
                        />
                    ),
                };
            },
            width: "80px",
        },
        {
            title: "리턴 컵",
            key: "returnCup",
            render: (record: ShopResponse) => {
                return {
                    children: (
                        <Switch
                            disabled={loading}
                            checked={record.reusable_cup_available}
                            onChange={(value) => {
                                setLoading(true);
                                shopApi
                                    .updateShop(record.id, { reusable_cup_available: value })
                                    .then(() => {
                                        setLoading(false);
                                        setRerender(!rerender);
                                        message.success("리턴 컵 선택 가능 여부를 수정하였습니다.", 1.5);
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        message.error(error.response.data.detail, 1.5);
                                    });
                            }}
                        />
                    ),
                };
            },
            width: "80px",
        },
        {
            title: "휴업",
            key: "closure",
            render: (record: ShopResponse) => {
                return {
                    children: (
                        <Switch
                            disabled={loading}
                            checked={record.temporary_business_closure}
                            onChange={(value) => {
                                setLoading(true);
                                shopApi
                                    .updateShop(record.id, { temporary_business_closure: value })
                                    .then(() => {
                                        setLoading(false);
                                        setRerender(!rerender);
                                        message.success("휴업 여부를 수정하였습니다.", 1.5);
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        message.error(error.response.data.detail, 1.5);
                                    });
                            }}
                        />
                    ),
                };
            },
            width: "80px",
        },
        {
            title: "관리",
            key: "manage",
            render: (record: ShopResponse) => {
                return {
                    children: (
                        <>
                            <Button
                                disabled={loading}
                                onClick={() => history.push(`/admin/shops/edit/${record.id}`)}
                                style={{ marginRight: "0.5rem" }}
                            >
                                수정
                            </Button>
                            <Button
                                danger
                                disabled={loading}
                                onClick={() => {
                                    setModal("delete");
                                    setModalShop(record);
                                }}
                            >
                                삭제
                            </Button>
                        </>
                    ),
                };
            },
            width: "160px",
        },
    ];

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getShops(undefined, undefined, keyword, undefined, undefined, page)
            .then((res) => {
                setLoading(false);
                setShopList(res.data);
                setShopListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("가맹점 목록을 불러오는데 실패하였습니다.");
            });
    }, [keyword, page, rerender]);

    return (
        <>
            <PageTitle>가맹점 관리</PageTitle>
            <Container>
                <Form
                    form={searchForm}
                    onFinish={(values) => {
                        const keyword = values.keyword || undefined;

                        setKeyword(keyword);
                        setPage(1);
                    }}
                >
                    <Left>
                        <Row>
                            <Col>
                                <Form.Item name="keyword">
                                    <Input disabled={loading} placeholder="검색어" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Left>
                    <Right>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        htmlType="reset"
                                        onClick={() => {
                                            searchForm.resetFields();

                                            setKeyword(undefined);
                                            setPage(1);
                                        }}
                                    >
                                        초기화
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "1.5rem" }}>
                                <Form.Item>
                                    <Button disabled={loading} htmlType="submit">
                                        검색
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button disabled={loading} htmlType="submit" onClick={() => setModal("add")}>
                                        추가
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Right>
                    <Clear />
                </Form>
            </Container>
            <Container>
                <TotalCountWrapper>가맹점 수 : {shopListTotal.toLocaleString("ko")}</TotalCountWrapper>
                <Table columns={columns} dataSource={shopList} loading={loading} rowKey="id" />
                {shopListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={shopListTotal}
                    />
                )}
            </Container>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    htmlType: "submit",
                    form: "addForm",
                }}
                okText="추가"
                onCancel={() => setModal(undefined)}
                title="가맹점 추가"
                visible={modal === "add"}
                width={800}
            >
                <Form
                    form={addForm}
                    name="addForm"
                    onFinish={(values) => {
                        if (!values.name) {
                            return message.error("가맹점 이름을 입력해주세요.", 1.5);
                        }
                        if (modalAddLat === undefined || modalAddLong === undefined || !modalAddAddress) {
                            return message.error("지도에서 주소를 선택해주세요.", 1.5);
                        }

                        setLoading(true);
                        shopApi
                            .createShop({
                                name: values.name,
                                latitude: modalAddLat,
                                longitude: modalAddLong,
                                address: modalAddAddress,
                                address_detail: values.addressDetail,
                            })
                            .then(() => {
                                setModal(undefined);
                                setModalAddLat(undefined);
                                setModalAddLong(undefined);
                                setModalAddAddress(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("추가하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="이름" name="name">
                        <Input disabled={loading} placeholder="이름" />
                    </FormItem>
                    <FormItem label="위치">
                        <KakaoMap
                            mapId="add-map"
                            latLong={
                                modalAddLat !== undefined && modalAddLong !== undefined
                                    ? {
                                          lat: modalAddLat,
                                          long: modalAddLong,
                                      }
                                    : undefined
                            }
                            onPlaceSelect={(place) => {
                                setModalAddLat(place.lat);
                                setModalAddLong(place.long);
                                setModalAddAddress(place.roadAddressName);
                            }}
                        />
                    </FormItem>
                    <FormItem label="위도 / 경도">
                        <div style={{ display: "flex" }}>
                            <Input disabled={loading} readOnly value={modalAddLat} style={{ marginRight: "0.25rem" }} />
                            <Input disabled={loading} readOnly value={modalAddLong} style={{ marginLeft: "0.25rem" }} />
                        </div>
                    </FormItem>
                    <FormItem label="주소">
                        <Input disabled={loading} placeholder="주소" readOnly value={modalAddAddress} />
                    </FormItem>
                    <FormItem hints={["선택항목"]} label="상세 주소" name="addressDetail">
                        <Input disabled={loading} placeholder="상세 주소" />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{ danger: true }}
                okText="삭제"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!modalShop) {
                        return message.error("가맹점을 선택해주세요.", 1.5);
                    }

                    setLoading(true);
                    shopApi
                        .deleteShop(modalShop.id)
                        .then(() => {
                            setModal(undefined);
                            setModalShop(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("삭제하였습니다.", 1.5);
                        })
                        .catch(() => {
                            setLoading(false);
                            message.error("삭제를 실패하였습니다", 1.5);
                        });
                }}
                title="가맹점 삭제"
                visible={modal === "delete"}
                width={800}
            >
                가맹점을 삭제하시겠습니까?
            </Modal>
        </>
    );
}
