import React from "react";
import { PostResponse } from "@ferda/rest-api-client";
import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";
import { format } from "date-fns";
import Link from "@/components/CustomLink";
import Divider from "@/components/Divider";

const NoticeButtonContainer = styled(ButtonBase)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 24px;
    padding-left: 20px;
    padding-bottom: 24px;
    padding-right: 20px;

    text-align: left;

    color: black;
`;

const Title = styled.div`
    color: white;
    font-size: 14px;
    color: #0c0c0c;
`;

const DateContainer = styled.div`
    margin-top: 5px;

    color: #5b5b5b;
    font-size: 12px;
`;

const StyledDivider = styled(Divider)`
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 0;
    margin-right: 20px;
    margin-bottom: 0;
`;

interface NoticeButtonProps {
    post: PostResponse;
}

export function NoticeButton({ post: { path, id, title, created_datetime } }: NoticeButtonProps) {
    return (
        <>
            <Link to={path || `/notice/${id}`}>
                <NoticeButtonContainer>
                    <Title>{title}</Title>
                    <DateContainer>{format(new Date(created_datetime), "yyyy.MM.dd HH:mm")}</DateContainer>
                </NoticeButtonContainer>
            </Link>
            <StyledDivider />
        </>
    );
}
