import axios from "axios";
import { GenericThunkAction, makeFetchMoreListThunkAction } from "@/utils/redux/thunk";
import { openErrorSnackbar } from "../snackbar/actions";
import {
    giftDetailInfoRequestFetchFailure,
    giftDetailInfoRequestFetchRequest,
    giftDetailInfoRequestFetchSuccess,
    giftLogListFetchFailure,
    giftLogListFetchRequest,
    giftLogListFetchSuccess,
    giftOrderFetchFailure,
    giftOrderFetchRequest,
    giftOrderFetchSuccess,
    giftSubscribeDetailInfoRequestFetchFailure,
    giftSubscribeDetailInfoRequestFetchRequest,
    giftSubscribeDetailInfoRequestFetchSuccess,
    giftSubscribeLogListFetchFailure,
    giftSubscribeLogListFetchRequest,
    giftSubscribeLogListFetchSuccess,
    giftSubscribeOrderFetchFailure,
    giftSubscribeOrderFetchRequest,
    giftSubscribeOrderFetchSuccess,
} from "./actions";
import { enqueSnackbarType } from "@/components/Snackbar/type";

interface GiftLogListRequestThunkActionParam {
    includeSentGifts?: boolean;
    includeReceivedGifts?: boolean;
    minCreatedDate?: string;
    maxCreatedDate?: string;
}

let emptyValue: undefined;

export const giftLogListRequestThunk = makeFetchMoreListThunkAction(
    giftLogListFetchRequest,
    giftLogListFetchSuccess,
    giftLogListFetchFailure,
    {
        getResponseListFunction: (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                    },
                },
                page,
            },
            {
                includeSentGifts,
                includeReceivedGifts,
                minCreatedDate,
                maxCreatedDate,
            }: GiftLogListRequestThunkActionParam,
        ) =>
            shopApi.getGifts(
                includeSentGifts,
                includeReceivedGifts,
                emptyValue,
                emptyValue,
                minCreatedDate,
                maxCreatedDate,
                page,
                "me",
            ),
        pageSelectFunction: ({ giftLog: { giftLogListPage: page, giftLogListTotalPage: totalPage } }) => ({
            page,
            totalPage,
        }),
    },
);

export const giftSubscribeLogListRequestThunk = makeFetchMoreListThunkAction(
    giftSubscribeLogListFetchRequest,
    giftSubscribeLogListFetchSuccess,
    giftSubscribeLogListFetchFailure,
    {
        getResponseListFunction: (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                    },
                },
                page,
            },
            {
                includeSentGifts,
                includeReceivedGifts,
                minCreatedDate,
                maxCreatedDate,
            }: GiftLogListRequestThunkActionParam,
        ) =>
            shopApi.getSubscriptionGifts(
                includeSentGifts,
                includeReceivedGifts,
                emptyValue,
                minCreatedDate,
                maxCreatedDate,
                page,
                "me",
            ),
        pageSelectFunction: ({
            giftLog: { giftSubscribeLogListPage: page, giftSubscribeLogListTotalPage: totalPage },
        }) => ({
            page,
            totalPage,
        }),
    },
);

export function giftLogDetailRequestThunk(id: number): GenericThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { shopApi },
            },
        } = getState();

        dispatch(giftDetailInfoRequestFetchRequest());

        let res;

        try {
            res = await shopApi.getGift(id);
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                dispatch(giftDetailInfoRequestFetchFailure(e));
                if (e.response) {
                    dispatch(openErrorSnackbar(e.response.data.detail));
                }
            }
            throw e;
        }

        dispatch(giftDetailInfoRequestFetchSuccess(res.data));
    };
}

export function giftSubscribeLogDetailRequestThunk(id: number): GenericThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { shopApi },
            },
        } = getState();

        dispatch(giftSubscribeDetailInfoRequestFetchRequest());

        let res;

        try {
            res = await shopApi.getSubscriptionGift(id);
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                dispatch(giftSubscribeDetailInfoRequestFetchFailure(e));
                if (e.response) {
                    dispatch(openErrorSnackbar(e.response.data.detail));
                }
            }
            throw e;
        }

        dispatch(giftSubscribeDetailInfoRequestFetchSuccess(res.data));
    };
}

export function giftOrderRequestThunk(giftId: number, shopId: number): GenericThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { shopApi },
            },
        } = getState();

        dispatch(giftOrderFetchRequest());

        try {
            await shopApi.giftOrder(giftId, { shop_id: shopId });
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                dispatch(giftOrderFetchFailure(e));
                if (e.response) {
                    dispatch(openErrorSnackbar(e.response.data.detail));
                }
            }
            throw e;
        }

        dispatch(giftOrderFetchSuccess());
    };
}

export function giftSubscribeOrderRequestThunk(giftId: number, enqueSnackbar: enqueSnackbarType): GenericThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { shopApi },
            },
        } = getState();

        dispatch(giftSubscribeOrderFetchRequest());

        try {
            await shopApi.useSubscriptionGift(giftId);
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                dispatch(giftSubscribeOrderFetchFailure(e));
                if (e.response) {
                    enqueSnackbar(e.response.data.detail, {
                        variant: "error",
                    });
                }
            }
            throw e;
        }

        dispatch(giftSubscribeOrderFetchSuccess());
    };
}
