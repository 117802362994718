import {
    addPaymentMethodFetchRequest,
    addPaymentMethodFetchSuccess,
    addPaymentMethodFetchFailure,
    paymentMethodFetchRequest,
    paymentMethodFetchSuccess,
    paymentMethodFetchFailure,
    deletePaymentMethodFetchRequest,
    deletePaymentMethodFetchSuccess,
    deletePaymentMethodFetchFailure,
} from "./actions";
import { makeFetchThunkAction } from "@/utils/redux/thunk";
import { requestPayment } from "./functions";

interface FetchAddPaymentMethodThunkProps {
    name: string;
}

export const fetchAddPaymentMethodThunk = makeFetchThunkAction(
    addPaymentMethodFetchRequest,
    addPaymentMethodFetchSuccess,
    addPaymentMethodFetchFailure,
    {
        getResponseFunction: async (
            {
                state: {
                    auth: {
                        restApiClient: { userApi },
                        user,
                    },
                },
            },
            { name }: FetchAddPaymentMethodThunkProps,
        ) => {
            const customerUid = `${user!.id}-${Date.now()}`;
            const res = await requestPayment({
                user: user!,
                price: 0,
                productName: "결제수단",
                customerUid,
                type: "SUBSCRIPTION",
            });

            if (!res) {
                throw new Error();
            }

            return userApi.setBillingKey("me", { name, customer_uid: customerUid });
        },
    },
);

export const fetchPaymentMethodThunk = makeFetchThunkAction(
    paymentMethodFetchRequest,
    paymentMethodFetchSuccess,
    paymentMethodFetchFailure,
    {
        getResponseFunction: async ({
            state: {
                auth: {
                    restApiClient: { userApi },
                },
            },
        }) => userApi.getBillingKey("me"),
    },
);

export const deletePaymentMethodThunk = makeFetchThunkAction(
    deletePaymentMethodFetchRequest,
    deletePaymentMethodFetchSuccess,
    deletePaymentMethodFetchFailure,
    {
        getResponseFunction: async ({
            state: {
                auth: {
                    restApiClient: { userApi },
                },
            },
        }) => userApi.deleteBillingKey("me"),
    },
);
