import styled from "@emotion/styled";
import { PostResponse } from "@ferda/rest-api-client";
import { isAfter } from "date-fns";
import React from "react";
import Link from "@/components/CustomLink";
import EndCircle from "@/components/EndCircle";
import PlaceHolderImage from "@/assets/img/place_holder.png";
import { getDateRangeString } from "@/utils/time";

const EventCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    text-align: left;
    color: white;

    border-radius: 8px;
`;

const Title = styled.div`
    margin-top: 8px;

    color: #0c0c0c;
    font-size: 18px;
    font-weight: bold;
`;

const DateContainer = styled.div`
    margin-top: 4px;

    font-size: 12px;
    color: #5b5b5b;
`;

const BannerImgContainer = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 50%;
`;

const BannerImg = styled.img`
    position: absolute;
    left: 0;
    top: 0;

    border-radius: 8px;
    width: 100%;
    height: 100%;
`;

interface EventCardProps {
    post: PostResponse;
    isMain?: boolean;
}

export default function EventCard({
    post: { id, title, path, created_datetime, post_start_date, post_end_date, small_thumbnail_set, title_hidden },
    isMain,
}: EventCardProps) {
    return (
        <Link to={path || `/event/${id}`} style={{ textDecoration: "none" }}>
            <EventCardContainer>
                <BannerImgContainer>
                    <BannerImg src={small_thumbnail_set?.original_image_url ?? PlaceHolderImage} />
                    {post_end_date && isAfter(new Date(), new Date(post_end_date)) && <EndCircle>종료</EndCircle>}
                </BannerImgContainer>
                {!(isMain && title_hidden) && <Title>{title}</Title>}
                <DateContainer>{getDateRangeString(created_datetime, post_start_date, post_end_date)}</DateContainer>
            </EventCardContainer>
        </Link>
    );
}
