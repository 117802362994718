import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const StyledPaper = styled(Paper)`
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: none;
`;

export const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 100%;
`;

export const BannerImage = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const HomeBannerCardTitleContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;

    padding-left: 16px;
    padding-bottom: 16px;

    font-size: 24px;
    font-weight: bold;
    color: white;
`;

export const HomeBannerDescriptionContainer = styled.div`
    padding: 16px;

    color: #0c0c0c;
    font-size: 12px;
`;
