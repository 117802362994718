import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import useHistory from "@/hooks/useCustomHistory";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import {
    AlertModalText,
    CoffeeGiftBackgroundImage,
    CoffeeGiftImage,
    CoffeeGiftImageWrapper,
    CoffeeGiftStampImage,
    CoffeeGiftWrapper,
    CompleteModalHighlight,
    GiftDateText,
    GiftDateTitle,
    GiftDateWrapper,
    GiftInfoDivider,
    GiftInfoSubtitle,
    GiftInfoTitle,
    GiftInfoWrapper,
    GiftOrderInfoNumber,
    GiftOrderInfoStatusText,
    GiftOrderInfoTextDivider,
    GiftOrderInfoTitle,
    GiftOrderInfoWrapper,
    StyledFindStoreInput,
    TopImageWrapper,
    TopNameText,
    TopWrapper,
    Wrapper,
} from "./style";
import GiftBoxIcon from "@/assets/img/img_giftbox.svg";
import PlaceHolderImage from "@/assets/img/place_holder.png";
import CoffeeBackgroundImage from "@/assets/img/img_gift_background.png";
import Modal from "@/components/Modal";
import ConfirmIcon from "@/assets/img/img_confirm_modal_check.svg";
import StampIcon from "@/assets/img/img_stamp.png";
import { getGiftLogDetailInfo, getGiftOrderFetchState } from "@/redux/giftLog/selectors";
import { giftLogDetailRequestThunk, giftOrderRequestThunk } from "@/redux/giftLog/thunks";
import { reset } from "@/redux/giftLog/actions";
import { BottomConfirmButtonContainer, ConfirmButton } from "@/components/Button/style";

export default function GiftGetDetail() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { giftId } = useParams<{ giftId: string }>();
    const [store, setStore] = useState<string>("");
    const [storeId, setStoreId] = useState<number | null>(null);
    const [alertModal, setAlertModal] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [completeModal, setCompleteModal] = useState<boolean>(false);
    const detailInfo = useSelector(getGiftLogDetailInfo);

    const giftOrderFetchState = useSelector(getGiftOrderFetchState);

    const handleChangeStore = (id: number, name: string) => {
        setStoreId(id);
        setStore(name);
    };
    const handleClickUseButton = () => {
        if (!storeId || !giftId) {
            return;
        }

        dispatch(giftOrderRequestThunk(Number(giftId), storeId));
    };

    const handleClickOrder = () => {
        if (!store || !storeId) {
            setAlertModal(true);
            return;
        }

        setConfirmModal(true);
    };

    useEffect(() => {
        if (!giftId) {
            return;
        }
        dispatch(giftLogDetailRequestThunk(Number(giftId)));

        return () => {
            dispatch(reset());
        };
    }, []);

    useEffect(() => {
        if (giftOrderFetchState === "SUCCESS") {
            setConfirmModal(false);
            setCompleteModal(true);
        }
    }, [giftOrderFetchState]);

    return (
        <AppLayout
            disableAppBarBorder={true}
            title="받은 선물"
            titleColor="#fff"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            fullHeight
        >
            <Modal
                open={alertModal}
                handleClose={() => setAlertModal(false)}
                buttonOnClick={() => setAlertModal(false)}
                titleText="주문할 매장을 선택해주세요"
                buttonText="확인"
                fullSizeButton
            >
                <AlertModalText>매장을 먼저 선택하신 후 주문해주세요</AlertModalText>
            </Modal>
            <Modal
                open={confirmModal}
                handleClose={() => setConfirmModal(false)}
                buttonOnClick={handleClickUseButton}
                cancelButtonOnClick={() => setConfirmModal(false)}
                titleText="선물을 사용하시겠어요?"
                buttonText="사용하기"
                cancelButtonText="취소"
                fullSizeButton
            >
                <AlertModalText>
                    사용한 선물을 되돌릴 수 없으며,
                    <br />
                    사용 즉시 해당 매장으로 주문됩니다.
                    <br />
                    정말 사용하시겠습니까?
                </AlertModalText>
            </Modal>
            <Modal
                open={completeModal}
                handleClose={() => setConfirmModal(false)}
                buttonOnClick={() => history.push("/history")}
                cancelButtonOnClick={() => history.push("/")}
                titleText="주문이 완료되었어요"
                buttonText="주문내역 확인"
                cancelButtonText="홈으로 가기"
                topImage={ConfirmIcon}
            >
                <AlertModalText>
                    진행단계는 <CompleteModalHighlight>마이페이지 &gt; 히스토리</CompleteModalHighlight>를<br /> 통하여
                    확인 가능합니다.
                </AlertModalText>
            </Modal>
            {detailInfo && (
                <Wrapper used={Boolean(detailInfo.used_datetime)}>
                    <TopWrapper>
                        <TopImageWrapper>
                            <img src={GiftBoxIcon} alt="gift box icon" />
                        </TopImageWrapper>
                        <TopNameText>{detailInfo.gifted_user.detail?.name || "-"}님의 선물</TopNameText>
                    </TopWrapper>

                    <CoffeeGiftWrapper>
                        <CoffeeGiftBackgroundImage src={CoffeeBackgroundImage} alt="background" />
                        <CoffeeGiftImageWrapper>
                            {detailInfo.used_datetime && <CoffeeGiftStampImage src={StampIcon} alt="stamp" />}
                            <CoffeeGiftImage
                                used={Boolean(detailInfo.used_datetime)}
                                src={detailInfo.menu.uploaded_image_set?.original_image_url ?? PlaceHolderImage}
                                alt="coffee"
                            />
                        </CoffeeGiftImageWrapper>
                    </CoffeeGiftWrapper>

                    <GiftInfoWrapper>
                        <GiftInfoTitle>{detailInfo.menu.name}</GiftInfoTitle>
                        <GiftInfoSubtitle>
                            {detailInfo.amount}잔{detailInfo.temperature_type && ` / ${detailInfo.temperature_type}`}
                        </GiftInfoSubtitle>

                        <GiftOrderInfoWrapper>
                            <GiftOrderInfoTitle>주문번호</GiftOrderInfoTitle>
                            <GiftOrderInfoNumber>{detailInfo.id}</GiftOrderInfoNumber>
                            <GiftOrderInfoTextDivider />
                            <GiftOrderInfoStatusText>
                                {detailInfo.used_datetime ? "사용완료" : "미사용"}
                            </GiftOrderInfoStatusText>
                        </GiftOrderInfoWrapper>
                        <GiftInfoDivider />

                        <GiftDateWrapper>
                            <GiftDateTitle>선물주문일</GiftDateTitle>
                            <GiftDateText>
                                {format(new Date(detailInfo.created_datetime), "yyyy년 MM월 dd일")}
                            </GiftDateText>
                        </GiftDateWrapper>
                        <GiftInfoDivider />

                        {/* <GiftDateWrapper>
                            <GiftDateTitle>유효기간</GiftDateTitle>
                            <GiftDateText>2021년 11월 26일</GiftDateText>
                        </GiftDateWrapper>
                        <GiftInfoDivider /> */}

                        {detailInfo.used_datetime && (
                            <>
                                <GiftDateWrapper>
                                    <GiftDateTitle>이용날짜</GiftDateTitle>
                                    <GiftDateText>
                                        {format(new Date(detailInfo.used_datetime), "yyyy년 MM월 dd일")}
                                    </GiftDateText>
                                </GiftDateWrapper>
                                <GiftInfoDivider />

                                <GiftDateWrapper>
                                    <GiftDateTitle>이용매장</GiftDateTitle>
                                    <GiftDateText>{detailInfo.orders[0].shop.name}</GiftDateText>
                                </GiftDateWrapper>
                                <GiftInfoDivider />
                            </>
                        )}
                        {!detailInfo.used_datetime && (
                            <StyledFindStoreInput
                                value={store}
                                handleValue={handleChangeStore}
                                placeholder="주문할 매장을 선택해주세요"
                                inputBackgroundColor="#2d2d2d"
                                selectedMenuId={Number(giftId)}
                            />
                        )}
                    </GiftInfoWrapper>

                    {!detailInfo.used_datetime && (
                        <BottomConfirmButtonContainer>
                            <ConfirmButton variant="contained" backgroundColor="#ff831e" onClick={handleClickOrder}>
                                주문하기
                            </ConfirmButton>
                        </BottomConfirmButtonContainer>
                    )}
                </Wrapper>
            )}
        </AppLayout>
    );
}
