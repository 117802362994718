import styled from "@emotion/styled";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Home from "../Home";
import My from "../My";
import OrderAppBar from "@/components/OrderAppBar";
import AppLayout from "@/containers/AppLayout";
import { getMainTab } from "@/redux/main/selectors";
import { Tab } from "@/components/Tab";
import { setMainTab } from "@/redux/main/actions";
import { MainTabType } from "@/redux/main/types";

const StyledTab = styled(Tab)`
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
`;

const StyledAppLayout = styled(AppLayout)`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    background: #f7f7f7;
`;

export default function Main() {
    const mainTab = useSelector(getMainTab);
    const dispatch = useDispatch();

    const setTab = useCallback(
        (newTab: string) => {
            dispatch(setMainTab(newTab as MainTabType));
        },
        [dispatch],
    );
    return (
        <StyledAppLayout
            appBar={<OrderAppBar />}
            disableAppBarBorder
            backgroundColor={mainTab === "HOME" ? "black" : "#5b5b5b"}
            showBottomAppBar
        >
            <StyledTab tab={mainTab} onTabChange={setTab} tabOneName="HOME" tabTwoName="MY" />
            {mainTab === "HOME" ? <Home /> : <My />}
        </StyledAppLayout>
    );
}
