import React from "react";
import { CupType, ShopResponse } from "@ferda/rest-api-client";
// import DisposableCupDisabledImage from "@/assets/img/ic_disposable_n.svg";
// import DisposableCupSelectedImage from "@/assets/img/ic_disposable_s.svg";
import OwnCupDisabledImage from "@/assets/img/ic_tumbler_n.svg";
import OwnCupSelectedImage from "@/assets/img/ic_tumbler_s.svg";
import ReusableDisabledImage from "@/assets/img/ic_reusable_n.svg";
import ReusableSelectedImage from "@/assets/img/ic_reusable_s.svg";
import { CupTypeButtonContainer } from "./style";
import { CupTypeButton } from "./CupTypeButton";
import { formatCupType } from "../util";

interface CupTypeSettingProps {
    value?: CupType;
    onChange: (newCupType?: CupType) => void;
    selectedShop: ShopResponse | null;
}

export default function CupTypeSetting({ value, onChange, selectedShop }: CupTypeSettingProps) {
    return (
        <CupTypeButtonContainer>
            <CupTypeButton
                selected={value === "REUSABLE"}
                title={formatCupType(CupType.REUSABLE) + "컵"}
                value={CupType.REUSABLE}
                selectedImgSrc={ReusableSelectedImage}
                unselectedImgSrc={ReusableDisabledImage}
                onChange={onChange}
                disabled={selectedShop?.reusable_cup_available === false}
            />
            {/* <CupTypeButton
                selected={value === "DISPOSABLE"}
                title={formatCupType(CupType.DISPOSABLE) + "컵"}
                value={CupType.DISPOSABLE}
                selectedImgSrc={DisposableCupSelectedImage}
                unselectedImgSrc={DisposableCupDisabledImage}
                onChange={onChange}
                disabled={selectedShop?.disposable_cup_available === false}
            /> */}
            <CupTypeButton
                selected={value === "INDIVIDUAL"}
                title={formatCupType(CupType.INDIVIDUAL) + "컵"}
                value={CupType.INDIVIDUAL}
                selectedImgSrc={OwnCupSelectedImage}
                unselectedImgSrc={OwnCupDisabledImage}
                onChange={onChange}
            />
        </CupTypeButtonContainer>
    );
}
