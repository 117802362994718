import axios from "axios";
import { UserType } from "@ferda/rest-api-client";
import { saveOauth2TokenToLocalStorage } from "@/redux/auth/utils";
import { GenericThunkAction } from "@/utils/redux/thunk";
import {
    posLoginFetchFailure,
    posLoginFetchRequest,
    posLoginFetchSuccess,
    posGetShopFetchRequest,
    posGetShopFetchFailure,
    posGetShopFetchSuccess,
} from "./actions";
import { loadAuthStateAndApiClientThunk } from "@/redux/auth/thunk";

let emptyValue: undefined;

export function posLoginRequestThunk(username: string, password: string): GenericThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { oauth2Api },
            },
        } = getState();

        dispatch(posLoginFetchRequest());

        try {
            const { data } = await oauth2Api.generateToken(username, password);

            if (data.scopes.find((scope) => scope === UserType.SHOP_OWNER)) {
                saveOauth2TokenToLocalStorage({
                    token: data.access_token,
                    tokenExpireDate: new Date(data.access_token_expire_datetime),
                    refreshToken: data.refresh_token,
                    refreshTokenExpireDate: new Date(data.refresh_token_expire_datetime),
                });

                dispatch(loadAuthStateAndApiClientThunk(() => dispatch(posLoginFetchSuccess("AUTHORIZED"))));
                return;
            }

            dispatch(posLoginFetchSuccess("UNAUTHORIZED"));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                dispatch(posLoginFetchFailure(e));
                return;
            }
            throw e;
        }
    };
}

export function posGetShopRequestThunk(): GenericThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { userApi, shopApi },
            },
            pos: { posLoginUser },
        } = getState();

        dispatch(posGetShopFetchRequest());

        let userData = posLoginUser;
        let shopData;

        if (!userData) {
            try {
                const { data } = await userApi.getUser("me");
                userData = data;
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    dispatch(posGetShopFetchFailure(e));
                    return;
                }
                throw e;
            }
        }

        try {
            shopData = await shopApi.getShops(emptyValue, userData.id);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                dispatch(posGetShopFetchFailure(e));
                return;
            }
            throw e;
        }

        dispatch(
            posGetShopFetchSuccess({
                shopData: shopData.data,
                userData,
            }),
        );
    };
}
