import { PostResponse, PostType } from "@ferda/rest-api-client";
import {
    noticePostFetchRequest,
    noticePostFetchSuccess,
    noticePostFetchFailure,
    noticePostListFetchRequest,
    noticePostListFetchSuccess,
    noticePostListFetchFailure,
    eventContentPostFetchRequest,
    eventContentPostFetchSuccess,
    eventContentPostFetchFailure,
    eventContentPostListFetchRequest,
    eventContentPostListFetchSuccess,
    eventContentPostListFetchFailure,
    unexpiredEventContentPostListFetchFailure,
    unexpiredEventContentPostListFetchRequest,
    unexpiredEventContentPostListFetchSuccess,
} from "./actions";
import {
    GetResponseFromRestApiClientFunction,
    makeFetchMoreListThunkAction,
    makeFetchThunkAction,
} from "@/utils/redux/thunk";
import { RootState } from "../types";

interface MakeFetchMorePostListThunkActionExtraArgs {
    includeExpired?: boolean;
    includeAlive?: boolean;
    postTypeList: PostType[];
}

const getPostListResponseFunction =
    ({ includeAlive, includeExpired, postTypeList }: MakeFetchMorePostListThunkActionExtraArgs) =>
    ({
        state: {
            auth: {
                restApiClient: { postApi },
            },
        },
        page,
    }: {
        state: RootState;
        page: number;
    }) => {
        return postApi.getPosts(includeAlive, includeExpired, postTypeList, false, page);
    };

const getPostResponseFunction: GetResponseFromRestApiClientFunction<PostResponse, number> = (
    {
        state: {
            auth: {
                restApiClient: { postApi },
            },
        },
    },
    payload,
) => postApi.getPost(payload);

export const fetchNoticePostThunk = makeFetchThunkAction(
    noticePostFetchRequest,
    noticePostFetchSuccess,
    noticePostFetchFailure,
    { getResponseFunction: getPostResponseFunction },
);

export const fetchMoreNoticePostListThunk = makeFetchMoreListThunkAction(
    noticePostListFetchRequest,
    noticePostListFetchSuccess,
    noticePostListFetchFailure,
    {
        getResponseListFunction: getPostListResponseFunction({ postTypeList: [PostType.NOTICE] }),
        pageSelectFunction: ({ post: { noticePostListPage: page, noticePostListTotalPage: totalPage } }) => ({
            page,
            totalPage,
        }),
    },
);

export const fetchEventContentPostThunk = makeFetchThunkAction(
    eventContentPostFetchRequest,
    eventContentPostFetchSuccess,
    eventContentPostFetchFailure,
    { getResponseFunction: getPostResponseFunction },
);

export const fetchMoreEventContentPostListThunk = makeFetchMoreListThunkAction(
    eventContentPostListFetchRequest,
    eventContentPostListFetchSuccess,
    eventContentPostListFetchFailure,
    {
        getResponseListFunction: getPostListResponseFunction({ postTypeList: [PostType.EVENT, PostType.CONTENT] }),
        pageSelectFunction: ({
            post: { eventContentPostListPage: page, eventContentPostListTotalPage: totalPage },
        }) => ({ page, totalPage }),
    },
);

export const fetchMoreUnexpiredEventContentPostListThunk = makeFetchMoreListThunkAction(
    unexpiredEventContentPostListFetchRequest,
    unexpiredEventContentPostListFetchSuccess,
    unexpiredEventContentPostListFetchFailure,
    {
        getResponseListFunction: getPostListResponseFunction({
            includeExpired: false,
            postTypeList: [PostType.EVENT, PostType.CONTENT],
        }),
        pageSelectFunction: ({
            post: { eventContentPostListPage: page, eventContentPostListTotalPage: totalPage },
        }) => ({ page, totalPage }),
    },
);
