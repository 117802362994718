import { MenuOrderByColumn } from "@ferda/rest-api-client";
import {
    menuGroupListFetchRequest,
    menuGroupListFetchSuccess,
    menuGroupListFetchFailure,
    menuListFetchRequest,
    menuListFetchSuccess,
    menuListFetchFailure,
    selectedMenuFetchFailure,
    selectedMenuFetchRequest,
    selectedMenuFetchSuccess,
    recommendedMenuListFetchRequest,
    recommendedMenuListFetchSuccess,
    recommendedMenuListFetchFailure,
} from "./actions";
import { makeFetchMoreListThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";

interface FetchMoreMenuGroupListThunkPayload {
    getAll?: boolean;
}

export const fetchMoreMenuGroupListThunk = makeFetchMoreListThunkAction(
    menuGroupListFetchRequest,
    menuGroupListFetchSuccess,
    menuGroupListFetchFailure,
    {
        getResponseListFunction: async (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                    },
                },
                page,
            },
            { getAll }: FetchMoreMenuGroupListThunkPayload,
        ) => shopApi.getMenuGroups(getAll, page),
        pageSelectFunction: ({ menu: { menuGroupListPage: page, menuGroupListTotalPage: totalPage } }) => ({
            page,
            totalPage,
        }),
    },
);

interface FetchMoreMenuListThunkPayload {
    menuGroupId?: number;
    keyword?: string;
    getAll?: boolean;
    order?: MenuOrderByColumn;
}

export const fetchMoreMenuListThunk = makeFetchMoreListThunkAction(
    menuListFetchRequest,
    menuListFetchSuccess,
    menuListFetchFailure,
    {
        getResponseListFunction: async (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                    },
                },
                page,
            },
            { menuGroupId, order, keyword, getAll }: FetchMoreMenuListThunkPayload,
        ) => shopApi.getMenus(menuGroupId, keyword, getAll, false, order, page),
        pageSelectFunction: ({ menu: { menuListPage: page, menuListTotalPage: totalPage } }) => ({
            page,
            totalPage,
        }),
    },
);

export const fetchMenuThunk = makeFetchThunkAction(
    selectedMenuFetchRequest,
    selectedMenuFetchSuccess,
    selectedMenuFetchFailure,
    {
        getResponseFunction: async (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                    },
                },
            },
            menuId: number,
        ) => shopApi.getMenu(menuId),
    },
);

export const fetchRecommendedMenuThunk = makeFetchThunkAction(
    recommendedMenuListFetchRequest,
    recommendedMenuListFetchSuccess,
    recommendedMenuListFetchFailure,
    {
        getResponseFunction: async ({
            state: {
                auth: {
                    restApiClient: { shopApi },
                },
            },
        }) => shopApi.getMenus(),
    },
);
