"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ferda
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerificationApi = exports.VerificationApiFactory = exports.VerificationApiFp = exports.VerificationApiAxiosParamCreator = exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = exports.ShopApi = exports.ShopApiFactory = exports.ShopApiFp = exports.ShopApiAxiosParamCreator = exports.ServiceApi = exports.ServiceApiFactory = exports.ServiceApiFp = exports.ServiceApiAxiosParamCreator = exports.PostApi = exports.PostApiFactory = exports.PostApiFp = exports.PostApiAxiosParamCreator = exports.Oauth2Api = exports.Oauth2ApiFactory = exports.Oauth2ApiFp = exports.Oauth2ApiAxiosParamCreator = exports.CommonApi = exports.CommonApiFactory = exports.CommonApiFp = exports.CommonApiAxiosParamCreator = exports.CallbackApi = exports.CallbackApiFactory = exports.CallbackApiFp = exports.CallbackApiAxiosParamCreator = exports.UserType = exports.UserOrderByColumn = exports.TransactionType = exports.TemperatureType = exports.SocialType = exports.ServiceCancelReasonType = exports.QuestionType = exports.QuestionCategory = exports.PostType = exports.OrderStatus = exports.NotificationType = exports.MiscellaneousType = exports.MenuOrderByColumn = exports.Gender = exports.CupType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var CupType;
(function (CupType) {
    CupType["INDIVIDUAL"] = "INDIVIDUAL";
    CupType["DISPOSABLE"] = "DISPOSABLE";
    CupType["REUSABLE"] = "REUSABLE";
})(CupType = exports.CupType || (exports.CupType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var Gender;
(function (Gender) {
    Gender["MALE"] = "MALE";
    Gender["FEMALE"] = "FEMALE";
})(Gender = exports.Gender || (exports.Gender = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var MenuOrderByColumn;
(function (MenuOrderByColumn) {
    MenuOrderByColumn["ORDER_PRIORITY_ASC"] = "ORDER_PRIORITY_ASC";
    MenuOrderByColumn["PRICE_ASC"] = "PRICE_ASC";
    MenuOrderByColumn["PRICE_DESC"] = "PRICE_DESC";
    MenuOrderByColumn["RECOMMENDED_ORDER_PRIORITY_ASC"] = "RECOMMENDED_ORDER_PRIORITY_ASC";
})(MenuOrderByColumn = exports.MenuOrderByColumn || (exports.MenuOrderByColumn = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var MiscellaneousType;
(function (MiscellaneousType) {
    MiscellaneousType["SUBSCRIPTION_INFO"] = "SUBSCRIPTION_INFO";
})(MiscellaneousType = exports.MiscellaneousType || (exports.MiscellaneousType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var NotificationType;
(function (NotificationType) {
    NotificationType["GIFT"] = "GIFT";
    NotificationType["EVENT"] = "EVENT";
    NotificationType["AD"] = "AD";
    NotificationType["ORDER"] = "ORDER";
    NotificationType["NOTICE"] = "NOTICE";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["ORDER_CANCELED"] = "ORDER_CANCELED";
    OrderStatus["ORDER_COMPLETED"] = "ORDER_COMPLETED";
    OrderStatus["MAKE_IN_PROGRESS"] = "MAKE_IN_PROGRESS";
    OrderStatus["MAKE_COMPLETED"] = "MAKE_COMPLETED";
    OrderStatus["PICKUP_COMPLETED"] = "PICKUP_COMPLETED";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var PostType;
(function (PostType) {
    PostType["CONTENT"] = "CONTENT";
    PostType["EVENT"] = "EVENT";
    PostType["NOTICE"] = "NOTICE";
})(PostType = exports.PostType || (exports.PostType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var QuestionCategory;
(function (QuestionCategory) {
    QuestionCategory["FOREIGN"] = "FOREIGN";
    QuestionCategory["SERVICE"] = "SERVICE";
    QuestionCategory["CLEANLINESS"] = "CLEANLINESS";
    QuestionCategory["QUALITY"] = "QUALITY";
    QuestionCategory["UNSUBSCRIBE"] = "UNSUBSCRIBE";
    QuestionCategory["REFUND"] = "REFUND";
    QuestionCategory["ETC"] = "ETC";
})(QuestionCategory = exports.QuestionCategory || (exports.QuestionCategory = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var QuestionType;
(function (QuestionType) {
    QuestionType["COMPLIMENT"] = "COMPLIMENT";
    QuestionType["QUESTION"] = "QUESTION";
    QuestionType["SUGGESTION"] = "SUGGESTION";
    QuestionType["DISSATISFACTION"] = "DISSATISFACTION";
})(QuestionType = exports.QuestionType || (exports.QuestionType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var ServiceCancelReasonType;
(function (ServiceCancelReasonType) {
    ServiceCancelReasonType["WITHDRAWAL"] = "WITHDRAWAL";
    ServiceCancelReasonType["UNSUBSCRIBE"] = "UNSUBSCRIBE";
})(ServiceCancelReasonType = exports.ServiceCancelReasonType || (exports.ServiceCancelReasonType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var SocialType;
(function (SocialType) {
    SocialType["KAKAO"] = "KAKAO";
})(SocialType = exports.SocialType || (exports.SocialType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var TemperatureType;
(function (TemperatureType) {
    TemperatureType["HOT"] = "HOT";
    TemperatureType["ICED"] = "ICED";
})(TemperatureType = exports.TemperatureType || (exports.TemperatureType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var TransactionType;
(function (TransactionType) {
    TransactionType["SUBSCRIPTION"] = "SUBSCRIPTION";
    TransactionType["GIFT"] = "GIFT";
    TransactionType["SINGLE_ORDER"] = "SINGLE_ORDER";
})(TransactionType = exports.TransactionType || (exports.TransactionType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var UserOrderByColumn;
(function (UserOrderByColumn) {
    UserOrderByColumn["CREATED_DATETIME_ASC"] = "CREATED_DATETIME_ASC";
    UserOrderByColumn["CREATED_DATETIME_DESC"] = "CREATED_DATETIME_DESC";
    UserOrderByColumn["NAME_ASC"] = "NAME_ASC";
    UserOrderByColumn["NAME_DESC"] = "NAME_DESC";
})(UserOrderByColumn = exports.UserOrderByColumn || (exports.UserOrderByColumn = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var UserType;
(function (UserType) {
    UserType["ADMIN"] = "ADMIN";
    UserType["USER"] = "USER";
    UserType["SHOP_OWNER"] = "SHOP_OWNER";
})(UserType = exports.UserType || (exports.UserType = {}));
/**
 * CallbackApi - axios parameter creator
 * @export
 */
exports.CallbackApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 아임포트 예약 결제
         * @summary Callback Iamport Schedule Payment
         * @param {IamportSchedulePaymentRequest} iamportSchedulePaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackIamportSchedulePayment: function (iamportSchedulePaymentRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'iamportSchedulePaymentRequest' is not null or undefined
                    common_1.assertParamExists('callbackIamportSchedulePayment', 'iamportSchedulePaymentRequest', iamportSchedulePaymentRequest);
                    localVarPath = "/callback/iamport-schedule-payment";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(iamportSchedulePaymentRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * CallbackApi - functional programming interface
 * @export
 */
exports.CallbackApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.CallbackApiAxiosParamCreator(configuration);
    return {
        /**
         * 아임포트 예약 결제
         * @summary Callback Iamport Schedule Payment
         * @param {IamportSchedulePaymentRequest} iamportSchedulePaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackIamportSchedulePayment: function (iamportSchedulePaymentRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.callbackIamportSchedulePayment(iamportSchedulePaymentRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * CallbackApi - factory interface
 * @export
 */
exports.CallbackApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.CallbackApiFp(configuration);
    return {
        /**
         * 아임포트 예약 결제
         * @summary Callback Iamport Schedule Payment
         * @param {IamportSchedulePaymentRequest} iamportSchedulePaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackIamportSchedulePayment: function (iamportSchedulePaymentRequest, options) {
            return localVarFp.callbackIamportSchedulePayment(iamportSchedulePaymentRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CallbackApi - object-oriented interface
 * @export
 * @class CallbackApi
 * @extends {BaseAPI}
 */
var CallbackApi = /** @class */ (function (_super) {
    __extends(CallbackApi, _super);
    function CallbackApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 아임포트 예약 결제
     * @summary Callback Iamport Schedule Payment
     * @param {IamportSchedulePaymentRequest} iamportSchedulePaymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallbackApi
     */
    CallbackApi.prototype.callbackIamportSchedulePayment = function (iamportSchedulePaymentRequest, options) {
        var _this = this;
        return exports.CallbackApiFp(this.configuration).callbackIamportSchedulePayment(iamportSchedulePaymentRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CallbackApi;
}(base_1.BaseAPI));
exports.CallbackApi = CallbackApi;
/**
 * CommonApi - axios parameter creator
 * @export
 */
exports.CommonApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        status: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Upload Image
         * @param {any} image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: function (image, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'image' is not null or undefined
                            common_1.assertParamExists('uploadImage', 'image', image);
                            localVarPath = "/uploaded-image-sets/upload-image";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (image !== undefined) {
                                localVarFormParams.append('image', image);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Image From Ckeditor5
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageFromCkeditor5: function (file, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'file' is not null or undefined
                            common_1.assertParamExists('uploadImageFromCkeditor5', 'file', file);
                            localVarPath = "/uploaded-image-sets/upload-image-from-ckeditor5";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * CommonApi - functional programming interface
 * @export
 */
exports.CommonApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.CommonApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        status: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.status(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Image
         * @param {any} image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: function (image, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadImage(image, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Image From Ckeditor5
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageFromCkeditor5: function (file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadImageFromCkeditor5(file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * CommonApi - factory interface
 * @export
 */
exports.CommonApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.CommonApiFp(configuration);
    return {
        /**
         *
         * @summary Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        status: function (options) {
            return localVarFp.status(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Image
         * @param {any} image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: function (image, options) {
            return localVarFp.uploadImage(image, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Image From Ckeditor5
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageFromCkeditor5: function (file, options) {
            return localVarFp.uploadImageFromCkeditor5(file, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
var CommonApi = /** @class */ (function (_super) {
    __extends(CommonApi, _super);
    function CommonApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.status = function (options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).status(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Image
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.uploadImage = function (image, options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).uploadImage(image, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Image From Ckeditor5
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.uploadImageFromCkeditor5 = function (file, options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).uploadImageFromCkeditor5(file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CommonApi;
}(base_1.BaseAPI));
exports.CommonApi = CommonApi;
/**
 * Oauth2Api - axios parameter creator
 * @export
 */
exports.Oauth2ApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 토큰 생성
         * @summary Generate Token
         * @param {string} username
         * @param {string} password
         * @param {string} [grantType]
         * @param {string} [scope]
         * @param {string} [clientId]
         * @param {string} [clientSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateToken: function (username, password, grantType, scope, clientId, clientSecret, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'username' is not null or undefined
                    common_1.assertParamExists('generateToken', 'username', username);
                    // verify required parameter 'password' is not null or undefined
                    common_1.assertParamExists('generateToken', 'password', password);
                    localVarPath = "/oauth2/token";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (grantType !== undefined) {
                        localVarFormParams.set('grant_type', grantType);
                    }
                    if (username !== undefined) {
                        localVarFormParams.set('username', username);
                    }
                    if (password !== undefined) {
                        localVarFormParams.set('password', password);
                    }
                    if (scope !== undefined) {
                        localVarFormParams.set('scope', scope);
                    }
                    if (clientId !== undefined) {
                        localVarFormParams.set('client_id', clientId);
                    }
                    if (clientSecret !== undefined) {
                        localVarFormParams.set('client_secret', clientSecret);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 토큰 재생성
         * @summary Refresh Token
         * @param {string} accessToken
         * @param {string} refreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: function (accessToken, refreshToken, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'accessToken' is not null or undefined
                    common_1.assertParamExists('refreshToken', 'accessToken', accessToken);
                    // verify required parameter 'refreshToken' is not null or undefined
                    common_1.assertParamExists('refreshToken', 'refreshToken', refreshToken);
                    localVarPath = "/oauth2/refresh";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (accessToken !== undefined) {
                        localVarFormParams.set('access_token', accessToken);
                    }
                    if (refreshToken !== undefined) {
                        localVarFormParams.set('refresh_token', refreshToken);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 토큰 취소
         * @summary Revoke Token
         * @param {string} accessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: function (accessToken, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'accessToken' is not null or undefined
                    common_1.assertParamExists('revokeToken', 'accessToken', accessToken);
                    localVarPath = "/oauth2/revoke";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (accessToken !== undefined) {
                        localVarFormParams.set('access_token', accessToken);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * Oauth2Api - functional programming interface
 * @export
 */
exports.Oauth2ApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.Oauth2ApiAxiosParamCreator(configuration);
    return {
        /**
         * 토큰 생성
         * @summary Generate Token
         * @param {string} username
         * @param {string} password
         * @param {string} [grantType]
         * @param {string} [scope]
         * @param {string} [clientId]
         * @param {string} [clientSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateToken: function (username, password, grantType, scope, clientId, clientSecret, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.generateToken(username, password, grantType, scope, clientId, clientSecret, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 토큰 재생성
         * @summary Refresh Token
         * @param {string} accessToken
         * @param {string} refreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: function (accessToken, refreshToken, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.refreshToken(accessToken, refreshToken, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 토큰 취소
         * @summary Revoke Token
         * @param {string} accessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: function (accessToken, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.revokeToken(accessToken, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * Oauth2Api - factory interface
 * @export
 */
exports.Oauth2ApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.Oauth2ApiFp(configuration);
    return {
        /**
         * 토큰 생성
         * @summary Generate Token
         * @param {string} username
         * @param {string} password
         * @param {string} [grantType]
         * @param {string} [scope]
         * @param {string} [clientId]
         * @param {string} [clientSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateToken: function (username, password, grantType, scope, clientId, clientSecret, options) {
            return localVarFp.generateToken(username, password, grantType, scope, clientId, clientSecret, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 토큰 재생성
         * @summary Refresh Token
         * @param {string} accessToken
         * @param {string} refreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: function (accessToken, refreshToken, options) {
            return localVarFp.refreshToken(accessToken, refreshToken, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 토큰 취소
         * @summary Revoke Token
         * @param {string} accessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: function (accessToken, options) {
            return localVarFp.revokeToken(accessToken, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * Oauth2Api - object-oriented interface
 * @export
 * @class Oauth2Api
 * @extends {BaseAPI}
 */
var Oauth2Api = /** @class */ (function (_super) {
    __extends(Oauth2Api, _super);
    function Oauth2Api() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 토큰 생성
     * @summary Generate Token
     * @param {string} username
     * @param {string} password
     * @param {string} [grantType]
     * @param {string} [scope]
     * @param {string} [clientId]
     * @param {string} [clientSecret]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Oauth2Api
     */
    Oauth2Api.prototype.generateToken = function (username, password, grantType, scope, clientId, clientSecret, options) {
        var _this = this;
        return exports.Oauth2ApiFp(this.configuration).generateToken(username, password, grantType, scope, clientId, clientSecret, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 토큰 재생성
     * @summary Refresh Token
     * @param {string} accessToken
     * @param {string} refreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Oauth2Api
     */
    Oauth2Api.prototype.refreshToken = function (accessToken, refreshToken, options) {
        var _this = this;
        return exports.Oauth2ApiFp(this.configuration).refreshToken(accessToken, refreshToken, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 토큰 취소
     * @summary Revoke Token
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Oauth2Api
     */
    Oauth2Api.prototype.revokeToken = function (accessToken, options) {
        var _this = this;
        return exports.Oauth2ApiFp(this.configuration).revokeToken(accessToken, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return Oauth2Api;
}(base_1.BaseAPI));
exports.Oauth2Api = Oauth2Api;
/**
 * PostApi - axios parameter creator
 * @export
 */
exports.PostApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 글 생성
         * @summary Create Post
         * @param {PostRequest} postRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPost: function (postRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'postRequest' is not null or undefined
                            common_1.assertParamExists('createPost', 'postRequest', postRequest);
                            localVarPath = "/posts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(postRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 글 삭제
         * @summary Delete Post
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deletePost', 'id', id);
                            localVarPath = "/posts/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 글 가져오기
         * @summary Get Post
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPost: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('getPost', 'id', id);
                    localVarPath = "/posts/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 글 목록
         * @summary Get Posts
         * @param {boolean} [includeAlivePosts]
         * @param {boolean} [includeExpiredPosts]
         * @param {Array<PostType>} [postTypes]
         * @param {boolean} [getAll]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts: function (includeAlivePosts, includeExpiredPosts, postTypes, getAll, page, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/posts";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (includeAlivePosts !== undefined) {
                        localVarQueryParameter['include_alive_posts'] = includeAlivePosts;
                    }
                    if (includeExpiredPosts !== undefined) {
                        localVarQueryParameter['include_expired_posts'] = includeExpiredPosts;
                    }
                    if (postTypes) {
                        localVarQueryParameter['post_types'] = postTypes;
                    }
                    if (getAll !== undefined) {
                        localVarQueryParameter['get_all'] = getAll;
                    }
                    if (page !== undefined) {
                        localVarQueryParameter['page'] = page;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 글 순서 설정
         * @summary Set Post Order Priority
         * @param {Array<SetPostPriority>} setPostPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPostOrderPriority: function (setPostPriority, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'setPostPriority' is not null or undefined
                            common_1.assertParamExists('setPostOrderPriority', 'setPostPriority', setPostPriority);
                            localVarPath = "/posts/set-order-priority";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(setPostPriority, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 글 수정
         * @summary Update Post
         * @param {number} id
         * @param {PostRequest} postRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost: function (id, postRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('updatePost', 'id', id);
                            // verify required parameter 'postRequest' is not null or undefined
                            common_1.assertParamExists('updatePost', 'postRequest', postRequest);
                            localVarPath = "/posts/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(postRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * PostApi - functional programming interface
 * @export
 */
exports.PostApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.PostApiAxiosParamCreator(configuration);
    return {
        /**
         * 글 생성
         * @summary Create Post
         * @param {PostRequest} postRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPost: function (postRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createPost(postRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 글 삭제
         * @summary Delete Post
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePost(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 글 가져오기
         * @summary Get Post
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPost: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPost(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 글 목록
         * @summary Get Posts
         * @param {boolean} [includeAlivePosts]
         * @param {boolean} [includeExpiredPosts]
         * @param {Array<PostType>} [postTypes]
         * @param {boolean} [getAll]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts: function (includeAlivePosts, includeExpiredPosts, postTypes, getAll, page, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPosts(includeAlivePosts, includeExpiredPosts, postTypes, getAll, page, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 글 순서 설정
         * @summary Set Post Order Priority
         * @param {Array<SetPostPriority>} setPostPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPostOrderPriority: function (setPostPriority, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setPostOrderPriority(setPostPriority, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 글 수정
         * @summary Update Post
         * @param {number} id
         * @param {PostRequest} postRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost: function (id, postRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePost(id, postRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PostApi - factory interface
 * @export
 */
exports.PostApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.PostApiFp(configuration);
    return {
        /**
         * 글 생성
         * @summary Create Post
         * @param {PostRequest} postRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPost: function (postRequest, options) {
            return localVarFp.createPost(postRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 글 삭제
         * @summary Delete Post
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost: function (id, options) {
            return localVarFp.deletePost(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 글 가져오기
         * @summary Get Post
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPost: function (id, options) {
            return localVarFp.getPost(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 글 목록
         * @summary Get Posts
         * @param {boolean} [includeAlivePosts]
         * @param {boolean} [includeExpiredPosts]
         * @param {Array<PostType>} [postTypes]
         * @param {boolean} [getAll]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts: function (includeAlivePosts, includeExpiredPosts, postTypes, getAll, page, options) {
            return localVarFp.getPosts(includeAlivePosts, includeExpiredPosts, postTypes, getAll, page, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 글 순서 설정
         * @summary Set Post Order Priority
         * @param {Array<SetPostPriority>} setPostPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPostOrderPriority: function (setPostPriority, options) {
            return localVarFp.setPostOrderPriority(setPostPriority, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 글 수정
         * @summary Update Post
         * @param {number} id
         * @param {PostRequest} postRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost: function (id, postRequest, options) {
            return localVarFp.updatePost(id, postRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PostApi - object-oriented interface
 * @export
 * @class PostApi
 * @extends {BaseAPI}
 */
var PostApi = /** @class */ (function (_super) {
    __extends(PostApi, _super);
    function PostApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 글 생성
     * @summary Create Post
     * @param {PostRequest} postRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.createPost = function (postRequest, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).createPost(postRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 글 삭제
     * @summary Delete Post
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.deletePost = function (id, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).deletePost(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 글 가져오기
     * @summary Get Post
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.getPost = function (id, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).getPost(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 글 목록
     * @summary Get Posts
     * @param {boolean} [includeAlivePosts]
     * @param {boolean} [includeExpiredPosts]
     * @param {Array<PostType>} [postTypes]
     * @param {boolean} [getAll]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.getPosts = function (includeAlivePosts, includeExpiredPosts, postTypes, getAll, page, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).getPosts(includeAlivePosts, includeExpiredPosts, postTypes, getAll, page, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 글 순서 설정
     * @summary Set Post Order Priority
     * @param {Array<SetPostPriority>} setPostPriority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.setPostOrderPriority = function (setPostPriority, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).setPostOrderPriority(setPostPriority, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 글 수정
     * @summary Update Post
     * @param {number} id
     * @param {PostRequest} postRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    PostApi.prototype.updatePost = function (id, postRequest, options) {
        var _this = this;
        return exports.PostApiFp(this.configuration).updatePost(id, postRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PostApi;
}(base_1.BaseAPI));
exports.PostApi = PostApi;
/**
 * ServiceApi - axios parameter creator
 * @export
 */
exports.ServiceApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 알림 생성
         * @summary Create Notification
         * @param {CreateNotificationRequest} createNotificationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: function (createNotificationRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createNotificationRequest' is not null or undefined
                            common_1.assertParamExists('createNotification', 'createNotificationRequest', createNotificationRequest);
                            localVarPath = "/notifications";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createNotificationRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 문의 생성
         * @summary Create Question
         * @param {CreateQuestionRequest} createQuestionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestion: function (createQuestionRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createQuestionRequest' is not null or undefined
                            common_1.assertParamExists('createQuestion', 'createQuestionRequest', createQuestionRequest);
                            localVarPath = "/questions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createQuestionRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 서비스 취소 사유 생성
         * @summary Create Service Cancel Reason
         * @param {CreateServiceCancelReasonRequest} createServiceCancelReasonRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceCancelReason: function (createServiceCancelReasonRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createServiceCancelReasonRequest' is not null or undefined
                            common_1.assertParamExists('createServiceCancelReason', 'createServiceCancelReasonRequest', createServiceCancelReasonRequest);
                            localVarPath = "/service-cancel-reasons";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createServiceCancelReasonRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 알림 삭제
         * @summary Delete Notification
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteNotification', 'id', id);
                            localVarPath = "/notifications/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 문의 삭제
         * @summary Delete Question
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteQuestion', 'id', id);
                            localVarPath = "/questions/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 구독 삭제
         * @summary Delete User Subscription
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserSubscription: function (usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('deleteUserSubscription', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            localVarPath = "/users/{username_or_id_or_me}/subscription"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 기타 내용 가져오기
         * @summary Get Miscellaneous
         * @param {MiscellaneousType} miscellaneousType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMiscellaneous: function (miscellaneousType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'miscellaneousType' is not null or undefined
                            common_1.assertParamExists('getMiscellaneous', 'miscellaneousType', miscellaneousType);
                            localVarPath = "/miscellaneous/{miscellaneous_type}"
                                .replace("{" + "miscellaneous_type" + "}", encodeURIComponent(String(miscellaneousType)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 알림 목록
         * @summary Get Notifications
         * @param {Array<NotificationType>} [notificationTypes]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: function (notificationTypes, page, usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/notifications";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (notificationTypes) {
                                localVarQueryParameter['notification_types'] = notificationTypes;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (usernameOrIdOrMe !== undefined) {
                                localVarQueryParameter['username_or_id_or_me'] = usernameOrIdOrMe;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 문의 목록
         * @summary Get Questions
         * @param {boolean} [answered]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestions: function (answered, page, usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/questions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (answered !== undefined) {
                                localVarQueryParameter['answered'] = answered;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (usernameOrIdOrMe !== undefined) {
                                localVarQueryParameter['username_or_id_or_me'] = usernameOrIdOrMe;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 서비스 취소 사유 목록
         * @summary Get Service Cancel Reasons
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceCancelReasons: function (page, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/service-cancel-reasons";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 구독 상태 가져오기
         * @summary Get Subscribe Status
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscribeStatus: function (usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('getSubscribeStatus', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            localVarPath = "/users/{username_or_id_or_me}/subscribe-status"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 구독권 목록
         * @summary Get Subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/subscriptions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 구독 만료시각 수정
         * @summary Hold Subscription
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdSubscription: function (usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('holdSubscription', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            localVarPath = "/users/{username_or_id_or_me}/hold-subscription"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 문의 답변
         * @summary Set Answer
         * @param {number} id
         * @param {AnswerQuestionRequest} answerQuestionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnswer: function (id, answerQuestionRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('setAnswer', 'id', id);
                            // verify required parameter 'answerQuestionRequest' is not null or undefined
                            common_1.assertParamExists('setAnswer', 'answerQuestionRequest', answerQuestionRequest);
                            localVarPath = "/questions/{id}/set-answer"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(answerQuestionRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 기타 내용 저장
         * @summary Set Miscellaneous
         * @param {MiscellaneousRequest} miscellaneousRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMiscellaneous: function (miscellaneousRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'miscellaneousRequest' is not null or undefined
                            common_1.assertParamExists('setMiscellaneous', 'miscellaneousRequest', miscellaneousRequest);
                            localVarPath = "/miscellaneous";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(miscellaneousRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 구독하기
         * @summary Subscription Subscribe
         * @param {number} id
         * @param {SubscriptionSubscribeRequest} subscriptionSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionSubscribe: function (id, subscriptionSubscribeRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('subscriptionSubscribe', 'id', id);
                            // verify required parameter 'subscriptionSubscribeRequest' is not null or undefined
                            common_1.assertParamExists('subscriptionSubscribe', 'subscriptionSubscribeRequest', subscriptionSubscribeRequest);
                            localVarPath = "/subscriptions/{id}/subscribe"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(subscriptionSubscribeRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 구독 만료시각 수정
         * @summary Update Subscription Expire Datetime
         * @param {string} usernameOrIdOrMe
         * @param {UpdateSubscriptionExpireDatetimeRequest} updateSubscriptionExpireDatetimeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionExpireDatetime: function (usernameOrIdOrMe, updateSubscriptionExpireDatetimeRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('updateSubscriptionExpireDatetime', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            // verify required parameter 'updateSubscriptionExpireDatetimeRequest' is not null or undefined
                            common_1.assertParamExists('updateSubscriptionExpireDatetime', 'updateSubscriptionExpireDatetimeRequest', updateSubscriptionExpireDatetimeRequest);
                            localVarPath = "/users/{username_or_id_or_me}/update-subscription-expire-datetime"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateSubscriptionExpireDatetimeRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * ServiceApi - functional programming interface
 * @export
 */
exports.ServiceApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ServiceApiAxiosParamCreator(configuration);
    return {
        /**
         * 알림 생성
         * @summary Create Notification
         * @param {CreateNotificationRequest} createNotificationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: function (createNotificationRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createNotification(createNotificationRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 문의 생성
         * @summary Create Question
         * @param {CreateQuestionRequest} createQuestionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestion: function (createQuestionRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createQuestion(createQuestionRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 서비스 취소 사유 생성
         * @summary Create Service Cancel Reason
         * @param {CreateServiceCancelReasonRequest} createServiceCancelReasonRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceCancelReason: function (createServiceCancelReasonRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createServiceCancelReason(createServiceCancelReasonRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 알림 삭제
         * @summary Delete Notification
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteNotification(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 문의 삭제
         * @summary Delete Question
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteQuestion(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 구독 삭제
         * @summary Delete User Subscription
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserSubscription: function (usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteUserSubscription(usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 기타 내용 가져오기
         * @summary Get Miscellaneous
         * @param {MiscellaneousType} miscellaneousType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMiscellaneous: function (miscellaneousType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMiscellaneous(miscellaneousType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 알림 목록
         * @summary Get Notifications
         * @param {Array<NotificationType>} [notificationTypes]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: function (notificationTypes, page, usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getNotifications(notificationTypes, page, usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 문의 목록
         * @summary Get Questions
         * @param {boolean} [answered]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestions: function (answered, page, usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getQuestions(answered, page, usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 서비스 취소 사유 목록
         * @summary Get Service Cancel Reasons
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceCancelReasons: function (page, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getServiceCancelReasons(page, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 구독 상태 가져오기
         * @summary Get Subscribe Status
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscribeStatus: function (usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubscribeStatus(usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 구독권 목록
         * @summary Get Subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubscriptions(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 구독 만료시각 수정
         * @summary Hold Subscription
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdSubscription: function (usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.holdSubscription(usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 문의 답변
         * @summary Set Answer
         * @param {number} id
         * @param {AnswerQuestionRequest} answerQuestionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnswer: function (id, answerQuestionRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setAnswer(id, answerQuestionRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 기타 내용 저장
         * @summary Set Miscellaneous
         * @param {MiscellaneousRequest} miscellaneousRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMiscellaneous: function (miscellaneousRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setMiscellaneous(miscellaneousRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 구독하기
         * @summary Subscription Subscribe
         * @param {number} id
         * @param {SubscriptionSubscribeRequest} subscriptionSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionSubscribe: function (id, subscriptionSubscribeRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionSubscribe(id, subscriptionSubscribeRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 구독 만료시각 수정
         * @summary Update Subscription Expire Datetime
         * @param {string} usernameOrIdOrMe
         * @param {UpdateSubscriptionExpireDatetimeRequest} updateSubscriptionExpireDatetimeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionExpireDatetime: function (usernameOrIdOrMe, updateSubscriptionExpireDatetimeRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSubscriptionExpireDatetime(usernameOrIdOrMe, updateSubscriptionExpireDatetimeRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ServiceApi - factory interface
 * @export
 */
exports.ServiceApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ServiceApiFp(configuration);
    return {
        /**
         * 알림 생성
         * @summary Create Notification
         * @param {CreateNotificationRequest} createNotificationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: function (createNotificationRequest, options) {
            return localVarFp.createNotification(createNotificationRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 문의 생성
         * @summary Create Question
         * @param {CreateQuestionRequest} createQuestionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestion: function (createQuestionRequest, options) {
            return localVarFp.createQuestion(createQuestionRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 서비스 취소 사유 생성
         * @summary Create Service Cancel Reason
         * @param {CreateServiceCancelReasonRequest} createServiceCancelReasonRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceCancelReason: function (createServiceCancelReasonRequest, options) {
            return localVarFp.createServiceCancelReason(createServiceCancelReasonRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 알림 삭제
         * @summary Delete Notification
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification: function (id, options) {
            return localVarFp.deleteNotification(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 문의 삭제
         * @summary Delete Question
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion: function (id, options) {
            return localVarFp.deleteQuestion(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 구독 삭제
         * @summary Delete User Subscription
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserSubscription: function (usernameOrIdOrMe, options) {
            return localVarFp.deleteUserSubscription(usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 기타 내용 가져오기
         * @summary Get Miscellaneous
         * @param {MiscellaneousType} miscellaneousType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMiscellaneous: function (miscellaneousType, options) {
            return localVarFp.getMiscellaneous(miscellaneousType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 알림 목록
         * @summary Get Notifications
         * @param {Array<NotificationType>} [notificationTypes]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: function (notificationTypes, page, usernameOrIdOrMe, options) {
            return localVarFp.getNotifications(notificationTypes, page, usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 문의 목록
         * @summary Get Questions
         * @param {boolean} [answered]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestions: function (answered, page, usernameOrIdOrMe, options) {
            return localVarFp.getQuestions(answered, page, usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 서비스 취소 사유 목록
         * @summary Get Service Cancel Reasons
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceCancelReasons: function (page, options) {
            return localVarFp.getServiceCancelReasons(page, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 구독 상태 가져오기
         * @summary Get Subscribe Status
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscribeStatus: function (usernameOrIdOrMe, options) {
            return localVarFp.getSubscribeStatus(usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 구독권 목록
         * @summary Get Subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions: function (options) {
            return localVarFp.getSubscriptions(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 구독 만료시각 수정
         * @summary Hold Subscription
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdSubscription: function (usernameOrIdOrMe, options) {
            return localVarFp.holdSubscription(usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 문의 답변
         * @summary Set Answer
         * @param {number} id
         * @param {AnswerQuestionRequest} answerQuestionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnswer: function (id, answerQuestionRequest, options) {
            return localVarFp.setAnswer(id, answerQuestionRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 기타 내용 저장
         * @summary Set Miscellaneous
         * @param {MiscellaneousRequest} miscellaneousRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMiscellaneous: function (miscellaneousRequest, options) {
            return localVarFp.setMiscellaneous(miscellaneousRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 구독하기
         * @summary Subscription Subscribe
         * @param {number} id
         * @param {SubscriptionSubscribeRequest} subscriptionSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionSubscribe: function (id, subscriptionSubscribeRequest, options) {
            return localVarFp.subscriptionSubscribe(id, subscriptionSubscribeRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 구독 만료시각 수정
         * @summary Update Subscription Expire Datetime
         * @param {string} usernameOrIdOrMe
         * @param {UpdateSubscriptionExpireDatetimeRequest} updateSubscriptionExpireDatetimeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionExpireDatetime: function (usernameOrIdOrMe, updateSubscriptionExpireDatetimeRequest, options) {
            return localVarFp.updateSubscriptionExpireDatetime(usernameOrIdOrMe, updateSubscriptionExpireDatetimeRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
var ServiceApi = /** @class */ (function (_super) {
    __extends(ServiceApi, _super);
    function ServiceApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 알림 생성
     * @summary Create Notification
     * @param {CreateNotificationRequest} createNotificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.createNotification = function (createNotificationRequest, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).createNotification(createNotificationRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 문의 생성
     * @summary Create Question
     * @param {CreateQuestionRequest} createQuestionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.createQuestion = function (createQuestionRequest, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).createQuestion(createQuestionRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 서비스 취소 사유 생성
     * @summary Create Service Cancel Reason
     * @param {CreateServiceCancelReasonRequest} createServiceCancelReasonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.createServiceCancelReason = function (createServiceCancelReasonRequest, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).createServiceCancelReason(createServiceCancelReasonRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 알림 삭제
     * @summary Delete Notification
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.deleteNotification = function (id, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).deleteNotification(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 문의 삭제
     * @summary Delete Question
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.deleteQuestion = function (id, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).deleteQuestion(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 구독 삭제
     * @summary Delete User Subscription
     * @param {string} usernameOrIdOrMe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.deleteUserSubscription = function (usernameOrIdOrMe, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).deleteUserSubscription(usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 기타 내용 가져오기
     * @summary Get Miscellaneous
     * @param {MiscellaneousType} miscellaneousType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.getMiscellaneous = function (miscellaneousType, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).getMiscellaneous(miscellaneousType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 알림 목록
     * @summary Get Notifications
     * @param {Array<NotificationType>} [notificationTypes]
     * @param {number} [page]
     * @param {string} [usernameOrIdOrMe]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.getNotifications = function (notificationTypes, page, usernameOrIdOrMe, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).getNotifications(notificationTypes, page, usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 문의 목록
     * @summary Get Questions
     * @param {boolean} [answered]
     * @param {number} [page]
     * @param {string} [usernameOrIdOrMe]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.getQuestions = function (answered, page, usernameOrIdOrMe, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).getQuestions(answered, page, usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 서비스 취소 사유 목록
     * @summary Get Service Cancel Reasons
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.getServiceCancelReasons = function (page, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).getServiceCancelReasons(page, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 구독 상태 가져오기
     * @summary Get Subscribe Status
     * @param {string} usernameOrIdOrMe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.getSubscribeStatus = function (usernameOrIdOrMe, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).getSubscribeStatus(usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 구독권 목록
     * @summary Get Subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.getSubscriptions = function (options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).getSubscriptions(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 구독 만료시각 수정
     * @summary Hold Subscription
     * @param {string} usernameOrIdOrMe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.holdSubscription = function (usernameOrIdOrMe, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).holdSubscription(usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 문의 답변
     * @summary Set Answer
     * @param {number} id
     * @param {AnswerQuestionRequest} answerQuestionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.setAnswer = function (id, answerQuestionRequest, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).setAnswer(id, answerQuestionRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 기타 내용 저장
     * @summary Set Miscellaneous
     * @param {MiscellaneousRequest} miscellaneousRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.setMiscellaneous = function (miscellaneousRequest, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).setMiscellaneous(miscellaneousRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 구독하기
     * @summary Subscription Subscribe
     * @param {number} id
     * @param {SubscriptionSubscribeRequest} subscriptionSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.subscriptionSubscribe = function (id, subscriptionSubscribeRequest, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).subscriptionSubscribe(id, subscriptionSubscribeRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 구독 만료시각 수정
     * @summary Update Subscription Expire Datetime
     * @param {string} usernameOrIdOrMe
     * @param {UpdateSubscriptionExpireDatetimeRequest} updateSubscriptionExpireDatetimeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.updateSubscriptionExpireDatetime = function (usernameOrIdOrMe, updateSubscriptionExpireDatetimeRequest, options) {
        var _this = this;
        return exports.ServiceApiFp(this.configuration).updateSubscriptionExpireDatetime(usernameOrIdOrMe, updateSubscriptionExpireDatetimeRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceApi;
}(base_1.BaseAPI));
exports.ServiceApi = ServiceApi;
/**
 * ShopApi - axios parameter creator
 * @export
 */
exports.ShopApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 선물 생성
         * @summary Create Gift
         * @param {CreateGiftRequest} createGiftRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGift: function (createGiftRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createGiftRequest' is not null or undefined
                            common_1.assertParamExists('createGift', 'createGiftRequest', createGiftRequest);
                            localVarPath = "/gifts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createGiftRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 생성
         * @summary Create Menu
         * @param {CreateMenuRequest} createMenuRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenu: function (createMenuRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createMenuRequest' is not null or undefined
                            common_1.assertParamExists('createMenu', 'createMenuRequest', createMenuRequest);
                            localVarPath = "/menus";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createMenuRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 생성
         * @summary Create Menu Group
         * @param {CreateMenuGroupRequest} createMenuGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenuGroup: function (createMenuGroupRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createMenuGroupRequest' is not null or undefined
                            common_1.assertParamExists('createMenuGroup', 'createMenuGroupRequest', createMenuGroupRequest);
                            localVarPath = "/menu-groups";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createMenuGroupRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 주문 생성
         * @summary Create Order
         * @param {CreateOrderRequest} createOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: function (createOrderRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createOrderRequest' is not null or undefined
                            common_1.assertParamExists('createOrder', 'createOrderRequest', createOrderRequest);
                            localVarPath = "/orders";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createOrderRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 가게 생성
         * @summary Create Shop
         * @param {CreateShopRequest} createShopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShop: function (createShopRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createShopRequest' is not null or undefined
                            common_1.assertParamExists('createShop', 'createShopRequest', createShopRequest);
                            localVarPath = "/shops";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createShopRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 구독권 선물 생성
         * @summary Create Subscription Gift
         * @param {CreateSubscriptionGiftRequest} createSubscriptionGiftRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionGift: function (createSubscriptionGiftRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'createSubscriptionGiftRequest' is not null or undefined
                            common_1.assertParamExists('createSubscriptionGift', 'createSubscriptionGiftRequest', createSubscriptionGiftRequest);
                            localVarPath = "/subscription-gifts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createSubscriptionGiftRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 선물 삭제
         * @summary Delete Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGift: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteGift', 'id', id);
                            localVarPath = "/gifts/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 삭제
         * @summary Delete Menu
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMenu: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteMenu', 'id', id);
                            localVarPath = "/menus/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 삭제
         * @summary Delete Menu Group
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMenuGroup: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteMenuGroup', 'id', id);
                            localVarPath = "/menu-groups/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 가게 삭제
         * @summary Delete Shop
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShop: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteShop', 'id', id);
                            localVarPath = "/shops/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 구독권 선물 삭제
         * @summary Delete Subscription Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscriptionGift: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteSubscriptionGift', 'id', id);
                            localVarPath = "/subscription-gifts/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 주문 목록
         * @summary Download Orders Csv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrdersCsv: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/orders/download-csv";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 가게 운영 시간 목록
         * @summary Get Business Hours
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessHours: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getBusinessHours', 'id', id);
                            localVarPath = "/shops/{id}/business-hours"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 선물 가져오기
         * @summary Get Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGift: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getGift', 'id', id);
                            localVarPath = "/gifts/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 선물 목록
         * @summary Get Gifts
         * @param {boolean} [includeSentGifts]
         * @param {boolean} [includeReceivedGifts]
         * @param {number} [menuId]
         * @param {Array<TemperatureType>} [temperatureTypes]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGifts: function (includeSentGifts, includeReceivedGifts, menuId, temperatureTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/gifts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (includeSentGifts !== undefined) {
                                localVarQueryParameter['include_sent_gifts'] = includeSentGifts;
                            }
                            if (includeReceivedGifts !== undefined) {
                                localVarQueryParameter['include_received_gifts'] = includeReceivedGifts;
                            }
                            if (menuId !== undefined) {
                                localVarQueryParameter['menu_id'] = menuId;
                            }
                            if (temperatureTypes) {
                                localVarQueryParameter['temperature_types'] = temperatureTypes;
                            }
                            if (minCreatedDate !== undefined) {
                                localVarQueryParameter['min_created_date'] = (minCreatedDate instanceof Date) ?
                                    minCreatedDate.toISOString().substr(0, 10) :
                                    minCreatedDate;
                            }
                            if (maxCreatedDate !== undefined) {
                                localVarQueryParameter['max_created_date'] = (maxCreatedDate instanceof Date) ?
                                    maxCreatedDate.toISOString().substr(0, 10) :
                                    maxCreatedDate;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (usernameOrIdOrMe !== undefined) {
                                localVarQueryParameter['username_or_id_or_me'] = usernameOrIdOrMe;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 가져오기
         * @summary Get Menu
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenu: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getMenu', 'id', id);
                            localVarPath = "/menus/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 가져오기
         * @summary Get Menu Group
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuGroup: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getMenuGroup', 'id', id);
                            localVarPath = "/menu-groups/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 목록
         * @summary Get Menu Groups
         * @param {boolean} [getAll]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuGroups: function (getAll, page, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/menu-groups";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (getAll !== undefined) {
                                localVarQueryParameter['get_all'] = getAll;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 목록
         * @summary Get Menus
         * @param {number} [menuGroupId]
         * @param {string} [keyword]
         * @param {boolean} [getAll]
         * @param {boolean} [recommendedOnly]
         * @param {MenuOrderByColumn} [order]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenus: function (menuGroupId, keyword, getAll, recommendedOnly, order, page, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/menus";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (menuGroupId !== undefined) {
                                localVarQueryParameter['menu_group_id'] = menuGroupId;
                            }
                            if (keyword !== undefined) {
                                localVarQueryParameter['keyword'] = keyword;
                            }
                            if (getAll !== undefined) {
                                localVarQueryParameter['get_all'] = getAll;
                            }
                            if (recommendedOnly !== undefined) {
                                localVarQueryParameter['recommended_only'] = recommendedOnly;
                            }
                            if (order !== undefined) {
                                localVarQueryParameter['order'] = order;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 주문 가져오기
         * @summary Get Order
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getOrder', 'id', id);
                            localVarPath = "/orders/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 주문 목록
         * @summary Get Orders
         * @param {number} [shopId]
         * @param {number} [menuId]
         * @param {Array<OrderStatus>} [orderStatuses]
         * @param {Array<TemperatureType>} [temperatureTypes]
         * @param {Array<CupType>} [cupTypes]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders: function (shopId, menuId, orderStatuses, temperatureTypes, cupTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/orders";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (shopId !== undefined) {
                                localVarQueryParameter['shop_id'] = shopId;
                            }
                            if (menuId !== undefined) {
                                localVarQueryParameter['menu_id'] = menuId;
                            }
                            if (orderStatuses) {
                                localVarQueryParameter['order_statuses'] = orderStatuses;
                            }
                            if (temperatureTypes) {
                                localVarQueryParameter['temperature_types'] = temperatureTypes;
                            }
                            if (cupTypes) {
                                localVarQueryParameter['cup_types'] = cupTypes;
                            }
                            if (minCreatedDate !== undefined) {
                                localVarQueryParameter['min_created_date'] = (minCreatedDate instanceof Date) ?
                                    minCreatedDate.toISOString().substr(0, 10) :
                                    minCreatedDate;
                            }
                            if (maxCreatedDate !== undefined) {
                                localVarQueryParameter['max_created_date'] = (maxCreatedDate instanceof Date) ?
                                    maxCreatedDate.toISOString().substr(0, 10) :
                                    maxCreatedDate;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (usernameOrIdOrMe !== undefined) {
                                localVarQueryParameter['username_or_id_or_me'] = usernameOrIdOrMe;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 가게 가져오기
         * @summary Get Shop
         * @param {number} id
         * @param {number} [selectedMenuId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShop: function (id, selectedMenuId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getShop', 'id', id);
                            localVarPath = "/shops/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (selectedMenuId !== undefined) {
                                localVarQueryParameter['selected_menu_id'] = selectedMenuId;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 가게 목록
         * @summary Get Shops
         * @param {number} [selectedMenuId]
         * @param {number} [shopOwnerId]
         * @param {string} [keyword]
         * @param {number} [latitude]
         * @param {number} [longitude]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShops: function (selectedMenuId, shopOwnerId, keyword, latitude, longitude, page, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/shops";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (selectedMenuId !== undefined) {
                                localVarQueryParameter['selected_menu_id'] = selectedMenuId;
                            }
                            if (shopOwnerId !== undefined) {
                                localVarQueryParameter['shop_owner_id'] = shopOwnerId;
                            }
                            if (keyword !== undefined) {
                                localVarQueryParameter['keyword'] = keyword;
                            }
                            if (latitude !== undefined) {
                                localVarQueryParameter['latitude'] = latitude;
                            }
                            if (longitude !== undefined) {
                                localVarQueryParameter['longitude'] = longitude;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 구독권 선물 가져오기
         * @summary Get Subscription Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionGift: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getSubscriptionGift', 'id', id);
                            localVarPath = "/subscription-gifts/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 구독권 선물 목록
         * @summary Get Subscription Gifts
         * @param {boolean} [includeSentGifts]
         * @param {boolean} [includeReceivedGifts]
         * @param {number} [subscriptionId]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionGifts: function (includeSentGifts, includeReceivedGifts, subscriptionId, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/subscription-gifts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (includeSentGifts !== undefined) {
                                localVarQueryParameter['include_sent_gifts'] = includeSentGifts;
                            }
                            if (includeReceivedGifts !== undefined) {
                                localVarQueryParameter['include_received_gifts'] = includeReceivedGifts;
                            }
                            if (subscriptionId !== undefined) {
                                localVarQueryParameter['subscription_id'] = subscriptionId;
                            }
                            if (minCreatedDate !== undefined) {
                                localVarQueryParameter['min_created_date'] = (minCreatedDate instanceof Date) ?
                                    minCreatedDate.toISOString().substr(0, 10) :
                                    minCreatedDate;
                            }
                            if (maxCreatedDate !== undefined) {
                                localVarQueryParameter['max_created_date'] = (maxCreatedDate instanceof Date) ?
                                    maxCreatedDate.toISOString().substr(0, 10) :
                                    maxCreatedDate;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (usernameOrIdOrMe !== undefined) {
                                localVarQueryParameter['username_or_id_or_me'] = usernameOrIdOrMe;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 선물 주문
         * @summary Gift Order
         * @param {number} id
         * @param {GiftOrderRequest} giftOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftOrder: function (id, giftOrderRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('giftOrder', 'id', id);
                            // verify required parameter 'giftOrderRequest' is not null or undefined
                            common_1.assertParamExists('giftOrder', 'giftOrderRequest', giftOrderRequest);
                            localVarPath = "/gifts/{id}/order"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(giftOrderRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 가게 운영 시간 설정
         * @summary Set Business Hours
         * @param {number} id
         * @param {Array<SetShopBusinessHourRequest>} setShopBusinessHourRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBusinessHours: function (id, setShopBusinessHourRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('setBusinessHours', 'id', id);
                            // verify required parameter 'setShopBusinessHourRequest' is not null or undefined
                            common_1.assertParamExists('setBusinessHours', 'setShopBusinessHourRequest', setShopBusinessHourRequest);
                            localVarPath = "/shops/{id}/business-hours"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(setShopBusinessHourRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 순서 설정
         * @summary Set Menu Group Order Priority
         * @param {Array<SetMenuGroupOrderPriority>} setMenuGroupOrderPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuGroupOrderPriority: function (setMenuGroupOrderPriority, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'setMenuGroupOrderPriority' is not null or undefined
                            common_1.assertParamExists('setMenuGroupOrderPriority', 'setMenuGroupOrderPriority', setMenuGroupOrderPriority);
                            localVarPath = "/menu-groups/set-order-priority";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(setMenuGroupOrderPriority, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 순서 설정
         * @summary Set Menu Order Priority
         * @param {Array<SetMenuOrderPriority>} setMenuOrderPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuOrderPriority: function (setMenuOrderPriority, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'setMenuOrderPriority' is not null or undefined
                            common_1.assertParamExists('setMenuOrderPriority', 'setMenuOrderPriority', setMenuOrderPriority);
                            localVarPath = "/menus/set-order-priority";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(setMenuOrderPriority, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 추천 순서 설정
         * @summary Set Menu Recommended Order Priority
         * @param {Array<SetMenuRecommendedOrderPriority>} setMenuRecommendedOrderPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuRecommendedOrderPriority: function (setMenuRecommendedOrderPriority, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'setMenuRecommendedOrderPriority' is not null or undefined
                            common_1.assertParamExists('setMenuRecommendedOrderPriority', 'setMenuRecommendedOrderPriority', setMenuRecommendedOrderPriority);
                            localVarPath = "/menus/set-recommended-order-priority";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(setMenuRecommendedOrderPriority, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 주문 상태 설정
         * @summary Set Order Status
         * @param {number} id
         * @param {SetOrderStatusRequest} setOrderStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderStatus: function (id, setOrderStatusRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('setOrderStatus', 'id', id);
                            // verify required parameter 'setOrderStatusRequest' is not null or undefined
                            common_1.assertParamExists('setOrderStatus', 'setOrderStatusRequest', setOrderStatusRequest);
                            localVarPath = "/orders/{id}/set-order-status"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(setOrderStatusRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 일회성 구독하기
         * @summary Subscription One Time Subscribe
         * @param {number} id
         * @param {CreateOneTimeSubscribeRequest} createOneTimeSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOneTimeSubscribe: function (id, createOneTimeSubscribeRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('subscriptionOneTimeSubscribe', 'id', id);
                            // verify required parameter 'createOneTimeSubscribeRequest' is not null or undefined
                            common_1.assertParamExists('subscriptionOneTimeSubscribe', 'createOneTimeSubscribeRequest', createOneTimeSubscribeRequest);
                            localVarPath = "/subscriptions/{id}/one-time-subscribe"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createOneTimeSubscribeRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 수정
         * @summary Update Menu
         * @param {number} id
         * @param {UpdateMenuRequest} updateMenuRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenu: function (id, updateMenuRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('updateMenu', 'id', id);
                            // verify required parameter 'updateMenuRequest' is not null or undefined
                            common_1.assertParamExists('updateMenu', 'updateMenuRequest', updateMenuRequest);
                            localVarPath = "/menus/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateMenuRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 수정
         * @summary Update Menu Group
         * @param {number} id
         * @param {UpdateMenuGroupRequest} updateMenuGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenuGroup: function (id, updateMenuGroupRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('updateMenuGroup', 'id', id);
                            // verify required parameter 'updateMenuGroupRequest' is not null or undefined
                            common_1.assertParamExists('updateMenuGroup', 'updateMenuGroupRequest', updateMenuGroupRequest);
                            localVarPath = "/menu-groups/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateMenuGroupRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 가게 수정
         * @summary Update Shop
         * @param {number} id
         * @param {UpdateShopRequest} updateShopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShop: function (id, updateShopRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('updateShop', 'id', id);
                            // verify required parameter 'updateShopRequest' is not null or undefined
                            common_1.assertParamExists('updateShop', 'updateShopRequest', updateShopRequest);
                            localVarPath = "/shops/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateShopRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 구독권 선물 사용
         * @summary Use Subscription Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useSubscriptionGift: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('useSubscriptionGift', 'id', id);
                            localVarPath = "/subscription-gifts/{id}/use"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * ShopApi - functional programming interface
 * @export
 */
exports.ShopApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ShopApiAxiosParamCreator(configuration);
    return {
        /**
         * 선물 생성
         * @summary Create Gift
         * @param {CreateGiftRequest} createGiftRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGift: function (createGiftRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createGift(createGiftRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 생성
         * @summary Create Menu
         * @param {CreateMenuRequest} createMenuRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenu: function (createMenuRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createMenu(createMenuRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 생성
         * @summary Create Menu Group
         * @param {CreateMenuGroupRequest} createMenuGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenuGroup: function (createMenuGroupRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createMenuGroup(createMenuGroupRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주문 생성
         * @summary Create Order
         * @param {CreateOrderRequest} createOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: function (createOrderRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createOrder(createOrderRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 가게 생성
         * @summary Create Shop
         * @param {CreateShopRequest} createShopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShop: function (createShopRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createShop(createShopRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 구독권 선물 생성
         * @summary Create Subscription Gift
         * @param {CreateSubscriptionGiftRequest} createSubscriptionGiftRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionGift: function (createSubscriptionGiftRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createSubscriptionGift(createSubscriptionGiftRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 선물 삭제
         * @summary Delete Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGift: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteGift(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 삭제
         * @summary Delete Menu
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMenu: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteMenu(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 삭제
         * @summary Delete Menu Group
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMenuGroup: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteMenuGroup(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 가게 삭제
         * @summary Delete Shop
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShop: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteShop(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 구독권 선물 삭제
         * @summary Delete Subscription Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscriptionGift: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteSubscriptionGift(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주문 목록
         * @summary Download Orders Csv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrdersCsv: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadOrdersCsv(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 가게 운영 시간 목록
         * @summary Get Business Hours
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessHours: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getBusinessHours(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 선물 가져오기
         * @summary Get Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGift: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getGift(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 선물 목록
         * @summary Get Gifts
         * @param {boolean} [includeSentGifts]
         * @param {boolean} [includeReceivedGifts]
         * @param {number} [menuId]
         * @param {Array<TemperatureType>} [temperatureTypes]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGifts: function (includeSentGifts, includeReceivedGifts, menuId, temperatureTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getGifts(includeSentGifts, includeReceivedGifts, menuId, temperatureTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 가져오기
         * @summary Get Menu
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenu: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMenu(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 가져오기
         * @summary Get Menu Group
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuGroup: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMenuGroup(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 목록
         * @summary Get Menu Groups
         * @param {boolean} [getAll]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuGroups: function (getAll, page, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMenuGroups(getAll, page, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 목록
         * @summary Get Menus
         * @param {number} [menuGroupId]
         * @param {string} [keyword]
         * @param {boolean} [getAll]
         * @param {boolean} [recommendedOnly]
         * @param {MenuOrderByColumn} [order]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenus: function (menuGroupId, keyword, getAll, recommendedOnly, order, page, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMenus(menuGroupId, keyword, getAll, recommendedOnly, order, page, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주문 가져오기
         * @summary Get Order
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getOrder(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주문 목록
         * @summary Get Orders
         * @param {number} [shopId]
         * @param {number} [menuId]
         * @param {Array<OrderStatus>} [orderStatuses]
         * @param {Array<TemperatureType>} [temperatureTypes]
         * @param {Array<CupType>} [cupTypes]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders: function (shopId, menuId, orderStatuses, temperatureTypes, cupTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getOrders(shopId, menuId, orderStatuses, temperatureTypes, cupTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 가게 가져오기
         * @summary Get Shop
         * @param {number} id
         * @param {number} [selectedMenuId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShop: function (id, selectedMenuId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getShop(id, selectedMenuId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 가게 목록
         * @summary Get Shops
         * @param {number} [selectedMenuId]
         * @param {number} [shopOwnerId]
         * @param {string} [keyword]
         * @param {number} [latitude]
         * @param {number} [longitude]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShops: function (selectedMenuId, shopOwnerId, keyword, latitude, longitude, page, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getShops(selectedMenuId, shopOwnerId, keyword, latitude, longitude, page, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 구독권 선물 가져오기
         * @summary Get Subscription Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionGift: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubscriptionGift(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 구독권 선물 목록
         * @summary Get Subscription Gifts
         * @param {boolean} [includeSentGifts]
         * @param {boolean} [includeReceivedGifts]
         * @param {number} [subscriptionId]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionGifts: function (includeSentGifts, includeReceivedGifts, subscriptionId, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubscriptionGifts(includeSentGifts, includeReceivedGifts, subscriptionId, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 선물 주문
         * @summary Gift Order
         * @param {number} id
         * @param {GiftOrderRequest} giftOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftOrder: function (id, giftOrderRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.giftOrder(id, giftOrderRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 가게 운영 시간 설정
         * @summary Set Business Hours
         * @param {number} id
         * @param {Array<SetShopBusinessHourRequest>} setShopBusinessHourRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBusinessHours: function (id, setShopBusinessHourRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setBusinessHours(id, setShopBusinessHourRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 순서 설정
         * @summary Set Menu Group Order Priority
         * @param {Array<SetMenuGroupOrderPriority>} setMenuGroupOrderPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuGroupOrderPriority: function (setMenuGroupOrderPriority, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setMenuGroupOrderPriority(setMenuGroupOrderPriority, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 순서 설정
         * @summary Set Menu Order Priority
         * @param {Array<SetMenuOrderPriority>} setMenuOrderPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuOrderPriority: function (setMenuOrderPriority, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setMenuOrderPriority(setMenuOrderPriority, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 추천 순서 설정
         * @summary Set Menu Recommended Order Priority
         * @param {Array<SetMenuRecommendedOrderPriority>} setMenuRecommendedOrderPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuRecommendedOrderPriority: function (setMenuRecommendedOrderPriority, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setMenuRecommendedOrderPriority(setMenuRecommendedOrderPriority, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 주문 상태 설정
         * @summary Set Order Status
         * @param {number} id
         * @param {SetOrderStatusRequest} setOrderStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderStatus: function (id, setOrderStatusRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setOrderStatus(id, setOrderStatusRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 일회성 구독하기
         * @summary Subscription One Time Subscribe
         * @param {number} id
         * @param {CreateOneTimeSubscribeRequest} createOneTimeSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOneTimeSubscribe: function (id, createOneTimeSubscribeRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscriptionOneTimeSubscribe(id, createOneTimeSubscribeRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 수정
         * @summary Update Menu
         * @param {number} id
         * @param {UpdateMenuRequest} updateMenuRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenu: function (id, updateMenuRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateMenu(id, updateMenuRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 메뉴 그룹 수정
         * @summary Update Menu Group
         * @param {number} id
         * @param {UpdateMenuGroupRequest} updateMenuGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenuGroup: function (id, updateMenuGroupRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateMenuGroup(id, updateMenuGroupRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 가게 수정
         * @summary Update Shop
         * @param {number} id
         * @param {UpdateShopRequest} updateShopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShop: function (id, updateShopRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateShop(id, updateShopRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 구독권 선물 사용
         * @summary Use Subscription Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useSubscriptionGift: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.useSubscriptionGift(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ShopApi - factory interface
 * @export
 */
exports.ShopApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ShopApiFp(configuration);
    return {
        /**
         * 선물 생성
         * @summary Create Gift
         * @param {CreateGiftRequest} createGiftRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGift: function (createGiftRequest, options) {
            return localVarFp.createGift(createGiftRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 생성
         * @summary Create Menu
         * @param {CreateMenuRequest} createMenuRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenu: function (createMenuRequest, options) {
            return localVarFp.createMenu(createMenuRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 그룹 생성
         * @summary Create Menu Group
         * @param {CreateMenuGroupRequest} createMenuGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenuGroup: function (createMenuGroupRequest, options) {
            return localVarFp.createMenuGroup(createMenuGroupRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주문 생성
         * @summary Create Order
         * @param {CreateOrderRequest} createOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: function (createOrderRequest, options) {
            return localVarFp.createOrder(createOrderRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 가게 생성
         * @summary Create Shop
         * @param {CreateShopRequest} createShopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShop: function (createShopRequest, options) {
            return localVarFp.createShop(createShopRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 구독권 선물 생성
         * @summary Create Subscription Gift
         * @param {CreateSubscriptionGiftRequest} createSubscriptionGiftRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionGift: function (createSubscriptionGiftRequest, options) {
            return localVarFp.createSubscriptionGift(createSubscriptionGiftRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 선물 삭제
         * @summary Delete Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGift: function (id, options) {
            return localVarFp.deleteGift(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 삭제
         * @summary Delete Menu
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMenu: function (id, options) {
            return localVarFp.deleteMenu(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 그룹 삭제
         * @summary Delete Menu Group
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMenuGroup: function (id, options) {
            return localVarFp.deleteMenuGroup(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 가게 삭제
         * @summary Delete Shop
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShop: function (id, options) {
            return localVarFp.deleteShop(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 구독권 선물 삭제
         * @summary Delete Subscription Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscriptionGift: function (id, options) {
            return localVarFp.deleteSubscriptionGift(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주문 목록
         * @summary Download Orders Csv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrdersCsv: function (options) {
            return localVarFp.downloadOrdersCsv(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 가게 운영 시간 목록
         * @summary Get Business Hours
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessHours: function (id, options) {
            return localVarFp.getBusinessHours(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 선물 가져오기
         * @summary Get Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGift: function (id, options) {
            return localVarFp.getGift(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 선물 목록
         * @summary Get Gifts
         * @param {boolean} [includeSentGifts]
         * @param {boolean} [includeReceivedGifts]
         * @param {number} [menuId]
         * @param {Array<TemperatureType>} [temperatureTypes]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGifts: function (includeSentGifts, includeReceivedGifts, menuId, temperatureTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            return localVarFp.getGifts(includeSentGifts, includeReceivedGifts, menuId, temperatureTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 가져오기
         * @summary Get Menu
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenu: function (id, options) {
            return localVarFp.getMenu(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 그룹 가져오기
         * @summary Get Menu Group
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuGroup: function (id, options) {
            return localVarFp.getMenuGroup(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 그룹 목록
         * @summary Get Menu Groups
         * @param {boolean} [getAll]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuGroups: function (getAll, page, options) {
            return localVarFp.getMenuGroups(getAll, page, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 목록
         * @summary Get Menus
         * @param {number} [menuGroupId]
         * @param {string} [keyword]
         * @param {boolean} [getAll]
         * @param {boolean} [recommendedOnly]
         * @param {MenuOrderByColumn} [order]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenus: function (menuGroupId, keyword, getAll, recommendedOnly, order, page, options) {
            return localVarFp.getMenus(menuGroupId, keyword, getAll, recommendedOnly, order, page, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주문 가져오기
         * @summary Get Order
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: function (id, options) {
            return localVarFp.getOrder(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주문 목록
         * @summary Get Orders
         * @param {number} [shopId]
         * @param {number} [menuId]
         * @param {Array<OrderStatus>} [orderStatuses]
         * @param {Array<TemperatureType>} [temperatureTypes]
         * @param {Array<CupType>} [cupTypes]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders: function (shopId, menuId, orderStatuses, temperatureTypes, cupTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            return localVarFp.getOrders(shopId, menuId, orderStatuses, temperatureTypes, cupTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 가게 가져오기
         * @summary Get Shop
         * @param {number} id
         * @param {number} [selectedMenuId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShop: function (id, selectedMenuId, options) {
            return localVarFp.getShop(id, selectedMenuId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 가게 목록
         * @summary Get Shops
         * @param {number} [selectedMenuId]
         * @param {number} [shopOwnerId]
         * @param {string} [keyword]
         * @param {number} [latitude]
         * @param {number} [longitude]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShops: function (selectedMenuId, shopOwnerId, keyword, latitude, longitude, page, options) {
            return localVarFp.getShops(selectedMenuId, shopOwnerId, keyword, latitude, longitude, page, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 구독권 선물 가져오기
         * @summary Get Subscription Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionGift: function (id, options) {
            return localVarFp.getSubscriptionGift(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 구독권 선물 목록
         * @summary Get Subscription Gifts
         * @param {boolean} [includeSentGifts]
         * @param {boolean} [includeReceivedGifts]
         * @param {number} [subscriptionId]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionGifts: function (includeSentGifts, includeReceivedGifts, subscriptionId, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            return localVarFp.getSubscriptionGifts(includeSentGifts, includeReceivedGifts, subscriptionId, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 선물 주문
         * @summary Gift Order
         * @param {number} id
         * @param {GiftOrderRequest} giftOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftOrder: function (id, giftOrderRequest, options) {
            return localVarFp.giftOrder(id, giftOrderRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 가게 운영 시간 설정
         * @summary Set Business Hours
         * @param {number} id
         * @param {Array<SetShopBusinessHourRequest>} setShopBusinessHourRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBusinessHours: function (id, setShopBusinessHourRequest, options) {
            return localVarFp.setBusinessHours(id, setShopBusinessHourRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 그룹 순서 설정
         * @summary Set Menu Group Order Priority
         * @param {Array<SetMenuGroupOrderPriority>} setMenuGroupOrderPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuGroupOrderPriority: function (setMenuGroupOrderPriority, options) {
            return localVarFp.setMenuGroupOrderPriority(setMenuGroupOrderPriority, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 순서 설정
         * @summary Set Menu Order Priority
         * @param {Array<SetMenuOrderPriority>} setMenuOrderPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuOrderPriority: function (setMenuOrderPriority, options) {
            return localVarFp.setMenuOrderPriority(setMenuOrderPriority, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 추천 순서 설정
         * @summary Set Menu Recommended Order Priority
         * @param {Array<SetMenuRecommendedOrderPriority>} setMenuRecommendedOrderPriority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMenuRecommendedOrderPriority: function (setMenuRecommendedOrderPriority, options) {
            return localVarFp.setMenuRecommendedOrderPriority(setMenuRecommendedOrderPriority, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 주문 상태 설정
         * @summary Set Order Status
         * @param {number} id
         * @param {SetOrderStatusRequest} setOrderStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderStatus: function (id, setOrderStatusRequest, options) {
            return localVarFp.setOrderStatus(id, setOrderStatusRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 일회성 구독하기
         * @summary Subscription One Time Subscribe
         * @param {number} id
         * @param {CreateOneTimeSubscribeRequest} createOneTimeSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionOneTimeSubscribe: function (id, createOneTimeSubscribeRequest, options) {
            return localVarFp.subscriptionOneTimeSubscribe(id, createOneTimeSubscribeRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 수정
         * @summary Update Menu
         * @param {number} id
         * @param {UpdateMenuRequest} updateMenuRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenu: function (id, updateMenuRequest, options) {
            return localVarFp.updateMenu(id, updateMenuRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 메뉴 그룹 수정
         * @summary Update Menu Group
         * @param {number} id
         * @param {UpdateMenuGroupRequest} updateMenuGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenuGroup: function (id, updateMenuGroupRequest, options) {
            return localVarFp.updateMenuGroup(id, updateMenuGroupRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 가게 수정
         * @summary Update Shop
         * @param {number} id
         * @param {UpdateShopRequest} updateShopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShop: function (id, updateShopRequest, options) {
            return localVarFp.updateShop(id, updateShopRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 구독권 선물 사용
         * @summary Use Subscription Gift
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useSubscriptionGift: function (id, options) {
            return localVarFp.useSubscriptionGift(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ShopApi - object-oriented interface
 * @export
 * @class ShopApi
 * @extends {BaseAPI}
 */
var ShopApi = /** @class */ (function (_super) {
    __extends(ShopApi, _super);
    function ShopApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 선물 생성
     * @summary Create Gift
     * @param {CreateGiftRequest} createGiftRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.createGift = function (createGiftRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).createGift(createGiftRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 생성
     * @summary Create Menu
     * @param {CreateMenuRequest} createMenuRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.createMenu = function (createMenuRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).createMenu(createMenuRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 그룹 생성
     * @summary Create Menu Group
     * @param {CreateMenuGroupRequest} createMenuGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.createMenuGroup = function (createMenuGroupRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).createMenuGroup(createMenuGroupRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주문 생성
     * @summary Create Order
     * @param {CreateOrderRequest} createOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.createOrder = function (createOrderRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).createOrder(createOrderRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 가게 생성
     * @summary Create Shop
     * @param {CreateShopRequest} createShopRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.createShop = function (createShopRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).createShop(createShopRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 구독권 선물 생성
     * @summary Create Subscription Gift
     * @param {CreateSubscriptionGiftRequest} createSubscriptionGiftRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.createSubscriptionGift = function (createSubscriptionGiftRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).createSubscriptionGift(createSubscriptionGiftRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 선물 삭제
     * @summary Delete Gift
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.deleteGift = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).deleteGift(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 삭제
     * @summary Delete Menu
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.deleteMenu = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).deleteMenu(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 그룹 삭제
     * @summary Delete Menu Group
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.deleteMenuGroup = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).deleteMenuGroup(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 가게 삭제
     * @summary Delete Shop
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.deleteShop = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).deleteShop(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 구독권 선물 삭제
     * @summary Delete Subscription Gift
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.deleteSubscriptionGift = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).deleteSubscriptionGift(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주문 목록
     * @summary Download Orders Csv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.downloadOrdersCsv = function (options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).downloadOrdersCsv(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 가게 운영 시간 목록
     * @summary Get Business Hours
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getBusinessHours = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getBusinessHours(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 선물 가져오기
     * @summary Get Gift
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getGift = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getGift(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 선물 목록
     * @summary Get Gifts
     * @param {boolean} [includeSentGifts]
     * @param {boolean} [includeReceivedGifts]
     * @param {number} [menuId]
     * @param {Array<TemperatureType>} [temperatureTypes]
     * @param {string} [minCreatedDate]
     * @param {string} [maxCreatedDate]
     * @param {number} [page]
     * @param {string} [usernameOrIdOrMe]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getGifts = function (includeSentGifts, includeReceivedGifts, menuId, temperatureTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getGifts(includeSentGifts, includeReceivedGifts, menuId, temperatureTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 가져오기
     * @summary Get Menu
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getMenu = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getMenu(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 그룹 가져오기
     * @summary Get Menu Group
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getMenuGroup = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getMenuGroup(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 그룹 목록
     * @summary Get Menu Groups
     * @param {boolean} [getAll]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getMenuGroups = function (getAll, page, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getMenuGroups(getAll, page, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 목록
     * @summary Get Menus
     * @param {number} [menuGroupId]
     * @param {string} [keyword]
     * @param {boolean} [getAll]
     * @param {boolean} [recommendedOnly]
     * @param {MenuOrderByColumn} [order]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getMenus = function (menuGroupId, keyword, getAll, recommendedOnly, order, page, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getMenus(menuGroupId, keyword, getAll, recommendedOnly, order, page, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주문 가져오기
     * @summary Get Order
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getOrder = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getOrder(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주문 목록
     * @summary Get Orders
     * @param {number} [shopId]
     * @param {number} [menuId]
     * @param {Array<OrderStatus>} [orderStatuses]
     * @param {Array<TemperatureType>} [temperatureTypes]
     * @param {Array<CupType>} [cupTypes]
     * @param {string} [minCreatedDate]
     * @param {string} [maxCreatedDate]
     * @param {number} [page]
     * @param {string} [usernameOrIdOrMe]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getOrders = function (shopId, menuId, orderStatuses, temperatureTypes, cupTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getOrders(shopId, menuId, orderStatuses, temperatureTypes, cupTypes, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 가게 가져오기
     * @summary Get Shop
     * @param {number} id
     * @param {number} [selectedMenuId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getShop = function (id, selectedMenuId, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getShop(id, selectedMenuId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 가게 목록
     * @summary Get Shops
     * @param {number} [selectedMenuId]
     * @param {number} [shopOwnerId]
     * @param {string} [keyword]
     * @param {number} [latitude]
     * @param {number} [longitude]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getShops = function (selectedMenuId, shopOwnerId, keyword, latitude, longitude, page, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getShops(selectedMenuId, shopOwnerId, keyword, latitude, longitude, page, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 구독권 선물 가져오기
     * @summary Get Subscription Gift
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getSubscriptionGift = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getSubscriptionGift(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 구독권 선물 목록
     * @summary Get Subscription Gifts
     * @param {boolean} [includeSentGifts]
     * @param {boolean} [includeReceivedGifts]
     * @param {number} [subscriptionId]
     * @param {string} [minCreatedDate]
     * @param {string} [maxCreatedDate]
     * @param {number} [page]
     * @param {string} [usernameOrIdOrMe]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.getSubscriptionGifts = function (includeSentGifts, includeReceivedGifts, subscriptionId, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).getSubscriptionGifts(includeSentGifts, includeReceivedGifts, subscriptionId, minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 선물 주문
     * @summary Gift Order
     * @param {number} id
     * @param {GiftOrderRequest} giftOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.giftOrder = function (id, giftOrderRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).giftOrder(id, giftOrderRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 가게 운영 시간 설정
     * @summary Set Business Hours
     * @param {number} id
     * @param {Array<SetShopBusinessHourRequest>} setShopBusinessHourRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.setBusinessHours = function (id, setShopBusinessHourRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).setBusinessHours(id, setShopBusinessHourRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 그룹 순서 설정
     * @summary Set Menu Group Order Priority
     * @param {Array<SetMenuGroupOrderPriority>} setMenuGroupOrderPriority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.setMenuGroupOrderPriority = function (setMenuGroupOrderPriority, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).setMenuGroupOrderPriority(setMenuGroupOrderPriority, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 순서 설정
     * @summary Set Menu Order Priority
     * @param {Array<SetMenuOrderPriority>} setMenuOrderPriority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.setMenuOrderPriority = function (setMenuOrderPriority, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).setMenuOrderPriority(setMenuOrderPriority, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 추천 순서 설정
     * @summary Set Menu Recommended Order Priority
     * @param {Array<SetMenuRecommendedOrderPriority>} setMenuRecommendedOrderPriority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.setMenuRecommendedOrderPriority = function (setMenuRecommendedOrderPriority, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).setMenuRecommendedOrderPriority(setMenuRecommendedOrderPriority, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 주문 상태 설정
     * @summary Set Order Status
     * @param {number} id
     * @param {SetOrderStatusRequest} setOrderStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.setOrderStatus = function (id, setOrderStatusRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).setOrderStatus(id, setOrderStatusRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 일회성 구독하기
     * @summary Subscription One Time Subscribe
     * @param {number} id
     * @param {CreateOneTimeSubscribeRequest} createOneTimeSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.subscriptionOneTimeSubscribe = function (id, createOneTimeSubscribeRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).subscriptionOneTimeSubscribe(id, createOneTimeSubscribeRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 수정
     * @summary Update Menu
     * @param {number} id
     * @param {UpdateMenuRequest} updateMenuRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.updateMenu = function (id, updateMenuRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).updateMenu(id, updateMenuRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 메뉴 그룹 수정
     * @summary Update Menu Group
     * @param {number} id
     * @param {UpdateMenuGroupRequest} updateMenuGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.updateMenuGroup = function (id, updateMenuGroupRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).updateMenuGroup(id, updateMenuGroupRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 가게 수정
     * @summary Update Shop
     * @param {number} id
     * @param {UpdateShopRequest} updateShopRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.updateShop = function (id, updateShopRequest, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).updateShop(id, updateShopRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 구독권 선물 사용
     * @summary Use Subscription Gift
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    ShopApi.prototype.useSubscriptionGift = function (id, options) {
        var _this = this;
        return exports.ShopApiFp(this.configuration).useSubscriptionGift(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ShopApi;
}(base_1.BaseAPI));
exports.ShopApi = ShopApi;
/**
 * UserApi - axios parameter creator
 * @export
 */
exports.UserApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 유저 비밀번호 변경
         * @summary Change Password
         * @param {string} usernameOrIdOrMe
         * @param {ChangePasswordRequest} changePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: function (usernameOrIdOrMe, changePasswordRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('changePassword', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            // verify required parameter 'changePasswordRequest' is not null or undefined
                            common_1.assertParamExists('changePassword', 'changePasswordRequest', changePasswordRequest);
                            localVarPath = "/users/{username_or_id_or_me}/change-password"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 전화번호 변경
         * @summary Change Phone Number
         * @param {string} usernameOrIdOrMe
         * @param {ChangePhoneNumberRequest} changePhoneNumberRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePhoneNumber: function (usernameOrIdOrMe, changePhoneNumberRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('changePhoneNumber', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            // verify required parameter 'changePhoneNumberRequest' is not null or undefined
                            common_1.assertParamExists('changePhoneNumber', 'changePhoneNumberRequest', changePhoneNumberRequest);
                            localVarPath = "/users/{username_or_id_or_me}/change-phone-number"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(changePhoneNumberRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 존재 여부 확인
         * @summary Check User Exists
         * @param {CheckUserExistsRequest} checkUserExistsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserExists: function (checkUserExistsRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'checkUserExistsRequest' is not null or undefined
                    common_1.assertParamExists('checkUserExists', 'checkUserExistsRequest', checkUserExistsRequest);
                    localVarPath = "/users/check-user-exists";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(checkUserExistsRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 포인트 로그 생성
         * @summary Create Point Logs
         * @param {string} usernameOrIdOrMe
         * @param {CreatePointLogRequest} createPointLogRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPointLogs: function (usernameOrIdOrMe, createPointLogRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('createPointLogs', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            // verify required parameter 'createPointLogRequest' is not null or undefined
                            common_1.assertParamExists('createPointLogs', 'createPointLogRequest', createPointLogRequest);
                            localVarPath = "/users/{username_or_id_or_me}/point-logs"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createPointLogRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 생성
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: function (createUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'createUserRequest' is not null or undefined
                    common_1.assertParamExists('createUser', 'createUserRequest', createUserRequest);
                    localVarPath = "/users";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 빌링키 삭제
         * @summary Delete Billing Key
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBillingKey: function (usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('deleteBillingKey', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            localVarPath = "/users/{username_or_id_or_me}/billing-key"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 포인트 로그 삭제
         * @summary Delete Point Logs
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePointLogs: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deletePointLogs', 'id', id);
                            localVarPath = "/point-logs/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 삭제
         * @summary Delete User
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('deleteUser', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            localVarPath = "/users/{username_or_id_or_me}"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 목록
         * @summary Download Users Csv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsersCsv: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/users/download-csv";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 빌링키 가져오기
         * @summary Get Billing Key
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingKey: function (usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('getBillingKey', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            localVarPath = "/users/{username_or_id_or_me}/billing-key"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 포인트 로그 목록
         * @summary Get Point Logs
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointLogs: function (minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/point-logs";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (minCreatedDate !== undefined) {
                                localVarQueryParameter['min_created_date'] = (minCreatedDate instanceof Date) ?
                                    minCreatedDate.toISOString().substr(0, 10) :
                                    minCreatedDate;
                            }
                            if (maxCreatedDate !== undefined) {
                                localVarQueryParameter['max_created_date'] = (maxCreatedDate instanceof Date) ?
                                    maxCreatedDate.toISOString().substr(0, 10) :
                                    maxCreatedDate;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (usernameOrIdOrMe !== undefined) {
                                localVarQueryParameter['username_or_id_or_me'] = usernameOrIdOrMe;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 가져오기
         * @summary Get User
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (usernameOrIdOrMe, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('getUser', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            localVarPath = "/users/{username_or_id_or_me}"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 목록
         * @summary Get Users
         * @param {boolean} [subscriptionInUseOnly]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {Array<UserType>} [userTypes]
         * @param {boolean} [receiveSmsConsented]
         * @param {boolean} [receiveEmailConsented]
         * @param {boolean} [receiveSnsMessageConsented]
         * @param {string} [username]
         * @param {string} [name]
         * @param {string} [phoneNumber]
         * @param {Gender} [gender]
         * @param {string} [email]
         * @param {boolean} [deleted]
         * @param {UserOrderByColumn} [order]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: function (subscriptionInUseOnly, minCreatedDate, maxCreatedDate, userTypes, receiveSmsConsented, receiveEmailConsented, receiveSnsMessageConsented, username, name, phoneNumber, gender, email, deleted, order, page, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/users";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (subscriptionInUseOnly !== undefined) {
                                localVarQueryParameter['subscription_in_use_only'] = subscriptionInUseOnly;
                            }
                            if (minCreatedDate !== undefined) {
                                localVarQueryParameter['min_created_date'] = (minCreatedDate instanceof Date) ?
                                    minCreatedDate.toISOString().substr(0, 10) :
                                    minCreatedDate;
                            }
                            if (maxCreatedDate !== undefined) {
                                localVarQueryParameter['max_created_date'] = (maxCreatedDate instanceof Date) ?
                                    maxCreatedDate.toISOString().substr(0, 10) :
                                    maxCreatedDate;
                            }
                            if (userTypes) {
                                localVarQueryParameter['user_types'] = userTypes;
                            }
                            if (receiveSmsConsented !== undefined) {
                                localVarQueryParameter['receive_sms_consented'] = receiveSmsConsented;
                            }
                            if (receiveEmailConsented !== undefined) {
                                localVarQueryParameter['receive_email_consented'] = receiveEmailConsented;
                            }
                            if (receiveSnsMessageConsented !== undefined) {
                                localVarQueryParameter['receive_sns_message_consented'] = receiveSnsMessageConsented;
                            }
                            if (username !== undefined) {
                                localVarQueryParameter['username'] = username;
                            }
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (phoneNumber !== undefined) {
                                localVarQueryParameter['phone_number'] = phoneNumber;
                            }
                            if (gender !== undefined) {
                                localVarQueryParameter['gender'] = gender;
                            }
                            if (email !== undefined) {
                                localVarQueryParameter['email'] = email;
                            }
                            if (deleted !== undefined) {
                                localVarQueryParameter['deleted'] = deleted;
                            }
                            if (order !== undefined) {
                                localVarQueryParameter['order'] = order;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 빌링키 설정
         * @summary Set Billing Key
         * @param {string} usernameOrIdOrMe
         * @param {SetBillingKeyRequest} setBillingKeyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBillingKey: function (usernameOrIdOrMe, setBillingKeyRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('setBillingKey', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            // verify required parameter 'setBillingKeyRequest' is not null or undefined
                            common_1.assertParamExists('setBillingKey', 'setBillingKeyRequest', setBillingKeyRequest);
                            localVarPath = "/users/{username_or_id_or_me}/billing-key"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(setBillingKeyRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * 유저 수정
         * @summary Update User
         * @param {string} usernameOrIdOrMe
         * @param {UpdateUserRequest} updateUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (usernameOrIdOrMe, updateUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'usernameOrIdOrMe' is not null or undefined
                            common_1.assertParamExists('updateUser', 'usernameOrIdOrMe', usernameOrIdOrMe);
                            // verify required parameter 'updateUserRequest' is not null or undefined
                            common_1.assertParamExists('updateUser', 'updateUserRequest', updateUserRequest);
                            localVarPath = "/users/{username_or_id_or_me}"
                                .replace("{" + "username_or_id_or_me" + "}", encodeURIComponent(String(usernameOrIdOrMe)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
exports.UserApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.UserApiAxiosParamCreator(configuration);
    return {
        /**
         * 유저 비밀번호 변경
         * @summary Change Password
         * @param {string} usernameOrIdOrMe
         * @param {ChangePasswordRequest} changePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: function (usernameOrIdOrMe, changePasswordRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.changePassword(usernameOrIdOrMe, changePasswordRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 전화번호 변경
         * @summary Change Phone Number
         * @param {string} usernameOrIdOrMe
         * @param {ChangePhoneNumberRequest} changePhoneNumberRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePhoneNumber: function (usernameOrIdOrMe, changePhoneNumberRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.changePhoneNumber(usernameOrIdOrMe, changePhoneNumberRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 존재 여부 확인
         * @summary Check User Exists
         * @param {CheckUserExistsRequest} checkUserExistsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserExists: function (checkUserExistsRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.checkUserExists(checkUserExistsRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 포인트 로그 생성
         * @summary Create Point Logs
         * @param {string} usernameOrIdOrMe
         * @param {CreatePointLogRequest} createPointLogRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPointLogs: function (usernameOrIdOrMe, createPointLogRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createPointLogs(usernameOrIdOrMe, createPointLogRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 생성
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: function (createUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createUser(createUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 빌링키 삭제
         * @summary Delete Billing Key
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBillingKey: function (usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteBillingKey(usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 포인트 로그 삭제
         * @summary Delete Point Logs
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePointLogs: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePointLogs(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 삭제
         * @summary Delete User
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteUser(usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 목록
         * @summary Download Users Csv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsersCsv: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadUsersCsv(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 빌링키 가져오기
         * @summary Get Billing Key
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingKey: function (usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getBillingKey(usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 포인트 로그 목록
         * @summary Get Point Logs
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointLogs: function (minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPointLogs(minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 가져오기
         * @summary Get User
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (usernameOrIdOrMe, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUser(usernameOrIdOrMe, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 목록
         * @summary Get Users
         * @param {boolean} [subscriptionInUseOnly]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {Array<UserType>} [userTypes]
         * @param {boolean} [receiveSmsConsented]
         * @param {boolean} [receiveEmailConsented]
         * @param {boolean} [receiveSnsMessageConsented]
         * @param {string} [username]
         * @param {string} [name]
         * @param {string} [phoneNumber]
         * @param {Gender} [gender]
         * @param {string} [email]
         * @param {boolean} [deleted]
         * @param {UserOrderByColumn} [order]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: function (subscriptionInUseOnly, minCreatedDate, maxCreatedDate, userTypes, receiveSmsConsented, receiveEmailConsented, receiveSnsMessageConsented, username, name, phoneNumber, gender, email, deleted, order, page, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUsers(subscriptionInUseOnly, minCreatedDate, maxCreatedDate, userTypes, receiveSmsConsented, receiveEmailConsented, receiveSnsMessageConsented, username, name, phoneNumber, gender, email, deleted, order, page, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 빌링키 설정
         * @summary Set Billing Key
         * @param {string} usernameOrIdOrMe
         * @param {SetBillingKeyRequest} setBillingKeyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBillingKey: function (usernameOrIdOrMe, setBillingKeyRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setBillingKey(usernameOrIdOrMe, setBillingKeyRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 유저 수정
         * @summary Update User
         * @param {string} usernameOrIdOrMe
         * @param {UpdateUserRequest} updateUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (usernameOrIdOrMe, updateUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUser(usernameOrIdOrMe, updateUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
exports.UserApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.UserApiFp(configuration);
    return {
        /**
         * 유저 비밀번호 변경
         * @summary Change Password
         * @param {string} usernameOrIdOrMe
         * @param {ChangePasswordRequest} changePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: function (usernameOrIdOrMe, changePasswordRequest, options) {
            return localVarFp.changePassword(usernameOrIdOrMe, changePasswordRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 전화번호 변경
         * @summary Change Phone Number
         * @param {string} usernameOrIdOrMe
         * @param {ChangePhoneNumberRequest} changePhoneNumberRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePhoneNumber: function (usernameOrIdOrMe, changePhoneNumberRequest, options) {
            return localVarFp.changePhoneNumber(usernameOrIdOrMe, changePhoneNumberRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 존재 여부 확인
         * @summary Check User Exists
         * @param {CheckUserExistsRequest} checkUserExistsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserExists: function (checkUserExistsRequest, options) {
            return localVarFp.checkUserExists(checkUserExistsRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 포인트 로그 생성
         * @summary Create Point Logs
         * @param {string} usernameOrIdOrMe
         * @param {CreatePointLogRequest} createPointLogRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPointLogs: function (usernameOrIdOrMe, createPointLogRequest, options) {
            return localVarFp.createPointLogs(usernameOrIdOrMe, createPointLogRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 생성
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: function (createUserRequest, options) {
            return localVarFp.createUser(createUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 빌링키 삭제
         * @summary Delete Billing Key
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBillingKey: function (usernameOrIdOrMe, options) {
            return localVarFp.deleteBillingKey(usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 포인트 로그 삭제
         * @summary Delete Point Logs
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePointLogs: function (id, options) {
            return localVarFp.deletePointLogs(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 삭제
         * @summary Delete User
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (usernameOrIdOrMe, options) {
            return localVarFp.deleteUser(usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 목록
         * @summary Download Users Csv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsersCsv: function (options) {
            return localVarFp.downloadUsersCsv(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 빌링키 가져오기
         * @summary Get Billing Key
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingKey: function (usernameOrIdOrMe, options) {
            return localVarFp.getBillingKey(usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 포인트 로그 목록
         * @summary Get Point Logs
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {number} [page]
         * @param {string} [usernameOrIdOrMe]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointLogs: function (minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
            return localVarFp.getPointLogs(minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 가져오기
         * @summary Get User
         * @param {string} usernameOrIdOrMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (usernameOrIdOrMe, options) {
            return localVarFp.getUser(usernameOrIdOrMe, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 목록
         * @summary Get Users
         * @param {boolean} [subscriptionInUseOnly]
         * @param {string} [minCreatedDate]
         * @param {string} [maxCreatedDate]
         * @param {Array<UserType>} [userTypes]
         * @param {boolean} [receiveSmsConsented]
         * @param {boolean} [receiveEmailConsented]
         * @param {boolean} [receiveSnsMessageConsented]
         * @param {string} [username]
         * @param {string} [name]
         * @param {string} [phoneNumber]
         * @param {Gender} [gender]
         * @param {string} [email]
         * @param {boolean} [deleted]
         * @param {UserOrderByColumn} [order]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: function (subscriptionInUseOnly, minCreatedDate, maxCreatedDate, userTypes, receiveSmsConsented, receiveEmailConsented, receiveSnsMessageConsented, username, name, phoneNumber, gender, email, deleted, order, page, options) {
            return localVarFp.getUsers(subscriptionInUseOnly, minCreatedDate, maxCreatedDate, userTypes, receiveSmsConsented, receiveEmailConsented, receiveSnsMessageConsented, username, name, phoneNumber, gender, email, deleted, order, page, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 빌링키 설정
         * @summary Set Billing Key
         * @param {string} usernameOrIdOrMe
         * @param {SetBillingKeyRequest} setBillingKeyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBillingKey: function (usernameOrIdOrMe, setBillingKeyRequest, options) {
            return localVarFp.setBillingKey(usernameOrIdOrMe, setBillingKeyRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 유저 수정
         * @summary Update User
         * @param {string} usernameOrIdOrMe
         * @param {UpdateUserRequest} updateUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (usernameOrIdOrMe, updateUserRequest, options) {
            return localVarFp.updateUser(usernameOrIdOrMe, updateUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
var UserApi = /** @class */ (function (_super) {
    __extends(UserApi, _super);
    function UserApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 유저 비밀번호 변경
     * @summary Change Password
     * @param {string} usernameOrIdOrMe
     * @param {ChangePasswordRequest} changePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.changePassword = function (usernameOrIdOrMe, changePasswordRequest, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).changePassword(usernameOrIdOrMe, changePasswordRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 전화번호 변경
     * @summary Change Phone Number
     * @param {string} usernameOrIdOrMe
     * @param {ChangePhoneNumberRequest} changePhoneNumberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.changePhoneNumber = function (usernameOrIdOrMe, changePhoneNumberRequest, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).changePhoneNumber(usernameOrIdOrMe, changePhoneNumberRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 존재 여부 확인
     * @summary Check User Exists
     * @param {CheckUserExistsRequest} checkUserExistsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.checkUserExists = function (checkUserExistsRequest, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).checkUserExists(checkUserExistsRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 포인트 로그 생성
     * @summary Create Point Logs
     * @param {string} usernameOrIdOrMe
     * @param {CreatePointLogRequest} createPointLogRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.createPointLogs = function (usernameOrIdOrMe, createPointLogRequest, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).createPointLogs(usernameOrIdOrMe, createPointLogRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 생성
     * @summary Create User
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.createUser = function (createUserRequest, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).createUser(createUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 빌링키 삭제
     * @summary Delete Billing Key
     * @param {string} usernameOrIdOrMe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.deleteBillingKey = function (usernameOrIdOrMe, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).deleteBillingKey(usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 포인트 로그 삭제
     * @summary Delete Point Logs
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.deletePointLogs = function (id, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).deletePointLogs(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 삭제
     * @summary Delete User
     * @param {string} usernameOrIdOrMe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.deleteUser = function (usernameOrIdOrMe, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).deleteUser(usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 목록
     * @summary Download Users Csv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.downloadUsersCsv = function (options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).downloadUsersCsv(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 빌링키 가져오기
     * @summary Get Billing Key
     * @param {string} usernameOrIdOrMe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getBillingKey = function (usernameOrIdOrMe, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).getBillingKey(usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 포인트 로그 목록
     * @summary Get Point Logs
     * @param {string} [minCreatedDate]
     * @param {string} [maxCreatedDate]
     * @param {number} [page]
     * @param {string} [usernameOrIdOrMe]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getPointLogs = function (minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).getPointLogs(minCreatedDate, maxCreatedDate, page, usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 가져오기
     * @summary Get User
     * @param {string} usernameOrIdOrMe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getUser = function (usernameOrIdOrMe, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).getUser(usernameOrIdOrMe, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 목록
     * @summary Get Users
     * @param {boolean} [subscriptionInUseOnly]
     * @param {string} [minCreatedDate]
     * @param {string} [maxCreatedDate]
     * @param {Array<UserType>} [userTypes]
     * @param {boolean} [receiveSmsConsented]
     * @param {boolean} [receiveEmailConsented]
     * @param {boolean} [receiveSnsMessageConsented]
     * @param {string} [username]
     * @param {string} [name]
     * @param {string} [phoneNumber]
     * @param {Gender} [gender]
     * @param {string} [email]
     * @param {boolean} [deleted]
     * @param {UserOrderByColumn} [order]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getUsers = function (subscriptionInUseOnly, minCreatedDate, maxCreatedDate, userTypes, receiveSmsConsented, receiveEmailConsented, receiveSnsMessageConsented, username, name, phoneNumber, gender, email, deleted, order, page, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).getUsers(subscriptionInUseOnly, minCreatedDate, maxCreatedDate, userTypes, receiveSmsConsented, receiveEmailConsented, receiveSnsMessageConsented, username, name, phoneNumber, gender, email, deleted, order, page, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 빌링키 설정
     * @summary Set Billing Key
     * @param {string} usernameOrIdOrMe
     * @param {SetBillingKeyRequest} setBillingKeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.setBillingKey = function (usernameOrIdOrMe, setBillingKeyRequest, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).setBillingKey(usernameOrIdOrMe, setBillingKeyRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 유저 수정
     * @summary Update User
     * @param {string} usernameOrIdOrMe
     * @param {UpdateUserRequest} updateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.updateUser = function (usernameOrIdOrMe, updateUserRequest, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).updateUser(usernameOrIdOrMe, updateUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserApi;
}(base_1.BaseAPI));
exports.UserApi = UserApi;
/**
 * VerificationApi - axios parameter creator
 * @export
 */
exports.VerificationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * 인증문자 발송
         * @summary Send Phone Number Verification Code
         * @param {SendPhoneNumberVerificationCodeRequest} sendPhoneNumberVerificationCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPhoneNumberVerificationCode: function (sendPhoneNumberVerificationCodeRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'sendPhoneNumberVerificationCodeRequest' is not null or undefined
                    common_1.assertParamExists('sendPhoneNumberVerificationCode', 'sendPhoneNumberVerificationCodeRequest', sendPhoneNumberVerificationCodeRequest);
                    localVarPath = "/verification/send-phone-number-verification-code";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(sendPhoneNumberVerificationCodeRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * 인증문자 검증
         * @summary Verify Phone Number Verification Code
         * @param {VerifyPhoneNumberVerificationCodeRequest} verifyPhoneNumberVerificationCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPhoneNumberVerificationCode: function (verifyPhoneNumberVerificationCodeRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'verifyPhoneNumberVerificationCodeRequest' is not null or undefined
                    common_1.assertParamExists('verifyPhoneNumberVerificationCode', 'verifyPhoneNumberVerificationCodeRequest', verifyPhoneNumberVerificationCodeRequest);
                    localVarPath = "/verification/verify-phone-number-verification-code";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(verifyPhoneNumberVerificationCodeRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * VerificationApi - functional programming interface
 * @export
 */
exports.VerificationApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.VerificationApiAxiosParamCreator(configuration);
    return {
        /**
         * 인증문자 발송
         * @summary Send Phone Number Verification Code
         * @param {SendPhoneNumberVerificationCodeRequest} sendPhoneNumberVerificationCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPhoneNumberVerificationCode: function (sendPhoneNumberVerificationCodeRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendPhoneNumberVerificationCode(sendPhoneNumberVerificationCodeRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * 인증문자 검증
         * @summary Verify Phone Number Verification Code
         * @param {VerifyPhoneNumberVerificationCodeRequest} verifyPhoneNumberVerificationCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPhoneNumberVerificationCode: function (verifyPhoneNumberVerificationCodeRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.verifyPhoneNumberVerificationCode(verifyPhoneNumberVerificationCodeRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * VerificationApi - factory interface
 * @export
 */
exports.VerificationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.VerificationApiFp(configuration);
    return {
        /**
         * 인증문자 발송
         * @summary Send Phone Number Verification Code
         * @param {SendPhoneNumberVerificationCodeRequest} sendPhoneNumberVerificationCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPhoneNumberVerificationCode: function (sendPhoneNumberVerificationCodeRequest, options) {
            return localVarFp.sendPhoneNumberVerificationCode(sendPhoneNumberVerificationCodeRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * 인증문자 검증
         * @summary Verify Phone Number Verification Code
         * @param {VerifyPhoneNumberVerificationCodeRequest} verifyPhoneNumberVerificationCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPhoneNumberVerificationCode: function (verifyPhoneNumberVerificationCodeRequest, options) {
            return localVarFp.verifyPhoneNumberVerificationCode(verifyPhoneNumberVerificationCodeRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * VerificationApi - object-oriented interface
 * @export
 * @class VerificationApi
 * @extends {BaseAPI}
 */
var VerificationApi = /** @class */ (function (_super) {
    __extends(VerificationApi, _super);
    function VerificationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 인증문자 발송
     * @summary Send Phone Number Verification Code
     * @param {SendPhoneNumberVerificationCodeRequest} sendPhoneNumberVerificationCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    VerificationApi.prototype.sendPhoneNumberVerificationCode = function (sendPhoneNumberVerificationCodeRequest, options) {
        var _this = this;
        return exports.VerificationApiFp(this.configuration).sendPhoneNumberVerificationCode(sendPhoneNumberVerificationCodeRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * 인증문자 검증
     * @summary Verify Phone Number Verification Code
     * @param {VerifyPhoneNumberVerificationCodeRequest} verifyPhoneNumberVerificationCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    VerificationApi.prototype.verifyPhoneNumberVerificationCode = function (verifyPhoneNumberVerificationCodeRequest, options) {
        var _this = this;
        return exports.VerificationApiFp(this.configuration).verifyPhoneNumberVerificationCode(verifyPhoneNumberVerificationCodeRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return VerificationApi;
}(base_1.BaseAPI));
exports.VerificationApi = VerificationApi;
