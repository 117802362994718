import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const BottomSheetContent = styled.div`
    padding: 28px 20px 40px 20px;
`;

export interface BottomSheetTitleProps {
    titleColor?: string;
    titleAlign?: "left" | "center" | "right";
    titleSize?: string;
    titleMarginBottom?: number;
}

export const BottomSheetTitle = styled.p<BottomSheetTitleProps>`
    font-size: 20px;
    font-weight: bold;
    color: ${({ titleColor }) => titleColor || "#fff"};
    margin: 0;
    margin-bottom: ${({ titleMarginBottom }) => (titleMarginBottom ? `${titleMarginBottom}px` : "16px")};
    ${({ titleAlign }) =>
        titleAlign &&
        css`
            text-align: ${titleAlign};
        `}
    ${({ titleSize }) =>
        titleSize &&
        css`
            font-size: ${titleSize};
        `}
`;
