import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getKeyListFromState } from "@/utils/redux/state";
import { CustomHistoryState } from "./types";

const initialState: CustomHistoryState = {
    historyList: ["/"],
    currentIndex: 0,
};

const SESSION_HISTORY_LIST_KEY = "historyList";
const SESSION_CURRENT_INDEX_KEY = "currentIndex";

export function getInitialHistoryFromSession(): CustomHistoryState {
    const rawHistoryList = sessionStorage.getItem(SESSION_HISTORY_LIST_KEY);
    const rawCurrentIndex = sessionStorage.getItem(SESSION_CURRENT_INDEX_KEY);

    if (!rawHistoryList || !rawCurrentIndex) {
        return {
            historyList: [window.location.pathname],
            currentIndex: 0,
        };
    }

    let historyList;
    let currentIndex;

    try {
        historyList = JSON.parse(rawHistoryList);
        currentIndex = Number(rawCurrentIndex);
    } catch (e) {
        return initialState;
    }

    if (!historyList.length) {
        return {
            historyList: [window.location.pathname],
            currentIndex: 0,
        };
    }

    if (historyList[historyList.length - 1] !== window.location.pathname) {
        return {
            historyList: [...historyList, window.location.pathname],
            currentIndex: currentIndex + 1,
        };
    }

    return { historyList, currentIndex };
}

export function saveHistoryToSession(state: CustomHistoryState) {
    sessionStorage.setItem(SESSION_HISTORY_LIST_KEY, JSON.stringify(state.historyList));
    sessionStorage.setItem(SESSION_CURRENT_INDEX_KEY, state.currentIndex.toString());
}

export const CUSTOM_HISTORY_STATE_KEY_LIST = getKeyListFromState(initialState);

export const customHistorySlice = createSlice({
    name: "customHistory",
    initialState: getInitialHistoryFromSession(),
    reducers: {
        reset: () => initialState,
        push: (state, { payload }: PayloadAction<string>) => {
            state.historyList = [
                ...(state.currentIndex < state.historyList.length - 1
                    ? state.historyList.slice(0, state.currentIndex + 1)
                    : state.historyList),
                payload,
            ];
            state.currentIndex = state.historyList.length - 1;
            saveHistoryToSession(state);
        },
        replace: ({ historyList, currentIndex }, { payload }: PayloadAction<string>) => {
            historyList[currentIndex] = payload;
            saveHistoryToSession({ historyList, currentIndex });
        },
        back: (state) => {
            if (state.currentIndex === 0) {
                return;
            }

            state.currentIndex -= 1;
            saveHistoryToSession(state);
        },
        forward: (state) => {
            if (state.currentIndex === state.historyList.length - 1) {
                return;
            }

            state.currentIndex += 1;
            saveHistoryToSession(state);
        },
    },
});

export const customHistoryReducer = customHistorySlice.reducer;
