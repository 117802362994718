import { CupType } from "@ferda/rest-api-client";

export function getMenuCupTypeText(type: CupType) {
    switch (type) {
        case CupType.REUSABLE: {
            return "리턴컵";
        }
        case CupType.DISPOSABLE: {
            return "뉴컵";
        }
        case CupType.INDIVIDUAL: {
            return "개인 컵";
        }
    }
}
