import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { ko } from "date-fns/locale";
import React from "react";
import { ensureDecimal, ensureFloat, ensureInt } from "./number";

export function comma(money: number | string, fractionDigits?: number) {
    const parsed = ensureFloat(money);
    if (Number.isNaN(parsed)) {
        return money.toString();
    }
    if (typeof fractionDigits !== "undefined") {
        return parsed.toLocaleString([], {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        });
    } else {
        return parsed.toLocaleString();
    }
}

export function validateEmail(email: string) {
    // Compatible with HTML5 email validation
    // See: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    const re = new RegExp(
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+" +
            "@" +
            "[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
    );
    return re.test(email);
}

export function validateNumber(n: string | number | undefined): boolean {
    if (typeof n === "undefined") {
        return false;
    }
    return ensureDecimal(n)?.isFinite() ?? false;
}

export function trunc(text: string, n: number) {
    return text.length > n ? `${text.substr(0, n - 1)}...` : text;
}

export function moneyShorten(num: number) {
    const TEN_MILLON = 10000000;
    const shortenNum = Math.round(num / TEN_MILLON);
    if (shortenNum >= 10) {
        return shortenNum % 10 === 0 ? `${(shortenNum / 10).toFixed(0)}억` : `${(shortenNum / 10).toFixed(1)}억`;
    } else if (shortenNum > 0) {
        return `${shortenNum}천만 원`;
    }
}

export function moneyShortenWithDot(num: number) {
    const TEN_MILLON = 10000000;
    const shortenNum = Math.floor(num / TEN_MILLON);

    if (shortenNum > 0) {
        return shortenNum % 10 === 0 ? `${(shortenNum / 10).toFixed(0)}억` : `${(shortenNum / 10).toFixed(1)}억`;
    }
}

export function moneyShortenDetailWithDot(num: number) {
    const [million, tenMillion, ...hundredMillionList] = (num / 1000000)
        .toFixed(0)
        .split("")
        .map((number) => ensureInt(number))
        .reverse();

    const hundredMillion = ensureInt(hundredMillionList.join());

    return (
        (hundredMillion ? `${hundredMillion}억` : "") +
        (tenMillion ? ` ${tenMillion}천` : "") +
        (million ? ` ${million}백` : "")
    ).trimLeft();
}

type FormatPriceOptions = {
    withSuffix?: boolean;
    asKorean?: boolean;
};

export function formatPrice(
    price: string | number | bigint,
    { withSuffix, asKorean }: FormatPriceOptions = { withSuffix: true },
) {
    const numberText = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
    const subUnit = ["", "십", "백", "천"];
    const unit = ["", "만", "억", "조", "경", "해", "자", "양", "구", "간", "정", "재", "극"];

    if (typeof price !== "string") {
        price = price.toString();
    }

    const decimalPrice = ensureDecimal(price);

    if (decimalPrice === null) {
        return price;
    }

    if (decimalPrice.equals(0)) {
        return `${asKorean ? "영" : "0"}${withSuffix ? " 원" : ""}`;
    }

    const priceSign = decimalPrice.lessThan(0) ? "-" : "";
    const priceString = decimalPrice.toString().replace("-", "");
    const priceStringLength = priceString.length;
    const priceStringOffset = priceStringLength % 4;

    const priceStringGroupList = [];
    const priceStringGroupListLength = Math.ceil(priceStringLength / 4);

    for (let i = 0; i < priceStringGroupListLength; i += 1) {
        const startPos = i * 4 - (priceStringOffset !== 0 ? 4 - priceStringOffset : 0);
        const endPos = startPos + 4;

        const priceStringGroup = priceString.slice(startPos < 0 ? 0 : startPos, endPos);

        priceStringGroupList.push(priceStringGroup);
    }

    const formattedPriceStringList: string[] = [];

    priceStringGroupList.forEach((priceStringGroup, unitIndex) => {
        const priceUnit = unit[priceStringGroupListLength - unitIndex - 1];

        if (!asKorean) {
            const parsedPriceStringGroup = ensureInt(priceStringGroup);

            if (parsedPriceStringGroup === 0) {
                return;
            }

            return formattedPriceStringList.push(parsedPriceStringGroup.toString() + priceUnit);
        }

        const subUnitIndexOffset = 4 - priceStringGroup.length;

        priceStringGroup = priceStringGroup
            .split("")
            .map((priceStringPart, subUnitIndex) => {
                if (priceStringPart === "0") {
                    return "";
                }

                const offsettedSubUnitIndex = 3 - (subUnitIndex + subUnitIndexOffset);

                return numberText[ensureInt(priceStringPart)] + subUnit[offsettedSubUnitIndex];
            })
            .join("");

        if (!priceStringGroup) {
            return;
        }

        formattedPriceStringList.push(priceStringGroup + priceUnit);
    });

    const formattedPriceString = formattedPriceStringList.join(asKorean ? "" : " ");

    return `${priceSign}${formattedPriceString}${withSuffix ? " 원" : ""}`;
}

export function numToKorean(inum: number) {
    // 1 2345 6789 won
    // 일억 , 이천 삼백 사십 오만 , 육천 칠백 팔십 구 원

    const numToHan = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구", "십"];
    const unit = ["", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천"];

    const num = inum.toString();
    const numLength = num.length;
    let koreanNumber = "";

    if (ensureInt(num) <= 0) {
        return "영 원";
    }

    for (let i = 0; i < numLength; i += 1) {
        const iNum = ensureInt(num[numLength - i - 1]);
        let hanNum = numToHan[iNum] !== "" ? numToHan[iNum] + unit[i] : numToHan[iNum];

        if (i === 4) {
            hanNum += "만";
        } else if (i === 8) {
            hanNum += "억";
        }

        koreanNumber = hanNum + koreanNumber;
    }

    return `${koreanNumber} 원`;
}

export function formatPriceToWon(price: number, withPostfix: boolean = true) {
    const sign = price < 0 ? "-" : "";
    const div = Math.floor(Math.abs(price) / 100000000);
    const mod = Math.floor((Math.abs(price) % 100000000) / 10000);

    if (div !== 0) {
        return mod !== 0
            ? `${sign}${div}억 ${Math.abs(mod)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${withPostfix ? "만 원" : ""}`
            : `${sign}${div}억${withPostfix ? " 원" : ""}`;
    }

    return mod !== 0
        ? `${sign}${mod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}만${withPostfix ? " 원" : ""}`
        : `0${withPostfix ? "원" : ""}`;
}

export function renderMultilineText(
    text: string,
    component: string | React.ComponentClass | React.SFC = React.Fragment,
): React.ReactChild {
    const lines = text.split("\n");
    const children: Array<string | React.ReactElement> = [];
    lines.forEach((line, index) => children.push(line, <br key={`line-${index}`} />));
    return React.createElement(component, {}, children.slice(0, -1).filter(Boolean));
}

export function padding(text: string, pad: string, length: number, trailing = false): string {
    if (text.length >= length || pad === "") {
        return text;
    }
    const repeat = Math.ceil((length - text.length) / pad.length);
    const fullPad = pad.repeat(repeat);

    if (trailing) {
        return (text + fullPad).substr(0, length);
    } else {
        const concated = fullPad + text;
        return concated.substr(concated.length - length, length);
    }
}

export function zeroPadding(n: number, length: number) {
    return padding(n.toString(), "0", length, false);
}

export function describeTime(hour: number, minute: number) {
    const am = hour < 12;
    if (hour >= 13) {
        hour -= 12;
    }
    return `${am ? "오전" : "오후"} ${zeroPadding(hour, 2)}:${zeroPadding(minute, 2)}`;
}

export function getPostPosition(word: string) {
    // 1. 로 (조사): 받침 없는 체언이나 ‘ㄹ’ 받침으로 끝나는 체언 뒤에 붙음.
    // 2. 으로 (조사): ‘ㄹ’을 제외한 받침 있는 체언 뒤에 붙음.
    // 위 규칙에 맞게 조사 표현.

    const NUM_KR_CHARACTERS = 28;
    const KR_UNICODE_START = 44032;

    const lastWordUnicode = word.charCodeAt(word.length - 1);
    const jongsungUnicode = (lastWordUnicode - KR_UNICODE_START) % NUM_KR_CHARACTERS;

    // 8: ㄹ의 유니코드, 0: 종성이 없음
    if (jongsungUnicode === 8 || jongsungUnicode === 0) {
        return "로";
    } else {
        return "으로";
    }
}
export function addHyphenToPhoneNumber(phoneNumber: string) {
    if (phoneNumber.length === 10) {
        return phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10);
    } else if (phoneNumber.length === 11) {
        return phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3, 7) + "-" + phoneNumber.substring(7, 11);
    } else {
        return phoneNumber;
    }
}

export function formatDateDistance(dateString: string, suffix: string = " 전") {
    return `${formatDistanceToNowStrict(parseISO(dateString), { locale: ko })}${suffix}`;
}

export function parseFormattedNumberToInt(num: string) {
    return ensureInt(num.replace(/[^\d]/g, ""));
}

export function capitalize(text: string): Capitalize<string> {
    return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}
