import { createSlice } from "@reduxjs/toolkit";
import { NotificationResponse } from "@ferda/rest-api-client";
import { NotificationState } from "./types";
import { makeAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";
import { ListFetchSuccessPayload } from "@/utils/redux/types";

const initialState: NotificationState = {
    notificationList: null,
    notificationListFetchState: "READY",
    notificationListPage: null,
    notificationListTotalPage: null,
};

export const NOTIFICATION_STATE_KEY_LIST = getKeyListFromState(initialState);

export const notificationSlice = createSlice({
    name: "post",
    initialState,
    reducers: {
        reset: () => initialState,
        ...makeAsyncReducer<"notificationList", NotificationState, void, ListFetchSuccessPayload<NotificationResponse>>(
            "notificationList",
            {
                useListSuccessReducer: true,
            },
        ),
    },
});

export const notificationReducer = notificationSlice.reducer;
