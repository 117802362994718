import styled from "@emotion/styled";

export const ThumbnailTooltip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ff831e;
    font-size: 12px;
`;
