import styled from "@emotion/styled";
import { ButtonBase, IconButton, InputBase } from "@mui/material";
import LinkButton from "@/components/LinkButton";

export const Title = styled.div`
    margin-top: 28px;

    color: #0c0c0c;
    font-size: 24px;
    font-weight: bold;
`;

export const Description = styled.div`
    margin-top: 12px;

    color: #0c0c0c;
    font-size: 16px;
`;

export const PaymentMetodSettingsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

export const CardWidthContainer = styled.div`
    position: relative;

    align-self: center;
    width: 100%;
    max-width: 330px;
    margin-top: 48px;
`;

export const AddCardButton = styled(ButtonBase)`
    position: relative;

    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: 61.79%;

    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%235B5B4BFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 16px;
`;

export const AddCardContentContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: translate(-50%, -50%);

    text-align: center;
`;

export const AddCardImg = styled.img``;

export const AddCardTitleContainer = styled.div`
    width: 100%;
    margin-top: 16px;

    font-size: 20px;
    font-weight: bold;
    color: #5b5b5b;
`;

export const CardFrameImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
`;

export const DeleteCardButton = styled(IconButton)`
    position: absolute;
    top: 4px;
    right: 4px;
`;

export const CardNameContainer = styled.div`
    position: absolute;
    left: 30px;
    bottom: 24px;

    color: white;
    font-weight: bold;
    font-size: 20px;
`;

export const CardContainer = styled.div`
    padding-bottom: 61.79%;
`;

export const StyledLinkButton = styled(LinkButton)`
    margin-top: 32px;
`;

export const AddCardNameInput = styled(InputBase)`
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 7px;

    background-color: #ff831e;
    border-radius: 4px;
`;

export const ModalContentContainer = styled.form`
    padding-top: 16px;
    padding-bottom: 32px;
`;

export const ModalDescriptionContainer = styled.div`
    color: #0c0c0c;
    font-size: 14px;
    text-align: center;
`;
