import styled from "@emotion/styled";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import useHistory from "@/hooks/useCustomHistory";
import {
    getSubscribeFetchState,
    getSubscribeStatus,
    getSubscriptionList,
    getSubscriptionListFetchState,
} from "@/redux/subscription/selectors";
import SubscriptionOptionButton from "./SubscriptionOptionButton";
import {
    fetchSubscribeStatusThunk,
    fetchSubscribeThunk,
    fetchSubscsriptionListThunk,
} from "@/redux/subscription/thunk";
import { BottomConfirmButtonContainer, ConfirmButton } from "@/components/Button/style";
import { getPaymentMethod, getPaymentMethodFetchState } from "@/redux/payment/selectors";
import { fetchPaymentMethodThunk } from "@/redux/payment/thunk";
import { BottomSpacer } from "@/components/Spacer";
import Modal from "@/components/Modal";
import Footer from "@/components/Footer";
import { SubscribeInfoContent } from "@/components/SubscribeInfoContent";
import { resetPaymentState } from "@/redux/payment/actions";
import IamportLoader from "@/containers/IamportLoader";
import { resetSubscriptionState } from "@/redux/subscription/actions";
import { comma } from "@/utils/text";

const SubscribeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-left: 20px;
    padding-top: 42px;
    padding-right: 20px;
    padding-bottom: 48px;
`;

const Title = styled.div`
    font-size: 24px;
    text-align: center;
    color: #0c0c0c;
    font-weight: bold;
    word-break: keep-all;
`;

const Description = styled.div`
    margin-top: 12px;

    font-size: 14px;
    color: #0c0c0c;
    text-align: center;
`;

const DescriptionRow = styled.div``;

const OptionButtonList = styled.div`
    width: 100%;
    margin-top: 32px;

    > button {
        margin-bottom: 24px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
`;

const AlertModalText = styled.div`
    font-size: 14px;
    color: #0c0c0c;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 32px;
`;

const OffsetLabel = styled.div`
    display: flex;
    justifty-contont: space-between;
`;

const OffsetLabelTitle = styled.div`
    flex-grow: 1;
    text-align: left;
`;

const OffsetLabelPrice = styled.div`
    text-align: right;
`;

const StyledLabel = styled(FormControlLabel)`
    .MuiFormControlLabel-label {
        display: block;
        flex-grow: 1;
    }
`;

export function Subscribe() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [addCardModalOpen, setAddCardModalOpen] = useState(false);
    const subscribeFetchState = useSelector(getSubscribeFetchState);
    const subscriptionList = useSelector(getSubscriptionList);
    const subscriptionListFetchState = useSelector(getSubscriptionListFetchState);
    const paymentMethod = useSelector(getPaymentMethod);
    const paymentMethodFetchState = useSelector(getPaymentMethodFetchState);

    const subscribeStatus = useSelector(getSubscribeStatus);
    const [subscription, setSubscription] = useState<number | null>(null);
    const [changeModalOpen, setChangeModalOpen] = useState(false);
    const [showSubscribeInfo, setShowSubscribeInfo] = useState(Boolean(subscribeStatus?.subscription_in_use));

    const [offset, setOffset] = useState(0);
    const [offsetModalOpen, setOffsetModalOpen] = useState(false);

    const handleOffsetChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOffset(Number(event.target.value));
    };

    useEffect(() => {
        if (subscribeFetchState === "SUCCESS") {
            dispatch(resetSubscriptionState());
            history.push("/me/subscription-info");
        }
    }, [subscribeFetchState]);

    useEffect(() => {
        if (paymentMethodFetchState === "READY") {
            dispatch(fetchPaymentMethodThunk({}));
        }
    }, [paymentMethodFetchState]);

    useEffect(() => {
        if (subscriptionListFetchState !== "READY") {
            return;
        }

        dispatch(fetchSubscsriptionListThunk({}));
    }, [subscriptionListFetchState]);

    useEffect(() => {
        dispatch(fetchSubscribeStatusThunk({}));
    }, []);

    const handleSubscriptionSelect = useCallback(
        (newSubscription: number) => () => {
            setSubscription(newSubscription === subscription ? null : newSubscription);
        },
        [subscription],
    );

    const handleSubscribe = useCallback(
        (changeCheck?: boolean) => {
            setOffsetModalOpen(false);

            if (paymentMethodFetchState !== "SUCCESS" || subscription === null) {
                return;
            }

            if (subscribeStatus && subscribeStatus.subscription_in_use && !changeCheck) {
                return setChangeModalOpen(true);
            }

            let subcriptionId = subscription * 3 + offset;

            if (offset === 3) {
                subcriptionId = subscription + 6;
            }

            dispatch(fetchSubscribeThunk(subcriptionId + 1));
        },
        [paymentMethod, paymentMethodFetchState, subscription, subscribeStatus, offset],
    );

    useEffect(() => {
        dispatch(resetPaymentState());
        dispatch(resetSubscriptionState());
    }, []);

    return (
        <SubscribeContainer>
            <Modal
                open={addCardModalOpen}
                handleClose={() => setAddCardModalOpen(false)}
                buttonOnClick={() => {
                    history.push("/me/payment-method-settings");
                }}
                cancelButtonOnClick={() => setAddCardModalOpen(false)}
                titleText="카드 등록"
                buttonText="카드 등록하기"
                cancelButtonText="취소"
                fullSizeButton
            >
                <AlertModalText>
                    정기결제를 위해서는 카드등록이 필요합니다.
                    <br />
                    먼저 카드를 등록 해 주세요.
                </AlertModalText>
            </Modal>
            <Modal
                open={changeModalOpen}
                handleClose={() => setChangeModalOpen(false)}
                buttonOnClick={() => handleSubscribe(true)}
                cancelButtonOnClick={() => setChangeModalOpen(false)}
                titleText="구독권 변경"
                buttonText="확인"
                cancelButtonText="취소"
                fullSizeButton
            >
                <AlertModalText>
                    현재 이용하고 계신 구독권이 있습니다.
                    <br />
                    구독권 변경을 진행 할 경우 기존 사용 구독권은 환불이 불가하며 자동 해지 됩니다.
                    <br />
                    그래도 진행하시겠습니까?
                </AlertModalText>
            </Modal>
            <Modal
                open={offsetModalOpen}
                handleClose={() => setOffsetModalOpen(false)}
                buttonOnClick={handleSubscribe}
                cancelButtonOnClick={() => setOffsetModalOpen(false)}
                titleText="구독기간 선택"
                buttonText="구매하기"
                cancelButtonText="취소"
                fullSizeButton
            >
                <AlertModalText>
                    {subscriptionList && subscription !== null && (
                        <RadioGroup defaultValue={1} value={offset} onChange={handleOffsetChange}>
                            <StyledLabel
                                value={1}
                                control={<Radio />}
                                label={
                                    <OffsetLabel>
                                        <OffsetLabelTitle>5일</OffsetLabelTitle>
                                        <OffsetLabelPrice>
                                            {comma(subscriptionList[subscription * 3 + 1].price)}원
                                        </OffsetLabelPrice>
                                    </OffsetLabel>
                                }
                            />
                            <StyledLabel
                                value={3}
                                control={<Radio />}
                                label={
                                    <OffsetLabel>
                                        <OffsetLabelTitle>30일</OffsetLabelTitle>
                                        <OffsetLabelPrice>
                                            {comma(subscriptionList[6 + subscription].price)}원
                                        </OffsetLabelPrice>
                                    </OffsetLabel>
                                }
                            />
                        </RadioGroup>
                    )}
                </AlertModalText>
            </Modal>
            <BottomConfirmButtonContainer>
                <ConfirmButton
                    variant="contained"
                    backgroundColor="#ff831e"
                    disabled={
                        subscription === null ||
                        paymentMethodFetchState !== "SUCCESS" ||
                        subscriptionListFetchState !== "SUCCESS" ||
                        subscribeFetchState === "FETCHING"
                    }
                    onClick={() => setOffsetModalOpen(true)}
                >
                    구매하기
                </ConfirmButton>
            </BottomConfirmButtonContainer>

            {showSubscribeInfo ? (
                subscribeStatus && (
                    <SubscribeInfoContent
                        subscription={subscribeStatus}
                        buttonText="구독권 변경"
                        buttonLink=""
                        buttonOnClick={() => setShowSubscribeInfo(false)}
                    />
                )
            ) : (
                <>
                    <Title>원하는 구독권을 선택해주세요</Title>
                    <Description>
                        <DescriptionRow>매일 마시는 음료,</DescriptionRow>
                        <DescriptionRow>워크무드 구독권을 통해</DescriptionRow>
                        <DescriptionRow>가장 합리적으로 즐겨보세요.</DescriptionRow>
                    </Description>
                    {subscriptionList && (
                        <OptionButtonList>
                            <SubscriptionOptionButton
                                onClick={handleSubscriptionSelect(0)}
                                selected={subscription === 0}
                                title="Black 구독권"
                                availableMenuList={["아메리카노"]}
                                priceNode={
                                    <div>
                                        <OffsetLabelPrice>5일 {comma(subscriptionList[1].price)}원</OffsetLabelPrice>
                                        <OffsetLabelPrice>30일 {comma(subscriptionList[6].price)}원</OffsetLabelPrice>
                                    </div>
                                }
                            />
                            <SubscriptionOptionButton
                                onClick={handleSubscriptionSelect(1)}
                                selected={subscription === 1}
                                title="Orange 구독권"
                                priceNode={
                                    <div>
                                        <OffsetLabelPrice>5일 {comma(subscriptionList[4].price)}원</OffsetLabelPrice>
                                        <OffsetLabelPrice>30일 {comma(subscriptionList[7].price)}원</OffsetLabelPrice>
                                    </div>
                                }
                                availableMenuList={["모든 제조음료"]}
                            />
                        </OptionButtonList>
                    )}
                </>
            )}

            <Footer noPadding />
            <BottomSpacer />
        </SubscribeContainer>
    );
}

export default function SubscribeWithIamportLoader() {
    return (
        <IamportLoader>
            <Subscribe />
        </IamportLoader>
    );
}
