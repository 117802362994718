import React from "react";
import { Switch, BrowserRouter, Redirect } from "react-router-dom";
import OrderCallback from "./pages/OrderDetail/OrderCallback";
import Route from "@/components/RouteWithAuthGuard";
import Gift from "./pages/Gift";
import History from "./pages/History";
import HistoryDetail from "./pages/History/HistoryDetail";
import Login from "./pages/Login";
import Main from "./pages/Main";
import OrderDetail from "./pages/OrderDetail";
import NutritionFacts from "./pages/OrderDetail/NutritionFacts";
import Question from "./pages/Question";
import PaymentMethodSettings from "./pages/PaymentMethodSettings";
import SignUp from "./pages/SignUp";
import SubscriptionInfo from "./pages/SubscriptionInfo";
import PointLog from "./pages/PointLog";
import Service from "./pages/Service";
import Policy from "./pages/Policy";
import PolicyService from "./pages/Policy/Service";
import PolicyPrivacy from "./pages/Policy/Privacy";
import PolicyLocation from "./pages/Policy/Location";
import Other from "./pages/Other";
import Notice from "./pages/Notice";
import NoticeDetail from "./pages/NoticeDetail";
import Notification from "./pages/Notification";
import EventPage from "./pages/Event";
import EventDetail from "./pages/EventDetail";
import Order from "./pages/Order";
import SelectStore from "./pages/SelectStore";
import EditMyInfo from "./pages/EditMyInfo";
import GiftOrder, { GiftSubsWithIamportloader, SingleOrderWithIamportloader } from "./pages/Gift/GiftOrder";
import GiftComplete from "./pages/Gift/GiftComplete";
import GiftGetDetail from "./pages/Gift/GiftGetDetail";
import GiftSendDetail from "./pages/Gift/GiftSendDetail";
import EditPhoneNumber from "./pages/EditPhoneNumber";
import AdminLogin from "./pages/Admin/Login";
import AdminLayout from "./containers/AdminLayout";
import PosLayout from "./containers/PosLayout";
import PosLogin from "./pages/Pos/Login";
import PosList from "./pages/Pos/List";
import ScrollToTop from "./components/ScrollToTop";
import Withdraw from "./pages/Withdraw";
import GiftSubscribeGetDetail from "./pages/Gift/GiftSubscribeGetDetail";
import GiftSubscribeSendDetail from "./pages/Gift/GiftSubscribeSendDetail";
import CustomHistoryCheck from "./components/CustomHistoryCheck";
import { AppPage } from "./pages/App";
import CustomSubscribe from "./pages/Service/Subscribe/custom";
import SubscribeCallback from "@/pages/Service/Subscribe/SubscribeCallback";

export function Router() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <CustomHistoryCheck />
            <Switch>
                <Route path="/app" exact component={AppPage} />
                <Route authorizedOnly exact path="/" component={Main} />
                <Route authorizedOnly path="/question" component={Question} />
                <Route authorizedOnly exact path="/history" component={History} />
                <Route authorizedOnly path="/history/:historyId" component={HistoryDetail} />
                <Route authorizedOnly path="/service" component={Service} />
                <Route authorizedOnly path="/custom-subscribe" component={CustomSubscribe} />
                <Route authorizedOnly path="/subscribe-callback" component={SubscribeCallback} />

                <Route exact path="/me/edit" component={EditMyInfo} />
                <Route exact path="/me/edit/phone" component={EditPhoneNumber} />
                <Route authorizedOnly path="/me/point-log" component={PointLog} />
                <Route authorizedOnly path="/me/payment-method-settings" component={PaymentMethodSettings} />
                <Route authorizedOnly path="/me/subscription-info" component={SubscriptionInfo} />

                <Route path="/select-shop" component={SelectStore} />

                <Route path="/order-callback" exact component={OrderCallback} />
                <Route path="/order/:menuGroupId?" exact component={Order} />
                <Route path="/order/:menuGroupId/:menuId" exact component={OrderDetail} />
                <Route path="/order/:menuGroupId/:menuId/facts" component={NutritionFacts} />
                <Route path="/order/:menuGroupId/:menuId/single" exact component={SingleOrderWithIamportloader} />

                <Route authorizedOnly exact path="/gift/shop" component={Gift} />
                <Route authorizedOnly exact path="/gift/get" component={Gift} />
                <Route
                    authorizedOnly
                    exact
                    path="/gift/:menuId"
                    render={(args) => <OrderDetail {...args} isGift={true} />}
                />
                <Route authorizedOnly exact path="/gift/:menuId/order" component={GiftOrder} />
                <Route authorizedOnly exact path="/gift/:menuId/order/complete/:name" component={GiftComplete} />
                <Route authorizedOnly exact path="/gift/subs/:subsId" component={GiftSubsWithIamportloader} />
                <Route authorizedOnly exact path="/gift/subs/:subsId/complete/:name" component={GiftComplete} />
                <Route authorizedOnly exact path="/gift/get/:giftId" component={GiftGetDetail} />
                <Route authorizedOnly exact path="/gift/send/:giftId" component={GiftSendDetail} />

                <Route authorizedOnly exact path="/gift/subscribe/get/:giftId" component={GiftSubscribeGetDetail} />
                <Route authorizedOnly exact path="/gift/subscribe/send/:giftId" component={GiftSubscribeSendDetail} />

                <Route authorizedOnly path="/other" component={Other} />
                <Route authorizedOnly path="/withdraw" component={Withdraw} />

                <Route unAuthorizedOnly path="/signup" component={SignUp} />
                <Route path="/login" component={Login} />

                <Route exact path="/policy" component={Policy} />
                <Route path="/policy/service" component={PolicyService} />
                <Route path="/policy/privacy" component={PolicyPrivacy} />
                <Route path="/policy/location" component={PolicyLocation} />

                <Route exact path="/shops" render={() => <SelectStore appBarTitle="스토어 정보" disableSelect />} />
                <Route path="/notice" exact component={Notice} />
                <Route path="/notice/:noticeId" component={NoticeDetail} />
                <Route path="/notification/" component={Notification} />
                <Route path="/event" exact component={EventPage} />
                <Route path="/event/:eventId" exact component={EventDetail} />

                <Route path="/admin/login" component={AdminLogin} />
                <Route path="/admin" component={AdminLayout} />

                <Route path="/pos" exact render={() => <Redirect to="/pos/login" />} />
                <Route path="/pos/login" exact component={PosLogin} />
                <Route path="/pos/list" exact component={PosList} />
                <Route path="/pos/:posId" component={PosLayout} />
            </Switch>
        </BrowserRouter>
    );
}
