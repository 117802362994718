import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useParams } from "react-router";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import IconCheckboxChecked from "@/assets/img/ic_checkbox_checked.svg";
import {
    AvailableMenuContainer,
    AvailableMenuContentContainer,
    AvailableMenuTitleContainer,
    BillingTitle,
    BillingWrapper,
    CheckboxIconImg,
    CoffeeMenuItems,
    ContentWrapper,
    DateText,
    DateTextTitle,
    DateTextWrapper,
    DescriptionContainer,
    DescriptionRow,
    GiftOrderInfoNumber,
    GiftOrderInfoStatusText,
    GiftOrderInfoTextDivider,
    GiftOrderInfoTitle,
    GiftOrderInfoWrapper,
    InfoTitle,
    InfoTitleTo,
    InfoWrapper,
    OptionButtonContainer,
    PriceItem,
    PriceItemDivider,
    PriceItemPrice,
    PriceItemTitle,
    PriceItemWrapper,
    Title,
    TitleContainer,
    TotalPriceText,
    TotalPriceTextUnit,
    TotalPriceTitle,
    TotalPriceWrapper,
    Wrapper,
} from "./style";
import { getGiftSubscribeLogDetailInfo } from "@/redux/giftLog/selectors";
import { giftSubscribeLogDetailRequestThunk } from "@/redux/giftLog/thunks";
import { numberWithCommas } from "@/utils/number";
import { reset } from "@/redux/giftLog/actions";

export default function GiftSubscribeSendDetail() {
    const dispatch = useDispatch();
    const { giftId } = useParams<{ giftId: string }>();
    const detailInfo = useSelector(getGiftSubscribeLogDetailInfo);

    const availableMenuList = useMemo(() => {
        if (!detailInfo) {
            return [];
        }

        if (detailInfo.subscription.id === 1) {
            return ["아메리카노"];
        }
        if (detailInfo.subscription.id === 2) {
            return ["모든 제조음료"];
        }

        return [];
    }, [giftId, detailInfo]);

    useEffect(() => {
        if (!giftId) {
            return;
        }
        dispatch(giftSubscribeLogDetailRequestThunk(Number(giftId)));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <AppLayout
            disableAppBarBorder={true}
            title="보낸 선물"
            titleColor="#fff"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            fullHeight
        >
            {detailInfo && (
                <Wrapper>
                    <ContentWrapper>
                        <DateTextWrapper>
                            <DateTextTitle>주문일</DateTextTitle>
                            <DateText>{format(new Date(detailInfo.created_datetime), "yyyy.MM.dd hh:ss")}</DateText>
                        </DateTextWrapper>

                        <InfoWrapper>
                            <InfoTitle>
                                <InfoTitleTo>To.</InfoTitleTo> {detailInfo.gift_given_name}님
                            </InfoTitle>

                            <CoffeeMenuItems>
                                <OptionButtonContainer>
                                    <TitleContainer>
                                        <Title>{detailInfo.subscription.name}</Title>
                                        {/* <Price>1주 {comma(price)}원</Price> */}
                                    </TitleContainer>
                                    <AvailableMenuContainer>
                                        <AvailableMenuTitleContainer>이용 가능한 메뉴</AvailableMenuTitleContainer>
                                        <AvailableMenuContentContainer>
                                            {availableMenuList.join(", ")}
                                        </AvailableMenuContentContainer>
                                    </AvailableMenuContainer>

                                    <DescriptionContainer>
                                        {[
                                            "5일권은 평일 기준 5일이며, 주말은 자유롭게 사용하세요. (총 7일 서비스 제공)",
                                            "연차 홀딩 가능 (5일권 - 최대 1일 / 30일권 - 최대 4일)",
                                            "법정 공휴일 자동 추가 연장",
                                            "매장 운영 종료 시 사용불가",
                                        ].map((description, i) => (
                                            <DescriptionRow key={i}>
                                                <CheckboxIconImg src={IconCheckboxChecked} />
                                                {description}
                                            </DescriptionRow>
                                        ))}
                                    </DescriptionContainer>
                                </OptionButtonContainer>
                            </CoffeeMenuItems>

                            <GiftOrderInfoWrapper>
                                <GiftOrderInfoTitle>주문번호</GiftOrderInfoTitle>
                                <GiftOrderInfoNumber>{detailInfo.id}</GiftOrderInfoNumber>
                                <GiftOrderInfoTextDivider />
                                <GiftOrderInfoStatusText>
                                    {detailInfo.used_datetime ? "사용완료" : "미사용"}
                                </GiftOrderInfoStatusText>
                            </GiftOrderInfoWrapper>
                        </InfoWrapper>
                    </ContentWrapper>

                    <BillingWrapper>
                        <BillingTitle>결제정보</BillingTitle>

                        <PriceItemWrapper>
                            <PriceItem>
                                <PriceItemTitle>상품 금액</PriceItemTitle>
                                <PriceItemPrice>{numberWithCommas(detailInfo.subscription.price)}원</PriceItemPrice>
                            </PriceItem>
                            <PriceItem>
                                <PriceItemTitle>포인트 사용</PriceItemTitle>
                                <PriceItemPrice>
                                    -
                                    {numberWithCommas(
                                        Math.abs(
                                            detailInfo.transaction?.point_log?.amount || detailInfo.subscription.price,
                                        ),
                                    )}
                                    원
                                </PriceItemPrice>
                            </PriceItem>
                        </PriceItemWrapper>
                        <PriceItemDivider />

                        <TotalPriceWrapper>
                            <TotalPriceTitle>최종 결제금액</TotalPriceTitle>
                            <TotalPriceText>
                                {numberWithCommas(
                                    detailInfo.subscription.price -
                                        Math.abs(
                                            detailInfo.transaction?.point_log?.amount || detailInfo.subscription.price,
                                        ),
                                )}
                                <TotalPriceTextUnit>원</TotalPriceTextUnit>
                            </TotalPriceText>
                        </TotalPriceWrapper>
                    </BillingWrapper>
                </Wrapper>
            )}
        </AppLayout>
    );
}
