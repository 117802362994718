import { SUBSCRIPTION_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
    getSubscribeStatus,
    getSubscribeStatusFetchState,
    getSubscriptionList,
    getSubscriptionListFetchState,
    getSubscribeFetchState,
    getMisc,
    getMiscFetchState,
    getUnSubscribeFetchState,
    getHoldSubscriptionFetchState,
} = makeSubStateSelectorMap("subscription", SUBSCRIPTION_STATE_KEY_LIST);
