import React from "react";
import moment from "moment";
import "moment/locale/ko";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Dropdown, Empty, Form, Input, Menu, message, Modal, Row, Select } from "antd";
import {
    Gender,
    SubscribeStatusResponse,
    SubscriptionResponse,
    UserOrderByColumn,
    UserResponse,
    UserType,
} from "@ferda/rest-api-client";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";
import { genderToText, userOrderByColumnToText } from "@/utils/enumToText";

import PostTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import FormItem from "@/components/Admin/FormItem";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import { Clear, Left, Right } from "@/components/Admin/Float";
import { TotalCountWrapper } from "@/components/Admin/Misc";

const { RangePicker } = DatePicker;
const { Option } = Select;

const SubscriptionContainer = styled.div``;

const SubscriptionHeader = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const SubscriptionTitle = styled.div`
    flex-grow: 1;
    margin-right: 0.5rem;
    font-weight: bold;
`;

const SubscriptionPrice = styled.div``;

const SubscriptionDate = styled.div`
    display: flex;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
`;

const SubscriptionDateText = styled.div`
    flex-grow: 1;
`;

const SubscriptionDateTime = styled.div`
    text-align: right;
`;

interface Props {}

export default function Page(props: Props) {
    const { search } = useLocation();
    const searchQuery = new URLSearchParams(search);

    const { serviceApi, userApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [csvLoading, setCSVLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalUser, setModalUser] = React.useState<UserResponse | undefined>();
    const [modalSubscription, setModalSubscription] = React.useState<SubscriptionResponse | undefined>();
    const [modalSubscriptionStatus, setModalSubscriptionStatus] = React.useState<SubscribeStatusResponse | undefined>();
    const [modalSubscriptionList, setModalSubscriptionList] = React.useState<SubscriptionResponse[]>([]);

    const [userList, setUserList] = React.useState<UserResponse[]>([]);
    const [userListTotal, setUserListTotal] = React.useState<number>(0);

    const [isSubscriberOnly, setIsSubscriberOnly] = React.useState<boolean | undefined>();
    const [minCreatedDate, setMinCreatedDate] = React.useState<Date | undefined>();
    const [maxCreatedDate, setMaxCreatedDate] = React.useState<Date | undefined>();
    const [name, setName] = React.useState<string | undefined>(searchQuery.get("name") || undefined);
    const [username, setUsername] = React.useState<string | undefined>(searchQuery.get("username") || undefined);
    const [phone, setPhone] = React.useState<string | undefined>();
    const [gender, setGender] = React.useState<Gender | undefined>();
    const [email, setEmail] = React.useState<string | undefined>();
    const [isDeleted, setIsDeleted] = React.useState<boolean | undefined>();
    const [order, setOrder] = React.useState<UserOrderByColumn | undefined>();
    const [page, setPage] = React.useState<number>(1);

    const [searchForm] = Form.useForm();
    const [editForm] = Form.useForm();
    const [phoneForm] = Form.useForm();
    const [subscriptionTimeForm] = Form.useForm();
    const [subscriptionUpdateForm] = Form.useForm();
    const [adminForm] = Form.useForm();
    const [passwordForm] = Form.useForm();

    const columns = [
        {
            title: "가입일자",
            key: "createdDatetime",
            render: (record: UserResponse) => {
                const time = moment(record.created_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "탈퇴일자",
            key: "deletedDatetime",
            render: (record: UserResponse) => {
                if (!record.deleted_datetime) {
                    return { children: undefined };
                }

                const time = moment(record.deleted_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "휴대폰번호",
            key: "phone",
            render: (record: UserResponse) => {
                return {
                    children: (
                        <>
                            <span>{record.detail?.phone_number}</span>
                            {record.detail?.phone_number && (
                                <Button
                                    onClick={() => {
                                        setModal("phone");
                                        setModalUser(record);
                                        phoneForm.setFieldsValue({ phone: record.detail?.phone_number });
                                    }}
                                    style={{ marginLeft: "0.5rem" }}
                                >
                                    변경
                                </Button>
                            )}
                        </>
                    ),
                };
            },
            width: "200px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.name,
                };
            },
            width: "115px",
        },
        {
            title: "아이디",
            key: "username",
            render: (record: UserResponse) => {
                return {
                    children: record.username,
                };
            },
            width: "115px",
        },
        {
            title: "성별",
            key: "gender",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.gender && genderToText(record.detail?.gender),
                };
            },
            width: "75px",
        },
        {
            title: "나이",
            key: "birthDate",
            render: (record: UserResponse) => {
                if (!record.detail?.birth_date) {
                    return { children: undefined };
                }
                const time = moment(record.detail?.birth_date);
                time.locale("ko-kr");
                return {
                    children: <>{2024 - time.year()}살</>,
                };
            },
            width: "75px",
        },
        {
            title: "직종",
            key: "job",
            render: (record: UserResponse) => {
                if (!record.detail?.job) {
                    return { children: undefined };
                }
                return {
                    children: record.detail?.job,
                };
            },
            width: "120px",
        },
        {
            title: "이메일",
            key: "email",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.email,
                };
            },
            width: "90px",
        },
        {
            title: "구독권 (횟수)",
            key: "subscription",
            render: (record: UserResponse) => {
                if (!record.subscription_in_use) {
                    return {
                        children: "",
                    };
                }
                return {
                    children: (
                        <>
                            {record.subscription_in_use?.name} ({record.subscription_count}회)
                        </>
                    ),
                };
            },
            width: "150px",
        },
        {
            title: "관리",
            key: "manage",
            render: (record: UserResponse) => {
                return {
                    children: (
                        <>
                            <Button
                                disabled={loading || !!record.deleted_datetime}
                                onClick={() => {
                                    setModal("edit");
                                    setModalUser(record);

                                    const birthday = record.detail?.birth_date
                                        ? moment(record.detail?.birth_date)
                                        : null;

                                    editForm.setFieldsValue({
                                        name: record.detail?.name,
                                        phone: record.detail?.phone_number,
                                        gender: record.detail?.gender,
                                        birthday,
                                        email: record.detail?.email,
                                    });
                                }}
                                style={{ marginRight: "0.5rem" }}
                            >
                                수정
                            </Button>
                            <Button
                                disabled={loading}
                                onClick={() => {
                                    setModal("password");
                                    setModalUser(record);
                                    passwordForm.resetFields();
                                }}
                                style={{ marginRight: "0.5rem" }}
                            >
                                비밀번호 변경
                            </Button>
                            <Button
                                disabled={loading || !!record.deleted_datetime}
                                danger
                                onClick={() => {
                                    setModal("delete");
                                    setModalUser(record);
                                }}
                            >
                                탈퇴 처리
                            </Button>
                        </>
                    ),
                };
            },
            width: "310px",
        },
        {
            title: "목록",
            key: "list",
            render: (record: UserResponse) => {
                return {
                    children: (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key="point">
                                        <Link to={`/admin/pointlogs/${record.id}`}>포인트 내역</Link>
                                    </Menu.Item>
                                    <Menu.Item key="gifts">
                                        <Link to={`/admin/gifts/${record.id}`}>선물 내역</Link>
                                    </Menu.Item>
                                    <Menu.Item key="order">
                                        <Link to={`/admin/orders?userId=${record.id}`}>주문 내역</Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        key="subscription"
                                        onClick={() => {
                                            setModal("subscription");
                                            setModalUser(record);
                                            setModalSubscriptionStatus(undefined);

                                            setLoading(true);
                                            serviceApi
                                                .getSubscribeStatus(record.username)
                                                .then((res) => {
                                                    setLoading(false);
                                                    setModalSubscriptionStatus(res.data);
                                                })
                                                .catch(() => {
                                                    setLoading(false);
                                                    message.error("구독권 정보를 불러오는데 실패하였습니다", 1.5);
                                                });
                                        }}
                                    >
                                        구독상태
                                    </Menu.Item>
                                </Menu>
                            }
                            placement="bottomRight"
                        >
                            <Button>관리</Button>
                        </Dropdown>
                    ),
                };
            },
            width: "90px",
        },
    ];

    const subscriptionColumn = [
        {
            title: "이름",
            key: "name",
            render: (record: SubscriptionResponse) => {
                return {
                    children: record.name,
                };
            },
            width: "200px",
        },
        {
            title: "가격",
            key: "price",
            render: (record: SubscriptionResponse) => {
                return {
                    children: record.price.toLocaleString("ko") + "원",
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: SubscriptionResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setModalSubscription(record);
                                setModal("subscription-gift-time");
                            }}
                        >
                            선물
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    React.useEffect(() => {
        setLoading(true);
        serviceApi
            .getSubscriptions()
            .then((res) => {
                setLoading(false);
                setModalSubscriptionList(res.data);
            })
            .catch(() => {
                setLoading(false);
                message.error("구독권 정보를 불러오는데 실패하였습니다.", 1.5);
            });
    }, []);

    React.useEffect(() => {
        setLoading(true);
        userApi
            .getUsers(
                isSubscriberOnly,
                minCreatedDate && moment(minCreatedDate).format("YYYY-MM-DD"),
                maxCreatedDate && moment(maxCreatedDate).format("YYYY-MM-DD"),
                [UserType.USER],
                undefined,
                undefined,
                undefined,
                username,
                name,
                phone,
                gender,
                email,
                isDeleted,
                order,
                page,
            )
            .then((res) => {
                setLoading(false);
                setUserList(res.data);
                setUserListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("회원 목록을 불러오는데 실패하였습니다.");
            });
    }, [
        minCreatedDate,
        maxCreatedDate,
        name,
        username,
        phone,
        gender,
        email,
        isDeleted,
        order,
        page,
        rerender,
        isSubscriberOnly,
    ]);

    return (
        <>
            <PostTitle>회원 관리</PostTitle>
            <Container>
                <Form
                    form={searchForm}
                    onFinish={(values) => {
                        let isSubscriberOnly: boolean | undefined;
                        const minCreatedDate = values.date && values.date[0] ? values.date[0].toDate() : undefined;
                        const maxCreatedDate = values.date && values.date[1] ? values.date[1].toDate() : undefined;
                        const name = values.name || undefined;
                        const username = values.username || undefined;
                        const phone = values.phone || undefined;
                        const gender = values.gender || undefined;
                        const email = values.email || undefined;
                        let isDeleted: boolean | undefined;
                        const order = values.order || undefined;

                        switch (values.isSubscriberOnly) {
                            case "false":
                                isSubscriberOnly = false;
                                break;
                            case "true":
                                isSubscriberOnly = true;
                                break;
                            default:
                                isSubscriberOnly = undefined;
                        }
                        switch (values.isDeleted) {
                            case "false":
                                isDeleted = false;
                                break;
                            case "true":
                                isDeleted = true;
                                break;
                            default:
                                isDeleted = undefined;
                        }

                        setIsSubscriberOnly(isSubscriberOnly);
                        setMinCreatedDate(minCreatedDate);
                        setMaxCreatedDate(maxCreatedDate);
                        setName(name);
                        setUsername(username);
                        setPhone(phone);
                        setGender(gender);
                        setEmail(email);
                        setIsDeleted(isDeleted);
                        setOrder(order);
                        setPage(1);
                    }}
                >
                    <Left>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="date">
                                    <RangePicker
                                        disabled={loading}
                                        placeholder={["가입 시작 일자", "가입 끝 일자"]}
                                        style={{ width: "230px" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="name">
                                    <Input disabled={loading} placeholder="이름" style={{ width: "120px" }} />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="username">
                                    <Input disabled={loading} placeholder="아이디" style={{ width: "120px" }} />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="phone">
                                    <Input disabled={loading} placeholder="휴대폰번호" style={{ width: "110px" }} />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="gender">
                                    <Select disabled={loading} placeholder="성별" style={{ width: "70px" }}>
                                        <Option value={Gender.MALE}>{genderToText(Gender.MALE)}</Option>
                                        <Option value={Gender.FEMALE}>{genderToText(Gender.FEMALE)}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="email">
                                    <Input disabled={loading} placeholder="이메일" style={{ width: "120px" }} />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="isDeleted">
                                    <Select disabled={loading} placeholder="탈퇴 여부" style={{ width: "100px" }}>
                                        <Option value="true">여</Option>
                                        <Option value="false">부</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="order">
                                    <Select disabled={loading} placeholder="정렬" style={{ width: "150px" }}>
                                        <Option value={UserOrderByColumn.CREATED_DATETIME_ASC}>
                                            {userOrderByColumnToText(UserOrderByColumn.CREATED_DATETIME_ASC)}
                                        </Option>
                                        <Option value={UserOrderByColumn.CREATED_DATETIME_DESC}>
                                            {userOrderByColumnToText(UserOrderByColumn.CREATED_DATETIME_DESC)}
                                        </Option>
                                        <Option value={UserOrderByColumn.NAME_ASC}>
                                            {userOrderByColumnToText(UserOrderByColumn.NAME_ASC)}
                                        </Option>
                                        <Option value={UserOrderByColumn.NAME_DESC}>
                                            {userOrderByColumnToText(UserOrderByColumn.NAME_DESC)}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="isSubscriberOnly">
                                    <Select disabled={loading} placeholder="구독자" style={{ width: "125px" }}>
                                        <Option value="true">구독자만 표시</Option>
                                        <Option value="false">전체 표시</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Left>
                    <Right>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        htmlType="reset"
                                        onClick={() => {
                                            searchForm.resetFields();

                                            setMinCreatedDate(undefined);
                                            setMaxCreatedDate(undefined);
                                            setName(undefined);
                                            setUsername(undefined);
                                            setPhone(undefined);
                                            setGender(undefined);
                                            setEmail(undefined);
                                            setIsDeleted(undefined);
                                            setOrder(undefined);
                                            setPage(1);
                                        }}
                                    >
                                        초기화
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button disabled={loading} htmlType="submit">
                                        검색
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        loading={csvLoading}
                                        onClick={() => {
                                            setLoading(true);
                                            setCSVLoading(true);
                                            userApi
                                                .downloadUsersCsv({ responseType: "blob" })
                                                .then((res) => {
                                                    setLoading(false);
                                                    setCSVLoading(false);
                                                    const url = URL.createObjectURL(
                                                        new Blob([res.data], { type: res.headers["content-type"] }),
                                                    );
                                                    const link = document.createElement("a");
                                                    link.href = url;
                                                    link.setAttribute("download", "회원.csv");
                                                    document.body.appendChild(link);
                                                    link.click();
                                                })
                                                .catch(() => {
                                                    setLoading(false);
                                                    setCSVLoading(false);
                                                    message.error("엑셀 파일을 불러오는데 실패하였습니다.");
                                                });
                                        }}
                                    >
                                        CSV 다운로드
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Right>
                    <Clear />
                </Form>
            </Container>
            <Container>
                <TotalCountWrapper>회원 수 : {userListTotal.toLocaleString("ko")}</TotalCountWrapper>
                <Table columns={columns} dataSource={userList} loading={loading} rowKey="id" />
                {userListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={userListTotal}
                    />
                )}
            </Container>
            <Modal
                footer={
                    <>
                        <Left>
                            <Button
                                danger
                                disabled={loading}
                                type="primary"
                                onClick={() => {
                                    setModal("admin");
                                    adminForm.resetFields();
                                }}
                            >
                                어드민으로 전환
                            </Button>
                        </Left>
                        <Right>
                            <Button
                                onClick={() => {
                                    setModal(undefined);
                                }}
                                style={{ marginRight: "0.5rem" }}
                            >
                                취소
                            </Button>
                            <Button
                                disabled={loading}
                                htmlType="submit"
                                form="editForm"
                                loading={loading}
                                type="primary"
                            >
                                수정
                            </Button>
                        </Right>
                        <Clear />
                    </>
                }
                onCancel={() => setModal(undefined)}
                title="정보 수정"
                visible={modal === "edit"}
                width={800}
            >
                <Form
                    form={editForm}
                    name="editForm"
                    onFinish={(values) => {
                        if (!modalUser) {
                            return;
                        }

                        if (!values.name) {
                            return message.error("이름을 입력해주세요", 1.5);
                        }

                        setLoading(true);
                        userApi
                            .updateUser(modalUser.id.toString(), {
                                name: values.name,
                                gender: values.gender,
                                birth_date: values.birthday ? values.birthday.format("YYYY-MM-DD") : undefined,
                                email: values.email,
                            })
                            .then(() => {
                                setModal(undefined);
                                setModalUser(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("수정하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="이름" name="name">
                        <Input disabled={loading} placeholder="이름" />
                    </FormItem>
                    <FormItem label="성별" name="gender">
                        <Select disabled={loading} placeholder="성별" style={{ width: "100%" }}>
                            <Option value={Gender.MALE}>{genderToText(Gender.MALE)}</Option>
                            <Option value={Gender.FEMALE}>{genderToText(Gender.FEMALE)}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="생일" name="birthday">
                        <DatePicker disabled={loading} placeholder="생일" style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem label="이메일" name="email">
                        <Input disabled={loading} placeholder="이메일" />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                }}
                okText="탈퇴"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!modalUser) {
                        return;
                    }

                    setLoading(true);
                    userApi
                        .deleteUser(modalUser.id.toString())
                        .then(() => {
                            setModal(undefined);
                            setModalUser(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("탈퇴하였습니다.", 1.5);
                        })
                        .catch(() => {
                            setLoading(false);
                            message.error("탈퇴를 실패하였습니다.", 1.5);
                        });
                }}
                title="탈퇴"
                visible={modal === "delete"}
                width={800}
            >
                회원을 탈퇴하시겠습니까?
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    htmlType: "submit",
                    form: "phoneForm",
                }}
                okText="변경"
                onCancel={() => {
                    setModal(undefined);
                    setModalSubscriptionStatus(undefined);
                }}
                title="휴대폰번호 변경"
                visible={modal === "phone"}
                width={800}
            >
                <Form
                    form={phoneForm}
                    name="phoneForm"
                    onFinish={(values) => {
                        if (!modalUser) {
                            return;
                        }
                        if (!modalUser.detail?.phone_number) {
                            return;
                        }
                        if (!values.phone) {
                            return message.error("휴대폰번호를 입력해주세요.", 1.5);
                        }

                        setLoading(true);
                        userApi
                            .changePhoneNumber(modalUser.id.toString(), { phone_number: values.phone })
                            .then(() => {
                                setModal(undefined);
                                setModalUser(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                editForm.resetFields();
                                message.success("수정하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="휴대폰번호" name="phone">
                        <Input disabled={loading} placeholder="휴대폰번호" />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                footer={
                    <>
                        {modalSubscriptionStatus && modalSubscriptionStatus.subscription_in_use && (
                            <Left>
                                <Button
                                    danger
                                    onClick={() => {
                                        if (!modalUser) {
                                            return message.error("회원을 선택해주세요.", 1.5);
                                        }

                                        setLoading(true);
                                        serviceApi
                                            .deleteUserSubscription(modalUser.id.toString())
                                            .then(() => {
                                                setModal(undefined);
                                                setModalUser(undefined);
                                                setLoading(false);
                                                setRerender(!rerender);
                                                message.success("구독권을 취소하였습니다.", 1.5);
                                            })
                                            .catch((error) => {
                                                setLoading(false);
                                                message.error(error.response.data.detail, 1.5);
                                            });
                                    }}
                                    style={{ marginRight: "0.5rem" }}
                                    type="primary"
                                >
                                    구독권 취소
                                </Button>
                                <Button onClick={() => setModal("subscription-update")}>구독권 만료기간 변경</Button>
                            </Left>
                        )}
                        <Right>
                            <Button
                                onClick={() => {
                                    setModal(undefined);
                                    setModalSubscriptionStatus(undefined);
                                }}
                                style={{ marginRight: "0.5rem" }}
                            >
                                취소
                            </Button>
                            <Button onClick={() => setModal("subscription-gift")} type="primary">
                                구독권 선물
                            </Button>
                        </Right>
                        <Clear />
                    </>
                }
                onCancel={() => {
                    setModal(undefined);
                    setModalSubscriptionStatus(undefined);
                }}
                title="구독권 사용 여부"
                visible={modal === "subscription"}
                width={600}
            >
                {modalSubscriptionStatus && (
                    <SubscriptionContainer>
                        {modalSubscriptionStatus.subscription_in_use ? (
                            <>
                                <SubscriptionHeader>
                                    <SubscriptionTitle>
                                        {modalSubscriptionStatus.subscription_in_use.name}
                                    </SubscriptionTitle>
                                    <SubscriptionPrice>
                                        {modalSubscriptionStatus.subscription_in_use.price.toLocaleString("ko")}원
                                    </SubscriptionPrice>
                                </SubscriptionHeader>
                                <SubscriptionDate>
                                    <SubscriptionDateText>만료시각</SubscriptionDateText>
                                    <SubscriptionDateTime>
                                        {moment(modalSubscriptionStatus.expire_datetime)
                                            .locale("ko")
                                            .format("YY. MM. DD. (dd) A hh:mm:ss")}
                                    </SubscriptionDateTime>
                                </SubscriptionDate>
                                <SubscriptionDate>
                                    <SubscriptionDateText>다음 주문 가능 시각</SubscriptionDateText>
                                    <SubscriptionDateTime>
                                        {moment(modalSubscriptionStatus.next_order_available_datetime)
                                            .locale("ko")
                                            .format("YY. MM. DD. (dd) A hh:mm:ss")}
                                    </SubscriptionDateTime>
                                </SubscriptionDate>
                            </>
                        ) : (
                            <Empty description="사용 중인 구독권이 없습니다." image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    </SubscriptionContainer>
                )}
            </Modal>
            <Modal
                okButtonProps={{ style: { display: "none" } }}
                onCancel={() => setModal("subscription")}
                title="구독권 선물"
                visible={modal === "subscription-gift"}
                width={600}
            >
                <Table columns={subscriptionColumn} dataSource={modalSubscriptionList} loading={loading} rowKey="id" />
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    form: "subscriptionTimeForm",
                    htmlType: "submit",
                }}
                okText="선물"
                onCancel={() => setModal("subscription-gift")}
                title="구독권 만료시각 설정"
                visible={modal === "subscription-gift-time"}
                width={400}
            >
                <Form
                    form={subscriptionTimeForm}
                    name="subscriptionTimeForm"
                    onFinish={(values) => {
                        if (!modalUser) {
                            return message.error("회원을 선택해주세요.", 1.5);
                        }
                        if (!modalSubscription) {
                            return message.error("구독권을 선택해주세요.", 1.5);
                        }
                        if (!values.date) {
                            return message.error("날짜를 선택해주세요.", 1.5);
                        }

                        setLoading(true);
                        serviceApi
                            .subscriptionSubscribe(modalSubscription.id, {
                                username_or_id_or_me: modalUser.username,
                                expire_datetime: `${values.date.format("YYYY-MM-DD")}T23:59:59.999+09:00`,
                            })
                            .then(() => {
                                setModal(undefined);
                                setModalUser(undefined);
                                setModalSubscriptionStatus(undefined);
                                setModalSubscription(undefined);
                                setLoading(false);
                                subscriptionTimeForm.resetFields();
                                message.success("구독권을 선물하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <Form.Item name="date">
                        <DatePicker
                            disabled={loading}
                            disabledDate={(date) => date.isBefore(moment().startOf("day"))}
                            placeholder="날짜"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    form: "subscriptionUpdateForm",
                    htmlType: "submit",
                }}
                okText="변경"
                onCancel={() => setModal("subscription-gift")}
                title="구독권 만료시각 변경"
                visible={modal === "subscription-update"}
                width={400}
            >
                <Form
                    form={subscriptionUpdateForm}
                    name="subscriptionUpdateForm"
                    onFinish={(values) => {
                        if (!modalUser) {
                            return message.error("회원을 선택해주세요.", 1.5);
                        }
                        if (!values.date) {
                            return message.error("날짜를 선택해주세요.", 1.5);
                        }

                        setLoading(true);
                        serviceApi
                            .updateSubscriptionExpireDatetime(modalUser.username, {
                                expire_datetime: `${values.date.format("YYYY-MM-DD")}T23:59:59.999+09:00`,
                            })
                            .then(() => {
                                setModal(undefined);
                                setModalUser(undefined);
                                setModalSubscriptionStatus(undefined);
                                setModalSubscription(undefined);
                                setLoading(false);
                                subscriptionTimeForm.resetFields();
                                message.success("구독권 기간을 변경하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <Form.Item name="date">
                        <DatePicker
                            disabled={loading}
                            disabledDate={(date) => date.isBefore(moment().startOf("day"))}
                            placeholder="날짜"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    form: "passwordForm",
                    htmlType: "submit",
                }}
                okText="변경"
                onCancel={() => setModal(undefined)}
                title="비밀번호 변경"
                visible={modal === "password"}
                width={800}
            >
                <Form
                    form={passwordForm}
                    name="passwordForm"
                    onFinish={(values) => {
                        if (!modalUser) {
                            return;
                        }

                        if (!values.passwordNew) {
                            return message.error("새 비밀번호를 입력해주세요", 1.5);
                        }

                        setLoading(true);
                        userApi
                            .changePassword(modalUser.id.toString(), { new_password: values.passwordNew })
                            .then(() => {
                                setModal(undefined);
                                setModalUser(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                editForm.resetFields();
                                message.success("변경하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="새 비밀번호" name="passwordNew">
                        <Input disabled={loading} placeholder="새 비밀번호" type="password" />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                    htmlType: "submit",
                    form: "adminForm",
                }}
                okText="변경"
                onCancel={() => {
                    setModal(undefined);
                    setModalSubscriptionStatus(undefined);
                }}
                title="어드민으로 변경"
                visible={modal === "admin"}
                width={800}
            >
                <Form
                    form={adminForm}
                    name="adminForm"
                    onFinish={(values) => {
                        if (!modalUser) {
                            return message.error("회원을 선택해주세요.", 1.5);
                        }
                        if (!values.username) {
                            return message.error("아이디를 입력해주세요", 1.5);
                        }
                        if (!values.password) {
                            return message.error("새 비밀번호를 입력해주세요", 1.5);
                        }

                        setLoading(true);
                        userApi
                            .updateUser(modalUser.id.toString(), {
                                username: values.username,
                                user_type: UserType.ADMIN,
                            })
                            .then(() => {
                                message.success("어드민으로 변경하였습니다.", 1.5);
                                userApi
                                    .changePassword(modalUser.id.toString(), { new_password: values.password })
                                    .then(() => {
                                        setModal(undefined);
                                        setModalUser(undefined);
                                        setLoading(false);
                                        adminForm.resetFields();
                                        message.success("비밀번호를 변경하였습니다.", 1.5);
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        message.error(error.response.data.detail, 1.5);
                                    });
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="아이디" name="username">
                        <Input disabled={loading} placeholder="아이디" />
                    </FormItem>
                    <FormItem label="비밀번호" name="password">
                        <Input disabled={loading} placeholder="비밀번호" type="password" />
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}
