import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const GiftTabTypeHeaderWrapper = styled.div`
    width: 100%;
    padding: 12px 20px;
    background-color: #ff831e;
`;

export const GiftTabTypeHeader = styled.div`
    width: 100%;
    border-radius: 8px;
    background-color: #2d2d2d;
`;

export interface GiftTabTypeHeaderButtonProps {
    selected: boolean;
}

export const GiftTabTypeHeaderButton = styled(Button)<GiftTabTypeHeaderButtonProps>`
    width: 50%;
    border-radius: 8px;
    background-color: #2d2d2d;
    color: #0c0c0c;

    ${({ selected }) =>
        selected &&
        css`
            background-color: #ff831e;
            color: #000000;

            &:hover {
                background-color: #ff831e;
                color: #000000;
            }
        `}
`;
