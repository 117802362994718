import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuOption, OrderResponse, OrderStatus } from "@ferda/rest-api-client";
import { format } from "date-fns";
import AppLayout from "@/containers/AppLayout";
import { BackButton } from "@/components/IconButton";
import {
    InfoWrapper,
    HistoryDetailRefreshButton,
    HistoryDetailTitle,
    HistoryDetailTopWrapper,
    HistoryDetailWrapper,
    InfoStoreTitle,
    InfoAddressWrapper,
    InfoAddressIcon,
    InfoAddressText,
    StatusWrapper,
    StatusItemWrapper,
    StatusTextWrapper,
    StatusTextCircle,
    StatusText,
    StatusDate,
    StatusBar,
    MenuWrapper,
    MenuName,
    MenuOptions,
} from "./style";
import RefreshIcon from "@/assets/img/btn_refresh_round.svg";
import AddressIcon from "@/assets/img/ic_Location_small.svg";
import { orderHistoryDetailRequestThunk } from "@/redux/history/thunks";
import { getOrderHistoryDetailInfo } from "@/redux/history/selectors";
import { getMenuCupTypeText } from "@/utils/menu";
import { reset } from "@/redux/history/actions";

type StatusType = "disabled" | "complete" | "inprogress";

export default function HistoryDetail() {
    const dispatch = useDispatch();
    const { historyId } = useParams<{ historyId: string }>();
    const detailItem = useSelector(getOrderHistoryDetailInfo);

    const isShowMakeInprogressDate = useMemo(() => {
        if (!detailItem?.order_status) {
            return false;
        }

        switch (detailItem.order_status) {
            case OrderStatus.ORDER_COMPLETED: {
                return false;
            }
            case OrderStatus.ORDER_CANCELED: {
                return false;
            }
            default: {
                return true;
            }
        }
    }, [detailItem?.order_status]);

    const isShowMakeCompletedDate = useMemo(() => {
        if (!detailItem?.order_status) {
            return false;
        }

        switch (detailItem.order_status) {
            case OrderStatus.MAKE_COMPLETED: {
                return true;
            }
            case OrderStatus.PICKUP_COMPLETED: {
                return true;
            }
            default: {
                return false;
            }
        }
    }, [detailItem?.order_status]);

    const getOrderCompletedStatus = (status: OrderStatus): StatusType => {
        switch (status) {
            case OrderStatus.ORDER_COMPLETED: {
                return "inprogress";
            }
            case OrderStatus.ORDER_CANCELED: {
                return "disabled";
            }
            default: {
                return "complete";
            }
        }
    };

    const getMakeInProgressStatus = (status: OrderStatus): StatusType => {
        switch (status) {
            case OrderStatus.ORDER_COMPLETED: {
                return "disabled";
            }
            case OrderStatus.MAKE_IN_PROGRESS: {
                return "inprogress";
            }
            case OrderStatus.ORDER_CANCELED: {
                return "disabled";
            }
            default: {
                return "complete";
            }
        }
    };

    const getMakeCompletedStatus = (status: OrderStatus): StatusType => {
        switch (status) {
            case OrderStatus.MAKE_COMPLETED: {
                return "inprogress";
            }
            case OrderStatus.PICKUP_COMPLETED: {
                return "complete";
            }
            default: {
                return "disabled";
            }
        }
    };

    const getOptionGroupText = (option: MenuOption[]) => {
        return option.map((v) => v.name).join(" / ");
    };

    const getStatusBarStatus = (status: OrderStatus): "order" | "complete" | "making" => {
        switch (status) {
            case OrderStatus.MAKE_IN_PROGRESS: {
                return "making";
            }
            case OrderStatus.MAKE_COMPLETED: {
                return "complete";
            }
            case OrderStatus.PICKUP_COMPLETED: {
                return "complete";
            }
            default: {
                return "order";
            }
        }
    };

    const handleClickRefresh = () => {
        dispatch(orderHistoryDetailRequestThunk(Number(historyId)));
    };

    const detailWrapper = ({
        display_id,
        shop: { name: shopName, address },
        menu: { name: menuName },
        order_status,
        created_datetime,
        make_in_progress_datetime,
        make_completed_datetime,
        temperature_type,
        cup_type,
        option_groups,
    }: OrderResponse) => {
        return (
            <HistoryDetailWrapper>
                <HistoryDetailTopWrapper>
                    <HistoryDetailTitle>주문번호 {display_id}</HistoryDetailTitle>
                    <HistoryDetailRefreshButton onClick={handleClickRefresh} src={RefreshIcon} alt="refresh" />
                </HistoryDetailTopWrapper>

                <InfoWrapper>
                    <InfoStoreTitle>{shopName}</InfoStoreTitle>
                    <InfoAddressWrapper>
                        <InfoAddressIcon src={AddressIcon} alt="address icon" />
                        <InfoAddressText>{address}</InfoAddressText>
                    </InfoAddressWrapper>

                    <StatusWrapper>
                        <StatusBar status={getStatusBarStatus(order_status)} />
                        <StatusItemWrapper>
                            <StatusTextWrapper>
                                <StatusTextCircle status={getOrderCompletedStatus(order_status)} />
                                <StatusText active={order_status === OrderStatus.ORDER_COMPLETED}>주문 완료</StatusText>
                            </StatusTextWrapper>

                            <StatusDate>
                                {order_status !== OrderStatus.ORDER_CANCELED &&
                                    format(new Date(created_datetime), "yyyy.MM.dd HH:mm")}
                            </StatusDate>
                        </StatusItemWrapper>
                        <StatusItemWrapper>
                            <StatusTextWrapper>
                                <StatusTextCircle status={getMakeInProgressStatus(order_status)} />
                                <StatusText active={order_status === OrderStatus.MAKE_IN_PROGRESS}>제조중</StatusText>
                            </StatusTextWrapper>

                            <StatusDate>
                                {isShowMakeInprogressDate &&
                                    make_in_progress_datetime &&
                                    format(new Date(make_in_progress_datetime), "yyyy.MM.dd HH:mm")}
                            </StatusDate>
                        </StatusItemWrapper>
                        <StatusItemWrapper>
                            <StatusTextWrapper>
                                <StatusTextCircle status={getMakeCompletedStatus(order_status)} />
                                <StatusText active={order_status === OrderStatus.MAKE_COMPLETED}>제조 완료</StatusText>
                            </StatusTextWrapper>

                            <StatusDate>
                                {isShowMakeCompletedDate &&
                                    make_completed_datetime &&
                                    format(new Date(make_completed_datetime), "yyyy.MM.dd HH:mm")}
                            </StatusDate>
                        </StatusItemWrapper>
                    </StatusWrapper>
                </InfoWrapper>

                <MenuWrapper>
                    <MenuName>{menuName}</MenuName>
                    <MenuOptions>
                        {temperature_type && `${temperature_type} / `} {getMenuCupTypeText(cup_type)}
                    </MenuOptions>
                    {option_groups &&
                        option_groups.map((option) => {
                            return (
                                <MenuOptions>
                                    {option.name} : {getOptionGroupText(option.options)}
                                </MenuOptions>
                            );
                        })}
                </MenuWrapper>
            </HistoryDetailWrapper>
        );
    };

    useEffect(() => {
        dispatch(orderHistoryDetailRequestThunk(Number(historyId)));

        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <AppLayout title="히스토리 상세" appBarBackgroundColor="#ff831e" leadingIcon={<BackButton />} titleColor="#fff">
            {detailItem && detailWrapper(detailItem)}
        </AppLayout>
    );
}
