import { LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "sanitize.css";
import { Global } from "@emotion/react";
import { getAuthState } from "./redux/auth/selectors";
import { loadAuthStateAndApiClientThunk } from "./redux/auth/thunk";
import { Router } from "./Router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import theme from "./theme";
import "antd/dist/antd.css";
import "./styles/antd.css";
import "./styles/global.css";
import reset from "./styles/reset";
import LoadingPage from "./components/LoadingPage";
import Modal from "./components/Modal";
import {
    FinishModalTitle,
    PermissionModalContent,
    PermissionModalContentTitle,
    PermissionModalContentWrapper,
    PermissionModalGuideText,
    PermissionModalTitle,
    PermissionModalWrapper,
} from "./styles/permissionModal";
import useHistory from "./hooks/useCustomHistory";
import Snackbar from "@/components/Snackbar";

declare global {
    interface Window {
        Kakao: any;
        showAllowAccessModal: () => void;
        showFinishModal: () => void;
        customHistory: ReturnType<typeof useHistory>;
        android: any;
        webkit: any;
    }
}

function App() {
    const dispatch = useDispatch();
    const authState = useSelector(getAuthState);
    const [permissionModal, setPermissionModal] = useState(false);
    const [finishModal, setFinishModal] = useState(false);
    const history = useHistory();

    const handleClickPermissionModalButton = () => {
        setPermissionModal(false);
        window.android.allowAccess();
    };

    const handleClickFinishModalButton = () => {
        window.android.finish();
    };

    useEffect(() => {
        window.showAllowAccessModal = () => setPermissionModal(true);
        window.showFinishModal = () => setFinishModal(true);
        window.customHistory = history;
        dispatch(loadAuthStateAndApiClientThunk());
    }, [dispatch]);

    return (
        <div className="App">
            <header className="App-header">
                <Modal
                    open={finishModal}
                    handleClose={() => false}
                    buttonText="확인"
                    cancelButtonText="취소"
                    fullSizeButton
                    wrapperPadding="16px 16px 0 16px"
                    buttonOnClick={handleClickFinishModalButton}
                    cancelButtonOnClick={() => setFinishModal(false)}
                >
                    <FinishModalTitle>앱을 종료하시겠습니까?</FinishModalTitle>
                </Modal>
                <Modal
                    open={permissionModal}
                    handleClose={() => false}
                    titleText={
                        <>
                            워크무드 앱 이용을 위해
                            <br />
                            접근권한 허용이 필요합니다
                        </>
                    }
                    buttonText="확인"
                    fullSizeButton
                    buttonOnClick={handleClickPermissionModalButton}
                >
                    <PermissionModalWrapper>
                        <PermissionModalTitle>선택적 접근권한</PermissionModalTitle>
                        <PermissionModalContentWrapper>
                            <PermissionModalContentTitle>위치</PermissionModalContentTitle>
                            <PermissionModalContent>가까운 매장 검색</PermissionModalContent>
                        </PermissionModalContentWrapper>

                        <PermissionModalGuideText>
                            * 위 접근권한은 고객님께 더 나은 서비스를 제공하기 위해 사용됩니다. 선택적 접근 권한은
                            승인하지 않으셔도 해당 기능을 제외하고 워크무드를 이용하실 수 있습니다.
                        </PermissionModalGuideText>
                    </PermissionModalWrapper>
                </Modal>
                <Global styles={reset} />
                <ThemeProvider theme={theme}>
                    <SnackbarProvider>
                        <Snackbar />
                        <LocalizationProvider dateAdapter={DateAdapter}>
                            {authState.type === "UNLOADED" ? <LoadingPage /> : <Router />}
                        </LocalizationProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </header>
        </div>
    );
}

export default App;
