import styled from "@emotion/styled";

export const Wrapper = styled.div`
    padding-top: 34px;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
`;

export const ContentWrapper = styled.div`
    padding: 0 20px;
    margin-bottom: 32px;
`;

export const DateTextWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const DateTextTitle = styled.p`
    font-size: 14px;
    color: #8f8f8f;
    margin-right: 16px;
`;

export const DateText = styled.p`
    font-size: 14px;
    color: #e2e2e2;
`;

export const InfoWrapper = styled.div`
    padding: 20px;
    background-color: #ff831e;
    border-radius: 8px;
`;

export const InfoTitle = styled.p`
    font-size: 14px;
    color: #e2e2e2;
    padding-bottom: 12px;
`;

export const InfoTitleTo = styled.span`
    font-weight: bold;
    color: #ff831e;
`;

export const CoffeeMenuItems = styled.div`
    display: flex;
    align-items: stretch;
    padding: 16px 0;
    margin-bottom: 20px;
`;

export const CoffeeImage = styled.div`
    min-width: 95px;
    width: 95px;
    height: 95px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 12px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const CoffeeMenuItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CoffeeTitleWrapper = styled.div``;

export const CoffeeTitle = styled.p`
    font-size: 14px;
    color: #e2e2e2;
    margin-bottom: 4px;
`;

export const CoffeeOption = styled.p`
    font-size: 14px;
    color: #aaa;
`;

export const CoffeePrice = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #ff831e;
`;

export const CoffeePriceUnit = styled.span`
    font-size: 16px;
    font-weight: normal;
`;

export const GiftOrderInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    background-color: #fff;
    border-radius: 4px;
`;

export const GiftOrderInfoTitle = styled.p`
    font-size: 14px;
    color: #8f8f8f;
    margin-right: 8px;
`;

export const GiftOrderInfoTextDivider = styled.div`
    width: 1px;
    height: 12px;
    background-color: #2d2d2d;
    margin: 0 8px;
`;

export const GiftOrderInfoStatusText = styled.p`
    font-size: 14px;
    color: #ff831e;
`;

export const GiftOrderInfoNumber = styled.p`
    font-size: 14px;
    color: #e2e2e2;
`;

export const BillingWrapper = styled.div`
    flex-grow: 1;
    background-color: #ff831e;
    padding: 32px 20px 0 20px;
`;

export const BillingTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #ff831e;
    margin-bottom: 16px;
`;

export const PriceItemWrapper = styled.div``;

export const PriceItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }
`;

export const PriceItemTitle = styled.p`
    font-size: 14px;
    color: #8f8f8f;
`;

export const PriceItemPrice = styled.p`
    font-size: 14px;
    color: #ff831e;
`;

export const PriceItemDivider = styled.div`
    height: 1px;
    background-color: #8f8f8f;
    margin: 12px 0;
`;

export const TotalPriceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TotalPriceTitle = styled.p`
    font-size: 16px;
    color: #ff831e;
`;

export const TotalPriceText = styled.p`
    font-size: 22px;
    font-weight: bold;
    line-height: 24px;
    color: #ff831e;
`;

export const TotalPriceTextUnit = styled.span`
    font-size: 14px;
    color: #ff831e;
    line-height: 24px;
    font-weight: normal;
`;

export const OptionButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    text-align: left;

    background-color: #ff831e;

    border-radius: 8px;

    border: 1px solid transparent;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;

    font-size: 16px;
    color: white;
`;

export const Title = styled.div``;

export const SubTitle = styled.span`
    color: #ff831e;
`;

export const Price = styled.div`
    font-weight: bold;
`;

export const AvailableMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 12px;
    padding: 14px;

    border: 1px solid rgba(255, 255, 255, 0.1);

    border-radius: 8px;
`;

export const AvailableMenuTitleContainer = styled.div`
    padding-left: 8px;
    padding-top: 2px;
    padding-right: 8px;
    padding-bottom: 2px;

    color: #e2e2e2;
    font-size: 12px;

    background-color: rgba(255, 255, 255, 0.1);

    border-radius: 4px;
`;

export const AvailableMenuContentContainer = styled.div`
    margin-top: 8px;

    color: #0c0c0c;
    font-size: 14px;
`;

export const CheckboxIconImg = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
`;

export const DescriptionContainer = styled.div`
    margin-top: 12px;

    color: #aaa;
    font-size: 14px;

    > div {
        margin-bottom: 8px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
`;

export const DescriptionRow = styled.div``;
