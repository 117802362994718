import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { Gender } from "@ferda/rest-api-client";
import { MobileDatePicker } from "@mui/lab";
import { inputBaseClasses } from "@mui/material";
import { useSnackbar } from "notistack";
import useHistory from "@/hooks/useCustomHistory";
import Link from "@/components/CustomLink";
import { BackButton } from "@/components/IconButton";
import InlineSelect from "@/components/InlineSelect";
import { getUserAsAlwaysExists } from "@/redux/auth/selectors";
import {
    TitleContainer,
    StyledCheckbox,
    StyledInputBase,
    Title,
    Paper,
    StyledAppLayout,
    EditButton,
    InputRow,
    InputTitle,
    InputValue,
    CheckAllCheckboxLabel,
    CheckboxDescription,
    StyledDivider,
    EditPhoneButton,
    DeleteModalContentWrapper,
    DeleteModalContentTitleText,
    DeleteModalContentSubtitleText,
    EditCancelButton,
} from "./style";
import { getDateIfExists } from "@/utils/date";
import { deleteUserRequestThunk, editUserInfoThunk } from "@/redux/user/thunk";
import { getDeleteUserFetchState, getEditUserInfoFetchState } from "@/redux/user/selectors";
import { BottomConfirmButtonContainer, ConfirmButton } from "@/components/Button/style";
import LinkButton from "@/components/LinkButton";
import Modal from "@/components/Modal";
import { resetUserState } from "@/redux/user/actions";
import { clearOauth2Token } from "@/redux/auth/utils";
import { setAuthType } from "@/redux/auth/actions";
import { loadAuthStateAndApiClientThunk } from "@/redux/auth/thunk";

interface EditMyInfoFormField {
    name?: string;
    birthDate?: Date;
    gender?: Gender;
    email?: string;
    smsTermAgree: boolean;
    emailTermAgree: boolean;
    noticeTermAgree: boolean;
}

function formatGender(gender: Gender) {
    switch (gender) {
        case Gender.MALE: {
            return "남자";
        }
        case Gender.FEMALE: {
            return "여자";
        }
    }
}

export default function EditMyInfo() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [editMode, setEditMode] = useState(false);
    const user = useSelector(getUserAsAlwaysExists);
    const editUserInfo = useSelector(getEditUserInfoFetchState);
    const [deleteModal, setDeleteModal] = useState(false);
    const deleteUserFetchState = useSelector(getDeleteUserFetchState);
    const { enqueueSnackbar } = useSnackbar();

    const { values, getFieldProps, setFieldValue, handleSubmit, resetForm } = useFormik<EditMyInfoFormField>({
        onSubmit: (args) => {
            dispatch(editUserInfoThunk(args));
        },
        initialValues: {
            name: user.detail?.name,
            birthDate: getDateIfExists(user.detail?.birth_date),
            gender: user.detail?.gender,
            email: user.detail?.email,
            smsTermAgree: !!user?.receive_sms_consented_datetime,
            emailTermAgree: !!user?.receive_email_consented_datetime,
            noticeTermAgree: !!user?.receive_sns_message_consented_datetime,
        },
    });

    const termAgreeChanged = useMemo(
        () =>
            values.smsTermAgree !== !!user?.receive_sms_consented_datetime ||
            values.emailTermAgree !== !!user?.receive_email_consented_datetime ||
            values.noticeTermAgree !== !!user?.receive_sns_message_consented_datetime,
        [values, user],
    );

    const termAllChecked = useMemo(
        () => values.emailTermAgree && values.noticeTermAgree && values.smsTermAgree,
        [values],
    );

    const toggleAllTerm = useCallback(() => {
        setFieldValue("smsTermAgree", !termAllChecked);
        setFieldValue("emailTermAgree", !termAllChecked);
        setFieldValue("noticeTermAgree", !termAllChecked);
    }, [termAllChecked]);

    const handleClickDeleteUser = () => {
        setDeleteModal(true);
        // history.push("/withdraw");
    };

    const handleClickDeleteUserPost = () => {
        dispatch(deleteUserRequestThunk(""));
    };

    useEffect(() => {
        if (editUserInfo !== "SUCCESS") {
            return;
        }

        dispatch(loadAuthStateAndApiClientThunk());
    }, [editUserInfo, history]);

    useEffect(() => {
        if (deleteUserFetchState === "FAILURE") {
            enqueueSnackbar("잠시 후 다시 시도 해주세요.", {
                variant: "error",
            });
        }
        if (deleteUserFetchState === "SUCCESS") {
            clearOauth2Token();
            dispatch(setAuthType("UNAUTHORIZED"));
            setDeleteModal(false);
            history.replace("/signup");
        }
    }, [deleteUserFetchState]);

    useEffect(() => {
        return () => {
            dispatch(resetUserState());
        };
    }, []);

    return (
        <StyledAppLayout
            title="정보수정"
            disableAppBarBorder
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            titleColor="#fff"
        >
            <Modal
                open={deleteModal}
                handleClose={() => setDeleteModal(false)}
                cancelButtonOnClick={() => setDeleteModal(false)}
                buttonOnClick={handleClickDeleteUserPost}
                cancelButtonText="취소"
                buttonText="회원탈퇴"
                titleText="회원탈퇴"
                fullSizeButton
            >
                <DeleteModalContentWrapper>
                    <DeleteModalContentTitleText>정말로 탈퇴 하시겠습니까?</DeleteModalContentTitleText>
                    <DeleteModalContentSubtitleText>
                        탈퇴 시, 정보가 삭제되며
                        <br /> 삭제된 데이터는 복구가 불가능 합니다.
                    </DeleteModalContentSubtitleText>
                </DeleteModalContentWrapper>
            </Modal>
            <form onSubmit={handleSubmit}>
                <TitleContainer>
                    <Title>회원 정보</Title>
                    {editMode ? (
                        <EditCancelButton
                            onClick={() => {
                                setEditMode(false);
                                resetForm();
                            }}
                        >
                            변경 취소하기
                        </EditCancelButton>
                    ) : (
                        <EditButton onClick={() => setEditMode(true)}>정보 변경하기</EditButton>
                    )}
                </TitleContainer>
                {editMode ? (
                    <Paper>
                        <InputRow>
                            <InputTitle>닉네임(이름)</InputTitle>
                            <StyledInputBase {...getFieldProps("name")} />
                        </InputRow>
                        <InputRow>
                            <InputTitle>생년월일</InputTitle>
                            <MobileDatePicker
                                renderInput={(args) => (
                                    <StyledInputBase
                                        ref={args.inputProps?.ref}
                                        inputProps={args.inputProps}
                                        autoFocus
                                        className={inputBaseClasses.input}
                                    />
                                )}
                                {...getFieldProps("birthDate")}
                                onChange={(newDate) => setFieldValue("birthDate", newDate)}
                            />
                        </InputRow>
                        <InputRow>
                            <InputTitle>E-mail</InputTitle>
                            <StyledInputBase {...getFieldProps("email")} />
                        </InputRow>
                        <InputRow>
                            <InputTitle style={{ width: "30px" }}>성별</InputTitle>
                            <InlineSelect
                                {...getFieldProps("gender")}
                                onChange={(newGender) => setFieldValue("gender", newGender)}
                                options={[
                                    { value: "MALE", label: "남자" },
                                    { value: "FEMALE", label: "여자" },
                                    { label: "선택안함" },
                                ]}
                            />
                        </InputRow>
                        <InputRow>
                            <InputTitle>휴대폰번호</InputTitle>
                            <Link to="/me/edit/phone">
                                <EditPhoneButton>휴대폰번호 수정</EditPhoneButton>
                            </Link>
                        </InputRow>
                    </Paper>
                ) : (
                    <Paper>
                        <InputRow>
                            <InputTitle>닉네임(이름)</InputTitle>
                            <InputValue>{user.detail?.name}</InputValue>
                        </InputRow>
                        <InputRow>
                            <InputTitle>생년월일</InputTitle>
                            <InputValue>{user.detail?.birth_date}</InputValue>
                        </InputRow>
                        <InputRow>
                            <InputTitle>E-mail</InputTitle>
                            <InputValue>{user.detail?.email}</InputValue>
                        </InputRow>
                        <InputRow>
                            <InputTitle>성별</InputTitle>
                            <InputValue>{user.detail?.gender && formatGender(user.detail?.gender)}</InputValue>
                        </InputRow>
                        <InputRow>
                            <InputTitle>휴대폰번호</InputTitle>
                            <InputValue>{user.detail?.phone_number}</InputValue>
                        </InputRow>
                    </Paper>
                )}

                <LinkButton sx={{ mb: "40px" }} href="/me/payment-method-settings" title="결제정보" />

                <Title>마케팅 정보 수신 여부</Title>
                <Paper>
                    <StyledCheckbox
                        iconType="circle"
                        label={<CheckAllCheckboxLabel>마케팅 수신동의</CheckAllCheckboxLabel>}
                        onClick={toggleAllTerm}
                        value={termAllChecked}
                    />
                    <CheckboxDescription>다양한 프로모션 소식과 정보를 알려드려요</CheckboxDescription>
                    <StyledDivider />
                    <StyledCheckbox
                        label="SMS/MMS/LMS 수신 동의"
                        {...getFieldProps("smsTermAgree")}
                        checked={values.smsTermAgree}
                    />
                    <StyledCheckbox
                        label="E-mail 수신 동의"
                        {...getFieldProps("emailTermAgree")}
                        checked={values.emailTermAgree}
                    />
                    <StyledCheckbox
                        label="알림톡 수신 동의"
                        {...getFieldProps("noticeTermAgree")}
                        checked={values.noticeTermAgree}
                    />
                </Paper>

                <LinkButton sx={{ mt: "40px" }} title="회원탈퇴" onClick={handleClickDeleteUser} />

                <BottomConfirmButtonContainer
                    style={{
                        backgroundColor: "transparent",
                        marginLeft: "-20px",
                    }}
                >
                    <ConfirmButton
                        variant="contained"
                        backgroundColor="#ff831e"
                        disabled={!(termAgreeChanged || editMode)}
                        disableRipple={!(termAgreeChanged || editMode)}
                        type="submit"
                    >
                        회원정보 변경
                    </ConfirmButton>
                </BottomConfirmButtonContainer>
            </form>
        </StyledAppLayout>
    );
}
