import { createSlice } from "@reduxjs/toolkit";
import { OrderResponse } from "@ferda/rest-api-client";
import { getKeyListFromState } from "@/utils/redux/state";
import { HistoryState } from "./types";
import { makeAsyncReducer, setValueReducerList } from "@/utils/redux/reducer";
import { ListFetchSuccessPayload } from "@/utils/redux/types";

const initialState: HistoryState = {
    orderHistoryList: null,
    orderHistoryListRequestFetchState: "READY",
    orderHistoryListPage: null,
    orderHistoryListTotalPage: null,

    orderHistoryDetailRequestFetchState: "READY",
    orderHistoryDetailInfo: null,
};

export const HISTORY_STATE_KEY_LIST = getKeyListFromState(initialState);

export const historySlice = createSlice({
    name: "history",
    initialState,
    reducers: {
        reset: () => initialState,
        ...makeAsyncReducer<"orderHistoryList", HistoryState, void, ListFetchSuccessPayload<OrderResponse>>(
            "orderHistoryList",
            {
                useListSuccessReducer: true,
            },
        ),
        ...makeAsyncReducer<"orderHistoryDetailRequest", HistoryState, void, OrderResponse>(
            "orderHistoryDetailRequest",
            {
                successReducer: (state, { payload }) => {
                    state.orderHistoryDetailInfo = payload;
                },
            },
        ),
        ...setValueReducerList<HistoryState, ["orderHistoryList", "orderHistoryListRequestFetchState"]>([
            "orderHistoryList",
            "orderHistoryListRequestFetchState",
        ]),
    },
});

export const historyReducer = historySlice.reducer;
