import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";
import { NotificationType } from "@ferda/rest-api-client";
import { KeyboardArrowDown } from "@mui/icons-material";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import NotificationCard from "./NotificationCard";
import {
    getNotificationListFetchState,
    getNotificationList,
    getNotificationListPage,
    getNotificationListTotalPage,
} from "@/redux/notification/selectors";
import { fetchMoreNotificationListThunk } from "@/redux/notification/thunk";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import BottomSheet from "@/components/BottomSheet";
import BottomSheetCategoryButton from "@/components/BottomSheetCategoryButton";
import { resetNotificationState } from "@/redux/notification/actions";

type NotificationTypeWithAll = NotificationType | "ALL";

function formatNotificationType(notiType: NotificationTypeWithAll) {
    switch (notiType) {
        case "ALL": {
            return "전체";
        }
        case NotificationType.AD: {
            return "광고";
        }
        case NotificationType.EVENT: {
            return "이벤트";
        }
        case NotificationType.GIFT: {
            return "선물";
        }
        case NotificationType.NOTICE: {
            return "공지";
        }
        case NotificationType.ORDER: {
            return "주문";
        }
    }
}

function getFilterList(notiType: NotificationTypeWithAll) {
    let filter: NotificationType[] = [];
    if (notiType !== "ALL") {
        filter = [notiType];
    }

    return filter;
}

const StyledAppLayout = styled(AppLayout)`
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-left: 20px;
    padding-bottom: 42px;
    padding-right: 20px;
`;

const FilterButton = styled(ButtonBase)`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    margin-bottom: 16px;

    font-size: 16px;

    border-radius: 8px;
    color: #0c0c0c;
    border: 1px solid #ff831e;
    background-color: #ffffff;
`;

const NOTIFICATION_FILTER_LIST = [
    "ALL",
    NotificationType.AD,
    NotificationType.EVENT,
    NotificationType.GIFT,
    NotificationType.NOTICE,
    NotificationType.ORDER,
] as const;

export default function Notification() {
    const dispatch = useDispatch();
    const notificationListFetchState = useSelector(getNotificationListFetchState);
    const notificationList = useSelector(getNotificationList);
    const notificationListPage = useSelector(getNotificationListPage);
    const notificationListTotalPage = useSelector(getNotificationListTotalPage);
    const [filterSettingSheetOpen, setFilterSettingSheetOpen] = useState(false);
    const [notiFilter, setNotiFilter] = useState<NotificationTypeWithAll>("ALL");

    const toggleFilterSettingSheetOpen = useCallback(
        (newValue: boolean) => () => {
            setFilterSettingSheetOpen(newValue);
        },
        [],
    );

    const handleFetchMore = useCallback(() => {
        dispatch(fetchMoreNotificationListThunk(getFilterList(notiFilter)));
    }, [notiFilter]);

    useEffect(() => {
        dispatch(resetNotificationState());
        dispatch(fetchMoreNotificationListThunk(getFilterList(notiFilter)));
    }, [notiFilter]);

    const handleNotiFilterChange = (newNotiFilter: NotificationTypeWithAll) => {
        setNotiFilter(newNotiFilter);
        setFilterSettingSheetOpen(false);
    };

    return (
        <StyledAppLayout
            title="알림"
            titleColor="#fff"
            disableAppBarBorder
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            <BottomSheet open={filterSettingSheetOpen} onDismiss={toggleFilterSettingSheetOpen(false)} title="알림">
                {NOTIFICATION_FILTER_LIST.map((noti) => (
                    <BottomSheetCategoryButton
                        value={noti}
                        selected={notiFilter === noti}
                        formatValue={formatNotificationType}
                        onChange={handleNotiFilterChange}
                    />
                ))}
            </BottomSheet>
            <FilterButton onClick={toggleFilterSettingSheetOpen(true)}>
                {formatNotificationType(notiFilter)}
                <KeyboardArrowDown sx={{ color: "#8f8f8f" }} />
            </FilterButton>
            <InfiniteScrollList
                fetchState={notificationListFetchState}
                list={notificationList}
                page={notificationListPage}
                totalPage={notificationListTotalPage}
                showNoResultMessage
                disableInitialize
                noResultMessage="알림이 없어요"
                renderItem={({ title, notification_type, content, created_datetime, path }, i) => (
                    <NotificationCard
                        key={i}
                        title={title}
                        description={content}
                        date={new Date(created_datetime)}
                        type={notification_type}
                        href={path}
                    />
                )}
                onLoadMore={handleFetchMore}
            />
        </StyledAppLayout>
    );
}
