import React from "react";
import styled from "@emotion/styled";

const Container = styled.ul`
    margin: 0 auto;
    padding: 1rem;
    text-align: center;
    list-style: none;
`;

const Button = styled.li<{ isActive: boolean; isDisabled: boolean }>`
    display: inline-block;
    margin: 0 0.5rem;
    border: 1px solid ${(props) => (props.isActive ? "#1890ff" : "#eeeeee")};
    border-radius: 0.25rem;
    min-width: 2rem;
    height: 2rem;
    box-sizing: border-box;
    padding: 0.25rem;
    text-align: center;
    line-height: 1.5rem;
    background-color: ${(props) => (props.isDisabled ? "#eeeeee" : "#ffffff")};
    user-select: none;
    cursor: pointer;
`;

interface Props {
    current: number;
    disabled: boolean;
    onChange: (page: number) => void;
    pageSize: number;
    total: number;
}

export default function Component(props: Props): React.ReactElement {
    const list = [];
    const maxPage = Math.ceil(props.total / props.pageSize);
    let current = props.current;

    if (current < 1) {
        current = 1;
    }
    if (current > maxPage) {
        current = maxPage;
    }

    const rangeStart = current - 4 > 0 ? current - 4 : 1;
    const rangeEnd = current + 4 <= maxPage ? current + 4 : maxPage;

    list.push(
        <Button
            key="<"
            isActive={false}
            isDisabled={props.disabled || current <= 1}
            onClick={() => {
                if (!(props.disabled || current <= 1)) {
                    props.onChange(current - 1);
                }
            }}
        >
            &lt;
        </Button>,
    );

    for (let i = rangeStart; i <= rangeEnd; i += 1) {
        list.push(
            <Button
                key={i}
                isActive={current === i}
                isDisabled={props.disabled}
                onClick={() => {
                    if (!props.disabled) {
                        props.onChange(i);
                    }
                }}
            >
                {i}
            </Button>,
        );
    }

    list.push(
        <Button
            key=">"
            isActive={false}
            isDisabled={props.disabled || current >= maxPage}
            onClick={() => {
                if (!(props.disabled || current >= maxPage)) {
                    props.onChange(current + 1);
                }
            }}
        >
            &gt;
        </Button>,
    );

    return <Container>{list}</Container>;
}
