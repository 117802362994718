import styled from "@emotion/styled";
import { Button } from "@mui/material";
import InputBase from "@/components/Input";
import { StyledIconButton } from "@/components/IconButton";

export const ModalWrapper = styled.div`
    width: calc(100% - 40px);
    padding: 32px 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 65%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
`;

export const ModalCloseButton = styled(Button)`
    position: absolute;
    top: 16px;
    right: 20px;
    width: 24px;
    min-width: unset;
    height: 24px;
    padding: 0;
`;

export const ModalTitle = styled.p`
    font-size: 18px;
    color: #ff831e;
    text-align: center;
    margin: 0 0 32px 0;
    font-weight: bold;
`;

export const ModalInput = styled(InputBase)`
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    font-size: 14px;
    margin: 0;
    border-radius: 8px;
    flex-grow: 0;
`;

export const ModalAdornmentWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 8px;
`;

export const ModalAdornmentSearch = styled.img`
    width: 24px;
    height: 24px;
`;

export const ModalAdornmentResetButton = styled(StyledIconButton)`
    width: 24px;
    height: 24px;
    margin-right: 4px;
`;

export const ModalContentWrapper = styled.div`
    flex-grow: 1;
    overflow-y: scroll;
    position: relative;
`;

export const ModalContentResultItem = styled.div`
    padding: 16px 0;
    border-bottom: 1px solid #ff831e;
    display: flex;
    align-items: center;
`;

export const ModalContentResultImage = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 12px;
    position: relative;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const ModalContentResultTooltip = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ff831e;
`;

export const ModalContentResultContent = styled.div``;

export const ModalContentResultItemTitle = styled.p`
    margin: 0 0 4px 0;
    font-size: 16px;
    font-weight: bold;
    color: #ff831e;
`;

export const ModalContentResultItemSubtitle = styled.p`
    margin: 0;
    font-size: 12px;
    color: #aaa;
`;

export const ModalContentNoResultWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const ModalContentNoResultImage = styled.img`
    margin: 0 auto 16px auto;
    display: block;
`;

export const ModalContentNoResultText = styled.p`
    font-size: 14px;
    margin: 0;
    color: #0c0c0c;
    text-align: center;
`;
