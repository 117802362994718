export type PhoneVerificationRequestStatusType =
    | "PREPARE"
    | "IN_PROGRESS"
    | "INVALID_AUTH_NUMBER"
    | "TIME_OUT"
    | "CONFIRMED";

export function formatPhoneVerificationState(verificationState: PhoneVerificationRequestStatusType) {
    switch (verificationState) {
        case "PREPARE": {
            return "인증번호 받기";
        }
        case "CONFIRMED": {
            return "재발송";
        }
        default: {
            return "재발송";
        }
    }
}
