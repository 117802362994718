import { paymentSlice } from "./slice";

const actions = paymentSlice.actions;

export const {
    reset: resetPaymentState,
    addPaymentMethodFetchRequest,
    addPaymentMethodFetchSuccess,
    addPaymentMethodFetchFailure,
    paymentMethodFetchRequest,
    paymentMethodFetchSuccess,
    paymentMethodFetchFailure,
    deletePaymentMethodFetchRequest,
    deletePaymentMethodFetchSuccess,
    deletePaymentMethodFetchFailure,
} = actions;
