import styled from "@emotion/styled";
import { ArrowForwardIos } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";

export const InfoCardContianer = styled.div`
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: calc(100% - 32px);
    height: 75px;
    padding-top: 16px;
    padding-bottom: 16px;

    border-radius: 8px;
    background: white;

    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
`;

export const InfoCardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    align-items: center;
`;

export const InfoCardDivider = styled.div`
    width: 1px;
    height: 100%;

    background: #2d2d2d;
`;

export const InfoCardTitleContainer = styled.div`
    text-align: center;
    font-size: 12px;
    color: #0c0c0c;
`;

export const InfoCardDescriptionContainer = styled(ButtonBase)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;

    color: #0c0c0c;
`;

export const TicketIconImg = styled.img`
    width: 16px;
    height: auto;
    margin-right: 4px;
`;

export const PointIconImg = styled.img`
    width: 16px;
    height: auto;
    margin-right: 4px;
`;

export const PointText = styled.span`
    font-weight: bold;
    font-size: 14px;
`;

export const ForwardArrow = styled(ArrowForwardIos)`
    margin-left: 8px;

    font-size: 12px;
`;
