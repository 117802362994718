import React, { useCallback, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { ButtonBase, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardArrowDown } from "@mui/icons-material";
import useHistory from "@/hooks/useCustomHistory";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import ProductCard from "./ProductCard";
import PlaceHolderImage from "@/assets/img/place_holder.png";
import Divider from "@/components/Divider";
import { getSelectedShop } from "@/redux/shop/selectors";
import {
    getMenuGroupList,
    getMenuGroupListFetchState,
    getMenuGroupListPage,
    getMenuGroupListTotalPage,
    getMenuList,
    getMenuListFetchState,
    getMenuListPage,
    getMenuListTotalPage,
} from "@/redux/menu/selectors";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import { fetchMoreMenuGroupListThunk, fetchMoreMenuListThunk } from "@/redux/menu/thunk";
import { resetMenuState, resetMenuListState } from "@/redux/menu/actions";
import Footer from "@/components/Footer";
import { OrderType } from "@/redux/order/types";
import { getOrderType } from "@/redux/order/selectors";
import { setOrderType } from "@/redux/order/actions";

const StyledAppLayout = styled(AppLayout)`
    position: relative;

    display: flex;
    flex-direction: column;
    padding-bottom: 180px;
`;

const StyledDivider = styled(Divider)`
    width: calc(100% - 40px);
    margin-top: 0;
    margin-left: 20px;
    margin-bottom: 0;
    margin-right: 20px;
`;

const SelectStoreButton = styled(ButtonBase)`
    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 20px;
    width: 250px;

    color: #ff831e;

    background-color: white;
    border-radius: 40px;
    box-shadow: 0 0 10px 0 #ff831e;
`;

export default function Order() {
    const dispatch = useDispatch();
    const { menuGroupId: rawMenuGroupId } = useParams<{ menuGroupId: string }>();
    const menuListFetchState = useSelector(getMenuListFetchState);
    const menuList = useSelector(getMenuList);
    const menuListPage = useSelector(getMenuListPage);
    const menuListTotalPage = useSelector(getMenuListTotalPage);
    const menuGroupListFetchState = useSelector(getMenuGroupListFetchState);
    const menuGroupList = useSelector(getMenuGroupList);
    const menuGroupListPage = useSelector(getMenuGroupListPage);
    const menuGroupListTotalPage = useSelector(getMenuGroupListTotalPage);
    const orderType = useSelector(getOrderType);

    const selectedShop = useSelector(getSelectedShop);
    const history = useHistory();

    const menuGroupId = useMemo(() => {
        if (!rawMenuGroupId) {
            return;
        }

        return Number(rawMenuGroupId);
    }, [rawMenuGroupId]);

    const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, newTab: OrderType) => {
        dispatch(setOrderType(newTab));
    };

    useEffect(() => {
        if (menuGroupId || menuGroupList) {
            return;
        }

        dispatch(resetMenuState());
        dispatch(fetchMoreMenuGroupListThunk({}));
    }, [menuGroupId, menuGroupList]);

    useEffect(() => {
        if (!menuGroupId) {
            return;
        }

        dispatch(resetMenuListState());
        dispatch(fetchMoreMenuListThunk({ menuGroupId }));
    }, [menuGroupId]);

    const onGroupLoadMore = useCallback(() => {
        dispatch(fetchMoreMenuGroupListThunk({}));
    }, []);

    const onLoadMore = useCallback(() => {
        dispatch(fetchMoreMenuListThunk({ menuGroupId }));
    }, [menuGroupId]);

    const handleSelectStore = useCallback(() => {
        history.push("/select-shop");
    }, [history]);

    let emptyValue: string;

    return (
        <StyledAppLayout
            title="ORDER"
            titleColor="#fff"
            disableAppBarBorder
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            showBottomAppBar
        >
            <Tabs variant="fullWidth" value={orderType || "SUBSCRIPTION"} onChange={handleTabChange}>
                <Tab label="구독권" value="SUBSCRIPTION" />
                <Tab label="단품" value="SINGLE" />
            </Tabs>
            <SelectStoreButton onClick={handleSelectStore}>
                {selectedShop?.name ?? "주문할 매장을 선택해 주세요"}
                <KeyboardArrowDown />
            </SelectStoreButton>

            {!menuGroupId ? (
                <InfiniteScrollList
                    fetchState={menuGroupListFetchState}
                    list={menuGroupList}
                    page={menuGroupListPage}
                    totalPage={menuGroupListTotalPage}
                    onLoadMore={onGroupLoadMore}
                    disableInitialize
                    showNoResultMessage
                    noResultMessage="메뉴 그룹이 없습니다"
                    renderItem={({ name, uploaded_image_set, description, id }, i) => (
                        <>
                            <ProductCard
                                key={i}
                                id={id}
                                thumbnailSrc={uploaded_image_set?.original_image_url ?? PlaceHolderImage}
                                title={name}
                                subTitle={description}
                            />
                            <StyledDivider key={`${i}_divider`} />
                        </>
                    )}
                />
            ) : (
                <InfiniteScrollList
                    fetchState={menuListFetchState}
                    list={menuList}
                    page={menuListPage}
                    totalPage={menuListTotalPage}
                    onLoadMore={onLoadMore}
                    disableInitialize
                    showNoResultMessage
                    noResultMessage="메뉴가 없습니다"
                    renderItem={({ name, menu_group, uploaded_image_set, description, id, single_orderable }, i) => (
                        <>
                            <ProductCard
                                disableClick={!single_orderable && orderType === "SINGLE"}
                                thumbnailTooltip={
                                    !single_orderable && orderType === "SINGLE" ? "구독권 주문 가능 메뉴" : emptyValue
                                }
                                key={i}
                                id={id}
                                groupId={menu_group.id}
                                thumbnailSrc={uploaded_image_set?.original_image_url ?? PlaceHolderImage}
                                title={name}
                                subTitle={description}
                            />
                            <StyledDivider key={`${i}_divider`} />
                        </>
                    )}
                />
            )}
            <Footer />
        </StyledAppLayout>
    );
}
