import React from "react";
import moment from "moment";
import { Button, DatePicker, Input, message, Modal, Select, Switch, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PostResponse, PostType } from "@ferda/rest-api-client";
import { getRestApiClient } from "@/redux/auth/selectors";
import { postTypeToText } from "@/utils/enumToText";
import { getOauth2TokenFromLocalStorage } from "@/redux/auth/utils";

import FormItem from "@/components/Admin/FormItem";
import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import CKEditor from "@/components/Admin/CKEditor";
import {
    DateContainer,
    FinishButtonContainer,
    HeaderContainer,
    HeaderSelectorWrapper,
    HeaderTitleWrapper,
    TitleHiddenContainer,
    TitleHiddenLabel,
} from "./PostsStyled";
import { Left, Right, Clear } from "@/components/Admin/Float";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

interface Props {}

export default function Page(props: Props) {
    const params = useParams<{ postId: string }>();
    const history = useHistory();

    const { postApi } = useSelector(getRestApiClient);

    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [token, setToken] = React.useState<string | undefined>();

    const [modal, setModal] = React.useState<string | undefined>();

    const [post, setPost] = React.useState<PostResponse | undefined>();
    const [postContent, setPostContent] = React.useState<string | undefined>();
    const [postTitle, setPostTitle] = React.useState<string | undefined>();
    const [postTitleHidden, setPostTitleHidden] = React.useState<boolean>(false);
    const [postType, setPostType] = React.useState<PostType | undefined>();
    const [postContentPreview, setPostContentPreview] = React.useState<string | undefined>();
    const [postPath, setPostPath] = React.useState<string | undefined>();
    const [postDateStart, setPostDateStart] = React.useState<Date | undefined>();
    const [postDateEnd, setPostDateEnd] = React.useState<Date | undefined>();
    const [postFirstFiles, setPostFirstFiles] = React.useState<Array<UploadFile<any>>>([]);
    const [postSecondFiles, setPostSecondFiles] = React.useState<Array<UploadFile<any>>>([]);

    React.useEffect(() => {
        const tokens = getOauth2TokenFromLocalStorage();
        if (!tokens) {
            history.push("/admin/login");
            message.error("회원 정보를 불러오지 못하였습니다.");
            return;
        }
        setToken(tokens.token);

        setInitialLoading(true);
        postApi
            .getPost(parseInt(params.postId, 10))
            .then((res) => {
                setPost(res.data);
                setPostContent(res.data.content);
                setPostTitle(res.data.title);
                setPostTitleHidden(res.data.title_hidden);
                setPostType(res.data.post_type);
                setPostContentPreview(res.data.content_preview);
                setPostPath(res.data.path);
                setPostDateStart(res.data.post_start_date ? new Date(res.data.post_start_date) : undefined);
                setPostDateEnd(res.data.post_end_date ? new Date(res.data.post_end_date) : undefined);
                setInitialLoading(false);
                if (res.data.large_thumbnail_set) {
                    setPostFirstFiles([
                        {
                            uid: res.data.large_thumbnail_set.id.toString(),
                            url: res.data.large_thumbnail_set.original_image_url,
                            status: "done",
                            name: "메인 사진",
                        },
                    ]);
                } else {
                    setPostFirstFiles([]);
                }
                if (res.data.small_thumbnail_set) {
                    setPostSecondFiles([
                        {
                            uid: res.data.small_thumbnail_set.id.toString(),
                            url: res.data.small_thumbnail_set.original_image_url,
                            status: "done",
                            name: "리스트 사진",
                        },
                    ]);
                } else {
                    setPostSecondFiles([]);
                }
            })
            .catch(() => {
                message.error("게시글을 불러오는데 실패하였습니다.");
                history.goBack();
            });
    }, []);

    return (
        <>
            <PageTitle>게시글 수정</PageTitle>
            <Container>
                <HeaderContainer>
                    <HeaderSelectorWrapper>
                        <Select
                            disabled
                            onChange={(value) => {
                                setPostType(value as PostType);
                            }}
                            placeholder="게시글 형식"
                            style={{ width: "100%" }}
                            value={postType}
                        >
                            <Option value={PostType.NOTICE}>{postTypeToText(PostType.NOTICE)}</Option>
                            <Option value={PostType.EVENT}>{postTypeToText(PostType.EVENT)}</Option>
                            <Option value={PostType.CONTENT}>{postTypeToText(PostType.CONTENT)}</Option>
                        </Select>
                    </HeaderSelectorWrapper>
                    <HeaderTitleWrapper>
                        <Input
                            disabled={loading || initialLoading}
                            onChange={(event) => {
                                setPostTitle(event.target.value);
                            }}
                            placeholder="제목"
                            style={{ width: "100%" }}
                            value={postTitle}
                        />
                    </HeaderTitleWrapper>
                </HeaderContainer>
            </Container>
            <Container>
                <CKEditor
                    data={postContent}
                    onChange={(event, editor) => {
                        setPostContent(editor.getData());
                    }}
                />
            </Container>
            <Container>
                <FormItem label="게시글 미리보기">
                    <TextArea
                        disabled={loading}
                        onChange={(event) => {
                            setPostContentPreview(event.target.value);
                        }}
                        placeholder="게시글 미리보기"
                        value={postContentPreview}
                    />
                </FormItem>
                <FormItem label="경로">
                    <Input
                        disabled={loading}
                        onChange={(event) => {
                            setPostPath(event.target.value);
                        }}
                        placeholder="경로"
                        value={postPath}
                    />
                </FormItem>
                <DateContainer>
                    <FormItem label="게시 기간">
                        <RangePicker
                            disabled={loading || initialLoading}
                            onChange={(values) => {
                                if (!values || !values[0] || !values[1]) {
                                    setPostDateStart(undefined);
                                    setPostDateEnd(undefined);
                                    return;
                                }
                                setPostDateStart(values[0].toDate());
                                setPostDateEnd(values[1].toDate());
                            }}
                            placeholder={["시작 일자", "끝 일자"]}
                            value={
                                postDateStart && postDateEnd ? [moment(postDateStart), moment(postDateEnd)] : undefined
                            }
                            style={{ width: "300px" }}
                        />
                    </FormItem>
                </DateContainer>
                <TitleHiddenContainer>
                    <Left>
                        <TitleHiddenLabel>제목 숨김 여부</TitleHiddenLabel>
                    </Left>
                    <Right>
                        <Switch
                            disabled={loading}
                            checked={postTitleHidden}
                            onChange={(value) => {
                                setPostTitleHidden(value);
                            }}
                        />
                    </Right>
                    <Clear />
                </TitleHiddenContainer>
                {(postType === PostType.EVENT || postType === PostType.CONTENT) && (
                    <FormItem label="사진 업로드">
                        <div style={{ marginBottom: "1rem" }}>
                            <Upload
                                action={`${process.env.REACT_APP_REST_API_BASE_URL}/uploaded-image-sets/upload-image`}
                                beforeUpload={(file) => {
                                    if (file.type === "image/png" || file.type === "image/jpeg") {
                                        return true;
                                    } else {
                                        message.error("사진 형식의 파일을 업로드해주세요.", 1.5);
                                        return Upload.LIST_IGNORE;
                                    }
                                }}
                                fileList={postFirstFiles}
                                headers={{ Authorization: `bearer ${token}` }}
                                listType="picture"
                                maxCount={1}
                                name="image"
                                onChange={({ fileList }) => {
                                    setPostFirstFiles(fileList);
                                }}
                                onRemove={(file) => {
                                    const files = [...postFirstFiles];
                                    const index = files.findIndex((f) => f.uid === file.uid);

                                    if (index === -1) {
                                        return;
                                    }

                                    files.splice(index, 1);
                                    setPostFirstFiles(files);
                                }}
                            >
                                <Button icon={<UploadOutlined />}>메인 사진 (정사작형 1:1)</Button>
                            </Upload>
                        </div>
                        <div>
                            <Upload
                                action={`${process.env.REACT_APP_REST_API_BASE_URL}/uploaded-image-sets/upload-image`}
                                beforeUpload={(file) => {
                                    if (file.type === "image/png" || file.type === "image/jpeg") {
                                        return true;
                                    } else {
                                        message.error("사진 형식의 파일을 업로드해주세요.", 1.5);
                                        return Upload.LIST_IGNORE;
                                    }
                                }}
                                fileList={postSecondFiles}
                                headers={{ Authorization: `bearer ${token}` }}
                                listType="picture"
                                maxCount={1}
                                name="image"
                                onChange={({ fileList }) => {
                                    setPostSecondFiles(fileList);
                                }}
                                onRemove={(file) => {
                                    const files = [...postSecondFiles];
                                    const index = files.findIndex((f) => f.uid === file.uid);

                                    if (index === -1) {
                                        return;
                                    }

                                    files.splice(index, 1);
                                    setPostSecondFiles(files);
                                }}
                            >
                                <Button icon={<UploadOutlined />}>리스트 사진 (직사각형 2:1)</Button>
                            </Upload>
                        </div>
                    </FormItem>
                )}
                <FinishButtonContainer>
                    <Button
                        danger
                        disabled={loading || initialLoading}
                        onClick={() => {
                            setModal("cancel");
                        }}
                        style={{ marginRight: "0.5rem" }}
                        type="primary"
                    >
                        취소
                    </Button>
                    <Button disabled={loading || initialLoading} onClick={() => setModal("finish")} type="primary">
                        저장
                    </Button>
                </FinishButtonContainer>
            </Container>
            <Modal
                okButtonProps={{ danger: true }}
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    history.goBack();
                }}
                title="취소"
                visible={modal === "cancel"}
                width={800}
            >
                작성을 취소하시겠습니까?
            </Modal>
            <Modal
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!post) {
                        return message.error("원본 글이 불러와지지 않았습니다.", 1.5);
                    }
                    if (!postContent) {
                        return message.error("내용이 비어있습니다.", 1.5);
                    }
                    if (!postTitle) {
                        return message.error("제목을 입력해주세요.", 1.5);
                    }
                    if (!postType) {
                        return message.error("게시글의 종류를 선택해주세요.", 1.5);
                    }
                    if (!postContentPreview) {
                        return message.error("게시글 미리보기를 입력해주세요.");
                    }

                    let firstFileId;
                    let secondFileId;
                    if (postType !== PostType.NOTICE) {
                        if (postFirstFiles.length > 0 && postFirstFiles[0].status === "done") {
                            if (postFirstFiles[0].response) {
                                firstFileId = postFirstFiles[0].response.id;
                            } else {
                                firstFileId = parseInt(postFirstFiles[0].uid, 10);
                            }
                        } else {
                            return message.error("사진를 업로드해주세요.", 1.5);
                        }
                        if (postSecondFiles.length > 0 && postSecondFiles[0].status === "done") {
                            if (postSecondFiles[0].response) {
                                secondFileId = postSecondFiles[0].response.id;
                            } else {
                                secondFileId = parseInt(postSecondFiles[0].uid, 10);
                            }
                        } else {
                            return message.error("사진를 업로드해주세요.", 1.5);
                        }
                    }

                    setLoading(true);
                    postApi
                        .updatePost(parseInt(params.postId, 10), {
                            title: postTitle,
                            title_hidden: postTitleHidden,
                            content: postContent,
                            post_type: postType,
                            post_start_date:
                                postDateStart &&
                                `${postDateStart.getFullYear()}-${
                                    postDateStart.getMonth() + 1
                                }-${postDateStart.getDate()}`,
                            post_end_date:
                                postDateEnd &&
                                `${postDateEnd.getFullYear()}-${postDateEnd.getMonth() + 1}-${postDateEnd.getDate()}`,
                            large_thumbnail_set_id: firstFileId,
                            small_thumbnail_set_id: secondFileId,
                            content_preview: postContentPreview,
                            path: postPath || undefined,
                        })
                        .then(() => {
                            message.success("게시글을 수정하였습니다.", 1.5);
                            history.push("/admin/posts");
                        })
                        .catch((error) => {
                            setLoading(false);
                            message.error(error.response.data.detail, 1.5);
                        });
                }}
                title="저장"
                visible={modal === "finish"}
                width={800}
            >
                저장하시겠습니까?
            </Modal>
        </>
    );
}
