import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";

export const MyPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
`;

export const HeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
`;

export const EditMyInfoButton = styled(ButtonBase)`
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;

    color: #0c0c0c;
    font-size: 12px;
    white-space: nowrap;

    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
`;

export const GreetingContainer = styled.div`
    margin-right: 16px;

    color: white;
    font-size: 20px;
    text-align: left;
    word-break: break-word;
`;

export const HelloContainer = styled.span`
    white-space: nowrap;
    word-break: keep-all;
    color: #0c0c0c;
`;

export const NameContainer = styled.span`
    white-space: nowrap;
    word-break: keep-all;
    font-weight: bold;
    color: #0c0c0c;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    flex: 1 1 0%;
    padding-top: 90px;
    padding-bottom: 130px;

    background: #eeeceb;
`;

export const PencilIconImg = styled.img`
    margin-right: 4px;
`;
