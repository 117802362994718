import styled from "@emotion/styled";

export const ImageMaxWidthContentContainer = styled.div`
    * {
        max-width: 100%;
    }

    img {
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 16px;
        max-width: calc(100% + 40px);
        width: calc(100% + 40px);
    }
`;
