import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import useHistory from "@/hooks/useCustomHistory";
import AppLayout from "@/containers/AppLayout";
import { BackButton } from "@/components/IconButton";
import {
    OtherInput,
    OtherWrapper,
    PriceInputTitle,
    PriceTitle,
    PriceWrapper,
    TasteTitle,
    TasteWrapper,
    WithdrawButton,
    WithdrawGuideTextWrapper,
    WithdrawInput,
    WithdrawModalContentSubtitleText,
    WithdrawModalContentTitleText,
    WithdrawModalContentWrapper,
    WithdrawWrapper,
} from "./style";
import Checkbox from "@/components/Checkbox";
import Modal from "@/components/Modal";
import { openErrorSnackbar } from "@/redux/snackbar/actions";
import { resetUserState } from "@/redux/user/actions";
import { unSubscribeRequestThunk } from "@/redux/subscription/thunk";
import { getSubscribeStatus, getUnSubscribeFetchState } from "@/redux/subscription/selectors";
import { resetPaymentState } from "@/redux/payment/actions";
import { resetSubscriptionState } from "@/redux/subscription/actions";
import { getPaymentMethod } from "@/redux/payment/selectors";

interface InitialValues {
    reason1: boolean;
    reason2: boolean;
    reason3: boolean;
    reason4: boolean;
    reason5: boolean;
    reason6: boolean;
    reason7: boolean;
    reason8: boolean;
    otherText: string;
}

const TASTE_TEXT = ["쓴맛", "단맛", "싱거움", "탄맛", "신맛"];

export default function Withdraw() {
    const [withdrawModal, setWithdrawModal] = useState(false);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const unSubscribeFetchState = useSelector(getUnSubscribeFetchState);
    const paymentMethod = useSelector(getPaymentMethod);
    const subscriptionStatus = useSelector(getSubscribeStatus);

    const initialValues: InitialValues = {
        reason1: false,
        reason2: false,
        reason3: false,
        reason4: false,
        reason5: false,
        reason6: false,
        reason7: false,
        reason8: false,
        otherText: "",
    };

    const { getFieldProps, values } = useFormik({
        initialValues,
        onSubmit: () => {
            //
        },
    });

    const disableButton = useMemo(() => {
        const { reason1, reason2, reason3, reason4, reason5, reason6, reason7, reason8, otherText } = values;

        const disabledCheckbox = [reason1, reason2, reason3, reason4, reason5, reason6, reason7, reason8].some(
            (v) => !!v,
        );
        const disabledInput = otherText.length > 0;

        return disabledCheckbox || disabledInput;
    }, [values]);

    const handleClickWithdraw = () => {
        if (!paymentMethod || !subscriptionStatus) {
            setSubscriptionModal(true);
            return;
        }

        setWithdrawModal(true);
    };

    const handleClickDeleteUserPost = () => {
        let resultText = "";
        const { reason1, reason2, reason3, reason4, reason5, reason6, reason7, reason8, otherText } = values;

        if (reason1) {
            resultText += `\n이용 요금이 비싸서`;
        }

        if (reason2) {
            resultText += "\n맛이 없어서";
        }

        if (reason3) {
            resultText += "\n다른 카페도 이용하고 싶어서";
        }

        if (reason4) {
            resultText += "\n서비스 이용이 불편해서";
        }

        if (reason5) {
            resultText += "\n휴가 혹은 재택근무로 인해서";
        }

        if (reason6) {
            resultText += `\n고객응대가 나빠서`;
        }

        if (reason7) {
            resultText += `\n더 이상 구독 서비스를 이용할 계획이 없어서`;
        }

        if (reason8) {
            resultText += `\n기타 다른 사유 (적어주시면 좋은 참고가 되겠습니다): ${otherText}`;
        }

        dispatch(unSubscribeRequestThunk(resultText));
    };

    useEffect(() => {
        if (unSubscribeFetchState === "FAILURE") {
            dispatch(openErrorSnackbar("잠시 후 다시 시도 해주세요."));
            return;
        }
        if (unSubscribeFetchState === "SUCCESS") {
            dispatch(resetPaymentState());
            dispatch(resetSubscriptionState());
            setWithdrawModal(false);
            history.replace("/");
        }
    }, [unSubscribeFetchState]);

    useEffect(() => {
        return () => {
            dispatch(resetUserState());
        };
    });

    return (
        <AppLayout
            disableAppBarBorder={true}
            title="구독권 해지"
            titleColor="#fff"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            <Modal
                open={subscriptionModal}
                handleClose={() => setSubscriptionModal(false)}
                buttonOnClick={() => setSubscriptionModal(false)}
                buttonText="확인"
                titleText="알림"
                fullSizeButton
                wrapperPadding="24px 16px 0 16px"
            >
                <WithdrawModalContentWrapper>
                    <WithdrawModalContentTitleText>사용중인 구독권이 없습니다</WithdrawModalContentTitleText>
                </WithdrawModalContentWrapper>
            </Modal>
            <Modal
                open={withdrawModal}
                handleClose={() => setWithdrawModal(false)}
                cancelButtonOnClick={() => setWithdrawModal(false)}
                buttonOnClick={handleClickDeleteUserPost}
                cancelButtonText="취소"
                buttonText="해지"
                titleText="구독권 해지"
                fullSizeButton
                wrapperPadding="24px 16px 0 16px"
            >
                <WithdrawModalContentWrapper>
                    {/* <WithdrawModalContentTitleText>정말로 탈퇴 하시겠습니까?</WithdrawModalContentTitleText> */}
                    <WithdrawModalContentSubtitleText>
                        해지 이후로도 남은 구독권 기간동안
                        <br /> 이용 가능합니다.
                    </WithdrawModalContentSubtitleText>
                    <WithdrawModalContentSubtitleText>
                        음료 구매 이력이 있을 시 전체 환불이 불가합니다.
                    </WithdrawModalContentSubtitleText>
                    <WithdrawModalContentSubtitleText>
                        환불 요청은 고객지원 - 1:1 문의를 통해 요청해주세요.
                    </WithdrawModalContentSubtitleText>
                    <WithdrawModalContentSubtitleText>그래도 해지하시겠습니까?</WithdrawModalContentSubtitleText>
                </WithdrawModalContentWrapper>
            </Modal>
            <WithdrawWrapper>
                <WithdrawGuideTextWrapper>
                    좀 더 나은 서비스를 제공하기 위해 해지사유에 대한 설문을 진행하고 있습니다.
                    <br /> 기타 사유를 필수 선택 및 작성 후 구독권 해지가 가능합니다.
                    <br /> 저희 워크무드 구독서비스를 해지하시는 이유를 알려주세요 :)
                </WithdrawGuideTextWrapper>

                <OtherWrapper>
                    <Checkbox
                        marginBottom={8}
                        labelColor="#0c0c0c"
                        labelBold
                        label="이용 요금이 비싸서"
                        iconType="red_square"
                        {...getFieldProps("reason1")}
                    />
                    <Checkbox
                        marginBottom={8}
                        labelColor="#0c0c0c"
                        labelBold
                        label="맛이 없어서"
                        iconType="red_square"
                        {...getFieldProps("reason2")}
                    />
                    <Checkbox
                        marginBottom={8}
                        labelColor="#0c0c0c"
                        labelBold
                        label="다른 카페도 이용하고 싶어서"
                        iconType="red_square"
                        {...getFieldProps("reason3")}
                    />
                    <Checkbox
                        marginBottom={8}
                        labelColor="#0c0c0c"
                        labelBold
                        label="서비스 이용이 불편해서"
                        iconType="red_square"
                        {...getFieldProps("reason4")}
                    />
                    <Checkbox
                        marginBottom={8}
                        labelColor="#0c0c0c"
                        labelBold
                        label="휴가 혹은 재택근무로 인해서"
                        iconType="red_square"
                        {...getFieldProps("reason5")}
                    />
                    <Checkbox
                        marginBottom={8}
                        labelColor="#0c0c0c"
                        labelBold
                        label="고객응대가 나빠서"
                        iconType="red_square"
                        {...getFieldProps("reason6")}
                    />
                    <Checkbox
                        marginBottom={8}
                        labelColor="#0c0c0c"
                        labelBold
                        label="더 이상 구독 서비스를 이용할 계획이 없어서"
                        iconType="red_square"
                        {...getFieldProps("reason7")}
                    />
                    <Checkbox
                        labelColor="#0c0c0c"
                        labelBold
                        label="기타 다른 사유 (적어주시면 좋은 참고가 되겠습니다)"
                        iconType="red_square"
                        {...getFieldProps("reason8")}
                    />
                    {values.reason8 && (
                        <OtherInput
                            placeholder="[맛에 대한 평가나 기타 사유를 입력해주세요]"
                            {...getFieldProps("otherText")}
                            multiline
                            minRows={2}
                        />
                    )}
                </OtherWrapper>

                <WithdrawButton backgroundColor="#ff831e" onClick={handleClickWithdraw} disabled={!disableButton}>
                    해지하기
                </WithdrawButton>
            </WithdrawWrapper>
        </AppLayout>
    );
}
