import { userSlice } from "./slice";

const actions = userSlice.actions;

export const {
    reset: resetUserState,
    editUserInfoFetchRequest,
    editUserInfoFetchSuccess,
    editUserInfoFetchFailure,
    editPhoneNumberFetchRequest,
    editPhoneNumberFetchSuccess,
    editPhoneNumberFetchFailure,
    deleteUserFetchRequest,
    deleteUserFetchSuccess,
    deleteUserFetchFailure,
} = actions;
