import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ShopResponse } from "@ferda/rest-api-client";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop } from "@mui/material";
import { useFormik } from "formik";
import useHistory from "@/hooks/useCustomHistory";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import SearchInput from "@/components/SearchInput";
import { TagButton } from "@/components/Button/style";
import StoreCard from "./StoreCard";
import Divider from "@/components/Divider";
import { getShopListFetchState, getShopList, getShopListPage, getShopListTotalPage } from "@/redux/shop/selectors";
import KakaoMapLoader from "@/containers/KakaoMapLoader";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import { fetchMoreShopListThunk, FetchMoreShopListThunkPayload } from "@/redux/shop/thunk";
import { resetShopState, setSelectedShop } from "@/redux/shop/actions";
import BottomSheet from "@/components/BottomSheet";
import StoreInfoCard from "./StoreInfoCard";
import { getOrderFetchState, getOrderStash, getOrderType } from "@/redux/order/selectors";
import { resetMenuState } from "@/redux/menu/actions";
import { resetOrderState, setOrderStash } from "@/redux/order/actions";
import { getSelectedMenu } from "@/redux/menu/selectors";
import { fetchOrderThunk } from "@/redux/order/thunk";
import { getSubscribeStatus } from "@/redux/subscription/selectors";
import { AlertModalText } from "../Gift/GiftGetDetail/style";
import Modal from "@/components/Modal";
import { getQueryStringObject } from "@/utils/global";
import { SubscribeStatusUpdater } from "@/components/SubscribeStatusUpdater";
import { checkCupAvaliable } from "../OrderDetail/util";

const StyledAppLayout = styled(AppLayout)`
    position: relative;

    display: flex;
    flex-direction: column;
`;

const InputContainer = styled.div`
    padding: 20px;
`;

const TagButtonContianer = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 15px;

    > button {
        margin-right: 8px;

        &:nth-last-child(1) {
            margin-right: 0;
        }
    }
`;

const StoreCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    background-color: #ffffff;
`;

const StyledDivider = styled(Divider)`
    margin-left: 20px;
    margin-top: 0;
    margin-right: 20px;
    margin-bottom: 0;
    width: calc(100% - 40px);

    background-color: #d1d1d1;
`;

interface AddressSearchFormField {
    keyword: string;
    searchNearShopOnly: boolean;
}

interface SelectStoreProps {
    disableSelect?: boolean;
    appBarTitle?: string;
}

function SelectStore({ disableSelect, appBarTitle }: SelectStoreProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const orderFetchState = useSelector(getOrderFetchState);
    const shopListFetchState = useSelector(getShopListFetchState);
    const shopList = useSelector(getShopList);
    const shopListPage = useSelector(getShopListPage);
    const shopListTotalPage = useSelector(getShopListTotalPage);
    const [tempSelectedShop, setTempSelectedShop] = useState<ShopResponse | null>(null);
    const selectedMenu = useSelector(getSelectedMenu);
    const [canNotOrderModalOpen, setCanNotOrderModal] = useState(false);
    const [needSubscriptionModalOpen, setNeedSubscriptionModalOpen] = useState(false);
    const orderStash = useSelector(getOrderStash);
    const subscribeStatus = useSelector(getSubscribeStatus);
    const orderType = useSelector(getOrderType);

    const handleShopSelect = useCallback(() => {
        if (!tempSelectedShop) {
            return;
        }

        if (orderStash && selectedMenu) {
            const { temperatureType, cupType, optionGroupList } = orderStash;

            if (orderType === "SUBSCRIPTION") {
                if (!subscribeStatus || !subscribeStatus?.subscription_in_use) {
                    return setNeedSubscriptionModalOpen(true);
                }

                if (!subscribeStatus?.can_order) {
                    return setCanNotOrderModal(true);
                }
            }

            if (!cupType || checkCupAvaliable(tempSelectedShop, cupType)) {
                dispatch(setSelectedShop(tempSelectedShop));
                history.back();
                return;
            }

            if (orderType === "SINGLE") {
                dispatch(setSelectedShop(tempSelectedShop));
                return history.push(`/order/${selectedMenu.menu_group.id}/${selectedMenu.id}/single`);
            }

            dispatch(
                fetchOrderThunk({
                    shopId: tempSelectedShop.id,
                    menuId: selectedMenu.id,
                    temperatureType,
                    cupType,
                    optionGroupList,
                }),
            );

            dispatch(setOrderStash(null));
        }

        dispatch(setSelectedShop(tempSelectedShop));
        history.back();
    }, [tempSelectedShop, orderStash, selectedMenu, subscribeStatus]);

    useEffect(() => {
        if (orderFetchState !== "SUCCESS") {
            return;
        }

        dispatch(resetOrderState());
        dispatch(resetMenuState());
        dispatch(resetShopState());
        window.history.replaceState(null, "워크무드", `/select-shop?isCompleted=true`);
        history.push("/history");
    }, [orderFetchState]);

    const { search } = useLocation();
    const query = useMemo(() => getQueryStringObject(search), [search]);

    useEffect(() => {
        if (query.isCompleted) {
            history.push("/");
        }
    }, [query]);

    const handleResetTempSelectedShop = () => {
        setTempSelectedShop(null);
    };

    const handleSetTempSelectedShop = (newShop: ShopResponse) => () => {
        setTempSelectedShop(newShop);
    };

    const { handleSubmit, setFieldValue, submitForm, getFieldProps, values } = useFormik<AddressSearchFormField>({
        initialValues: {
            keyword: "",
            searchNearShopOnly: true,
        },
        onSubmit: ({ keyword, searchNearShopOnly }) => {
            dispatch(resetShopState());
            dispatch(
                fetchMoreShopListThunk({
                    keyword,
                    searchNearShopOnly,
                }),
            );
        },
    });

    const handleKeywordReset = () => {
        setFieldValue("keyword", "");
    };

    const onLoadMore = useCallback(() => {
        const payload: FetchMoreShopListThunkPayload = {
            keyword: values.keyword,
        };

        if (selectedMenu?.id) {
            payload.selectedMenuId = selectedMenu.id;
        }

        dispatch(fetchMoreShopListThunk(payload));
    }, [dispatch, values]);

    const setSearchNearShopOnly = useCallback(
        (newValue: boolean) => () => {
            setFieldValue("searchNearShopOnly", newValue);
            submitForm();
        },
        [],
    );

    useEffect(() => {
        const payload: FetchMoreShopListThunkPayload = {
            keyword: values.keyword,
            searchNearShopOnly: values.searchNearShopOnly,
        };

        if (selectedMenu?.id) {
            payload.selectedMenuId = selectedMenu.id;
        }

        dispatch(resetShopState());
        dispatch(fetchMoreShopListThunk(payload));
    }, []);

    return (
        <StyledAppLayout
            title={appBarTitle || "매장 설정"}
            titleColor="#fff"
            disableAppBarBorder
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            <SubscribeStatusUpdater />
            <BottomSheet open={!!tempSelectedShop} onDismiss={handleResetTempSelectedShop} blocking={false}>
                {tempSelectedShop && (
                    <StoreInfoCard
                        handleClose={handleResetTempSelectedShop}
                        shop={tempSelectedShop}
                        disableSelect={disableSelect}
                        handleShopSelect={handleShopSelect}
                    />
                )}
            </BottomSheet>
            <Modal
                titleText="구독권 없음"
                open={needSubscriptionModalOpen}
                handleClose={() => setNeedSubscriptionModalOpen(false)}
                buttonOnClick={() => setNeedSubscriptionModalOpen(false)}
                fullSizeButton
                buttonText="확인"
            >
                <AlertModalText>구독권 구매가 필요합니다.</AlertModalText>
            </Modal>
            <Modal
                titleText="주문불가"
                open={canNotOrderModalOpen}
                handleClose={() => setCanNotOrderModal(false)}
                buttonOnClick={() => setCanNotOrderModal(false)}
                fullSizeButton
                buttonText="확인"
            >
                <AlertModalText>재주문 가능시간이 남았습니다</AlertModalText>
            </Modal>
            <form onSubmit={handleSubmit}>
                <InputContainer>
                    <SearchInput
                        {...getFieldProps("keyword")}
                        onSearch={submitForm}
                        handleReset={handleKeywordReset}
                        placeholder="검색"
                    />
                </InputContainer>
                <TagButtonContianer>
                    <TagButton onClick={setSearchNearShopOnly(true)} selected={values.searchNearShopOnly}>
                        가까운 매장
                    </TagButton>
                    <TagButton onClick={setSearchNearShopOnly(false)} selected={!values.searchNearShopOnly}>
                        전체 매장
                    </TagButton>
                </TagButtonContianer>
            </form>
            <StoreCardContainer>
                <InfiniteScrollList
                    fetchState={shopListFetchState}
                    list={shopList}
                    page={shopListPage}
                    totalPage={shopListTotalPage}
                    onLoadMore={onLoadMore}
                    disableInitialize
                    renderItem={(shop) => (
                        <>
                            <StoreCard shop={shop} onClick={handleSetTempSelectedShop(shop)} />
                            <StyledDivider />
                        </>
                    )}
                />
            </StoreCardContainer>
            <Backdrop onClick={handleResetTempSelectedShop} open={!!tempSelectedShop} />
        </StyledAppLayout>
    );
}

export default function SelectStoreWithKakaoMapLoader({ disableSelect, appBarTitle }: SelectStoreProps) {
    return (
        // <KakaoMapLoader>
        //     <SelectStore disableSelect={disableSelect} appBarTitle={appBarTitle} />
        // </KakaoMapLoader>
        <SelectStore disableSelect={disableSelect} appBarTitle={appBarTitle} />
    );
}
