import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import useHistory from "@/hooks/useCustomHistory";
import NoticeImage from "@/assets/img/ic_notice.svg";
import EventImage from "@/assets/img/ic_event.svg";
import InquiryImage from "@/assets/img/ic_inquiry.svg";
import NotiImage from "@/assets/img/ic_noti.svg";
import SubscsriptionInfoImage from "@/assets/img/ticket-thin-svgrepo-com.svg";
// import GiftImage from "@/assets/img/ic_gift.svg";
import PersonalImage from "@/assets/img/ic_personal.svg";
import StoreImage from "@/assets/img/ic_store.svg";
import TermsImage from "@/assets/img/ic_terms.svg";
import LogoutImage from "@/assets/img/ic_logout.svg";
import { BackButton } from "@/components/IconButton";
import {
    CategoryContainer,
    CategoryTitle,
    DeleteModalContentSubtitleText,
    DeleteModalContentTitleText,
    DeleteModalContentWrapper,
    LogoutButton,
    LogoutImageWrapper,
    LogoutText,
    LogoutWrapper,
    StyledAppLayout,
    StyledDivider,
    StyledLinkButton,
} from "./style";
import { clearOauth2Token } from "@/redux/auth/utils";
import { setAuthType } from "@/redux/auth/actions";
import Footer from "@/components/Footer";
import Modal from "@/components/Modal";
import { deleteUserRequestThunk } from "@/redux/user/thunk";
import { getDeleteUserFetchState } from "@/redux/user/selectors";
import { getPaymentMethod, getPaymentMethodFetchState } from "@/redux/payment/selectors";
import { getSubscribeStatus } from "@/redux/subscription/selectors";
import { fetchPaymentMethodThunk } from "@/redux/payment/thunk";
import { resetPaymentState } from "@/redux/payment/actions";
// import { resetSubscriptionState } from "@/redux/subscription/actions";

interface CategoryProps {
    title: string;
    children?: React.ReactNode;
}

function Category({ title, children }: CategoryProps) {
    return (
        <CategoryContainer>
            <CategoryTitle>{title}</CategoryTitle>
            {children}
        </CategoryContainer>
    );
}

interface LinkButtonWithDividerProps {
    title: string;
    href: string;
    iconSrc: string;
}

function LinkButtonWithDivider(args: LinkButtonWithDividerProps) {
    return (
        <>
            <StyledLinkButton {...args} />
            <StyledDivider />
        </>
    );
}

export default function Other() {
    const [deleteModal, setDeleteModal] = useState(false);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const deleteUserFetchState = useSelector(getDeleteUserFetchState);
    const paymentMethod = useSelector(getPaymentMethod);
    const subscriptionStatus = useSelector(getSubscribeStatus);
    const paymentMethodFetchState = useSelector(getPaymentMethodFetchState);

    const handleClickLogout = () => {
        clearOauth2Token();
        dispatch(setAuthType("UNAUTHORIZED"));
        if (window.android) {
            window.android.signOut();
        }
        if (window.webkit) {
            window.webkit.messageHandlers.signOut.postMessage();
        }
    };

    const handleClickDeleteUserPost = () => {
        dispatch(deleteUserRequestThunk(""));
    };

    useEffect(() => {
        if (deleteUserFetchState === "FAILURE") {
            enqueueSnackbar("잠시 후 다시 시도 해주세요.", {
                variant: "error",
            });
        }
        if (deleteUserFetchState === "SUCCESS") {
            clearOauth2Token();
            dispatch(setAuthType("UNAUTHORIZED"));
            setDeleteModal(false);
            history.replace("/signup");
        }
    }, [deleteUserFetchState]);

    useEffect(() => {
        if (paymentMethodFetchState === "READY") {
            dispatch(fetchPaymentMethodThunk({}));
        }
    }, [paymentMethodFetchState]);

    useEffect(() => {
        dispatch(resetPaymentState());
    }, []);

    return (
        <StyledAppLayout
            title="OTHER"
            titleColor="#fff"
            disableAppBarBorder
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            showBottomAppBar
        >
            <Modal
                open={deleteModal}
                handleClose={() => setDeleteModal(false)}
                cancelButtonOnClick={() => setDeleteModal(false)}
                buttonOnClick={handleClickDeleteUserPost}
                cancelButtonText="취소"
                buttonText="회원탈퇴"
                titleText="회원탈퇴"
                fullSizeButton
            >
                <DeleteModalContentWrapper>
                    <DeleteModalContentTitleText>정말로 탈퇴 하시겠습니까?</DeleteModalContentTitleText>
                    <DeleteModalContentSubtitleText>
                        탈퇴 시, 정보가 삭제되며
                        <br /> 삭제된 데이터는 복구가 불가능 합니다.
                    </DeleteModalContentSubtitleText>
                </DeleteModalContentWrapper>
            </Modal>
            <Category title="서비스">
                <LinkButtonWithDivider iconSrc={EventImage} title="컨텐츠/이벤트" href="/event" />
                <LinkButtonWithDivider iconSrc={NoticeImage} title="공지사항" href="/notice" />
                <LinkButtonWithDivider iconSrc={NotiImage} title="알림" href="/notification" />
                {/* <LinkButtonWithDivider iconSrc={GiftImage} title="선물하기" href="/gift/shop" /> */}
                <LinkButtonWithDivider iconSrc={SubscsriptionInfoImage} title="구독권 정보" href="/service" />
            </Category>
            <Category title="고객지원">
                <LinkButtonWithDivider iconSrc={InquiryImage} title="1:1문의" href="/question" />
                <LinkButtonWithDivider iconSrc={StoreImage} title="스토어 정보" href="/shops" />
                {paymentMethod && subscriptionStatus && (
                    <LinkButtonWithDivider iconSrc={LogoutImage} title="구독권 해지" href="/withdraw" />
                )}
                {/* <LogoutButton onClick={() => setDeleteModal(true)}>
                    <LogoutImageWrapper>
                        <img src={LogoutImage} alt="logout" />
                    </LogoutImageWrapper>
                    <LogoutText>회원탈퇴</LogoutText>
                </LogoutButton>
                <StyledDivider /> */}
            </Category>
            <Category title="서비스">
                <LinkButtonWithDivider iconSrc={TermsImage} title="이용약관" href="/policy" />
                <LinkButtonWithDivider iconSrc={PersonalImage} title="개인정보 처리방침" href="/policy/privacy" />
            </Category>

            <LogoutWrapper>
                <StyledDivider />
                <LogoutButton onClick={handleClickLogout}>
                    <LogoutImageWrapper>
                        <img src={LogoutImage} alt="logout" />
                    </LogoutImageWrapper>
                    <LogoutText>로그아웃</LogoutText>
                </LogoutButton>
                <StyledDivider />
            </LogoutWrapper>
            <Footer />
        </StyledAppLayout>
    );
}
