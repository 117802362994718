import React, { HTMLProps } from "react";
import useCustomHistory from "@/hooks/useCustomHistory";

interface CustomLinkProps extends HTMLProps<HTMLAnchorElement> {
    to: string;
}

export default function CustomLink({ to, ...rest }: CustomLinkProps) {
    const history = useCustomHistory();

    const onLinkClick = () => {
        history.push(to);
    };
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a onClick={onLinkClick} {...rest} />;
}
