import React, { useState } from "react";
import StoreFindModal from "../StoreFindModal";
import { FindStore, FindStoreButton, FindStoreText, FindStoreTextPlcaeholder, FindStoreWrapper } from "./style";

interface FindStoreInput {
    value: string;
    handleValue: (id: number, name: string) => void;
    placeholder?: string;
    inputBackgroundColor?: string;
    selectedMenuId?: number;
    disableStoreClose?: boolean;
    className?: string;
}

export default function FindStoreInput({
    value,
    handleValue,
    placeholder = "매장을 선택해주세요",
    inputBackgroundColor,
    selectedMenuId,
    disableStoreClose,
    className,
}: FindStoreInput) {
    const [findModal, setFindModal] = useState<boolean>(false);
    const handleOpenFindModal = () => setFindModal(true);
    const handleCloseFindModal = () => setFindModal(false);
    const handleClickSearchResult = (id: number, name: string) => {
        handleValue(id, name);
        setFindModal(false);
    };

    return (
        <>
            <StoreFindModal
                open={findModal}
                handleClose={handleCloseFindModal}
                handleClickStoreItem={handleClickSearchResult}
                selectedMenuId={selectedMenuId}
                disableStoreClose={disableStoreClose}
            />
            <FindStoreWrapper className={className}>
                <FindStore background={inputBackgroundColor}>
                    {value ? (
                        <FindStoreText>{value}</FindStoreText>
                    ) : (
                        <FindStoreTextPlcaeholder>{placeholder}</FindStoreTextPlcaeholder>
                    )}
                </FindStore>
                <FindStoreButton backgroundColor="#ff831e" onClick={handleOpenFindModal}>
                    매장찾기
                </FindStoreButton>
            </FindStoreWrapper>
        </>
    );
}
