import styled from "@emotion/styled";

const Divider = styled.div`
    height: 1px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;

    background-color: #d1d1d1;
`;

export default Divider;
