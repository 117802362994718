import { createSlice } from "@reduxjs/toolkit";
import { OrderState } from "./types";
import { makeAsyncReducer, setValueReducerList } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: OrderState = {
    orderFetchState: "READY",
    giftOrderFetchState: "READY",
    giftSubsFetchState: "READY",
    orderStash: null,
    orderType: "SUBSCRIPTION",
    successOrderUidList: [],
};

export const ORDER_STATE_KEY_LIST = getKeyListFromState(initialState);

export const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        reset: () => initialState,
        ...setValueReducerList<OrderState, ["orderStash", "orderType"]>(["orderStash", "orderType"]),
        ...makeAsyncReducer<"order", OrderState>("order"),
        ...makeAsyncReducer<"giftOrder", OrderState>("giftOrder"),
        ...makeAsyncReducer<"giftSubs", OrderState>("giftSubs"),
        addSuccessOrderUid: (state, { payload }: { payload: string; type: string }) => {
            state.successOrderUidList = [...state.successOrderUidList, payload];
        },
    },
});

export const orderReducer = orderSlice.reducer;
