import styled from "@emotion/styled";
import React from "react";
import { useSelector } from "react-redux";
import { getEventContentPostList } from "@/redux/post/selectors";
import EventCard from "@/pages/Event/EventCard";

const BottomBannerContainer = styled.div`
    margin-top: 16px;

    > a {
        display: block;
        margin-bottom: 24px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
`;

export default function BottomBanner() {
    const eventContentPostList = useSelector(getEventContentPostList);

    return (
        <BottomBannerContainer>
            {eventContentPostList && eventContentPostList.map((post, i) => <EventCard key={i} post={post} isMain />)}
        </BottomBannerContainer>
    );
}
