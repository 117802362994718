import styled from "@emotion/styled";
import { ArrowForwardIos } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";

export const PointLogCardContainer = styled(ButtonBase)`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
    padding-left: 20px;
    padding-top: 30px;
    padding-right: 30px;

    text-align: start;
`;

export const DateContainer = styled.div`
    font-size: 12px;
    color: #5b5b5b;
    width: 100%;
`;

export const ContentContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 12px;
    align-items: center;
`;

export const LogNameContainer = styled.div`
    flex-grow: 1;
    color: #0a0a0a;
`;

export const AmountContainer = styled.div`
    color: #0a0a0a;
`;

export const InnerDivider = styled.div`
    width: 100%;
    height: 1px;
    margin-top: 36px;
    background: #d1d1d1;
`;

export const ArrowIcon = styled(ArrowForwardIos)`
    margin-left: 16px;
`;
