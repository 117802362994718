import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Toolbar, ToolbarProps } from "@mui/material";
import React, { HTMLProps } from "react";

interface TitleContainerProps extends HTMLProps<HTMLSpanElement> {
    titleAlign?: "center" | "left";
    color?: string;
    size?: number;
}

export const TitleContainer = styled(({ titleAlign, color, size, ...props }: TitleContainerProps) => (
    <span {...props} />
))`
    flex: 1 0 auto;
    overflow-x: auto;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    white-space: nowrap;

    color: ${({ color }) => color || "#344356"};
    font-size: ${({ size }) => (size ? `${size}px` : "16px")};
    font-weight: bold;
    text-align: ${({ titleAlign }) => titleAlign};
`;

export interface StyledToolbarProps extends ToolbarProps {
    disableBorder?: boolean;
    className?: string;
    size?: number;
    borderColor?: string;
}

export const StyledToolbar = styled(({ disableBorder, borderColor, size, ...props }: StyledToolbarProps) => (
    <Toolbar {...props} />
))`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}

    ${({ disableBorder, borderColor }) =>
        !disableBorder &&
        css`
            border-bottom: 1px solid ${borderColor || "#2d2d2d00"};
        `}
        
    ${({ size }) =>
        size &&
        css`
            font-size: ${size}px;
        `};
`;

export const ActionsPlaceholder = styled.div`
    width: 56px;
`;
