import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import {
    BillingTitle,
    BillingWrapper,
    CoffeeImage,
    CoffeeMenuItem,
    CoffeeMenuItems,
    CoffeeOption,
    CoffeePrice,
    CoffeePriceUnit,
    CoffeeTitle,
    CoffeeTitleWrapper,
    ContentWrapper,
    DateText,
    DateTextTitle,
    DateTextWrapper,
    GiftOrderInfoNumber,
    GiftOrderInfoStatusText,
    GiftOrderInfoTextDivider,
    GiftOrderInfoTitle,
    GiftOrderInfoWrapper,
    InfoTitle,
    InfoTitleTo,
    InfoWrapper,
    PriceItem,
    PriceItemDivider,
    PriceItemPrice,
    PriceItemTitle,
    PriceItemWrapper,
    TotalPriceText,
    TotalPriceTextUnit,
    TotalPriceTitle,
    TotalPriceWrapper,
    Wrapper,
} from "./style";
import { getGiftLogDetailInfo } from "@/redux/giftLog/selectors";
import { giftLogDetailRequestThunk } from "@/redux/giftLog/thunks";
import { numberWithCommas } from "@/utils/number";
import { getMenuOptionGroupTotalPrice } from "@/utils/menuOption";

export default function GiftSendDetail() {
    const dispatch = useDispatch();
    const { giftId } = useParams<{ giftId: string }>();
    const detailInfo = useSelector(getGiftLogDetailInfo);

    useEffect(() => {
        if (!giftId) {
            return;
        }
        dispatch(giftLogDetailRequestThunk(Number(giftId)));
    }, []);

    const optionPrice = useMemo(
        () => (!detailInfo ? 0 : getMenuOptionGroupTotalPrice(detailInfo.option_groups)),
        [detailInfo],
    );

    return (
        <AppLayout
            disableAppBarBorder={true}
            title="보낸 선물"
            titleColor="#fff"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            fullHeight
        >
            {detailInfo && (
                <Wrapper>
                    <ContentWrapper>
                        <DateTextWrapper>
                            <DateTextTitle>주문일</DateTextTitle>
                            <DateText>{format(new Date(detailInfo.created_datetime), "yyyy.MM.dd hh:ss")}</DateText>
                        </DateTextWrapper>

                        <InfoWrapper>
                            <InfoTitle>
                                <InfoTitleTo>To.</InfoTitleTo> {detailInfo.gift_given_name}님
                            </InfoTitle>

                            <CoffeeMenuItems>
                                <CoffeeImage>
                                    <img src={detailInfo.menu.uploaded_image_set?.original_image_url} alt="coffee" />
                                </CoffeeImage>
                                <CoffeeMenuItem>
                                    <CoffeeTitleWrapper>
                                        <CoffeeTitle>{detailInfo.menu.name}</CoffeeTitle>
                                        <CoffeeOption>
                                            {detailInfo.amount}잔{" "}
                                            {detailInfo.temperature_type && `/ ${detailInfo.temperature_type}`}
                                        </CoffeeOption>
                                    </CoffeeTitleWrapper>
                                    <CoffeePrice>
                                        {numberWithCommas(detailInfo.menu.price)}
                                        <CoffeePriceUnit>원</CoffeePriceUnit>
                                    </CoffeePrice>
                                </CoffeeMenuItem>
                            </CoffeeMenuItems>

                            <GiftOrderInfoWrapper>
                                <GiftOrderInfoTitle>주문번호</GiftOrderInfoTitle>
                                <GiftOrderInfoNumber>{detailInfo.id}</GiftOrderInfoNumber>
                                <GiftOrderInfoTextDivider />
                                <GiftOrderInfoStatusText>
                                    {detailInfo.used_datetime ? "사용완료" : "미사용"}
                                </GiftOrderInfoStatusText>
                            </GiftOrderInfoWrapper>
                        </InfoWrapper>
                    </ContentWrapper>

                    <BillingWrapper>
                        <BillingTitle>결제정보</BillingTitle>

                        <PriceItemWrapper>
                            <PriceItem>
                                <PriceItemTitle>상품 금액</PriceItemTitle>
                                <PriceItemPrice>
                                    {numberWithCommas(detailInfo.menu.price * detailInfo.amount)}원
                                </PriceItemPrice>
                            </PriceItem>
                            {optionPrice > 0 && (
                                <PriceItem>
                                    <PriceItemTitle>옵션 금액</PriceItemTitle>
                                    <PriceItemPrice>
                                        {numberWithCommas(optionPrice * detailInfo.amount)}원
                                    </PriceItemPrice>
                                </PriceItem>
                            )}
                            <PriceItem>
                                <PriceItemTitle>포인트 사용</PriceItemTitle>
                                <PriceItemPrice>
                                    -
                                    {numberWithCommas(
                                        Math.abs(
                                            detailInfo.transaction?.point_log?.amount ||
                                                detailInfo.menu.price * detailInfo.amount,
                                        ),
                                    )}
                                    원
                                </PriceItemPrice>
                            </PriceItem>
                        </PriceItemWrapper>
                        <PriceItemDivider />

                        <TotalPriceWrapper>
                            <TotalPriceTitle>최종 결제금액</TotalPriceTitle>
                            <TotalPriceText>
                                {numberWithCommas(
                                    (detailInfo.menu.price + optionPrice) * detailInfo.amount -
                                        Math.abs(
                                            detailInfo.transaction?.point_log?.amount ||
                                                detailInfo.menu.price * detailInfo.amount,
                                        ),
                                )}
                                <TotalPriceTextUnit>원</TotalPriceTextUnit>
                            </TotalPriceText>
                        </TotalPriceWrapper>
                    </BillingWrapper>
                </Wrapper>
            )}
        </AppLayout>
    );
}
