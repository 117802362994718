import React from "react";
import { use100vh } from "react-div-100vh";
import { AppLayoutContainer, InnerContainer } from "./style";
import AppBar from "@/components/AppBar";
import Snackbar from "@/components/Snackbar";
import BottomAppBar from "@/components/BottomAppBar";

interface AppLayoutProps {
    children?: React.ReactNode;
    disableAppBar?: boolean;
    appBar?: React.ReactNode;
    className?: string;
    backgroundColor?: string;
    title?: string;
    titleAlign?: "center" | "left";
    actions?: React.ReactNode;
    leadingIcon?: React.ReactNode;
    appBarBackgroundColor?: string;
    disableAppBarBorder?: boolean;
    titleColor?: string;
    titleSize?: number;
    fullHeight?: boolean;
    showBottomAppBar?: boolean;
}

export default function AppLayout({
    children,
    disableAppBar,
    title,
    titleAlign,
    leadingIcon,
    actions,
    className,
    backgroundColor = "white",
    appBarBackgroundColor = backgroundColor,
    disableAppBarBorder,
    titleColor,
    titleSize,
    fullHeight = false,
    showBottomAppBar,
    appBar = (
        <AppBar
            backgroundColor={appBarBackgroundColor}
            title={title}
            titleAlign={titleAlign}
            actions={actions}
            leadingIcon={leadingIcon}
            disableBorder={disableAppBarBorder}
            titleColor={titleColor}
            titleSize={titleSize}
        />
    ),
}: AppLayoutProps) {
    const minHeight = use100vh();

    return (
        <AppLayoutContainer minHeight={minHeight ? `${minHeight}px` : "100vh"} backgroundColor={backgroundColor}>
            {!disableAppBar && appBar}
            <InnerContainer className={className} backgroundColor={backgroundColor} fullHeight={fullHeight}>
                {children}
                <Snackbar />
            </InnerContainer>
            {showBottomAppBar && <BottomAppBar />}
        </AppLayoutContainer>
    );
}
