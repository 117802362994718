import styled from "@emotion/styled";
import { StyledButton } from "@/components/Button/style";

export const SubscribeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-left: 20px;
    padding-top: 42px;
    padding-right: 20px;
    padding-bottom: 48px;
`;

export const OptionButtonList = styled.div`
    width: 100%;

    > button {
        margin-bottom: 24px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
`;

export const BottomConfirmButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 10000;

    width: 100%;
    padding-top: 8px;
    padding-left: 20px;
    padding-bottom: 8px;
    padding-right: 20px;

    background-color: #2d2d2d;
`;

export const ConfirmButton = styled(StyledButton)`
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;

    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
`;
