import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHistory from "@/hooks/useCustomHistory";
import { getSubscriptionList, getSubscriptionListFetchState } from "@/redux/subscription/selectors";
import { fetchSubscsriptionListThunk } from "@/redux/subscription/thunk";
import SubscriptionOptionButton from "@/pages/Service/Subscribe/SubscriptionOptionButton";
import { BottomConfirmButtonContainer, ConfirmButton, OptionButtonList, SubscribeContainer } from "./style";

export default function GiftSubscribe() {
    const history = useHistory();
    const dispatch = useDispatch();
    const subscriptionList = useSelector(getSubscriptionList);
    const subscriptionListFetchState = useSelector(getSubscriptionListFetchState);
    const [subscription, setSubscription] = useState<number | null>(null);

    const handleSubscriptionSelect = useCallback(
        (newSubscription: number) => () => {
            setSubscription(newSubscription === subscription ? null : newSubscription);
        },
        [subscription],
    );

    useEffect(() => {
        if (subscriptionListFetchState !== "READY") {
            return;
        }

        dispatch(fetchSubscsriptionListThunk({}));
    }, [subscriptionListFetchState]);

    const handleOrderGift = useCallback(() => {
        if (subscription === null) {
            return;
        }

        history.push(`/gift/subs/${subscription}`);
    }, [history, subscription]);

    return (
        <SubscribeContainer>
            {subscriptionList && (
                <OptionButtonList>
                    <SubscriptionOptionButton
                        onClick={handleSubscriptionSelect(1)}
                        title={subscriptionList[0].name}
                        price={subscriptionList[0].price}
                        selected={subscription === 1}
                        availableMenuList={["아메리카노"]}
                    />
                    <SubscriptionOptionButton
                        onClick={handleSubscriptionSelect(2)}
                        title={subscriptionList[1].name}
                        price={subscriptionList[1].price}
                        selected={subscription === 2}
                        availableMenuList={["모든 제조음료"]}
                    />
                </OptionButtonList>
            )}

            <BottomConfirmButtonContainer>
                <ConfirmButton
                    variant="contained"
                    backgroundColor="#ff831e"
                    disabled={subscription === null || subscriptionListFetchState !== "SUCCESS"}
                    onClick={handleOrderGift}
                >
                    구매하기
                </ConfirmButton>
            </BottomConfirmButtonContainer>
        </SubscribeContainer>
    );
}
