import { createSlice } from "@reduxjs/toolkit";
import { ShopBusinessHourResponse } from "@ferda/rest-api-client";
import { ShopListFetchSuccessPayload, ShopState } from "./types";
import { setValueReducerList, makeAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: ShopState = {
    shopList: null,
    shopListFetchState: "READY",
    shopListPage: null,
    shopListTotalPage: null,

    shopBusinessHour: null,
    shopBusinessHourFetchState: "READY",

    selectedShop: null,
};

export const SHOP_STATE_KEY_LIST = getKeyListFromState(initialState);

export const shopSlice = createSlice({
    name: "shop",
    initialState,
    reducers: {
        reset: () => initialState,
        ...setValueReducerList<ShopState, ["selectedShop"]>(["selectedShop"]),
        ...makeAsyncReducer<"shopList", ShopState, void, ShopListFetchSuccessPayload>("shopList", {
            useListSuccessReducer: true,
        }),
        ...makeAsyncReducer<"shopBusinessHour", ShopState, void, ShopBusinessHourResponse[]>("shopBusinessHour"),
    },
});

export const shopReducer = shopSlice.reducer;
