import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ButtonBase, ButtonBaseProps } from "@mui/material";

interface OptionButtonContainerProps extends ButtonBaseProps {
    selected?: boolean;
}

export const OptionButtonContainer = styled(ButtonBase)<OptionButtonContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: 20px;

    text-align: left;

    background-color: #ffffff;

    border-radius: 8px;

    border: 1px solid transparent;
    border-color: #ff831e;

    ${({ selected }) =>
        selected &&
        css`
            background-color: #ff831e;
        `}
`;

export const TitleContainer = styled.div<OptionButtonContainerProps>`
    display: flex;
    justify-content: space-between;

    font-size: 16px;
    color: #ff831e;

    ${({ selected }) =>
        selected &&
        css`
            color: white;
        `}
`;

export const Title = styled.div``;

export const SubTitle = styled.span`
    color: #ff831e;
`;

export const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Price = styled.div`
    font-weight: bold;
`;

export const AvailableMenuContainer = styled.div<OptionButtonContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 12px;
    padding: 14px;

    border: 1px solid #ff831e;

    ${({ selected }) =>
        selected &&
        css`
            border: 1px solid #ffffff;
        `}

    border-radius: 8px;
`;

export const AvailableMenuTitleContainer = styled.div`
    padding-left: 8px;
    padding-top: 2px;
    padding-right: 8px;
    padding-bottom: 2px;

    color: #0c0c0c;
    font-size: 12px;

    background-color: rgba(0, 0, 0, 0.1);

    border-radius: 4px;
`;

export const AvailableMenuContentContainer = styled.div`
    margin-top: 8px;

    color: #0c0c0c;
    font-size: 14px;
`;

export const CheckboxIconImg = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
`;

export const DescriptionContainer = styled.div<OptionButtonContainerProps>`
    margin-top: 12px;

    color: #8f8f8f;
    font-size: 14px;

    ${({ selected }) =>
        selected &&
        css`
            color: #ffffff;
        `}

    > div {
        margin-bottom: 8px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
`;

export const DescriptionRow = styled.div``;
