import React from "react";
import { Button, Col, Form, Input, message, Row, Select } from "antd";
import { MenuOrderByColumn, MenuResponse } from "@ferda/rest-api-client";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useHistory from "@/hooks/useCustomHistory";
import { getRestApiClient } from "@/redux/auth/selectors";
import { getOauth2TokenFromLocalStorage } from "@/redux/auth/utils";
import { menuOrderByColumnToText } from "@/utils/enumToText";
import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import { Clear, Left, Right } from "@/components/Admin/Float";
import { ImageWrapper, MenuInfoDescription, MenuInfoTitle, StyledEndCircle } from "./styled";
import PlaceholderIcon from "@/assets/img/place_holder.png";

const { Option } = Select;

interface Props {}

export default function PosMenuDetail(props: Props) {
    const params = useParams<{ groupId: string }>();
    const history = useHistory();

    const { shopApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [menuList, setMenuList] = React.useState<MenuResponse[]>([]);
    const [menuListTotal, setMenuListTotal] = React.useState<number>(0);

    const [order, setOrder] = React.useState<MenuOrderByColumn | undefined>();
    const [keyword, setKeyword] = React.useState<string | undefined>();
    const [page, setPage] = React.useState<number>(1);

    const [searchForm] = Form.useForm();

    const columns = [
        {
            title: "사진",
            key: "image",
            render: (record: MenuResponse) => {
                return {
                    children: (
                        <ImageWrapper>
                            <img
                                src={record.uploaded_image_set?.original_image_url || PlaceholderIcon}
                                width={64}
                                height={64}
                            />
                            {!record.temperature_selectable && (
                                <StyledEndCircle>
                                    주문
                                    <br />
                                    불가
                                </StyledEndCircle>
                            )}
                        </ImageWrapper>
                    ),
                };
            },
            width: "100px",
        },
        {
            title: "정보",
            key: "info",
            render: (record: MenuResponse) => {
                return {
                    children: (
                        <>
                            <MenuInfoTitle>{record.name}</MenuInfoTitle>
                            <MenuInfoDescription>{record.description}</MenuInfoDescription>
                        </>
                    ),
                };
            },
        },
        {
            title: "가격",
            key: "price",
            render: (record: MenuResponse) => {
                return {
                    children: record.price.toLocaleString("ko") + "원",
                };
            },
            width: "100px",
        },
    ];

    React.useEffect(() => {
        const tokens = getOauth2TokenFromLocalStorage();
        if (!tokens) {
            history.push("/pos/login");
            message.error("회원 정보를 불러오지 못하였습니다.");
        }
    }, []);

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getMenus(parseInt(params.groupId, 10), keyword, false, undefined, order, page)
            .then((res) => {
                setLoading(false);
                setMenuList(res.data);
                setMenuListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("메뉴 목록을 불러오는데 실패하였습니다.");
            });
    }, [order, keyword, page]);

    return (
        <>
            <PageTitle>메뉴 목록</PageTitle>
            <Container>
                <Form
                    form={searchForm}
                    onFinish={(values) => {
                        const order = values.order || undefined;
                        const keyword = values.keyword || undefined;

                        setOrder(order);
                        setKeyword(keyword);
                        setPage(1);
                    }}
                >
                    <Left>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="keyword">
                                    <Input disabled={loading} placeholder="검색어" style={{ width: "300px" }} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="order">
                                    <Select disabled={loading} placeholder="정렬" style={{ width: "200px" }}>
                                        <Option value={MenuOrderByColumn.ORDER_PRIORITY_ASC}>
                                            {menuOrderByColumnToText(MenuOrderByColumn.ORDER_PRIORITY_ASC)}
                                        </Option>
                                        <Option value={MenuOrderByColumn.PRICE_ASC}>
                                            {menuOrderByColumnToText(MenuOrderByColumn.PRICE_ASC)}
                                        </Option>
                                        <Option value={MenuOrderByColumn.PRICE_DESC}>
                                            {menuOrderByColumnToText(MenuOrderByColumn.PRICE_DESC)}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Left>
                    <Right>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        htmlType="reset"
                                        onClick={() => {
                                            searchForm.resetFields();

                                            setOrder(undefined);
                                            setKeyword(undefined);
                                            setPage(1);
                                        }}
                                    >
                                        초기화
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button disabled={loading} htmlType="submit">
                                        검색
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Right>
                    <Clear />
                </Form>
            </Container>
            <Container>
                <Table columns={columns} dataSource={menuList} loading={loading} rowKey="id" />
                {menuListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={menuListTotal}
                    />
                )}
            </Container>
        </>
    );
}
