import React, { useCallback } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonBase, ButtonBaseProps } from "@mui/material";

const InlineSelectContainer = styled.div`
    border: 1px solid #ff831e;
    border-radius: 8px;
`;

interface OptionButtonProps extends ButtonBaseProps {
    selected?: boolean;
}

const OptionButton = styled(ButtonBase)<OptionButtonProps>`
    width: 64px;
    height: 36px;

    font-size: 12px;
    font-weight: bold;

    border-radius: 8px;

    ${({ selected }) =>
        selected
            ? css`
                  color: #ffffff;

                  background-color: #ff831e;
              `
            : css`
                  color: #ff831e;
              `}
`;

interface InlineSelectOptionProps {
    label: string;
    value?: string;
    selected?: boolean;
    onClick?: (value?: string) => void;
}

export function InlineSelectOption({ label, value, selected, onClick }: InlineSelectOptionProps) {
    const handleClick = useCallback(() => {
        if (!onClick) {
            return;
        }

        onClick(value);
    }, [value, onClick]);

    return (
        <OptionButton onClick={handleClick} selected={selected}>
            {label}
        </OptionButton>
    );
}

interface InlineSelectProps {
    value: string;
    options: Array<{ label: string; value?: string }>;
    onChange?: (newValue?: string) => void;
}

export default function InlineSelect({ value, options, onChange }: InlineSelectProps) {
    return (
        <InlineSelectContainer>
            {options.map((option, i) => (
                <InlineSelectOption key={i} selected={value === option.value} {...option} onClick={onChange} />
            ))}
        </InlineSelectContainer>
    );
}
