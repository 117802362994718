import React from "react";
import { Button, Checkbox, message } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { MenuResponse } from "@ferda/rest-api-client";
import { getRestApiClient } from "@/redux/auth/selectors";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import { Clear, Right } from "@/components/Admin/Float";
import {
    MenuDraggable,
    MenuDraggableCheckboxWrapper,
    MenuDraggableContainer,
    MenuDraggableDescription,
    MenuDraggableImageWrapper,
    MenuDraggableTitle,
} from "./MenusStyled";

type MenuResponseModified = MenuResponse & { checked: boolean };

interface Props {}

export default function Page(props: Props) {
    const params = useParams<{ groupId: string }>();
    const history = useHistory();

    const { shopApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [menuList, setMenuList] = React.useState<MenuResponseModified[]>([]);

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getMenus(parseInt(params.groupId, 10), undefined, true)
            .then((res) => {
                setLoading(false);
                setMenuList(
                    res.data.map((value) => {
                        return { ...value, checked: value.recommended_order_priority !== undefined };
                    }),
                );
            })
            .catch(() => {
                setLoading(false);
                message.error("메뉴를 불러오는데 실패하였습니다.");
            });
    }, []);

    return (
        <>
            <PageTitle>메뉴 관리</PageTitle>
            <Container>
                <Right>
                    <Button
                        disabled={loading}
                        onClick={() => {
                            setLoading(true);
                            shopApi
                                .setMenuRecommendedOrderPriority(
                                    menuList.map((menu, index) => {
                                        return {
                                            menu_id: menu.id,
                                            recommended_order_priority: menu.checked ? index : undefined,
                                        };
                                    }),
                                )
                                .then((res) => {
                                    setLoading(false);
                                    message.success("저장하였습니다.", 1.5);
                                    history.push(`/admin/menus/view/${parseInt(params.groupId, 10)}`);
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    message.error(error.response.data.detail, 1.5);
                                });
                        }}
                    >
                        저장
                    </Button>
                </Right>
                <Clear />
            </Container>
            <Container>
                <DragDropContext
                    onDragEnd={(result, provided) => {
                        if (!result.destination) {
                            return;
                        }

                        const newMenuList = [...menuList];
                        const [reorderedItem] = newMenuList.splice(result.source.index, 1);
                        newMenuList.splice(result.destination.index, 0, reorderedItem);

                        setMenuList(newMenuList);
                    }}
                >
                    <Droppable droppableId="menus">
                        {(provided) => (
                            <MenuDraggableContainer {...provided.droppableProps} ref={provided.innerRef}>
                                {menuList.map((menu, menuIndex) => (
                                    <Draggable key={menu.id} draggableId={menu.id.toString()} index={menuIndex}>
                                        {(provided) => (
                                            <MenuDraggable
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <MenuDraggableCheckboxWrapper>
                                                    <Checkbox
                                                        checked={menu.checked}
                                                        onChange={(value) => {
                                                            const newMenuList = [...menuList];
                                                            newMenuList[menuIndex].checked = value.target.checked;
                                                            setMenuList(newMenuList);
                                                        }}
                                                    />
                                                </MenuDraggableCheckboxWrapper>
                                                <MenuDraggableImageWrapper>
                                                    {menu.uploaded_image_set && (
                                                        <img
                                                            src={menu.uploaded_image_set.original_image_url}
                                                            width={64}
                                                            height={64}
                                                        />
                                                    )}
                                                </MenuDraggableImageWrapper>
                                                <MenuDraggableTitle>{menu.name}</MenuDraggableTitle>
                                                <MenuDraggableDescription>{menu.description}</MenuDraggableDescription>
                                            </MenuDraggable>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </MenuDraggableContainer>
                        )}
                    </Droppable>
                </DragDropContext>
            </Container>
        </>
    );
}
