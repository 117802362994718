import React from "react";
import styled from "@emotion/styled";
import moment from "moment";
import "moment/locale/ko";
import { Button, Form, Input, message, Modal, Select } from "antd";
import { NotificationResponse, NotificationType, UserResponse, UserType } from "@ferda/rest-api-client";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";
import { notificationTypeToText } from "@/utils/enumToText";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import FormItem from "@/components/Admin/FormItem";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import { Clear, Left, Right } from "@/components/Admin/Float";
import { TotalCountWrapper } from "@/components/Admin/Misc";

const { TextArea } = Input;
const { Option } = Select;

const SearchBarContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

interface Props {}

export default function Page(_props: Props) {
    const { serviceApi, userApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalNotification, setModalNotification] = React.useState<NotificationResponse | undefined>();
    const [modalUsers, setModalUsers] = React.useState<UserResponse[]>([]);
    const [modalUserList, setModalUserList] = React.useState<UserResponse[]>([]);
    const [modalUserListTotal, setModalUserListTotal] = React.useState<number>(0);
    const [modalUserName, setModalUserName] = React.useState<string | undefined>();
    const [modalUserPhone, setModalUserPhone] = React.useState<string | undefined>();
    const [modalUserPage, setModalUserPage] = React.useState<number>(1);

    const [notificationList, setNotificationList] = React.useState<NotificationResponse[]>([]);
    const [notificationListTotal, setNotificationListTotal] = React.useState<number>(0);

    const [page, setPage] = React.useState<number>(1);

    const [addForm] = Form.useForm();
    const [userSearchForm] = Form.useForm();

    const columns = [
        {
            title: "생성시각",
            key: "createdDatetime",
            render: (record: NotificationResponse) => {
                const time = moment(record.created_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "종류",
            key: "type",
            render: (record: NotificationResponse) => {
                return { children: notificationTypeToText(record.notification_type) };
            },
            width: "100px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: NotificationResponse) => {
                return { children: record.user && record.user.detail?.name };
            },
            width: "125px",
        },
        {
            title: "제목",
            key: "title",
            render: (record: NotificationResponse) => {
                return { children: record.title };
            },
            width: "250px",
        },
        {
            title: "내용",
            key: "content",
            render: (record: NotificationResponse) => {
                return { children: record.content };
            },
        },
        {
            title: "경로",
            key: "path",
            render: (record: NotificationResponse) => {
                return { children: record.path };
            },
            width: "250px",
        },
        {
            title: "관리",
            key: "manage",
            render: (record: NotificationResponse) => {
                return {
                    children: (
                        <Button
                            danger
                            onClick={() => {
                                setModal("delete");
                                setModalNotification(record);
                            }}
                        >
                            삭제
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    const userColumns = [
        {
            title: "휴대폰번호",
            key: "phone",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.phone_number,
                };
            },
            width: "150px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.name,
                };
            },
            width: "125px",
        },
        {
            title: "아이디",
            key: "username",
            render: (record: UserResponse) => {
                return {
                    children: record.username,
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: UserResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                const users = [...modalUsers];
                                users.push(record);
                                setModalUsers(users);
                                setModal("add");
                                addForm.setFieldsValue({ user: users.map((user) => user.detail!.name).join(", ") });
                            }}
                        >
                            선택
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    React.useEffect(() => {
        setLoading(true);
        serviceApi
            .getNotifications(undefined, page)
            .then((res) => {
                setLoading(false);
                setNotificationList(res.data);
                setNotificationListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("알림 목록을 불러오는데 실패하였습니다.");
            });
    }, [page, rerender]);

    React.useEffect(() => {
        setLoading(true);
        userApi
            .getUsers(
                undefined,
                undefined,
                undefined,
                [UserType.USER],
                undefined,
                undefined,
                undefined,
                undefined,
                modalUserName,
                modalUserPhone,
                undefined,
                undefined,
                undefined,
                undefined,
                modalUserPage,
            )
            .then((res) => {
                setLoading(false);
                setModalUserList(res.data);
                setModalUserListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("회원 목록을 불러오는데 실패하였습니다.");
            });
    }, [modalUserName, modalUserPhone, modalUserPage]);

    return (
        <>
            <PageTitle>알림 관리</PageTitle>
            <Container>
                <Left>
                    <TotalCountWrapper style={{ marginBottom: 0, marginTop: "0.25rem" }}>
                        알림 수 : {notificationListTotal.toLocaleString("ko")}
                    </TotalCountWrapper>
                </Left>
                <Right style={{ marginBottom: "1rem" }}>
                    <Button
                        onClick={() => {
                            setModal("add");
                            setModalUsers([]);
                            addForm.resetFields();
                        }}
                    >
                        추가
                    </Button>
                </Right>
                <Clear />
                <Table columns={columns} dataSource={notificationList} loading={loading} rowKey="id" />
                {notificationListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={notificationListTotal}
                    />
                )}
            </Container>
            <Modal
                confirmLoading={loading}
                okButtonProps={{ danger: true }}
                okText="삭제"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!modalNotification) {
                        return;
                    }

                    setLoading(true);
                    serviceApi
                        .deleteNotification(modalNotification.id)
                        .then(() => {
                            setModal(undefined);
                            setModalNotification(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("삭제하였습니다.", 1.5);
                        })
                        .catch(() => {
                            setLoading(false);
                            message.error("삭제를 실패하였습니다.", 1.5);
                        });
                }}
                title="알림 삭제"
                visible={modal === "delete"}
                width={800}
            >
                해당 알림을 삭제하시겠습니까?
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    htmlType: "submit",
                    form: "addForm",
                }}
                okText="추가"
                onCancel={() => setModal(undefined)}
                title="알림 추가"
                visible={modal === "add"}
                width={800}
            >
                <Form
                    form={addForm}
                    name="addForm"
                    onFinish={(values) => {
                        if (!values.type) {
                            return message.error("종류를 선택해주세요.", 1.5);
                        }
                        if (!values.title) {
                            return message.error("제목을 입력해주세요.", 1.5);
                        }
                        if (!values.content) {
                            return message.error("내용 입력해주세요.", 1.5);
                        }

                        setLoading(true);
                        if (modalUsers.length > 0) {
                            const promises: any[] = [];
                            modalUsers.forEach((user) => {
                                promises.push(
                                    serviceApi.createNotification({
                                        notification_type: values.type,
                                        title: values.title,
                                        content: values.content,
                                        path: values.path,
                                        username_or_id_or_me: user.username,
                                    }),
                                );
                            });
                            Promise.all(promises)
                                .then(() => {
                                    setModal(undefined);
                                    setModalNotification(undefined);
                                    setLoading(false);
                                    setRerender(!rerender);
                                    message.success("알림을 등록하였습니다.", 1.5);
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    message.error(error.response.data.detail, 1.5);
                                });
                        } else {
                            serviceApi
                                .createNotification({
                                    notification_type: values.type,
                                    title: values.title,
                                    content: values.content,
                                    path: values.path,
                                })
                                .then(() => {
                                    setModal(undefined);
                                    setModalNotification(undefined);
                                    setLoading(false);
                                    setRerender(!rerender);
                                    message.success("알림을 등록하였습니다.", 1.5);
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    message.error(error.response.data.detail, 1.5);
                                });
                        }
                    }}
                >
                    <FormItem label="종류" name="type">
                        <Select disabled={loading} placeholder="종류" style={{ width: "100%" }}>
                            <Option value={NotificationType.AD}>{notificationTypeToText(NotificationType.AD)}</Option>
                            <Option value={NotificationType.EVENT}>
                                {notificationTypeToText(NotificationType.EVENT)}
                            </Option>
                            <Option value={NotificationType.GIFT}>
                                {notificationTypeToText(NotificationType.GIFT)}
                            </Option>
                            <Option value={NotificationType.NOTICE}>
                                {notificationTypeToText(NotificationType.NOTICE)}
                            </Option>
                            <Option value={NotificationType.ORDER}>
                                {notificationTypeToText(NotificationType.ORDER)}
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem label="제목" name="title">
                        <Input disabled={loading} placeholder="제목" />
                    </FormItem>
                    <FormItem label="내용" name="content">
                        <TextArea disabled={loading} placeholder="내용" rows={2} />
                    </FormItem>
                    <FormItem hints={["선택항목"]} label="경로" name="path">
                        <Input disabled={loading} placeholder="경로" />
                    </FormItem>
                    <FormItem hints={["선택항목"]} label="회원" name="user">
                        <Input
                            disabled={loading}
                            placeholder="회원"
                            onFocus={(e) => {
                                setModal("add-user");
                                setModalUserName(undefined);
                                setModalUserPhone(undefined);
                                setModalUserPage(1);
                                userSearchForm.resetFields();
                                e.target.blur();
                            }}
                            readOnly
                        />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    style: { display: "none" },
                }}
                onCancel={() => setModal("add")}
                title="회원 선택"
                visible={modal === "add-user"}
                width={800}
            >
                <Form
                    form={userSearchForm}
                    onFinish={(values) => {
                        if (!values.name && !values.phone) {
                            return message.error("검색어를 입력해주세요.", 1.5);
                        }

                        setModalUserName(values.name);
                        setModalUserPhone(values.phone);
                        setModalUserPage(1);
                    }}
                >
                    <SearchBarContainer>
                        <Form.Item name="phone" style={{ marginBottom: "0", marginRight: "0.5rem", flexGrow: 1 }}>
                            <Input placeholder="휴대폰번호" style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item name="name" style={{ marginBottom: "0", marginRight: "0.5rem", flexGrow: 1 }}>
                            <Input placeholder="이름" style={{ width: "100%" }} />
                        </Form.Item>
                        <Button disabled={loading} htmlType="submit">
                            검색
                        </Button>
                    </SearchBarContainer>
                    <Table columns={userColumns} dataSource={modalUserList} loading={loading} rowKey="id" />
                    {modalUserListTotal > 0 && (
                        <Pagination
                            current={modalUserPage}
                            disabled={loading}
                            onChange={(page: number) => setModalUserPage(page)}
                            pageSize={20}
                            total={modalUserListTotal}
                        />
                    )}
                </Form>
            </Modal>
        </>
    );
}
