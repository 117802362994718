export async function getLocation() {
    return new Promise<{
        latitude: number;
        longitude: number;
    }>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            ({ coords: { latitude, longitude } }) =>
                resolve({
                    latitude,
                    longitude,
                }),
            (e) => reject(e),
        );
    });
}
