import styled from "@emotion/styled";

export const EndCircle = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;

    font-size: 22px;

    background-color: rgba(0, 0, 0, 0.6);

    border-radius: 90px;
`;
