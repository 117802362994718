import styled from "@emotion/styled";
import { css } from "@emotion/react";
import React from "react";
import { CircularProgress } from "@mui/material";

interface LoadingCircleProps {
    position?: "relative" | "absolute";
}

const LoadingCircleContainer = styled.div<LoadingCircleProps>`
    display: flex;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;

    ${({ position }) =>
        position === "absolute" &&
        css`
            position: absolute;

            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        `}
`;

export default function LoadingCircle({ position = "relative" }: LoadingCircleProps) {
    return (
        <LoadingCircleContainer position={position}>
            <CircularProgress />
        </LoadingCircleContainer>
    );
}
