import styled from "@emotion/styled";
import { Tab, Tabs, tabClasses } from "@mui/material";

export const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    min-width: 40px;
`;

export const StyledGiftTabs = styled(Tabs)`
    background-color: #ff831e;

    & .MuiTabs-indicator {
        color: #ff831e;
        background-color: #ff831e;
    }
`;

export const StyledGiftTab = styled(Tab)`
    border-bottom: 1px solid #d1d1d1;
    color: #757575;

    &.${tabClasses.selected} {
        color: #ff831e;
    }
`;
