import { pointLogSlice } from "./slice";

const actions = pointLogSlice.actions;

export const {
    reset: resetPointLogState,
    pointLogListFetchRequest,
    pointLogListFetchSuccess,
    pointLogListFetchFailure,
} = actions;
