import styled from "@emotion/styled";
import { styled as muiStyled, Button, buttonClasses } from "@mui/material";
import Checkbox from "@/components/Checkbox";
import InputBase from "@/components/Input";

export const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 20px;
    background-color: #fff;
    min-height: calc(100vh - 56px);
`;

export const GuideTextWrapper = styled.div`
    margin-bottom: 32px;
`;

export const GuideText = styled.p`
    font-size: 12px;
    color: #0c0c0c;
    line-height: 1.5rem;
    margin: 0;
`;

export const LoginInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 240px;
`;

export const LoginInputTitle = styled.div`
    margin-bottom: 16px;
    color: #0c0c0c;
    font-weight: bold;
`;

export const LoginInputItem = styled.div`
    display: flex;
    margin-bottom: 12px;
    position: relative;

    &:nth-last-child(1) {
        margin-bottom: 76px;
    }
`;

export const CodeInputErrorText = styled.p`
    font-size: 12px;
    color: #ff831e;
`;

export const LoginInputButton = muiStyled(Button)`
    width: 100px;
    font-size: 14px;
    letter-spacing: -0.14px;
    white-space: nowrap;
    background-color: #ff831e;
    color: #ffffff;
    font-weight: 500;

    &:hover {
        background-color: #ff831e;
        color: #ffffff;
    }

    &.${buttonClasses.disabled} {
        background-color: #ff831e;
        color: #ffffff;
    }
`;

export const LoginInputEndAdornment = styled.p`
    color: #ff831e;
    font-size: 14px;
    white-space: nowrap;
    margin-right: 16px;
`;

export const TermsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
`;

export const TermsCheckbox = styled(Checkbox)`
    margin-bottom: 12px;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }
`;

export const CheckAllCheckboxLabel = styled.p`
    color: #0c0c0c;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
`;

export const LoginButton = styled(Button)`
    width: 100%;
    background-color: #ff831e;
    font-weight: bold;
    height: 48px;
    border-radius: 20px;

    &:hover {
        background-color: #ff831e;
    }

    &.${buttonClasses.disabled} {
        background-color: #ff831e;
        color: #ffffff;
    }
`;

export const LoginSuccessModalText = styled.p`
    text-align: center;
    font-size: 14px;
    color: #0c0c0c;
    margin: 0 0 40px 0;
`;

export const LoginFinishModalText = styled.p`
    text-align: center;
    font-size: 14px;
    color: #0c0c0c;
    margin: 0 0 143px 0;
`;

export const AlertModalContent = styled.p`
    font-size: 14px;
    color: #ff831e;
    margin: 16px 0 0 0;
    text-align: center;
    word-break: keep-all;
`;

export const OtherInfoInputBase = styled(InputBase)`
    margin: 0;
    height: 56px;
`;

export const OtherInfoGuideText = styled.p`
    font-size: 14px;
    color: #0c0c0c;
    line-height: 1.2;
    margin-bottom: 8px;
`;

export const OtherInfoGuideWarningText = styled.p`
    font-size: 12px;
    color: #0c0c0c;
`;

export const OtherInfoInputWrapper = styled.div`
    margin-bottom: 40px;
`;

export const OtherInfoGenderWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 32px;
`;

export const OtherInfoGenderItem = styled.div`
    display: flex;
    align-items: center;
`;

export const OtherInfoInputTitleText = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: #0c0c0c;
    margin-bottom: 18px;
`;

export const OtherInfoJobItem = styled.div`
    display: flex;
    width: 100%;
    &:not(:last-of-type) {
        margin-bottom: 12px;
    }
`;
