import { subscriptionSlice } from "./slice";

const actions = subscriptionSlice.actions;

export const {
    reset: resetSubscriptionState,
    subscribeStatusFetchRequest,
    subscribeStatusFetchSuccess,
    subscribeStatusFetchFailure,
    subscriptionListFetchRequest,
    subscriptionListFetchSuccess,
    subscriptionListFetchFailure,
    subscribeFetchRequest,
    subscribeFetchSuccess,
    subscribeFetchFailure,
    miscFetchRequest,
    miscFetchSuccess,
    miscFetchFailure,
    unSubscribeFetchRequest,
    unSubscribeFetchSuccess,
    unSubscribeFetchFailure,
    holdSubscriptionFetchSuccess,
    holdSubscriptionFetchFailure,
    holdSubscriptionFetchRequest,
    resetHoldSubscriptionFetchState,
} = actions;
