import { postSlice } from "./slice";

const actions = postSlice.actions;

export const {
    reset: resetPostState,
    resetEventContentPostState,
    resetNoticePostState,

    noticePostFetchRequest,
    noticePostFetchSuccess,
    noticePostFetchFailure,
    noticePostListFetchRequest,
    noticePostListFetchSuccess,
    noticePostListFetchFailure,

    eventContentPostFetchRequest,
    eventContentPostFetchSuccess,
    eventContentPostFetchFailure,
    eventContentPostListFetchRequest,
    eventContentPostListFetchSuccess,
    eventContentPostListFetchFailure,

    unexpiredEventContentPostListFetchRequest,
    unexpiredEventContentPostListFetchSuccess,
    unexpiredEventContentPostListFetchFailure,
} = actions;
