import { createSlice } from "@reduxjs/toolkit";
import { PaymentState } from "./types";
import { makeAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: PaymentState = {
    addPaymentMethodFetchState: "READY",
    paymentMethod: null,
    paymentMethodFetchState: "READY",
    deletePaymentMethodFetchState: "READY",
};

export const PAYMENT_STATE_KEY_LIST = getKeyListFromState(initialState);

export const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        reset: () => initialState,
        ...makeAsyncReducer<"addPaymentMethod", PaymentState>("addPaymentMethod"),
        ...makeAsyncReducer<"paymentMethod", PaymentState>("paymentMethod"),
        ...makeAsyncReducer<"deletePaymentMethod", PaymentState>("deletePaymentMethod"),
    },
});

export const paymentReducer = paymentSlice.reducer;
