import { SHOP_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
    getShopListFetchState,
    getShopList,
    getShopListPage,
    getShopListTotalPage,
    getShopBusinessHour,
    getShopBusinessHourFetchState,
    getSelectedShop,
} = makeSubStateSelectorMap("shop", SHOP_STATE_KEY_LIST);
