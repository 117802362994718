import React from "react";
import { Button, message } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PostResponse } from "@ferda/rest-api-client";
import { getRestApiClient } from "@/redux/auth/selectors";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import { Clear, Right } from "@/components/Admin/Float";
import { PostDraggable, PostDraggableContainer } from "./PostsStyled";

interface Props {}

export default function Page(props: Props) {
    const history = useHistory();

    const { postApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [postList, setPostList] = React.useState<PostResponse[]>([]);

    React.useEffect(() => {
        setLoading(true);
        postApi
            .getPosts(undefined, undefined, undefined, true, undefined)
            .then((res) => {
                setLoading(false);
                setPostList(res.data);
            })
            .catch(() => {
                setLoading(false);
                message.error("게시글 목록을 불러오는데 실패하였습니다.");
            });
    }, []);

    return (
        <>
            <PageTitle>게시글 관리</PageTitle>
            <Container>
                <Right>
                    <Button
                        disabled={loading}
                        onClick={() => {
                            setLoading(true);
                            postApi
                                .setPostOrderPriority(
                                    postList.map((post, index) => {
                                        return {
                                            post_id: post.id,
                                            order_priority: index,
                                        };
                                    }),
                                )
                                .then((res) => {
                                    setLoading(false);
                                    message.success("저장하였습니다.", 1.5);
                                    history.push("/admin/posts");
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    message.error(error.response.data.detail, 1.5);
                                });
                        }}
                    >
                        저장
                    </Button>
                </Right>
                <Clear />
            </Container>
            <Container>
                <DragDropContext
                    onDragEnd={(result, provided) => {
                        if (!result.destination) {
                            return;
                        }

                        const newMenuGroupList = [...postList];
                        const [reorderedItem] = newMenuGroupList.splice(result.source.index, 1);
                        newMenuGroupList.splice(result.destination.index, 0, reorderedItem);

                        setPostList(newMenuGroupList);
                    }}
                >
                    <Droppable droppableId="menuGroups">
                        {(provided) => (
                            <PostDraggableContainer {...provided.droppableProps} ref={provided.innerRef}>
                                {postList.map((post, postIndex) => (
                                    <Draggable key={post.id} draggableId={post.id.toString()} index={postIndex}>
                                        {(provided) => (
                                            <PostDraggable
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {post.title}
                                            </PostDraggable>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </PostDraggableContainer>
                        )}
                    </Droppable>
                </DragDropContext>
            </Container>
        </>
    );
}
