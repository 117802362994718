import React from "react";
import { useParams } from "react-router-dom";
import AppLayout from "@/containers/AppLayout";
import {
    BottomHomeButtonContainer,
    GiftImageWrapper,
    GiftListButton,
    GiftSubtitle,
    GiftTitle,
    HomeButton,
    Wrapper,
} from "./style";
import GiftIcon from "@/assets/img/img_gift_ send.svg";

export default function GiftComplete() {
    const { name } = useParams<{ name: string }>();

    return (
        <AppLayout title="선물하기" titleColor="#fff" appBarBackgroundColor="#ff831e">
            <Wrapper>
                <GiftImageWrapper>
                    <img src={GiftIcon} alt="gift icon" />
                </GiftImageWrapper>
                <GiftTitle>{name}님에게 선물을 보냈습니다</GiftTitle>
                <GiftSubtitle>
                    구매하신 선물은 휴대폰 메시지를 통해
                    <br />
                    친구에게 발송됩니다.
                </GiftSubtitle>
                <GiftListButton variant="contained" backgroundColor="#191919" href="/gift/get?headerTypeTab=menu">
                    선물내역 보기
                </GiftListButton>

                <BottomHomeButtonContainer>
                    <HomeButton href="/" variant="contained" backgroundColor="#ff831e">
                        홈으로 가기
                    </HomeButton>
                </BottomHomeButtonContainer>
            </Wrapper>
        </AppLayout>
    );
}
