import { LOGIN_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
    getPhoneVerificationFetchState,
    getVerifyPhoneVerificationCodeFetchState,
    getVerifyPhoneVerificationCodeIsVerified,
    getUserExist,
    getGenerateUserTokenFetchState,
    getIsFirstLogin,
    getCheckUserExistFetchState,
} = makeSubStateSelectorMap("login", LOGIN_STATE_KEY_LIST);
