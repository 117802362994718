import { loginSlice } from "./slice";

const actions = loginSlice.actions;

export const {
    phoneVerificationFetchRequest,
    phoneVerificationFetchSuccess,
    phoneVerificationFetchFailure,
    verifyPhoneVerificationCodeFetchRequest,
    verifyPhoneVerificationCodeFetchSuccess,
    verifyPhoneVerificationCodeFetchFailure,
    checkUserExistFetchRequest,
    checkUserExistFetchSuccess,
    checkUserExistFetchFailure,
    generateUserTokenFetchRequest,
    generateUserTokenFetchSuccess,
    generateUserTokenFetchFailure,
    setVerifyPhoneVerificationCodeIsVerified,
    setUserExist,
    setCheckUserExistFetchState,
    reset: resetLoginState,
    setIsFirstLogin,
} = actions;
