import { shopSlice } from "./slice";

const actions = shopSlice.actions;

export const {
    reset: resetShopState,
    shopListFetchRequest,
    shopListFetchSuccess,
    shopListFetchFailure,
    shopBusinessHourFetchRequest,
    shopBusinessHourFetchSuccess,
    shopBusinessHourFetchFailure,
    setSelectedShop,
} = actions;
