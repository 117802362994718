import React from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import { NoticeButton } from "./NoticeButton";
import {
    getNoticePostListFetchState,
    getNoticePostList,
    getNoticePostListPage,
    getNoticePostListTotalPage,
} from "@/redux/post/selectors";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import { fetchMoreNoticePostListThunk } from "@/redux/post/thunk";

const StyledAppLayout = styled(AppLayout)`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
`;

export default function Notice() {
    const dispatch = useDispatch();
    const noticePostListFetchState = useSelector(getNoticePostListFetchState);
    const noticePostList = useSelector(getNoticePostList);
    const noticePostListPage = useSelector(getNoticePostListPage);
    const noticePostListTotalPage = useSelector(getNoticePostListTotalPage);

    const handleFetchMore = () => {
        dispatch(fetchMoreNoticePostListThunk());
    };

    return (
        <StyledAppLayout
            title="공지사항"
            titleColor="#fff"
            disableAppBarBorder
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            <InfiniteScrollList
                fetchState={noticePostListFetchState}
                list={noticePostList}
                page={noticePostListPage}
                totalPage={noticePostListTotalPage}
                showNoResultMessage
                noResultMessage="공지사항이 없어요"
                renderItem={(post, i) => <NoticeButton key={i} post={post} />}
                onLoadMore={handleFetchMore}
            />
        </StyledAppLayout>
    );
}
