import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
    margin: 1rem 0;
    border: 1.5px solid #00000012;
    border-radius: 0.25rem;
    width: 100%;

    background: #ffffff;

    overflow: hidden;
`;

const TabContainer = styled.div`
    display: flex;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Tab = styled.div<{ isSelected: boolean }>`
    flex: 1;

    color: ${(props) => (props.isSelected ? "#ffffff" : "inherit")};
    line-height: 3rem;
    text-align: center;

    background: ${(props) => (props.isSelected ? "#1890ff" : "transparent")};

    cursor: pointer;

    transition: color 0.25s, background 0.25s;

    &:hover {
        color: #ffffff;
        background: #1890ff;
    }
`;

const ContentContainer = styled.div`
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;

    & table {
        font-size: 0.8rem;
    }
`;

const Header = styled.div`
    float: left;

    margin-right: 0.5rem;

    color: rgba(0, 0, 0, 0.85);
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2.75rem;
`;

const Left = styled.div`
    float: left;
`;

const Right = styled.div`
    float: right;
`;

const Clear = styled.div`
    float: clear;
`;

const Content = styled.div<{ marginTop: boolean }>`
    margin-top: ${(props) => (props.marginTop ? "1rem" : "0")};
`;

interface Props {
    header?: string;
    left?: React.ReactNode;
    right?: React.ReactNode;
    tab?: {
        menu: string[];
        default: string;
        disabled: boolean;
        onSelect: (menu: string) => void;
    };
    children?: React.ReactNode;
}

export default function Component(props: Props): React.ReactElement {
    // 스테이트.

    const [tab, setTab] = React.useState<string>(props.tab ? props.tab.default : "");

    // 컴포넌트.

    return (
        <Container>
            {props.tab && (
                <TabContainer>
                    {props.tab.menu.map((menu) => (
                        <Tab
                            key={menu}
                            isSelected={tab === menu}
                            onClick={() => {
                                if (props.tab?.disabled) {
                                    return;
                                }

                                setTab(menu);
                                props.tab?.onSelect(menu);
                            }}
                        >
                            {menu}
                        </Tab>
                    ))}
                </TabContainer>
            )}
            <ContentContainer>
                {props.header && <Header>{props.header}</Header>}
                {props.left && <Left>{props.left}</Left>}
                {props.right && <Right>{props.right}</Right>}
                <Clear />
                {props.children && <Content marginTop={!!props.header}>{props.children}</Content>}
            </ContentContainer>
        </Container>
    );
}
