import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";

export const TodayStatisticsContainer = styled(ButtonBase)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin-top: 16px;
    width: 100%;
    height: 56px;

    border-radius: 8px;

    background: white;
    color: black;
`;

export const TodayStatisticsTitleContainer = styled.div`
    font-size: 14px;
    color: #0c0c0c;
    font-weight: bold;
`;

export const CoffeeIconImg = styled.img`
    margin-bottom: 3px;
    margin-right: 8px;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    align-items: center;

    color: #0c0c0c;
    font-size: 12px;
    text-align: center;
`;

export const AmountContainer = styled.div`
    margin-bottom: 3px;
    font-weight: bold;
    font-size: 20px;
`;
