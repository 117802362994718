import { CupType, MenuOptionGroup, ShopResponse } from "@ferda/rest-api-client";

export function getOptionGroupMapFromOptionList(
    optionGroupList: MenuOptionGroup[],
    persistValue?: boolean,
): Record<string, MenuOptionGroup> {
    return optionGroupList.reduce(
        (prev, optionGroup) => ({
            ...prev,
            [optionGroup.name]: {
                ...optionGroup,
                options: persistValue ? optionGroup.options : [],
            },
        }),
        {},
    );
}

export function checkCupAvaliable(
    { reusable_cup_available, disposable_cup_available }: ShopResponse,
    cupType?: CupType,
) {
    return (
        (!reusable_cup_available && cupType === CupType.REUSABLE) ||
        (!disposable_cup_available && cupType === CupType.DISPOSABLE)
    );
}

export function formatCupType(cupType?: CupType) {
    if (!cupType) {
        return "";
    }
    switch (cupType) {
        case CupType.DISPOSABLE: {
            return "뉴";
        }
        case CupType.INDIVIDUAL: {
            return "개인";
        }
        case CupType.REUSABLE: {
            return "리턴";
        }
    }
}
