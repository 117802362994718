import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { CreateUserRequest } from "packages/ferda-rest-api-client/dist";
import { RootState } from "../types";
import {
    checkUserExistFetchFailure,
    checkUserExistFetchRequest,
    checkUserExistFetchSuccess,
    generateUserTokenFetchFailure,
    generateUserTokenFetchRequest,
    generateUserTokenFetchSuccess,
    phoneVerificationFetchFailure,
    phoneVerificationFetchRequest,
    phoneVerificationFetchSuccess,
    setIsFirstLogin,
    verifyPhoneVerificationCodeFetchFailure,
    verifyPhoneVerificationCodeFetchRequest,
    verifyPhoneVerificationCodeFetchSuccess,
} from "./actions";
import {
    CheckUserExistFetchRequestPayload,
    GenerateUserTokenFetchRequestPayload,
    PhoneVerificationFetchRequestPayload,
    VerifyPhoneVerificationCodeFetchRequestPayload,
} from "./types";
import { openErrorSnackbar } from "../snackbar/actions";
import { saveOauth2TokenToLocalStorage } from "../auth/utils";
import { loadAuthStateAndApiClientThunk } from "../auth/thunk";

type AuthThunkAction = ThunkAction<void, RootState, unknown, AnyAction>;

export function phoneVerificationFetchRequestThunk({
    phoneNumber,
}: PhoneVerificationFetchRequestPayload): AuthThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { verificationApi, userApi },
            },
            login: { userExist },
        } = getState();

        // 카카오 로그인은 페이지 로딩하자마자 userExist 값이 할당되므로 인증번호 전송 시점에 핸드폰 로그인 userExist 여부 체크
        if (userExist === null) {
            let res;

            try {
                res = await userApi.checkUserExists({
                    phone_number: phoneNumber,
                });
            } catch (e: unknown) {
                if (axios.isAxiosError(e)) {
                    dispatch(checkUserExistFetchFailure(e));
                }
                throw e;
            }

            dispatch(checkUserExistFetchSuccess({ exists: res.data.exists }));
        }

        dispatch(phoneVerificationFetchRequest());

        try {
            await verificationApi.sendPhoneNumberVerificationCode({
                phone_number: phoneNumber,
            });
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                dispatch(phoneVerificationFetchFailure(e));
                if (e.response) {
                    dispatch(openErrorSnackbar(e.response.data.detail));
                }
            }
            throw e;
        }

        dispatch(phoneVerificationFetchSuccess());
    };
}

export function verifyPhoneVerificationCodeFetchRequestThunk({
    phoneNumber,
    verificationCode,
}: VerifyPhoneVerificationCodeFetchRequestPayload): AuthThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { verificationApi },
            },
        } = getState();

        dispatch(verifyPhoneVerificationCodeFetchRequest());

        let res;

        try {
            res = await verificationApi.verifyPhoneNumberVerificationCode({
                phone_number: phoneNumber,
                phone_number_verification_code: verificationCode,
            });
        } catch (e: unknown) {
            if (e instanceof Error) {
                dispatch(verifyPhoneVerificationCodeFetchFailure(e));
                return;
            }
            throw e;
        }

        dispatch(verifyPhoneVerificationCodeFetchSuccess({ verified: res.data.verified }));
    };
}

export function generateUserTokenFetchRequestThunk(payload: GenerateUserTokenFetchRequestPayload): AuthThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { userApi, oauth2Api },
            },
            login: { userExist },
        } = getState();

        dispatch(generateUserTokenFetchRequest());

        if (userExist === null) {
            return;
        }

        // 유저 정보가 없는경우 create
        if (userExist === false) {
            dispatch(setIsFirstLogin(true));
            try {
                if (!payload.phoneNumber || !payload.verificationCode) {
                    return;
                }
                const createUserPostParams: CreateUserRequest = {
                    phone_number: payload.phoneNumber,
                    phone_number_verification_code: payload.verificationCode,
                    consent_receive_email: payload.consentReceiveEmail || false,
                    consent_receive_sms: payload.consentReceiveSms || false,
                    consent_receive_sns_message: payload.consentReceiveSnsMessage || false,
                };

                if (payload.socialLoginAuthentication) {
                    createUserPostParams.social_login_authentication = payload.socialLoginAuthentication;
                }
                await userApi.createUser(createUserPostParams);
            } catch (e: unknown) {
                if (axios.isAxiosError(e)) {
                    dispatch(generateUserTokenFetchFailure(e));

                    if (e.response) {
                        dispatch(openErrorSnackbar(e.response.data.detail));
                    }
                }
                throw e;
            }
        }

        try {
            const {
                data: { access_token, access_token_expire_datetime, refresh_token, refresh_token_expire_datetime },
            } = await oauth2Api.generateToken(payload.username, payload.password);

            saveOauth2TokenToLocalStorage({
                token: access_token,
                tokenExpireDate: new Date(access_token_expire_datetime),
                refreshToken: refresh_token,
                refreshTokenExpireDate: new Date(refresh_token_expire_datetime),
            });

            dispatch(
                loadAuthStateAndApiClientThunk(() => {
                    dispatch(generateUserTokenFetchSuccess());
                }),
            );
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                dispatch(generateUserTokenFetchFailure(e));
            }
            throw e;
        }
    };
}

export function checkUserExistFetchRequestThunk(payload: CheckUserExistFetchRequestPayload): AuthThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { userApi },
            },
        } = getState();

        dispatch(checkUserExistFetchRequest());
        let res;

        // 일반 유저
        if (payload.phoneNumber) {
            try {
                res = await userApi.checkUserExists({
                    phone_number: payload.phoneNumber,
                });
            } catch (e: unknown) {
                if (axios.isAxiosError(e)) {
                    dispatch(checkUserExistFetchFailure(e));
                }
                throw e;
            }

            dispatch(checkUserExistFetchSuccess({ exists: res.data.exists }));
            return;
        }

        try {
            res = await userApi.checkUserExists({
                social_login_authentication: payload.socialLoginData,
            });
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                dispatch(checkUserExistFetchFailure(e));
            }
            throw e;
        }

        dispatch(checkUserExistFetchSuccess({ exists: res.data.exists }));

        if (payload.callback) {
            payload.callback(res.data.exists);
        }
    };
}
