import { SubscribeStatusResponse } from "@ferda/rest-api-client";
import { differenceInDays, format } from "date-fns";
import React, { useMemo } from "react";
import { linearProgressClasses } from "@mui/material";
import {
    StyledLinkButton,
    StyledProgressBar,
    SubscriptionContainer,
    TicketImgContainer,
    TicketImg,
    SubscriptionTitle,
    StyledDivider,
    PeriodLeftContainer,
    PeriodContainer,
    PeriodTitle,
    PeriodDate,
    RefreshRemainContainer,
    RefreshRemainDateContainer,
} from "./style";
import TicketImage from "@/assets/img/img_ticket.svg";

interface SubscribeInfoContentProps {
    subscription: SubscribeStatusResponse;
    buttonLink: string;
    buttonText: string;
    buttonOnClick?: () => void;
}

export function SubscribeInfoContent({
    subscription: { subscription_in_use, start_datetime, expire_datetime },
    buttonText,
    buttonLink,
    buttonOnClick,
}: SubscribeInfoContentProps) {
    const endDate = useMemo(() => new Date(expire_datetime!), [expire_datetime]);
    const startDate = useMemo(() => new Date(start_datetime!), [start_datetime]);

    const periodString = useMemo(() => {
        if (!expire_datetime || !start_datetime) {
            return "";
        }

        return `${format(startDate, "yyyy.MM.dd")} ~ ${format(endDate, "yyyy.MM.dd")}`;
    }, [expire_datetime, start_datetime, endDate, startDate]);

    const dateDiff = useMemo(() => differenceInDays(endDate, new Date()), [endDate]);

    if (!subscription_in_use) {
        return null;
    }

    return (
        <SubscriptionContainer>
            <TicketImgContainer>
                <TicketImg src={TicketImage} />
            </TicketImgContainer>
            <SubscriptionTitle>{subscription_in_use.name}</SubscriptionTitle>
            <StyledDivider />
            <PeriodLeftContainer>
                <PeriodContainer>
                    <PeriodTitle>이용기간</PeriodTitle>
                    <PeriodDate>{periodString}</PeriodDate>
                </PeriodContainer>
                <StyledProgressBar
                    sx={{
                        [`&.${linearProgressClasses.colorPrimary}`]: {
                            backgroundColor: "#2d2d2d",
                        },
                        [`& .${linearProgressClasses.bar}`]: {
                            backgroundColor: "#ff831e",
                            borderRadius: 5,
                        },
                    }}
                    variant="determinate"
                    value={100 - 100 * (dateDiff / differenceInDays(endDate, startDate))}
                />
                <RefreshRemainContainer>
                    이용권 갱신까지 <RefreshRemainDateContainer>{dateDiff}일</RefreshRemainDateContainer> 남았습니다
                </RefreshRemainContainer>
            </PeriodLeftContainer>
            <StyledLinkButton href={buttonLink} title={buttonText} onClick={buttonOnClick} />
        </SubscriptionContainer>
    );
}
