import React, { useEffect, useRef } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import useHistory from "@/hooks/useCustomHistory";
import AppLayout from "@/containers/AppLayout";
import { BackButton } from "@/components/IconButton";
import {
    HistoryMenuItemBottomWrapper,
    HistoryMenuItemContent,
    HistoryMenuItemImage,
    HistoryMenuItemStatus,
    HistoryMenuItemTextDate,
    HistoryMenuItemTextShop,
    HistoryMenuItemTextTitle,
    HistoryMenuItemTextWrapper,
    HistoryMenuItemTopWrapper,
    HistoryMenuItemWrapper,
    HistoryMenuWrapper,
    HistoryTopSubtitle,
    HistoryTopTitleImage,
    HistoryTopTitleText,
    HistoryTopTitleWrapper,
    HistoryTopWrapper,
    HistoryWrapper,
} from "./style";
import CupImage from "@/assets/img/ic_beverage_middle.svg";
import BarDateRangePicker from "@/components/BarDateRangePicker";
import { getToday } from "@/utils/time";
import { orderHistoryRequestThunk } from "@/redux/history/thunks";
import {
    getOrderHistoryList,
    getOrderHistoryListPage,
    getOrderHistoryListRequestFetchState,
    getOrderHistoryListTotalPage,
} from "@/redux/history/selectors";
import { reset } from "@/redux/history/actions";
import { getHistoryMenuItemStatusText } from "@/utils/orderHistory";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import { formatISODate } from "@/utils/date";
import PlaceholderImage from "@/assets/img/place_holder.png";
import { getAuthState } from "@/redux/auth/selectors";

export default function History() {
    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useSelector(getAuthState);
    const startDay = userInfo.user?.created_datetime ? new Date(userInfo.user?.created_datetime) : getToday();
    const [startDate, setStartDate] = React.useState<Date | null>(startDay);
    const [endDate, setEndDate] = React.useState<Date | null>(getToday());
    const isFirstRun = useRef(true);

    const orderHistoryList = useSelector(getOrderHistoryList);
    const orderHistoryListPage = useSelector(getOrderHistoryListPage);
    const orderHistoryListTotalPage = useSelector(getOrderHistoryListTotalPage);
    const orderHistoryListRequestFetchState = useSelector(getOrderHistoryListRequestFetchState);

    const handleSetDate = (sd: Date | null, ed: Date | null) => {
        setStartDate(sd);
        setEndDate(ed);
    };

    const handleClickMenu = (id: number) => history.push(`/history/${id}`);

    const handleLoadMore = () => {
        if (!startDate || !endDate) {
            return;
        }

        const minDate = formatISODate(startDate);
        const maxDate = formatISODate(endDate);

        dispatch(orderHistoryRequestThunk({ minCreatedDate: minDate, maxCreatedDate: maxDate }));
    };

    useEffect(() => {
        if (!startDate || !endDate) {
            return;
        }
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        dispatch(reset());
        const minDate = formatISODate(startDate);
        const maxDate = formatISODate(endDate);

        dispatch(orderHistoryRequestThunk({ minCreatedDate: minDate, maxCreatedDate: maxDate }));
    }, [startDate, endDate]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <AppLayout
            title="히스토리"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            titleColor="#fff"
            fullHeight
        >
            <HistoryWrapper>
                <HistoryTopWrapper>
                    <HistoryTopTitleWrapper>
                        <HistoryTopTitleImage src={CupImage} alt="cup icon" />
                        <HistoryTopTitleText>히스토리</HistoryTopTitleText>
                    </HistoryTopTitleWrapper>
                    <HistoryTopSubtitle>주문 내역을 확인할 수 있어요</HistoryTopSubtitle>
                </HistoryTopWrapper>

                <BarDateRangePicker startDate={startDate} endDate={endDate} setDate={handleSetDate} />

                <HistoryMenuWrapper>
                    <InfiniteScrollList
                        fetchState={orderHistoryListRequestFetchState}
                        list={orderHistoryList}
                        page={orderHistoryListPage}
                        totalPage={orderHistoryListTotalPage}
                        showNoResultMessage
                        noResultMessage="히스토리가 없어요"
                        renderItem={({
                            id,
                            menu: { uploaded_image_set, name },
                            shop,
                            order_status,
                            created_datetime,
                        }) => (
                            <HistoryMenuItemWrapper onClick={() => handleClickMenu(id)} key={id}>
                                <HistoryMenuItemContent>
                                    <HistoryMenuItemImage>
                                        <img
                                            src={uploaded_image_set?.original_image_url || PlaceholderImage}
                                            alt="coffee"
                                        />
                                    </HistoryMenuItemImage>
                                    <HistoryMenuItemTextWrapper>
                                        <HistoryMenuItemTopWrapper>
                                            <HistoryMenuItemStatus status={order_status}>
                                                {getHistoryMenuItemStatusText(order_status)}
                                            </HistoryMenuItemStatus>
                                        </HistoryMenuItemTopWrapper>
                                        <HistoryMenuItemTextTitle>{name}</HistoryMenuItemTextTitle>
                                        <HistoryMenuItemBottomWrapper>
                                            <HistoryMenuItemTextShop>{shop.name}</HistoryMenuItemTextShop>
                                            <HistoryMenuItemTextDate>
                                                {created_datetime &&
                                                    format(new Date(created_datetime), "yyyy.MM.dd HH:mm")}
                                            </HistoryMenuItemTextDate>
                                        </HistoryMenuItemBottomWrapper>
                                    </HistoryMenuItemTextWrapper>
                                </HistoryMenuItemContent>
                            </HistoryMenuItemWrapper>
                        )}
                        onLoadMore={handleLoadMore}
                    />
                </HistoryMenuWrapper>
            </HistoryWrapper>
        </AppLayout>
    );
}
