import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";

export const StyledLinkButton = styled(ButtonBase)`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;

    color: #0c0c0c;
    font-size: 16px;

    border-radius: 8px;
`;

export const IconImg = styled.img`
    height: 24px;
    width: 24px;
    margin-right: 20px;
`;

export const Title = styled.div`
    flex-grow: 1;

    text-align: left;
`;
