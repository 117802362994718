import styled from "@emotion/styled";
import React from "react";

const PageInfoHeaderContainer = styled.div`
    padding-top: 48px;
    padding-left: 20px;
    padding-bottom: 36px;
    padding-right: 20px;

    background-color: white;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const TitleIconImg = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: #0c0c0c;
`;

const Description = styled.div`
    height: 46px;
    margin-top: 12px;

    font-size: 16px;
    color: #0c0c0c;
`;

interface PageInfoHeader {
    title: string;
    description?: string;
    iconSrc?: string;
}

export default function PageInfoHeader({ title, description, iconSrc }: PageInfoHeader) {
    return (
        <PageInfoHeaderContainer>
            <TitleContainer>
                {iconSrc && <TitleIconImg src={iconSrc} />}
                <Title>{title}</Title>
            </TitleContainer>
            {description && <Description>{description}</Description>}
        </PageInfoHeaderContainer>
    );
}
