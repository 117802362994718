import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { BackButton } from "@/components/IconButton";
import IconCheckboxChecked from "@/assets/img/ic_checkbox_checked.svg";
import AppLayout from "@/containers/AppLayout";
import {
    AlertModalText,
    AvailableMenuContainer,
    AvailableMenuContentContainer,
    AvailableMenuTitleContainer,
    CheckboxIconImg,
    CoffeeGiftImageWrapper,
    CoffeeGiftStampImage,
    CoffeeGiftWrapper,
    DescriptionContainer,
    DescriptionRow,
    GiftDateText,
    GiftDateTitle,
    GiftDateWrapper,
    GiftInfoDivider,
    GiftInfoTitle,
    GiftInfoWrapper,
    GiftOrderInfoNumber,
    GiftOrderInfoStatusText,
    GiftOrderInfoTextDivider,
    GiftOrderInfoTitle,
    GiftOrderInfoWrapper,
    OptionButtonContainer,
    Title,
    TitleContainer,
    TopImageWrapper,
    TopNameText,
    TopWrapper,
    Wrapper,
} from "./style";
import GiftBoxIcon from "@/assets/img/img_giftbox.svg";
import Modal from "@/components/Modal";
import ConfirmIcon from "@/assets/img/img_confirm_modal_check.svg";
import StampIcon from "@/assets/img/img_stamp.png";
import { getGiftSubscribeLogDetailInfo, getGiftSubscribeOrderFetchState } from "@/redux/giftLog/selectors";
import { giftSubscribeLogDetailRequestThunk, giftSubscribeOrderRequestThunk } from "@/redux/giftLog/thunks";
import { reset } from "@/redux/giftLog/actions";
import { BottomConfirmButtonContainer, ConfirmButton } from "@/components/Button/style";

export default function GiftSubscribeGetDetail() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { giftId } = useParams<{ giftId: string }>();
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [completeModal, setCompleteModal] = useState<boolean>(false);
    const detailInfo = useSelector(getGiftSubscribeLogDetailInfo);
    const { enqueueSnackbar } = useSnackbar();

    const availableMenuList = useMemo(() => {
        if (!detailInfo) {
            return [];
        }

        if (detailInfo.subscription.id === 1) {
            return ["아메리카노"];
        }
        if (detailInfo.subscription.id === 2) {
            return ["모든 제조음료"];
        }

        return [];
    }, [giftId, detailInfo]);

    const giftSubscribeOrderFetchState = useSelector(getGiftSubscribeOrderFetchState);

    const handleClickOrder = () => {
        setConfirmModal(true);
    };

    const handleClickUseButton = () => {
        dispatch(giftSubscribeOrderRequestThunk(Number(giftId), enqueueSnackbar));
    };

    useEffect(() => {
        if (!giftId) {
            return;
        }
        dispatch(giftSubscribeLogDetailRequestThunk(Number(giftId)));

        return () => {
            dispatch(reset());
        };
    }, []);

    useEffect(() => {
        if (giftSubscribeOrderFetchState === "SUCCESS") {
            setConfirmModal(false);
            setCompleteModal(true);
        }
    }, [giftSubscribeOrderFetchState]);

    return (
        <AppLayout
            disableAppBarBorder={true}
            title="받은 선물"
            titleColor="#fff"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            fullHeight
        >
            <Modal
                open={confirmModal}
                handleClose={() => setConfirmModal(false)}
                buttonOnClick={handleClickUseButton}
                cancelButtonOnClick={() => setConfirmModal(false)}
                titleText="선물을 사용하시겠어요?"
                buttonText="사용하기"
                cancelButtonText="취소"
                fullSizeButton
            >
                <AlertModalText>
                    사용한 선물을 되돌릴 수 없습니다.
                    <br />
                    정말 사용하시겠습니까?
                </AlertModalText>
            </Modal>
            <Modal
                open={completeModal}
                handleClose={() => setConfirmModal(false)}
                buttonOnClick={() => history.push("/")}
                titleText="사용이 완료되었어요"
                buttonText="홈으로 가기"
                topImage={ConfirmIcon}
                fullSizeButton
            >
                <AlertModalText>구독권을 이용하여 메뉴를 주문해보세요.</AlertModalText>
            </Modal>
            {detailInfo && (
                <Wrapper used={Boolean(detailInfo.used_datetime)}>
                    <TopWrapper>
                        <TopImageWrapper>
                            <img src={GiftBoxIcon} alt="gift box icon" />
                        </TopImageWrapper>
                        <TopNameText>{detailInfo.gifted_user.detail?.name || "-"}님의 선물</TopNameText>
                    </TopWrapper>

                    <CoffeeGiftWrapper>
                        {/* <CoffeeGiftBackgroundImage src={CoffeeBackgroundImage} alt="background" /> */}
                        <CoffeeGiftImageWrapper>
                            {detailInfo.used_datetime && <CoffeeGiftStampImage src={StampIcon} alt="stamp" />}
                            <OptionButtonContainer>
                                <TitleContainer>
                                    <Title>{detailInfo.subscription.name}</Title>
                                    {/* <Price>1주 {comma(price)}원</Price> */}
                                </TitleContainer>
                                <AvailableMenuContainer>
                                    <AvailableMenuTitleContainer>이용 가능한 메뉴</AvailableMenuTitleContainer>
                                    <AvailableMenuContentContainer>
                                        {availableMenuList.join(", ")}
                                    </AvailableMenuContentContainer>
                                </AvailableMenuContainer>

                                <DescriptionContainer>
                                    {[
                                        "5일권은 평일 기준 5일이며, 주말은 자유롭게 사용하세요. (총 7일 서비스 제공)",
                                        "연차 홀딩 가능 (5일권 - 최대 1일 / 30일권 - 최대 4일)",
                                        "법정 공휴일 자동 추가 연장",
                                        "매장 운영 종료 시 사용불가",
                                    ].map((description, i) => (
                                        <DescriptionRow key={i}>
                                            <CheckboxIconImg src={IconCheckboxChecked} />
                                            {description}
                                        </DescriptionRow>
                                    ))}
                                </DescriptionContainer>
                            </OptionButtonContainer>
                        </CoffeeGiftImageWrapper>
                    </CoffeeGiftWrapper>

                    <GiftInfoWrapper>
                        <GiftInfoTitle>{detailInfo.subscription.name}</GiftInfoTitle>
                        {/* <GiftInfoSubtitle>
                            {detailInfo.amount}잔{detailInfo.temperature_type && ` / ${detailInfo.temperature_type}`}
                        </GiftInfoSubtitle> */}

                        <GiftOrderInfoWrapper>
                            <GiftOrderInfoTitle>주문번호</GiftOrderInfoTitle>
                            <GiftOrderInfoNumber>{detailInfo.id}</GiftOrderInfoNumber>
                            <GiftOrderInfoTextDivider />
                            <GiftOrderInfoStatusText>
                                {detailInfo.used_datetime ? "사용완료" : "미사용"}
                            </GiftOrderInfoStatusText>
                        </GiftOrderInfoWrapper>
                        <GiftInfoDivider />

                        <GiftDateWrapper>
                            <GiftDateTitle>선물주문일</GiftDateTitle>
                            <GiftDateText>
                                {format(new Date(detailInfo.created_datetime), "yyyy년 MM월 dd일")}
                            </GiftDateText>
                        </GiftDateWrapper>
                        <GiftInfoDivider />

                        {/* <GiftDateWrapper>
                            <GiftDateTitle>유효기간</GiftDateTitle>
                            <GiftDateText>2021년 11월 26일</GiftDateText>
                        </GiftDateWrapper>
                        <GiftInfoDivider /> */}

                        {detailInfo.used_datetime && (
                            <>
                                <GiftDateWrapper>
                                    <GiftDateTitle>이용날짜</GiftDateTitle>
                                    <GiftDateText>
                                        {format(new Date(detailInfo.used_datetime), "yyyy년 MM월 dd일")}
                                    </GiftDateText>
                                </GiftDateWrapper>
                                <GiftInfoDivider />

                                {/* <GiftDateWrapper>
                                    <GiftDateTitle>이용매장</GiftDateTitle>
                                    <GiftDateText>{detailInfo.orders[0].shop.name}</GiftDateText>
                                </GiftDateWrapper>
                                <GiftInfoDivider /> */}
                            </>
                        )}
                        {/* {!detailInfo.used_datetime && (
                            <StyledFindStoreInput
                                value={store}
                                handleValue={handleChangeStore}
                                placeholder="주문할 매장을 선택해주세요"
                                inputBackgroundColor="#2d2d2d"
                                selectedMenuId={Number(giftId)}
                            />
                        )} */}
                    </GiftInfoWrapper>

                    {!detailInfo.used_datetime && (
                        <BottomConfirmButtonContainer>
                            <ConfirmButton variant="contained" backgroundColor="#ff831e" onClick={handleClickOrder}>
                                사용하기
                            </ConfirmButton>
                        </BottomConfirmButtonContainer>
                    )}
                </Wrapper>
            )}
        </AppLayout>
    );
}
