import React from "react";
import moment from "moment";
import "moment/locale/ko";
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, Modal, Radio, Row, Select } from "antd";
import { GiftResponse, MenuOption, MenuOptionGroup, MenuResponse, TemperatureType } from "@ferda/rest-api-client";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";
import { temperatureTypeToText } from "@/utils/enumToText";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import FormItem from "@/components/Admin/FormItem";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import Tab from "@/components/Admin/Tab";
import StatusBadge from "@/components/Admin/StatusBadge";
import { Clear, Left, Right } from "@/components/Admin/Float";
import {
    MenuOptionContainer,
    MenuOptionGroupTitle,
    MenuOptionList,
    MenuOptionListItem,
    ModalMenuOptionGroup,
    ModalMenuOptionGroupTitle,
    ModalMenuOptionItem,
    ModalMenuOptionItemName,
    ModalMenuOptionItemPrice,
    SearchBarContainer,
} from "./GiftsStyled";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props {}

export default function Page(props: Props) {
    const params = useParams<{ userId: string }>();

    const { shopApi, userApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalGift, setModalGift] = React.useState<GiftResponse | undefined>();
    const [modalMenuList, setModalMenuList] = React.useState<MenuResponse[]>([]);
    const [modalMenuListTotal, setModalMenuListTotal] = React.useState<number>(0);
    const [modalMenuPage, setModalMenuPage] = React.useState<number>(1);
    const [modalMenuKeyword, setModalMenuKeyword] = React.useState<string | undefined>();
    const [modalAddMenu, setModalAddMenu] = React.useState<MenuResponse | undefined>();
    const [modalAddMenuOption, setModalAddMenuOptions] = React.useState<MenuOptionGroup[]>([]);

    const [giftList, setGiftList] = React.useState<GiftResponse[]>([]);
    const [giftListTotal, setGiftListTotal] = React.useState<number>(0);

    const [menu, setMenu] = React.useState<MenuResponse | undefined>();

    const [tab, setTab] = React.useState<"sent" | "received">("received");
    const [isTabChanging, setIsTabChanging] = React.useState<boolean>(false);

    const [menuId, setMenuId] = React.useState<number | undefined>();
    const [temperatureType, setTemperatureType] = React.useState<TemperatureType[] | undefined>();
    const [minCreatedDate, setMinCreatedDate] = React.useState<Date | undefined>();
    const [maxCreatedDate, setMaxCreatedDate] = React.useState<Date | undefined>();
    const [page, setPage] = React.useState<number>(1);

    const [searchForm] = Form.useForm();
    const [menuForm] = Form.useForm();
    const [addForm] = Form.useForm();

    const temperatureTypeToColor = (temperatureType: TemperatureType): string => {
        switch (temperatureType) {
            case TemperatureType.HOT:
                return "#d75959";
            case TemperatureType.ICED:
                return "#59a8d7";
        }
    };

    const columns: any[] = [
        {
            title: "생성시각",
            key: "createdDatetime",
            render: (record: GiftResponse) => {
                const time = moment(record.created_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "사용시각",
            key: "usedDatetime",
            render: (record: GiftResponse) => {
                if (!record.used_datetime) {
                    return { children: undefined };
                }

                const time = moment(record.used_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
    ];

    if (tab === "received" || (tab === "sent" && isTabChanging)) {
        columns.push(
            {
                title: "보낸사람",
                key: "sender",
                render: (record: GiftResponse) => {
                    return { children: record.gift_given_name };
                },
                width: "100px",
            },
            {
                title: "보낸사람 휴대폰번호",
                key: "senderPhone",
                render: (record: GiftResponse) => {
                    return { children: record.gift_given_phone_number };
                },
                width: "150px",
            },
        );
    } else {
        columns.push(
            {
                title: "받은사람",
                key: "receiver",
                render: (record: GiftResponse) => {
                    return { children: record.gifted_user.detail?.name };
                },
                width: "100px",
            },
            {
                title: "받은사람 휴대폰번호",
                key: "receiverPhone",
                render: (record: GiftResponse) => {
                    return { children: record.gifted_user.detail?.phone_number };
                },
                width: "150px",
            },
        );
    }

    columns.push(
        {
            title: "메뉴",
            key: "menu",
            render: (record: GiftResponse) => {
                return {
                    children: `${record.menu.name} (${record.amount}개)`,
                };
            },
        },
        {
            title: "메뉴옵션",
            key: "options",
            render: (record: GiftResponse) => {
                return {
                    children: record.option_groups.map((group, groupIndex) => (
                        <MenuOptionContainer key={groupIndex}>
                            <MenuOptionGroupTitle>{group.name}</MenuOptionGroupTitle>
                            <MenuOptionList>
                                {group.options.map((option, optionIndex) => (
                                    <MenuOptionListItem key={optionIndex}>{option.name}</MenuOptionListItem>
                                ))}
                            </MenuOptionList>
                        </MenuOptionContainer>
                    )),
                };
            },
            width: "200px",
        },
        {
            title: "온도",
            key: "temperature",
            render: (record: GiftResponse) => {
                return {
                    children: record.temperature_type && (
                        <StatusBadge color={temperatureTypeToColor(record.temperature_type)}>
                            {temperatureTypeToText(record.temperature_type)}
                        </StatusBadge>
                    ),
                };
            },
            width: "80px",
        },
        {
            title: "관리",
            key: "manage",
            render: (record: GiftResponse) => {
                return {
                    children: (
                        <Button
                            danger
                            disabled={loading}
                            onClick={() => {
                                setModal("delete");
                                setModalGift(record);
                            }}
                        >
                            삭제
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    );

    const menuColumns = [
        {
            title: "사진",
            key: "image",
            render: (record: MenuResponse) => {
                return {
                    children: record.uploaded_image_set && (
                        <img src={record.uploaded_image_set.original_image_url} width={64} height={64} />
                    ),
                };
            },
            width: "70px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: MenuResponse) => {
                return {
                    children: record.name,
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: MenuResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setMenu(record);
                                if (modal === "add-menu") {
                                    setModal("add");
                                    setModalAddMenu(record);
                                    setModalAddMenuOptions([]);
                                    addForm.setFieldsValue({ menu: record.name });
                                } else {
                                    setModal(undefined);
                                    searchForm.setFieldsValue({ menu: record.name });
                                }
                            }}
                        >
                            선택
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getGifts(
                tab === "sent",
                tab === "received",
                menuId,
                temperatureType,
                minCreatedDate && moment(minCreatedDate).format("YYYY-MM-DD"),
                maxCreatedDate && moment(minCreatedDate).format("YYYY-MM-DD"),
                page,
                params.userId,
            )
            .then((res) => {
                setLoading(false);
                setGiftList(res.data);
                setGiftListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("선물 목록을 불러오는데 실패하였습니다.");
            });
    }, [tab, menuId, temperatureType, minCreatedDate, maxCreatedDate, page, rerender]);

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getMenus(undefined, modalMenuKeyword, false, undefined, undefined, modalMenuPage)
            .then((res) => {
                setLoading(false);
                setIsTabChanging(false);
                setModalMenuList(res.data);
                setModalMenuListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                setIsTabChanging(false);
                message.error("메뉴 목록을 불러오는데 실패하였습니다.");
            });
    }, [modalMenuKeyword, modalMenuPage]);

    return (
        <>
            <PageTitle>선물 내역</PageTitle>
            <Container>
                <Form
                    form={searchForm}
                    onFinish={(values) => {
                        const menuId = menu ? menu.id : undefined;
                        const temperatureType = values.temperatureType || undefined;
                        const minCreatedDate = values.date && values.date[0] ? values.date[0].toDate() : undefined;
                        const maxCreatedDate = values.date && values.date[1] ? values.date[1].toDate() : undefined;

                        setMenuId(menuId);
                        setTemperatureType(temperatureType);
                        setMinCreatedDate(minCreatedDate);
                        setMaxCreatedDate(maxCreatedDate);
                        setPage(1);
                    }}
                >
                    <Left>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="date">
                                    <RangePicker
                                        disabled={loading}
                                        placeholder={["시작 일자", "끝 일자"]}
                                        style={{ width: "300px" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="menu">
                                    <Input
                                        disabled={loading}
                                        onFocus={(e) => {
                                            setModal("menu");
                                            setModalMenuKeyword(undefined);
                                            setModalMenuPage(1);
                                            menuForm.resetFields();
                                            e.target.blur();
                                        }}
                                        placeholder="메뉴"
                                        readOnly
                                        style={{ width: "200px" }}
                                        value={menu?.name}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="temperatureType">
                                    <Select
                                        mode="multiple"
                                        disabled={loading}
                                        placeholder="온도"
                                        style={{ width: "150px" }}
                                    >
                                        <Option value={TemperatureType.HOT}>
                                            {temperatureTypeToText(TemperatureType.HOT)}
                                        </Option>
                                        <Option value={TemperatureType.ICED}>
                                            {temperatureTypeToText(TemperatureType.ICED)}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Left>
                    <Right>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        htmlType="reset"
                                        onClick={() => {
                                            searchForm.resetFields();

                                            setMinCreatedDate(undefined);
                                            setMaxCreatedDate(undefined);
                                            setTemperatureType(undefined);
                                            setMenu(undefined);
                                            setMenuId(undefined);
                                            setPage(1);
                                        }}
                                    >
                                        초기화
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "1.5rem" }}>
                                <Form.Item>
                                    <Button disabled={loading} htmlType="submit">
                                        검색
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        onClick={() => {
                                            setModal("add");
                                            setModalAddMenu(undefined);
                                            setModalAddMenuOptions([]);
                                            addForm.resetFields();
                                        }}
                                    >
                                        선물 하기
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Right>
                    <Clear />
                </Form>
            </Container>
            <Container>
                <Tab
                    defaultActiveKey="unanswered"
                    onChange={(key) => {
                        switch (key) {
                            case "received":
                                setTab("received");
                                break;
                            case "sent":
                                setTab("sent");
                                break;
                        }
                        setIsTabChanging(true);
                        setPage(1);
                    }}
                    tabs={[
                        {
                            title: "받은선물",
                            key: "received",
                        },
                        {
                            title: "보낸선물",
                            key: "sent",
                        },
                    ]}
                />
                <Table columns={columns} dataSource={giftList} loading={loading} rowKey="id" />
                {giftListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={giftListTotal}
                    />
                )}
            </Container>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    style: { display: "none" },
                }}
                onCancel={() => setModal(undefined)}
                title="메뉴 선택"
                visible={modal === "menu" || modal === "add-menu"}
                width={800}
            >
                <Form
                    form={menuForm}
                    onFinish={(values) => {
                        if (!values.keyword) {
                            return message.error("검색어를 입력해주세요.", 1.5);
                        }

                        setModalMenuKeyword(values.keyword);
                        setModalMenuPage(1);
                    }}
                >
                    <SearchBarContainer>
                        <Form.Item name="keyword" style={{ marginBottom: "0", marginRight: "0.5rem", width: "100%" }}>
                            <Input placeholder="검색어" style={{ width: "100%" }} />
                        </Form.Item>
                        <Button disabled={loading} htmlType="submit">
                            검색
                        </Button>
                    </SearchBarContainer>
                </Form>
                <Table columns={menuColumns} dataSource={modalMenuList} loading={loading} rowKey="id" />
                {modalMenuListTotal > 0 && (
                    <Pagination
                        current={modalMenuPage}
                        disabled={loading}
                        onChange={(page: number) => setModalMenuPage(page)}
                        pageSize={20}
                        total={modalMenuListTotal}
                    />
                )}
            </Modal>
            <Modal
                confirmLoading={loading}
                onCancel={() => setModal(undefined)}
                okButtonProps={{
                    htmlType: "submit",
                    form: "addForm",
                }}
                okText="선물"
                title="선물하기"
                visible={modal === "add"}
                width={800}
            >
                <Form
                    form={addForm}
                    name="addForm"
                    onFinish={(values) => {
                        if (values.amount === undefined) {
                            return message.error("수량을 입력해주세요.", 1.5);
                        }
                        if (!modalAddMenu) {
                            return message.error("메뉴를 선택해주세요.", 1.5);
                        }

                        let menuPrice = modalAddMenu.price;
                        const optionGroups: MenuOptionGroup[] = [];
                        modalAddMenuOption!.forEach((group) => {
                            const tempGroup: MenuOptionGroup = {
                                multiple_selection_possible: group.multiple_selection_possible,
                                name: group.name,
                                options: [],
                            };

                            group.options.forEach((option) => {
                                if (option) {
                                    tempGroup.options.push(option);
                                    menuPrice += option.price;
                                }
                            });

                            if (tempGroup.options.length > 0) {
                                optionGroups.push(tempGroup);
                            }
                        });
                        menuPrice *= values.amount;

                        setLoading(true);
                        userApi
                            .getUser(params.userId)
                            .then((res) => {
                                if (!res.data.detail) {
                                    return message.error("회원 상세 정보가 없습니다.", 1.5);
                                }

                                shopApi
                                    .createGift({
                                        price: menuPrice,
                                        use_point: 0,
                                        name: "어드민",
                                        phone_number: res.data.detail.phone_number,
                                        amount: values.amount,
                                        menu_id: modalAddMenu.id,
                                        option_groups: optionGroups,
                                        temperature_type: values.temperatureType,
                                    })
                                    .then(() => {
                                        setModal(undefined);
                                        setModalAddMenu(undefined);
                                        setModalAddMenuOptions([]);
                                        setLoading(false);
                                        setRerender(!rerender);
                                        addForm.resetFields();
                                        message.success("수정하였습니다.", 1.5);
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        message.error(error.response.data.detail, 1.5);
                                    });
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error("회원 정보를 불러오는데 실패하였습니다.", 1.5);
                            });
                    }}
                >
                    <FormItem label="수량" name="amount">
                        <InputNumber disabled={loading} min={0} placeholder="수량" style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem label="메뉴" name="menu">
                        <Input
                            disabled={loading}
                            onFocus={(e) => {
                                setModal("add-menu");
                                setModalMenuKeyword(undefined);
                                setModalMenuPage(1);
                                setModalAddMenu(undefined);
                                menuForm.resetFields();
                                e.target.blur();
                            }}
                            placeholder="메뉴"
                            readOnly
                            value={modalAddMenu?.name}
                        />
                    </FormItem>
                    {modalAddMenu && modalAddMenu.option_groups.length > 0 && (
                        <FormItem label="메뉴옵션">
                            {modalAddMenu.option_groups.map((group, groupIndex) => (
                                <ModalMenuOptionGroup key={groupIndex}>
                                    <ModalMenuOptionGroupTitle>{group.name}</ModalMenuOptionGroupTitle>
                                    {group.multiple_selection_possible ? (
                                        <Radio.Group
                                            onChange={(event) => {
                                                const options = [...modalAddMenuOption];
                                                if (options[groupIndex]) {
                                                    options[groupIndex].options = [group.options[event.target.value]];
                                                } else {
                                                    options[groupIndex] = {
                                                        multiple_selection_possible: group.multiple_selection_possible,
                                                        name: group.name,
                                                        options: [group.options[event.target.value]],
                                                    };
                                                }
                                                setModalAddMenuOptions(options);
                                            }}
                                            style={{ width: "100%" }}
                                        >
                                            {group.options.map((option, optionIndex) => (
                                                <ModalMenuOptionItem key={optionIndex}>
                                                    <Radio
                                                        disabled={loading}
                                                        value={optionIndex}
                                                        style={{ margin: 0 }}
                                                    />
                                                    <ModalMenuOptionItemName>{option.name}</ModalMenuOptionItemName>
                                                    <ModalMenuOptionItemPrice>
                                                        {option.price.toLocaleString("ko")}원
                                                    </ModalMenuOptionItemPrice>
                                                </ModalMenuOptionItem>
                                            ))}
                                            <ModalMenuOptionItem>
                                                <Radio disabled={loading} style={{ margin: 0 }} />
                                                <ModalMenuOptionItemName>없음</ModalMenuOptionItemName>
                                            </ModalMenuOptionItem>
                                        </Radio.Group>
                                    ) : (
                                        group.options.map((option, optionIndex) => (
                                            <ModalMenuOptionItem key={optionIndex}>
                                                <Checkbox
                                                    disabled={loading}
                                                    onChange={(event) => {
                                                        const options = [...modalAddMenuOption];
                                                        if (options[groupIndex]) {
                                                            options[groupIndex].options[optionIndex] = option;
                                                        } else {
                                                            const tempOptions: MenuOption[] = [];
                                                            tempOptions[optionIndex] = option;
                                                            options[groupIndex] = {
                                                                multiple_selection_possible:
                                                                    group.multiple_selection_possible,
                                                                name: group.name,
                                                                options: tempOptions,
                                                            };
                                                        }
                                                        setModalAddMenuOptions(options);
                                                    }}
                                                />
                                                <ModalMenuOptionItemName>{option.name}</ModalMenuOptionItemName>
                                                <ModalMenuOptionItemPrice>
                                                    {option.price.toLocaleString("ko")}원
                                                </ModalMenuOptionItemPrice>
                                            </ModalMenuOptionItem>
                                        ))
                                    )}
                                </ModalMenuOptionGroup>
                            ))}
                        </FormItem>
                    )}
                    <FormItem hints={["선택항목"]} label="온도" name="temperatureType">
                        <Select disabled={loading} placeholder="온도" style={{ width: "100%" }}>
                            <Option value={TemperatureType.HOT}>{temperatureTypeToText(TemperatureType.HOT)}</Option>
                            <Option value={TemperatureType.ICED}>{temperatureTypeToText(TemperatureType.ICED)}</Option>
                        </Select>
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                }}
                okText="삭제"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!modalGift) {
                        return;
                    }

                    setLoading(true);
                    shopApi
                        .deleteGift(modalGift.id)
                        .then(() => {
                            setModal(undefined);
                            setModalGift(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("삭제하였습니다.", 1.5);
                        })
                        .catch(() => {
                            setLoading(false);
                            message.error("삭제를 실패하였습니다.", 1.5);
                        });
                }}
                title="삭제"
                visible={modal === "delete"}
                width={800}
            >
                선물을 삭제하시겠습니까?
            </Modal>
        </>
    );
}
