import React from "react";
import styled from "@emotion/styled";
import moment from "moment";
import "moment/locale/ko";
import { Button, Form, Input, message, Modal } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { QuestionResponse } from "@ferda/rest-api-client";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";
import { questionCategoryToText, questionTypeToText } from "@/utils/enumToText";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import FormItem from "@/components/Admin/FormItem";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import Tab from "@/components/Admin/Tab";
import StatusBadge from "@/components/Admin/StatusBadge";
import { Clear, Right } from "@/components/Admin/Float";
import { TotalCountWrapper } from "@/components/Admin/Misc";

const { TextArea } = Input;

const QuestionHeader = styled.div`
    font-weight: bold;
    margin-bottom: 1rem;
`;
const QuestionTextWrapper = styled.div`
    margin-bottom: 2rem;
`;

interface Props {}

export default function Page(props: Props) {
    const { serviceApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalQuestion, setModalQuestion] = React.useState<QuestionResponse | undefined>();

    const [questionList, setQuestionList] = React.useState<QuestionResponse[]>([]);
    const [questionListTotal, setQuestionListTotal] = React.useState<number>(0);

    const [isAnswered, setIsAnswered] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(1);

    const [answerForm] = Form.useForm();

    const columns = [
        {
            title: "게시일자",
            key: "createdDatetime",
            render: (record: QuestionResponse) => {
                const time = moment(record.created_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "답변일자",
            key: "answeredDatetime",
            render: (record: QuestionResponse) => {
                if (!record.answered_datetime) {
                    return { children: undefined };
                }

                const time = moment(record.answered_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "가맹점",
            key: "shop",
            render: (record: QuestionResponse) => {
                return {
                    children: record.shop?.name,
                };
            },
            width: "100px",
        },
        {
            title: "질문자",
            key: "user",
            render: (record: QuestionResponse) => {
                return {
                    children: record.user?.detail?.name,
                };
            },
            width: "100px",
        },
        {
            title: "종류",
            key: "type",
            render: (record: QuestionResponse) => {
                return {
                    children: <StatusBadge color="#1890ff">{questionTypeToText(record.question_type)}</StatusBadge>,
                };
            },
            width: "100px",
        },
        {
            title: "분류",
            key: "category",
            render: (record: QuestionResponse) => {
                return {
                    children: (
                        <StatusBadge color="#ff7918">{questionCategoryToText(record.question_category)}</StatusBadge>
                    ),
                };
            },
            width: "100px",
        },
        {
            title: "제목",
            key: "title",
            render: (record: QuestionResponse) => {
                return { children: record.title };
            },
        },
        {
            title: "",
            key: "arrow",
            render: (_: unknown, __: unknown, extra: any) => {
                return { children: extra.isRowExpanded ? <UpOutlined /> : <DownOutlined /> };
            },
            width: "50px",
        },
    ];

    React.useEffect(() => {
        setLoading(true);
        serviceApi
            .getQuestions(isAnswered, page, undefined)
            .then((res) => {
                setLoading(false);
                setQuestionList(res.data);
                setQuestionListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("회원 목록을 불러오는데 실패하였습니다.");
            });
    }, [isAnswered, page, rerender]);

    return (
        <>
            <PageTitle>질문 관리</PageTitle>
            <Container>
                <Tab
                    defaultActiveKey="unanswered"
                    onChange={(key) => {
                        switch (key) {
                            case "unanswered":
                                setIsAnswered(false);
                                break;
                            case "answered":
                                setIsAnswered(true);
                                break;
                        }
                        setPage(1);
                    }}
                    tabs={[
                        {
                            title: "미답변",
                            key: "unanswered",
                        },
                        {
                            title: "답변됨",
                            key: "answered",
                        },
                    ]}
                />
                <TotalCountWrapper>질문 수 : {questionListTotal.toLocaleString("ko")}</TotalCountWrapper>
                <Table
                    columns={columns}
                    dataSource={questionList}
                    expandable={{
                        isExpandable: (record: QuestionResponse) => !!record.content,
                        render: (record: QuestionResponse) => (
                            <>
                                {record.uploaded_image_set && (
                                    <>
                                        <QuestionHeader>질문 사진</QuestionHeader>
                                        <QuestionTextWrapper>
                                            <img
                                                src={record.uploaded_image_set.original_image_url}
                                                style={{ display: "block", maxWidth: "100%" }}
                                            />
                                        </QuestionTextWrapper>
                                    </>
                                )}
                                <QuestionHeader>질문</QuestionHeader>
                                <QuestionTextWrapper>{record.content}</QuestionTextWrapper>
                                {record.answer && (
                                    <>
                                        <QuestionHeader>답변</QuestionHeader>
                                        <QuestionTextWrapper>{record.answer}</QuestionTextWrapper>
                                    </>
                                )}
                                <Right>
                                    <Button
                                        disabled={loading}
                                        onClick={() => {
                                            setModal("answer");
                                            setModalQuestion(record);
                                            answerForm.setFieldsValue({ answer: record.answer });
                                        }}
                                        style={{ marginRight: "0.5rem" }}
                                    >
                                        답변
                                    </Button>
                                    <Button
                                        danger
                                        disabled={loading}
                                        onClick={() => {
                                            setModal("delete");
                                            setModalQuestion(record);
                                        }}
                                    >
                                        삭제
                                    </Button>
                                </Right>
                                <Clear />
                            </>
                        ),
                        type: "row",
                    }}
                    loading={loading}
                    rowKey="id"
                />
                {questionListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={questionListTotal}
                    />
                )}
            </Container>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                }}
                okText="삭제"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!modalQuestion) {
                        return;
                    }

                    setLoading(true);
                    serviceApi
                        .deleteQuestion(modalQuestion.id)
                        .then(() => {
                            setModal(undefined);
                            setModalQuestion(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("삭제하였습니다.", 1.5);
                        })
                        .catch(() => {
                            setLoading(false);
                            message.error("삭제를 실패하였습니다.", 1.5);
                        });
                }}
                title="삭제"
                visible={modal === "delete"}
                width={800}
            >
                질문을 삭제하시겠습니까?
            </Modal>
            <Modal
                cancelText="닫기"
                confirmLoading={loading}
                okButtonProps={{
                    form: "answerForm",
                    htmlType: "submit",
                }}
                okText="등록"
                onCancel={() => setModal(undefined)}
                title="답변"
                visible={modal === "answer"}
                width={800}
            >
                <Form
                    form={answerForm}
                    name="answerForm"
                    onFinish={(values) => {
                        if (!modalQuestion) {
                            return;
                        }
                        if (!values.answer) {
                            return message.error("내용을 입력해주세요.", 1.5);
                        }

                        setLoading(true);
                        serviceApi
                            .setAnswer(modalQuestion.id, { answer: values.answer })
                            .then(() => {
                                setModal(undefined);
                                setModalQuestion(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("답변을 등록하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="질문">
                        <div>{modalQuestion?.content}</div>
                    </FormItem>
                    <FormItem label="답변" name="answer">
                        <TextArea disabled={loading} rows={5} />
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}
