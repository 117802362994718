import styled from "@emotion/styled";
import Select from "@/components/Select";
import Button from "@/components/Button";
import InputBase from "@/components/Input";
import { StyledIconButton } from "@/components/IconButton";
import Checkbox from "@/components/Checkbox";

export const Wrapper = styled.div`
    padding: 0 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const AlertModalContent = styled.p`
    font-size: 14px;
    color: #ff831e;
    margin: 16px 0 0 0;
    text-align: center;
    word-break: keep-all;
`;

export const PolicyWrapper = styled.div`
    padding: 24px 0 32px 0;
    border-bottom: 1px solid #d1d1d1;
`;

export const PolicyItems = styled.p`
    position: relative;
    font-size: 12px;
    color: #8f8f8f;
    margin: 0 0 4px 0;
    padding-left: 20px;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }

    &::before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #8f8f8f;
        position: absolute;
        left: 8px;
        top: 7px;
    }
`;

export const QuestionContentWrapper = styled.div`
    padding: 32px 0;
    border-bottom: 1px solid #d1d1d1;

    &:nth-last-of-type(1) {
        border-bottom: 0;
    }
`;

export const QuestionContentTitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: #0c0c0c;
    margin: 0 0 24px 0;
`;

export const QuestionStyledSelect = styled(Select)`
    margin-bottom: 16px;
`;

export const QuestionStoreWrapper = styled.div`
    display: flex;
`;

export const QuestionStore = styled.div`
    border-radius: 4px;
    flex: 1 1;
    border: 1px solid #ff831e;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0 16px;
`;

export const QuestionStoreText = styled.p`
    font-size: 16px;
    margin: 0;
    color: #ff831e;
`;

export const QuestionStoreTextPlcaeholder = styled.p`
    font-size: 16px;
    margin: 0;
    color: #0c0c0c;
`;

export const QuestionStoreButton = styled(Button)`
    width: 100px;
    height: 48px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
    box-shadow: none;
`;

export const QuestionWriteInput = styled(InputBase)`
    width: 100%;
    margin: 0 0 16px 0;

    input {
        &::placeholder {
            font-size: 16px;
            color: #5b5b5b;
            opacity: 1;
        }
    }
`;

export const QuestionWriteTextfield = styled(InputBase)`
    width: 100%;
    margin: 0 0 16px 0;
    height: unset;
    padding: 12px;

    textarea {
        &::placeholder {
            font-size: 16px;
            color: #5b5b5b;
            opacity: 1;
        }
    }
`;

export const QuestionWriteImageButton = styled(Button)`
    width: 90px;
    color: #000;
    font-size: 12px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    margin-bottom: 8px;
    white-space: nowrap;
`;

export const QuestionWriteImageButtonIcon = styled.img`
    margin-right: 6px;
`;

export const QuestionWriteImageInput = styled.input`
    width: 0;
    height: 0;
    position: fixed;
    left: 0;
    top: 0;
`;

export const QuestionWriteImageGuide = styled.p`
    font-size: 12px;
    color: #8f8f8f;
    padding-left: 16px;
    margin: 0 0 4px 0;
    position: relative;

    &::before {
        content: "";
        width: 4px;
        height: 4px;
        background-color: #8f8f8f;
        border-radius: 50%;
        position: absolute;
        left: 4px;
        top: 7px;
    }
`;

export const QuestionWriteImageWrapper = styled.div`
    position: relative;
    padding: 0 32px 16px 0;
    display: flex;
    align-items: center;
    margin-top: 16px;
    border-bottom: 1px solid #191919;
`;

export const QuestionWriteImage = styled.div`
    width: 76px;
    min-width: 76px;
    height: 76px;
    border-radius: 16px;
    overflow: hidden;
    margin-right: 12px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const QuestionWriteImageName = styled.p`
    font-size: 16px;
    color: #ff831e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const QuestionWriteImageResetButton = styled(StyledIconButton)`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: calc(50% - 8px);
    transform: translate(0, -50%);
`;

export const QuestionWriteGuideWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
`;

export const QuestionWriteGuideIcon = styled.img`
    margin: 2px 10px 0 0;
`;

export const QuestionWriteGuideText = styled.p`
    font-size: 12px;
    color: #0c0c0c;
    margin: 0;
    word-break: keep-all;
`;

export const QuestionAgreeCheckbox = styled(Checkbox)`
    margin-bottom: 16px;
`;

export const QuestionAgreeGuideWrapper = styled.div`
    margin: 16px 0 56px 0;
    display: flex;
    align-items: flex-start;
`;

export const QuestionAgreeGuideIcon = styled.img`
    margin: 2px 10px 0 0;
`;

export const QuestionAgreeGuideText = styled.p`
    font-size: 12px;
    color: #0c0c0c;
    margin: 0;
    word-break: keep-all;
`;

export const InquiyWriteButton = styled(Button)`
    width: 100%;
    border-radius: 20px;
    height: 48px;
    font-weight: bold;
`;

export const QuestionWriteCompleteWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    height: calc(100vh - 48px - 56px);
`;

export const QuestionWriteCompleteImage = styled.div`
    width: 90px;
    height: 86px;
    margin-bottom: 32px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const QuestionWriteCompleteTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #ff831e;
    margin: 0 0 8px 0;
`;

export const QuestionWriteCompleteSubtitle = styled.p`
    font-size: 14px;
    color: #0c0c0c;
    margin: 0 0 40px 0;
`;

export const QuestionWriteCompleteButton = styled(Button)`
    width: 190px;
    height: 45px;
    color: #0c0c0c;
    font-size: 14px;
    border-radius: 8px;
`;
