import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const LogTabHeaderWrapper = styled.div`
    width: 100%;
    padding: 12px 20px;
    background-color: #ff831e;
`;

export const LogTabHeader = styled.div`
    width: 100%;
    border-radius: 8px;
    background-color: #2d2d2d;
`;

export interface LogTabHeaderButtonProps {
    selected: boolean;
}

export const LogTabHeaderButton = styled(Button)<LogTabHeaderButtonProps>`
    width: 50%;
    border-radius: 8px;
    background-color: #2d2d2d;
    color: #0c0c0c;

    ${({ selected }) =>
        selected &&
        css`
            background-color: #ff831e;
            color: #000000;

            &:hover {
                background-color: #ff831e;
                color: #000000;
            }
        `}
`;

export const LogTabContentWrapper = styled.div``;

export const LogTabMenuItemWrapper = styled.div`
    padding: 0 20px;
`;

export const LogTabMenuItemContent = styled.div`
    width: 100%;
    display: flex;
    border-bottom: 1px solid #191919;
    padding: 32px 0;
`;

export const LogTabMenuItemImage = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const LogTabMenuItemTextWrapper = styled.div``;

export const LogTabMenuItemTextDate = styled.p`
    font-size: 12px;
    color: #5b5b5b;
    margin: 0 0 5px 0;
`;

export const LogTabMenuItemTextTitle = styled.p`
    font-size: 16px;
    color: #ff831e;
    margin: 0 0 2px 0;
`;

export const LogTabMenuItemTextAmount = styled.p`
    font-size: 14px;
    color: #8f8f8f;
    margin: 0;
`;

export const LogTabItemNoResult = styled.div`
    padding-top: 104px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LogTabItemNoResultImage = styled.div`
    width: 140px;
    height: 120px;
    margin-bottom: 16px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const LogTabItemNoResultText = styled.p`
    font-size: 14px;
    line-height: 24px;
    color: #0c0c0c;
    text-align: center;
`;
export const TagButtonContianer = styled.div`
    padding: 12px 20px;
    background-color: #ff831e;

    > button {
        margin-right: 8px;

        &:nth-last-child(1) {
            margin-right: 0;
        }
    }
`;
