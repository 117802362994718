import { combineReducers } from "@reduxjs/toolkit";
import { adminReducer as admin } from "./admin/slices";
import { authReducer as auth } from "@/redux/auth/slice";
import { mainReducer as main } from "@/redux/main/slice";
import { orderReducer as order } from "@/redux/order/slice";
import { snackbarReducer as snackbar } from "@/redux/snackbar/slice";
import { loginReducer as login } from "@/redux/login/slice";
import { userReducer as user } from "@/redux/user/slice";
import { postReducer as post } from "@/redux/post/slice";
import { notificationReducer as notification } from "@/redux/notification/slice";
import { questionReducer as question } from "@/redux/question/slice";
import { shopReducer as shop } from "@/redux/shop/slice";
import { pointLogReducer as pointLog } from "@/redux/pointLog/slice";
import { historyReducer as history } from "@/redux/history/slice";
import { posReducer as pos } from "@/redux/pos/slice";
import { menuReducer as menu } from "@/redux/menu/slice";
import { giftLogReducer as giftLog } from "@/redux/giftLog/slice";
import { paymentReducer as payment } from "@/redux/payment/slice";
import { subscriptionReducer as subscription } from "@/redux/subscription/slice";
import { customHistoryReducer as customHistory } from "@/redux/customHistory/slice";

export const rootReducerMap = {
    admin,
    auth,
    main,
    order,
    snackbar,
    login,
    user,
    post,
    notification,
    question,
    shop,
    pointLog,
    history,
    pos,
    menu,
    giftLog,
    payment,
    subscription,
    customHistory,
};

const rootReducer = combineReducers(rootReducerMap);

export default rootReducer;
