import styled from "@emotion/styled";
import { AppBar as MuiAppBar, AppBarProps as MuiAppBarProps, Toolbar, useScrollTrigger } from "@mui/material";
import * as React from "react";
import { ActionsPlaceholder, StyledToolbar, TitleContainer } from "./style";

interface StyledAppBarProps extends MuiAppBarProps {
    appDownloadOpen?: boolean;
    backgroundColor?: string;
}

const StyledAppBar = styled(({ appDownloadOpen, backgroundColor, ...props }: StyledAppBarProps) => (
    <MuiAppBar {...props} />
))`
    ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
`;

interface ElevationScrollProps {
    children: React.ReactElement;
    color: MuiAppBarProps["color"];
    scrollTarget?: HTMLElement;
    alwaysElevate?: boolean;
    disableBorder?: boolean;
}

function ElevationScroll({ color, children, scrollTarget, alwaysElevate, disableBorder }: ElevationScrollProps) {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: scrollTarget,
    });

    return React.cloneElement(children, {
        elevation: 0,
        color,
        style:
            !disableBorder && (trigger || alwaysElevate)
                ? {
                      borderBottom: "1px solid #f2f2f2",
                  }
                : {},
    });
}

export interface ElevateAppBar extends StyledAppBarProps {
    scrollTarget?: HTMLElement;
    alwaysElevate?: boolean;
    disableBorder?: boolean;
}

export function ElevateAppBar({ scrollTarget, disableBorder, alwaysElevate, ...props }: ElevateAppBar) {
    return (
        <ElevationScroll
            disableBorder={disableBorder}
            scrollTarget={scrollTarget}
            alwaysElevate={alwaysElevate}
            color={props.color}
        >
            <StyledAppBar {...props} />
        </ElevationScroll>
    );
}

interface AppBarProps {
    title?: string;
    titleAlign?: "center" | "left";
    titleColor?: string;
    actions?: React.ReactNode;
    leadingIcon?: React.ReactNode;
    backgroundColor?: string;
    disableBorder?: boolean;
    borderColor?: string;
    titleSize?: number;
}

export default function AppBar({
    backgroundColor,
    title,
    titleAlign = "center",
    titleColor = "white",
    actions,
    leadingIcon,
    disableBorder,
    borderColor,
    titleSize,
}: AppBarProps) {
    return (
        <>
            <ElevateAppBar disableBorder={disableBorder} backgroundColor={backgroundColor}>
                <StyledToolbar
                    disableBorder={disableBorder}
                    borderColor={borderColor}
                    color={titleColor}
                    size={titleSize}
                >
                    {leadingIcon}
                    {title && (
                        <TitleContainer color={titleColor} titleAlign={titleAlign} size={titleSize}>
                            {title}
                        </TitleContainer>
                    )}
                    {actions || (titleAlign === "center" && leadingIcon ? <ActionsPlaceholder /> : null)}
                </StyledToolbar>
            </ElevateAppBar>
            <Toolbar />
        </>
    );
}
