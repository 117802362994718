import styled from "@emotion/styled";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHistory from "@/hooks/useCustomHistory";
import {
    getSubscribeFetchState,
    getSubscribeStatus,
    getSubscriptionList,
    getSubscriptionListFetchState,
} from "@/redux/subscription/selectors";
import SubscriptionOptionButton from "./SubscriptionOptionButton";
import {
    fetchSubscribeStatusThunk,
    fetchSubscribeThunk,
    fetchSubscsriptionListThunk,
} from "@/redux/subscription/thunk";
import { BottomConfirmButtonContainer, ConfirmButton } from "@/components/Button/style";
import { getPaymentMethod, getPaymentMethodFetchState } from "@/redux/payment/selectors";
import { fetchPaymentMethodThunk } from "@/redux/payment/thunk";
import { BottomSpacer } from "@/components/Spacer";
import Modal from "@/components/Modal";
import Footer from "@/components/Footer";
import { resetPaymentState } from "@/redux/payment/actions";
import IamportLoader from "@/containers/IamportLoader";
import { resetSubscriptionState } from "@/redux/subscription/actions";

const SubscribeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-left: 20px;
    padding-top: 42px;
    padding-right: 20px;
    padding-bottom: 48px;
`;

const Title = styled.div`
    font-size: 24px;
    text-align: center;
    color: #0c0c0c;
    font-weight: bold;
    word-break: keep-all;
`;

const Description = styled.div`
    margin-top: 12px;

    font-size: 14px;
    color: #ffffff;
    text-align: center;
`;

const DescriptionRow = styled.div``;

const OptionButtonList = styled.div`
    width: 100%;
    margin-top: 32px;

    > button {
        margin-bottom: 24px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
`;

const AlertModalText = styled.div`
    font-size: 14px;
    color: #0c0c0c;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 32px;
`;

export function CustomSubscribe() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [addCardModalOpen, setAddCardModalOpen] = useState(false);
    const subscribeFetchState = useSelector(getSubscribeFetchState);
    const subscriptionList = useSelector(getSubscriptionList);
    const subscriptionListFetchState = useSelector(getSubscriptionListFetchState);
    const paymentMethod = useSelector(getPaymentMethod);
    const paymentMethodFetchState = useSelector(getPaymentMethodFetchState);

    const subscribeStatus = useSelector(getSubscribeStatus);
    const [subscription, setSubscription] = useState<number | null>(null);
    const [changeModalOpen, setChangeModalOpen] = useState(false);

    useEffect(() => {
        if (subscribeFetchState === "SUCCESS") {
            dispatch(resetSubscriptionState());
            history.push("/me/subscription-info");
        }
    }, [subscribeFetchState]);

    useEffect(() => {
        if (paymentMethodFetchState === "READY") {
            dispatch(fetchPaymentMethodThunk({}));
        }
    }, [paymentMethodFetchState]);

    useEffect(() => {
        if (subscriptionListFetchState !== "READY") {
            return;
        }

        dispatch(fetchSubscsriptionListThunk({}));
    }, [subscriptionListFetchState]);

    useEffect(() => {
        dispatch(fetchSubscribeStatusThunk({}));
    }, []);

    const handleSubscriptionSelect = useCallback(
        (newSubscription: number) => () => {
            setSubscription(newSubscription === subscription ? null : newSubscription);
        },
        [subscription],
    );

    const handleSubscribe = useCallback(() => {
        if (paymentMethodFetchState !== "SUCCESS" || subscription === null) {
            return;
        }

        if (subscribeStatus && subscribeStatus.subscription_in_use) {
            return setChangeModalOpen(true);
        }

        dispatch(fetchSubscribeThunk(subscription));
    }, [paymentMethod, paymentMethodFetchState, subscription, subscribeStatus]);

    const handleSubscriptionChange = useCallback(() => {
        if (paymentMethodFetchState !== "SUCCESS" || subscription === null) {
            return;
        }

        dispatch(fetchSubscribeThunk(subscription));
    }, [paymentMethod, paymentMethodFetchState, subscription]);

    useEffect(() => {
        dispatch(resetPaymentState());
        dispatch(resetSubscriptionState());
    }, []);

    const filteredCustomSubscriptionList = useMemo(
        () => subscriptionList?.filter(({ id }) => id >= 500),
        [subscriptionList],
    );

    return (
        <SubscribeContainer>
            <Modal
                open={addCardModalOpen}
                handleClose={() => setAddCardModalOpen(false)}
                buttonOnClick={() => {
                    history.push("/me/payment-method-settings");
                }}
                cancelButtonOnClick={() => setAddCardModalOpen(false)}
                titleText="카드 등록"
                buttonText="카드 등록하기"
                cancelButtonText="취소"
                fullSizeButton
            >
                <AlertModalText>
                    정기결제를 위해서는 카드등록이 필요합니다.
                    <br />
                    먼저 카드를 등록 해 주세요.
                </AlertModalText>
            </Modal>
            <Modal
                open={changeModalOpen}
                handleClose={() => setChangeModalOpen(false)}
                buttonOnClick={handleSubscriptionChange}
                cancelButtonOnClick={() => setChangeModalOpen(false)}
                titleText="구독권 변경"
                buttonText="확인"
                cancelButtonText="취소"
                fullSizeButton
            >
                <AlertModalText>
                    현재 이용하고 계신 구독권이 있습니다.
                    <br />
                    구독권 변경을 진행 할 경우 기존 사용 구독권은 환불이 불가하며 자동 해지 됩니다.
                    <br />
                    그래도 진행하시겠습니까?
                </AlertModalText>
            </Modal>
            <BottomConfirmButtonContainer>
                <ConfirmButton
                    variant="contained"
                    backgroundColor="#ff831e"
                    disabled={
                        subscription === null ||
                        paymentMethodFetchState !== "SUCCESS" ||
                        subscriptionListFetchState !== "SUCCESS" ||
                        subscribeFetchState === "FETCHING"
                    }
                    onClick={handleSubscribe}
                >
                    구매하기
                </ConfirmButton>
            </BottomConfirmButtonContainer>

            <Title>원하는 구독권을 선택해주세요</Title>
            <Description>
                <DescriptionRow>매일 마시는 음료,</DescriptionRow>
                <DescriptionRow>워크무드 구독권을 통해</DescriptionRow>
                <DescriptionRow>가장 합리적으로 즐겨보세요.</DescriptionRow>
            </Description>
            {filteredCustomSubscriptionList && (
                <OptionButtonList>
                    {filteredCustomSubscriptionList.map(({ name, price, id }) => (
                        <SubscriptionOptionButton
                            onClick={handleSubscriptionSelect(id)}
                            selected={subscription === id}
                            title={name}
                            price={price}
                            hideMenu
                            hidePerWeek
                        />
                    ))}
                </OptionButtonList>
            )}
            <Footer noPadding />
            <BottomSpacer />
        </SubscribeContainer>
    );
}

export default function CustomSubscribeWithIamportLoader() {
    return (
        <IamportLoader>
            <CustomSubscribe />
        </IamportLoader>
    );
}
