import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";
import AppLayout from "@/containers/AppLayout";
import Checkbox from "@/components/Checkbox";
import Divider from "@/components/Divider";
import InputBase from "@/components/Input";

export const StyledAppLayout = styled(AppLayout)`
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
    background: #f7f7f7;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const Title = styled.div`
    height: 30px;

    font-size: 16px;
    color: #0f0f0f;
    font-weight: bold;
`;

export const EditPhoneButton = styled(ButtonBase)`
    width: 192px;
    height: 36px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;

    color: #ffffff;
    font-weight: bold;

    background-color: #ff831e;

    border-radius: 8px;
`;

export const EditButton = styled(ButtonBase)`
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;

    color: #ffffff;

    background-color: #ff831e;

    border: 1px solid #ff831e;
    border-radius: 8px;
`;

export const EditCancelButton = styled(ButtonBase)`
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;

    color: #ffffff;

    background-color: #ff831e;

    border-radius: 8px;
`;

export const Paper = styled.div`
    padding: 20px;
    margin-top: 14px;
    margin-bottom: 40px;

    background-color: #ffffff;

    border-radius: 8px;
`;

export const CheckAllCheckboxLabel = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #0f0f0f;
`;

export const StyledCheckbox = styled(Checkbox)`
    margin-bottom: 4px;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }
`;

export const CheckboxDescription = styled.div`
    padding-left: 34px;
    margin-top: 4px;

    font-size: 12px;
    color: #757575;
`;

export const StyledDivider = styled(Divider)`
    margin-top: 10px;
    margin-bottom: 10px;

    background-color: #2d2d2d;
`;

export const InputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    &:nth-child(1) {
        margin-top: -8px;
    }

    &:nth-last-child(1) {
        margin-bottom: -8px;
    }

    font-size: 14px;
`;

export const InputTitle = styled.div`
    width: 80px;
    color: #0c0c0c;
`;

export const InputValue = styled.div`
    color: #0c0c0c;
`;

export const StyledInputBase = styled(InputBase)`
    display: flex;
    margin: 0;
    margin-left: 10px;
    min-width: 150px;
    max-width: 193px;
    flex-grow: 1;
    height: 36px;

    color: #0c0c0c;
    border: 1px solid #ff831e;
    background-color: #ffffff;

    input {
        padding: 0 12px;

        &::placeholder {
            color: #5b5b5b;
        }
    }

    border-radius: 8px;

    > input {
        text-align: right;
    }
`;

export const DeleteModalContentWrapper = styled.div`
    margin: 16px 0 32px 0;
    font-size: 14px;
    color: #0c0c0c;
    text-align: center;
`;

export const DeleteModalContentTitleText = styled.p`
    margin-bottom: 10px;
`;

export const DeleteModalContentSubtitleText = styled.p``;
