import { createTheme, tabClasses } from "@mui/material";

const theme = createTheme({
    palette: {
        mode: "light",
    },
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#FF831E",
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF",
                },
                indicator: {
                    color: "white",
                    backgroundColor: "#FF831E",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid #d1d1d1",
                    color: "#757575",
                    [`&.${tabClasses.selected}`]: {
                        color: "#FF831E !important",
                    },
                },
            },
        },
    },
});

export default theme;
