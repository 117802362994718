import React from "react";
import { IconButton } from "@mui/material";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";
import Link from "@/components/CustomLink";
import NavHomeSelectedIcon from "@/assets/img/btn_nav_home_s.svg";
import NavHomeIcon from "@/assets/img/btn_nav_home_n.svg";
import NavOtherSelectedIcon from "@/assets/img/btn_nav_other_s.svg";
import NavOtherIcon from "@/assets/img/btn_nav_other_n.svg";
import NavOrderIcon from "@/assets/img/btn_nav_order.svg";

const BottomAppBarContainer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;

    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70px;
    width: 100%;
    margin-top: 40px;

    background-color: #ff831e;
`;

const CenterCircleContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 6px;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
    width: 82px;
    height: 82px;

    background-color: #ff831e;
    border-radius: 82px;
`;

const StyledIconButton = styled(IconButton)`
    display: flex;
    flex-direction: column;
    width: 58px;
    height: 58px;
`;

const IconWrapper = styled.div`
    width: 25px;
    height: 25px;
    position: relative;

    > img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const OrderIconWrapper = styled.div`
    width: 58px;
    height: 58px;
    position: relative;

    > img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

interface StyledMenuTitleProps {
    selected?: boolean;
}

const MenuTitle = styled.div<StyledMenuTitleProps>`
    margin-top: 6px;

    font-size: 10px;

    ${({ selected }) =>
        selected
            ? css`
                  color: #ffffff;
              `
            : css`
                  color: #c6c6c6;
              `}
`;

export default function BottomAppBar() {
    const { pathname } = useLocation();

    return (
        <BottomAppBarContainer>
            <Link to="/">
                <StyledIconButton>
                    <IconWrapper>
                        <img src={pathname === "/" ? NavHomeSelectedIcon : NavHomeIcon} />
                    </IconWrapper>
                    <MenuTitle selected={pathname === "/"}>HOME</MenuTitle>
                </StyledIconButton>
            </Link>
            <Link to="/order">
                <CenterCircleContainer>
                    <IconButton sx={{ padding: 0 }}>
                        <OrderIconWrapper>
                            <img src={NavOrderIcon} />
                        </OrderIconWrapper>
                    </IconButton>
                    <MenuTitle selected={pathname === "/order"}>ORDER</MenuTitle>
                </CenterCircleContainer>
            </Link>
            <Link to="/other">
                <StyledIconButton>
                    <IconWrapper>
                        <img src={pathname === "/other" ? NavOtherSelectedIcon : NavOtherIcon} />
                    </IconWrapper>
                    <MenuTitle selected={pathname === "/other"}>OTHER</MenuTitle>
                </StyledIconButton>
            </Link>
        </BottomAppBarContainer>
    );
}
