import React, { useState } from "react";
import GiftMenu from "./GiftMenu";
import GiftSubscribe from "./GiftSubscribe";
import { GiftTabTypeHeader, GiftTabTypeHeaderButton, GiftTabTypeHeaderWrapper } from "./style";

type HeaderTabType = "menu" | "subscribe";

export default function GiftTab() {
    const [headerTab, setHeaderTab] = useState<HeaderTabType>("menu");

    return (
        <div>
            <GiftTabTypeHeaderWrapper>
                <GiftTabTypeHeader>
                    <GiftTabTypeHeaderButton selected={headerTab === "menu"} onClick={() => setHeaderTab("menu")}>
                        단품메뉴
                    </GiftTabTypeHeaderButton>
                    <GiftTabTypeHeaderButton
                        selected={headerTab === "subscribe"}
                        onClick={() => setHeaderTab("subscribe")}
                    >
                        구독권
                    </GiftTabTypeHeaderButton>
                </GiftTabTypeHeader>
            </GiftTabTypeHeaderWrapper>

            {headerTab === "menu" && <GiftMenu />}
            {headerTab === "subscribe" && <GiftSubscribe />}
        </div>
    );
}
