import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CreateOrderRequest } from "@ferda/rest-api-client";
import LoadingPage from "@/components/LoadingPage";
import useHistory from "@/hooks/useCustomHistory";
import { getQueryStringObject } from "@/utils/global";
import { fetchOrderCallbackThunk } from "@/redux/order/thunk";
import { getOrderFetchState, getSuccessOrderUidList } from "@/redux/order/selectors";

export default function OrderCallback() {
    const history = useHistory();
    const { search } = useLocation();
    const query = useMemo(() => getQueryStringObject(search), [search]);
    const dispatch = useDispatch();
    const orderFetchState = useSelector(getOrderFetchState);
    const successOrderUidList = useSelector(getSuccessOrderUidList);

    useEffect(() => {
        if (orderFetchState === "FETCHING") {
            return;
        }

        if (!query.params) {
            history.reset();
        }

        const params: CreateOrderRequest = JSON.parse(decodeURIComponent(query.params));

        if (typeof params.merchant_uid !== "undefined" && successOrderUidList.includes(params.merchant_uid)) {
            history.reset();
            history.push("/");
        }

        dispatch(fetchOrderCallbackThunk(params));
    }, [query]);

    return <LoadingPage />;
}
