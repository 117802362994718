import { Checkbox, FormControlLabel } from "@mui/material";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BottomBanner from "./BottomBanner";
import { RecommendedMenuWithTitle } from "@/components/RecommendedMenu";
import HomeBanner from "@/pages/Home/HomeBanner";
import {
    getEventContentPostList,
    getEventContentPostListFetchState,
    getUnexpiredEventContentPostList,
    getUnexpiredEventContentPostListFetchState,
} from "@/redux/post/selectors";
import { fetchMoreEventContentPostListThunk, fetchMoreUnexpiredEventContentPostListThunk } from "@/redux/post/thunk";
import Footer from "@/components/Footer";
import { AlertModalText } from "../Gift/GiftGetDetail/style";
import Modal from "@/components/Modal";

const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 130px;
    background: #f7f7f7;
`;

const NOTICE_KEY = "NOTISHOWED_20220706";

function getNoticeShowed() {
    return true;
    // if (window.localStorage.getItem(NOTICE_KEY)) {
    //     return true;
    // }

    // return false;
}

export default function Home() {
    const dispatch = useDispatch();
    const eventContentPostList = useSelector(getEventContentPostList);
    const eventContentPostListFetchState = useSelector(getEventContentPostListFetchState);
    const unexpiredEventContentPostList = useSelector(getUnexpiredEventContentPostList);
    const unexpiredEventContentPostListFetchState = useSelector(getUnexpiredEventContentPostListFetchState);
    const [noticeModalOpen, setNoticeModalOpen] = useState(() => !getNoticeShowed());
    const [doNotOpenAgain, setdoNotOpenAgain] = useState(false);

    const closeNoticeModal = () => {
        if (doNotOpenAgain) {
            window.localStorage.setItem(NOTICE_KEY, "yes");
        }
        setNoticeModalOpen(false);
    };

    useEffect(() => {
        if (eventContentPostList || eventContentPostListFetchState !== "READY") {
            return;
        }

        dispatch(fetchMoreEventContentPostListThunk());
    }, [eventContentPostList, eventContentPostListFetchState]);

    useEffect(() => {
        if (unexpiredEventContentPostList || unexpiredEventContentPostListFetchState !== "READY") {
            return;
        }

        dispatch(fetchMoreUnexpiredEventContentPostListThunk());
    }, [unexpiredEventContentPostList, unexpiredEventContentPostListFetchState]);

    return (
        <HomeContainer>
            <Modal
                titleText="워크무드 임시휴업 안내"
                open={noticeModalOpen}
                handleClose={closeNoticeModal}
                buttonOnClick={closeNoticeModal}
                fullSizeButton
                buttonText="확인"
            >
                <AlertModalText>
                    22.07.06(수)
                    <br /> 금일 내부 사정으로 인하여 카페 임시 휴업입니다.
                    <br /> 고객님들의 이용에 불편을 드려 죄송합니다.
                    <br /> 빠르게 복구하여 더 나은 서비스를 제공하도록 하겠습니다.
                    <br />
                    <FormControlLabel
                        onClick={() => setdoNotOpenAgain(!doNotOpenAgain)}
                        control={<Checkbox style={{ color: "#ff831e" }} checked={doNotOpenAgain} />}
                        label="다시 보지 않기"
                    />
                </AlertModalText>
            </Modal>
            <HomeBanner />
            <RecommendedMenuWithTitle hidePrice />
            <BottomBanner />
            <Footer noPadding />
        </HomeContainer>
    );
}
