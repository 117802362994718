import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

interface ModalWrapperProps {
    fullSize?: boolean;
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
    ${({ fullSize }) =>
        fullSize
            ? css`
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  background-color: #fff;
                  padding: 106px 0 0 0;
                  z-index: 9999;
              `
            : css`
                  max-width: 300px;
                  width: calc(100% - 72px);
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  border-radius: 8px;
                  background-color: #fff;
              `}
    position: fixed;
    overflow: hidden;
`;

interface ModalContentProps {
    fullSize?: boolean;
    padding: string;
}

export const ModalContent = styled.div<ModalContentProps>`
    width: 100%;
    ${({ fullSize, padding }) =>
        fullSize
            ? css`
                  padding: 34px 0 0 0;
              `
            : css`
                  padding: ${padding};
              `}
`;

export const ModalCloseButton = styled(Button)`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    min-width: unset;
    height: 24px;
    padding: 0;
`;

interface ModalTopImage {
    imageHeight?: number;
}

export const ModalTopImage = styled.div<ModalTopImage>`
    width: 100%;
    margin: 12px 0 32px 0;
    height: ${({ imageHeight }) => imageHeight}px;

    > img {
        margin: 0 auto;
        display: block;
        height: 100%;
    }
`;

interface ModalTitleTextProps {
    marginBottom?: number;
}

export const ModalTitleText = styled.p<ModalTitleTextProps>`
    font-size: 18px;
    text-align: center;
    color: #ff831e;
    font-weight: bold;
    margin: 0;
    ${({ marginBottom }) =>
        marginBottom &&
        css`
            margin-bottom: ${marginBottom}px;
        `}
`;

export const ModalButtonContainer = styled.div`
    padding: 0 20px 20px 20px;
`;

export const ModalFullButtonContainer = styled.div`
    display: flex;
`;

interface ModalButtonProps {
    cancel?: boolean;
}

export const ModalButton = styled(Button)<ModalButtonProps>`
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background-color: #ff831e;
    font-size: 16px;
    color: #ffffff;

    &:hover {
        background-color: #ff831e;
        color: #ffffff;
    }

    ${({ cancel }) =>
        cancel &&
        css`
            background-color: transparent;
            color: #757575;
            margin-top: 8px;

            &:hover {
                background-color: transparent;
                color: #757575;
            }
        `}
`;

export const ModalFullsizeButton = styled(Button)<ModalButtonProps>`
    width: 100%;
    height: 58px;
    border-radius: 0;
    background-color: #ff831e;
    font-size: 16px;
    color: #ffffff;

    &:hover {
        background-color: #ff831e;
        color: #ffffff;
    }

    ${({ cancel }) =>
        cancel &&
        css`
            &,
            &:hover {
                background-color: #5b5b5b;
                color: #ffffff;
            }
        `}
`;
