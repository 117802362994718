import styled from "@emotion/styled";
import { InputBase } from "@mui/material";
import { StyledIconButton } from "@/components/IconButton";

export const SearchInputProps = styled(InputBase)`
    flex-grow: 0;
    padding-left: 16px;
    padding-right: 8px;
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    margin: 0;

    font-size: 14px;

    border-radius: 8px;

    color: #0c0c0c;
    border: 1px solid #ff831e;
    background-color: #ffffff;

    input {
        padding: 0 12px;

        &::placeholder {
            color: #5b5b5b;
        }
    }
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 8px;
`;

export const ResetButton = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 4px;
`;

export const SearchButton = styled(StyledIconButton)`
    width: 24px;
    height: 24px;
`;
