import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import CardFrameImage from "@/assets/img/card_frame.svg";
import Modal from "@/components/Modal";
import {
    Description,
    Title,
    ModalContentContainer,
    AddCardNameInput,
    ModalDescriptionContainer,
    PaymentMetodSettingsContainer,
    CardWidthContainer,
    CardContainer,
    CardFrameImg,
    DeleteCardButton,
    CardNameContainer,
    AddCardButton,
    AddCardContentContainer,
    AddCardTitleContainer,
    // StyledLinkButton,
} from "./style";
import { deletePaymentMethodThunk, fetchAddPaymentMethodThunk, fetchPaymentMethodThunk } from "@/redux/payment/thunk";
import IamportLoader from "@/containers/IamportLoader";
import {
    getPaymentMethod,
    getPaymentMethodFetchState,
    getAddPaymentMethodFetchState,
    getDeletePaymentMethodFetchState,
} from "@/redux/payment/selectors";
import LoadingCircle from "@/components/LoadingCircle";
import Footer from "@/components/Footer";
import useHistory from "@/hooks/useCustomHistory";

export function PaymentMethodSettings() {
    const dispatch = useDispatch();
    const paymentMethod = useSelector(getPaymentMethod);
    const paymentMethodFetchState = useSelector(getPaymentMethodFetchState);
    const addPaymentMethodFetchState = useSelector(getAddPaymentMethodFetchState);
    const deletePaymentMethodFetchState = useSelector(getDeletePaymentMethodFetchState);
    const [addCardModalOpen, setAddCardModalOpen] = useState(false);
    const [removeCardModalOpen, setRemoveCardModalOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchPaymentMethodThunk({}));
    }, []);

    useEffect(() => {
        if (paymentMethodFetchState === "READY") {
            dispatch(fetchPaymentMethodThunk({}));
        }
    }, [paymentMethodFetchState]);

    useEffect(() => {
        if (addPaymentMethodFetchState === "SUCCESS") {
            dispatch(fetchPaymentMethodThunk({}));
        }
    }, [addPaymentMethodFetchState]);

    useEffect(() => {
        if (deletePaymentMethodFetchState === "SUCCESS") {
            dispatch(fetchPaymentMethodThunk({}));
        }
    }, [deletePaymentMethodFetchState]);

    const { getFieldProps, handleSubmit, submitForm } = useFormik<{
        name: string;
    }>({
        initialValues: { name: "" },
        onSubmit: ({ name }) => {
            dispatch(fetchAddPaymentMethodThunk({ name }));
        },
    });

    const handleAddCard = () => {
        submitForm();
        handleAddCardModalClose();
    };

    const handleAddCardModalOpen = () => {
        history.push("/service");
        // setAddCardModalOpen(false);
        // setAddCardModalOpen(true);
    };

    const handleAddCardModalClose = () => {
        setAddCardModalOpen(false);
    };

    const handleRemoveCardModalOpen = () => {
        setRemoveCardModalOpen(true);
    };

    const handleRemoveCardModalClose = () => {
        setRemoveCardModalOpen(false);
    };

    const handleCardRemove = () => {
        handleRemoveCardModalClose();
        dispatch(deletePaymentMethodThunk({}));
    };

    return (
        <AppLayout
            disableAppBarBorder={true}
            title="결제정보"
            titleColor="#fff"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            <Modal
                titleText="카드 별명 등록"
                fullSizeButton
                buttonText="등록하기"
                buttonOnClick={handleAddCard}
                showCloseButton
                open={addCardModalOpen}
                handleClose={handleAddCardModalClose}
            >
                <ModalContentContainer onSubmit={handleSubmit}>
                    <AddCardNameInput fullWidth {...getFieldProps("name")} />
                </ModalContentContainer>
            </Modal>

            <Modal
                fade
                titleText="등록카드 삭제"
                fullSizeButton
                buttonText="카드삭제"
                buttonOnClick={handleCardRemove}
                cancelButtonOnClick={handleRemoveCardModalClose}
                cancelButtonText="취소"
                open={removeCardModalOpen}
                handleClose={handleRemoveCardModalClose}
            >
                <ModalContentContainer>
                    <ModalDescriptionContainer>
                        카드를 정말 삭제하시겠어요?
                        <ModalDescriptionContainer />
                    </ModalDescriptionContainer>
                </ModalContentContainer>
            </Modal>
            <PaymentMetodSettingsContainer>
                <Title>결제 관리</Title>
                <Description>결제 카드를 관리할 수 있어요</Description>
                <CardWidthContainer>
                    {paymentMethodFetchState === "FETCHING" && (
                        <CardContainer>
                            <LoadingCircle position="absolute" />
                        </CardContainer>
                    )}
                    {paymentMethodFetchState === "SUCCESS" &&
                        (paymentMethod ? (
                            <CardContainer>
                                <CardFrameImg src={CardFrameImage} />
                                <DeleteCardButton onClick={handleRemoveCardModalOpen}>
                                    <CloseIcon sx={{ color: "white" }} />
                                </DeleteCardButton>
                                <CardNameContainer>{paymentMethod.name}</CardNameContainer>
                            </CardContainer>
                        ) : (
                            <AddCardButton onClick={handleAddCardModalOpen}>
                                <AddCardContentContainer>
                                    <AddCardTitleContainer>구독권 구매</AddCardTitleContainer>
                                </AddCardContentContainer>
                            </AddCardButton>
                        ))}
                </CardWidthContainer>
                {/* {!!paymentMethod && <StyledLinkButton title="결제 카드 변경" onClick={handleAddCardModalOpen} />} */}
            </PaymentMetodSettingsContainer>
            <Footer />
        </AppLayout>
    );
}

export default function PaymentMethodSettingsWithImaportLoader() {
    return (
        <IamportLoader>
            <PaymentMethodSettings />
        </IamportLoader>
    );
}
