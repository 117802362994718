import styled from "@emotion/styled";
import LinkButton from "@/components/LinkButton";
import AppLayout from "@/containers/AppLayout";
import Divider from "@/components/Divider";
import Button from "@/components/Button";

export const CategoryContainer = styled.div``;

export const CategoryTitle = styled.div`
    padding-left: 20px;
    margin-bottom: 4px;

    font-size: 14px;
    color: #0c0c0c;
`;

export const StyledAppLayout = styled(AppLayout)`
    padding-top: 20px;
    padding-bottom: 120px;

    > div {
        margin-bottom: 40px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
`;

export const StyledLinkButton = styled(LinkButton)`
    > button {
        padding-left: 20px;
        padding-right: 20px;

        background-color: transparent;

        border-radius: 0;
    }
`;

export const StyledDivider = styled(Divider)`
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 0;
    margin-right: 20px;
    margin-bottom: 0;
`;

export const LogoutWrapper = styled.div``;

export const LogoutButton = styled(Button)`
    padding-left: 20px;
    color: #ff831e;
    width: 100%;
    font-weight: bold;
    background-color: inherit;
    height: 56px;
    justify-content: flex-start;

    &:hover {
        background-color: inherit;
    }
`;

export const LogoutImageWrapper = styled.div`
    margin-right: 20px;
    width: 24px;
    height: 24px;

    > img {
        width: 100%;
        height: 100%;
        display: block;
    }
`;

export const LogoutText = styled.p`
    font-size: 16px;
    color: #0c0c0c;
    line-height: 25px;
    font-weight: normal;
`;

export const DeleteModalContentWrapper = styled.div`
    margin: 16px 0 32px 0;
    font-size: 14px;
    color: #0c0c0c;
    text-align: center;
`;

export const DeleteModalContentTitleText = styled.p`
    margin-bottom: 10px;
`;

export const DeleteModalContentSubtitleText = styled.p``;
