import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "./types";
import { makeAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: UserState = {
    editPhoneNumberFetchState: "READY",
    editUserInfoFetchState: "READY",
    deleteUserFetchState: "READY",
};

export const USER_STATE_KEY_LIST = getKeyListFromState(initialState);

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        reset: () => initialState,
        ...makeAsyncReducer<"editPhoneNumber", UserState>("editPhoneNumber"),
        ...makeAsyncReducer<"editUserInfo", UserState>("editUserInfo"),
        ...makeAsyncReducer<"deleteUser", UserState>("deleteUser"),
    },
});

export const userReducer = userSlice.reducer;
