import React from "react";
import { CircularProgress } from "@mui/material";
import { LoadingPageWrapper } from "./style";

export default function LoadingPage() {
    return (
        <LoadingPageWrapper>
            <CircularProgress />
        </LoadingPageWrapper>
    );
}
