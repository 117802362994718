import { menuSlice } from "./slice";

const actions = menuSlice.actions;

export const {
    resetAllMenuState,
    resetMenuGroupListState,
    resetMenuListState,
    resetMenuState,
    menuGroupListFetchRequest,
    menuGroupListFetchSuccess,
    menuGroupListFetchFailure,
    menuListFetchRequest,
    menuListFetchSuccess,
    menuListFetchFailure,
    selectedMenuFetchRequest,
    selectedMenuFetchSuccess,
    selectedMenuFetchFailure,
    recommendedMenuListFetchRequest,
    recommendedMenuListFetchSuccess,
    recommendedMenuListFetchFailure,
} = actions;
