import Decimal from "decimal.js";

declare module "decimal.js" {
    interface Decimal {
        roundToPrecision(sd?: number, rm?: Decimal.Rounding): Decimal;
    }
}

function roundToPrecision(this: Decimal, sd?: number, rm?: Decimal.Rounding): Decimal {
    let text;
    if (typeof sd !== "undefined") {
        if (typeof rm !== "undefined") {
            text = this.toPrecision(sd, rm);
        } else {
            text = this.toPrecision(sd);
        }
    } else {
        text = this.toPrecision();
    }
    return new Decimal(text);
}

Decimal.prototype.roundToPrecision = roundToPrecision;

export function isDecimal(object: unknown): object is Decimal {
    return Decimal.isDecimal(object);
}

export function ensureInt(x: number | string, radix = 10) {
    if (typeof x === "number") {
        return x;
    }
    return parseInt(x, radix);
}

export function ensureFloat(x: number | string) {
    if (typeof x === "number") {
        return x;
    }
    return parseFloat(x);
}

export function ensureDecimal(x: unknown): Decimal | null {
    try {
        if (typeof x === "number" || typeof x === "string") {
            return new Decimal(x);
        } else if (isDecimal(x)) {
            return x;
        }
        return null;
    } catch {
        return null;
    }
}

export function numberWithCommas(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
