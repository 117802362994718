import { createSlice } from "@reduxjs/toolkit";
import { QuestionResponse } from "@ferda/rest-api-client";
import { getKeyListFromState } from "@/utils/redux/state";
import { QuestionState } from "./types";
import { makeAsyncReducer, setValueReducerList } from "@/utils/redux/reducer";
import { ListFetchSuccessPayload } from "@/utils/redux/types";

const initialState: QuestionState = {
    questionWriteFetchState: "READY",
    questionDeleteFetchState: "READY",
    questionListFetchState: "READY",
    questionList: [],
    questionListPage: null,
    questionListTotalPage: null,
};

export const QUESTION_STATE_KEY_LIST = getKeyListFromState(initialState);

export const questionSlice = createSlice({
    name: "question",
    initialState,
    reducers: {
        reset: () => initialState,
        ...setValueReducerList<QuestionState, ["questionWriteFetchState", "questionList"]>([
            "questionWriteFetchState",
            "questionList",
        ]),
        ...makeAsyncReducer<"questionWrite", QuestionState>("questionWrite"),
        ...makeAsyncReducer<"questionDelete", QuestionState>("questionDelete"),
        ...makeAsyncReducer<"questionList", QuestionState, void, ListFetchSuccessPayload<QuestionResponse>>(
            "questionList",
            {
                useListSuccessReducer: true,
            },
        ),
    },
});

export const questionReducer = questionSlice.reducer;
