import { createSlice } from "@reduxjs/toolkit";
import { makeAsyncReducer, setValueReducerList } from "@/utils/redux/reducer";
import { PosGetShopRequestPayload, PosLoginAuthType, PosState } from "./types";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: PosState = {
    posLoginFetchState: "READY",
    posLoginAuthType: "UNLOADED",
    posLoginUser: null,
    posLoginShop: null,
    posGetShopFetchState: "READY",
};

export const POS_STATE_KEY_LIST = getKeyListFromState(initialState);

export const posSlice = createSlice({
    name: "pos",
    initialState,
    reducers: {
        reset: () => initialState,
        ...makeAsyncReducer<"posLogin", PosState, void, PosLoginAuthType>("posLogin", {
            successReducer: (state, { payload }) => {
                state.posLoginAuthType = payload;
                state.posLoginFetchState = "SUCCESS";
            },
            failureReducer: (state) => {
                state.posLoginAuthType = "UNAUTHORIZED";
                state.posLoginFetchState = "FAILURE";
            },
        }),
        ...makeAsyncReducer<"posGetShop", PosState, void, PosGetShopRequestPayload>("posGetShop", {
            successReducer: (state, { payload: { shopData, userData } }) => {
                state.posLoginShop = shopData;
                state.posLoginUser = userData;
                state.posGetShopFetchState = "SUCCESS";
            },
        }),
        ...setValueReducerList<
            PosState,
            ["posLoginUser", "posLoginShop", "posLoginFetchState", "posGetShopFetchState"]
        >(["posLoginUser", "posLoginShop", "posLoginFetchState", "posGetShopFetchState"]),
    },
});

export const posReducer = posSlice.reducer;
