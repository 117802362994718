import { format } from "date-fns";
import { toDate } from "date-fns/fp";

export function getToday() {
    const now = toDate(new Date());

    return new Date(now.setHours(0, 0, 0, 0));
}

export function getHmFromHms(hms: string) {
    const [h, m] = hms.split(":");

    return [h, m].join(":");
}

export function getDateRangeString(createdAt: string, startAt?: string, endAt?: string) {
    const formatString = "yyyy.MM.dd";

    if (startAt || endAt) {
        return (
            startAt &&
            format(new Date(startAt), formatString) +
                (endAt && (startAt && " ~ ") + format(new Date(endAt), formatString))
        );
    }

    return format(new Date(createdAt), formatString);
}
