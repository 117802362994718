import styled from "@emotion/styled";
import { Checkbox } from "@mui/material";
import { css } from "@emotion/react";

interface CheckboxWrapperProps {
    marginBottom?: number;
}

export const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
    width: 100%;
    display: flex;
    align-items: center;

    ${({ marginBottom }) =>
        marginBottom &&
        css`
            margin-bottom: ${marginBottom}px;
        `}
`;

export const StyledCheckbox = styled(Checkbox)`
    width: 24px;
    height: 24px;
`;

interface CheckboxLabelProps {
    bold?: boolean;
    color?: string;
}

export const CheckboxLabel = styled.div<CheckboxLabelProps>`
    flex-grow: 1;
    font-size: 14px;
    color: ${({ color }) => color || "#8f8f8f"};
    ${({ bold }) =>
        bold &&
        css`
            font-weight: bold;
        `}
    margin: 0 0 0 10px;
`;

export const Description = styled.div`
    flex-grow: 1;
    text-align: right;
    color: #0c0c0c;
`;
