import { orderSlice } from "./slice";

const actions = orderSlice.actions;

export const {
    reset: resetOrderState,
    setOrderStash,
    setOrderType,
    orderFetchRequest,
    orderFetchSuccess,
    orderFetchFailure,
    giftOrderFetchRequest,
    giftOrderFetchSuccess,
    giftOrderFetchFailure,
    giftSubsFetchRequest,
    giftSubsFetchSuccess,
    giftSubsFetchFailure,
    addSuccessOrderUid,
} = actions;
