import React from "react";
import styled from "@emotion/styled";
import { Button, Input } from "antd";

const MapContainer = styled.div`
    position: relative;
    height: 500px;
`;

const Map = styled.div`
    height: 100%;
`;

const Search = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    padding: 1rem;
    background: #ffffffaa;
    z-index: 100;
    overflow-y: auto;
`;

const SearchBar = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const SearchResultList = styled.ul`
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;
    list-style: none;
`;

const SearchResultItem = styled.li`
    display: flex;
    margin-bottom: 0.5rem;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0;
    }
`;

const SearchResultItemMarker = styled.div`
    flex-basis: 36px;
    margin-right: 0.5rem;
    width: 36px;
    height: 37px;
`;

const SearchResultItemInfoContainer = styled.div`
    flex-grow: 1;
    font-size: 0.8rem;
`;

const SearchResultItemInfoTitle = styled.div`
    font-weight: bold;
`;

const SearchResultItemInfoAddress = styled.div`
    color: #888888;
`;

interface Place {
    roadAddressName: string;
    lat: number;
    long: number;
}

interface Props {
    defaultLatLong?: { lat: number; long: number };
    latLong?: { lat: number; long: number };
    mapId: string;
    onPlaceSelect: (place: Place) => void;
}

export default function Component(props: Props): React.ReactElement {
    const [map, setMap] = React.useState<any>();
    const [places, setPlaces] = React.useState<any>();
    const [infoWindow, setInfoWindow] = React.useState<any>();
    const [markers, setMarkers] = React.useState<any[]>([]);
    const [keyword, setKeyword] = React.useState<string | undefined>();
    const [keywordTemp, setKeywordTemp] = React.useState<string | undefined>();
    const [searchResults, setSearchResults] = React.useState<any[]>([]);
    const [defaultMarker, setDefaultMarker] = React.useState<any>();

    React.useEffect(() => {
        const mapElement = document.getElementById(props.mapId);
        const mapOptions = {
            center: new (window as any).kakao.maps.LatLng(33.450701, 126.570667),
            level: 3,
        };

        const map = new (window as any).kakao.maps.Map(mapElement, mapOptions);
        const places = new (window as any).kakao.maps.services.Places();
        const infoWindow = new (window as any).kakao.maps.InfoWindow({ zIndex: 1 });

        setMap(map);
        setPlaces(places);
        setInfoWindow(infoWindow);
    }, []);

    React.useEffect(() => {
        if (!props.defaultLatLong || !props.defaultLatLong.lat || !props.defaultLatLong.long || !map || defaultMarker) {
            return;
        }

        const position = new (window as any).kakao.maps.LatLng(props.defaultLatLong.lat, props.defaultLatLong.long);
        const marker = new (window as any).kakao.maps.Marker({ position });

        map.setCenter(position);
        marker.setMap(map);

        setDefaultMarker(marker);
    }, [props.defaultLatLong, map]);

    React.useEffect(() => {
        if (!props.latLong || !map) {
            return;
        }

        const position = new (window as any).kakao.maps.LatLng(props.latLong.lat, props.latLong.long);
        map.setCenter(position);
    }, [props.latLong, map]);

    React.useEffect(() => {
        if (!map || !places || !keyword || !infoWindow) {
            return;
        }

        places.keywordSearch(keyword, (data: any, status: any, pagination: any) => {
            switch (status) {
                case (window as any).kakao.maps.services.Status.OK: {
                    setSearchResults(data);

                    if (defaultMarker) {
                        defaultMarker.setMap(null);
                    }

                    const bounds = new (window as any).kakao.maps.LatLngBounds();
                    const newMarkers = [];

                    markers.forEach((marker) => {
                        marker.setMap(null);
                    });

                    data.forEach((place: any, index: number) => {
                        const position = new (window as any).kakao.maps.LatLng(place.y, place.x);
                        const marker = new (window as any).kakao.maps.Marker({
                            position,
                            image: new (window as any).kakao.maps.MarkerImage(
                                "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png",
                                new (window as any).kakao.maps.Size(36, 37),
                                {
                                    spriteSize: new (window as any).kakao.maps.Size(36, 691),
                                    spriteOrigin: new (window as any).kakao.maps.Point(0, index * 46 + 10),
                                    offset: new (window as any).kakao.maps.Point(13, 37),
                                },
                            ),
                        });

                        (window as any).kakao.maps.event.addListener(marker, "mouseover", () => {
                            infoWindow.setContent(
                                `<div style="padding:5px;z-index:1;font-size:0.75rem">${place.place_name}</div>`,
                            );
                            infoWindow.open(map, marker);
                        });
                        (window as any).kakao.maps.event.addListener(marker, "mouseout", () => {
                            infoWindow.close();
                        });
                        (window as any).kakao.maps.event.addListener(marker, "click", () => {
                            props.onPlaceSelect({
                                lat: position.getLat(),
                                long: position.getLng(),
                                roadAddressName: place.road_address_name,
                            });
                        });

                        marker.setMap(map);
                        newMarkers.push(marker);

                        bounds.extend(position);
                    });

                    map.setBounds(bounds);
                    setMarkers(markers);
                    break;
                }
                case (window as any).kakao.maps.services.Status.ZERO_RESULT: {
                    alert("검색 결과가 존재하지 않습니다.");
                    return;
                }
                case (window as any).kakao.maps.services.Status.ERROR: {
                    alert("검색 결과 중 오류가 발생했습니다.");
                    return;
                }
                default: {
                    alert("알 수 없는 에러입니다.");
                }
            }
        });
    }, [keyword]);

    return (
        <MapContainer className="kakao-map">
            <Search>
                <SearchBar>
                    <Input
                        style={{ marginRight: "0.5rem" }}
                        onChange={(event) => setKeywordTemp(event.target.value)}
                        value={keywordTemp}
                    />
                    <Button onClick={() => setKeyword(keywordTemp)}>검색</Button>
                </SearchBar>
                <SearchResultList>
                    {searchResults.map((result, index) => (
                        <SearchResultItem
                            key={result.id}
                            onClick={() => {
                                props.onPlaceSelect({
                                    lat: result.y,
                                    long: result.x,
                                    roadAddressName: result.road_address_name,
                                });
                            }}
                        >
                            <SearchResultItemMarker className={`markerbg marker_${index + 1}`} />
                            <SearchResultItemInfoContainer>
                                <SearchResultItemInfoTitle>{result.place_name}</SearchResultItemInfoTitle>
                                <SearchResultItemInfoAddress>{result.road_address_name}</SearchResultItemInfoAddress>
                            </SearchResultItemInfoContainer>
                        </SearchResultItem>
                    ))}
                </SearchResultList>
            </Search>
            <Map id={props.mapId} />
        </MapContainer>
    );
}
