import axios from "axios";
import { GenericThunkAction, makeFetchMoreListThunkAction } from "@/utils/redux/thunk";
import { openErrorSnackbar } from "../snackbar/actions";
import {
    orderHistoryDetailRequestFetchFailure,
    orderHistoryDetailRequestFetchRequest,
    orderHistoryDetailRequestFetchSuccess,
    orderHistoryListFetchFailure,
    orderHistoryListFetchRequest,
    orderHistoryListFetchSuccess,
} from "./actions";

interface OrderHistoryRequestThunkActionExtraArgs {
    minCreatedDate?: string;
    maxCreatedDate?: string;
}

let emptyValue: undefined;

export const orderHistoryRequestThunk = makeFetchMoreListThunkAction(
    orderHistoryListFetchRequest,
    orderHistoryListFetchSuccess,
    orderHistoryListFetchFailure,
    {
        getResponseListFunction: (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                    },
                },
                page,
            },
            { minCreatedDate, maxCreatedDate }: OrderHistoryRequestThunkActionExtraArgs,
        ) =>
            shopApi.getOrders(
                emptyValue,
                emptyValue,
                emptyValue,
                emptyValue,
                emptyValue,
                minCreatedDate,
                maxCreatedDate,
                page,
                "me",
            ),
        pageSelectFunction: ({ history: { orderHistoryListPage: page, orderHistoryListTotalPage: totalPage } }) => ({
            page,
            totalPage,
        }),
    },
);

export function orderHistoryDetailRequestThunk(id: number): GenericThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { shopApi },
            },
        } = getState();

        dispatch(orderHistoryDetailRequestFetchRequest());

        let res;

        try {
            res = await shopApi.getOrder(id);
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                dispatch(orderHistoryDetailRequestFetchFailure(e));
                if (e.response) {
                    dispatch(openErrorSnackbar(e.response.data.detail));
                }
            }
            throw e;
        }

        dispatch(orderHistoryDetailRequestFetchSuccess(res.data));
    };
}
