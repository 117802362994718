import { makeSubStateSelectorMap } from "@/utils/redux/selector";
import { GIFT_LOG_STATE_KEY_LIST } from "./slice";

export const {
    getGiftLogList,
    getGiftLogListPage,
    getGiftLogListRequestFetchState,
    getGiftSubscribeLogList,
    getGiftSubscribeLogListPage,
    getGiftSubscribeLogListRequestFetchState,
    getGiftSubscribeLogListTotalPage,
    getGiftLogListTotalPage,
    getGiftLogDetailInfo,
    getGiftSubscribeLogDetailInfo,
    getGiftOrderFetchState,
    getGiftSubscribeOrderFetchState,
} = makeSubStateSelectorMap("giftLog", GIFT_LOG_STATE_KEY_LIST);
