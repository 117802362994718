import {
    shopListFetchRequest,
    shopListFetchSuccess,
    shopListFetchFailure,
    shopBusinessHourFetchRequest,
    shopBusinessHourFetchSuccess,
    shopBusinessHourFetchFailure,
} from "./actions";
import { makeFetchMoreListThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";
import { getLocation } from "@/utils/location";

export interface FetchMoreShopListThunkPayload {
    selectedMenuId?: number;
    keyword?: string;
    searchNearShopOnly?: boolean;
}

export const fetchMoreShopListThunk = makeFetchMoreListThunkAction(
    shopListFetchRequest,
    shopListFetchSuccess,
    shopListFetchFailure,
    {
        getResponseListFunction: async (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                    },
                },
                dispatch,
                page,
            },
            { selectedMenuId, keyword, searchNearShopOnly }: FetchMoreShopListThunkPayload,
        ) => {
            let location;
            let emptyValue;
            if (searchNearShopOnly) {
                try {
                    location = await getLocation();
                } catch (e) {
                    if (e instanceof GeolocationPositionError) {
                        // console.log(GeolocationPositionError.PERMISSION_DENIED);
                        // console.log(GeolocationPositionError.POSITION_UNAVAILABLE);
                        // dispatch(
                        //     openSnackbar({
                        //         message: "",
                        //     }),
                        // );
                    } else {
                        throw e;
                    }
                }
            }

            return shopApi.getShops(selectedMenuId, emptyValue, keyword, location?.latitude, location?.longitude, page);
        },
        pageSelectFunction: ({ shop: { shopListPage: page, shopListTotalPage: totalPage } }) => ({
            page,
            totalPage,
        }),
    },
);

export const fetchShopBusinessHourThunk = makeFetchThunkAction(
    shopBusinessHourFetchRequest,
    shopBusinessHourFetchSuccess,
    shopBusinessHourFetchFailure,
    {
        getResponseFunction: (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                    },
                },
            },
            payload: number,
        ) => shopApi.getBusinessHours(payload),
    },
);
