import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonBase, CircularProgress } from "@mui/material";

export const SubscribeInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StateTitleContainer = styled.div`
    margin-bottom: 32px;

    font-size: 18px;
    font-weight: bold;
    color: #0c0c0c;
`;

export const CircleCommonStyle = css`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    border-radius: 225px;
`;

interface GlowCircleProps {
    glow?: boolean;
}

export const StateCircleContainer = styled.div<GlowCircleProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 210px;
    background: #d1d1d1;
    border-radius: 225px;

    ${({ glow }) =>
        glow &&
        css`
            animation: glow 2s ease-in-out infinite;
        `}

    @keyframes glow {
        0%,
        100% {
            box-shadow: 0px 0px 24px 2px #ff831e;
        }

        50% {
            box-shadow: 0px 0px 0px 0px transparent;
        }
    }

    @keyframes glow-inset {
        0%,
        100% {
            box-shadow: inset 0px 0px 24px 2px #ff831e;
        }

        50% {
            box-shadow: inset 0px 0px 0px 0px transparent;
        }
    }
`;

export const InnerBlackCircle = styled.div<GlowCircleProps>`
    ${CircleCommonStyle}
    width: 200px;
    height: 200px;
    ${({ glow }) =>
        glow &&
        css`
            animation: glow 2s ease-in-out infinite;
        `}

    background: white;
`;

export const InnerDarkCircle = styled.div<GlowCircleProps>`
    ${CircleCommonStyle}
    width: 186px;
    height: 186px;

    ${({ glow }) =>
        glow &&
        css`
            animation: glow-inset 2s ease-in-out infinite;
        `}

    background: #d1d1d1;
`;

export const InnerCircle = styled.div`
    ${CircleCommonStyle}
    width: 175px;
    height: 175px;

    background: #d1d1d1;
`;

export const CircleContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledCircularProgress = styled(CircularProgress)`
    position: absolute;
`;

export const StyledButton = styled(ButtonBase)`
    width: 100px;
    height: 36px;

    color: white;
    font-weight: bold;
    font-size: 14px;

    background: #ff831e;

    border-radius: 36px;
`;

export const DescriptionContainer = styled.div`
    margin-bottom: 12px;

    text-align: center;
    font-size: 12px;
    color: #0c0c0c;
`;

export const DescriptionRow = styled.div``;

export const CoffeeCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-bottom: 8px;

    border-radius: 48px;
    background-image: linear-gradient(226deg, #feab29 -107%, rgba(254, 171, 41, 0) 100%);
`;

export const CoffeeIconImg = styled.img``;

export const TimeLeftContainer = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: white;
`;

export const TimeLeftTime = styled.span`
    font-size: 30px;
`;
