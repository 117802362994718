import { ButtonProps as MuiButtonProps } from "@mui/material";
import React from "react";
import useHistory from "@/hooks/useCustomHistory";
import { StyledButton } from "./style";

export interface ButtonProps extends MuiButtonProps {
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    hover?: boolean;
    href?: string;
    replace?: boolean;
}

export default function Button({ onClick, backgroundColor, href, replace, variant, ...args }: ButtonProps) {
    const history = useHistory();

    const handleClickButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onClick) {
            onClick(e);
        }

        if (href) {
            if (replace) {
                history.replace(href);
            } else {
                history.push(href);
            }
        }
    };

    const getVariant = () => {
        if (backgroundColor && !variant) {
            return "contained";
        }

        return variant;
    };

    return (
        <StyledButton onClick={handleClickButton} variant={getVariant()} backgroundColor={backgroundColor} {...args} />
    );
}
