import { useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { QuestionCategory, QuestionType } from "@ferda/rest-api-client";
import {
    AlertModalContent,
    QuestionContentTitle,
    QuestionContentWrapper,
    QuestionStore,
    QuestionStoreButton,
    QuestionStoreText,
    QuestionStoreTextPlcaeholder,
    QuestionStoreWrapper,
    QuestionStyledSelect,
    QuestionWriteGuideIcon,
    QuestionWriteGuideText,
    QuestionWriteGuideWrapper,
    QuestionWriteImageButton,
    QuestionWriteImageButtonIcon,
    QuestionWriteImageInput,
    QuestionWriteImageGuide,
    QuestionWriteInput,
    QuestionWriteTextfield,
    PolicyItems,
    PolicyWrapper,
    Wrapper,
    QuestionWriteImageWrapper,
    QuestionWriteImage,
    QuestionWriteImageName,
    QuestionWriteImageResetButton,
    QuestionAgreeCheckbox,
    QuestionAgreeGuideWrapper,
    QuestionAgreeGuideIcon,
    QuestionAgreeGuideText,
    InquiyWriteButton,
    QuestionWriteCompleteWrapper,
    QuestionWriteCompleteImage,
    QuestionWriteCompleteTitle,
    QuestionWriteCompleteSubtitle,
    QuestionWriteCompleteButton,
} from "./style";
import StoreFindModal from "../../../components/StoreFindModal";
import ImageIcon from "@/assets/img/ic_insert_photo.svg";
import AlertIcon from "@/assets/img/ic_alert_small.svg";
import CloseIcon from "@/assets/img/btn_close_round_normal.svg";
import CompleteIcon from "@/assets/img/img_confirm_modal_check.svg";
import Modal from "@/components/Modal";
import { questionWriteRequestThunk } from "@/redux/question/thunk";
import { getQuestionWriteFetchState } from "@/redux/question/selectors";
import { reset } from "@/redux/question/actions";
import LoadingCircle from "@/components/LoadingCircle";

interface InitialValues {
    question_type: QuestionType;
    question_category: QuestionCategory;
    shop_id: string;
    title: string;
    content: string;
    image: null | File;
    use_email_notification: boolean;
    use_push_notification: boolean;
}

interface QuestionWriteProps {
    handleChangeTab: (value: "write" | "list") => void;
}

type QuestionTypeItems = Array<{
    label: string;
    value: QuestionType;
}>;

type QuestionCategoryItems = Array<{
    label: string;
    value: QuestionCategory;
}>;

export default function QuestionWrite({ handleChangeTab }: QuestionWriteProps) {
    const dispatch = useDispatch();
    const [findModal, setFindModal] = useState<boolean>(false);
    const [alertModal, setAlertModal] = useState<boolean>(false);
    const [completeModal, setCompleteModal] = useState<boolean>(false);
    const [alertModalContent, setAlertModalContent] = useState<string>("");
    const [shopName, setShopName] = useState<string>("");

    const QUESTION_WRITE_FETCH_STATE = useSelector(getQuestionWriteFetchState);

    const handleOpenFindModal = () => setFindModal(true);
    const handleCloseFindModal = () => setFindModal(false);
    const handleOpenAlertModal = (content: string = "") => {
        setAlertModal(true);
        setAlertModalContent(content);
    };
    const handleCloseAlertModal = () => {
        setAlertModal(false);
        setAlertModalContent("");
    };
    const handleOpenCompleteModal = () => setCompleteModal(true);

    const initialValues: InitialValues = {
        question_type: QuestionType.COMPLIMENT,
        question_category: QuestionCategory.CLEANLINESS,
        shop_id: "",
        title: "",
        content: "",
        image: null,
        use_email_notification: false,
        use_push_notification: false,
    };

    const { getFieldProps, setFieldValue, values } = useFormik<InitialValues>({
        initialValues,
        validationSchema: Yup.object().shape({
            question_type: Yup.string().required("문의 유형을 선택해주세요."),
            question_category: Yup.string().required("문의 분류를 선택해주세요."),
            shop_id: Yup.string().required("문의할 매장을 선택해주세요."),
            title: Yup.string().required("제목을 입력해주세요."),
            content: Yup.string().required("내용을 입력해주세요."),
        }),
        onSubmit: () => {
            //
        },
    });

    const QUESTION_TYPE_ITEMS: QuestionTypeItems = [
        { label: "칭찬", value: QuestionType.COMPLIMENT },
        { label: "문의", value: QuestionType.QUESTION },
        { label: "제안", value: QuestionType.SUGGESTION },
        { label: "불만", value: QuestionType.DISSATISFACTION },
    ];
    const QUESTION_CATEGORY_ITEMS: QuestionCategoryItems = [
        { label: "이름", value: QuestionCategory.FOREIGN },
        { label: "서비스", value: QuestionCategory.SERVICE },
        { label: "청결", value: QuestionCategory.CLEANLINESS },
        { label: "품질", value: QuestionCategory.QUALITY },
        { label: "해지", value: QuestionCategory.UNSUBSCRIBE },
        { label: "환불", value: QuestionCategory.REFUND },
        { label: "기타", value: QuestionCategory.ETC },
    ];

    const handleClickSearchResult = (id: number, name: string) => {
        setFieldValue("shop_id", id);
        setShopName(name);
        setFindModal(false);
    };

    const imageRef = useRef<HTMLInputElement | null>(null);
    const handleInputImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const ext = file.type.split("/").reverse()[0];
        const ACCEPT_EXT = ["png", "jpeg"];
        const size = file.size;

        if (ACCEPT_EXT.indexOf(ext) < 0 || size > 5242880) {
            handleOpenAlertModal("5MB 이하의 이미지(PNG, JPG) 1개를 첨부하실 수 있습니다.");
            return;
        }

        setFieldValue("image", file);
    };
    const getImageSrcFromFile = (file: File) => {
        return URL.createObjectURL(file);
    };
    const handleClickImageReset = () => {
        if (imageRef.current) {
            imageRef.current.value = "";
        }
        setFieldValue("image", null);
    };
    const handleClickWriteButton = () => {
        dispatch(
            questionWriteRequestThunk({
                title: values.title,
                content: values.content,
                question_category: values.question_category,
                question_type: values.question_type,
                use_email_notification: values.use_email_notification,
                use_push_notification: values.use_push_notification,
                image: values.image,
                shop_id: Number(values.shop_id),
            }),
        );
    };

    useEffect(() => {
        if (QUESTION_WRITE_FETCH_STATE === "SUCCESS") {
            handleOpenCompleteModal();
        }
    }, [QUESTION_WRITE_FETCH_STATE]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    const writeContent = useMemo(() => {
        if (QUESTION_WRITE_FETCH_STATE === "FETCHING") {
            return <LoadingCircle position="absolute" />;
        }

        return (
            <>
                <PolicyWrapper>
                    <PolicyItems>
                        워크무드는 (이하 '회사'는) 이용자의 개인정보를 중요하시하며, 정보통신망 이용촉진 및 정보보호
                        등에 관한 법률(이하 '정보통신망법'), 개인정보보호법 등 국내의 개인정보 보호에 관한 법률을
                        준수하고 있습니다.
                    </PolicyItems>
                </PolicyWrapper>

                <QuestionContentWrapper>
                    <QuestionContentTitle>문의유형 및 매장선택</QuestionContentTitle>
                    <QuestionStyledSelect
                        showSelectValueType="label"
                        items={QUESTION_TYPE_ITEMS}
                        {...getFieldProps("question_type")}
                        placeholder="유형"
                    />

                    <QuestionStyledSelect
                        showSelectValueType="label"
                        items={QUESTION_CATEGORY_ITEMS}
                        {...getFieldProps("question_category")}
                        placeholder="분류"
                    />

                    <QuestionStoreWrapper>
                        <QuestionStore>
                            {shopName ? (
                                <QuestionStoreText>{shopName}</QuestionStoreText>
                            ) : (
                                <QuestionStoreTextPlcaeholder>매장을 선택해주세요</QuestionStoreTextPlcaeholder>
                            )}
                        </QuestionStore>
                        <QuestionStoreButton backgroundColor="#ff831e" onClick={handleOpenFindModal}>
                            매장찾기
                        </QuestionStoreButton>
                    </QuestionStoreWrapper>
                </QuestionContentWrapper>

                <QuestionContentWrapper>
                    <QuestionContentTitle>문의내용</QuestionContentTitle>
                    <QuestionWriteInput placeholder="제목을 입력해주세요" {...getFieldProps("title")} />
                    <QuestionWriteTextfield
                        placeholder="내용을 입력해주세요"
                        multiline
                        rows={4}
                        {...getFieldProps("content")}
                    />
                    <QuestionWriteImageButton
                        backgroundColor="#e2e2e2"
                        onClick={() => imageRef?.current && imageRef.current.click()}
                    >
                        <QuestionWriteImageButtonIcon src={ImageIcon} alt="image" /> 사진첨부
                    </QuestionWriteImageButton>

                    <QuestionWriteImageInput ref={imageRef} type="file" accept="image/*" onChange={handleInputImage} />

                    <QuestionWriteImageGuide>
                        5MB 이하의 이미지(PNG, JPG) 1개를 첨부하실 수 있습니다.
                    </QuestionWriteImageGuide>
                    <QuestionWriteImageGuide>음란/혐오등의 부적절한 첨부파일은 자체 삭제.</QuestionWriteImageGuide>

                    {values.image && (
                        <QuestionWriteImageWrapper>
                            <QuestionWriteImage>
                                <img src={getImageSrcFromFile(values.image)} alt="preview" />
                            </QuestionWriteImage>
                            <QuestionWriteImageName>{values.image.name}</QuestionWriteImageName>
                            <QuestionWriteImageResetButton
                                src={CloseIcon}
                                alt="reset"
                                onClick={handleClickImageReset}
                            />
                        </QuestionWriteImageWrapper>
                    )}

                    <QuestionWriteGuideWrapper>
                        <QuestionWriteGuideIcon src={AlertIcon} alt="alert icon" />
                        <QuestionWriteGuideText>
                            답변 등록 시 입력하신 이메일로 알림이 발송됩니다.
                            <br />
                            이메일 답변 알림에 동의하지 않으시는 경우 [문의내역]을 통해 조회 가능합니다.
                            <br />
                            개인정보(고유식별 정보) 및 음란성 파일은 별도의 통보 없이 삭제되며, 처벌될 수 있습니다.
                        </QuestionWriteGuideText>
                    </QuestionWriteGuideWrapper>
                </QuestionContentWrapper>

                <QuestionContentWrapper>
                    <QuestionContentTitle>답변등록 알림</QuestionContentTitle>

                    <QuestionAgreeCheckbox
                        label="이메일"
                        iconType="red_square"
                        labelColor="#0c0c0c"
                        labelBold
                        {...getFieldProps("use_email_notification")}
                    />
                    <QuestionAgreeCheckbox
                        labelColor="#0c0c0c"
                        labelBold
                        label="PUSH"
                        iconType="red_square"
                        {...getFieldProps("use_push_notification")}
                    />

                    <QuestionAgreeGuideWrapper>
                        <QuestionAgreeGuideIcon src={AlertIcon} alt="alert icon" />
                        <QuestionAgreeGuideText>
                            '답변 등록 알림' 내 PUSH 알림을 선택하신 경우 답변 완료 시 APP PUSH를 통해 안내됩니다.
                            (환경설정 내 알림 설정이 OFF인 경우, 답변 등록 알림 PUSH 선택시에도 알림이 되지 않습니다.
                        </QuestionAgreeGuideText>
                    </QuestionAgreeGuideWrapper>

                    <InquiyWriteButton
                        backgroundColor="#ff831e"
                        onClick={handleClickWriteButton}
                        disabled={!values.title || !values.content}
                    >
                        등록하기
                    </InquiyWriteButton>
                </QuestionContentWrapper>
            </>
        );
    }, [QUESTION_WRITE_FETCH_STATE, values, getFieldProps]);

    return (
        <Wrapper>
            <StoreFindModal
                open={findModal}
                handleClose={handleCloseFindModal}
                handleClickStoreItem={handleClickSearchResult}
                disableStoreClose
            />
            <Modal
                open={alertModal}
                handleClose={handleCloseAlertModal}
                titleText="알림"
                buttonText="확인"
                fullSizeButton
                buttonOnClick={handleCloseAlertModal}
                wrapperPadding="18px 16px 16px 16px"
            >
                <AlertModalContent>{alertModalContent}</AlertModalContent>
            </Modal>
            {completeModal ? (
                <QuestionWriteCompleteWrapper>
                    <QuestionWriteCompleteImage>
                        <img src={CompleteIcon} alt="complete" />
                    </QuestionWriteCompleteImage>
                    <QuestionWriteCompleteTitle>문의가 접수되었어요</QuestionWriteCompleteTitle>
                    <QuestionWriteCompleteSubtitle>
                        문의가 정상적으로 접수되었어요
                        <br />
                        확인하여 답변 드리겠습니다
                    </QuestionWriteCompleteSubtitle>
                    <QuestionWriteCompleteButton
                        variant="contained"
                        backgroundColor="#191919"
                        onClick={() => handleChangeTab("list")}
                    >
                        내 문의내역 보기
                    </QuestionWriteCompleteButton>
                </QuestionWriteCompleteWrapper>
            ) : (
                writeContent
            )}
        </Wrapper>
    );
}
