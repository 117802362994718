import React from "react";

import { EndCircle as StyledEndCircle } from "./style";

interface EndCircleProps {
    children?: string | React.ReactNode;
}

export default function EndCircle({ children, ...args }: EndCircleProps) {
    return <StyledEndCircle {...args}>{children}</StyledEndCircle>;
}
