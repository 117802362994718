import { ArrowForwardIos } from "@mui/icons-material";
import { ButtonBaseProps } from "@mui/material";
import React from "react";
import Link from "@/components/CustomLink";
import { IconImg, Title, StyledLinkButton } from "./style";

interface LinkButtonProps extends ButtonBaseProps {
    iconSrc?: string;
    title: string;
    href?: string;
    noBorder?: boolean;
}

export default function LinkButton({ title, iconSrc, href, className, ...rest }: LinkButtonProps) {
    if (!href) {
        return (
            <StyledLinkButton className={className} {...rest}>
                {iconSrc && <IconImg src={iconSrc} />}
                <Title>{title}</Title>
                <ArrowForwardIos sx={{ color: "#757575", fontSize: "16px" }} />
            </StyledLinkButton>
        );
    }

    return (
        <Link to={href} style={{ width: "100%", textDecoration: "none" }} className={className}>
            <StyledLinkButton {...rest}>
                {iconSrc && <IconImg src={iconSrc} />}
                <Title>{title}</Title>
                <ArrowForwardIos sx={{ color: "#757575", fontSize: "16px" }} />
            </StyledLinkButton>
        </Link>
    );
}
