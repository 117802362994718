import styled from "@emotion/styled";

export interface GridContainerProps {
    itemWidth: number;
    rowGap?: number;
    columnGap?: number;
}

export const GridContainer = styled.div<GridContainerProps>`
    display: grid;
    width: 100%;
    row-gap: ${({ rowGap }) => rowGap || 0}px;
    column-gap: ${({ columnGap }) => columnGap || 0}px;
    grid-template-columns: ${({ itemWidth }) => `repeat(auto-fill, minmax(${itemWidth}px, auto))`};
    justify-content: space-around;
`;
