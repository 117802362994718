import React, { useCallback, useState } from "react";
import { addMonths, subMonths, subYears } from "date-fns";
import SliderIcon from "@/assets/img/ic_slider.svg";
import { dateFormatter } from "@/utils/date";
import DatePicker from "@/components/DatePicker";
import { getToday } from "@/utils/time";
import { DateHeader, DateHeaderText, DateHeaderButton } from "./style";

interface BarDateRangePickerProps {
    startDate?: Date | null;
    endDate?: Date | null;
    setDate?: (sd: Date | null, ed: Date | null) => void;
}

export default function BarDateRangePicker({ startDate, endDate, setDate }: BarDateRangePickerProps) {
    const today = getToday();
    const prevMonth = subMonths(today, 1);
    const [inputStartDate, setInputStartDate] = useState<Date | null>(prevMonth);
    const [inputEndDate, setInputEndDate] = useState<Date | null>(today);
    const [dateModal, setDateModal] = useState<boolean>(false);
    const [dateCategory, setDateCategory] = useState("custom");

    const handleInputStartDateChange = (newValue: Date | null) => setInputStartDate(newValue);
    const handleInputEndDateChange = (newValue: Date | null) => setInputEndDate(newValue);
    const handleOpenDateModal = () => setDateModal(true);

    const handleChangeDateCategory = useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            switch (value) {
                case "year": {
                    handleInputStartDateChange(subYears(inputEndDate ?? today, 1));
                    break;
                }
                case "month": {
                    handleInputStartDateChange(subMonths(inputEndDate ?? today, 1));
                    break;
                }
            }

            setDateCategory(value);
        },
        [inputStartDate],
    );

    const handleCloseDateModal = () => {
        if (endDate && startDate) {
            setInputEndDate(endDate);
            setInputStartDate(startDate);
        }

        setDateModal(false);
    };

    const handleClickModalButton = () => {
        if (setDate) {
            setDate(inputStartDate, inputEndDate);
        }

        setDateModal(false);
    };

    return (
        <>
            <DatePicker
                open={dateModal}
                dateCategory={dateCategory}
                handleDateCategoryChange={handleChangeDateCategory}
                handleClose={handleCloseDateModal}
                handleSubmit={handleClickModalButton}
                startDate={inputStartDate}
                endDate={inputEndDate}
                handleStartDateChange={handleInputStartDateChange}
                handleEndDateChange={handleInputEndDateChange}
                minDate={addMonths(today, -3)}
            />
            <DateHeader>
                <DateHeaderText>
                    {dateFormatter(startDate || inputStartDate, "YYYY.MM.DD")} ~{" "}
                    {dateFormatter(endDate || inputEndDate, "YYYY.MM.DD")}
                </DateHeaderText>
                <DateHeaderButton onClick={handleOpenDateModal}>
                    <img src={SliderIcon} alt="slider icon" />
                    기간설정
                </DateHeaderButton>
            </DateHeader>
        </>
    );
}
