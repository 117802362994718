import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
`;

export const ItemContainer = styled.div`
    width: 1000px;
    border: 1px solid #c8c8c8;
    padding: 0 20px;
`;

export const AvatarContainer = styled.div`
    width: 60px;
    height: 60px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const PosLink = styled(Link)``;
