import React from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MenuGroupResponse } from "@ferda/rest-api-client";
import useHistory from "@/hooks/useCustomHistory";
import { getRestApiClient } from "@/redux/auth/selectors";
import { getOauth2TokenFromLocalStorage } from "@/redux/auth/utils";
import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import { MenuGroupInfoTitle } from "./styled";
import PlaceholderIcon from "@/assets/img/place_holder.png";

interface Props {}

export default function PosMenu(props: Props) {
    const history = useHistory();
    const { posId } = useParams<{ posId: string }>();

    const { shopApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [menuGroupList, setMenuGroupList] = React.useState<MenuGroupResponse[]>([]);
    const [menuGroupListTotal, setMenuGroupListTotal] = React.useState<number>(0);

    const [page, setPage] = React.useState<number>(1);

    const columns = [
        {
            title: "사진",
            key: "image",
            render: (record: MenuGroupResponse) => {
                return {
                    children: (
                        <img
                            src={record.uploaded_image_set?.original_image_url || PlaceholderIcon}
                            width={64}
                            height={64}
                        />
                    ),
                };
            },
            width: "100px",
        },
        {
            title: "정보",
            key: "info",
            render: (record: MenuGroupResponse) => {
                return {
                    children: (
                        <>
                            <MenuGroupInfoTitle onClick={() => history.push(`/pos/${posId}/menu/${record.id}`)}>
                                {record.name}
                            </MenuGroupInfoTitle>
                            <p>{record.description}</p>
                        </>
                    ),
                };
            },
        },
    ];

    React.useEffect(() => {
        const tokens = getOauth2TokenFromLocalStorage();
        if (!tokens) {
            history.push("/pos/login");
            message.error("회원 정보를 불러오지 못하였습니다.");
        }
    }, []);

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getMenuGroups(false, page)
            .then((res) => {
                setLoading(false);
                setMenuGroupList(res.data);
                setMenuGroupListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("메뉴 그룹을 불러오는데 실패하였습니다.");
            });
    }, [page]);

    return (
        <>
            <PageTitle>메뉴 목록</PageTitle>
            <Container>
                <Table columns={columns} dataSource={menuGroupList} loading={loading} rowKey="id" />
                {menuGroupListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={menuGroupListTotal}
                    />
                )}
            </Container>
        </>
    );
}
