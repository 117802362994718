import { Toolbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { OrderStatus } from "@ferda/rest-api-client";
import { differenceInSeconds } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import useHistory from "@/hooks/useCustomHistory";
import { ElevateAppBar } from "../AppBar";
import {
    ReadyText,
    SubTitleImportantContainer,
    StyledToolbarWithGlow,
    LeadingIconImage,
    TitleContainer,
    SubTitleContainer,
    StyledProgressBar,
} from "./style";
import CoolTimeIcon from "@/assets/icons/cooltime.svg";
import OrderIcon from "@/assets/icons/order.svg";
import MakingIcon from "@/assets/icons/making.svg";
import CompletionIcon from "@/assets/icons/completion.svg";
import TicketIcon from "@/assets/icons/ticket.svg";
import { getSubscribeStatus, getSubscribeStatusFetchState } from "@/redux/subscription/selectors";
import { fetchSubscribeStatusThunk } from "@/redux/subscription/thunk";
import { SubscribeStatusUpdater } from "../SubscribeStatusUpdater";

export default function OrderAppBar() {
    const [now, setNow] = useState(new Date());
    const history = useHistory();
    const dispatch = useDispatch();
    const subscribeStatus = useSelector(getSubscribeStatus);
    const subscribeStatusFetchState = useSelector(getSubscribeStatusFetchState);

    const handleClickAppBar = () => {
        if (!subscribeStatus || !subscribeStatus.subscription_in_use) {
            history.push("/service?tab=1");
            return;
        }

        if (subscribeStatus.can_order) {
            history.push("/order");
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const timeLeft = useMemo(() => {
        if (!subscribeStatus || !subscribeStatus.next_order_available_datetime) {
            return 60 * 60 * 3;
        }

        const diff = differenceInSeconds(new Date(subscribeStatus.next_order_available_datetime), now);

        if (diff < 0) {
            return 0;
        }

        return diff;
    }, [subscribeStatus, now]);

    useEffect(() => {
        if (subscribeStatusFetchState !== "READY") {
            return;
        }

        dispatch(fetchSubscribeStatusThunk({}));
    }, [subscribeStatusFetchState]);

    useEffect(() => {
        if (timeLeft !== 0) {
            return;
        }

        dispatch(fetchSubscribeStatusThunk({}));
    }, [timeLeft]);

    const leadingIcon = useMemo(() => {
        if (!subscribeStatus || !subscribeStatus.subscription_in_use) {
            return TicketIcon;
        }

        if (subscribeStatus?.can_order) {
            return CoolTimeIcon;
        }

        switch (subscribeStatus?.last_order?.order_status) {
            case OrderStatus.ORDER_COMPLETED: {
                return OrderIcon;
            }
            case OrderStatus.MAKE_IN_PROGRESS: {
                return MakingIcon;
            }
            case OrderStatus.MAKE_COMPLETED: {
                return CompletionIcon;
            }
        }
    }, [subscribeStatus]);

    const title = useMemo(() => {
        if (!subscribeStatus || !subscribeStatus.subscription_in_use) {
            return "워크무드를 구독해보세요!";
        }

        if (subscribeStatus.hold) {
            return <ReadyText>지금은 홀딩 중이에요.</ReadyText>;
        }

        if (subscribeStatus.can_order) {
            return <ReadyText>지금 음료를 주문할 수 있어요!</ReadyText>;
        }

        switch (subscribeStatus?.last_order?.order_status) {
            case OrderStatus.ORDER_COMPLETED: {
                return "주문완료";
            }
            case OrderStatus.MAKE_IN_PROGRESS: {
                return "제조중";
            }
            case OrderStatus.MAKE_COMPLETED: {
                return "제조완료";
            }
        }

        if (subscribeStatus.next_order_available_datetime) {
            return (
                <>
                    <img src={TicketIcon} alt="ticket" style={{ marginRight: "8px" }} />
                    {subscribeStatus.subscription_in_use?.name}
                </>
            );
        }
    }, [subscribeStatus]);

    const subTitle = useMemo(() => {
        if (
            !subscribeStatus ||
            !subscribeStatus.subscription_in_use ||
            subscribeStatus.can_order ||
            !subscribeStatus.next_order_available_datetime
        ) {
            return;
        }

        if (subscribeStatus.last_order) {
            switch (subscribeStatus.last_order.order_status) {
                case OrderStatus.ORDER_COMPLETED: {
                    return (
                        <>
                            주문번호{" "}
                            <SubTitleImportantContainer>
                                {subscribeStatus.last_order.display_id}
                            </SubTitleImportantContainer>
                            로 주문되었습니다.
                        </>
                    );
                }
                case OrderStatus.MAKE_IN_PROGRESS: {
                    return "주문하신 메뉴를 제조중입니다";
                }
                case OrderStatus.MAKE_COMPLETED: {
                    return "주문하신 음료가 완성되었습니다";
                }
            }
        }

        return (
            <>
                남은시간{" "}
                <SubTitleImportantContainer>
                    {Math.floor(timeLeft / (60 * 60))}시간 {Math.floor((timeLeft / 60) % 60)}분 {timeLeft % 60}초
                </SubTitleImportantContainer>
            </>
        );
    }, [subscribeStatus, timeLeft]);

    const progress = useMemo(() => {
        if (!subscribeStatus || !subscribeStatus.subscription_in_use || subscribeStatus?.can_order) {
            return;
        }

        switch (subscribeStatus?.last_order?.order_status) {
            case OrderStatus.ORDER_COMPLETED: {
                return 33.333;
            }
            case OrderStatus.MAKE_IN_PROGRESS: {
                return 66.666;
            }
            case OrderStatus.MAKE_COMPLETED: {
                return 100;
            }
        }
    }, [subscribeStatus]);

    return (
        <>
            <SubscribeStatusUpdater />
            <ElevateAppBar disableBorder backgroundColor="#ff831e" onClick={handleClickAppBar}>
                <StyledToolbarWithGlow disableBorder glow={subscribeStatus?.can_order}>
                    <LeadingIconImage src={leadingIcon} />
                    <TitleContainer>
                        {title}
                        {subTitle && <SubTitleContainer> | {subTitle}</SubTitleContainer>}
                    </TitleContainer>
                </StyledToolbarWithGlow>
                {progress && <StyledProgressBar variant="determinate" value={progress} />}
            </ElevateAppBar>
            <Toolbar />
        </>
    );
}
