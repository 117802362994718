import { createSlice } from "@reduxjs/toolkit";
import { MainState } from "./types";
import { setValueReducerList } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: MainState = {
    tab: "HOME",
};

export const MAIN_STATE_KEY_LIST = getKeyListFromState(initialState);

export const mainSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        ...setValueReducerList<MainState, ["tab"]>(["tab"]),
    },
});

export const mainReducer = mainSlice.reducer;
