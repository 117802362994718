import { createSlice } from "@reduxjs/toolkit";
import { makeAsyncReducer } from "@/utils/redux/reducer";
import { AdminAuthType, AdminState } from "./types";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: AdminState = {
    adminType: "UNLOADED",
    adminTypeFetchState: "READY",
};

export const ADMIN_STATE_KEY_LIST = getKeyListFromState(initialState);

export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        ...makeAsyncReducer<"adminType", AdminState, void, AdminAuthType>("adminType", {
            requestReducer: (state) => {
                state.adminType = "UNLOADED";
                state.adminTypeFetchState = "FETCHING";
            },
            successReducer: (state, action) => {
                state.adminType = action.payload;
                state.adminTypeFetchState = "SUCCESS";
            },
            failureReducer: (state) => {
                state.adminType = "UNAUTHORIZED";
                state.adminTypeFetchState = "FAILURE";
            },
        }),
    },
});

export const adminReducer = adminSlice.reducer;
