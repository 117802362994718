import React from "react";
import { Link, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider, Layout as AntLayout, Menu } from "antd";
import koKR from "antd/lib/locale/ko_KR";
import Route from "@/components/RouteWithAuthGuard";

import AdminUser from "@/pages/Admin/Users";
import AdminAdmins from "@/pages/Admin/Admins";
import AdminDeleted from "@/pages/Admin/Deleted";
import AdminPointLog from "@/pages/Admin/PointLog";
import AdminGifts from "@/pages/Admin/Gifts";
import AdminQuestions from "@/pages/Admin/Questions";
import AdminNotifications from "@/pages/Admin/Notifications";
import AdminShops from "@/pages/Admin/Shops";
import AdminShopsEdit from "@/pages/Admin/ShopsEdit";
import AdminMenus from "@/pages/Admin/Menus";
import AdminMenusOrder from "@/pages/Admin/MenusOrder";
import AdminMenusView from "@/pages/Admin/MenusView";
import AdminMenusViewOrder from "@/pages/Admin/MenusViewOrder";
import AdminMenusViewRecommend from "@/pages/Admin/MenusViewRecommend";
import AdminOrders from "@/pages/Admin/Orders";
import AdminPosts from "@/pages/Admin/Posts";
import AdminPostsAdd from "@/pages/Admin/PostsAdd";
import AdminPostsEdit from "@/pages/Admin/PostsEdit";
import AdminPostsView from "@/pages/Admin/PostsView";
import AdminPostsOrder from "@/pages/Admin/PostsOrder";
import AdminSubscriptionInfo from "@/pages/Admin/SubscriptionInfo";
import AdminSubscriptionInfoEdit from "@/pages/Admin/SubscriptionInfoEdit";

const { Content, Sider } = AntLayout;

interface Props {
    children?: React.ReactNode;
}

export default function Page(props: Props) {
    const history = useHistory();
    const location = useLocation();
    const paths = location.pathname.split("/").splice(1, 2);

    if (!paths[1]) {
        history.push("/admin/login");
    }

    const routeMatch = useRouteMatch();
    return (
        <ConfigProvider locale={koKR}>
            <AntLayout style={{ minHeight: "100vh" }}>
                <Sider width="250px">
                    <Menu mode="inline" selectedKeys={[paths[1]]}>
                        <Menu.Item key="users">
                            <Link to="/admin/users">회원 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="admins">
                            <Link to="/admin/admins">어드민 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="deleted">
                            <Link to="/admin/deleted">탈퇴 사유</Link>
                        </Menu.Item>
                        <Menu.Item key="pointlogs">
                            <Link to="/admin/pointlogs">포인트 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="questions">
                            <Link to="/admin/questions">질문 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="notifications">
                            <Link to="/admin/notifications">알림 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="shops">
                            <Link to="/admin/shops">가맹점 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="menus">
                            <Link to="/admin/menus">메뉴 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="orders">
                            <Link to="/admin/orders">주문 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="posts">
                            <Link to="/admin/posts">게시글 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="subscriptioninfo">
                            <Link to="/admin/subscriptioninfo">구독 정보</Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <AntLayout className="site-layout" style={{ backgroundColor: "#ffffff" }}>
                    <Content style={{ margin: "0 auto", padding: "1rem", width: "97rem" }}>
                        <Switch>
                            <Route path={`${routeMatch.path}/users`} component={AdminUser} />
                            <Route path={`${routeMatch.path}/admins`} component={AdminAdmins} />
                            <Route path={`${routeMatch.path}/deleted`} component={AdminDeleted} />
                            <Route path={`${routeMatch.path}/pointlogs/:userId`} component={AdminPointLog} />
                            <Route path={`${routeMatch.path}/pointlogs`} component={AdminPointLog} />
                            <Route path={`${routeMatch.path}/gifts/:userId`} component={AdminGifts} />
                            <Route path={`${routeMatch.path}/questions`} component={AdminQuestions} />
                            <Route path={`${routeMatch.path}/notifications`} component={AdminNotifications} />
                            <Route path={`${routeMatch.path}/shops/edit/:shopId`} component={AdminShopsEdit} />
                            <Route path={`${routeMatch.path}/shops`} component={AdminShops} />
                            <Route path={`${routeMatch.path}/menus/order`} component={AdminMenusOrder} />
                            <Route
                                path={`${routeMatch.path}/menus/view/:groupId/order`}
                                component={AdminMenusViewOrder}
                            />
                            <Route
                                path={`${routeMatch.path}/menus/view/:groupId/recommend`}
                                component={AdminMenusViewRecommend}
                            />
                            <Route path={`${routeMatch.path}/menus/view/:groupId`} component={AdminMenusView} />
                            <Route path={`${routeMatch.path}/menus`} component={AdminMenus} />
                            <Route path={`${routeMatch.path}/orders`} component={AdminOrders} />
                            <Route path={`${routeMatch.path}/posts/add`} component={AdminPostsAdd} />
                            <Route path={`${routeMatch.path}/posts/edit/:postId`} component={AdminPostsEdit} />
                            <Route path={`${routeMatch.path}/posts/view/:postId`} component={AdminPostsView} />
                            <Route path={`${routeMatch.path}/posts/order`} component={AdminPostsOrder} />
                            <Route path={`${routeMatch.path}/posts`} component={AdminPosts} />
                            <Route
                                path={`${routeMatch.path}/subscriptioninfo/edit`}
                                component={AdminSubscriptionInfoEdit}
                            />
                            <Route path={`${routeMatch.path}/subscriptioninfo`} component={AdminSubscriptionInfo} />
                        </Switch>
                    </Content>
                </AntLayout>
            </AntLayout>
        </ConfigProvider>
    );
}
