import axios from "axios";
import { MiscellaneousType, ServiceCancelReasonType } from "@ferda/rest-api-client";
import {
    subscribeStatusFetchRequest,
    subscribeStatusFetchSuccess,
    subscribeStatusFetchFailure,
    subscriptionListFetchRequest,
    subscriptionListFetchSuccess,
    subscriptionListFetchFailure,
    subscribeFetchRequest,
    subscribeFetchSuccess,
    subscribeFetchFailure,
    miscFetchRequest,
    miscFetchSuccess,
    miscFetchFailure,
    unSubscribeFetchRequest,
    unSubscribeFetchFailure,
    unSubscribeFetchSuccess,
    holdSubscriptionFetchFailure,
    holdSubscriptionFetchSuccess,
    holdSubscriptionFetchRequest,
} from "./actions";
import { GenericThunkAction, makeFetchThunkAction } from "@/utils/redux/thunk";
import { requestPayment } from "../payment/functions";
import { push, reset } from "@/redux/customHistory/actions";
import { openErrorSnackbar, openSuccessSnackbar } from "@/redux/snackbar/actions";

export const fetchSubscribeStatusThunk = makeFetchThunkAction(
    subscribeStatusFetchRequest,
    subscribeStatusFetchSuccess,
    subscribeStatusFetchFailure,
    {
        getResponseFunction: async ({
            state: {
                auth: {
                    restApiClient: { serviceApi },
                },
            },
        }) => serviceApi.getSubscribeStatus("me"),
    },
);

export const fetchSubscsriptionListThunk = makeFetchThunkAction(
    subscriptionListFetchRequest,
    subscriptionListFetchSuccess,
    subscriptionListFetchFailure,
    {
        getResponseFunction: async ({
            state: {
                auth: {
                    restApiClient: { serviceApi },
                },
            },
        }) => serviceApi.getSubscriptions(),
    },
);

export const fetchSubscribeThunk = makeFetchThunkAction(
    subscribeFetchRequest,
    subscribeFetchSuccess,
    subscribeFetchFailure,
    {
        getResponseFunction: async (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                        user,
                    },
                    payment: { paymentMethod },
                    subscription: { subscriptionList },
                },
            },
            id: number,
        ) => {
            const subscription = subscriptionList!.find((sub) => sub.id === id)!;

            const res = await requestPayment({
                user: user!,
                price: subscription.price,
                productName: subscription.name,
                type: "GIFT",
                getCallbackUrl: (merchantUid) =>
                    window.location.href.split("//")[0] +
                    "//" +
                    window.location.host +
                    "/subscribe-callback?params=" +
                    encodeURIComponent(
                        JSON.stringify({
                            merchant_uid: merchantUid,
                            type: "SUBSCRIPTION",
                            id: subscription.id,
                        }),
                    ),
            });

            if (!res) {
                throw new Error();
            }

            return shopApi.subscriptionOneTimeSubscribe(subscription.id, { merchant_uid: res.merchant_uid });
        },
    },
);

export const fetchSubscribeCallbackThunk = makeFetchThunkAction(
    subscribeFetchRequest,
    subscribeFetchSuccess,
    subscribeFetchFailure,
    {
        getResponseFunction: async (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                    },
                },
                dispatch,
            },
            params: { merchantUid: string; subscriptionId: number },
        ) => {
            try {
                const result = await shopApi.subscriptionOneTimeSubscribe(params.subscriptionId, {
                    merchant_uid: params.merchantUid,
                });

                dispatch(reset());
                dispatch(push("/"));
                dispatch(push("/me/subscription-info"));

                return result;
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    dispatch(openErrorSnackbar(e.response?.data.detail));
                }
                throw e;
            }
        },
    },
);

export const fetchMiscThunk = makeFetchThunkAction(miscFetchRequest, miscFetchSuccess, miscFetchFailure, {
    getResponseFunction: async ({
        state: {
            auth: {
                restApiClient: { serviceApi },
            },
        },
    }) => serviceApi.getMiscellaneous(MiscellaneousType.SUBSCRIPTION_INFO),
});

export function unSubscribeRequestThunk(reason: string): GenericThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { userApi, serviceApi },
            },
        } = getState();

        dispatch(unSubscribeFetchRequest());

        try {
            await serviceApi.createServiceCancelReason({
                reason,
                service_cancel_reason_type: ServiceCancelReasonType.UNSUBSCRIBE,
            });
        } catch (e) {
            if (e instanceof Error) {
                dispatch(unSubscribeFetchFailure(e));
                return;
            }
        }

        try {
            await userApi.deleteBillingKey("me");
        } catch (e) {
            if (e instanceof Error) {
                dispatch(unSubscribeFetchFailure(e));
                return;
            }
        }

        dispatch(unSubscribeFetchSuccess());
    };
}

export const fetchHoldSubscriptionThunk = makeFetchThunkAction(
    holdSubscriptionFetchRequest,
    holdSubscriptionFetchSuccess,
    holdSubscriptionFetchFailure,
    {
        getResponseFunction: async (
            {
                state: {
                    auth: {
                        restApiClient: { serviceApi },
                    },
                },
                dispatch,
            },
            params: {},
        ) => {
            try {
                const result = await serviceApi.holdSubscription("me");

                dispatch(openSuccessSnackbar("홀딩 되었습니다."));

                return result;
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    dispatch(openErrorSnackbar(e.response?.data.detail));
                }
                throw e;
            }
        },
    },
);
