import { OrderStatus } from "@ferda/rest-api-client";

export function getHistoryMenuItemStatusText(
    status: OrderStatus,
): "주문완료" | "제조중" | "제조완료" | "주문취소" | "픽업완료" {
    switch (status) {
        case OrderStatus.ORDER_COMPLETED: {
            return "주문완료";
        }
        case OrderStatus.MAKE_IN_PROGRESS: {
            return "제조중";
        }
        case OrderStatus.MAKE_COMPLETED: {
            return "제조완료";
        }
        case OrderStatus.ORDER_CANCELED: {
            return "주문취소";
        }
        case OrderStatus.PICKUP_COMPLETED: {
            return "픽업완료";
        }
        default: {
            return "주문취소";
        }
    }
}
