import styled from "@emotion/styled";
import { LinearProgress } from "@mui/material";
import Divider from "@/components/Divider";
import LinkButton from "@/components/LinkButton";

export const SubscriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    padding: 20px;
`;

export const TicketImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    height: 120px;
    width: 120px;

    border-radius: 120px;
    background-color: #ff831e;
`;

export const TicketImg = styled.img``;

export const SubscriptionTitle = styled.div`
    margin-top: 24px;

    color: white;
    font-size: 24px;
    font-weight: bold;
`;

export const StyledDivider = styled(Divider)`
    margin-top: 24px;
`;

export const PeriodLeftContainer = styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
`;

export const PeriodContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    font-size: 14px;
`;

export const PeriodTitle = styled.div`
    color: #757575;
`;

export const PeriodDate = styled.div`
    color: #0c0c0c;
`;

export const StyledProgressBar = styled(LinearProgress)`
    width: 100%;
    height: 8px;
    margin-top: 16px;

    border-radius: 4px;
`;

export const RefreshRemainContainer = styled.div`
    margin-top: 8px;

    text-align: right;
    color: #0c0c0c;
    font-size: 14px;
`;

export const RefreshRemainDateContainer = styled.span`
    color: #ff831e;
    font-weight: bold;
`;

export const StyledLinkButton = styled(LinkButton)`
    margin-top: 80px;
`;
