import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import { getSubscribeStatus } from "@/redux/subscription/selectors";
import { fetchSubscribeStatusThunk } from "@/redux/subscription/thunk";
import Footer from "@/components/Footer";
import { SubscribeInfoContent } from "@/components/SubscribeInfoContent";

export default function SubscriptionInfo() {
    const subscribeStatus = useSelector(getSubscribeStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSubscribeStatusThunk({}));
    }, []);

    return (
        <AppLayout
            disableAppBarBorder={true}
            title="구독권 정보"
            titleColor="#fff"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            {subscribeStatus && (
                <SubscribeInfoContent
                    subscription={subscribeStatus}
                    buttonText="결제정보"
                    buttonLink="/me/payment-method-settings"
                />
            )}
            <Footer />
        </AppLayout>
    );
}
