import React from "react";
import { useSelector } from "react-redux";
import Link from "@/components/CustomLink";
import InfoCard from "./InfoCard";
import { getUserAsAlwaysExists } from "@/redux/auth/selectors";
import {
    ContentContainer,
    GreetingContainer,
    MyPageContainer,
    HelloContainer,
    NameContainer,
    HeaderRow,
    EditMyInfoButton,
    PencilIconImg,
} from "./style";
import SubscribeInfo from "./SubscribeInfo";
import TodayStatistics from "./TodayStatistics";
import PencilIconImage from "@/assets/img/ic_pencil.svg";

export default function My() {
    const user = useSelector(getUserAsAlwaysExists);

    return (
        <MyPageContainer>
            <HeaderRow>
                <GreetingContainer>
                    <HelloContainer>안녕하세요,</HelloContainer>
                    <NameContainer> {user.detail?.name ?? "회원"}님!</NameContainer>
                </GreetingContainer>
                <Link to="/me/edit">
                    <EditMyInfoButton>
                        <PencilIconImg src={PencilIconImage} />
                        내정보 수정
                    </EditMyInfoButton>
                </Link>
            </HeaderRow>
            <ContentContainer>
                <InfoCard />
                <SubscribeInfo />
                <TodayStatistics />
            </ContentContainer>
        </MyPageContainer>
    );
}
