import {
    CupType,
    Gender,
    MenuOrderByColumn,
    NotificationType,
    OrderStatus,
    PostType,
    QuestionCategory,
    QuestionType,
    ServiceCancelReasonType,
    TemperatureType,
    UserOrderByColumn,
} from "@ferda/rest-api-client";

export function cupTypeToText(cupType: CupType): string {
    switch (cupType) {
        case CupType.DISPOSABLE:
            return "뉴 컵";
        case CupType.INDIVIDUAL:
            return "개인 컵";
        case CupType.REUSABLE:
            return "리턴 컵";
    }
}

export function genderToText(gender: Gender): string {
    switch (gender) {
        case Gender.FEMALE:
            return "여성";
        case Gender.MALE:
            return "남성";
    }
}

export function menuOrderByColumnToText(menuOrderByColumn: MenuOrderByColumn): string {
    switch (menuOrderByColumn) {
        case MenuOrderByColumn.ORDER_PRIORITY_ASC:
            return "순서 오름차순";
        case MenuOrderByColumn.PRICE_ASC:
            return "가격 오름차순";
        case MenuOrderByColumn.PRICE_DESC:
            return "가격 내림차순";
        case MenuOrderByColumn.RECOMMENDED_ORDER_PRIORITY_ASC:
            return "추천 오름차순";
    }
}

export function notificationTypeToText(notificationType: NotificationType): string {
    switch (notificationType) {
        case NotificationType.AD:
            return "광고";
        case NotificationType.EVENT:
            return "이벤트";
        case NotificationType.GIFT:
            return "선물";
        case NotificationType.NOTICE:
            return "공지";
        case NotificationType.ORDER:
            return "주문";
    }
}

export function orderStatusToText(orderStatus: OrderStatus): string {
    switch (orderStatus) {
        case OrderStatus.MAKE_COMPLETED:
            return "제조완료";
        case OrderStatus.MAKE_IN_PROGRESS:
            return "제조중";
        case OrderStatus.ORDER_CANCELED:
            return "주문취소";
        case OrderStatus.ORDER_COMPLETED:
            return "주문완료";
        case OrderStatus.PICKUP_COMPLETED:
            return "픽업완료";
    }
}

export function postTypeToText(postType: PostType): string {
    switch (postType) {
        case PostType.CONTENT:
            return "콘텐츠";
        case PostType.EVENT:
            return "이벤트";
        case PostType.NOTICE:
            return "공지사항";
    }
}

export function questionCategoryToText(questionCategory: QuestionCategory): string {
    switch (questionCategory) {
        case QuestionCategory.CLEANLINESS:
            return "청결";
        case QuestionCategory.ETC:
            return "기타";
        case QuestionCategory.FOREIGN:
            return "이물";
        case QuestionCategory.QUALITY:
            return "품질";
        case QuestionCategory.REFUND:
            return "환불";
        case QuestionCategory.SERVICE:
            return "서비스";
        case QuestionCategory.UNSUBSCRIBE:
            return "해지";
    }
}

export function questionTypeToText(questionText: QuestionType): string {
    switch (questionText) {
        case QuestionType.COMPLIMENT:
            return "칭찬";
        case QuestionType.DISSATISFACTION:
            return "불만";
        case QuestionType.QUESTION:
            return "문의";
        case QuestionType.SUGGESTION:
            return "제안";
    }
}

export function serviceCancelReasonTypeToText(serviceCancelReasonType: ServiceCancelReasonType): string {
    switch (serviceCancelReasonType) {
        case ServiceCancelReasonType.UNSUBSCRIBE:
            return "해지";
        case ServiceCancelReasonType.WITHDRAWAL:
            return "탈퇴";
    }
}

export function temperatureTypeToText(temperatureType: TemperatureType): string {
    switch (temperatureType) {
        case TemperatureType.HOT:
            return "HOT";
        case TemperatureType.ICED:
            return "ICED";
    }
}

export function userOrderByColumnToText(userOrderByColumn: UserOrderByColumn): string {
    switch (userOrderByColumn) {
        case UserOrderByColumn.CREATED_DATETIME_ASC:
            return "가입일자 오름차순";
        case UserOrderByColumn.CREATED_DATETIME_DESC:
            return "가입일자 내림차순";
        case UserOrderByColumn.NAME_ASC:
            return "이름 오름차순";
        case UserOrderByColumn.NAME_DESC:
            return "이름 내림차순";
    }
}
