import styled from "@emotion/styled";

export const HeaderContainer = styled.div`
    display: flex;
`;

export const HeaderSelectorWrapper = styled.div`
    flex-basis: 200px;
    margin-right: 1rem;
`;

export const HeaderTitleWrapper = styled.div`
    flex-grow: 1;
`;

export const FinishButtonContainer = styled.div`
    text-align: right;
`;

export const DateContainer = styled.div`
    margin-bottom: 1rem;
`;

export const TitleHiddenContainer = styled.div`
    margin-bottom: 1rem;
`;

export const TitleHiddenLabel = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

export const FileContainer = styled.div`
    margin-bottom: 1rem;
`;

export const FileWrapper = styled.div`
    margin-bottom: 0.5rem;
`;

export const PostDraggableContainer = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const PostDraggable = styled.li`
    display: flex;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #00000020;
    border-radius: 0.25rem;
    overflow: hidden;
    user-select: none;
    &:last-child {
        margin-bottom: 0;
    }
`;
