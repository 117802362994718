import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";
import useHistory from "@/hooks/useCustomHistory";
import BtnBackIcon from "@/assets/img/ic_btn_back.svg";

interface StyledIconButtonProps extends IconButtonProps {
    src: string | React.ReactNode;
    alt: string;
}

export const StyledIconButton = ({ src, alt, className, ...args }: StyledIconButtonProps) => {
    return (
        <IconButton className={className} {...args}>
            {typeof src === "string" ? <img src={src} alt={alt} /> : src}
        </IconButton>
    );
};

export function BackButton() {
    const history = useHistory();

    return (
        <IconButton color="default" onClick={() => history.back()}>
            <img src={BtnBackIcon} alt="back icon" />
        </IconButton>
    );
}
