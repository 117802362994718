import React from "react";
import moment from "moment";
import "moment/locale/ko";
import { Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row } from "antd";
import { PointLogResponse } from "@ferda/rest-api-client";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import FormItem from "@/components/Admin/FormItem";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import { Clear, Left, Right } from "@/components/Admin/Float";
import { TotalCountWrapper } from "@/components/Admin/Misc";

const { RangePicker } = DatePicker;

interface Props {}

export default function Page(props: Props) {
    const params = useParams<{ userId: string | undefined }>();

    const { userApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalLog, setModalLog] = React.useState<PointLogResponse | undefined>();

    const [logList, setLogList] = React.useState<PointLogResponse[]>([]);
    const [logListTotal, setLogListTotal] = React.useState<number>(0);

    const [minCreatedDate, setMinCreatedDate] = React.useState<Date | undefined>();
    const [maxCreatedDate, setMaxCreatedDate] = React.useState<Date | undefined>();
    const [page, setPage] = React.useState<number>(1);

    const [searchForm] = Form.useForm();
    const [addForm] = Form.useForm();

    const columns: any[] = [
        {
            title: "생성시각",
            key: "createdDatetime",
            render: (record: PointLogResponse) => {
                const time = moment(record.created_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
    ];

    if (params.userId === undefined) {
        columns.push({
            title: "이름",
            key: "name",
            render: (record: PointLogResponse) => {
                return { children: record.user.detail?.name };
            },
            width: "150px",
        });
    }

    columns.push(
        {
            title: "제목",
            key: "title",
            render: (record: PointLogResponse) => {
                return { children: record.title };
            },
            width: "300px",
        },
        {
            title: "포인트량",
            key: "point",
            render: (record: PointLogResponse) => {
                return { children: record.amount.toLocaleString("ko") };
            },
            width: "200px",
        },
        {
            title: "경로",
            key: "path",
            render: (record: PointLogResponse) => {
                return { children: record.path };
            },
        },
        {
            title: "관리",
            key: "manage",
            render: (record: PointLogResponse) => {
                return {
                    children: (
                        <Button
                            danger
                            onClick={() => {
                                setModal("delete");
                                setModalLog(record);
                            }}
                        >
                            삭제
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    );

    React.useEffect(() => {
        setLoading(true);
        userApi
            .getPointLogs(
                minCreatedDate && moment(minCreatedDate).format("YYYY-MM-DD"),
                maxCreatedDate && moment(maxCreatedDate).format("YYYY-MM-DD"),
                page,
                params.userId,
            )
            .then((res) => {
                setLoading(false);
                setLogList(res.data);
                setLogListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("포인트 목록을 불러오는데 실패하였습니다.");
            });
    }, [minCreatedDate, maxCreatedDate, page, rerender]);

    return (
        <>
            <PageTitle>포인트 관리</PageTitle>
            <Container>
                <Form
                    form={searchForm}
                    onFinish={(values) => {
                        const minCreatedDate = values.date && values.date[0] ? values.date[0].toDate() : undefined;
                        const maxCreatedDate = values.date && values.date[1] ? values.date[1].toDate() : undefined;

                        setMinCreatedDate(minCreatedDate);
                        setMaxCreatedDate(maxCreatedDate);
                        setPage(1);
                    }}
                >
                    <Left>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="date">
                                    <RangePicker disabled={loading} placeholder={["시작 일자", "가입 끝 일자"]} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Left>
                    <Right>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        htmlType="reset"
                                        onClick={() => {
                                            searchForm.resetFields();

                                            setMinCreatedDate(undefined);
                                            setMaxCreatedDate(undefined);
                                            setPage(1);
                                        }}
                                    >
                                        초기화
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button disabled={loading} htmlType="submit">
                                        검색
                                    </Button>
                                </Form.Item>
                            </Col>
                            {params.userId !== undefined && (
                                <Col style={{ marginLeft: "1.5rem" }}>
                                    <Form.Item>
                                        <Button disabled={loading} htmlType="submit" onClick={() => setModal("add")}>
                                            추가
                                        </Button>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </Right>
                    <Clear />
                </Form>
            </Container>
            <Container>
                <TotalCountWrapper>내역 수 : {logListTotal.toLocaleString("ko")}</TotalCountWrapper>
                <Table columns={columns} dataSource={logList} loading={loading} rowKey="id" />
                {logListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={logListTotal}
                    />
                )}
            </Container>
            <Modal
                confirmLoading={loading}
                okButtonProps={{ danger: true }}
                okText="삭제"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!modalLog) {
                        return;
                    }

                    setLoading(true);
                    userApi
                        .deletePointLogs(modalLog.id)
                        .then(() => {
                            setModal(undefined);
                            setModalLog(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("삭제하였습니다.", 1.5);
                        })
                        .catch(() => {
                            setLoading(false);
                            message.error("삭제를 실패하였습니다.", 1.5);
                        });
                }}
                title="목록 삭제"
                visible={modal === "delete"}
                width={800}
            >
                해당 기록을 삭제하시겠습니까?
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    htmlType: "submit",
                    form: "addForm",
                }}
                okText="추가"
                onCancel={() => setModal(undefined)}
                title="목록 추가"
                visible={modal === "add"}
                width={800}
            >
                <Form
                    form={addForm}
                    name="addForm"
                    onFinish={(values) => {
                        if (params.userId === undefined) {
                            return message.error("회원을 선택해주세요.", 1.5);
                        }
                        if (!values.title) {
                            return message.error("제목을 입력해주세요.", 1.5);
                        }
                        if (values.point < 0) {
                            return message.error("포인트량에 0 이상의 값을 입력해주세요.", 1.5);
                        }

                        setLoading(true);
                        userApi
                            .createPointLogs(params.userId, {
                                title: values.title,
                                amount: values.point,
                                path: values.path,
                            })
                            .then(() => {
                                setModal(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("추가하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="제목" name="title">
                        <Input disabled={loading} placeholder="제목" required />
                    </FormItem>
                    <FormItem label="포인트량" name="point">
                        <InputNumber
                            disabled={loading}
                            min={0}
                            placeholder="포인트량"
                            required
                            style={{ width: "100%" }}
                        />
                    </FormItem>
                    <FormItem hints={["선택항목"]} label="경로" name="path">
                        <Input disabled={loading} placeholder="경로" required />
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}
