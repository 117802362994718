import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useHistory from "@/hooks/useCustomHistory";
import {
    InfoCardTitleContainer,
    InfoCardContentContainer,
    InfoCardDivider,
    InfoCardContianer,
    InfoCardDescriptionContainer,
    ForwardArrow,
    PointIconImg,
    PointText,
    TicketIconImg,
} from "./style";
import PointIconImage from "@/assets/icons/point.svg";
import { getUserAsAlwaysExists } from "@/redux/auth/selectors";
import { getSubscribeStatus } from "@/redux/subscription/selectors";
import TicketIcon from "@/assets/img/ic_ticket_small_tilt.svg";
import { resetSubscriptionState } from "@/redux/subscription/actions";

export default function InfoCard() {
    const user = useSelector(getUserAsAlwaysExists);
    const subscribeStatus = useSelector(getSubscribeStatus);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleSubscriptionClick = () => {
        if (!subscribeStatus?.subscription_in_use) {
            return history.push("/service");
        }

        dispatch(resetSubscriptionState());
        history.push("/me/subscription-info");
    };

    const handlePointClick = () => {
        history.push("/me/point-log");
    };

    return (
        <InfoCardContianer>
            <InfoCardContentContainer>
                <InfoCardTitleContainer>사용중인 이용권</InfoCardTitleContainer>
                <InfoCardDescriptionContainer onClick={handleSubscriptionClick}>
                    {subscribeStatus?.subscription_in_use ? (
                        <>
                            <TicketIconImg src={TicketIcon} /> {subscribeStatus.subscription_in_use.name}
                        </>
                    ) : (
                        "-"
                    )}{" "}
                    <ForwardArrow />
                </InfoCardDescriptionContainer>
            </InfoCardContentContainer>
            <InfoCardDivider />
            <InfoCardContentContainer>
                <InfoCardTitleContainer>나의 포인트</InfoCardTitleContainer>
                <InfoCardDescriptionContainer onClick={handlePointClick}>
                    <PointIconImg src={PointIconImage} /> <PointText>{user.point}P</PointText> <ForwardArrow />
                </InfoCardDescriptionContainer>
            </InfoCardContentContainer>
        </InfoCardContianer>
    );
}
