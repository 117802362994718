import styled from "@emotion/styled";
import Button from "@/components/Button";

export const Wrapper = styled.div`
    flex-grow: 1;
    height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 150px;
`;

export const GiftImageWrapper = styled.div`
    width: 140px;
    height: 120px;
    margin-bottom: 28px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const GiftTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #ff831e;
    margin-bottom: 8px;
`;

export const GiftSubtitle = styled.p`
    font-size: 14px;
    color: #0c0c0c;
    margin-bottom: 40px;
`;

export const GiftListButton = styled(Button)`
    width: 192px;
    height: 45px;
    color: #0c0c0c;
    font-size: 14px;
    border-radius: 8px;
`;

export const BottomHomeButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 10000;

    width: 100%;
    padding-top: 8px;
    padding-left: 20px;
    padding-bottom: 8px;
    padding-right: 20px;

    background-color: #fff;
`;

export const HomeButton = styled(Button)`
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;

    color: white;
    font-size: 16px;
    font-weight: bold;

    background-color: #ff831e;
    border-radius: 25px;
`;
