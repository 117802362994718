import React from "react";
import { GridContainer, GridContainerProps } from "./style";

interface GridProps extends GridContainerProps {
    children: React.ReactNode[];
}

export default function Grid({ children, ...args }: GridProps) {
    return <GridContainer {...args}>{children}</GridContainer>;
}
