import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadAuthStateAndApiClientThunk } from "../auth/thunk";
import { saveOauth2TokenToLocalStorage } from "../auth/utils";
import { RootState } from "../types";
import { adminTypeFetchRequest, adminTypeFetchSuccess, adminTypeFetchFailure } from "./actions";

interface AdminThunkActionPayload {
    username: string;
    password: string;
}

type AdminThunkAction = ThunkAction<void, RootState, unknown, AnyAction>;

export function adminAuthenticateThunk({ username, password }: AdminThunkActionPayload): AdminThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { oauth2Api },
            },
        } = getState();
        dispatch(adminTypeFetchRequest());
        try {
            const { data } = await oauth2Api.generateToken(username, password);

            if (data.scopes.find((scope) => scope === "ADMIN")) {
                saveOauth2TokenToLocalStorage({
                    token: data.access_token,
                    tokenExpireDate: new Date(data.access_token_expire_datetime),
                    refreshToken: data.refresh_token,
                    refreshTokenExpireDate: new Date(data.refresh_token_expire_datetime),
                });

                dispatch(
                    loadAuthStateAndApiClientThunk(() => {
                        dispatch(adminTypeFetchSuccess("AUTHORIZED"));
                    }),
                );
            } else {
                dispatch(adminTypeFetchSuccess("UNAUTHORIZED"));
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                dispatch(adminTypeFetchFailure(error));
                return;
            }
            throw error;
        }
    };
}
