import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SocialType } from "@ferda/rest-api-client";
import useHistory from "@/hooks/useCustomHistory";
import {
    // CircleBackground,
    ContentWrapper,
    ImageWrapper,
    Logo,
    // MainImage,
    SignUpButton,
    SignUpButtonWrapper,
    // SubTitle,
    // TextWrapper,
    // Title,
    Wrapper,
} from "./style";
import ImgLogo from "@/assets/img/img_login.svg";
// import ImgMain1 from "@/assets/img/main_img_1.webp";
import KakaoLogin from "@/components/KakaoLogin";
import { IKakaoLoginSuccess } from "@/components/KakaoLogin/types";
import { checkUserExistFetchRequestThunk, generateUserTokenFetchRequestThunk } from "@/redux/login/thunk";
import { GenerateUserTokenFetchRequestPayload } from "@/redux/login/types";
import { setCheckUserExistFetchState, setUserExist } from "@/redux/login/actions";

export default function SignUp() {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleSuccessLogin = (param: IKakaoLoginSuccess) => {
        // setKakaoToken(param.access_token);

        dispatch(
            checkUserExistFetchRequestThunk({
                socialLoginData: { access_token: param.access_token, social_type: SocialType.KAKAO },
                callback: (userExist) => {
                    if (userExist === false) {
                        history.push(`/login?code=${param.access_token}`);
                        return;
                    }

                    const payload: GenerateUserTokenFetchRequestPayload = {
                        username: "KAKAO",
                        password: param.access_token,
                    };

                    dispatch(generateUserTokenFetchRequestThunk(payload));
                },
            }),
        );
    };

    useEffect(() => {
        return () => {
            dispatch(setCheckUserExistFetchState("READY"));
            dispatch(setUserExist(null));
        };
    }, []);

    return (
        <Wrapper>
            <ImageWrapper>
                {/* <CircleBackground /> */}
                <Logo src={ImgLogo} alt="logo" />
                {/* <MainImage src={ImgMain1} alt="main image" /> */}
            </ImageWrapper>

            <ContentWrapper>
                {/* <TextWrapper>
                    <Title>Welcome to 워크무드</Title>
                    <SubTitle>쉽고 간편하게, 스마트한 음료 구독생활!</SubTitle>
                </TextWrapper> */}
                <SignUpButtonWrapper>
                    {/* {!window.navigator.userAgent.endsWith("iOS") && (
                        <KakaoLogin type="popup" onSuccess={handleSuccessLogin} />
                    )} */}

                    {/* <KakaoLogin type="popup" onSuccess={handleSuccessLogin} /> */}
                    <SignUpButton href="/login" variant="outlined">
                        휴대폰번호로 로그인
                    </SignUpButton>
                </SignUpButtonWrapper>
            </ContentWrapper>
        </Wrapper>
    );
}
