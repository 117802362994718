import { CupType, OrderStatus, TemperatureType } from "@ferda/rest-api-client";

interface OrderStatusProgress {
    canProgress: boolean;
    message: string;
}

export function getOrderStatusProgressMessage(orderStatus: OrderStatus): OrderStatusProgress {
    switch (orderStatus) {
        case OrderStatus.ORDER_CANCELED:
            return { canProgress: false, message: "이미 취소가 되어 상태를 변경할 수 없습니다." };
        case OrderStatus.ORDER_COMPLETED:
            return { canProgress: true, message: "'제조중'으로 상태를 변경하시겠습니까?" };
        case OrderStatus.MAKE_IN_PROGRESS:
            return { canProgress: true, message: "'제조완료'로 상태를 변경하시겠습니까?" };
        case OrderStatus.MAKE_COMPLETED:
            return { canProgress: true, message: "'픽업완료'로 상태를 변경하시겠습니까?" };
        case OrderStatus.PICKUP_COMPLETED:
            return { canProgress: false, message: "이미 픽업이 완료가 되어 상태를 변경할 수 없습니다." };
    }
}

export function getOrderStatusToColor(orderStatus: OrderStatus): string {
    switch (orderStatus) {
        case OrderStatus.ORDER_CANCELED:
            return "#d75959";
        case OrderStatus.ORDER_COMPLETED:
            return "#ffa84b";
        case OrderStatus.MAKE_IN_PROGRESS:
            return "#dda946";
        case OrderStatus.MAKE_COMPLETED:
            return "#d7ce46";
        case OrderStatus.PICKUP_COMPLETED:
            return "#8ed746";
    }
}

export function getTemperatureTypeToColor(temperatureType: TemperatureType): string {
    switch (temperatureType) {
        case TemperatureType.HOT:
            return "#d75959";
        case TemperatureType.ICED:
            return "#59a8d7";
    }
}

export function getCupTypeToColor(cupType: CupType): string {
    switch (cupType) {
        case CupType.DISPOSABLE:
            return "#d75959";
        case CupType.INDIVIDUAL:
            return "#ffa84b";
        case CupType.REUSABLE:
            return "#59a8d7";
    }
}
