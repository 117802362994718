import { PostResponse } from "@ferda/rest-api-client";
import React from "react";
import Link from "@/components/CustomLink";
import { extractTextContentFromHtml } from "@/utils/html";
import {
    HomeBannerDescriptionContainer,
    HomeBannerCardTitleContainer,
    StyledPaper,
    ImageContainer,
    BannerImage,
} from "./style";
import PlaceHolderImage from "@/assets/img/place_holder.png";

interface HomeBannerCardProps {
    post: PostResponse;
}

export default function HomeBannerCard({
    post: { path, id, title, content_preview, large_thumbnail_set, title_hidden },
}: HomeBannerCardProps) {
    return (
        <Link to={path || `/event/${id}`}>
            <StyledPaper>
                <ImageContainer>
                    <BannerImage src={large_thumbnail_set?.original_image_url ?? PlaceHolderImage} />
                    {title && !title_hidden && <HomeBannerCardTitleContainer>{title}</HomeBannerCardTitleContainer>}
                </ImageContainer>
                {content_preview && !title_hidden && (
                    <HomeBannerDescriptionContainer>
                        {extractTextContentFromHtml(content_preview)}
                    </HomeBannerDescriptionContainer>
                )}
            </StyledPaper>
        </Link>
    );
}
