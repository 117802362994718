import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import Divider from "@/components/Divider";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import { getSelectedMenu } from "@/redux/menu/selectors";
import { fetchMenuThunk } from "@/redux/menu/thunk";

const NutritionFactsContainer = styled.div`
    padding-left: 32px;
    padding-top: 24px;
    padding-right: 32px;
    padding-bottom: 32px;

    color: white;
`;

const Title = styled.div`
    margin-bottom: 4px;

    font-size: 18px;
    font-weight: bold;
`;

const Amount = styled.div`
    margin-bottom: 16px;

    color: #0c0c0c;
    font-size: 12px;
`;

const FactContainer = styled.div``;

const FactRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    font-size: 14px;
`;

const FactTitleContainer = styled.div``;

const FactAmountContainer = styled.div`
    color: #e2e2e2;
`;

const StyledDivider = styled(Divider)`
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
`;

export default function NutritionFacts() {
    const dispatch = useDispatch();
    const { menuId: rawMenuId } = useParams<{ menuId: string }>();
    const selectedMenu = useSelector(getSelectedMenu);

    const menuId = useMemo(() => Number(rawMenuId), [rawMenuId]);

    useEffect(() => {
        if (selectedMenu && selectedMenu.id === menuId) {
            return;
        }

        dispatch(fetchMenuThunk(menuId));
    }, [menuId, selectedMenu]);

    return (
        <AppLayout
            title="제품 영양 정보"
            titleColor="#fff"
            disableAppBarBorder
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            {selectedMenu && (
                <NutritionFactsContainer>
                    <Title>{selectedMenu.name}</Title>
                    <Amount>{selectedMenu.size}</Amount>
                    <FactContainer>
                        <StyledDivider />
                        {selectedMenu.nutrition_facts.map(({ name, value }, i) => (
                            <>
                                <FactRowContainer key={i}>
                                    <FactTitleContainer>{name}</FactTitleContainer>
                                    <FactAmountContainer>{value}</FactAmountContainer>
                                </FactRowContainer>
                                <StyledDivider key={`${i}_divider`} />
                            </>
                        ))}
                    </FactContainer>
                </NutritionFactsContainer>
            )}
        </AppLayout>
    );
}
