import { format } from "date-fns";
import { pointLogListFetchRequest, pointLogListFetchSuccess, pointLogListFetchFailure } from "./actions";
import { makeFetchMoreListThunkAction } from "@/utils/redux/thunk";

interface FetchMoreNoticePostListThunkPayload {
    startDate: Date | null;
    endDate: Date | null;
}

export const fetchMorePointLogListThunk = makeFetchMoreListThunkAction(
    pointLogListFetchRequest,
    pointLogListFetchSuccess,
    pointLogListFetchFailure,
    {
        getResponseListFunction: (
            {
                state: {
                    auth: {
                        restApiClient: { userApi },
                    },
                },
                page,
            },
            { startDate, endDate }: FetchMoreNoticePostListThunkPayload,
        ) => {
            let emptyValue;
            return userApi.getPointLogs(
                startDate ? format(startDate, "yyyy-MM-dd") : emptyValue,
                endDate ? format(endDate, "yyyy-MM-dd") : emptyValue,
                page,
                "me",
            );
        },
        pageSelectFunction: ({ pointLog: { pointLogListPage: page, pointLogListTotalPage: totalPage } }) => ({
            page,
            totalPage,
        }),
    },
);
