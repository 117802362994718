import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../types";
import { setAuthType, setRestApiClient, setUser } from "./actions";
import {
    clearOauth2Token,
    getOauth2TokenFromLocalStorage,
    makeRestApiClient,
    saveOauth2TokenToLocalStorage,
} from "./utils";
import { isAxiosError } from "@/utils/axios";
import { replace as historyReplace } from "../customHistory/actions";

type AuthThunkAction = ThunkAction<void, RootState, unknown, AnyAction>;

export function reloadApiClientThunk(): AuthThunkAction {
    return async (dispatch) => {
        const oauth2Token = getOauth2TokenFromLocalStorage();
        const restApiClient = makeRestApiClient(process.env.REACT_APP_REST_API_BASE_URL!, oauth2Token);

        dispatch(setRestApiClient(restApiClient));
    };
}

async function refreshOauth2Token(dispatch: ThunkDispatch<RootState, unknown, AnyAction>) {
    let oauth2Token = getOauth2TokenFromLocalStorage();

    if (!oauth2Token) {
        return;
    }

    const restApiClient = makeRestApiClient(process.env.REACT_APP_REST_API_BASE_URL!);

    try {
        const {
            data: { access_token, access_token_expire_datetime, refresh_token, refresh_token_expire_datetime },
        } = await restApiClient.oauth2Api.refreshToken(oauth2Token?.token, oauth2Token?.refreshToken);

        oauth2Token = {
            token: access_token,
            tokenExpireDate: new Date(access_token_expire_datetime),
            refreshToken: refresh_token,
            refreshTokenExpireDate: new Date(refresh_token_expire_datetime),
        };

        saveOauth2TokenToLocalStorage(oauth2Token);
    } catch (e) {
        if (isAxiosError(e) && e.response?.status) {
            clearOauth2Token();
            dispatch(historyReplace("/"));
        }

        throw e;
    }
}

export function refreshApiClientThunk(): AuthThunkAction {
    return async (dispatch) => {
        await refreshOauth2Token(dispatch);
        dispatch(reloadApiClientThunk());
    };
}

export function loadAuthStateAndApiClientThunk(callback?: () => void): AuthThunkAction {
    return async (dispatch) => {
        let oauth2Token = getOauth2TokenFromLocalStorage();

        if (!oauth2Token) {
            dispatch(setRestApiClient(makeRestApiClient(process.env.REACT_APP_REST_API_BASE_URL!)));
            dispatch(setAuthType("UNAUTHORIZED"));
            return;
        }

        await refreshOauth2Token(dispatch);
        oauth2Token = getOauth2TokenFromLocalStorage();

        const restApiClient = makeRestApiClient(process.env.REACT_APP_REST_API_BASE_URL!, oauth2Token);

        dispatch(setRestApiClient(restApiClient));

        const { data: me } = await restApiClient.userApi.getUser("me");

        if (window.android) {
            window.android.signIn(me.detail?.phone_number);
        }
        if (window.webkit) {
            window.webkit.messageHandlers.signIn.postMessage(me.detail?.phone_number);
        }

        dispatch(setUser(me));
        dispatch(setAuthType("AUTHORIZED"));

        if (callback) {
            callback();
        }
    };
}
