import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { StyledIconButton } from "../IconButton";

export const CounterWrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface CounterButtonProps {
    disabled?: boolean;
}

export const CounterButton = styled(StyledIconButton)<CounterButtonProps>`
    width: 24px;
    height: 24px;
    color: #ff831e;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.4;
            pointer-events: none;
        `}
`;

export const CounterText = styled.span`
    font-size: 20px;
    line-height: 32px;
    color: #ff831e;
    margin: 0 18px;
`;
