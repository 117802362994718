import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import InputBase from "@/components/Input";
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";

export const Wrapper = styled.form`
    background-color: #fff;
    padding-bottom: 68px;
`;

export const Content = styled.div`
    padding: 32px 20px;
`;

export const ContentTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 24px;
`;

export const Divider = styled.div`
    height: 8px;
    background-color: #ff831e;
`;

export const CoffeeMenuItems = styled.div`
    display: flex;
    align-items: stretch;
    padding: 16px 0;
`;

export const CoffeeImage = styled.div`
    min-width: 95px;
    width: 95px;
    height: 95px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 12px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const CoffeeMenuItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CoffeeTitleWrapper = styled.div``;

export const CoffeeTitle = styled.p`
    font-size: 14px;
    color: #e2e2e2;
    margin-bottom: 4px;
`;

export const CoffeeOption = styled.p`
    font-size: 14px;
    color: #aaa;
`;

export const CoffeePrice = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #ff831e;
`;

export const CoffeePriceUnit = styled.span`
    font-size: 16px;
    font-weight: normal;
`;

interface GiftOrderInputProps {
    marginBottom?: number;
}

export const GiftOrderInput = styled(InputBase)<GiftOrderInputProps>`
    width: 100%;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
    margin-right: 0;

    &:nth-last-of-type(1) {
        margin-bottom: ${({ marginBottom }) => marginBottom}px;
    }

    & > input {
        &::placeholder {
            color: #5b5b5b;
        }
    }
`;

export const TargetTooltip = styled.p`
    font-size: 12px;
    color: #8f8f8f;
`;

export const PointInputWrapper = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

export const PointUseButton = styled(Button)`
    min-width: 100px;
    margin-left: 8px;
    font-weight: bold;
`;

export const PointText = styled.p`
    font-size: 14px;
    color: #0c0c0c;
`;

export const PointAmountText = styled.span`
    color: #ff831e;
    font-weight: bold;
`;

export const PriceItemWrapper = styled.div``;

export const PriceItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }
`;

export const PriceItemTitle = styled.p`
    font-size: 14px;
    color: #8f8f8f;
`;

export const PriceItemPrice = styled.p`
    font-size: 14px;
    color: #ff831e;
`;

export const PriceItemDivider = styled.div`
    height: 1px;
    background-color: #8f8f8f;
    margin: 12px 0;
`;

export const TotalPriceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TotalPriceTitle = styled.p`
    font-size: 16px;
    color: #ff831e;
`;

export const TotalPriceText = styled.p`
    font-size: 22px;
    font-weight: bold;
    line-height: 24px;
    color: #ff831e;
`;

export const TotalPriceTextUnit = styled.span`
    font-size: 14px;
    color: #ff831e;
    line-height: 24px;
    font-weight: normal;
`;

export const TermsCheckbox = styled(Checkbox)`
    margin-bottom: 12px;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }
`;

export const CheckAllCheckboxLabel = styled.p`
    color: #0c0c0c;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
`;

export const CheckboxLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const CheckboxLabel = styled.p`
    font-size: 14px;
    color: #0c0c0c;
`;

export const CheckboxLabelLink = styled(Link)`
    font-size: 12px;
    color: #757575;
    text-decoration: none;
`;

export const PointModalText = styled.p`
    font-size: 14px;
    color: #0c0c0c;
    margin: 8px 0 34px 0;
    text-align: center;
`;
