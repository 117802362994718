import React, { useState } from "react";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import { QuestionTabs } from "./style";
import { StyledGiftTab } from "../Gift/style";
import QuestionWrite from "./Write";
import QuestionList from "./List";

export default function Question() {
    const [tab, setTab] = useState<"write" | "list">("write");

    const handleChangeTab = (e: React.SyntheticEvent, value: "write" | "list") => {
        setTab(value);
    };

    const changeTab = (value: "write" | "list") => setTab(value);

    return (
        <AppLayout
            disableAppBarBorder={true}
            title="1:1 문의"
            titleColor="#fff"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            <QuestionTabs value={tab} onChange={handleChangeTab} variant="fullWidth">
                <StyledGiftTab label="문의하기" value="write" />
                <StyledGiftTab label="문의내역" value="list" />
            </QuestionTabs>

            {tab === "write" && <QuestionWrite handleChangeTab={changeTab} />}
            {tab === "list" && <QuestionList />}
        </AppLayout>
    );
}
