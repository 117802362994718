import React from "react";
import styled from "@emotion/styled";
import { Button, message } from "antd";
import { PostResponse } from "@ferda/rest-api-client";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import { Left, Right, Clear } from "@/components/Admin/Float";

const PostContainer = styled.div`
    & figure > img {
        max-width: 100%;
    }
`;

interface Props {}

export default function Page(props: Props) {
    const params = useParams<{ postId: string }>();
    const history = useHistory();

    const { postApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [post, setPost] = React.useState<PostResponse | undefined>();

    React.useEffect(() => {
        setLoading(true);
        postApi
            .getPost(parseInt(params.postId, 10))
            .then((res) => {
                setLoading(false);
                setPost(res.data);
            })
            .catch(() => {
                message.error("게시글을 불러오는데 실패하였습니다.");
                history.goBack();
            });
    }, []);

    return (
        <>
            <PageTitle>게시글 보기</PageTitle>
            <Container>
                <Left>{post?.title}</Left>
                <Right>
                    <Button disabled={loading} onClick={() => history.push(`/admin/posts/edit/${post?.id}`)}>
                        수정
                    </Button>
                </Right>
                <Clear />
            </Container>
            <Container>
                {/* eslint-disable-next-line react/no-danger */}
                {post ? (
                    <PostContainer dangerouslySetInnerHTML={{ __html: post.content }} />
                ) : (
                    <div>내용이 없습니다.</div>
                )}
            </Container>
        </>
    );
}
