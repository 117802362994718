import React from "react";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import { PolicyLinkButton, PolicyWrapper } from "./style";
import ArrowIcon from "@/assets/img/btn-arrow-right.svg";

export default function Policy() {
    return (
        <AppLayout title="이용약관" appBarBackgroundColor="#ff831e" leadingIcon={<BackButton />} titleColor="#fff">
            <PolicyWrapper>
                <PolicyLinkButton backgroundColor="#fff" href="/policy/service">
                    워크무드 이용약관
                    <img src={ArrowIcon} alt="arrow icon" />
                </PolicyLinkButton>
                <PolicyLinkButton backgroundColor="#fff" href="/policy/privacy">
                    개인정보 수집 및 이용동의
                    <img src={ArrowIcon} alt="arrow icon" />
                </PolicyLinkButton>
                <PolicyLinkButton backgroundColor="#fff" href="/policy/location">
                    위치정보 이용약관
                    <img src={ArrowIcon} alt="arrow icon" />
                </PolicyLinkButton>
            </PolicyWrapper>
        </AppLayout>
    );
}
