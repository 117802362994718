import React from "react";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import __html from "@/assets/policy_location.html";
import PolicyContent from "../PolicyContent";

export default function PolicyLocation() {
    return (
        <AppLayout
            title="위치정보 이용약관"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            titleColor="#fff"
            disableAppBarBorder
        >
            <PolicyContent dangerouslySetInnerHTML={{ __html }} />
        </AppLayout>
    );
}
