import { CupType } from "@ferda/rest-api-client";
import React, { useCallback } from "react";
import { CupTypeButtonAspectRatioContainer, StyledCupTypeButton, CupTypeButtonImg, CupTypeButtonText } from "./style";

interface CupTypeButtonProps {
    selectedImgSrc: string;
    unselectedImgSrc: string;
    title: string;
    value: CupType;
    selected?: boolean;
    onChange: (newCupType: CupType) => void;
    disabled?: boolean;
}

export function CupTypeButton({
    selectedImgSrc,
    unselectedImgSrc,
    title,
    value,
    selected,
    onChange,
    disabled,
}: CupTypeButtonProps) {
    const handleChange = useCallback(() => {
        onChange(value);
    }, [onChange, value]);

    return (
        <CupTypeButtonAspectRatioContainer>
            <StyledCupTypeButton disabled={disabled} selected={selected} onClick={handleChange}>
                <CupTypeButtonImg src={selected ? selectedImgSrc : unselectedImgSrc} />
                <CupTypeButtonText>{title}</CupTypeButtonText>
            </StyledCupTypeButton>
        </CupTypeButtonAspectRatioContainer>
    );
}
