import { message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
    getPosLoginAuthType,
    getPosLoginFetchState,
    getPosLoginUser,
    getPosLoginShop,
    getPosGetShopFetchState,
} from "@/redux/pos/selector";
import ShopLoginForm from "./LoginForm";
import { posGetShopRequestThunk, posLoginRequestThunk } from "@/redux/pos/thunk";
import { clearOauth2Token } from "@/redux/auth/utils";
import { setPosGetShopFetchState, setPosLoginFetchState } from "@/redux/pos/actions";

export default function ShopLogin() {
    const history = useHistory();

    const dispatch = useDispatch();

    const authType = useSelector(getPosLoginAuthType);
    const posLoginFetchState = useSelector(getPosLoginFetchState);
    const posGetShopFetchState = useSelector(getPosGetShopFetchState);
    const posLoginUser = useSelector(getPosLoginUser);
    const posLoginShop = useSelector(getPosLoginShop);

    useEffect(() => {
        switch (authType) {
            case "UNLOADED":
                return;
            case "AUTHORIZED": {
                dispatch(posGetShopRequestThunk());
                break;
            }
            case "UNAUTHORIZED": {
                switch (posLoginFetchState) {
                    case "FAILURE": {
                        message.error("계정이 존재하지 않습니다.");
                        return;
                    }
                    case "SUCCESS": {
                        message.error("권한이 없습니다.");
                        return;
                    }
                    default: {
                        message.error("알 수 없는 에러.");
                    }
                }
                break;
            }
        }

        return () => {
            dispatch(setPosGetShopFetchState("READY"));
            dispatch(setPosLoginFetchState("READY"));
        };
    }, [authType]);

    useEffect(() => {
        if (posGetShopFetchState === "SUCCESS") {
            if (!posLoginUser || !posLoginShop || posLoginShop.length < 1) {
                message.error("계정이 존재하지 않습니다.");
                clearOauth2Token();
                return;
            }

            if (posLoginShop.length === 1) {
                history.push(`/pos/${posLoginShop[0].id}/orders`);
                return;
            }

            history.push(`/pos/list`);
            return;
        }

        if (posGetShopFetchState === "FAILURE") {
            message.error("오류가 발생했습니다.");
            clearOauth2Token();
        }
    }, [posGetShopFetchState]);

    return (
        <ShopLoginForm
            onSubmit={({ username, password }) => {
                dispatch(posLoginRequestThunk(username, password));
            }}
        />
    );
}
