import styled from "@emotion/styled";
import { Button, Modal } from "antd";

export const OrderTitle = styled.div`
    font-size: 1.5rem;
`;

export const SearchBarContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const ShopTitle = styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

export const OrderOptionContainer = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const OrderOptionGroupTitle = styled.div`
    font-weight: bold;
`;

export const OrderOptionList = styled.ul`
    flex-grow: 1;
    margin: 0;
    margin-left: 0.5rem;
    padding: 0;
    list-style: none;
`;

export const OrderOptionListItem = styled.li`
    margin-bottom: 0.25rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const PrintButton = styled(Button)`
    margin-top: 8px;
`;

export const LargeModal = styled(Modal)`
    & .ant-modal-content {
        transform: scale(2);
        transform-origin: top;
    }
`;
