import React from "react";
import { loadScript } from "../utils/script";

type Props = {};

class IamportLoader extends React.Component<Props> {
    state = {
        ready: false,
    };

    componentDidMount() {
        this.load();
    }

    async load() {
        await loadScript("jquery", "https://code.jquery.com/jquery-1.12.4.min.js");
        await loadScript("iamport", "https://cdn.iamport.kr/js/iamport.payment-1.2.0.js");
        this.setState({ ready: true });
    }

    render() {
        const { ready } = this.state;
        const { children } = this.props;
        return ready ? children : null;
    }
}

export default IamportLoader;
