import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button as MuiButton, ButtonBase, ButtonBaseProps, ButtonProps } from "@mui/material";
import React from "react";

interface StyledButtonProps extends ButtonProps {
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    hover?: boolean;
}

export const StyledButton = styled(({ backgroundColor, hoverBackgroundColor, hover, ...args }: StyledButtonProps) => (
    <MuiButton {...args} />
))`
    text-transform: none;
    ${({ backgroundColor, hover, hoverBackgroundColor }) =>
        backgroundColor &&
        css`
            & {
                background-color: ${backgroundColor};
            }

            &:hover {
                background-color: ${hover ? hoverBackgroundColor || `inherit` : backgroundColor};
            }
        `}

    &.Mui-disabled {
        background-color: #ff831e;
    }
`;

interface TagButtonProps extends ButtonBaseProps {
    selected?: boolean;
    disabledBold?: boolean;
}

export const TagButton = styled(ButtonBase)<TagButtonProps>`
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;

    font-size: 14px;
    font-weight: ${({ disabledBold }) => (disabledBold ? "normal" : "bold")};

    border-radius: 8px;
    box-shadow: none;

    ${({ selected }) =>
        selected
            ? css`
                  color: #ffffff;

                  background-color: #ff831e;
              `
            : css`
                  color: #0c0c0c;
              `}
`;

export const BottomConfirmButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 10000;

    width: 100%;
    padding-top: 8px;
    padding-left: 20px;
    padding-bottom: 8px;
    padding-right: 20px;

    background-color: #ffffff;
`;

export const ConfirmButton = styled(StyledButton)`
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;

    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
`;
