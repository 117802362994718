import styled from "@emotion/styled";
import Button from "@/components/Button";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    overflow-y: auto;
    background-color: #fff;
`;

export const ImageWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 432px;
    padding-top: 40px;
    overflow-x: hidden;
`;

export const Logo = styled.img`
    margin-bottom: 60px;
    z-index: 1;
    width: 55%;
`;

export const MainImage = styled.img`
    width: 277px;
    z-index: 1;
`;

export const CircleBackground = styled.div`
    position: absolute;
    width: 150%;
    height: 100%;
    background-color: #151515;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 0;
    border-radius: 0 0 50% 50%;
`;

export const ContentWrapper = styled.div`
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 20px 30px 20px;
`;

export const TextWrapper = styled.div`
    text-align: center;
    color: #ff831e;
`;

export const Title = styled.p`
    font-size: 32px;
    letter-spacing: -0.32px;
    font-weight: 900;
    margin: 0 0 8px 0;
`;

export const SubTitle = styled.p`
    font-size: 14px;
    color: #0c0c0c;
    letter-spacing: -0.14px;
`;

export const SignUpButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SignUpButton = styled(Button)`
    margin-top: 12px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ff831e;
    color: #ff831e;
    height: 54px;
    width: 100%;
    border-radius: 100px;

    &:hover {
        border: 1px solid #ff831e;
    }
`;
