import { createSlice } from "@reduxjs/toolkit";
import { GiftResponse, SubscriptionGiftResponse } from "packages/ferda-rest-api-client/dist";
import { makeAsyncReducer, setValueReducerList } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";
import { ListFetchSuccessPayload } from "@/utils/redux/types";
import { GiftLogState } from "./types";

const initialState: GiftLogState = {
    giftLogList: null,
    giftLogListRequestFetchState: "READY",
    giftLogListPage: null,
    giftLogListTotalPage: null,

    giftSubscribeLogList: null,
    giftSubscribeLogListRequestFetchState: "READY",
    giftSubscribeLogListPage: null,
    giftSubscribeLogListTotalPage: null,

    giftLogDetailInfo: null,
    giftLogDetailInfoRequestFetchState: "READY",

    giftSubscribeLogDetailInfo: null,
    giftSubscribeLogDetailInfoRequestFetchState: "READY",

    giftOrderFetchState: "READY",
    giftSubscribeOrderFetchState: "READY",
};

export const GIFT_LOG_STATE_KEY_LIST = getKeyListFromState(initialState);

export const giftLogSlice = createSlice({
    name: "giftLog",
    initialState,
    reducers: {
        reset: () => initialState,
        resetGiftLogState: (state) => {
            state.giftLogList = null;
            state.giftLogListRequestFetchState = "READY";
            state.giftLogListPage = null;
            state.giftLogListTotalPage = null;
        },
        ...makeAsyncReducer<"giftLogList", GiftLogState, void, ListFetchSuccessPayload<GiftResponse>>("giftLogList", {
            useListSuccessReducer: true,
        }),
        ...makeAsyncReducer<
            "giftSubscribeLogList",
            GiftLogState,
            void,
            ListFetchSuccessPayload<SubscriptionGiftResponse>
        >("giftSubscribeLogList", {
            useListSuccessReducer: true,
        }),
        ...makeAsyncReducer<"giftDetailInfoRequest", GiftLogState, void, GiftResponse>("giftDetailInfoRequest", {
            successReducer: (state, { payload }) => {
                state.giftLogDetailInfo = payload;
                state.giftLogDetailInfoRequestFetchState = "SUCCESS";
            },
        }),
        ...makeAsyncReducer<"giftSubscribeDetailInfoRequest", GiftLogState, void, SubscriptionGiftResponse>(
            "giftSubscribeDetailInfoRequest",
            {
                successReducer: (state, { payload }) => {
                    state.giftSubscribeLogDetailInfo = payload;
                    state.giftSubscribeLogDetailInfoRequestFetchState = "SUCCESS";
                },
            },
        ),
        ...makeAsyncReducer<"giftOrder", GiftLogState>("giftOrder"),
        ...makeAsyncReducer<"giftSubscribeOrder", GiftLogState>("giftSubscribeOrder"),
        ...setValueReducerList<
            GiftLogState,
            [
                "giftLogList",
                "giftLogListRequestFetchState",
                "giftLogDetailInfo",
                "giftSubscribeLogDetailInfo",
                "giftLogDetailInfoRequestFetchState",
            ]
        >([
            "giftLogList",
            "giftLogListRequestFetchState",
            "giftLogDetailInfo",
            "giftSubscribeLogDetailInfo",
            "giftLogDetailInfoRequestFetchState",
        ]),
    },
});

export const giftLogReducer = giftLogSlice.reducer;
