import React from "react";
import { TabButtonContainer, TabButton } from "./style";

interface TabProps<TabType extends string> {
    className?: string;
    tabOneName: TabType;
    tabOneDisplayName?: string;
    tabOneBackgroundColor?: string;
    tabTwoName: TabType;
    tabTwoDisplayName?: string;
    tabTwoBackgroundColor?: string;
    textColor?: string;
    selectedTextColor?: string;
    tab: TabType;
    onTabChange: (newTab: TabType) => void;
}

export function Tab<TabType extends string>({
    className,
    tabOneName,
    tabOneDisplayName,
    tabOneBackgroundColor,
    tabTwoName,
    tabTwoDisplayName,
    tabTwoBackgroundColor,
    textColor,
    selectedTextColor,
    tab,
    onTabChange,
}: TabProps<TabType>) {
    return (
        <TabButtonContainer className={className}>
            <TabButton
                backgroundColor={tabOneBackgroundColor}
                selected={tab === tabOneName}
                onClick={() => onTabChange(tabOneName)}
                textColor={textColor}
                selectedTextColor={selectedTextColor}
            >
                {tabOneDisplayName ?? tabOneName}
            </TabButton>
            <TabButton
                backgroundColor={tabTwoBackgroundColor}
                selected={tab === tabTwoName}
                onClick={() => onTabChange(tabTwoName)}
                textColor={textColor}
                selectedTextColor={selectedTextColor}
            >
                {tabTwoDisplayName ?? tabTwoName}
            </TabButton>
        </TabButtonContainer>
    );
}
