import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
    LogTabContentWrapper,
    LogTabMenuItemContent,
    LogTabMenuItemImage,
    LogTabMenuItemTextAmount,
    LogTabMenuItemTextDate,
    LogTabMenuItemTextTitle,
    LogTabMenuItemTextWrapper,
    LogTabMenuItemWrapper,
    TagButtonContianer,
} from "./style";
import { formatISODate } from "@/utils/date";
import BarDateRangePicker from "@/components/BarDateRangePicker";
import { getQueryStringObject } from "@/utils/global";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import { getToday } from "@/utils/time";
import { reset } from "@/redux/giftLog/actions";
import { giftLogListRequestThunk } from "@/redux/giftLog/thunks";
import PlaceholderIcon from "@/assets/img/place_holder.png";
import {
    getGiftLogList,
    getGiftLogListPage,
    getGiftLogListRequestFetchState,
    getGiftLogListTotalPage,
} from "@/redux/giftLog/selectors";
import { TagButton } from "@/components/Button/style";
import { getAuthState } from "@/redux/auth/selectors";

type HeaderTabType = "get_list" | "send_list";

export default function LogMenu() {
    const dispatch = useDispatch();
    const [headerTab, setHeaderTab] = useState<HeaderTabType>("get_list");
    const userInfo = useSelector(getAuthState);
    const startDay = userInfo.user?.created_datetime ? new Date(userInfo.user?.created_datetime) : getToday();
    const [startDate, setStartDate] = React.useState<Date | null>(startDay);
    const [endDate, setEndDate] = React.useState<Date | null>(getToday());
    const history = useHistory();
    const { search } = useLocation();
    const query = useMemo(() => getQueryStringObject(search), [search]);
    const isDateFirstRun = useRef(true);
    const isTabFirstRun = useRef(true);
    const giftLogList = useSelector(getGiftLogList);
    const giftLogListPage = useSelector(getGiftLogListPage);
    const giftLogListTotalPage = useSelector(getGiftLogListTotalPage);
    const giftLogListRequestFetchState = useSelector(getGiftLogListRequestFetchState);

    const handleClickMenuItem = (v: number) => {
        if (headerTab === "get_list") {
            history.push(`/gift/get/${v}`);
        }
        if (headerTab === "send_list") {
            history.push(`/gift/send/${v}`);
        }
    };

    const handleSetDate = (sd: Date | null, ed: Date | null) => {
        setStartDate(sd);
        setEndDate(ed);
    };

    const fetchGiftLog = () => {
        if (!startDate || !endDate) {
            return;
        }

        const minDate = formatISODate(startDate);
        const maxDate = formatISODate(endDate);

        dispatch(
            giftLogListRequestThunk({
                includeSentGifts: headerTab === "send_list",
                includeReceivedGifts: headerTab === "get_list",
                minCreatedDate: minDate,
                maxCreatedDate: maxDate,
            }),
        );
    };

    const handleLoadMore = () => {
        if (!startDate || !endDate) {
            return;
        }

        fetchGiftLog();
    };

    useEffect(() => {
        if (query.headerTab && headerTab !== query.headerTab) {
            setHeaderTab(query.headerTab as HeaderTabType);
        }
    }, [query]);

    useEffect(() => {
        if (!startDate || !endDate) {
            return;
        }
        if (isDateFirstRun.current) {
            isDateFirstRun.current = false;
            return;
        }
        fetchGiftLog();
    }, [startDate, endDate]);

    useEffect(() => {
        if (!startDate || !endDate) {
            return;
        }
        if (isTabFirstRun.current) {
            isTabFirstRun.current = false;
            return;
        }
        dispatch(reset());

        fetchGiftLog();
    }, [headerTab]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <>
            <TagButtonContianer>
                <TagButton onClick={() => setHeaderTab("get_list")} selected={headerTab === "get_list"} disabledBold>
                    받은 선물
                </TagButton>
                <TagButton onClick={() => setHeaderTab("send_list")} selected={headerTab === "send_list"} disabledBold>
                    보낸 선물
                </TagButton>
            </TagButtonContianer>
            <BarDateRangePicker startDate={startDate} endDate={endDate} setDate={handleSetDate} />
            <LogTabContentWrapper>
                <InfiniteScrollList
                    fetchState={giftLogListRequestFetchState}
                    list={giftLogList}
                    page={giftLogListPage}
                    totalPage={giftLogListTotalPage}
                    showNoResultMessage
                    noResultMessage={headerTab === "get_list" ? "받은 선물이 없어요" : "보낸 선물이 없어요"}
                    onLoadMore={handleLoadMore}
                    renderItem={({ id, created_datetime, menu: { name: menuName, uploaded_image_set }, amount }) => (
                        <LogTabMenuItemWrapper onClick={() => handleClickMenuItem(id)}>
                            <LogTabMenuItemContent>
                                <LogTabMenuItemImage>
                                    <img src={uploaded_image_set?.original_image_url || PlaceholderIcon} alt="coffee" />
                                </LogTabMenuItemImage>
                                <LogTabMenuItemTextWrapper>
                                    <LogTabMenuItemTextDate>
                                        {format(new Date(created_datetime), "yyyy.MM.dd hh:mm")}
                                    </LogTabMenuItemTextDate>
                                    <LogTabMenuItemTextTitle>{menuName}</LogTabMenuItemTextTitle>
                                    <LogTabMenuItemTextAmount>수량 {amount}개</LogTabMenuItemTextAmount>
                                </LogTabMenuItemTextWrapper>
                            </LogTabMenuItemContent>
                        </LogTabMenuItemWrapper>
                    )}
                />
            </LogTabContentWrapper>
        </>
    );
}
