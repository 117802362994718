import styled from "@emotion/styled";
import Button from "@/components/Button";

export const PolicyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
`;

export const PolicyLinkButton = styled(Button)`
    width: 100%;
    height: 56px;
    justify-content: space-between;
    color: #ff831e;
    font-size: 16px;
    border-radius: 0;
    border-bottom: 1px solid #d1d1d1;
    box-shadow: none;
`;
