import styled from "@emotion/styled";
import { InputBase } from "@mui/material";
import { css } from "@emotion/react";

interface StyledInputBaseProps {
    error?: boolean;
}

export const StyledInputBase = styled(InputBase)<StyledInputBaseProps>`
    width: 0;
    flex-grow: 1;
    border-radius: 4px;
    margin-bottom: 8px;
    min-height: 48px;
    margin-right: 8px;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }

    color: #0c0c0c;
    border: 1px solid #ff831e;
    background-color: #ffffff;

    input {
        padding: 0 12px;

        &::placeholder {
            color: #5b5b5b;
        }
    }

    ${({ error }) =>
        error &&
        css`
            border: 1px solid #ff831e;
        `}

    &.error {
        border: 1px solid #ff831e;
    }
`;
