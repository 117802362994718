import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MobileDatePicker } from "@mui/lab";
import { FormControlLabel, RadioGroup, TextFieldProps, TextField } from "@mui/material";
import React from "react";

export const GlobalStyle = css`
    button.PrivateDatePickerToolbar-penIcon {
        display: none;
    }

    h4 {
        color: #191919;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
        background-color: #ffffff;
        background-image: unset;
    }

    .MuiButtonBase-root.MuiPickersDay-root {
        background-color: #ffffff;
        color: #191919;
    }

    .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today {
        background-color: #ffffff;
        color: #191919;
        border: 0;
    }

    .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
        color: #0c0c0c;
        border: 1px solid #ff831e;
        background-color: #ffffff;
        border: 0;

        &:hover {
            background-color: #ffffff;
        }

        &:focus {
            background-color: #ffffff;
        }
    }

    .MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root {
        color: #ffffff;
    }
`;

export const DatePickerModalWrapper = styled.div`
    padding-bottom: 32px;
`;
interface DateTextFieldBaseProps {
    marginBottom?: number;
    last?: boolean;
}

export const StyledDatePicker = styled(MobileDatePicker)``;

export const DateTextFieldBase = styled(({ marginBottom, last, ...args }: TextFieldProps & DateTextFieldBaseProps) => (
    <TextField {...args} />
))<DateTextFieldBaseProps>`
    width: 100%;
    margin-bottom: 8px;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
    border: 1px solid #ff831e;
    background-color: #ffffff;
    overflow: hidden;
    border-radius: 16px;

    * {
        transition: none;
    }

    &:hover {
        & .MuiInputLabel-root {
            color: #0c0c0c;
        }
    }

    ${({ last }) =>
        last &&
        css`
            margin-bottom: 0;
        `};

    & .MuiFilledInput-root {
        background-color: inherit !important;
    }

    & .MuiInputBase-root {
        &::after,
        &::before {
            display: none;
        }

        &.Mui-disabled {
            background-color: unset;
        }
    }

    & .MuiInputBase-input {
        text-align: right;
        border: 0;
        padding: 16px 20px;
        font-size: 16px;
        color: #0c0c0c;

        &:disabled {
            color: #8f8f8f;
        }

        &.Mui-disabled {
            -webkit-text-fill-color: unset;
        }
    }

    & .MuiInputLabel-root {
        transform: translate(0, -50%);
        top: 50%;
        left: 20px;
        font-size: 16px;
        color: #0c0c0c;
        transition: none;
        line-height: 1rem;
        opacity: 1;

        &.Mui-focused {
            color: #0c0c0c;
        }

        &.Mui-disabled {
            color: #0c0c0c;
        }
    }
`;

export const DatePickerModalTooltip = styled.p`
    color: #0c0c0c;
    font-size: 12px;
    text-align: center;
    margin: 0;
    margin-top: 12px;
`;

export const DatePickerRadioGroup = styled(RadioGroup)`
    padding: 0;
    justify-content: space-between;
`;

export const DatePickerRadioButton = styled(FormControlLabel)`
    margin: 0;

    & .MuiRadio-colorPrimary {
        color: #ff831e !important;
    }

    & .Mui-checked {
        color: #ff831e !important;
    }

    & .MuiFormControlLabel-label {
        font-size: 14px;
        color: #0a0a0a;
    }
`;
