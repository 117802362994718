import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./types";
import { makeRestApiClient } from "./utils";
import { setValueReducerList } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: AuthState = {
    type: "UNLOADED",
    user: null,
    restApiClient: makeRestApiClient(process.env.REACT_APP_REST_API_BASE_URL!),
};

export const AUTH_STATE_KEY_LIST = getKeyListFromState(initialState);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        ...setValueReducerList<AuthState, ["type", "user", "restApiClient"]>(["type", "user", "restApiClient"]),
    },
});

export const authReducer = authSlice.reducer;
