import { OptionsObject } from "notistack";
import { snackbarSlice } from "./slice";

const actions = snackbarSlice.actions;

export const { reset: resetSnackbarState, openSnackbar, removeSnackbar } = actions;

export function openTextSnackbar(message: string, variant?: OptionsObject["variant"]) {
    return openSnackbar({
        message,
        options: {
            variant,
        },
    });
}

export function openSuccessSnackbar(message: string) {
    return openTextSnackbar(message, "success");
}

export function openErrorSnackbar(message: string) {
    return openTextSnackbar(message, "error");
}
