import React from "react";
import styled from "@emotion/styled";

import { Tabs } from "antd";

const { TabPane } = Tabs;

const Container = styled.div`
    display: flex;
    align-items: end;
    margin-bottom: 1rem;
`;

const Left = styled.div`
    flex: 1;
`;

const Right = styled.div``;

interface Tab {
    title: string;
    key: string;
}

interface Props {
    defaultActiveKey: string;
    onChange: (key: string) => void;
    right?: React.ReactNode;
    tabs: Tab[];
}

export default function Component(props: Props) {
    return (
        <Container>
            <Left>
                <Tabs defaultActiveKey={props.defaultActiveKey} onChange={(key) => props.onChange(key)}>
                    {props.tabs.map((tab) => (
                        <TabPane tab={tab.title} key={tab.key} />
                    ))}
                </Tabs>
            </Left>
            <Right>{props.right}</Right>
        </Container>
    );
}
