import axios from "axios";
import { CreateQuestionRequest } from "@ferda/rest-api-client";
import { openErrorSnackbar } from "../snackbar/actions";
import {
    questionWriteFetchRequest,
    questionWriteFetchSuccess,
    questionWriteFetchFailure,
    questionListFetchRequest,
    questionListFetchFailure,
    questionListFetchSuccess,
    questionDeleteFetchRequest,
    questionDeleteFetchFailure,
    questionDeleteFetchSuccess,
} from "./actions";
import { QuestionWriteRequestPayload } from "./types";
import { GenericThunkAction, makeFetchMoreListThunkAction } from "@/utils/redux/thunk";

let emptyValue: undefined;

export function questionWriteRequestThunk({
    title,
    content,
    question_type,
    question_category,
    use_email_notification,
    use_push_notification,
    image,
    shop_id,
}: QuestionWriteRequestPayload): GenericThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { serviceApi, commonApi },
            },
        } = getState();

        dispatch(questionWriteFetchRequest());

        let imageId;

        if (image) {
            try {
                const res = await commonApi.uploadImage(image);

                imageId = res.data.id;
            } catch (e: unknown) {
                if (axios.isAxiosError(e)) {
                    dispatch(questionWriteFetchFailure(e));
                    if (e.response) {
                        dispatch(openErrorSnackbar("이미지 업로드 중 오류가 발생했습니다."));
                    }
                }
                throw e;
            }
        }

        try {
            const payload: CreateQuestionRequest = {
                title,
                content,
                question_type,
                question_category,
                use_email_notification,
                use_push_notification,
                shop_id,
            };

            if (imageId) {
                payload.uploaded_image_set_id = imageId;
            }
            await serviceApi.createQuestion(payload);
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                dispatch(questionWriteFetchFailure(e));
                if (e.response) {
                    dispatch(openErrorSnackbar(e.response.data.detail));
                }
            }
            throw e;
        }

        dispatch(questionWriteFetchSuccess());
    };
}

export function questionDeleteRequestThunk(id: number): GenericThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { serviceApi },
            },
        } = getState();

        dispatch(questionDeleteFetchRequest());

        try {
            await serviceApi.deleteQuestion(id);
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                dispatch(questionDeleteFetchFailure(e));
                if (e.response) {
                    dispatch(openErrorSnackbar(e.response.data.detail));
                }
            }
            throw e;
        }

        dispatch(questionDeleteFetchSuccess());
    };
}

export const questionListRequestThunk = makeFetchMoreListThunkAction(
    questionListFetchRequest,
    questionListFetchSuccess,
    questionListFetchFailure,
    {
        getResponseListFunction: ({
            state: {
                auth: {
                    restApiClient: { serviceApi },
                },
            },
            page,
        }) => serviceApi.getQuestions(emptyValue, page, "me"),
        pageSelectFunction: ({ question: { questionListPage: page, questionListTotalPage: totalPage } }) => ({
            page,
            totalPage,
        }),
    },
);
