import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";
import { css } from "@emotion/react";
import { OrderStatus } from "@ferda/rest-api-client";

export const HistoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
`;

export const HistoryTopWrapper = styled.div`
    padding: 54px 20px;
`;

export const HistoryTopTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

export const HistoryTopTitleImage = styled.img`
    margin-right: 8px;
`;

export const HistoryTopTitleText = styled.p`
    font-size: 24px;
    color: #0a0a0a;
    font-weight: bold;
    margin: 0;
`;

export const HistoryTopSubtitle = styled.p`
    color: #0a0a0a;
    font-size: 16px;
`;

export const HistoryMenuWrapper = styled.div`
    flex-grow: 1;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
`;

export const HistoryMenuItemWrapper = styled(ButtonBase)`
    width: 100%;
    padding: 0 20px;
    text-align: left;
`;

export const HistoryMenuItemContent = styled.div`
    width: 100%;
    display: flex;
    border-bottom: 1px solid #d1d1d1;
    padding: 32px 0;
`;

export const HistoryMenuItemImage = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const HistoryMenuItemTextWrapper = styled.div`
    flex-grow: 1;
`;

export const HistoryMenuItemTopWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
`;

export const HistoryMenuItemBottomWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
        margin: 0;
    }
`;

interface HistoryMenuItemStatusProps {
    status: OrderStatus;
}

function getHistoryMenuItemStatusColor(status: OrderStatus): { color: string; backgroundColor: string } {
    switch (status) {
        case OrderStatus.ORDER_COMPLETED: {
            return {
                color: "#ff5991",
                backgroundColor: "rgba(255, 89, 145, 0.2)",
            };
        }
        case OrderStatus.MAKE_IN_PROGRESS: {
            return {
                color: "#ff831e",
                backgroundColor: "rgba(255, 182, 0, 0.2)",
            };
        }
        case OrderStatus.MAKE_COMPLETED: {
            return {
                color: "#ffffff",
                backgroundColor: "rgba(170, 170, 170, 0.15)",
            };
        }
        case OrderStatus.ORDER_CANCELED: {
            return {
                color: "#ff5991",
                backgroundColor: "rgba(255, 89, 145, 0.2)",
            };
        }
        case OrderStatus.PICKUP_COMPLETED: {
            return {
                color: "#fff",
                backgroundColor: "rgba(255, 183, 0, 0.6)",
            };
        }
        default: {
            return {
                color: "#ff5991",
                backgroundColor: "rgba(255, 89, 145, 0.2)",
            };
        }
    }
}

export const HistoryMenuItemStatus = styled.span<HistoryMenuItemStatusProps>`
    font-size: 12px;
    line-height: 18px;
    padding: 0 4px;
    border-radius: 4px;

    ${({ status }) => css`
        color: ${getHistoryMenuItemStatusColor(status).color};
        background-color: ${getHistoryMenuItemStatusColor(status).backgroundColor};
    `}
`;

export const HistoryMenuItemTextDate = styled.p`
    font-size: 12px;
    color: #5b5b5b;
`;

export const HistoryMenuItemTextTitle = styled.p`
    font-size: 16px;
    color: #ff831e;
    margin: 0 0 2px 0;
`;

export const HistoryMenuItemTextShop = styled.p`
    font-size: 14px;
    color: #8f8f8f;
    margin: 0;
`;
