import { Backdrop, Fade, Modal, ModalProps } from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ShopResponse } from "@ferda/rest-api-client";
import {
    ModalAdornmentResetButton,
    ModalAdornmentSearch,
    ModalAdornmentWrapper,
    ModalCloseButton,
    ModalContentNoResultImage,
    ModalContentNoResultText,
    ModalContentNoResultWrapper,
    ModalContentResultContent,
    ModalContentResultImage,
    ModalContentResultItem,
    ModalContentResultItemSubtitle,
    ModalContentResultItemTitle,
    ModalContentResultTooltip,
    ModalContentWrapper,
    ModalInput,
    ModalTitle,
    ModalWrapper,
} from "./style";
import CloseIcon from "@/assets/img/ic_btn_close.svg";
import SearchIcon from "@/assets/img/btn_search_go.svg";
import ResetIcon from "@/assets/img/btn_close_round_small.svg";
import NoResultIcon from "@/assets/img/img_error.svg";
import { useDebounce } from "@/hooks/useDebounce";
import { getShopList } from "@/redux/shop/selectors";
import { fetchMoreShopListThunk } from "@/redux/shop/thunk";
import { resetShopState } from "@/redux/shop/actions";
import PlaceholderIcon from "@/assets/img/place_holder.png";

interface StoreFindModal extends Omit<ModalProps, "children"> {
    open: boolean;
    handleClose: () => void;
    handleClickStoreItem: (id: number, name: string) => void;
    selectedMenuId?: number;
    disableStoreClose?: boolean;
}

interface InitialValues {
    keyword: string;
}

export default function StoreFindModal({
    open,
    handleClose,
    handleClickStoreItem,
    selectedMenuId,
    disableStoreClose,
    ...args
}: StoreFindModal) {
    const dispatch = useDispatch();
    const shopList = useSelector(getShopList);
    const { getFieldProps, setFieldValue, values } = useFormik<InitialValues>({
        initialValues: {
            keyword: "",
        },
        onSubmit: () => {
            //
        },
    });

    const keyword: string = useDebounce<string>(values.keyword, 300);
    const handleResetButton = () => setFieldValue("keyword", "");
    const handleClickSearchResult = (value: ShopResponse) => {
        if (!disableStoreClose && (!value.opened || !value.menu_order_available)) {
            return;
        }

        setFieldValue("keyword", "");
        handleClickStoreItem(value.id, value.name);
    };

    const getStoreTooltipText = ({ menu_order_available, opened, temporary_business_closure }: ShopResponse) => {
        if (temporary_business_closure) {
            return "휴업";
        }

        if (!menu_order_available) {
            return "주문불가";
        }
        if (!opened) {
            return "영업종료";
        }
    };

    useEffect(() => {
        dispatch(resetShopState());
        // if (!keyword) {
        //     return;
        // }

        dispatch(
            fetchMoreShopListThunk({
                selectedMenuId,
                keyword,
            }),
        );
    }, [keyword]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            tabIndex={0}
            {...args}
        >
            <Fade in={open}>
                <ModalWrapper>
                    <ModalCloseButton onClick={handleClose}>
                        <img src={CloseIcon} alt="close icon" />
                    </ModalCloseButton>
                    <ModalTitle>매장 찾기</ModalTitle>
                    <ModalInput
                        endAdornment={
                            <ModalAdornmentWrapper>
                                {values.keyword && (
                                    <ModalAdornmentResetButton
                                        onClick={handleResetButton}
                                        src={ResetIcon}
                                        alt="reset"
                                    />
                                )}
                                <ModalAdornmentSearch src={SearchIcon} alt="search" />
                            </ModalAdornmentWrapper>
                        }
                        placeholder="매장명 또는 주소를 입력해보세요"
                        {...getFieldProps("keyword")}
                    />

                    <ModalContentWrapper>
                        {shopList &&
                            (shopList.length > 0 ? (
                                shopList.map((v) => (
                                    <ModalContentResultItem
                                        key={`item-${v.id}`}
                                        onClick={() => handleClickSearchResult(v)}
                                    >
                                        <ModalContentResultImage>
                                            <img
                                                src={v.uploaded_image_sets[0]?.original_image_url || PlaceholderIcon}
                                                alt="store"
                                            />
                                            {!disableStoreClose &&
                                                (v.temporary_business_closure ||
                                                    !v.menu_order_available ||
                                                    !v.opened) && (
                                                    <ModalContentResultTooltip>
                                                        {getStoreTooltipText(v)}
                                                    </ModalContentResultTooltip>
                                                )}
                                        </ModalContentResultImage>
                                        <ModalContentResultContent>
                                            <ModalContentResultItemTitle>{v.name}</ModalContentResultItemTitle>
                                            <ModalContentResultItemSubtitle>{v.address}</ModalContentResultItemSubtitle>
                                            {v.address_detail && (
                                                <ModalContentResultItemSubtitle>
                                                    {v.address_detail}
                                                </ModalContentResultItemSubtitle>
                                            )}
                                        </ModalContentResultContent>
                                    </ModalContentResultItem>
                                ))
                            ) : (
                                <ModalContentNoResultWrapper>
                                    <ModalContentNoResultImage src={NoResultIcon} alt="no result" />
                                    <ModalContentNoResultText>검색 결과가 없어요</ModalContentNoResultText>
                                </ModalContentNoResultWrapper>
                            ))}
                    </ModalContentWrapper>
                </ModalWrapper>
            </Fade>
        </Modal>
    );
}
