import React, { useEffect } from "react";

export function AppPage() {
    useEffect(() => {
        if (navigator.userAgent.match(/Android/i) !== null) {
            window.location.replace("https://play.google.com/store/apps/details?id=kr.co.zzzcoffee");
            return;
        }
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) {
            window.location.replace("https://apps.apple.com/us/app/zzz-coffee/id1602171356");
        }
    }, []);

    return <div />;
}
