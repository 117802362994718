import { makeSubStateSelectorMap } from "@/utils/redux/selector";
import { HISTORY_STATE_KEY_LIST } from "./slice";

export const {
    getOrderHistoryList,
    getOrderHistoryListPage,
    getOrderHistoryListTotalPage,
    getOrderHistoryListRequestFetchState,
    getOrderHistoryDetailInfo,
    getOrderHistoryDetailRequestFetchState,
} = makeSubStateSelectorMap("history", HISTORY_STATE_KEY_LIST);
