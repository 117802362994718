import { questionSlice } from "./slice";

const actions = questionSlice.actions;

export const {
    questionWriteFetchRequest,
    questionWriteFetchSuccess,
    questionWriteFetchFailure,
    questionDeleteFetchRequest,
    questionDeleteFetchSuccess,
    questionDeleteFetchFailure,
    questionListFetchRequest,
    questionListFetchSuccess,
    questionListFetchFailure,
    setQuestionWriteFetchState,
    setQuestionList,
    reset,
} = actions;
