import { createSlice } from "@reduxjs/toolkit";
import { PostResponse } from "@ferda/rest-api-client";
import { PostListFetchSuccessPayload, PostState } from "./types";
import { makeAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: PostState = {
    noticePost: null,
    noticePostFetchState: "READY",
    noticePostList: null,
    noticePostListFetchState: "READY",
    noticePostListPage: null,
    noticePostListTotalPage: null,

    eventContentPost: null,
    eventContentPostFetchState: "READY",
    eventContentPostList: null,
    eventContentPostListFetchState: "READY",
    eventContentPostListPage: null,
    eventContentPostListTotalPage: null,

    unexpiredEventContentPostList: null,
    unexpiredEventContentPostListFetchState: "READY",
    unexpiredEventContentPostListPage: null,
    unexpiredEventContentPostListTotalPage: null,
};

export const POST_STATE_KEY_LIST = getKeyListFromState(initialState);

export const postSlice = createSlice({
    name: "post",
    initialState,
    reducers: {
        reset: () => initialState,
        resetNoticePostState: (state) => {
            state.noticePost = null;
            state.noticePostFetchState = "READY";
        },
        ...makeAsyncReducer<"noticePost", PostState, void, PostResponse>("noticePost"),
        ...makeAsyncReducer<"noticePostList", PostState, void, PostListFetchSuccessPayload>("noticePostList", {
            useListSuccessReducer: true,
        }),
        resetEventContentPostState: (state) => {
            state.eventContentPost = null;
            state.eventContentPostFetchState = "READY";
        },
        ...makeAsyncReducer<"eventContentPost", PostState, void, PostResponse>("eventContentPost"),
        ...makeAsyncReducer<"eventContentPostList", PostState, void, PostListFetchSuccessPayload>(
            "eventContentPostList",
            {
                useListSuccessReducer: true,
            },
        ),
        ...makeAsyncReducer<"unexpiredEventContentPostList", PostState, void, PostListFetchSuccessPayload>(
            "unexpiredEventContentPostList",
            {
                useListSuccessReducer: true,
            },
        ),
    },
});

export const postReducer = postSlice.reducer;
