import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import Link from "@/components/CustomLink";

const StyledLink = styled(Link)`
    color: #848484;
    :hover {
        color: #848484;
    }
`;

interface FooterProps {
    noPadding?: boolean;
}

const FooterContainer = styled(({ noPadding, ...rest }: FooterProps) => <div {...rest} />)`
    width: 100%;
    padding-top: 16px;
    ${({ noPadding }) =>
        !noPadding &&
        css`
            padding-left: 16px;
            padding-right: 16px;
        `}
    color: #848484;
    font-size: 12px;
`;

const Row = styled.div``;

export default function Footer({ noPadding }: FooterProps) {
    return (
        <FooterContainer noPadding={noPadding}>
            <Row>주식회사 나루데이타 | 대표 유영준 | 사업자번호: 229-81-31253 | 대표번호: 02-3409-6111</Row>
            <Row>통신판매업번호: 2022-서울성동-00119 | 민원 담당자: 김다미 | 연락처: 010-2326-5620</Row>
            <Row>서울특별시 성동구 성수일로4길 25 (성수동2가, 서울숲코오롱디지털타워 1905호)</Row>
            <Row>
                주식회사 나루데이타는 모든 거래에 대한 책임과 배송, 교환, 환불, 민원등의 처리는 주식회사 나루데이타에서
                진행합니다
            </Row>
            <Row>
                <StyledLink to="/policy">이용약관</StyledLink> |{" "}
                <StyledLink to="/policy/privacy">개인정보제공방침</StyledLink>
            </Row>
        </FooterContainer>
    );
}
