import styled from "@emotion/styled";
import { css } from "@emotion/react";
import FindStoreInput from "@/components/FindStoreInput";

interface WrapperProps {
    used: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
    padding: 20px 0 0 0;

    padding-bottom: ${({ used }) => (used ? "0" : "68px")};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const TopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

export const TopImageWrapper = styled.div`
    width: 60px;
    height: 60px;
    margin-bottom: 4px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const TopNameText = styled.p`
    font-size: 20px;
    color: #ff831e;
`;

export const CoffeeGiftWrapper = styled.div`
    padding: 20px 0;
    border-radius: 12px 12px 0 0;
    display: flex;
    position: relative;
`;

export const CoffeeGiftBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
`;

export const CoffeeGiftImageWrapper = styled.div`
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    padding: 0 20px;
    z-index: 100;
    position: relative;
`;

interface CoffeeGiftImageProps {
    used?: boolean;
}

export const CoffeeGiftImage = styled.img<CoffeeGiftImageProps>`
    width: 100%;
    height: 100%;

    ${({ used }) =>
        used &&
        css`
            filter: grayscale(100%);
        `}
`;

export const CoffeeGiftStampImage = styled.img`
    position: absolute;
    right: 12px;
    top: 12px;
    width: 90px;
    height: 90px;
    z-index: 100;
`;

export const GiftInfoWrapper = styled.div`
    background-color: #ff831e;
    padding: 24px 20px;
    flex-grow: 1;
`;

export const GiftInfoTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    color: #ff831e;
    text-align: center;
    margin-bottom: 4px;
`;

export const GiftInfoSubtitle = styled.p`
    font-size: 16px;
    color: #aaa;
    text-align: center;
    margin-bottom: 20px;
`;

export const GiftOrderInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 20px;
`;

export const GiftOrderInfoTitle = styled.p`
    font-size: 14px;
    color: #8f8f8f;
    margin-right: 8px;
`;

export const GiftOrderInfoTextDivider = styled.div`
    width: 1px;
    height: 12px;
    background-color: #2d2d2d;
    margin: 0 8px;
`;

export const GiftOrderInfoStatusText = styled.p`
    font-size: 14px;
    color: #ff831e;
`;

export const GiftOrderInfoNumber = styled.p`
    font-size: 14px;
    color: #e2e2e2;
`;

export const GiftInfoDivider = styled.div`
    height: 1px;
    background-color: #2d2d2d;
`;

export const GiftDateWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 12px;
`;

export const GiftDateTitle = styled.p`
    font-size: 14px;
    color: #aaa;
`;

export const GiftDateText = styled.p`
    font-size: 14px;
    color: #ff831e;
`;

export const StyledFindStoreInput = styled(FindStoreInput)`
    margin-top: 20px;
`;

export const AlertModalText = styled.p`
    font-size: 14px;
    color: #0c0c0c;
    text-align: center;
    margin: 8px 0 34px 0;
`;

export const CompleteModalHighlight = styled.span`
    color: #ff831e;
    text-decoration: underline;
`;

export const OptionButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: 20px;

    text-align: left;

    background-color: #ff831e;

    border-radius: 8px;

    border: 1px solid transparent;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;

    font-size: 16px;
    color: white;
`;

export const Title = styled.div``;

export const SubTitle = styled.span`
    color: #ff831e;
`;

export const Price = styled.div`
    font-weight: bold;
`;

export const AvailableMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 12px;
    padding: 14px;

    border: 1px solid rgba(255, 255, 255, 0.1);

    border-radius: 8px;
`;

export const AvailableMenuTitleContainer = styled.div`
    padding-left: 8px;
    padding-top: 2px;
    padding-right: 8px;
    padding-bottom: 2px;

    color: #e2e2e2;
    font-size: 12px;

    background-color: rgba(255, 255, 255, 0.1);

    border-radius: 4px;
`;

export const AvailableMenuContentContainer = styled.div`
    margin-top: 8px;

    color: #0c0c0c;
    font-size: 14px;
`;

export const CheckboxIconImg = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
`;

export const DescriptionContainer = styled.div`
    margin-top: 12px;

    color: #aaa;
    font-size: 14px;

    > div {
        margin-bottom: 8px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
`;

export const DescriptionRow = styled.div``;
