import styled from "@emotion/styled";
import React from "react";
import ErrorIconImage from "@/assets/img/img_error.svg";

const ErrorIconImg = styled.img`
    width: 63px;
    margin: 0 auto;
`;

const NoResultContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
`;

const NoResultMessageContainer = styled.div`
    margin-top: 1rem;

    color: #0c0c0c;
    font-size: 14px;
`;

interface NoResultProps {
    message: string;
}

export function NoResult({ message }: NoResultProps) {
    return (
        <NoResultContainer>
            <ErrorIconImg src={ErrorIconImage} />
            <NoResultMessageContainer>{message}</NoResultMessageContainer>
        </NoResultContainer>
    );
}
