import { createSlice, PayloadAction, Draft } from "@reduxjs/toolkit";
import { SnackbarKey } from "notistack";
import { OpenSnackbarPayload, SnackbarState } from "./types";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: SnackbarState = {
    snackbarList: [],
};

export const SNACKBAR_STATE_KEY_LIST = getKeyListFromState(initialState);

export const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        reset: () => initialState,
        openSnackbar: (state, { payload }: PayloadAction<OpenSnackbarPayload>) => {
            state.snackbarList = [
                ...state.snackbarList,
                { ...(payload as Draft<OpenSnackbarPayload>), key: payload.key ?? `${Date.now()}.${Math.random()}` },
            ];
        },
        removeSnackbar: (state, { payload }: PayloadAction<SnackbarKey | undefined>) => {
            if (typeof payload === "undefined") {
                state.snackbarList = [];
                return;
            }
            state.snackbarList = state.snackbarList.filter(({ key }) => key !== payload);
        },
    },
});

export const snackbarReducer = snackbarSlice.reducer;
