import { ORDER_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
    getOrderFetchState,
    getGiftOrderFetchState,
    getGiftSubsFetchState,
    getOrderStash,
    getOrderType,
    getSuccessOrderUidList,
} = makeSubStateSelectorMap("order", ORDER_STATE_KEY_LIST);
