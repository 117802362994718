import { MENU_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
    getMenuListFetchState,
    getMenuList,
    getMenuListPage,
    getMenuListTotalPage,
    getSelectedMenu,
    getSelectedMenuFetchState,
    getMenuGroupList,
    getMenuGroupListFetchState,
    getMenuGroupListPage,
    getMenuGroupListTotalPage,
    getRecommendedMenuList,
    getRecommendedMenuListFetchState,
} = makeSubStateSelectorMap("menu", MENU_STATE_KEY_LIST);
