import styled from "@emotion/styled";
import Button from "../Button";

export interface CoffeeMenuItemWrapperProps {
    itemWidth: number;
}

export const CoffeeMenuItemWrapper = styled(Button)<CoffeeMenuItemWrapperProps>`
    width: ${({ itemWidth }) => itemWidth}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 0;
`;

export interface CoffeeMenuImageProps {
    imageHeight?: number;
}

export const CoffeeMenuImageWrapper = styled.div<CoffeeMenuImageProps>`
    width: 100%;
    height: ${({ imageHeight }) => (imageHeight ? `${imageHeight}px` : "auto")};
    margin-bottom: 14px;
    border-radius: 8px;
    overflow: hidden;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const CoffeeMenuTitle = styled.p`
    font-size: 14px;
    color: #e2e2e2;
    margin: 0 0 6px 0;
`;

export const CoffeeMenuPriceText = styled.p`
    font-size: 16px;
    color: #ff831e;
    margin: 0;
`;

export const CoffeeMenuPriceNumber = styled.span`
    font-size: 18px;
    color: #ff831e;
    font-weight: bold;
    margin-right: 2px;
`;
