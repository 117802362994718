import { RootState } from "@/redux/types";
import { capitalize } from "@/utils/text";

type SubStateSelectorMap<
    SubStateKey extends keyof RootState,
    SubState extends RootState[SubStateKey] = RootState[SubStateKey],
> = {
    [Key in keyof SubState as `get${Capitalize<string & Key>}`]: (rootState: RootState) => SubState[Key];
};

export function makeSubStateSelectorMap<SubStateKey extends keyof RootState, SubState extends RootState[SubStateKey]>(
    subStateKey: SubStateKey,
    subStateKeyList: Array<keyof SubState>,
) {
    const result = subStateKeyList.reduce((prev, key) => {
        return {
            ...prev,
            [`get${capitalize(key.toString())}`]: ({ [subStateKey]: { [key]: state } }: RootState) => state,
        };
    }, {} as SubStateSelectorMap<SubStateKey>);

    return result;
}
