import { CreateOrderRequest, CupType, MenuOptionGroup, TemperatureType } from "@ferda/rest-api-client";
import axios from "axios";
import {
    orderFetchRequest,
    orderFetchSuccess,
    orderFetchFailure,
    giftOrderFetchRequest,
    giftOrderFetchSuccess,
    giftOrderFetchFailure,
    giftSubsFetchRequest,
    giftSubsFetchSuccess,
    giftSubsFetchFailure,
    addSuccessOrderUid,
} from "./actions";
import { makeFetchThunkAction } from "@/utils/redux/thunk";
import { requestPayment } from "../payment/functions";
import { openErrorSnackbar } from "../snackbar/actions";
import { getMenuOptionGroupTotalPrice } from "@/utils/menuOption";
import { reset, push } from "../customHistory/actions";

interface FetchOrderThunkProps {
    shopId: number;
    menuId: number;
    temperatureType?: TemperatureType;
    cupType: CupType;
    optionGroupList: MenuOptionGroup[];
    singleRequest?: {
        point: number;
        price: number;
        amount: number;
        productName: string;
    };
}

function getFinalPrice({
    price,
    amount,
    optionGroupList,
    point,
}: {
    price: number;
    amount: number;
    optionGroupList: MenuOptionGroup[];
    point: number;
}) {
    return price * amount + getMenuOptionGroupTotalPrice(optionGroupList) - point;
}

export const fetchOrderThunk = makeFetchThunkAction(orderFetchRequest, orderFetchSuccess, orderFetchFailure, {
    getResponseFunction: async (
        {
            state: {
                auth: {
                    restApiClient: { shopApi },
                    user,
                },
            },
            dispatch,
        },
        { singleRequest, shopId, menuId, temperatureType, cupType, optionGroupList }: FetchOrderThunkProps,
    ) => {
        let apiPrice;
        let res;

        if (singleRequest) {
            const { price, amount, point, productName } = singleRequest;

            const finalPrice = getFinalPrice({ point, price, amount, optionGroupList });

            apiPrice = finalPrice;

            if (price * amount - point > 0) {
                res = await requestPayment({
                    user: user!,
                    price: finalPrice,
                    productName,
                    type: "GIFT",
                    getCallbackUrl: (merchantUid) =>
                        window.location.href.split("//")[0] +
                        "//" +
                        window.location.host +
                        "/order-callback?params=" +
                        encodeURIComponent(
                            JSON.stringify({
                                type: "GIFT",
                                merchant_uid: merchantUid,
                                shop_id: shopId,
                                menu_id: menuId,
                                temperature_type: temperatureType,
                                cup_type: cupType,
                                option_groups: optionGroupList,
                                price: finalPrice,
                                use_point: singleRequest?.point,
                                amount: singleRequest?.amount,
                            }),
                        ),
                });

                if (!res || !res.imp_uid) {
                    throw new Error();
                }
            }
        }

        try {
            return await shopApi.createOrder({
                merchant_uid: res?.merchant_uid,
                shop_id: shopId,
                menu_id: menuId,
                temperature_type: temperatureType,
                cup_type: cupType,
                option_groups: optionGroupList,
                price: apiPrice,
                use_point: singleRequest?.point,
                amount: singleRequest?.amount,
            });
        } catch (e) {
            if (axios.isAxiosError(e)) {
                dispatch(openErrorSnackbar(e.response?.data.detail));
            }
            throw e;
        }
    },
});

export const fetchOrderCallbackThunk = makeFetchThunkAction(orderFetchRequest, orderFetchSuccess, orderFetchFailure, {
    getResponseFunction: async (
        {
            state: {
                auth: {
                    restApiClient: { shopApi },
                    user,
                },
            },
            dispatch,
        },
        params: CreateOrderRequest,
    ) => {
        try {
            const result = await shopApi.createOrder(params);

            if (typeof params.merchant_uid !== "undefined") {
                dispatch(addSuccessOrderUid(params.merchant_uid));
            }

            dispatch(reset());
            dispatch(push("/"));
            dispatch(push("/history"));

            return result;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                dispatch(openErrorSnackbar(e.response?.data.detail));
            }
            throw e;
        }
    },
});

interface FetchGiftThunkProps {
    amount: number;
    menuId: number;
    temperatureType?: TemperatureType;
    optionGroupList: MenuOptionGroup[];
    point: number;
    price: number;
    name: string;
    phoneNumber: string;
    productName: string;
}

export const fetchGiftOrderThunk = makeFetchThunkAction(
    giftOrderFetchRequest,
    giftOrderFetchSuccess,
    giftOrderFetchFailure,
    {
        getResponseFunction: async (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                        user,
                    },
                },
            },
            {
                menuId,
                temperatureType,
                name,
                phoneNumber,
                optionGroupList,
                amount,
                price,
                point,
                productName,
            }: FetchGiftThunkProps,
        ) => {
            let res;

            const finalPrice = getFinalPrice({ point, price, amount, optionGroupList });

            if (finalPrice > 0) {
                res = await requestPayment({
                    user: user!,
                    price: finalPrice,
                    productName,
                    type: "GIFT",
                });

                if (!res) {
                    throw new Error();
                }
            }

            return shopApi.createGift({
                merchant_uid: res?.merchant_uid,
                menu_id: menuId,
                temperature_type: temperatureType,
                option_groups: optionGroupList,
                price: finalPrice,
                use_point: point,
                name,
                phone_number: phoneNumber,
                amount,
            });
        },
    },
);

interface FetchGiftSubsThunkProps {
    subsId: number;
    point: number;
    price: number;
    name: string;
    productName: string;
    phoneNumber: string;
}

export const fetchGiftSubsThunk = makeFetchThunkAction(
    giftSubsFetchRequest,
    giftSubsFetchSuccess,
    giftSubsFetchFailure,
    {
        getResponseFunction: async (
            {
                state: {
                    auth: {
                        restApiClient: { shopApi },
                        user,
                    },
                },
            },
            { name, phoneNumber, price, point, subsId, productName }: FetchGiftSubsThunkProps,
        ) => {
            let res;

            if (price - point > 0) {
                res = await requestPayment({
                    user: user!,
                    price: price - point,
                    productName,
                    type: "GIFT",
                });

                if (!res) {
                    throw new Error();
                }
            }

            return shopApi.createSubscriptionGift({
                merchant_uid: res?.merchant_uid,
                subscription_id: subsId,
                price: price - point,
                use_point: point,
                name,
                phone_number: phoneNumber,
            });
        },
    },
);
