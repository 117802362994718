import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNoticePost, getNoticePostFetchState } from "@/redux/post/selectors";
import { fetchNoticePostThunk } from "@/redux/post/thunk";
import { resetNoticePostState } from "@/redux/post/actions";
import CommonPostDetailPage from "@/components/CommonPostDetailPage";

export default function NoticeDetail() {
    const dispatch = useDispatch();
    const noticePost = useSelector(getNoticePost);
    const noticePostFetchState = useSelector(getNoticePostFetchState);
    const { noticeId } = useParams<{ noticeId: string }>();

    useEffect(() => {
        dispatch(fetchNoticePostThunk(Number(noticeId)));

        return () => {
            dispatch(resetNoticePostState());
        };
    }, []);

    return <CommonPostDetailPage title="컨텐츠/이벤트" post={noticePost} fetchState={noticePostFetchState} />;
}
