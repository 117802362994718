import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LinearProgress, linearProgressClasses } from "@mui/material";
import { StyledToolbar, StyledToolbarProps } from "../AppBar/style";

export const ReadyText = styled.span`
    font-weight: bold;
    color: #ffffff;
`;

export const TitleContainer = styled.div`
    flex-grow: 1;
    margin-left: 8px;
    color: white;
    font-weight: bold;
    font-size: 14px;
`;

export const SubTitleContainer = styled.span`
    color: #ffffff;
`;

export const SubTitleImportantContainer = styled.span`
    color: white;
`;

export const LeadingIconImage = styled.img``;

export const StyledProgressBar = styled(LinearProgress)`
    &.${linearProgressClasses.colorPrimary} {
        background-color: #2d2d2d;
    }

    & .${linearProgressClasses.bar} {
        background-color: #ff831e;
    }
`;

interface StyledToolbarWithGlowProps extends StyledToolbarProps {
    glow?: boolean;
}

export const StyledToolbarWithGlow = styled(StyledToolbar)<StyledToolbarWithGlowProps>`
    ${({ glow }) =>
        glow &&
        css`
            animation: glow 2s ease-in-out infinite;
        `}

    @keyframes glow {
        0%,
        100% {
            box-shadow: 0px 0px 24px 2px #ff831e;
        }

        50% {
            box-shadow: 0px 0px 0px 0px transparent;
        }
    }
`;
