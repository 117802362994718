import React from "react";
import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";
import useHistory from "@/hooks/useCustomHistory";
import { ThumbnailTooltip } from "@/components/ThumbnailTooltip";

const ProductCardContainer = styled(ButtonBase)`
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 20px;
`;

const ThumbnailImg = styled.img`
    width: 100%;
    height: 100%;

    border-radius: 8px;
`;

const ThumbnailContainer = styled.div`
    min-width: 95px;
    width: 95px;
    height: 95px;
    margin-right: 12px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    text-align: left;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #0c0c0c;
`;

const SubTitle = styled.div`
    margin-top: 4px;

    font-size: 14px;
    color: #8f8f8f;
`;

interface ProductCardProps {
    id: number;
    thumbnailSrc: string;
    title: string;
    subTitle?: string;
    groupId?: number;
    thumbnailTooltip?: string;
    disableClick?: boolean;
}

export default function ProductCard({
    thumbnailSrc,
    groupId,
    title,
    subTitle,
    id,
    thumbnailTooltip,
    disableClick,
}: ProductCardProps) {
    const history = useHistory();

    const handleClick = () => {
        history.push(groupId ? `/order/${groupId}/${id}` : `/order/${id}`);
    };

    return (
        <ProductCardContainer disabled={disableClick} onClick={handleClick}>
            <ThumbnailContainer>
                <ThumbnailImg src={thumbnailSrc} />
                {thumbnailTooltip && <ThumbnailTooltip>{thumbnailTooltip}</ThumbnailTooltip>}
            </ThumbnailContainer>
            <ContentContainer>
                <Title>{title}</Title>
                {subTitle && <SubTitle>{subTitle}</SubTitle>}
            </ContentContainer>
        </ProductCardContainer>
    );
}
