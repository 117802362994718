import styled from "@emotion/styled";
import { css } from "@emotion/react";
import React from "react";

interface AppLayoutContainerProps {
    minHeight: string;
    backgroundColor?: string;
}

export const AppLayoutContainer = styled(({ minHeight, backgroundColor, ...props }: AppLayoutContainerProps) => (
    <div {...props} />
))`
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: ${({ minHeight }) => minHeight};

    color: white;
    ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
`;

interface InnerContainerProps {
    backgroundColor?: string;
    fullHeight: boolean;
}

export const InnerContainer = styled.div<InnerContainerProps>`
    position: relative;
    width: 100%;
    flex-grow: 1;

    ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}

    ${({ fullHeight }) =>
        fullHeight &&
        css`
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        `}
`;

export const ContentContainer = styled.div`
    height: 100%;
    width: 100%;
`;
