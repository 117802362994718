import styled from "@emotion/styled";
import Button from "../Button";

export const FindStoreWrapper = styled.div`
    display: flex;
`;

interface FindStoreProps {
    background?: string;
}

export const FindStore = styled.div<FindStoreProps>`
    border-radius: 4px;
    flex: 1 1;
    background-color: ${({ background }) => background || "#191919"};
    display: flex;
    align-items: center;
    padding: 0 16px;
`;

export const FindStoreText = styled.p`
    font-size: 16px;
    margin: 0;
    color: #ff831e;
`;

export const FindStoreTextPlcaeholder = styled.p`
    font-size: 16px;
    margin: 0;
    color: #ffffff;
`;

export const FindStoreButton = styled(Button)`
    width: 100px;
    height: 48px;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
`;
