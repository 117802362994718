import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import {
    ResultItemWrapper,
    ResultItemTopDate,
    ResultItemTopTag,
    ResultItemTopWrapper,
    Wrapper,
    ResultItemTitleWrapper,
    ResultItemTitle,
    ResultItemArrow,
    ResultItemContentWrapper,
    ResultItemAnswerWrapper,
    ResultItemAnswerDate,
    ResultItemImage,
    QuestionDeleteButtonWrapper,
    QuestionDeleteButton,
    DeleteModalText,
} from "./style";
import ArrowUpIcon from "@/assets/img/btn-arrow-up.svg";
import ArrowDownIcon from "@/assets/img/btn-arrow-down.svg";
import {
    getQuestionDeleteFetchState,
    getQuestionList,
    getQuestionListFetchState,
    getQuestionListPage,
    getQuestionListTotalPage,
} from "@/redux/question/selectors";
import { questionDeleteRequestThunk, questionListRequestThunk } from "@/redux/question/thunk";
import { reset } from "@/redux/question/actions";
import { getQuestionType } from "@/utils/questionType";
import { dateFormatter } from "@/utils/date";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import Modal from "@/components/Modal";

export default function ContactList() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [deleteModal, setDeleteModal] = useState(false);
    const [openIndex, setOpenIndex] = useState<number>(-1);
    const questionList = useSelector(getQuestionList);
    const questionListPage = useSelector(getQuestionListPage);
    const questionListTotalPage = useSelector(getQuestionListTotalPage);
    const questionListFetchState = useSelector(getQuestionListFetchState);
    const questionDeleteFetchState = useSelector(getQuestionDeleteFetchState);

    const handleClickItem = (index: number) => {
        if (openIndex === index) {
            setOpenIndex(-1);
            return;
        }
        setOpenIndex(index);
    };

    const handleLoadMore = () => dispatch(questionListRequestThunk());

    const handleClickDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        setDeleteModal(true);
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch, questionListRequestThunk]);

    useEffect(() => {
        if (questionDeleteFetchState === "SUCCESS") {
            setOpenIndex(-1);
            dispatch(reset());
            handleLoadMore();
            setDeleteModal(false);
            enqueueSnackbar("문의 내역을 삭제했습니다.", {
                variant: "success",
            });
        }
    }, [questionDeleteFetchState]);

    return (
        <Wrapper>
            <Modal
                open={deleteModal}
                handleClose={() => false}
                buttonText="확인"
                cancelButtonText="취소"
                buttonOnClick={() => dispatch(questionDeleteRequestThunk(openIndex))}
                cancelButtonOnClick={() => setDeleteModal(false)}
                fullSizeButton
                titleText="문의 삭제"
            >
                <DeleteModalText>삭제한 문의는 복구할 수 없습니다.</DeleteModalText>
            </Modal>
            <InfiniteScrollList
                fetchState={questionListFetchState}
                list={questionList}
                page={questionListPage}
                totalPage={questionListTotalPage}
                showNoResultMessage
                noResultMessage="문의하신 내역이 없어요"
                renderItem={({
                    id,
                    title,
                    content,
                    answer,
                    question_type,
                    created_datetime,
                    answered_datetime,
                    uploaded_image_set,
                }) => (
                    <ResultItemWrapper
                        open={openIndex === id}
                        key={id}
                        onClick={() => handleClickItem(id)}
                        disableRipple={openIndex === id}
                    >
                        <ResultItemTitleWrapper>
                            <ResultItemTopWrapper>
                                <ResultItemTopTag type={answer ? "answer" : "accept"}>
                                    {answer ? "답변완료" : "접수완료"}
                                </ResultItemTopTag>
                                <ResultItemTopDate>
                                    {dateFormatter(new Date(created_datetime), "YYYY.MM.DD hh:mm")}
                                </ResultItemTopDate>
                            </ResultItemTopWrapper>
                            <ResultItemTitle>
                                [{getQuestionType(question_type)}] {title}
                            </ResultItemTitle>

                            <ResultItemArrow src={openIndex === id ? ArrowUpIcon : ArrowDownIcon} />
                        </ResultItemTitleWrapper>

                        <ResultItemContentWrapper>
                            <p>{content}</p>

                            {uploaded_image_set?.original_image_url && (
                                <ResultItemImage src={uploaded_image_set?.original_image_url} alt="문의 이미지" />
                            )}

                            {answer && (
                                <ResultItemAnswerWrapper>
                                    <p>{answer}</p>

                                    {answered_datetime && (
                                        <ResultItemAnswerDate>
                                            {format(new Date(answered_datetime), "yyyy.MM.dd HH:mm")}
                                        </ResultItemAnswerDate>
                                    )}
                                </ResultItemAnswerWrapper>
                            )}

                            <QuestionDeleteButtonWrapper>
                                <QuestionDeleteButton onClick={handleClickDelete}>삭제</QuestionDeleteButton>
                            </QuestionDeleteButtonWrapper>
                        </ResultItemContentWrapper>
                    </ResultItemWrapper>
                )}
                onLoadMore={handleLoadMore}
            />
        </Wrapper>
    );
}
