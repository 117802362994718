import React from "react";
import moment from "moment";
import "moment/locale/ko";
import { Button, message, Modal, Switch } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PostResponse, PostType } from "@ferda/rest-api-client";
import { getRestApiClient } from "@/redux/auth/selectors";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import Tab from "@/components/Admin/Tab";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import { TotalCountWrapper } from "@/components/Admin/Misc";

interface Props {}

export default function Page(props: Props) {
    const history = useHistory();

    const { postApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalPost, setModalPost] = React.useState<PostResponse | undefined>();

    const [postList, setPostList] = React.useState<PostResponse[]>([]);
    const [postListTotal, setPostListTotal] = React.useState<number>(0);

    const [postType, setPostType] = React.useState<PostType>(PostType.NOTICE);
    const [isAlivePostIncluded, setIsAlivePostIncluded] = React.useState<boolean>(true);
    const [isExpiredPostIncluded, setIsExpiredPostIncluded] = React.useState<boolean>(true);
    const [page, setPage] = React.useState<number>(1);

    const columns = [
        {
            title: "작성시작",
            key: "createdDatetime",
            render: (record: PostResponse) => {
                const time = moment(record.created_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "수정시각",
            key: "modifiedDatetime",
            render: (record: PostResponse) => {
                if (!record.modified_datetime) {
                    return { children: undefined };
                }

                const time = moment(record.modified_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "제목",
            key: "title",
            render: (record: PostResponse) => {
                return {
                    children: record.title,
                };
            },
        },
        {
            title: "관리",
            key: "manage",
            render: (record: PostResponse) => {
                return {
                    children: (
                        <>
                            <Button
                                onClick={() => history.push(`/admin/posts/view/${record.id}`)}
                                style={{ marginRight: "0.5rem" }}
                            >
                                보기
                            </Button>
                            <Button
                                danger
                                onClick={() => {
                                    setModal("delete");
                                    setModalPost(record);
                                }}
                            >
                                삭제
                            </Button>
                        </>
                    ),
                };
            },
            width: "170px",
        },
    ];

    React.useEffect(() => {
        setLoading(true);
        postApi
            .getPosts(isAlivePostIncluded, isExpiredPostIncluded, postType ? [postType] : undefined, false, page)
            .then((res) => {
                setLoading(false);
                setPostList(res.data);
                setPostListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("게시글 목록을 불러오는데 실패하였습니다.");
            });
    }, [postType, isAlivePostIncluded, isExpiredPostIncluded, page, rerender]);

    return (
        <>
            <PageTitle>게시글 관리</PageTitle>
            <Container>
                <Tab
                    defaultActiveKey="notice"
                    onChange={(key) => {
                        switch (key) {
                            case "notice":
                                setPostType(PostType.NOTICE);
                                break;
                            case "event":
                                setPostType(PostType.EVENT);
                                break;
                            case "content":
                                setPostType(PostType.CONTENT);
                                break;
                        }
                    }}
                    right={
                        <>
                            <Switch
                                defaultChecked
                                checkedChildren="활성된 글 표시"
                                onChange={(checked) => setIsAlivePostIncluded(checked)}
                                unCheckedChildren="활성된 글 숨김"
                            />
                            <Switch
                                defaultChecked
                                checkedChildren="만료된 글 표시"
                                onChange={(checked) => setIsExpiredPostIncluded(checked)}
                                unCheckedChildren="만료된 글 숨김"
                                style={{ marginLeft: "0.5rem" }}
                            />
                            <Button onClick={() => history.push("/admin/posts/order")} style={{ marginLeft: "1rem" }}>
                                정렬
                            </Button>
                            <Button onClick={() => history.push("/admin/posts/add")} style={{ marginLeft: "1rem" }}>
                                작성
                            </Button>
                        </>
                    }
                    tabs={[
                        {
                            title: "공지",
                            key: "notice",
                        },
                        {
                            title: "이벤트",
                            key: "event",
                        },
                        {
                            title: "콘텐츠",
                            key: "content",
                        },
                    ]}
                />
                <TotalCountWrapper>게시글 수 : {postListTotal.toLocaleString("ko")}</TotalCountWrapper>
                <Table columns={columns} dataSource={postList} loading={loading} rowKey="id" />
                {postListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={postListTotal}
                    />
                )}
            </Container>
            <Modal
                confirmLoading={loading}
                okButtonProps={{ danger: true }}
                okText="삭제"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!modalPost) {
                        return;
                    }

                    setLoading(true);
                    postApi
                        .deletePost(modalPost.id)
                        .then(() => {
                            setModal(undefined);
                            setModalPost(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("삭제하였습니다.", 1.5);
                        })
                        .catch(() => {
                            setLoading(false);
                            message.error("삭제를 실패하였습니다.", 1.5);
                        });
                }}
                title="게시글 삭제"
                visible={modal === "delete"}
                width={800}
            >
                해당 게시글을 삭제하시겠습니까?
            </Modal>
        </>
    );
}
