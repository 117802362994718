import React from "react";
import { format } from "date-fns";
import { comma } from "@/utils/text";
import {
    ContentContainer,
    AmountContainer,
    PointLogCardContainer,
    DateContainer,
    LogNameContainer,
    ArrowIcon,
    InnerDivider,
} from "./style";

interface PointLogCardProps {
    date: Date;
    title: string;
    amount: number;
}

export default function PointLogCard({ date, title, amount }: PointLogCardProps) {
    return (
        <PointLogCardContainer>
            <DateContainer>{format(date, "yyyy.MM.dd HH:mm")}</DateContainer>
            <ContentContainer>
                <LogNameContainer>{title} 사용</LogNameContainer>
                <AmountContainer>
                    {amount < 0 && "- "}
                    {comma(Math.abs(amount))}원
                </AmountContainer>
                <ArrowIcon sx={{ color: "#757575", fontSize: "16px" }} />
            </ContentContainer>
            <InnerDivider />
        </PointLogCardContainer>
    );
}
