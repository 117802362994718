import styled from "@emotion/styled";
import InputBase from "@/components/Input";
import Button from "@/components/Button";

export const WithdrawWrapper = styled.div`
    padding: 0 20px;
`;

export const WithdrawGuideTextWrapper = styled.div`
    padding: 24px 0 32px 0;
    border-bottom: 1px solid #2d2d2d;
    font-size: 12px;
    color: #8f8f8f;
    word-break: keep-all;
`;

export const WithdrawInput = styled(InputBase)`
    width: 100%;
    font-size: 14px;
    height: unset;
    padding: 12px;

    textarea {
        &::placeholder {
            font-size: 14px;
            color: #5b5b5b;
            opacity: 1;
        }
    }
`;

export const TasteWrapper = styled.div`
    padding: 24px 0;
    border-bottom: 1px solid #2d2d2d;
`;

export const TasteTitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
`;

export const PriceWrapper = styled.div`
    padding: 24px 0;
    border-bottom: 1px solid #2d2d2d;
`;

export const PriceTitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
`;

export const PriceInputTitle = styled.p`
    font-size: 12px;
    margin-bottom: 4px;
    color: #0c0c0c;
`;

export const OtherWrapper = styled.div`
    padding: 24px 0;
    border-bottom: 1px solid #2d2d2d;
    margin-bottom: 24px;
`;

export const OtherInput = styled(WithdrawInput)`
    margin-top: 8px;
`;

export const WithdrawButton = styled(Button)`
    width: 100%;
    border-radius: 24px;
    height: 48px;
    color: white;
    font-weight: bold;
`;

export const WithdrawModalContentWrapper = styled.div`
    margin: 16px 0 32px 0;
    font-size: 14px;
    color: #0c0c0c;
    text-align: center;
`;

export const WithdrawModalContentTitleText = styled.p`
    margin-bottom: 10px;
`;

export const WithdrawModalContentSubtitleText = styled.p`
    font-size: 12px;
    word-break: keep-all;
    margin-bottom: 8px;
`;
