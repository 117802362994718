import React from "react";
import { InputBaseProps } from "@mui/material";
import SearchIcon from "@/assets/img/btn_search_go.svg";
import ResetIcon from "@/assets/img/btn_close_round_small.svg";
import { ActionButtonContainer, ResetButton, SearchButton, SearchInputProps } from "./styled";

interface SearchInputProps extends InputBaseProps {
    handleReset?: () => void;
    onSearch?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function SearchInput({ handleReset, onSearch, value, ...args }: SearchInputProps) {
    return (
        <SearchInputProps
            {...args}
            value={value}
            endAdornment={
                <ActionButtonContainer>
                    {value && <ResetButton onClick={handleReset} src={ResetIcon} alt="reset" />}
                    <SearchButton src={SearchIcon} alt="search" onClick={onSearch} />
                </ActionButtonContainer>
            }
        />
    );
}
