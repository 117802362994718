import { RootState } from "../types";
import { AUTH_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const { getRestApiClient } = makeSubStateSelectorMap("auth", AUTH_STATE_KEY_LIST);

export function getAuthState({ auth: { type, user } }: RootState) {
    if (type === "AUTHORIZED") {
        return {
            type,
            user: user!,
        };
    }

    return {
        type,
    };
}

export function getUserAsAlwaysExists({ auth: { user } }: RootState) {
    return user!;
}
