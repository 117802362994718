import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrentUrl } from "@/redux/customHistory/selectors";

export default function CustomHistoryCheck() {
    const currentUrl = useSelector(getCurrentUrl);
    const history = useHistory();

    useEffect(() => {
        if (
            window.location.pathname.startsWith("/admin") ||
            window.location.pathname.startsWith("/pos") ||
            history.location.pathname === currentUrl
        ) {
            return;
        }

        history.replace(currentUrl);
    }, [history, currentUrl]);

    return null;
}
