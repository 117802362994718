import { format } from "date-fns";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { Gender, ServiceCancelReasonType } from "@ferda/rest-api-client";
import { VerifyPhoneVerificationCodeFetchRequestPayload } from "../login/types";
import { RootState } from "../types";
import {
    editUserInfoFetchRequest,
    editUserInfoFetchSuccess,
    editUserInfoFetchFailure,
    editPhoneNumberFetchRequest,
    editPhoneNumberFetchSuccess,
    editPhoneNumberFetchFailure,
    deleteUserFetchRequest,
    deleteUserFetchSuccess,
    deleteUserFetchFailure,
} from "./actions";
import { loadAuthStateAndApiClientThunk } from "@/redux/auth/thunk";

type UserThunkAction = ThunkAction<void, RootState, unknown, AnyAction>;

export function editUserInfoThunk({
    name,
    gender,
    email,
    birthDate,
    smsTermAgree,
    emailTermAgree,
    noticeTermAgree,
    job,
}: {
    name?: string;
    gender?: Gender;
    email?: string;
    birthDate?: Date;
    smsTermAgree?: boolean;
    emailTermAgree?: boolean;
    noticeTermAgree?: boolean;
    job?: string;
}): UserThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { userApi },
            },
        } = getState();

        dispatch(editUserInfoFetchRequest());

        let emptyValue;

        try {
            await userApi.updateUser("me", {
                name,
                gender,
                birth_date: birthDate ? format(birthDate, "yyyy-MM-dd") : emptyValue,
                email,
                consent_receive_email: emailTermAgree,
                consent_receive_sms: smsTermAgree,
                consent_receive_sns_message: noticeTermAgree,
                job,
            });

            dispatch(editUserInfoFetchSuccess());
        } catch (e: unknown) {
            if (e instanceof Error) {
                dispatch(editUserInfoFetchFailure(e));
                return;
            }
            throw e;
        }
    };
}

export function editPhoneNumberThunk({
    phoneNumber,
    verificationCode,
}: VerifyPhoneVerificationCodeFetchRequestPayload): UserThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { userApi },
            },
        } = getState();

        dispatch(editPhoneNumberFetchRequest());

        try {
            await userApi.changePhoneNumber("me", {
                phone_number: phoneNumber,
                phone_number_verification_code: verificationCode,
            });

            dispatch(editPhoneNumberFetchSuccess());
        } catch (e: unknown) {
            if (e instanceof Error) {
                dispatch(editPhoneNumberFetchFailure(e));
                return;
            }
            throw e;
        }
    };
}

export function deleteUserRequestThunk(reason: string): UserThunkAction {
    return async (dispatch, getState) => {
        const {
            auth: {
                restApiClient: { userApi, serviceApi },
            },
        } = getState();

        dispatch(deleteUserFetchRequest());

        try {
            await serviceApi.createServiceCancelReason({
                reason,
                service_cancel_reason_type: ServiceCancelReasonType.WITHDRAWAL,
            });
        } catch (e) {
            if (e instanceof Error) {
                dispatch(deleteUserFetchFailure(e));
                return;
            }
        }

        try {
            await userApi.deleteUser("me");

            dispatch(deleteUserFetchSuccess());
        } catch (e: unknown) {
            if (e instanceof Error) {
                dispatch(deleteUserFetchFailure(e));
                return;
            }
            throw e;
        }
    };
}
