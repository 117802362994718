import React from "react";
import styled from "@emotion/styled";

type Size = "NORMAL" | "LARGE";

const Badge = styled.span<{ color: string; size?: Size }>`
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    background-color: ${(props) => props.color};
    color: #ffffff;
    font-size: ${(props) => (props.size === "LARGE" ? "2rem" : "inherit")};
    font-weight: ${(props) => (props.size === "LARGE" ? "bold" : "inherit")};
    cursor: ${(props) => (props.onClick ? "pointer" : "inherit")};
`;

interface Props {
    color: string;
    children: React.ReactNode;
    onClick?: () => void;
    size?: Size;
}

export default function Component(props: Props) {
    return (
        <Badge color={props.color} size={props.size} onClick={props.onClick}>
            {props.children}
        </Badge>
    );
}
