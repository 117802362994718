import { QuestionType } from "@ferda/rest-api-client";

export function getQuestionType(type: QuestionType) {
    switch (type) {
        case QuestionType.COMPLIMENT: {
            return "칭찬";
        }
        case QuestionType.QUESTION: {
            return "문의";
        }
        case QuestionType.SUGGESTION: {
            return "제안";
        }
        case QuestionType.DISSATISFACTION: {
            return "불만";
        }
    }
}
