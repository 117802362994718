import { historySlice } from "./slice";

const actions = historySlice.actions;

export const {
    orderHistoryListFetchRequest,
    orderHistoryListFetchSuccess,
    orderHistoryListFetchFailure,
    orderHistoryDetailRequestFetchRequest,
    orderHistoryDetailRequestFetchSuccess,
    orderHistoryDetailRequestFetchFailure,
    setOrderHistoryList,
    reset,
} = actions;
