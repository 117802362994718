import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { FetchState } from "@/interfaces/fetch";
import { capitalize } from "@/utils/text";
import { ListFetchSuccessPayload } from "./types";

export function setValueReducer<StateType, Key extends keyof StateType>(
    key: Key,
): (state: StateType, { payload }: PayloadAction<StateType[Key]>) => void {
    return (state, { payload }) => {
        state[key] = payload;
    };
}

type ArrayElement<ArrayType extends unknown[]> = ArrayType extends Array<infer ElementType> ? ElementType : never;

type PartialReducer<StateType, KeyArray extends Array<keyof StateType>> = {
    [Key in ArrayElement<KeyArray> as `set${Capitalize<string & Key>}`]: (
        state: Draft<StateType>,
        action: PayloadAction<StateType[Key]>,
    ) => void;
};

export function setValueReducerList<StateType, KeyList extends Array<keyof StateType> = never>(
    keyList: KeyList,
): PartialReducer<StateType, KeyList> {
    return keyList.reduce((prev, key) => {
        const reducerKey = `set${capitalize(key.toString())}`;
        return {
            ...prev,
            [reducerKey]: setValueReducer<StateType, typeof key>(key),
        };
    }, {} as PartialReducer<StateType, KeyList>);
}

type AsyncReducerMap<Key extends string, RequestReducer, SuccessReducer, FailureReducer> = {
    [RequestKey in Key as `${Key}FetchRequest`]: RequestReducer;
} & { [SuccessKey in Key as `${Key}FetchSuccess`]: SuccessReducer } & {
    [FailureKey in Key as `${Key}FetchFailure`]: FailureReducer;
};

type ActionReducer<StateType> = (state: Draft<StateType>) => void;

export function AsyncActionReducer<Key extends string, StateType, Reducer = ActionReducer<StateType>>(
    key: Key,
    requestReducer: Reducer,
    successReducer: Reducer,
    failureReducer: Reducer,
) {
    return {
        [`${key}FetchRequest`]: requestReducer,
        [`${key}FetchSuccess`]: successReducer,
        [`${key}FetchFailure`]: failureReducer,
    } as AsyncReducerMap<Key, Reducer, Reducer, Reducer>;
}

function getFetchStateReducer<StateType, Key extends string>(key: Key, fetchState: FetchState) {
    return (state: Draft<StateType>) => {
        (state[`${key}FetchState` as keyof Draft<StateType>] as FetchState) = fetchState;
    };
}

// if (!useListSuccessReducer) {
//     return;
// }

// if (
//     typeof state[`${key}FetchState` as keyof Draft<StateType>] !== "undefined" &&
//     process.env.NODE_ENV === "development"
// ) {
//     throw Error(`${key} state is not defined`);
// } else {
//     (state[`${key}FetchState` as keyof Draft<StateType>] as FetchState) = "SUCCESS";
// }

// if (
//     typeof state[`${key}FetchState` as keyof Draft<StateType>] !== "undefined" &&
//     process.env.NODE_ENV === "development"
// ) {
//     throw Error(`${key} state is not defined`);
// } else {
//     (state[`${key}FetchState` as keyof Draft<StateType>] as FetchState) = "SUCCESS";
// }

function CheckStateAndSetValue<StateType, Key extends keyof StateType, Value = StateType[Key]>(
    state: Draft<StateType>,
    key: Key,
    value: Value,
    warn?: boolean,
) {
    if (typeof state[`key` as keyof Draft<StateType>] !== "undefined" && process.env.NODE_ENV === "development") {
        if (warn) {
            // eslint-disable-next-line no-console
            console.warn(`${key} state is not defined`);

            return;
        }
        throw Error(`${key} state is not defined`);
    }

    (state[`${key}` as keyof Draft<StateType>] as any) = value;
}

function getFetchSuccessReducer<StateType, Key extends string, PayloadType>(key: Key) {
    return (state: Draft<StateType>, { payload }: PayloadAction<PayloadType>) => {
        CheckStateAndSetValue(state, `${key}` as keyof StateType, payload, true);
        CheckStateAndSetValue(state, `${key}FetchState` as keyof StateType, "SUCCESS");
    };
}

function getListFetchSuccessReducer<
    StateType,
    Key extends string,
    ResponseType,
    PayloadType extends ListFetchSuccessPayload<ResponseType>,
>(key: Key) {
    return (state: Draft<StateType>, { payload }: PayloadAction<PayloadType>) => {
        CheckStateAndSetValue(state, `${key}` as keyof StateType, [
            ...((state[`${key}` as keyof Draft<StateType>] as any) ?? []),
            ...payload.list,
        ]);
        CheckStateAndSetValue(state, `${key}FetchState` as keyof StateType, "SUCCESS");
        CheckStateAndSetValue(state, `${key}Page` as keyof StateType, payload.nextPage);
        CheckStateAndSetValue(state, `${key}TotalPage` as keyof StateType, payload.totalPage);
    };
}

type PayloadActionReducer<StateType, PayloadType> = (
    state: Draft<StateType>,
    payloadAction: PayloadAction<PayloadType>,
) => void;

interface AsyncReducerOptions<RequestReducer, SuccessReducer, FailureReducer> {
    requestReducer?: RequestReducer;
    successReducer?: SuccessReducer;
    failureReducer?: FailureReducer;
    useListSuccessReducer?: boolean;
}

export function makeAsyncReducer<
    Key extends string,
    StateType,
    RequestActionPayload = void,
    SuccessActionPayload = void,
    FailureActionPayload = Error,
    RequestReducer = PayloadActionReducer<StateType, RequestActionPayload>,
    SuccessReducer = PayloadActionReducer<StateType, SuccessActionPayload>,
    FailureReducer = PayloadActionReducer<StateType, FailureActionPayload>,
>(
    key: Key,
    {
        requestReducer,
        successReducer,
        useListSuccessReducer,
        failureReducer,
    }: AsyncReducerOptions<RequestReducer, SuccessReducer, FailureReducer> = {},
) {
    const fetchStateReducer = (fetchState: FetchState) => getFetchStateReducer<StateType, Key>(key, fetchState);

    const fetchSuccessReducer = useListSuccessReducer ? getListFetchSuccessReducer(key) : getFetchSuccessReducer(key);

    return {
        [`${key}FetchRequest`]: requestReducer ?? fetchStateReducer("FETCHING"),
        [`${key}FetchSuccess`]: successReducer ?? fetchSuccessReducer,
        [`${key}FetchFailure`]: failureReducer ?? fetchStateReducer("FAILURE"),
    } as AsyncReducerMap<Key, RequestReducer, SuccessReducer, FailureReducer>;
}
