import React from "react";
import { useLocation } from "react-router-dom";

import useHistory from "@/hooks/useCustomHistory";
import GiftTab from "./GiftTab";
import LogTab from "./LogTab";
import { StyledGiftTab, StyledGiftTabs } from "./style";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";

export default function Gift() {
    const { pathname } = useLocation();
    const history = useHistory();

    const handleChangeTab = (e: React.SyntheticEvent, value: "/gift/shop" | "/gift/get") => {
        history.replace(value);
    };

    return (
        <AppLayout
            disableAppBarBorder={true}
            title="Gift"
            titleColor="#fff"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            <StyledGiftTabs value={pathname} onChange={handleChangeTab} variant="fullWidth">
                <StyledGiftTab label="선물하기" value="/gift/shop" />
                <StyledGiftTab label="선물함" value="/gift/get" />
            </StyledGiftTabs>

            {pathname === "/gift/shop" && <GiftTab />}
            {pathname === "/gift/get" && <LogTab />}
        </AppLayout>
    );
}
