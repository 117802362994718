import { isEqual } from "lodash";
import * as React from "react";
import { zeroPadding } from "@/utils/text";

export function usePrevious<T>(value: T, initial?: undefined): T | undefined;
export function usePrevious<T, I>(value: T, initial: I): T | I;
export function usePrevious<T, I = undefined>(value: T, initial: I): T | I {
    const ref = React.useRef<T | I>(initial);
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useEffectWithComparator<T extends any[]>(
    effect: React.EffectCallback,
    comparator: (prev: T, next: T) => boolean,
    inputs: T,
) {
    const revision = React.useRef<number>(0);
    const prevInputs = usePrevious(inputs);
    if (prevInputs) {
        if (!comparator(prevInputs, inputs)) {
            revision.current += 1;
        }
    }
    React.useEffect(effect, [revision.current]);
}

export function useMemoWithComparator<T, D extends any[]>(
    factory: () => T,
    comparator: (prev: D, next: D) => boolean,
    inputs: D,
): T {
    const revision = React.useRef<number>(0);
    const prevInputs = usePrevious(inputs);
    if (prevInputs) {
        if (!comparator(prevInputs, inputs)) {
            revision.current += 1;
        }
    }
    return React.useMemo(factory, [revision.current]);
}

export function useLockBodyScroll(lock: boolean) {
    const body = typeof document !== "undefined" ? document.body : null;

    React.useEffect(() => {
        if (lock && body !== null) {
            const computedStyle = window.getComputedStyle(body);
            const overflow = computedStyle.overflow;

            body.style.overflow = "hidden";
            // Re-enable scrolling when component unmounts
            return () => {
                body.style.overflow = overflow;
            };
        }
    }, [body, lock]);
}

export function useIntersect<T extends Element = Element>(options: IntersectionObserverInit) {
    const [entry, updateEntry] = React.useState<IntersectionObserverEntry | null>(null);
    const observer = React.useRef<IntersectionObserver | null>(null);
    const target = React.useRef<T | null>(null);

    const ref = React.useCallback((elem: T | null) => {
        if (observer.current !== null) {
            if (target.current !== null) {
                observer.current.unobserve(target.current);
            }
            if (elem !== null) {
                observer.current.observe(elem);
            }
        }
        target.current = elem;
    }, []);

    useEffectWithComparator(
        () => {
            observer.current = new IntersectionObserver(([item]) => updateEntry(item), options);

            if (target.current !== null) {
                observer.current.observe(target.current);
            }

            return () => {
                if (observer.current !== null) {
                    observer.current.disconnect();
                }
            };
        },
        isEqual,
        [options],
    );

    return [ref, entry] as const;
}

export function useTimer(seconds: number) {
    const [startTime, setStartTime] = React.useState<Date | null>(null);
    const startTimer = React.useCallback(() => {
        setStartTime(new Date());
    }, [setStartTime]);
    const stopTimer = React.useCallback(() => {
        setStartTime(null);
    }, [setStartTime]);
    const [timeLeft, setTimeLeft] = React.useState<number>(seconds);
    React.useEffect(() => {
        function tick() {
            if (startTime !== null) {
                // 시작시간 TS
                const start = Math.floor(startTime.getTime() / 1000);
                // 끝시간 TS
                const end = start + seconds;
                // 현재시간 TS
                const now = Math.floor(new Date().getTime() / 1000);
                // boundary check
                const newTimeLeft = Math.max(0, end - now);
                setTimeLeft(newTimeLeft);
            }
        }
        if (startTime === null) {
            setTimeLeft(seconds);
        } else {
            tick();
            const id = setInterval(tick, 1);
            return () => {
                clearInterval(id);
            };
        }
    }, [seconds, startTime, setTimeLeft]);
    const running = startTime !== null;
    const minutesLeft = Math.floor(timeLeft / 60);
    const secondsLeft = timeLeft % 60;
    const timeDescription = running ? `${zeroPadding(minutesLeft, 2)}:${zeroPadding(secondsLeft, 2)}` : "";
    return { timeDescription, startTimer, stopTimer, timeLeft };
}
