import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RouteProps, Route } from "react-router-dom";
import { getAuthState } from "@/redux/auth/selectors";
import useHistory from "@/hooks/useCustomHistory";

interface RouteWithAuthGuardProps extends RouteProps {
    authorizedOnly?: boolean;
    unAuthorizedOnly?: boolean;
}

export default function RouteWithAuthGuard({ authorizedOnly, unAuthorizedOnly, ...args }: RouteWithAuthGuardProps) {
    const history = useHistory();
    const authState = useSelector(getAuthState);
    const needRedirect = useMemo(
        () =>
            (authorizedOnly && authState.type !== "AUTHORIZED") ||
            (unAuthorizedOnly && authState.type === "AUTHORIZED"),
        [authState.type, authorizedOnly, unAuthorizedOnly],
    );

    useEffect(() => {
        if (!needRedirect) {
            return;
        }
        history.push(authState.type !== "AUTHORIZED" ? "/signup" : "/");
    }, [needRedirect, authState]);

    if (needRedirect) {
        return null;
    }

    return <Route {...args} />;
}
