import styled from "@emotion/styled";

export const Clear = styled.div`
    clear: both;
`;

export const Right = styled.div`
    float: right;
`;

export const Left = styled.div`
    float: left;
`;
