import { SelectProps as MuiSelectProps, MenuItem } from "@mui/material";
import React from "react";
import { SelectInput, SelectPlaceholder, StyledFormControl, StyledSelect } from "./style";

export type SelectItemsType = Array<{
    value: string | number;
    label: string;
}>;

interface SelectProps extends MuiSelectProps {
    value: string | number;
    placeholder: string;
    items: SelectItemsType;
    width?: number;
    height?: number;
    showSelectValueType?: "label" | "value";
}

export default function Select({
    placeholder,
    showSelectValueType = "value",
    value,
    items,
    width,
    height = 56,
    ...args
}: SelectProps) {
    return (
        <StyledFormControl width={width}>
            <StyledSelect
                displayEmpty
                input={<SelectInput />}
                height={height}
                fullWidth
                renderValue={(_) => {
                    if (typeof value === "string" && value.length === 0) {
                        return <SelectPlaceholder>{placeholder}</SelectPlaceholder>;
                    }

                    if (showSelectValueType === "label") {
                        return items.find((v) => v.value === value)?.label;
                    }

                    return value;
                }}
                value={value || ""}
                {...args}
            >
                {items.map(({ value, label }, index) => (
                    <MenuItem value={value} key={index}>
                        {label}
                    </MenuItem>
                ))}
            </StyledSelect>
        </StyledFormControl>
    );
}
