import { createSlice } from "@reduxjs/toolkit";
import { PointLogListFetchSuccessPayload, PointLogState } from "./types";
import { makeAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: PointLogState = {
    pointLogList: null,
    pointLogListFetchState: "READY",
    pointLogListPage: null,
    pointLogListTotalPage: null,
};

export const POINT_LOG_STATE_KEY_LIST = getKeyListFromState(initialState);

export const pointLogSlice = createSlice({
    name: "pointlog",
    initialState,
    reducers: {
        reset: () => initialState,
        ...makeAsyncReducer<"pointLogList", PointLogState, void, PointLogListFetchSuccessPayload>("pointLogList", {
            useListSuccessReducer: true,
        }),
    },
});

export const pointLogReducer = pointLogSlice.reducer;
