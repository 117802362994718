import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonBase, ButtonBaseProps } from "@mui/material";

export const CupTypeButtonAspectRatioContainer = styled.div`
    position: relative;

    width: calc(50% - 8px);
    padding-top: 45%;
`;

interface StyledCupTypeButtonProps extends ButtonBaseProps {
    selected?: boolean;
}

export const StyledCupTypeButton = styled(ButtonBase)<StyledCupTypeButtonProps>`
    position: absolute;
    left: 0;
    top: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    border-radius: 8px;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.4;
        `}

    ${({ selected }) =>
        selected
            ? css`
                  color: #ffffff;

                  background-color: #ff831e;
              `
            : css`
                  color: #8f8f8f;

                  background-color: #ffffff;
                  border: 1px solid #ff831e;
              `}
`;

export const CupTypeButtonImg = styled.img`
    width: calc(75% - 30px);
    height: auto;
`;

export const CupTypeButtonText = styled.div`
    margin-top: 8px;
`;

export const CupTypeButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
