import { giftLogSlice } from "./slice";

const actions = giftLogSlice.actions;

export const {
    giftLogListFetchRequest,
    giftLogListFetchSuccess,
    giftLogListFetchFailure,
    giftSubscribeLogListFetchRequest,
    giftSubscribeLogListFetchSuccess,
    giftSubscribeLogListFetchFailure,
    setGiftLogList,
    setGiftLogListRequestFetchState,
    giftDetailInfoRequestFetchRequest,
    giftDetailInfoRequestFetchSuccess,
    giftDetailInfoRequestFetchFailure,
    giftSubscribeDetailInfoRequestFetchRequest,
    giftSubscribeDetailInfoRequestFetchSuccess,
    giftSubscribeDetailInfoRequestFetchFailure,
    setGiftLogDetailInfoRequestFetchState,
    setGiftLogDetailInfo,
    giftOrderFetchRequest,
    giftOrderFetchSuccess,
    giftOrderFetchFailure,
    giftSubscribeOrderFetchRequest,
    giftSubscribeOrderFetchSuccess,
    giftSubscribeOrderFetchFailure,
    reset,
    resetGiftLogState,
} = actions;
