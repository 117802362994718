import styled from "@emotion/styled";
import { StyledIconButton } from "@/components/IconButton";

export const HistoryDetailWrapper = styled.div`
    padding: 40px 20px;
`;

export const HistoryDetailTopWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
`;

export const HistoryDetailTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    color: #ff831e;
`;

export const HistoryDetailRefreshButton = styled(StyledIconButton)`
    padding: 0;
`;

export const InfoWrapper = styled.div`
    background-color: #ffffff;
    border-radius: 8px;
    padding: 32px 20px 40px 20px;
    margin-bottom: 32px;
    border: 1px solid #ff831e;
`;

export const InfoStoreTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #0c0c0c;
    margin-bottom: 8px;
`;

export const InfoAddressWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #ff831e;
    margin-bottom: 38px;
`;

export const InfoAddressIcon = styled.img`
    margin-right: 4px;
`;

export const InfoAddressText = styled.p`
    font-size: 12px;
    color: #0c0c0c;
`;

export const StatusWrapper = styled.div`
    position: relative;
`;

export const StatusItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }
`;

export const StatusTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface StatusTextCircleProps {
    status: "disabled" | "complete" | "inprogress";
}

const CircleColor = (status: "disabled" | "complete" | "inprogress") => {
    if (!status || status === "disabled") {
        return "#d1d1d1";
    }

    if (status === "inprogress") {
        return "#5b5b5b";
    }

    if (status === "complete") {
        return "#ff831e";
    }
};

export const StatusTextCircle = styled.div<StatusTextCircleProps>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 12px;
    z-index: 100;

    background-color: ${({ status }) => CircleColor(status)};
`;

interface StatusTextProps {
    active?: boolean;
}

export const StatusText = styled.span<StatusTextProps>`
    font-size: 16px;
    font-weight: bold;
    color: ${({ active }) => (active ? "#0c0c0c" : "#8f8f8f")};
`;

export const StatusDate = styled.span`
    font-size: 12px;
    color: #8f8f8f;
`;

interface StatusBarProps {
    status: "order" | "making" | "complete";
}

export const StatusBar = styled.div<StatusBarProps>`
    width: 2px;
    height: calc(100% - 12px);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 5px;
    background-color: #d1d1d1;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: ${({ status }) => {
            if (status === "order") {
                return 0;
            }

            if (status === "making") {
                return 50;
            }

            if (status === "complete") {
                return 100;
            }
        }}%;
        background-color: #ff831e;
    }
`;

export const MenuWrapper = styled.div``;

export const MenuName = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #ff831e;
    margin-bottom: 12px;
`;

export const MenuOptions = styled.p`
    font-size: 14px;
    color: #0c0c0c;
    margin-bottom: 12px;
`;
