import styled from "@emotion/styled";

const PolicyContent = styled.div`
    padding: 24px 20px;

    * {
        border-color: white !important;
        color: #000000 !important;
        background-color: transparent !important;
    }
`;

export default PolicyContent;
