import { RequestPayResponse } from "iamport-typings";
import { UserResponse } from "@ferda/rest-api-client";

interface RequestPaymentProps {
    user: UserResponse;
    price: number;
    productName?: string;
    customerUid?: string;
    type: "GIFT" | "SUBSCRIPTION";
    getCallbackUrl?: (merchantUid: string) => string;
}

export async function requestPayment({
    price,
    productName,
    user: { username, detail },
    customerUid,
    type,
    getCallbackUrl,
}: RequestPaymentProps) {
    if (!username || !detail) {
        throw new Error("유저정보가 없습니다.");
    }

    const { email, name, phone_number } = detail;

    const { IMP } = window;

    const IAMPORT_UID = process.env.REACT_APP_IAMPORT_UID;

    if (!IMP || !IAMPORT_UID) {
        throw new Error("결제모듈이 로드되지 않았습니다.");
    }

    IMP.init(IAMPORT_UID);

    const orderUid = `${type}-${username}-${Date.now()}`;

    let impResponse;

    try {
        impResponse = await new Promise<RequestPayResponse>((resolve, reject) => {
            IMP.request_pay(
                {
                    pg: `html5_inicis.${customerUid ? "MOIzzzcofB" : "MOI7775799"}`,
                    pay_method: "card",
                    merchant_uid: orderUid,
                    name: productName,
                    amount: price,
                    buyer_email: email ?? "",
                    buyer_name: name,
                    buyer_tel: phone_number,
                    biz_num: "558-86-00202",
                    ["customer_uid" as any]: customerUid,
                    m_redirect_url: getCallbackUrl && getCallbackUrl(orderUid),
                },
                (rsp) => (rsp.success ? resolve(rsp) : reject(rsp)),
            );
        });
    } catch (e: unknown) {
        if (typeof e === "undefined") {
            return;
        }

        throw new Error((e as RequestPayResponse).error_msg ?? "예상하지 못한 오류가 발생하였습니다.");
    }

    if (!customerUid && impResponse.imp_uid === null) {
        throw new Error("결제 정보를 받아오지 못하였습니다.");
    }

    return impResponse;
}
