import React from "react";
import { Button } from "antd";
import { MiscellaneousType } from "@ferda/rest-api-client";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import { Right, Clear } from "@/components/Admin/Float";

interface Props {}

export default function Page(props: Props) {
    const history = useHistory();

    const { serviceApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [content, setContent] = React.useState<string | undefined>();

    React.useEffect(() => {
        setLoading(true);
        serviceApi
            .getMiscellaneous(MiscellaneousType.SUBSCRIPTION_INFO)
            .then((res) => {
                setLoading(false);
                setContent(res.data.content);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            <PageTitle>구독 정보</PageTitle>
            <Container>
                <Right>
                    <Button disabled={loading} onClick={() => history.push("/admin/subscriptioninfo/edit")}>
                        수정
                    </Button>
                </Right>
                <Clear />
            </Container>
            <Container>
                {/* eslint-disable-next-line react/no-danger */}
                {content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <div>내용이 없습니다.</div>}
            </Container>
        </>
    );
}
