export function getQueryStringObject(search: string): Record<string, string> {
    const params = new URLSearchParams(search);

    const result: Record<string, string> = {};

    params.forEach((value: string, key: string) => {
        result[key] = encodeURIComponent(value);
    });

    return result;
}
