import styled from "@emotion/styled";

export const SearchBarContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const MenuOptionContainer = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const MenuOptionGroupTitle = styled.div`
    font-weight: bold;
`;

export const MenuOptionList = styled.ul`
    flex-grow: 1;
    margin: 0;
    margin-left: 0.5rem;
    padding: 0;
    list-style: none;
`;

export const MenuOptionListItem = styled.li`
    margin-bottom: 0.25rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const ModalMenuOptionGroup = styled.div`
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const ModalMenuOptionGroupTitle = styled.div`
    margin-bottom: 0.5rem;
`;

export const ModalMenuOptionItem = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const ModalMenuOptionItemName = styled.div`
    flex-grow: 1;
    margin: 0 0.5rem;
`;

export const ModalMenuOptionItemPrice = styled.div``;
