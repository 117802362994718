import React, { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import { getDay, addDays } from "date-fns";
import { ArrowForwardIos } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { ButtonBase } from "@mui/material";
import { ShopResponse } from "@ferda/rest-api-client";
import Divider from "@/components/Divider";
import { getShopBusinessHour } from "@/redux/shop/selectors";
import { fetchShopBusinessHourThunk } from "@/redux/shop/thunk";
import BottomSheet from "@/components/BottomSheet";
import { BottomConfirmButtonContainer, ConfirmButton } from "@/components/Button/style";
import { BottomSpacer } from "@/components/Spacer";
import { getHmFromHms } from "@/utils/time";

const StoreInfoCardContainer = styled.div`
    width: 100%;
`;

const StyledSlider = styled(Slider)`
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 24px;
    overflow: hidden;
`;

const ShopImg = styled.img`
    width: 100%;
    height: auto;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #ff831e;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
`;

const RowTitle = styled.div`
    min-width: 60px;
    margin-right: 20px;

    color: #0f0f0f;
`;

const Content = styled.div`
    flex-grow: 1;
    font-size: 14px;
    color: #0c0c0c;
    text-align: right;
`;

const PhoneLink = styled.a`
    color: #ff831e;
    text-decoration: underline;
`;

const StyledDivider = styled(Divider)`
    margin-top: 12px;
    margin-bottom: 12px;
`;

const InnerDivider = styled(Divider)`
    margin-top: 6px;
    margin-bottom: 6px;
`;

const StyledButtonBase = styled(ButtonBase)`
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;

    text-align: left;
`;

const BusinessHourTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #0c0c0c;
`;

const ShopName = styled.div`
    font-size: 14px;
    color: #8f8f8f;
`;

interface StoreInfoCardProps {
    handleClose: () => void;
    handleShopSelect: () => void;
    shop: ShopResponse;
    disableSelect?: boolean;
}

export default function StoreInfoCard({ handleShopSelect, handleClose, shop, disableSelect }: StoreInfoCardProps) {
    const { id, name, more_infos, uploaded_image_sets } = shop;
    const dispatch = useDispatch();
    const shopBusinessHour = useSelector(getShopBusinessHour);
    const [businessHourSheetOpen, setBusinessHourSheetOpen] = useState(false);

    const handleBusinessHourSheetToggle = (newValue: boolean) => () => {
        setBusinessHourSheetOpen(newValue);
    };

    useEffect(() => {
        dispatch(fetchShopBusinessHourThunk(id));
    }, [id]);

    const businessHourText = useMemo(() => {
        if (!shopBusinessHour) {
            return "-";
        }

        const todayBusinessHour = shopBusinessHour.find(({ weekday }) => weekday === getDay(addDays(new Date(), -1)));

        if (!todayBusinessHour) {
            return "금일 휴무";
        }

        const { start_time, end_time } = todayBusinessHour;

        return `${getHmFromHms(start_time)} ~ ${getHmFromHms(end_time)}`;
    }, [shopBusinessHour]);

    const getButtonText = ({ opened, temporary_business_closure, menu_order_available }: ShopResponse) => {
        if (temporary_business_closure) {
            return "휴업";
        }

        if (!opened) {
            return "영업종료";
        }

        if (menu_order_available === false) {
            return "재고부족";
        }

        return "매장선택";
    };

    return (
        <StoreInfoCardContainer>
            <BottomSheet open={businessHourSheetOpen} onDismiss={handleBusinessHourSheetToggle(false)}>
                <BusinessHourTitle>운영시간</BusinessHourTitle>
                <ShopName>{name}</ShopName>
                <InnerDivider />
                {shopBusinessHour &&
                    shopBusinessHour.map(({ weekday, start_time, end_time }) => (
                        <>
                            <Row>
                                <RowTitle>{["월", "화", "수", "목", "금", "토", "일"][weekday]}요일</RowTitle>
                                <Content>
                                    {getHmFromHms(start_time)} ~ {getHmFromHms(end_time)}
                                </Content>
                            </Row>
                            <InnerDivider />
                        </>
                    ))}
                <BottomSpacer />
            </BottomSheet>
            {uploaded_image_sets.length > 0 ? (
                <StyledSlider>
                    {uploaded_image_sets.map((img, i) => (
                        <ShopImg key={i} src={img.original_image_url} />
                    ))}
                </StyledSlider>
            ) : null}
            <Title>{name}</Title>
            <StyledDivider />
            <StyledButtonBase onClick={handleBusinessHourSheetToggle(true)}>
                <Row>
                    <RowTitle>운영시간</RowTitle>
                    <Content>
                        {businessHourText} <ArrowForwardIos sx={{ fontSize: "14px", marginBottom: "4px" }} />
                    </Content>
                </Row>
            </StyledButtonBase>
            {more_infos.map((info, i) => (
                <Row key={i}>
                    <RowTitle>{info.name}</RowTitle>
                    <Content>
                        {info.name === "전화번호" ? (
                            <PhoneLink href={`tel:${info.value}`}>{info.value}</PhoneLink>
                        ) : (
                            info.value
                        )}
                    </Content>
                </Row>
            ))}
            <BottomSpacer />
            {!disableSelect && (
                <BottomConfirmButtonContainer style={{ paddingLeft: "0", paddingRight: "0" }}>
                    <ConfirmButton
                        onClick={handleShopSelect}
                        style={{ width: "calc(100% - 40px)" }}
                        variant="contained"
                        backgroundColor="#ff831e"
                        disabled={shop.menu_order_available === false || !shop.opened}
                    >
                        {getButtonText(shop)}
                    </ConfirmButton>
                </BottomConfirmButtonContainer>
            )}
        </StoreInfoCardContainer>
    );
}
