import React from "react";
import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";
import { ShopResponse } from "packages/ferda-rest-api-client/dist";
import PlaceHolderImage from "@/assets/img/place_holder.png";
import { ThumbnailTooltip } from "@/components/ThumbnailTooltip";

const StoreCardContainer = styled(ButtonBase)`
    display: flex;
    align-items: stretch;
    padding-left: 20px;
    padding-top: 16px;
    padding-right: 20px;
    padding-bottom: 16px;
`;

const ThumbnailWrapper = styled.div`
    width: 88px;
    height: 88px;
    margin-right: 12px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
`;

const ThumbnailImg = styled.img`
    width: 100%;
    height: 100%;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;

    text-align: left;
    color: white;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #0c0c0c;
`;

const Address = styled.div`
    margin-top: 4px;
    flex-grow: 1;

    font-size: 12px;
    color: #8f8f8f;
`;

const Distance = styled.div`
    font-size: 14px;
    text-align: right;
`;

interface StoreCardProps {
    shop: ShopResponse;
    onClick?: () => void;
}

export const StoreOrderStatusText = ({ opened, menu_order_available, temporary_business_closure }: ShopResponse) => {
    if (temporary_business_closure) {
        return <ThumbnailTooltip>휴업</ThumbnailTooltip>;
    }

    if (!opened) {
        return <ThumbnailTooltip>영업종료</ThumbnailTooltip>;
    }

    if (menu_order_available === false) {
        return <ThumbnailTooltip>주문불가</ThumbnailTooltip>;
    }
};

export default function StoreCard({ shop, onClick }: StoreCardProps) {
    const { name, uploaded_image_sets, address, distance } = shop;
    return (
        <StoreCardContainer onClick={onClick}>
            <ThumbnailWrapper>
                <ThumbnailImg
                    src={uploaded_image_sets[0] ? uploaded_image_sets[0].original_image_url : PlaceHolderImage}
                />
                {StoreOrderStatusText(shop)}
            </ThumbnailWrapper>
            <ContentContainer>
                <Title>{name}</Title>
                <Address>{address}</Address>
                {distance && (
                    <Distance>{distance > 1000 ? (distance / 1000).toFixed(1) + "k" : Math.floor(distance)}m</Distance>
                )}
            </ContentContainer>
        </StoreCardContainer>
    );
}
