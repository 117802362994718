import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarDateRangePicker from "@/components/BarDateRangePicker";
import AppLayout from "@/containers/AppLayout";
import PageInfoHeader from "@/components/PageInfoHeader";
import PointImage from "@/assets/icons/point.svg";
import { BackButton } from "@/components/IconButton";
import PointLogCard from "./PointLogCard";
import {
    getPointLogList,
    getPointLogListFetchState,
    getPointLogListPage,
    getPointLogListTotalPage,
} from "@/redux/pointLog/selectors";
import { fetchMorePointLogListThunk } from "@/redux/pointLog/thunk";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import { getToday } from "@/utils/time";
import { resetPointLogState } from "@/redux/pointLog/actions";
import { getAuthState } from "@/redux/auth/selectors";

export default function PointLog() {
    const dispatch = useDispatch();
    const pointLogListFetchState = useSelector(getPointLogListFetchState);
    const pointLogList = useSelector(getPointLogList);
    const pointLogListPage = useSelector(getPointLogListPage);
    const pointLogListTotalPage = useSelector(getPointLogListTotalPage);
    const userInfo = useSelector(getAuthState);
    const startDay = userInfo.user?.created_datetime ? new Date(userInfo.user?.created_datetime) : getToday();
    const [startDate, setStartDate] = React.useState<Date | null>(startDay);
    const [endDate, setEndDate] = React.useState<Date | null>(getToday());

    const handleFetchMore = useCallback(() => {
        dispatch(
            fetchMorePointLogListThunk({
                startDate,
                endDate,
            }),
        );
    }, [startDate, endDate]);

    const handleSetDate = (sd: Date | null, ed: Date | null) => {
        setStartDate(sd);
        setEndDate(ed);
    };

    useEffect(() => {
        if (!startDate || !endDate) {
            return;
        }

        dispatch(resetPointLogState());
        dispatch(
            fetchMorePointLogListThunk({
                startDate,
                endDate,
            }),
        );
    }, [startDate, endDate]);

    return (
        <AppLayout
            appBarBackgroundColor="#ff831e"
            disableAppBarBorder
            backgroundColor="#f7f7f7"
            leadingIcon={<BackButton />}
            titleColor="#fff"
            title="포인트"
        >
            <PageInfoHeader iconSrc={PointImage} title="포인트" description="포인트 세부내역을 확인할 수 있어요" />
            <BarDateRangePicker startDate={startDate} endDate={endDate} setDate={handleSetDate} />
            <InfiniteScrollList
                fetchState={pointLogListFetchState}
                list={pointLogList}
                page={pointLogListPage}
                totalPage={pointLogListTotalPage}
                showNoResultMessage
                disableInitialize
                noResultMessage="기록이 없어요"
                renderItem={({ title, amount, created_datetime }, i) => (
                    <PointLogCard key={i} title={title} amount={amount} date={new Date(created_datetime)} />
                )}
                onLoadMore={handleFetchMore}
            />
        </AppLayout>
    );
}
