import styled from "@emotion/styled";
import EndCircle from "@/components/EndCircle";

export const MenuImagePlaceholder = styled.div`
    display: inline-block;
    width: 64px;
    height: 64px;
`;

export const MenuInfoTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

export const MenuInfoDescription = styled.div`
    margin-top: 0.5rem;
`;

export const MenuGroupInfoTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const MenuGroup = styled.div`
    margin-bottom: 1rem;
    border: 1px solid #00000020;
    border-radius: 0.25rem;
    overflow: hidden;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const MenuGroupHeader = styled.div`
    display: flex;
    border-bottom: 1px solid #00000020;
`;

export const MenuGroupHeaderLeft = styled.div`
    display: flex;
    flex-grow: 1;
    padding: 1rem;
`;

export const MenuGroupHeaderTitle = styled.div`
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 2rem;
`;

export const MenuGroupHeaderDescription = styled.div`
    flex-grow: 1;
    margin-left: 0.5rem;
    color: #00000080;
    line-height: 2rem;
`;

export const MenuGroupHeaderRight = styled.div`
    padding: 1rem;
    line-height: 2rem;
`;

export const MenuGroupContent = styled.div`
    padding: 1rem;
`;

export const ModalSearchBarContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const ModalShopTitle = styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

export const UnavailableShopContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
    line-height: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const UnavailableShopName = styled.div`
    flex-grow: 1;
    margin-right: 0.5rem;
    line-height: 2rem;
`;

export const NutritionFactContainer = styled.div`
    margin-bottom: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const NutritionFactTitleContainer = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
`;

export const ImageWrapper = styled.div`
    width: 64px;
    height: 64px;
    position: relative;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const StyledEndCircle = styled(EndCircle)`
    width: 60px;
    height: 60px;
    font-size: 14px;
    color: #ff831e;
    line-height: 1rem;
`;
