import { posSlice } from "./slice";

const actions = posSlice.actions;

export const {
    posLoginFetchRequest,
    posLoginFetchSuccess,
    posLoginFetchFailure,
    setPosLoginUser,
    setPosLoginShop,
    posGetShopFetchRequest,
    posGetShopFetchSuccess,
    posGetShopFetchFailure,
    setPosGetShopFetchState,
    setPosLoginFetchState,
    reset,
} = actions;
