import styled from "@emotion/styled";

export const MenuImagePlaceholder = styled.div`
    display: inline-block;
    width: 64px;
    height: 64px;
`;

export const MenuInfoTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

export const MenuInfoDescription = styled.div`
    margin-top: 0.5rem;
`;

export const MenuGroupInfoTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const MenuDraggableContainer = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const MenuDraggable = styled.li`
    display: flex;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #00000020;
    border-radius: 0.25rem;
    overflow: hidden;
    user-select: none;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const MenuDraggableCheckboxWrapper = styled.div`
    line-height: 5rem;
    margin-right: 0.5rem;
`;

export const MenuDraggableImageWrapper = styled.div`
    line-height: 5rem;
    margin-right: 0.5rem;
`;

export const MenuDraggableTitle = styled.div`
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 5rem;
`;

export const MenuDraggableDescription = styled.div`
    flex-grow: 1;
    color: #00000080;
    line-height: 5rem;
`;

export const ModalSearchBarContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const ModalShopTitle = styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

export const UnavailableShopContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
    line-height: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const UnavailableShopName = styled.div`
    flex-grow: 1;
    margin-right: 0.5rem;
    line-height: 2rem;
`;

export const NutritionFactContainer = styled.div`
    margin-bottom: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const NutritionFactTitleContainer = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
`;

export const SubscriptionContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
    line-height: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const SubscriptionName = styled.div`
    flex-grow: 1;
    margin-right: 0.5rem;
    line-height: 2rem;
`;

export const OrderOptionGroupContainer = styled.div`
    margin-bottom: 2rem;
`;

export const OrderOptionCheckboxContainer = styled.div`
    text-align: right;
    margin-bottom: 0.5rem;
`;

export const OrderOptionNameContainer = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
`;

export const OrderOptionOptionsContainer = styled.div`
    display: flex;
    padding-left: 2rem;
    margin-bottom: 0.5rem;
`;
