import React from "react";
import { Button, Checkbox, Col, Form, Input, InputNumber, message, Modal, Row, Select, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import {
    MenuOptionGroup,
    MenuOrderByColumn,
    MenuResponse,
    NutritionFact,
    ShopResponse,
    SubscriptionResponse,
} from "@ferda/rest-api-client";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";
import { getOauth2TokenFromLocalStorage } from "@/redux/auth/utils";
import { menuOrderByColumnToText } from "@/utils/enumToText";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import FormItem from "@/components/Admin/FormItem";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import { Clear, Left, Right } from "@/components/Admin/Float";
import {
    MenuImagePlaceholder,
    MenuInfoDescription,
    MenuInfoTitle,
    ModalSearchBarContainer,
    ModalShopTitle,
    NutritionFactContainer,
    NutritionFactTitleContainer,
    OrderOptionCheckboxContainer,
    OrderOptionGroupContainer,
    OrderOptionNameContainer,
    OrderOptionOptionsContainer,
    SubscriptionContainer,
    SubscriptionName,
    UnavailableShopContainer,
    UnavailableShopName,
} from "./MenusStyled";
import { TotalCountWrapper } from "@/components/Admin/Misc";

const { Option } = Select;
const { TextArea } = Input;

interface Props {}

export default function Page(props: Props) {
    const params = useParams<{ groupId: string }>();
    const history = useHistory();

    const { serviceApi, shopApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [token, setToken] = React.useState<string | undefined>();

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalMenu, setModalMenu] = React.useState<MenuResponse | undefined>();
    const [modalEditFiles, setModalEditFiles] = React.useState<Array<UploadFile<any>>>([]);
    const [modalEditUnavailableShops, setModalEditUnavailableShops] = React.useState<ShopResponse[]>([]);
    const [modalEditNutritionFacts, setModalEditNutritionFacts] = React.useState<NutritionFact[]>([]);
    const [modalEditSubscriptions, setModalEditSubscriptions] = React.useState<SubscriptionResponse[]>([]);
    const [modalEditOrderOptions, setModalEditOrderOptions] = React.useState<MenuOptionGroup[]>([]);
    const [modalShopList, setModalShopList] = React.useState<ShopResponse[]>([]);
    const [modalShopListTotal, setModalShopListTotal] = React.useState<number>(0);
    const [modalShopListPage, setModalShopListPage] = React.useState<number>(1);
    const [modalShopKeyword, setModalShopKeyword] = React.useState<string | undefined>();
    const [modalSubscriptionList, setModalSubscriptionList] = React.useState<SubscriptionResponse[]>([]);

    const [menuList, setMenuList] = React.useState<MenuResponse[]>([]);
    const [menuListTotal, setMenuListTotal] = React.useState<number>(0);

    const [order, setOrder] = React.useState<MenuOrderByColumn | undefined>();
    const [keyword, setKeyword] = React.useState<string | undefined>();
    const [page, setPage] = React.useState<number>(1);

    const [searchForm] = Form.useForm();
    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();
    const [editShopForm] = Form.useForm();

    const columns = [
        {
            title: "사진",
            key: "image",
            render: (record: MenuResponse) => {
                return {
                    children: record.uploaded_image_set ? (
                        <img src={record.uploaded_image_set.original_image_url} width={64} height={64} />
                    ) : (
                        <MenuImagePlaceholder />
                    ),
                };
            },
            width: "100px",
        },
        {
            title: "정보",
            key: "info",
            render: (record: MenuResponse) => {
                return {
                    children: (
                        <>
                            <MenuInfoTitle onClick={() => history.push(`/admin/menus/view/${record.id}`)}>
                                {record.name}
                            </MenuInfoTitle>
                            <MenuInfoDescription>{record.description}</MenuInfoDescription>
                        </>
                    ),
                };
            },
        },
        {
            title: "가격",
            key: "price",
            render: (record: MenuResponse) => {
                return {
                    children: record.price.toLocaleString("ko") + "원",
                };
            },
            width: "100px",
        },
        {
            title: "관리",
            key: "manage",
            render: (record: MenuResponse) => {
                return {
                    children: (
                        <>
                            <Button
                                disabled={loading}
                                onClick={() => {
                                    setModal("edit");
                                    setModalMenu(record);
                                    setModalEditUnavailableShops(record.order_unavailable_shops);
                                    setModalEditNutritionFacts(record.nutrition_facts);
                                    setModalEditSubscriptions(record.order_available_subscriptions);
                                    setModalEditOrderOptions(record.option_groups);
                                    if (record.uploaded_image_set) {
                                        setModalEditFiles([
                                            {
                                                uid: record.uploaded_image_set.id.toString(),
                                                url: record.uploaded_image_set.original_image_url,
                                                status: "done",
                                                name: "메뉴 사진",
                                            },
                                        ]);
                                    } else {
                                        setModalEditFiles([]);
                                    }
                                    editForm.setFieldsValue({
                                        name: record.name,
                                        description: record.description,
                                        price: record.price,
                                        size: record.size,
                                        temperature: record.temperature_selectable ? "TRUE" : "FALSE",
                                        singleOrder: record.single_orderable ? "TRUE" : "FALSE",
                                    });
                                }}
                                style={{ marginRight: "0.5rem" }}
                            >
                                수정
                            </Button>
                            <Button
                                danger
                                disabled={loading}
                                onClick={() => {
                                    setModal("delete");
                                    setModalMenu(record);
                                }}
                            >
                                삭제
                            </Button>
                        </>
                    ),
                };
            },
            width: "160px",
        },
    ];

    const editShopColumns = [
        {
            title: "사진",
            key: "image",
            render: (record: ShopResponse) => {
                return {
                    children: record.uploaded_image_sets[0] && (
                        <img src={record.uploaded_image_sets[0].original_image_url} width={64} height={64} />
                    ),
                };
            },
            width: "100px",
        },
        {
            title: "정보",
            key: "info",
            render: (record: ShopResponse) => {
                return {
                    children: (
                        <>
                            <ModalShopTitle>{record.name}</ModalShopTitle>
                            <p>
                                {record.address} {record.address_detail}
                            </p>
                        </>
                    ),
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: ShopResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                if (modalEditUnavailableShops.find((shop) => shop.id === record.id)) {
                                    setModal("edit");
                                    return;
                                }

                                const shops = [...modalEditUnavailableShops];
                                shops.push(record);
                                setModalEditUnavailableShops(shops);
                                setModal("edit");
                            }}
                        >
                            선택
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    const editSubscriptionsColumn = [
        {
            title: "이름",
            key: "name",
            render: (record: SubscriptionResponse) => {
                return {
                    children: record.name,
                };
            },
            width: "300px",
        },
        {
            title: "가격",
            key: "price",
            render: (record: SubscriptionResponse) => {
                return {
                    children: record.price.toLocaleString("ko") + "원",
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: SubscriptionResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                if (modalEditSubscriptions.find((subscription) => subscription.id === record.id)) {
                                    setModal("edit");
                                    return;
                                }

                                const subscriptions = [...modalEditSubscriptions];
                                subscriptions.push(record);
                                setModalEditSubscriptions(subscriptions);
                                setModal("edit");
                            }}
                        >
                            선택
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    React.useEffect(() => {
        const tokens = getOauth2TokenFromLocalStorage();
        if (!tokens) {
            history.push("/admin/login");
            message.error("회원 정보를 불러오지 못하였습니다.");
            return;
        }
        setToken(tokens.token);
    }, []);

    React.useEffect(() => {
        setLoading(true);
        serviceApi
            .getSubscriptions()
            .then((res) => {
                setLoading(false);
                setModalSubscriptionList(res.data);
            })
            .catch(() => {
                setLoading(false);
                message.error("구독권 목록을 불러오는데 실패하였습니다.");
            });
    }, []);

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getMenus(parseInt(params.groupId, 10), keyword, false, undefined, order, page)
            .then((res) => {
                setLoading(false);
                setMenuList(res.data);
                setMenuListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("메뉴 목록을 불러오는데 실패하였습니다.");
            });
    }, [order, keyword, page, rerender]);

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getShops(undefined, undefined, modalShopKeyword, undefined, undefined, modalShopListPage)
            .then((res) => {
                setLoading(false);
                setModalShopList(res.data);
                setModalShopListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("가맹점 목록을 불러오는데 실패하였습니다.");
            });
    }, [modalShopKeyword, modalShopListPage]);

    return (
        <>
            <PageTitle>메뉴 관리</PageTitle>
            <Container>
                <Form
                    form={searchForm}
                    onFinish={(values) => {
                        const order = values.order || undefined;
                        const keyword = values.keyword || undefined;

                        setOrder(order);
                        setKeyword(keyword);
                        setPage(1);
                    }}
                >
                    <Left>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="keyword">
                                    <Input disabled={loading} placeholder="검색어" style={{ width: "300px" }} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="order">
                                    <Select disabled={loading} placeholder="정렬" style={{ width: "200px" }}>
                                        <Option value={MenuOrderByColumn.ORDER_PRIORITY_ASC}>
                                            {menuOrderByColumnToText(MenuOrderByColumn.ORDER_PRIORITY_ASC)}
                                        </Option>
                                        <Option value={MenuOrderByColumn.PRICE_ASC}>
                                            {menuOrderByColumnToText(MenuOrderByColumn.PRICE_ASC)}
                                        </Option>
                                        <Option value={MenuOrderByColumn.PRICE_DESC}>
                                            {menuOrderByColumnToText(MenuOrderByColumn.PRICE_DESC)}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Left>
                    <Right>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        htmlType="reset"
                                        onClick={() => {
                                            searchForm.resetFields();

                                            setOrder(undefined);
                                            setKeyword(undefined);
                                            setPage(1);
                                        }}
                                    >
                                        초기화
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "1.5rem" }}>
                                <Form.Item>
                                    <Button disabled={loading} htmlType="submit">
                                        검색
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        onClick={() => {
                                            history.push(`/admin/menus/view/${parseInt(params.groupId, 10)}/order`);
                                        }}
                                    >
                                        메뉴 순서 변경
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        onClick={() => {
                                            history.push(`/admin/menus/view/${parseInt(params.groupId, 10)}/recommend`);
                                        }}
                                    >
                                        추천 메뉴 순서 변경
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        onClick={() => {
                                            setModal("add");
                                            addForm.resetFields();
                                        }}
                                    >
                                        추가
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Right>
                    <Clear />
                </Form>
            </Container>
            <Container>
                <TotalCountWrapper>메뉴 수 : {menuListTotal.toLocaleString("ko")}</TotalCountWrapper>
                <Table columns={columns} dataSource={menuList} loading={loading} rowKey="id" />
                {menuListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={menuListTotal}
                    />
                )}
            </Container>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    form: "addForm",
                    htmlType: "submit",
                }}
                okText="추가"
                onCancel={() => setModal(undefined)}
                title="메뉴 추가"
                visible={modal === "add"}
                width={800}
            >
                <Form
                    form={addForm}
                    name="addForm"
                    onFinish={(values) => {
                        if (!values.name) {
                            return message.error("이릅을 입력해주세요.", 1.5);
                        }
                        if (values.price === undefined) {
                            return message.error("가격을 입력해주세요.", 1.5);
                        }
                        if (!values.size) {
                            return message.error("사이즈를 입력해주세요.", 1.5);
                        }
                        if (!values.temperature) {
                            return message.error("온도 선택 가능 여부를 선택해주세요.", 1.5);
                        }

                        setLoading(true);
                        shopApi
                            .createMenu({
                                menu_group_id: parseInt(params.groupId, 10),
                                name: values.name,
                                price: values.price,
                                size: values.size,
                                temperature_selectable: values.temperature === "TRUE",
                            })
                            .then(() => {
                                setModal(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("메뉴를 추가하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="메뉴 이름" name="name">
                        <Input disabled={loading} placeholder="메뉴 이름" />
                    </FormItem>
                    <FormItem label="메뉴 가격" name="price">
                        <InputNumber disabled={loading} min={0} placeholder="메뉴 가격" style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem label="사이즈" name="size">
                        <Input disabled={loading} placeholder="사이즈" />
                    </FormItem>
                    <FormItem label="온도 선택 가능 여부" name="temperature">
                        <Select disabled={loading} placeholder="온도" style={{ width: "100%" }}>
                            <Option value="TRUE">가능</Option>
                            <Option value="FALSE">불가능</Option>
                        </Select>
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                }}
                okText="삭제"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!modalMenu) {
                        return message.error("메뉴를 선택해주세요.", 1.5);
                    }

                    setLoading(true);
                    shopApi
                        .deleteMenu(modalMenu.id)
                        .then(() => {
                            setModal(undefined);
                            setModalMenu(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("메뉴를 삭제하였습니다.", 1.5);
                        })
                        .catch((error) => {
                            setLoading(false);
                            message.error(error.response.data.detail, 1.5);
                        });
                }}
                title="메뉴 삭제"
                visible={modal === "delete"}
                width={800}
            >
                메뉴를 삭제하시겠습니까?
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    form: "editForm",
                    htmlType: "submit",
                }}
                okText="수정"
                onCancel={() => setModal(undefined)}
                title="메뉴 수정"
                visible={modal === "edit"}
                width={800}
            >
                <Form
                    form={editForm}
                    name="editForm"
                    onFinish={(values) => {
                        if (!modalMenu) {
                            return message.error("메뉴를 선택해주세요.", 1.5);
                        }
                        if (!values.name) {
                            return message.error("이릅을 입력해주세요.", 1.5);
                        }
                        if (values.price === undefined) {
                            return message.error("가격을 입력해주세요.", 1.5);
                        }
                        if (!values.size) {
                            return message.error("사이즈를 입력해주세요.", 1.5);
                        }
                        if (!values.temperature) {
                            return message.error("온도 선택 가능 여부를 선택해주세요.", 1.5);
                        }

                        for (let index = 0; index < modalEditNutritionFacts.length; index += 1) {
                            const nutritionFact = modalEditNutritionFacts[index];
                            if (!nutritionFact.name) {
                                return message.error("영양정보 이름을 입력해주세요.", 1.5);
                            }
                            if (!nutritionFact.value) {
                                return message.error("영양정보 내용을 입력해주세요.", 1.5);
                            }
                        }

                        for (let groupIndex = 0; groupIndex < modalEditOrderOptions.length; groupIndex += 1) {
                            const group = modalEditOrderOptions[groupIndex];
                            if (!group.name) {
                                return message.error("주문옵션 그룹 이름을 입력해주세요.", 1.5);
                            }
                            if (group.options.length === 0) {
                                return message.error("주문옵션 그룹이 비어있습니다.", 1.5);
                            }
                            for (let optionIndex = 0; optionIndex < group.options.length; optionIndex += 1) {
                                const option = group.options[optionIndex];
                                if (!option.name) {
                                    return message.error("주문옵션 이름을 입력해주세요.", 1.5);
                                }
                                if (option.price === undefined) {
                                    return message.error("주문옵션 가격을 입력해주세요.", 1.5);
                                }
                            }
                        }

                        let fileId = null;
                        if (modalEditFiles.length > 0 && modalEditFiles[0].status === "done") {
                            if (modalEditFiles[0].response) {
                                fileId = modalEditFiles[0].response.id;
                            } else {
                                fileId = parseInt(modalEditFiles[0].uid, 10);
                            }
                        }

                        setLoading(true);
                        shopApi
                            .updateMenu(modalMenu.id, {
                                name: values.name,
                                price: values.price,
                                size: values.size,
                                description: values.description,
                                temperature_selectable: values.temperature === "TRUE",
                                single_orderable: values.singleOrder === "TRUE",
                                order_unavailable_shop_ids: modalEditUnavailableShops.map((shop) => shop.id),
                                nutrition_facts: modalEditNutritionFacts,
                                order_available_subscription_ids: modalEditSubscriptions.map(
                                    (subscription) => subscription.id,
                                ),
                                option_groups: modalEditOrderOptions,
                                uploaded_image_set_id: fileId,
                            })
                            .then(() => {
                                setModal(undefined);
                                setModalMenu(undefined);
                                setModalEditUnavailableShops([]);
                                setModalEditNutritionFacts([]);
                                setModalEditSubscriptions([]);
                                setModalEditOrderOptions([]);
                                setModalEditFiles([]);
                                setLoading(false);
                                setRerender(!rerender);
                                editForm.resetFields();
                                message.success("메뉴를 수정하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="메뉴 이름" name="name">
                        <Input disabled={loading} placeholder="메뉴 이름" />
                    </FormItem>
                    <FormItem label="메뉴 설명" name="description">
                        <Input disabled={loading} placeholder="메뉴 설명" />
                    </FormItem>
                    <FormItem label="가격" name="price">
                        <InputNumber disabled={loading} min={0} placeholder="가격" style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem label="사이즈" name="size">
                        <Input disabled={loading} placeholder="사이즈" />
                    </FormItem>
                    <FormItem label="온도 선택 가능 여부" name="temperature">
                        <Select disabled={loading} placeholder="온도" style={{ width: "100%" }}>
                            <Option value="TRUE">가능</Option>
                            <Option value="FALSE">불가능</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="단품 주문 가능 여부" name="singleOrder">
                        <Select disabled={loading} placeholder="단품" style={{ width: "100%" }}>
                            <Option value="TRUE">가능</Option>
                            <Option value="FALSE">불가능</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="주문 불가 가맹점">
                        {modalEditUnavailableShops.map((shop, shopIndex) => (
                            <UnavailableShopContainer key={shop.id}>
                                <UnavailableShopName>{shop.name}</UnavailableShopName>
                                <Button
                                    danger
                                    disabled={loading}
                                    onClick={() => {
                                        const shops = [...modalEditUnavailableShops];
                                        shops.splice(shopIndex, 1);
                                        setModalEditUnavailableShops(shops);
                                    }}
                                >
                                    삭제
                                </Button>
                            </UnavailableShopContainer>
                        ))}
                        <Button
                            onClick={() => {
                                setModal("edit-shop");
                            }}
                            style={{ width: "100%" }}
                        >
                            가맹점 추가
                        </Button>
                    </FormItem>
                    <FormItem label="해당 주문 가능 구독권">
                        {modalEditSubscriptions.map((subscription, subscriptionIndex) => (
                            <SubscriptionContainer key={subscription.id}>
                                <SubscriptionName>{subscription.name}</SubscriptionName>
                                <Button
                                    danger
                                    disabled={loading}
                                    onClick={() => {
                                        const subscriptions = [...modalEditSubscriptions];
                                        subscriptions.splice(subscriptionIndex, 1);
                                        setModalEditSubscriptions(subscriptions);
                                    }}
                                >
                                    삭제
                                </Button>
                            </SubscriptionContainer>
                        ))}
                        <Button
                            onClick={() => {
                                setModal("edit-subscription");
                            }}
                            style={{ width: "100%" }}
                        >
                            구독권 추가
                        </Button>
                    </FormItem>
                    <FormItem label="영양 정보">
                        {modalEditNutritionFacts.map((fact, index) => (
                            <NutritionFactContainer>
                                <NutritionFactTitleContainer>
                                    <Input
                                        defaultValue={fact.name}
                                        disabled={loading}
                                        placeholder="이름"
                                        onBlur={(event) => {
                                            const info = [...modalEditNutritionFacts];
                                            info[index].name = event.target.value;
                                            setModalEditNutritionFacts(info);
                                        }}
                                        style={{ marginRight: "0.5rem" }}
                                    />
                                    <Button
                                        danger
                                        disabled={loading}
                                        onClick={() => {
                                            const facts = [...modalEditNutritionFacts];
                                            facts.splice(index, 1);
                                            setModalEditNutritionFacts(facts);
                                        }}
                                    >
                                        삭제
                                    </Button>
                                </NutritionFactTitleContainer>
                                <TextArea
                                    defaultValue={fact.value}
                                    disabled={loading}
                                    placeholder="내용"
                                    onBlur={(event) => {
                                        const facts = [...modalEditNutritionFacts];
                                        facts[index].value = event.target.value;
                                        setModalEditNutritionFacts(facts);
                                    }}
                                    rows={2}
                                />
                            </NutritionFactContainer>
                        ))}
                        <Button
                            disabled={loading}
                            style={{ width: "100%" }}
                            onClick={() => {
                                const facts = [...modalEditNutritionFacts];
                                facts.push({
                                    name: "",
                                    value: "",
                                });
                                setModalEditNutritionFacts(facts);
                            }}
                        >
                            영양 정보 추가
                        </Button>
                    </FormItem>
                    <FormItem label="메뉴 추가옵션">
                        {modalEditOrderOptions.map((group, groupIndex) => (
                            <OrderOptionGroupContainer key={groupIndex}>
                                <OrderOptionCheckboxContainer>
                                    복수 선택 가능
                                    <Checkbox
                                        disabled={loading}
                                        checked={group.multiple_selection_possible}
                                        onChange={(event) => {
                                            const groups = [...modalEditOrderOptions];
                                            groups[groupIndex].multiple_selection_possible = event.target.checked;
                                            setModalEditOrderOptions(groups);
                                        }}
                                        style={{ marginLeft: "0.5rem" }}
                                    />
                                </OrderOptionCheckboxContainer>
                                <OrderOptionNameContainer>
                                    <Input
                                        disabled={loading}
                                        placeholder="그룹 이름"
                                        onBlur={(event) => {
                                            const groups = [...modalEditOrderOptions];
                                            groups[groupIndex].name = event.target.value;
                                            setModalEditOrderOptions(groups);
                                        }}
                                        style={{ marginRight: "0.5rem" }}
                                        defaultValue={group.name}
                                    />
                                    <Button
                                        danger
                                        disabled={loading}
                                        onClick={() => {
                                            const groups = [...modalEditOrderOptions];
                                            groups.splice(groupIndex, 1);
                                            setModalEditOrderOptions(groups);
                                        }}
                                    >
                                        삭제
                                    </Button>
                                </OrderOptionNameContainer>
                                {group.options.map((option, optionIndex) => (
                                    <OrderOptionOptionsContainer key={optionIndex}>
                                        <Input
                                            disabled={loading}
                                            placeholder="이름"
                                            onBlur={(event) => {
                                                const groups = [...modalEditOrderOptions];
                                                groups[groupIndex].options[optionIndex].name = event.target.value;
                                                setModalEditOrderOptions(groups);
                                            }}
                                            style={{ marginRight: "0.5rem" }}
                                            defaultValue={option.name}
                                        />
                                        <InputNumber
                                            disabled={loading}
                                            placeholder="가격"
                                            onBlur={(event) => {
                                                const groups = [...modalEditOrderOptions];
                                                groups[groupIndex].options[optionIndex].price = parseInt(
                                                    event.target.value,
                                                    10,
                                                );
                                                setModalEditOrderOptions(groups);
                                            }}
                                            style={{ width: "200px", marginRight: "0.5rem" }}
                                            defaultValue={option.price}
                                        />
                                        <Button
                                            danger
                                            disabled={loading}
                                            onClick={() => {
                                                const groups = [...modalEditOrderOptions];
                                                groups[groupIndex].options.splice(optionIndex, 1);
                                                setModalEditOrderOptions(groups);
                                            }}
                                        >
                                            삭제
                                        </Button>
                                    </OrderOptionOptionsContainer>
                                ))}
                                <Button
                                    onClick={() => {
                                        const groups = [...modalEditOrderOptions];
                                        groups[groupIndex].options.push({
                                            name: "",
                                            price: 0,
                                        });
                                        setModalEditOrderOptions(groups);
                                    }}
                                    style={{ marginLeft: "2rem", width: "calc(100% - 2rem)" }}
                                >
                                    주문옵션 추가
                                </Button>
                            </OrderOptionGroupContainer>
                        ))}
                        <Button
                            onClick={() => {
                                const groups = [...modalEditOrderOptions];
                                groups.push({
                                    name: "",
                                    options: [],
                                    multiple_selection_possible: false,
                                });
                                setModalEditOrderOptions(groups);
                            }}
                            style={{ width: "100%" }}
                        >
                            주문옵션 그룹 추가
                        </Button>
                    </FormItem>
                    <FormItem label="사진">
                        <Upload
                            action={`${process.env.REACT_APP_REST_API_BASE_URL}/uploaded-image-sets/upload-image`}
                            beforeUpload={(file) => {
                                if (file.type === "image/png" || file.type === "image/jpeg") {
                                    return true;
                                } else {
                                    message.error("사진 형식의 파일을 업로드해주세요.", 1.5);
                                    return Upload.LIST_IGNORE;
                                }
                            }}
                            fileList={modalEditFiles}
                            headers={{ Authorization: `bearer ${token}` }}
                            listType="picture"
                            maxCount={1}
                            name="image"
                            onChange={({ fileList }) => {
                                setModalEditFiles(fileList);
                            }}
                            onRemove={(file) => {
                                const files = [...modalEditFiles];
                                const index = files.findIndex((f) => f.uid === file.uid);

                                if (index === -1) {
                                    return;
                                }

                                files.splice(index, 1);
                                setModalEditFiles(files);
                            }}
                        >
                            <Button icon={<UploadOutlined />}>사진 업로드</Button>
                        </Upload>
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    style: { display: "none" },
                }}
                onCancel={() => setModal("edit")}
                title="가맹점 선택"
                visible={modal === "edit-shop"}
                width={800}
            >
                <Form
                    form={editShopForm}
                    onFinish={(values) => {
                        if (!values.keyword) {
                            return message.error("검색어를 입력해주세요.", 1.5);
                        }

                        setModalShopKeyword(values.keyword);
                        setModalShopListPage(1);
                    }}
                >
                    <ModalSearchBarContainer>
                        <Form.Item name="keyword" style={{ marginBottom: "0", marginRight: "0.5rem", width: "100%" }}>
                            <Input placeholder="검색어" style={{ width: "100%" }} />
                        </Form.Item>
                        <Button disabled={loading} htmlType="submit">
                            검색
                        </Button>
                    </ModalSearchBarContainer>
                </Form>
                <Table columns={editShopColumns} dataSource={modalShopList} loading={loading} rowKey="id" />
                {modalShopListTotal > 0 && (
                    <Pagination
                        current={modalShopListPage}
                        disabled={loading}
                        onChange={(page: number) => setModalShopListPage(page)}
                        pageSize={20}
                        total={modalShopListTotal}
                    />
                )}
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    style: { display: "none" },
                }}
                onCancel={() => setModal("edit")}
                title="구독권 선택"
                visible={modal === "edit-subscription"}
                width={800}
            >
                <Table
                    columns={editSubscriptionsColumn}
                    dataSource={modalSubscriptionList}
                    loading={loading}
                    rowKey="id"
                />
            </Modal>
        </>
    );
}
