import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CounterButton, CounterText, CounterWrapper } from "./style";

interface CounterProps {
    value: number;
    setValue: (value: number) => void;
    maxSize?: number;
}

export default function Counter({ value, maxSize, setValue }: CounterProps) {
    const handleClickCounterButton = (target: 1 | -1) => {
        const result = value + target;

        if (result < 1) {
            return;
        }

        if (maxSize && result > maxSize) {
            return;
        }

        setValue(result);
    };

    return (
        <CounterWrapper>
            <CounterButton
                disabled={value <= 1}
                onClick={() => handleClickCounterButton(-1)}
                src={<RemoveCircleOutlineIcon />}
                alt="remove button"
            />
            <CounterText>{value}</CounterText>
            <CounterButton
                disabled={typeof maxSize === "number" && value >= maxSize}
                onClick={() => handleClickCounterButton(1)}
                src={<AddCircleOutlineIcon />}
                alt="add button"
            />
        </CounterWrapper>
    );
}
