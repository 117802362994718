import React from "react";
import styled from "@emotion/styled";
import { Button, Form, Input } from "antd";
import LogoImage from "@/assets/img/logo_color.png";
import FormItem from "../FormItem";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    background: #ffffff;
`;

const LoginContainer = styled.div`
    padding: 1rem;
    border: 1.5px solid #00000012;
    border-radius: 0.25rem;
    box-sizing: border-box;
    width: calc(100vmin - 2rem);
    max-width: 480px;
    background: #ffffff;
    overflow: hidden;
`;

const ImageContainer = styled.div`
    padding: 1rem 0;
    margin-bottom: 2rem;
    text-align: center;
`;

const LoginHeader = styled.div`
    margin-bottom: 1rem;

    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
    text-align: center;
`;

interface FormData {
    username: string;
    password: string;
}

interface Props {
    onSubmit: (values: FormData) => void;
}

interface FormType {
    username: string;
    password: string;
}

export default function ShopLoginForm(props: Props) {
    const [form] = Form.useForm<FormType>();

    return (
        <Container>
            <LoginContainer>
                <ImageContainer>
                    <img src={LogoImage} style={{ width: "10rem" }} />
                </ImageContainer>
                <LoginHeader>가맹점 로그인</LoginHeader>
                <Form form={form} onFinish={props.onSubmit}>
                    <FormItem label="아이디" name="username" style={{ marginBottom: "1rem" }}>
                        <Input placeholder="아이디" required />
                    </FormItem>
                    <FormItem label="비밀번호" name="password" style={{ marginBottom: "1rem" }}>
                        <Input placeholder="비밀번호" type="password" required />
                    </FormItem>
                    <FormItem style={{ textAlign: "center" }}>
                        <Button htmlType="submit" type="primary" style={{ width: "100%" }}>
                            로그인
                        </Button>
                    </FormItem>
                </Form>
            </LoginContainer>
        </Container>
    );
}
