import Axios from "axios";
import { Oauth2Api, UserApi, VerificationApi, CommonApi, PostApi, ServiceApi, ShopApi } from "@ferda/rest-api-client";
import { Oauth2Token, RestApiClient } from "./types";

const OAUTH2_TOKEN_LOCAL_STORAGE_KEY = "oauth2_token";
const OAUTH2_TOKEN_EXPIRE_DATE_LOCAL_STORAGE_KEY = "oauth2_token_expire_date";
const OAUTH2_REFRESH_TOKEN_LOCAL_STORAGE_KEY = "oauth2_refresh_token";
const OAUTH2_REFRESH_TOKEN_EXPIRE_DATE_LOCAL_STORAGE_KEY = "oauth2_refresh_token_expire_date";

export function getOauth2TokenFromLocalStorage() {
    const token = localStorage.getItem(OAUTH2_TOKEN_LOCAL_STORAGE_KEY);
    const tokenExpireDate = localStorage.getItem(OAUTH2_TOKEN_EXPIRE_DATE_LOCAL_STORAGE_KEY);
    const refreshToken = localStorage.getItem(OAUTH2_REFRESH_TOKEN_LOCAL_STORAGE_KEY);
    const refreshTokenExpireDate = localStorage.getItem(OAUTH2_REFRESH_TOKEN_EXPIRE_DATE_LOCAL_STORAGE_KEY);

    if (token === null || tokenExpireDate === null || refreshToken === null || refreshTokenExpireDate === null) {
        return;
    }

    const oauth2Token: Oauth2Token = {
        token,
        tokenExpireDate: new Date(tokenExpireDate),
        refreshToken,
        refreshTokenExpireDate: new Date(tokenExpireDate),
    };

    return oauth2Token;
}

export function saveOauth2TokenToLocalStorage({
    token,
    tokenExpireDate,
    refreshToken,
    refreshTokenExpireDate,
}: Oauth2Token) {
    localStorage.setItem(OAUTH2_TOKEN_LOCAL_STORAGE_KEY, token);
    localStorage.setItem(OAUTH2_TOKEN_EXPIRE_DATE_LOCAL_STORAGE_KEY, tokenExpireDate.getTime().toString());
    localStorage.setItem(OAUTH2_REFRESH_TOKEN_LOCAL_STORAGE_KEY, refreshToken);
    localStorage.setItem(
        OAUTH2_REFRESH_TOKEN_EXPIRE_DATE_LOCAL_STORAGE_KEY,
        refreshTokenExpireDate.getTime().toString(),
    );
}

export function clearOauth2Token() {
    localStorage.removeItem(OAUTH2_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(OAUTH2_TOKEN_EXPIRE_DATE_LOCAL_STORAGE_KEY);
    localStorage.removeItem(OAUTH2_REFRESH_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(OAUTH2_REFRESH_TOKEN_EXPIRE_DATE_LOCAL_STORAGE_KEY);
}

export function getAxios(oauth2Token?: Oauth2Token) {
    const axios = Axios.create();

    if (!oauth2Token) {
        return axios;
    }

    axios.interceptors.request.use(async (config) => {
        const { headers } = config;

        headers.Authorization = `bearer ${oauth2Token.token}`;

        return config;
    });

    return axios;
}

export function isJsonMime(mime: string): boolean {
    const jsonMime = new RegExp("^(application/json|[^;/ \t]+/[^;/ \t]+[+]json)[ \t]*(;.*)?$", "i");
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === "application/json-patch+json");
}

export function makeRestApiClient(baseUrl: string, oauth2Token?: Oauth2Token): RestApiClient {
    const axios = getAxios(oauth2Token);

    const commonConfig = { isJsonMime };
    const commonArgs = [commonConfig, baseUrl, axios] as const;

    return {
        userApi: new UserApi(...commonArgs),
        oauth2Api: new Oauth2Api(...commonArgs),
        verificationApi: new VerificationApi(...commonArgs),
        commonApi: new CommonApi(...commonArgs),
        postApi: new PostApi(...commonArgs),
        serviceApi: new ServiceApi(...commonArgs),
        shopApi: new ShopApi(...commonArgs),
    };
}
