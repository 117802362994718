import { Backdrop, circularProgressClasses } from "@mui/material";
import { differenceInSeconds } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "@/components/CustomLink";
import { getHoldSubscriptionFetchState, getSubscribeStatus } from "@/redux/subscription/selectors";
import {
    StyledButton,
    SubscribeInfoContainer,
    StateTitleContainer,
    StateCircleContainer,
    InnerBlackCircle,
    InnerDarkCircle,
    InnerCircle,
    StyledCircularProgress,
    CircleContentContainer,
    DescriptionContainer,
    DescriptionRow,
    CoffeeCircle,
    CoffeeIconImg,
    TimeLeftContainer,
    TimeLeftTime,
} from "./style";
import CoffeeIconImage from "@/assets/img/ic-beverage-small.svg";
import Modal from "@/components/Modal";
import { AlertModalText } from "@/pages/Gift/GiftGetDetail/style";
import { fetchHoldSubscriptionThunk } from "@/redux/subscription/thunk";
import { resetHoldSubscriptionFetchState } from "@/redux/subscription/actions";

export default function SubscribeInfo() {
    const [now, setNow] = useState(new Date());
    const subscribeStatus = useSelector(getSubscribeStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const timeLeft = useMemo(() => {
        if (!subscribeStatus || !subscribeStatus.next_order_available_datetime) {
            return 60 * 60 * 3;
        }

        const diff = differenceInSeconds(new Date(subscribeStatus.next_order_available_datetime), now);

        if (diff < 0) {
            return 0;
        }

        return diff;
    }, [subscribeStatus, now]);

    const stateTitle = useMemo(() => {
        if (!subscribeStatus || !subscribeStatus.subscription_in_use) {
            return "워크무드를 구독해보세요!";
        }

        if (subscribeStatus.hold) {
            return "지금은 홀딩 중이에요.";
        }

        if (subscribeStatus.can_order) {
            return "지금 음료를 주문할 수 있어요!";
        }

        if (subscribeStatus.next_order_available_datetime) {
            return "다음 음료 주문까지";
        }

        return "";
    }, [subscribeStatus]);

    const circleValue = useMemo(() => {
        if (!subscribeStatus || !subscribeStatus.subscription_in_use) {
            return 0;
        }

        if (subscribeStatus.can_order) {
            return 100;
        }

        if (subscribeStatus.next_order_available_datetime) {
            if (!subscribeStatus.last_order) {
                return 100;
            }

            const interval = differenceInSeconds(
                new Date(subscribeStatus.next_order_available_datetime),
                new Date(subscribeStatus.last_order.created_datetime),
            );

            return 100 - (timeLeft / interval) * 100;
        }

        return 0;
    }, [subscribeStatus, timeLeft]);

    const [holdModalOpen, setHoldModalOpen] = useState(false);
    const holdSubscriptionFetchState = useSelector(getHoldSubscriptionFetchState);

    const handleSubscriptionHold = () => {
        dispatch(fetchHoldSubscriptionThunk({}));
    };

    useEffect(() => {
        if (holdSubscriptionFetchState !== "SUCCESS") {
            return;
        }

        setHoldModalOpen(false);
        dispatch(resetHoldSubscriptionFetchState());
    }, [holdSubscriptionFetchState]);

    return (
        <SubscribeInfoContainer>
            <StateTitleContainer>{stateTitle}</StateTitleContainer>
            <StateCircleContainer>
                <InnerBlackCircle glow={subscribeStatus?.can_order} />
                <InnerDarkCircle glow={subscribeStatus?.can_order} />
                <InnerCircle />
                <StyledCircularProgress
                    variant="determinate"
                    value={circleValue}
                    size={200}
                    thickness={1.5}
                    sx={{
                        color: "#ff831e",
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                        },
                    }}
                />
                <CircleContentContainer>
                    {!subscribeStatus || !subscribeStatus.subscription_in_use ? (
                        <>
                            <DescriptionContainer>
                                <DescriptionRow>부족함 없이 기다림 없이</DescriptionRow>
                                <DescriptionRow>즐겨보세요</DescriptionRow>
                            </DescriptionContainer>
                            <Link to="/service">
                                <StyledButton>구독하기 </StyledButton>
                            </Link>
                        </>
                    ) : (
                        <>
                            <CoffeeCircle>
                                <CoffeeIconImg src={CoffeeIconImage} />
                            </CoffeeCircle>
                            {!subscribeStatus.can_order && subscribeStatus.next_order_available_datetime && (
                                <TimeLeftContainer>
                                    <TimeLeftTime>{Math.floor(timeLeft / (60 * 60))}</TimeLeftTime>시간{" "}
                                    <TimeLeftTime>{Math.floor((timeLeft / 60) % 60)}</TimeLeftTime>분{" "}
                                    <TimeLeftTime>{timeLeft % 60}</TimeLeftTime>초
                                </TimeLeftContainer>
                            )}
                            {subscribeStatus.can_order && !subscribeStatus.hold ? (
                                <Link to="/order">
                                    <StyledButton>주문하기</StyledButton>
                                </Link>
                            ) : null}
                            <Modal
                                open={holdModalOpen}
                                handleClose={() => setHoldModalOpen(false)}
                                buttonOnClick={handleSubscriptionHold}
                                cancelButtonOnClick={() => setHoldModalOpen(false)}
                                titleText="오늘 하루 홀딩"
                                buttonText="사용하기"
                                cancelButtonText="취소"
                                fullSizeButton
                            >
                                <AlertModalText>
                                    홀딩 시 구독권 기간이 자동 하루 연장되며,
                                    <br />
                                    현 시각부터 24시간동안 구독권을 이용한 주문이 불가능합니다.
                                </AlertModalText>
                            </Modal>
                            <Backdrop style={{ zIndex: 999999 }} open={holdSubscriptionFetchState === "FETCHING"} />
                            {!subscribeStatus.hold && (
                                <StyledButton
                                    style={{ marginTop: "8px" }}
                                    onClick={() => {
                                        setHoldModalOpen(true);
                                    }}
                                >
                                    오늘 홀딩
                                </StyledButton>
                            )}
                        </>
                    )}
                </CircleContentContainer>
            </StateCircleContainer>
        </SubscribeInfoContainer>
    );
}
