import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import useHistory from "@/hooks/useCustomHistory";
import AppLayout from "@/containers/AppLayout";
import { BackButton } from "@/components/IconButton";
import {
    CheckAllCheckboxLabel,
    CheckboxLabel,
    CheckboxLabelLink,
    CheckboxLabelWrapper,
    CoffeeImage,
    CoffeeMenuItem,
    CoffeeMenuItems,
    CoffeeOption,
    CoffeePrice,
    CoffeePriceUnit,
    CoffeeTitle,
    CoffeeTitleWrapper,
    Content,
    ContentTitle,
    Divider,
    GiftOrderInput,
    PointAmountText,
    PointInputWrapper,
    PointModalText,
    PointText,
    PointUseButton,
    PriceItem,
    PriceItemDivider,
    PriceItemPrice,
    PriceItemTitle,
    PriceItemWrapper,
    TermsCheckbox,
    TotalPriceText,
    TotalPriceTextUnit,
    TotalPriceTitle,
    TotalPriceWrapper,
    Wrapper,
} from "./style";
import PlaceHolderImage from "@/assets/img/place_holder.png";
import { getUserAsAlwaysExists } from "@/redux/auth/selectors";
import { getSelectedMenu } from "@/redux/menu/selectors";
import {
    getGiftOrderFetchState,
    getGiftSubsFetchState,
    getOrderFetchState,
    getOrderStash,
} from "@/redux/order/selectors";
import { comma } from "@/utils/text";
import { fetchGiftOrderThunk, fetchGiftSubsThunk, fetchOrderThunk } from "@/redux/order/thunk";
import IamportLoader from "@/containers/IamportLoader";
import { getMenuOptionGroupTotalPrice } from "@/utils/menuOption";
import { BottomConfirmButtonContainer, ConfirmButton } from "@/components/Button/style";
import { getQueryStringObject } from "@/utils/global";
import { resetOrderState } from "@/redux/order/actions";
import { resetPaymentState } from "@/redux/payment/actions";
import Footer from "@/components/Footer";
import Modal from "@/components/Modal";
import { getSubscriptionList } from "@/redux/subscription/selectors";
import { FetchState } from "@/interfaces/fetch";
import { getSelectedShop } from "@/redux/shop/selectors";
import { BottomSpacer } from "@/components/Spacer";

interface GiftOrderField {
    name: string;
    phoneNumber: string;
    point: number;
    agreeAll: boolean;
    servicePolicy: boolean;
    privacyPolicy: boolean;
    locationPolicy: boolean;
}

interface GiftOrderProps {
    price: number;
    optionPrice?: number;
    amount: number;
    thumbnailImage?: string;
    orderCallback: (name: string, phoneNumber: string, point: number) => void;
    replaceUrl: string;
    getCompleteUrl: (name: string) => string;
    itemDescription: React.ReactNode;
    fetchState: FetchState;
    isGift?: boolean;
    title?: string;
}

export function GiftOrder({
    price,
    optionPrice = 0,
    amount,
    thumbnailImage,
    orderCallback,
    replaceUrl,
    getCompleteUrl,
    itemDescription,
    fetchState,
    isGift = true,
    title = "선물하기",
}: GiftOrderProps) {
    const history = useHistory();
    const user = useSelector(getUserAsAlwaysExists);
    const dispatch = useDispatch();
    const [pointAlertModal, setPointAlertModal] = useState(false);
    const [pointAlertModalText, setPointAlertModalText] = useState("");

    const { getFieldProps, setFieldValue, values, handleChange, handleSubmit } = useFormik<GiftOrderField>({
        initialValues: {
            name: "",
            phoneNumber: "",
            point: 0,
            agreeAll: false,
            servicePolicy: false,
            privacyPolicy: false,
            locationPolicy: false,
        },
        onSubmit: ({ point, phoneNumber, name }) => {
            if (price - point !== 0 && price - point < 100) {
                setPointAlertModalText(
                    "메뉴 금액에서 사용할 포인트를 제외한 금액이 100원 이상이여야 포인트 사용이 가능합니다.",
                );
                setPointAlertModal(true);
                return;
            }

            if (point > user.point) {
                setPointAlertModalText("보유하신 포인트 잔액이 부족합니다.");
                setPointAlertModal(true);
                return;
            }

            orderCallback(name, phoneNumber, Number(point));
        },
    });

    const handleClickUsedAllPoint = () => setFieldValue("point", Math.min(user.point, price));
    // Checkbox
    useEffect(() => {
        const { servicePolicy, privacyPolicy, locationPolicy } = values;

        setFieldValue("agreeAll", servicePolicy && privacyPolicy && locationPolicy);
    }, [values.servicePolicy, values.privacyPolicy, values.locationPolicy]);

    // Checkbox
    useEffect(() => {
        const { agreeAll, servicePolicy, privacyPolicy, locationPolicy } = values;

        if (agreeAll) {
            setFieldValue("servicePolicy", true);
            setFieldValue("privacyPolicy", true);
            setFieldValue("locationPolicy", true);
            return;
        }

        if (servicePolicy && privacyPolicy && locationPolicy) {
            setFieldValue("servicePolicy", false);
            setFieldValue("privacyPolicy", false);
            setFieldValue("locationPolicy", false);
        }
    }, [values.agreeAll]);

    const handlePointChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        const rawValue = event.target.value;
        const value = Math.min(user.point, Number(rawValue));

        event.target.value = Math.max(Math.min(price, value), 0).toString();

        handleChange(event);
    };

    const handleClosePointAlertModal = () => {
        setPointAlertModal(false);
        setPointAlertModalText("");
    };

    useEffect(() => {
        if (fetchState !== "SUCCESS") {
            return;
        }

        window.history.replaceState(null, "워크무드", replaceUrl);
        history.push(getCompleteUrl(values.name));
    }, [fetchState, history, values, replaceUrl, getCompleteUrl]);

    useEffect(() => {
        return () => {
            dispatch(resetOrderState());
            dispatch(resetPaymentState());
        };
    }, []);

    return (
        <AppLayout
            title={title}
            titleColor="#fff"
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            disableAppBarBorder
        >
            <Modal
                titleText="포인트 알림"
                open={pointAlertModal}
                handleClose={handleClosePointAlertModal}
                buttonText="확인"
                buttonOnClick={handleClosePointAlertModal}
                fullSizeButton
            >
                <PointModalText>{pointAlertModalText}</PointModalText>
            </Modal>
            <Wrapper onSubmit={handleSubmit}>
                <Content>
                    <ContentTitle>{isGift && "선물할 "}메뉴</ContentTitle>

                    <CoffeeMenuItems>
                        <CoffeeImage>
                            <img src={thumbnailImage ?? PlaceHolderImage} alt="thumbnail-img" />
                        </CoffeeImage>
                        {itemDescription}
                    </CoffeeMenuItems>
                </Content>
                <Divider />

                {isGift && (
                    <>
                        <Content>
                            <ContentTitle>선물 받으시는 분</ContentTitle>

                            <GiftOrderInput
                                placeholder="사용하실 분의 이름"
                                {...getFieldProps("name")}
                                marginBottom={12}
                            />
                            <GiftOrderInput
                                placeholder="사용하실 분의 휴대폰번호"
                                {...getFieldProps("phoneNumber")}
                                marginBottom={8}
                            />
                        </Content>
                        <Divider />
                    </>
                )}

                <Content>
                    <ContentTitle>포인트 사용</ContentTitle>

                    <PointInputWrapper>
                        <GiftOrderInput
                            maxNumber={Math.min(user.point, price)}
                            type="number"
                            {...getFieldProps("point")}
                            onChange={handlePointChange}
                            placeholder="0원"
                        />
                        <PointUseButton variant="contained" backgroundColor="#e2e2e2" onClick={handleClickUsedAllPoint}>
                            전액사용
                        </PointUseButton>
                    </PointInputWrapper>

                    <PointText>
                        보유 포인트 :<PointAmountText> {comma(user.point - Number(values.point))}P</PointAmountText>
                    </PointText>
                </Content>
                <Divider />

                <Content>
                    <ContentTitle>결제금액</ContentTitle>

                    <PriceItemWrapper>
                        <PriceItem>
                            <PriceItemTitle>상품 금액</PriceItemTitle>
                            <PriceItemPrice>{comma(price * amount)}원</PriceItemPrice>
                        </PriceItem>
                        {optionPrice > 0 && (
                            <PriceItem>
                                <PriceItemTitle>옵션 금액</PriceItemTitle>
                                <PriceItemPrice>{comma(optionPrice * amount)}원</PriceItemPrice>
                            </PriceItem>
                        )}
                        <PriceItem>
                            <PriceItemTitle>포인트 사용</PriceItemTitle>
                            <PriceItemPrice>-{comma(Number(values.point))}원</PriceItemPrice>
                        </PriceItem>
                    </PriceItemWrapper>
                    <PriceItemDivider />

                    <TotalPriceWrapper>
                        <TotalPriceTitle>최종 결제금액</TotalPriceTitle>
                        <TotalPriceText>
                            {comma(price * amount + optionPrice - Number(values.point))}
                            <TotalPriceTextUnit>원</TotalPriceTextUnit>
                        </TotalPriceText>
                    </TotalPriceWrapper>
                </Content>
                <Divider />

                <Content>
                    <TermsCheckbox
                        iconType="circle"
                        label={<CheckAllCheckboxLabel>전체 동의</CheckAllCheckboxLabel>}
                        {...getFieldProps("agreeAll")}
                    />
                    <TermsCheckbox
                        label={
                            <CheckboxLabelWrapper>
                                <CheckboxLabel>개인정보 제 3자 제공동의</CheckboxLabel>
                                <CheckboxLabelLink to="/policy">보기</CheckboxLabelLink>
                            </CheckboxLabelWrapper>
                        }
                        {...getFieldProps("servicePolicy")}
                    />
                    <TermsCheckbox
                        label={
                            <CheckboxLabelWrapper>
                                <CheckboxLabel>결제대행서비스 이용약관 동의</CheckboxLabel>
                                <CheckboxLabelLink to="/policy">보기</CheckboxLabelLink>
                            </CheckboxLabelWrapper>
                        }
                        {...getFieldProps("privacyPolicy")}
                    />
                    <TermsCheckbox
                        label={
                            <CheckboxLabelWrapper>
                                <CheckboxLabel>상품구매조건 확인 및 취소, 환불 규정 동의</CheckboxLabel>
                                <CheckboxLabelLink to="/policy">보기</CheckboxLabelLink>
                            </CheckboxLabelWrapper>
                        }
                        {...getFieldProps("locationPolicy")}
                    />
                </Content>

                <BottomConfirmButtonContainer>
                    <ConfirmButton
                        type="submit"
                        disabled={!values.agreeAll || (isGift && (!values.name || !values.phoneNumber))}
                        variant="contained"
                        backgroundColor="#ff831e"
                    >
                        결제하기
                    </ConfirmButton>
                </BottomConfirmButtonContainer>
            </Wrapper>
            <Footer />
            <BottomSpacer />
        </AppLayout>
    );
}

export default function GiftOrderWithIamportloader() {
    const selectedMenu = useSelector(getSelectedMenu);
    const orderStash = useSelector(getOrderStash);
    const history = useHistory();
    const { search } = useLocation();
    const query = useMemo(() => getQueryStringObject(search), [search]);
    const dispatch = useDispatch();
    const giftOrderFetchState = useSelector(getGiftOrderFetchState);

    useEffect(() => {
        if (!selectedMenu || !orderStash) {
            history.replace("/");
        }
    }, []);

    useEffect(() => {
        if (query.isCompleted) {
            history.push("/");
        }
    }, []);

    let emptyValue: number;

    const optionPrice = useMemo(
        () => (orderStash ? getMenuOptionGroupTotalPrice(orderStash.optionGroupList) : emptyValue),
        [orderStash],
    );

    const itemDescription = useMemo(() => {
        if (!orderStash || !selectedMenu) {
            return;
        }

        return (
            <CoffeeMenuItem>
                <CoffeeTitleWrapper>
                    <CoffeeTitle>{selectedMenu.name}</CoffeeTitle>
                    <CoffeeOption>
                        {orderStash.amount}잔 / {orderStash.temperatureType}
                    </CoffeeOption>
                </CoffeeTitleWrapper>
                <CoffeePrice>
                    {comma(selectedMenu.price)}
                    <CoffeePriceUnit>원</CoffeePriceUnit>
                </CoffeePrice>
            </CoffeeMenuItem>
        );
    }, [orderStash, selectedMenu]);

    const orderCallback = useCallback(
        (name: string, phoneNumber: string, point: number) => {
            if (giftOrderFetchState === "FETCHING" || giftOrderFetchState === "SUCCESS") {
                return;
            }
            if (!orderStash || !selectedMenu) {
                return;
            }
            dispatch(
                fetchGiftOrderThunk({
                    amount: orderStash.amount,
                    menuId: selectedMenu.id,
                    temperatureType: orderStash.temperatureType,
                    optionGroupList: orderStash.optionGroupList,
                    point: Number(point),
                    price: selectedMenu.price + optionPrice ?? 0,
                    name,
                    phoneNumber,
                    productName: selectedMenu.name,
                }),
            );
        },

        [selectedMenu, orderStash, giftOrderFetchState],
    );

    return (
        <IamportLoader>
            {selectedMenu && orderStash && (
                <GiftOrder
                    fetchState={giftOrderFetchState}
                    price={selectedMenu.price}
                    optionPrice={optionPrice}
                    amount={orderStash.amount}
                    thumbnailImage={selectedMenu.uploaded_image_set?.original_image_url}
                    orderCallback={orderCallback}
                    replaceUrl={`/gift/${selectedMenu.id}/order?isCompleted=true`}
                    getCompleteUrl={(name) => `/gift/${selectedMenu.id}/order/complete/${name}`}
                    itemDescription={itemDescription}
                />
            )}
        </IamportLoader>
    );
}

export function GiftSubsWithIamportloader() {
    const subscriptionList = useSelector(getSubscriptionList);
    const history = useHistory();
    const { search } = useLocation();
    const query = useMemo(() => getQueryStringObject(search), [search]);
    const dispatch = useDispatch();
    const { subsId: rawSubsId } = useParams<{ subsId: string }>();
    const giftSubsFetchState = useSelector(getGiftSubsFetchState);

    const subsId = useMemo(() => {
        if (!rawSubsId) {
            return;
        }

        return Number(rawSubsId);
    }, [rawSubsId]);

    useEffect(() => {
        if (query.isCompleted) {
            history.push("/");
        }
    }, []);

    const subscription = useMemo(() => {
        if (!subscriptionList || !subsId) {
            return;
        }

        return subscriptionList[subsId - 1];
    }, [subscriptionList, subsId]);

    useEffect(() => {
        if (subsId && !subscription) {
            history.replace("/");
        }
    }, [subsId, subscription]);

    const itemDescription = useMemo(() => {
        if (!subscription) {
            return;
        }

        return (
            <CoffeeMenuItem>
                <CoffeeTitleWrapper>
                    <CoffeeTitle>{subscription.name}</CoffeeTitle>
                </CoffeeTitleWrapper>
                <CoffeePrice>
                    {comma(subscription.price)}
                    <CoffeePriceUnit>원</CoffeePriceUnit>
                </CoffeePrice>
            </CoffeeMenuItem>
        );
    }, [subscription]);

    const orderCallback = useCallback(
        (name: string, phoneNumber: string, point: number) => {
            if (!subscription) {
                return;
            }

            dispatch(
                fetchGiftSubsThunk({
                    subsId: subscription.id,
                    point: Number(point),
                    price: subscription.price,
                    name,
                    phoneNumber,
                    productName: subscription.name,
                }),
            );
        },

        [subscription],
    );

    return (
        <IamportLoader>
            {subsId && subscription && (
                <GiftOrder
                    fetchState={giftSubsFetchState}
                    price={subscription.price}
                    amount={1}
                    orderCallback={orderCallback}
                    replaceUrl={`/gift/subs/${subsId}?isCompleted=true`}
                    getCompleteUrl={(name) => `/gift/subs/${subsId}/complete/${name}`}
                    itemDescription={itemDescription}
                />
            )}
        </IamportLoader>
    );
}

export function SingleOrderWithIamportloader() {
    const selectedMenu = useSelector(getSelectedMenu);
    const selectedShop = useSelector(getSelectedShop);
    const orderStash = useSelector(getOrderStash);
    const history = useHistory();
    const { search } = useLocation();
    const query = useMemo(() => getQueryStringObject(search), [search]);
    const dispatch = useDispatch();
    const orderFetchState = useSelector(getOrderFetchState);

    useEffect(() => {
        if (!selectedMenu || !orderStash || !selectedShop) {
            history.replace("/");
        }
    }, []);

    useEffect(() => {
        if (query.isCompleted) {
            history.push("/");
        }
    }, []);

    let emptyValue: number;

    const optionPrice = useMemo(
        () => (orderStash ? getMenuOptionGroupTotalPrice(orderStash.optionGroupList) : emptyValue),
        [orderStash],
    );

    const itemDescription = useMemo(() => {
        if (!orderStash || !selectedMenu) {
            return;
        }

        return (
            <CoffeeMenuItem>
                <CoffeeTitleWrapper>
                    <CoffeeTitle>{selectedMenu.name}</CoffeeTitle>
                    <CoffeeOption>
                        {orderStash.amount}잔 / {orderStash.temperatureType}
                    </CoffeeOption>
                </CoffeeTitleWrapper>
                <CoffeePrice>
                    {comma(selectedMenu.price)}
                    <CoffeePriceUnit>원</CoffeePriceUnit>
                </CoffeePrice>
            </CoffeeMenuItem>
        );
    }, [orderStash, selectedMenu]);

    const orderCallback = useCallback(
        (_name: string, _phoneNumber: string, point: number) => {
            if (orderFetchState === "FETCHING" || orderFetchState === "SUCCESS") {
                return;
            }
            if (!orderStash || !selectedMenu || !selectedShop) {
                return;
            }

            dispatch(
                fetchOrderThunk({
                    shopId: selectedShop.id,
                    menuId: selectedMenu.id,
                    temperatureType: orderStash.temperatureType,
                    cupType: orderStash.cupType!,
                    optionGroupList: orderStash.optionGroupList,
                    singleRequest: {
                        amount: orderStash.amount,
                        point,
                        price: selectedMenu.price,
                        productName: selectedMenu.name,
                    },
                }),
            );
        },

        [selectedMenu, selectedShop, orderStash, orderFetchState],
    );

    return (
        <IamportLoader>
            {selectedMenu && orderStash && (
                <GiftOrder
                    fetchState={orderFetchState}
                    price={selectedMenu.price}
                    optionPrice={optionPrice}
                    amount={orderStash.amount}
                    thumbnailImage={selectedMenu.uploaded_image_set?.original_image_url}
                    orderCallback={orderCallback}
                    replaceUrl={`/order/${selectedMenu.menu_group.id}/${selectedMenu.id}/single?isCompleted=true`}
                    getCompleteUrl={() => `/history`}
                    itemDescription={itemDescription}
                    isGift={false}
                    title="단품 구매"
                />
            )}
        </IamportLoader>
    );
}
