import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import EventCard from "./EventCard";
import {
    getEventContentPostListFetchState,
    getEventContentPostList,
    getEventContentPostListPage,
    getEventContentPostListTotalPage,
} from "@/redux/post/selectors";
import { fetchMoreEventContentPostListThunk } from "@/redux/post/thunk";
import InfiniteScrollList from "@/components/InfiniteScrollList";

const StyledAppLayout = styled(AppLayout)`
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-left: 20px;
    padding-bottom: 42px;
    padding-right: 20px;

    > a {
        margin-bottom: 24px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
`;

export default function Event() {
    const dispatch = useDispatch();
    const eventContentPostListFetchState = useSelector(getEventContentPostListFetchState);
    const eventContentPostList = useSelector(getEventContentPostList);
    const eventContentPostListPage = useSelector(getEventContentPostListPage);
    const eventContentPostListTotalPage = useSelector(getEventContentPostListTotalPage);

    const handleFetchMore = () => {
        dispatch(fetchMoreEventContentPostListThunk());
    };

    return (
        <StyledAppLayout
            title="컨텐츠/이벤트"
            titleColor="#fff"
            disableAppBarBorder
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            <InfiniteScrollList
                fetchState={eventContentPostListFetchState}
                list={eventContentPostList}
                page={eventContentPostListPage}
                totalPage={eventContentPostListTotalPage}
                showNoResultMessage
                noResultMessage="이벤트가 없어요"
                renderItem={(post, i) => <EventCard key={i} post={post} />}
                onLoadMore={handleFetchMore}
            />
        </StyledAppLayout>
    );
}
