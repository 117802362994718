import styled from "@emotion/styled";
import { FormControl, OutlinedInput, Select } from "@mui/material";

interface StyledFormControlProps {
    width?: number;
}

export const StyledFormControl = styled(FormControl)<StyledFormControlProps>`
    width: ${({ width }) => (width ? `${width}px` : "100%")};
`;

interface StyledSelectProps {
    height?: number;
}

export const StyledSelect = styled(Select)<StyledSelectProps>`
    font-size: 16px;
    border-radius: 8px;
    color: #0c0c0c;
    border: 1px solid #ff831e;
    background-color: #ffffff;

    & .MuiSelect-select {
        line-height: ${({ height }) => height}px;
        padding-top: 0;
        padding-bottom: 0;
        padding: 0 32px 0 20px;
    }

    & .MuiOutlinedInput-notchedOutline {
        border: 0;
    }
`;

export const SelectInput = styled(OutlinedInput)``;

export const SelectPlaceholder = styled.p`
    margin: 0;
    font-size: 16px;
    color: #676767;
    opacity: 0.8;
`;
