import styled from "@emotion/styled";
import { Tab, Tabs, tabClasses } from "@mui/material";

export const QuestionTabs = styled(Tabs)`
    background-color: #ffffff;

    & .MuiTabs-indicator {
        color: #ff831e;
        background-color: #ff831e;
    }
`;

export const QuestionTab = styled(Tab)`
    border-bottom: 1px solid #d1d1d1;
    color: #757575;

    &.${tabClasses.selected} {
        color: #ff831e;
    }
`;
