import React from "react";
import { Switch, useRouteMatch, useParams, Link } from "react-router-dom";
import { ConfigProvider, Layout as AntLayout, Menu } from "antd";
import koKR from "antd/lib/locale/ko_KR";
import useHistory from "@/hooks/useCustomHistory";
import Route from "@/components/RouteWithAuthGuard";
import PosOrder from "@/pages/Pos/Order";
import PosShopInfo from "@/pages/Pos/ShopInfo";
import PosMenu from "@/pages/Pos/Menu";
import PosMenuDetail from "@/pages/Pos/MenuDetail";

const { Content, Header } = AntLayout;

interface Props {
    children?: React.ReactNode;
}

export default function ShopLayout(props: Props) {
    const history = useHistory();
    const params = useParams<{ posId: string }>();
    const paths = window.location.pathname.split("/").splice(1, 3);

    if (!paths[1]) {
        history.push("/pos/login");
    }

    if (paths[1] !== "list" && !paths[2]) {
        history.push("/pos/login");
    }

    const getLinkTo = (link: string) => `/pos/${params.posId}${link}`;

    const routeMatch = useRouteMatch();
    return (
        <ConfigProvider locale={koKR}>
            <AntLayout style={{ minHeight: "100vh" }}>
                <Header>
                    <Menu mode="horizontal" defaultSelectedKeys={[paths[2]]}>
                        <Menu.Item key="orders">
                            <Link to={getLinkTo("/orders")}>주문 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="shop-info">
                            <Link to={getLinkTo("/shop-info")}>가맹점 관리</Link>
                        </Menu.Item>
                        <Menu.Item key="menu">
                            <Link to={getLinkTo("/menu")}>메뉴 목록</Link>
                        </Menu.Item>
                    </Menu>
                </Header>
                <AntLayout className="site-layout-pos" style={{ backgroundColor: "#ffffff" }}>
                    <Content style={{ margin: "0 auto", maxWidth: "97rem", padding: "1rem", width: "100%" }}>
                        <Switch>
                            <Route path={`${routeMatch.path}/orders`} component={PosOrder} />
                            <Route path={`${routeMatch.path}/shop-info`} component={PosShopInfo} />
                            <Route path={`${routeMatch.path}/menu`} exact component={PosMenu} />
                            <Route path={`${routeMatch.path}/menu/:groupId`} component={PosMenuDetail} />
                        </Switch>
                    </Content>
                </AntLayout>
            </AntLayout>
        </ConfigProvider>
    );
}
