// import { ArrowForwardIos } from "@mui/icons-material";
import { useFormik } from "formik";
import { Backdrop } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CupType, TemperatureType, MenuOptionGroup, MenuResponse, MenuOption } from "@ferda/rest-api-client";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarProvider } from "notistack";
import useHistory from "@/hooks/useCustomHistory";
import PlaceHolderImage from "@/assets/img/place_holder.png";
import BottomSheet from "@/components/BottomSheet";
import Checkbox from "@/components/Checkbox";
import { BackButton } from "@/components/IconButton";
import RecommendedMenu from "@/components/RecommendedMenu";
import AppLayout from "@/containers/AppLayout";
import {
    Description,
    Title,
    StyledDivider,
    FieldTitle,
    CheckBoxContainer,
    BottomSheetSpacer,
    BottomOrderButtonContainer,
    OrderContainer,
    ProductImageContainer,
    ProductImg,
    MainContentContainer,
    ColdnessTab,
    // SeeNutritionFactsButton,
    RecommendContainer,
    RecommendTitle,
    PriceWrapper,
    PriceText,
    PriceUnit,
} from "./style";
import Counter from "@/components/Counter";
import { getSelectedMenu, getSelectedMenuFetchState } from "@/redux/menu/selectors";
import { fetchMenuThunk } from "@/redux/menu/thunk";
import { checkCupAvaliable, formatCupType, getOptionGroupMapFromOptionList } from "./util";
// import CupTypeSetting from "./CupTypeSetting";
import { comma } from "@/utils/text";
import { getSelectedShop } from "@/redux/shop/selectors";
import { fetchOrderThunk } from "@/redux/order/thunk";
import { resetMenuState } from "@/redux/menu/actions";
import { getOrderFetchState, getOrderStash, getOrderType } from "@/redux/order/selectors";
import { resetShopState } from "@/redux/shop/actions";
import { resetOrderState, setOrderStash, setOrderType } from "@/redux/order/actions";
import { BottomSpacer } from "@/components/Spacer";
import { getSubscribeStatus, getSubscribeStatusFetchState } from "@/redux/subscription/selectors";
import { AlertModalText, AlertModalTitle } from "../Gift/GiftGetDetail/style";
import Modal from "@/components/Modal";
import { fetchSubscribeStatusThunk } from "@/redux/subscription/thunk";
import LoadingCircle from "@/components/LoadingCircle";
import { getQueryStringObject } from "@/utils/global";
import { SubscribeStatusUpdater } from "@/components/SubscribeStatusUpdater";
import CupTypeSetting from "./CupTypeSetting";
import Footer from "@/components/Footer";
import { ConfirmButton } from "@/components/Button/style";

interface OrderFormField {
    temperatureType: TemperatureType;
    cupType?: CupType;
    amount: number;
    optionGroupMap: Record<string, MenuOptionGroup>;
}

interface OptionDetailContentProps {
    menu: MenuResponse;
    isGift?: boolean;
    optionOpen: boolean;
    setOptionOpen: (newOption: boolean) => void;
}

function OptionDetailContent({ menu, isGift, optionOpen, setOptionOpen }: OptionDetailContentProps) {
    const dispatch = useDispatch();
    const shop = useSelector(getSelectedShop);
    const history = useHistory();
    const orderFetchState = useSelector(getOrderFetchState);
    const orderStash = useSelector(getOrderStash);
    const subscribeStatus = useSelector(getSubscribeStatus);
    const subscribeStatusFetchState = useSelector(getSubscribeStatusFetchState);
    const [canNotOrderModalOpen, setCanNotOrderModal] = useState(false);
    const [needSubscriptionModalOpen, setNeedSubscriptionModalOpen] = useState(false);
    const [returnCupPopupOpened, setReturnCupPopupOpened] = useState(() => Boolean(isGift || orderStash));
    const [returnCupPopup, setReturnCupPopup] = useState(false);

    useEffect(() => {
        if (subscribeStatusFetchState !== "READY") {
            return;
        }

        dispatch(fetchSubscribeStatusThunk({}));
    }, [subscribeStatus]);

    useEffect(() => {
        if (orderStash && orderStash.optionGroupList.length > 0) {
            setOptionOpen(true);
        }
    }, [orderStash]);

    const { menuGroupId: rawMenuGroupId, menuId: rawMenuId } = useParams<{ menuGroupId: string; menuId: string }>();
    const { menuGroupId, menuId } = useMemo(() => {
        return {
            menuGroupId: Number(rawMenuGroupId),
            menuId: Number(rawMenuId),
        };
    }, [rawMenuGroupId, rawMenuId]);

    useEffect(() => {
        if (orderFetchState !== "SUCCESS") {
            return;
        }

        dispatch(resetOrderState());
        dispatch(resetMenuState());
        dispatch(resetShopState());
        window.history.replaceState(null, "워크무드", `/order/${menuGroupId}/${menuId}?isCompleted=true`);
        history.push("/history");
    }, [orderFetchState, menuGroupId, menuId]);

    const { search } = useLocation();
    const query = useMemo(() => getQueryStringObject(search), [search]);

    useEffect(() => {
        if (query.isCompleted) {
            history.push("/");
        }
    }, [query]);

    const initialValues: OrderFormField = useMemo(
        () => ({
            temperatureType: orderStash?.temperatureType ?? TemperatureType.HOT,
            amount: orderStash?.amount ?? 1,
            optionGroupMap:
                orderStash && orderStash.optionGroupList && orderStash.optionGroupList.length
                    ? getOptionGroupMapFromOptionList(orderStash.optionGroupList ?? menu.option_groups, true)
                    : getOptionGroupMapFromOptionList(menu.option_groups),
            cupType: orderStash?.cupType,
        }),
        [orderStash, menu],
    );

    const orderType = useSelector(getOrderType);

    const isSubscription = useMemo(() => {
        return !isGift && orderType === "SUBSCRIPTION";
    }, [isGift, menu, orderType]);

    const { setFieldValue, values, handleSubmit } = useFormik<OrderFormField>({
        initialValues,
        onSubmit: ({ temperatureType, cupType, optionGroupMap, amount }: OrderFormField) => {
            let emptyValue;

            const tempType = menu.temperature_selectable ? temperatureType : emptyValue;

            if (!optionOpen) {
                if (!isGift) {
                    if (!returnCupPopupOpened) {
                        setReturnCupPopupOpened(true);
                        // setReturnCupPopup(true);
                    }
                    return setOptionOpen(true);
                }

                if (isGift && menu.option_groups.length > 0) {
                    return setOptionOpen(true);
                }
            }

            const optionGroupList = Object.values(optionGroupMap).reduce<MenuOptionGroup[]>(
                (prev, cur) => (cur.options.length ? [...prev, cur] : prev),
                [],
            );

            if (!shop || isGift || orderType === "SINGLE") {
                dispatch(
                    setOrderStash({
                        temperatureType: tempType,
                        cupType,
                        optionGroupList,
                        amount,
                        isGift,
                    }),
                );
            }

            if (isGift) {
                return history.push(`/gift/${menu.id}/order`);
            }

            if (!shop) {
                return history.push("/select-shop");
            }

            if (orderType === "SUBSCRIPTION") {
                if (!subscribeStatus || !subscribeStatus?.subscription_in_use) {
                    return setNeedSubscriptionModalOpen(true);
                }

                if (!subscribeStatus?.can_order) {
                    return setCanNotOrderModal(true);
                }
            }

            if (!cupType) {
                return;
            }

            if (orderType === "SINGLE") {
                return history.push(`/order/${menu.menu_group.id}/${menu.id}/single`);
            }

            dispatch(
                fetchOrderThunk({
                    shopId: shop.id,
                    menuId: menu.id,
                    temperatureType: tempType,
                    cupType,
                    optionGroupList,
                }),
            );

            dispatch(setOrderStash(null));
        },
        enableReinitialize: true,
    });

    const getCurrentOptionGroup: (groupName: string) => MenuOptionGroup | undefined = useCallback(
        (groupName: string) => values.optionGroupMap[groupName],
        [values],
    );

    const checkOptionIsAlreadyExists = useCallback(
        (groupName: string, optionName: string) => {
            const optionGroup = getCurrentOptionGroup(groupName);

            return !!optionGroup?.options.find(({ name }) => name === optionName);
        },
        [values],
    );

    const handleOptionGroupValueChange = useCallback(
        (groupName: string, option: MenuOption, selectMultiple?: boolean) => () => {
            const optionGroup = getCurrentOptionGroup(groupName);
            const optionAlreadyExists = checkOptionIsAlreadyExists(groupName, option.name);
            const prevOptionList = optionGroup?.options.filter((prevOption) => prevOption.name !== option.name) ?? [];

            let options;

            if (selectMultiple) {
                options = [...prevOptionList, ...(optionAlreadyExists ? [] : [option])];
            } else {
                options = optionAlreadyExists ? [] : [option];
            }

            setFieldValue("optionGroupMap", {
                ...values.optionGroupMap,
                [groupName]: {
                    ...optionGroup,
                    options,
                },
            });
        },
        [values],
    );

    const handleTemperatureTypeChange = (newTempType: string) => {
        setFieldValue("temperatureType", newTempType);
    };

    const handleCupTypeChange = (newCupType?: CupType) => {
        setFieldValue("cupType", newCupType);
    };

    const handleAmountChange = (newAmount: number) => setFieldValue("amount", newAmount);

    const handleOptionClose = () => {
        setOptionOpen(false);
    };

    const priceComponent = useMemo(() => {
        if (isSubscription) {
            return <Description>{menu.description}</Description>;
        }

        return (
            <PriceWrapper>
                <PriceText>
                    {comma(menu.price * values.amount)}
                    <PriceUnit>원</PriceUnit>
                </PriceText>

                <Counter value={values.amount} setValue={handleAmountChange} />
            </PriceWrapper>
        );
    }, [menu, values, isSubscription]);

    let emptyValue: any;

    const [notAvaliableModalOpen, setNotAvaliableModalOpen] = useState(false);

    useEffect(() => {
        if (!shop || !values.cupType) {
            return;
        }

        if (checkCupAvaliable(shop, values.cupType)) {
            setNotAvaliableModalOpen(true);
        }
    }, [shop, values]);

    const clearCupType = () => {
        setFieldValue("cupType", emptyValue);
        setNotAvaliableModalOpen(false);
    };

    const handleOrderTypeChange = (newType: string) => {
        dispatch(setOrderType(newType as "SUBSCRIPTION" | "SINGLE"));
    };

    const isAllRequiredOptionChecked = useMemo(() => {
        const requiredOptionList = menu.option_groups
            .filter(({ name }) => name.includes("필수"))
            .map(({ name }) => name);

        const selectedOptionList = Object.values(values.optionGroupMap)
            .filter(({ options }) => options.length > 0)
            .map(({ name }) => name);

        return requiredOptionList.every((requiredOptionName) =>
            selectedOptionList.some((name) => name === requiredOptionName),
        );
    }, [values, menu]);

    const confirmButtonDisabled = useMemo(() => {
        if (isGift || !optionOpen) {
            return false;
        }

        if (optionOpen && isAllRequiredOptionChecked && typeof values.cupType !== "undefined") {
            return false;
        }

        return true;
    }, [isGift, optionOpen, isAllRequiredOptionChecked, values, optionOpen]);

    return (
        <form style={{ height: "100%" }} onSubmit={handleSubmit}>
            <Modal
                titleText={`${formatCupType(values.cupType)} 컵 사용 불가`}
                open={notAvaliableModalOpen}
                handleClose={clearCupType}
                buttonOnClick={clearCupType}
                fullSizeButton
                buttonText="확인"
            >
                <>
                    <AlertModalText>
                        선택하신 매장에서는 현재 {formatCupType(values.cupType)} 컵으로 주문이 불가 합니다.
                    </AlertModalText>
                    <AlertModalText>다른 컵을 사용해 주세요.</AlertModalText>
                </>
            </Modal>
            <Modal
                titleText="구독권 없음"
                open={needSubscriptionModalOpen}
                handleClose={() => setNeedSubscriptionModalOpen(false)}
                buttonOnClick={() => setNeedSubscriptionModalOpen(false)}
                fullSizeButton
                buttonText="확인"
            >
                <AlertModalText>구독권 구매가 필요합니다.</AlertModalText>
            </Modal>
            <Modal
                titleText="알림"
                open={returnCupPopup}
                handleClose={() => setReturnCupPopup(false)}
                buttonOnClick={() => setReturnCupPopup(false)}
                fullSizeButton
                buttonText="확인"
            >
                <>
                    <AlertModalTitle>
                        워크무드의 모든 음료는
                        <br />
                        리유저블컵에 제공됩니다.
                    </AlertModalTitle>
                    <AlertModalText>
                        뉴컵: 최초로 제공되는 컵<br />
                        리턴컵: 회수 후, 세척이 완료되어 제공되는 컵
                    </AlertModalText>
                </>
            </Modal>
            <Modal
                titleText="주문불가"
                open={canNotOrderModalOpen}
                handleClose={() => setCanNotOrderModal(false)}
                buttonOnClick={() => setCanNotOrderModal(false)}
                fullSizeButton
                buttonText="확인"
            >
                <AlertModalText>재주문 가능시간이 남았습니다</AlertModalText>
            </Modal>
            <BottomSheet
                blocking={false}
                onDismiss={handleOptionClose}
                title={menu.name}
                titleAlign="center"
                open={optionOpen}
                snapPoints={({ maxHeight, minHeight }) => [(maxHeight / 6) * 5, minHeight]}
            >
                {!isGift && (
                    <>
                        <StyledDivider />
                        <FieldTitle>컵 선택</FieldTitle>
                        <CupTypeSetting value={values.cupType} selectedShop={shop} onChange={handleCupTypeChange} />
                    </>
                )}
                <StyledDivider />
                {menu.option_groups.map(({ name, options, multiple_selection_possible }, i) => (
                    <div key={i}>
                        <FieldTitle>{name}</FieldTitle>
                        <CheckBoxContainer>
                            {options.map((option) =>
                                !option ? null : (
                                    <Checkbox
                                        iconType="square"
                                        value={checkOptionIsAlreadyExists(name, option.name)}
                                        label={option.name}
                                        onClick={handleOptionGroupValueChange(
                                            name,
                                            option,
                                            multiple_selection_possible,
                                        )}
                                        description={!isSubscription ? `${comma(option.price)}원` : emptyValue}
                                    />
                                ),
                            )}
                        </CheckBoxContainer>
                        <StyledDivider />
                    </div>
                ))}
                <BottomSheetSpacer />
            </BottomSheet>
            <OrderContainer isGift={isGift}>
                <ProductImageContainer>
                    <ProductImg src={menu.uploaded_image_set?.original_image_url ?? PlaceHolderImage} />
                </ProductImageContainer>
                <MainContentContainer>
                    <Title>{menu.name}</Title>
                    {priceComponent}
                    {!isGift && (
                        <ColdnessTab
                            tabOneName="SUBSCRIPTION"
                            tabOneDisplayName="구독권"
                            tabTwoName="SINGLE"
                            tabTwoDisplayName="단품"
                            tab={orderType}
                            onTabChange={handleOrderTypeChange}
                        />
                    )}
                    {menu.temperature_selectable && (
                        <ColdnessTab
                            tabOneName="HOT"
                            tabOneBackgroundColor="#e32424"
                            tabTwoName="ICED"
                            tabTwoBackgroundColor="#0b6ad9"
                            selectedTextColor="white"
                            tab={values.temperatureType}
                            onTabChange={handleTemperatureTypeChange}
                        />
                    )}
                    {/* <SeeNutritionFactsButton
                        backgroundColor="#191919"
                        href={`/order/${menu.menu_group.id}/${menu.id}/facts`}
                    >
                        <span>제품 영양 정보</span>
                        <ArrowForwardIos sx={{ color: "#757575", fontSize: "16px" }} />
                    </SeeNutritionFactsButton> */}
                </MainContentContainer>
            </OrderContainer>

            <BottomOrderButtonContainer>
                <ConfirmButton
                    variant="contained"
                    backgroundColor="#ff831e"
                    disabled={confirmButtonDisabled}
                    type="submit"
                >
                    {isGift ? "선물하기" : "주문하기"}
                </ConfirmButton>
            </BottomOrderButtonContainer>
        </form>
    );
}

interface OrderDetailProps {
    isGift?: boolean;
}

const useStyles = makeStyles({
    snackbarContainer: {
        bottom: "70px !important",
    },
});

export default function OrderDetail({ isGift }: OrderDetailProps) {
    const dispatch = useDispatch();
    const { menuId: rawMenuId } = useParams<{ menuId: string }>();
    const selectedMenu = useSelector(getSelectedMenu);
    const selectedMenuFetchState = useSelector(getSelectedMenuFetchState);
    const [optionOpen, setOptionOpen] = useState<boolean>(false);
    const classes = useStyles();

    useEffect(() => {
        const menuId = Number(rawMenuId);

        if (selectedMenu && selectedMenu.id === menuId) {
            return;
        }

        setOptionOpen(false);
        dispatch(setOrderStash(null));
        dispatch(fetchMenuThunk(menuId));
    }, [rawMenuId, selectedMenu]);

    // useEffect(() => {
    //     console.log(orderStash);
    //     if (orderStash) {
    //         setOptionOpen(true);
    //     }
    // }, [orderStash]);

    return (
        <SnackbarProvider
            classes={{
                containerAnchorOriginBottomLeft: classes.snackbarContainer,
            }}
        >
            <SubscribeStatusUpdater />
            <AppLayout
                fullHeight
                disableAppBarBorder
                appBarBackgroundColor={isGift ? "#191919" : "transparent"}
                titleColor="#fff"
                title={isGift ? "선물상세" : ""}
                leadingIcon={<BackButton />}
            >
                {selectedMenuFetchState === "FETCHING" && <LoadingCircle position="absolute" />}
                {selectedMenu && selectedMenuFetchState === "SUCCESS" && (
                    <>
                        <OptionDetailContent
                            menu={selectedMenu}
                            isGift={isGift}
                            optionOpen={optionOpen}
                            setOptionOpen={setOptionOpen}
                        />
                        <RecommendContainer>
                            <RecommendTitle>이런 음료는 어때요?</RecommendTitle>
                            <RecommendedMenu hidePrice={!isGift} />
                            <Footer noPadding />
                            <BottomSpacer />
                        </RecommendContainer>
                        <Backdrop onClick={() => setOptionOpen(false)} open={optionOpen} />
                    </>
                )}
            </AppLayout>
        </SnackbarProvider>
    );
}
