import { Global } from "@emotion/react";
import React from "react";
import { BottomSheet as ReactBottomSheet, BottomSheetProps as ReactBottomSheetProps } from "react-spring-bottom-sheet";
import BottomSheetGlobalStyle from "./globalStyle";
import { BottomSheetContent, BottomSheetTitle, BottomSheetTitleProps } from "./style";
import "react-spring-bottom-sheet/dist/style.css";

interface BottomSheetProps extends Omit<ReactBottomSheetProps, "children" | "title">, BottomSheetTitleProps {
    title?: string | React.ReactNode;
    fullSize?: boolean;
    handleBackgroundColor?: string;
    backgroundColor?: string;
    children: React.ReactNode;
}

export default function BottomSheet({
    title,
    titleColor = "#0c0c0c",
    titleAlign,
    titleMarginBottom,
    titleSize,
    fullSize,
    handleBackgroundColor = "#5b5b5b",
    backgroundColor = "#ffffff",
    children,
    snapPoints = ({ maxHeight, minHeight }) => (fullSize ? maxHeight : minHeight),
    open,
    onDismiss,
    ...args
}: BottomSheetProps) {
    return (
        <ReactBottomSheet onDismiss={onDismiss} open={open} {...args} snapPoints={snapPoints}>
            <Global styles={BottomSheetGlobalStyle} />
            <Global
                styles={`
                    :root {
                        --rsbs-handle-bg: ${handleBackgroundColor};
                        --rsbs-bg: ${backgroundColor};
                    }
                `}
            />
            <BottomSheetContent>
                {typeof title === "string" ? (
                    <BottomSheetTitle
                        titleColor={titleColor}
                        titleAlign={titleAlign}
                        titleSize={titleSize}
                        titleMarginBottom={titleMarginBottom}
                    >
                        {title}
                    </BottomSheetTitle>
                ) : (
                    title || ""
                )}

                {children}
            </BottomSheetContent>
        </ReactBottomSheet>
    );
}
