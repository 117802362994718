import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { getMisc, getMiscFetchState } from "@/redux/subscription/selectors";
import { fetchMiscThunk } from "@/redux/subscription/thunk";
import { ImageMaxWidthContentContainer } from "@/components/ImageMaxWidthContentContainer";
import Footer from "@/components/Footer";

const ServiceContentContainer = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`;

export default function ServiceContent() {
    const dispatch = useDispatch();
    const misc = useSelector(getMisc);
    const miscFetchState = useSelector(getMiscFetchState);

    useEffect(() => {
        if (miscFetchState !== "READY") {
            return;
        }

        dispatch(fetchMiscThunk({}));
    }, [miscFetchState]);

    // eslint-disable-next-line react/no-danger
    return (
        <ServiceContentContainer>
            <ImageMaxWidthContentContainer dangerouslySetInnerHTML={{ __html: misc?.content ?? "" }} />
            <Footer noPadding />
        </ServiceContentContainer>
    );
}
