import { makeSubStateSelectorMap } from "@/utils/redux/selector";
import { RootState } from "../types";
import { CUSTOM_HISTORY_STATE_KEY_LIST } from "./slice";

export const { getCurrentIndex, getHistoryList } = makeSubStateSelectorMap(
    "customHistory",
    CUSTOM_HISTORY_STATE_KEY_LIST,
);

export const getCurrentUrl = ({ customHistory: { historyList, currentIndex } }: RootState) => historyList[currentIndex];
