import React from "react";
import { Button, message, Modal } from "antd";
import { MiscellaneousType } from "@ferda/rest-api-client";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import CKEditor from "@/components/Admin/CKEditor";
import { Right, Clear } from "@/components/Admin/Float";

interface Props {}

export default function Page(props: Props) {
    const history = useHistory();

    const { serviceApi } = useSelector(getRestApiClient);

    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [modal, setModal] = React.useState<string | undefined>();

    const [content, setContent] = React.useState<string | undefined>();

    React.useEffect(() => {
        setInitialLoading(true);
        serviceApi
            .getMiscellaneous(MiscellaneousType.SUBSCRIPTION_INFO)
            .then((res) => {
                setInitialLoading(false);
                setContent(res.data.content);
            })
            .catch(() => {
                setInitialLoading(false);
                setContent(undefined);
            });
    }, []);

    return (
        <>
            <PageTitle>구독 정보 수정</PageTitle>
            <Container>
                <CKEditor
                    data={content}
                    onChange={(event, editor) => {
                        setContent(editor.getData());
                    }}
                />
            </Container>
            <Container>
                <Right>
                    <Button
                        danger
                        disabled={loading || initialLoading}
                        onClick={() => setModal("cancel")}
                        style={{ marginRight: "0.5rem" }}
                    >
                        취소
                    </Button>
                    <Button disabled={loading || initialLoading} onClick={() => setModal("finish")}>
                        저장
                    </Button>
                </Right>
                <Clear />
            </Container>
            <Modal
                okButtonProps={{ danger: true }}
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    history.goBack();
                }}
                title="취소"
                visible={modal === "cancel"}
                width={800}
            >
                작성을 취소하시겠습니까?
            </Modal>
            <Modal
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!content) {
                        return message.error("구독 정보를 작성해주세요.", 1.5);
                    }

                    setLoading(true);
                    serviceApi
                        .setMiscellaneous({ miscellaneous_type: MiscellaneousType.SUBSCRIPTION_INFO, content })
                        .then(() => {
                            message.success("구독 정보를 수정하였습니다.", 1.5);
                            history.push("/admin/subscriptioninfo");
                        })
                        .catch((error) => {
                            setLoading(false);
                            message.error(error.response.data.detail, 1.5);
                        });
                }}
                title="저장"
                visible={modal === "finish"}
                width={800}
            >
                저장하시겠습니까?
            </Modal>
        </>
    );
}
