import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetEventContentPostState } from "@/redux/post/actions";
import { getEventContentPost, getEventContentPostFetchState } from "@/redux/post/selectors";
import { fetchEventContentPostThunk } from "@/redux/post/thunk";
import CommonPostDetailPage from "@/components/CommonPostDetailPage";

export default function EventDetail() {
    const dispatch = useDispatch();
    const eventContentPost = useSelector(getEventContentPost);
    const eventContentPostFetchState = useSelector(getEventContentPostFetchState);
    const { eventId } = useParams<{ eventId: string }>();

    useEffect(() => {
        dispatch(fetchEventContentPostThunk(Number(eventId)));

        return () => {
            dispatch(resetEventContentPostState());
        };
    }, []);

    return (
        <CommonPostDetailPage title="컨텐츠/이벤트" post={eventContentPost} fetchState={eventContentPostFetchState} />
    );
}
