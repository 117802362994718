import { useDispatch } from "react-redux";
import {
    push as pushHistory,
    replace as replaceHistory,
    reset as resetHistory,
    back as backHistory,
    forward as forwardHistory,
} from "@/redux/customHistory/actions";

export default function useCustomHistory() {
    const dispatch = useDispatch();

    const push = (newUrl: string) => {
        dispatch(pushHistory(newUrl));
    };

    const replace = (newUrl: string) => {
        dispatch(replaceHistory(newUrl));
    };

    const reset = () => {
        dispatch(resetHistory());
    };

    const back = () => {
        dispatch(backHistory());
    };

    const forward = () => {
        dispatch(forwardHistory());
    };

    return {
        push,
        replace,
        reset,
        back,
        forward,
    };
}
