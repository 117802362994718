import styled from "@emotion/styled";

export const PermissionModalWrapper = styled.div`
    padding: 40px 10px 24px 10px;
`;

export const PermissionModalTitle = styled.p`
    color: #ff831e;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
`;

export const PermissionModalContentWrapper = styled.div`
    padding-left: 10px;
`;

export const PermissionModalContentTitle = styled.p`
    color: #ff831e;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
`;

export const PermissionModalContent = styled.p`
    color: #ff831e;
    font-size: 14px;
    color: #ccc;
`;

export const PermissionModalGuideText = styled.p`
    font-size: 10px;
    color: #0c0c0c;
    margin-top: 30px;
`;

export const FinishModalTitle = styled.p`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 24px 0 36px 0;
    color: #ff831e;
`;
