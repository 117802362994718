import { List, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHistory from "@/hooks/useCustomHistory";
import { AvatarContainer, Container, ItemContainer, PosLink } from "./style";
import { getPosGetShopFetchState, getPosLoginShop } from "@/redux/pos/selector";
import { posGetShopRequestThunk } from "@/redux/pos/thunk";
import AvatarPlaceholderIcon from "@/assets/img/place_holder.png";
import { clearOauth2Token } from "@/redux/auth/utils";

export default function ShopList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);

    const shopList = useSelector(getPosLoginShop);
    const posGetShopRequestStatus = useSelector(getPosGetShopFetchState);

    useEffect(() => {
        if (posGetShopRequestStatus === "READY" || posGetShopRequestStatus === "FETCHING") {
            return;
        }

        if (posGetShopRequestStatus === "FAILURE") {
            message.error("가맹점 정보를 불러오는 중 오류가 발생했습니다.");
            clearOauth2Token();
            history.replace("/pos/login");
            return;
        }

        setLoading(false);
    }, [posGetShopRequestStatus]);

    useEffect(() => {
        if (!shopList) {
            setLoading(true);
            dispatch(posGetShopRequestThunk());
        }
    }, []);

    return (
        <Container>
            <Spin spinning={loading}>
                <ItemContainer>
                    {shopList && (
                        <List
                            itemLayout="horizontal"
                            dataSource={shopList}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <AvatarContainer>
                                                <img
                                                    src={
                                                        item.uploaded_image_sets[0]?.original_image_url ||
                                                        AvatarPlaceholderIcon
                                                    }
                                                    alt="avatar"
                                                />
                                            </AvatarContainer>
                                        }
                                        title={<PosLink to={`/pos/${item.id}/orders`}>{item.name}</PosLink>}
                                        description={item.address}
                                    />
                                </List.Item>
                            )}
                        />
                    )}
                </ItemContainer>
            </Spin>
        </Container>
    );
}
