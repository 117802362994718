import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "moment/locale/ko";
import { Button, Col, DatePicker, Form, Input, message, notification, Row, Select } from "antd";
import {
    CupType,
    MenuResponse,
    OrderResponse,
    OrderStatus,
    TemperatureType,
    UserResponse,
    UserType,
} from "@ferda/rest-api-client";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRestApiClient } from "@/redux/auth/selectors";
import { cupTypeToText, orderStatusToText, temperatureTypeToText } from "@/utils/enumToText";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import Table from "@/components/Admin/Table";
import FormItem from "@/components/Admin/FormItem";
import Pagination from "@/components/Admin/Pagination";
import StatusBadge from "@/components/Admin/StatusBadge";
import { Clear, Left, Right } from "@/components/Admin/Float";
import {
    LargeModal,
    OrderOptionContainer,
    OrderOptionGroupTitle,
    OrderOptionList,
    OrderOptionListItem,
    OrderTitle,
    PrintButton,
    SearchBarContainer,
} from "./style";
import {
    getCupTypeToColor,
    getOrderStatusProgressMessage,
    getOrderStatusToColor,
    getTemperatureTypeToColor,
} from "@/utils/admin";
import BellSound from "../../../assets/bell.mp3";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props {}

export default function PosOrder(props: Props) {
    const { shopApi, userApi } = useSelector(getRestApiClient);
    const { posId } = useParams<{ posId: string }>();

    const [loading, setLoading] = useState<boolean>(false);
    const [csvLoading, setCSVLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = useState<boolean>(false);

    const [modal, setModal] = useState<string | undefined>();
    const [modalOrder, setModalOrder] = useState<OrderResponse | undefined>();
    const [modalMenuList, setModalMenuList] = useState<MenuResponse[]>([]);
    const [modalMenuListTotal, setModalMenuListTotal] = useState<number>(0);
    const [modalMenuPage, setModalMenuPage] = useState<number>(1);
    const [modalMenuKeyword, setModalMenuKeyword] = useState<string | undefined>();
    const [modalUserList, setModalUserList] = useState<UserResponse[]>([]);
    const [modalUserListTotal, setModalUserListTotal] = useState<number>(0);
    const [modalUserName, setModalUserName] = useState<string | undefined>();
    const [modalUserPhone, setModalUserPhone] = useState<string | undefined>();
    const [modalUserPage, setModalUserPage] = useState<number>(1);

    const [orderList, setOrderList] = useState<OrderResponse[]>([]);
    const [orderListTotal, setOrderListTotal] = useState<number | null>(null);
    const [orderUnfilteredListTotal, setOrderUnfilteredListTotal] = useState<number | null>(null);

    const [menu, setMenu] = useState<MenuResponse | undefined>();
    const [user, setUser] = useState<UserResponse | undefined>();

    const [minCreatedDate, setMinCreatedDate] = useState<Date | undefined>();
    const [maxCreatedDate, setMaxCreatedDate] = useState<Date | undefined>();
    const [menuId, setMenuId] = useState<number | undefined>();
    const [username, setUsername] = useState<string | undefined>();
    const [orderStatus, setOrderStatus] = useState<OrderStatus[] | undefined>();
    const [temperatureType, setTemperatureType] = useState<TemperatureType[] | undefined>();
    const [cupType, setCupType] = useState<CupType[] | undefined>();
    const [page, setPage] = useState<number>(1);

    const [cancelForm] = Form.useForm();
    const [progressForm] = Form.useForm();

    const orderInterval = useRef<NodeJS.Timer | null>(null);
    const bellAudio = new Audio(BellSound);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const handleClickPrint = (record: OrderResponse) => {
        if (record.order_status !== OrderStatus.MAKE_IN_PROGRESS) {
            setLoading(true);
            shopApi
                .setOrderStatus(record.id, { order_status: OrderStatus.MAKE_IN_PROGRESS })
                .then(() => {
                    setLoading(false);
                    setRerender(!rerender);
                    message.success("주문 상태를 변경하였습니다.", 1.5);
                })
                .catch((error) => {
                    setLoading(false);
                    message.error(error.response.data.detail, 1.5);
                });
        }

        const myWindow = window.open("", "", "width=700,height=500");
        if (!myWindow) {
            return;
        }

        myWindow.document.write(`
            <style>
                @media print {
                    body{
                        width: 7cm;
                        height: 5cm;
                        border: 1px solid #000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                        padding: 0.25rem;
                    } 
                    p {
                        margin: 0.25rem 0;
                    }
                }
            </style>

            <div>
                <p>이름(닉네임) : ${record.user.detail?.name}</p>
                <p>주문 번호(Number) : W-${record.display_id}</p>
                <p>주문 음료(Beverage) : ${record.menu.name}</p>
                <p>온도 : ${record.temperature_type ? temperatureTypeToText(record.temperature_type) : ""}</p>
                <p>컵 종류 : ${cupTypeToText(record.cup_type)}</p>
                <p>주문 옵션 : ${record.option_groups
                    .map((group) => `${group.name}: ${group.options.map((option) => option.name).join(", ")}`)
                    .join(" | ")}</p>
            </div>

            <script>
                window.onafterprint = function(){
                    window.close();
                }
            </script>
        `);
        myWindow.document.close();

        myWindow.focus();
        myWindow.print();
    };

    const orderColumns = [
        {
            title: "주문번호",
            key: "number",
            render: (record: OrderResponse) => {
                return {
                    children: `${record.display_id} (${record.id.toLocaleString("ko")})`,
                };
            },
            width: "80px",
        },
        {
            title: "주문시각",
            key: "createdDatetime",
            render: (record: OrderResponse) => {
                const time = moment(record.created_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "주문자",
            key: "user",
            render: (record: OrderResponse) => {
                let extraInfo;
                if (record.subscription) {
                    extraInfo = <p>{record.subscription.name}</p>;
                } else if (record.gift) {
                    extraInfo = <p>선물사용</p>;
                }
                return {
                    children: record.user.detail && (
                        <>
                            <p>{record.user.detail?.name}</p>
                            <p>{record.user.detail?.phone_number}</p>
                            {record.transaction && <p>단품 주문</p>}
                            {extraInfo}
                        </>
                    ),
                };
            },
            width: "130px",
        },
        {
            title: "단품주문",
            key: "singleOrder",
            render: (record: OrderResponse) => {
                return {
                    children: record.transaction ? "O" : "X",
                };
            },
            width: "80px",
        },
        {
            title: "주문내역",
            key: "menu",
            render: (record: OrderResponse) => {
                return {
                    children: (
                        <OrderTitle>
                            {record.menu.name}: {record.amount}잔
                        </OrderTitle>
                    ),
                };
            },
        },
        {
            title: "온도",
            key: "temperature",
            render: (record: OrderResponse) => {
                return {
                    children: record.temperature_type && (
                        <StatusBadge color={getTemperatureTypeToColor(record.temperature_type)} size="LARGE">
                            {temperatureTypeToText(record.temperature_type)}
                        </StatusBadge>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "컵",
            key: "cup",
            render: (record: OrderResponse) => {
                return {
                    children: record.cup_type && (
                        <StatusBadge color={getCupTypeToColor(record.cup_type)} size="LARGE">
                            {cupTypeToText(record.cup_type)}
                        </StatusBadge>
                    ),
                };
            },
            width: "150px",
        },
        {
            title: "주문옵션",
            key: "options",
            render: (record: OrderResponse) => {
                return {
                    children: record.option_groups.map((group, groupIndex) => (
                        <OrderOptionContainer key={groupIndex}>
                            <OrderOptionGroupTitle>{group.name}</OrderOptionGroupTitle>
                            <OrderOptionList>
                                {group.options.map((option, optionIndex) => (
                                    <OrderOptionListItem key={optionIndex}>{option.name}</OrderOptionListItem>
                                ))}
                            </OrderOptionList>
                        </OrderOptionContainer>
                    )),
                };
            },
            width: "250px",
        },
        {
            title: "주문상태",
            key: "status",
            render: (record: OrderResponse) => {
                return {
                    children: (
                        <StatusBadge
                            color={getOrderStatusToColor(record.order_status)}
                            size="LARGE"
                            onClick={
                                record.order_status !== OrderStatus.ORDER_CANCELED &&
                                record.order_status !== OrderStatus.PICKUP_COMPLETED
                                    ? () => {
                                          let tempStatus: OrderStatus;
                                          switch (record.order_status) {
                                              case OrderStatus.ORDER_COMPLETED:
                                                  tempStatus = OrderStatus.MAKE_IN_PROGRESS;
                                                  break;
                                              case OrderStatus.MAKE_IN_PROGRESS:
                                                  tempStatus = OrderStatus.MAKE_COMPLETED;
                                                  break;
                                              case OrderStatus.MAKE_COMPLETED:
                                                  tempStatus = OrderStatus.PICKUP_COMPLETED;
                                                  break;
                                              default:
                                                  tempStatus = OrderStatus.PICKUP_COMPLETED;
                                          }

                                          setLoading(true);
                                          shopApi
                                              .setOrderStatus(record.id, { order_status: tempStatus })
                                              .then(() => {
                                                  setLoading(false);
                                                  setRerender(!rerender);
                                                  message.success("주문 상태를 변경하였습니다.", 1.5);
                                                  if (tempStatus === OrderStatus.MAKE_IN_PROGRESS) {
                                                      setModalOrder(record);
                                                      // setModal("print");
                                                      handleClickPrint(record);
                                                  }
                                              })
                                              .catch((error) => {
                                                  setLoading(false);
                                                  message.error(error.response.data.detail, 1.5);
                                              });
                                      }
                                    : undefined
                            }
                        >
                            {orderStatusToText(record.order_status)}
                        </StatusBadge>
                    ),
                };
            },
            width: "170px",
        },
        {
            title: "관리",
            key: "manage",
            render: (record: OrderResponse) => {
                return {
                    children: (
                        <>
                            <div>
                                <Button
                                    disabled={
                                        loading ||
                                        // record.order_status === OrderStatus.PICKUP_COMPLETED ||
                                        record.order_status === OrderStatus.ORDER_CANCELED
                                    }
                                    onClick={() => {
                                        setModal("progress");
                                        setModalOrder(record);
                                    }}
                                >
                                    상태 변경
                                </Button>
                            </div>
                            <div>
                                <PrintButton onClick={() => handleClickPrint(record)}>주문서 출력</PrintButton>
                            </div>
                        </>
                    ),
                };
            },
            width: "135px",
        },
    ];

    const menuColumns = [
        {
            title: "사진",
            key: "image",
            render: (record: MenuResponse) => {
                return {
                    children: record.uploaded_image_set && (
                        <img src={record.uploaded_image_set.original_image_url} width={64} height={64} />
                    ),
                };
            },
            width: "70px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: MenuResponse) => {
                return {
                    children: record.name,
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: MenuResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setMenu(record);
                                setModal(undefined);
                                searchForm.setFieldsValue({ menu: record.name });
                            }}
                        >
                            선택
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    const userColumns = [
        {
            title: "휴대폰번호",
            key: "phone",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.phone_number,
                };
            },
            width: "150px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.name,
                };
            },
            width: "125px",
        },
        {
            title: "아이디",
            key: "username",
            render: (record: UserResponse) => {
                return {
                    children: record.username,
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: UserResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setUser(record);
                                setModal(undefined);
                                searchForm.setFieldsValue({ user: record.detail!.name });
                            }}
                        >
                            선택
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    const [searchForm] = Form.useForm();
    const [menuForm] = Form.useForm();
    const [userSearchForm] = Form.useForm();

    const fetchOrderList = (showLoader: boolean = true) => {
        if (showLoader) {
            setLoading(true);
        }
        Promise.all([
            shopApi.getOrders(
                Number(posId),
                menuId,
                orderStatus,
                temperatureType,
                cupType,
                minCreatedDate && moment(minCreatedDate).format("YYYY-MM-DD"),
                maxCreatedDate && moment(maxCreatedDate).format("YYYY-MM-DD"),
                page,
            ),
            shopApi.getOrders(Number(posId)),
        ])
            .then((res) => {
                if (showLoader) {
                    setLoading(false);
                }
                setOrderList(res[0].data);
                setOrderListTotal(parseInt(res[0].headers["x-total-count"], 10));
                setOrderUnfilteredListTotal(parseInt(res[1].headers["x-total-count"], 10));
            })
            .catch(() => {
                if (showLoader) {
                    setLoading(false);
                }
                message.error("주문 목록을 불러오는데 실패하였습니다.");
            });
    };

    const fetchUserList = () => {
        setLoading(true);
        userApi
            .getUsers(
                undefined,
                undefined,
                undefined,
                [UserType.USER, UserType.SHOP_OWNER, UserType.ADMIN],
                undefined,
                undefined,
                undefined,
                undefined,
                modalUserName,
                modalUserPhone,
                undefined,
                undefined,
                undefined,
                undefined,
                modalUserPage,
            )
            .then((res) => {
                setLoading(false);
                setModalUserList(res.data);
                setModalUserListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("회원 목록을 불러오는데 실패하였습니다.");
            });
    };

    const fetchMenuList = () => {
        setLoading(true);
        shopApi
            .getMenus(undefined, modalMenuKeyword, false, undefined, undefined, modalMenuPage)
            .then((res) => {
                setLoading(false);
                setModalMenuList(res.data);
                setModalMenuListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("메뉴 목록을 불러오는데 실패하였습니다.");
            });
    };

    useEffect(() => {
        if (!posId) {
            return;
        }
        if (orderInterval.current) {
            clearInterval(orderInterval.current);
            orderInterval.current = setInterval(() => {
                fetchOrderList(false);
            }, 5000);
        } else {
            orderInterval.current = setInterval(() => {
                fetchOrderList(false);
            }, 5000);
        }
        fetchOrderList();

        return () => {
            if (orderInterval.current) {
                clearInterval(orderInterval.current);
            }
        };
    }, [
        minCreatedDate,
        maxCreatedDate,
        posId,
        menuId,
        username,
        orderStatus,
        temperatureType,
        cupType,
        page,
        rerender,
    ]);

    useEffect(() => {
        fetchUserList();
    }, [modalUserName, modalUserPhone, modalUserPage]);

    useEffect(() => {
        fetchMenuList();
    }, [modalMenuKeyword, modalMenuPage]);

    useEffect(() => {
        if (orderUnfilteredListTotal === null) {
            return;
        }

        if (isFirstLoad) {
            setIsFirstLoad(false);
            return;
        }

        if (orderUnfilteredListTotal === 0) {
            return;
        }

        bellAudio.currentTime = 0;
        bellAudio.play().catch(() => setModal("sound"));

        setTimeout(() => {
            bellAudio.currentTime = 0;
            bellAudio.play().catch(() => setModal("sound"));
        }, 1500);

        notification.info({
            message: "새로운 주문이 접수되었습니다.",
            placement: "bottomRight",
        });
    }, [orderUnfilteredListTotal]);

    return (
        <>
            <PageTitle>주문 관리</PageTitle>
            <Container>
                <Form
                    form={searchForm}
                    onFinish={(values) => {
                        const minCreatedDate = values.date && values.date[0] ? values.date[0].toDate() : undefined;
                        const maxCreatedDate = values.date && values.date[1] ? values.date[1].toDate() : undefined;
                        const menuId = menu ? menu.id : undefined;
                        const username = user ? user.username : undefined;
                        const orderStatus = values.orderStatus || undefined;
                        const temperatureType = values.temperatureType || undefined;
                        const cupType = values.cupType || undefined;

                        setMinCreatedDate(minCreatedDate);
                        setMaxCreatedDate(maxCreatedDate);
                        setMenuId(menuId);
                        setUsername(username);
                        setOrderStatus(orderStatus);
                        setTemperatureType(temperatureType);
                        setCupType(cupType);
                        setPage(1);
                    }}
                >
                    <Left>
                        <Row style={{ marginBottom: "0.5rem" }}>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="menu">
                                    <Input
                                        disabled={loading}
                                        onFocus={(e) => {
                                            setModal("menu");
                                            setModalMenuKeyword(undefined);
                                            setModalMenuPage(1);
                                            menuForm.resetFields();
                                            e.target.blur();
                                        }}
                                        placeholder="메뉴"
                                        readOnly
                                        style={{ width: "200px" }}
                                        value={menu?.name}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="user">
                                    <Input
                                        disabled={loading}
                                        onFocus={(e) => {
                                            setModal("user");
                                            setModalUserName(undefined);
                                            setModalUserPhone(undefined);
                                            setModalUserPage(1);
                                            userSearchForm.resetFields();
                                            e.target.blur();
                                        }}
                                        placeholder="주문자"
                                        readOnly
                                        style={{ width: "200px" }}
                                        value={user && user.detail?.name}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="date">
                                    <RangePicker
                                        disabled={loading}
                                        placeholder={["시작 일자", "끝 일자"]}
                                        style={{ width: "300px" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="orderStatus">
                                    <Select
                                        mode="multiple"
                                        disabled={loading}
                                        placeholder="상태"
                                        style={{ width: "200px" }}
                                    >
                                        <Option value={OrderStatus.MAKE_COMPLETED}>
                                            {orderStatusToText(OrderStatus.MAKE_COMPLETED)}
                                        </Option>
                                        <Option value={OrderStatus.MAKE_IN_PROGRESS}>
                                            {orderStatusToText(OrderStatus.MAKE_IN_PROGRESS)}
                                        </Option>
                                        <Option value={OrderStatus.ORDER_CANCELED}>
                                            {orderStatusToText(OrderStatus.ORDER_CANCELED)}
                                        </Option>
                                        <Option value={OrderStatus.ORDER_COMPLETED}>
                                            {orderStatusToText(OrderStatus.ORDER_COMPLETED)}
                                        </Option>
                                        <Option value={OrderStatus.PICKUP_COMPLETED}>
                                            {orderStatusToText(OrderStatus.PICKUP_COMPLETED)}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="temperatureType">
                                    <Select
                                        mode="multiple"
                                        disabled={loading}
                                        placeholder="온도"
                                        style={{ width: "200px" }}
                                    >
                                        <Option value={TemperatureType.HOT}>
                                            {temperatureTypeToText(TemperatureType.HOT)}
                                        </Option>
                                        <Option value={TemperatureType.ICED}>
                                            {temperatureTypeToText(TemperatureType.ICED)}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="cupType">
                                    <Select
                                        mode="multiple"
                                        disabled={loading}
                                        placeholder="컵 종류"
                                        style={{ width: "200px" }}
                                    >
                                        <Option value={CupType.DISPOSABLE}>{cupTypeToText(CupType.DISPOSABLE)}</Option>
                                        <Option value={CupType.INDIVIDUAL}>{cupTypeToText(CupType.INDIVIDUAL)}</Option>
                                        <Option value={CupType.REUSABLE}>{cupTypeToText(CupType.REUSABLE)}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Left>
                    <Right>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        htmlType="reset"
                                        onClick={() => {
                                            searchForm.resetFields();

                                            setMinCreatedDate(undefined);
                                            setMaxCreatedDate(undefined);
                                            setOrderStatus(undefined);
                                            setTemperatureType(undefined);
                                            setCupType(undefined);
                                            setUser(undefined);
                                            setMenu(undefined);
                                            setUsername(undefined);
                                            setMenuId(undefined);
                                        }}
                                    >
                                        초기화
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button disabled={loading} htmlType="submit">
                                        검색
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        loading={csvLoading}
                                        onClick={() => {
                                            setLoading(true);
                                            setCSVLoading(true);
                                            shopApi
                                                .downloadOrdersCsv({ responseType: "blob" })
                                                .then((res) => {
                                                    setLoading(false);
                                                    setCSVLoading(false);
                                                    const url = URL.createObjectURL(
                                                        new Blob([res.data], { type: res.headers["content-type"] }),
                                                    );
                                                    const link = document.createElement("a");
                                                    link.href = url;
                                                    link.setAttribute("download", "주문.csv");
                                                    document.body.appendChild(link);
                                                    link.click();
                                                })
                                                .catch(() => {
                                                    setLoading(false);
                                                    setCSVLoading(false);
                                                    message.error("엑셀 파일을 불러오는데 실패하였습니다.");
                                                });
                                        }}
                                    >
                                        CSV 다운로드
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Right>
                    <Clear />
                </Form>
            </Container>
            <Container>
                <Table columns={orderColumns} dataSource={orderList} loading={loading} rowKey="id" />
                {orderListTotal !== null && orderListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={orderListTotal}
                    />
                )}
            </Container>
            <LargeModal
                confirmLoading={loading}
                okButtonProps={{
                    style: { display: "none" },
                }}
                onCancel={() => setModal(undefined)}
                title="메뉴 선택"
                visible={modal === "menu"}
                width={800}
            >
                <Form
                    form={menuForm}
                    onFinish={(values) => {
                        if (!values.keyword) {
                            return message.error("검색어를 입력해주세요.", 1.5);
                        }

                        setModalMenuKeyword(values.keyword);
                        setModalMenuPage(1);
                    }}
                >
                    <SearchBarContainer>
                        <Form.Item name="keyword" style={{ marginBottom: "0", marginRight: "0.5rem", width: "100%" }}>
                            <Input placeholder="검색어" style={{ width: "100%" }} />
                        </Form.Item>
                        <Button disabled={loading} htmlType="submit">
                            검색
                        </Button>
                    </SearchBarContainer>
                </Form>
                <Table columns={menuColumns} dataSource={modalMenuList} loading={loading} rowKey="id" />
                {modalMenuListTotal > 0 && (
                    <Pagination
                        current={modalMenuPage}
                        disabled={loading}
                        onChange={(page: number) => setModalMenuPage(page)}
                        pageSize={20}
                        total={modalMenuListTotal}
                    />
                )}
            </LargeModal>
            <LargeModal
                confirmLoading={loading}
                okButtonProps={{
                    disabled: modalOrder && !getOrderStatusProgressMessage(modalOrder.order_status).canProgress,
                }}
                onCancel={() => setModal(undefined)}
                footer={
                    <>
                        <Left>
                            <Button
                                danger
                                onClick={() => {
                                    setModal("cancel");
                                    cancelForm.resetFields();
                                }}
                                type="primary"
                                style={{ marginRight: "0.25rem" }}
                            >
                                주문 취소
                            </Button>
                        </Left>
                        <Right>
                            <Button onClick={() => setModal(undefined)} style={{ marginRight: "0.25rem" }}>
                                취소
                            </Button>
                            <Button form="progressForm" htmlType="submit" loading={loading} type="primary">
                                상태 변경
                            </Button>
                        </Right>
                        <Clear />
                    </>
                }
                title="주문 상태 변경"
                visible={modal === "progress"}
                width={800}
            >
                <Form
                    form={progressForm}
                    name="progressForm"
                    onFinish={(values) => {
                        if (!modalOrder) {
                            return message.error("주문이 선택되지 않았습니다.", 1.5);
                        }
                        if (!values.status) {
                            return message.error("상태를 선택해주세요.", 1.5);
                        }

                        setLoading(true);
                        shopApi
                            .setOrderStatus(modalOrder.id, { order_status: values.status })
                            .then(() => {
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("주문 상태를 변경하였습니다.", 1.5);
                                if (values.status === OrderStatus.MAKE_IN_PROGRESS) {
                                    // setModal("print");
                                    handleClickPrint(modalOrder);
                                } else {
                                    setModal(undefined);
                                    setModalOrder(undefined);
                                }
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="상태" name="status">
                        <Select
                            disabled={loading}
                            placeholder="상태"
                            style={{ width: "100%" }}
                            onClick={() => {
                                const list = document.getElementById("progressForm_status_list");
                                (list!.parentNode!.parentNode! as HTMLElement).style.transform = "scale(2.0)";
                            }}
                        >
                            <Option value={OrderStatus.MAKE_IN_PROGRESS}>
                                {orderStatusToText(OrderStatus.MAKE_IN_PROGRESS)}
                            </Option>
                            <Option value={OrderStatus.MAKE_COMPLETED}>
                                {orderStatusToText(OrderStatus.MAKE_COMPLETED)}
                            </Option>
                            <Option value={OrderStatus.PICKUP_COMPLETED}>
                                {orderStatusToText(OrderStatus.PICKUP_COMPLETED)}
                            </Option>
                        </Select>
                    </FormItem>
                </Form>
            </LargeModal>
            <LargeModal
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                    htmlType: "submit",
                    form: "cancelForm",
                }}
                onCancel={() => setModal(undefined)}
                title="주문 취소"
                visible={modal === "cancel"}
                width={800}
            >
                <Form
                    form={cancelForm}
                    name="cancelForm"
                    onFinish={(values) => {
                        if (!modalOrder) {
                            return message.error("주문이 선택되지 않았습니다.", 1.5);
                        }
                        if (!values.reason) {
                            return message.error("취소 사유를 입력해주세요.", 1.5);
                        }

                        setLoading(true);
                        shopApi
                            .setOrderStatus(modalOrder.id, {
                                order_status: OrderStatus.ORDER_CANCELED,
                                order_cancel_reason: values.reason,
                            })
                            .then(() => {
                                setModal(undefined);
                                setModalOrder(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("주문을 취소하였습니다", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="취소 사유" name="reason">
                        <Input disabled={loading} placeholder="취소 사유" />
                    </FormItem>
                </Form>
            </LargeModal>
            <LargeModal
                confirmLoading={loading}
                okButtonProps={{
                    style: { display: "none" },
                }}
                onCancel={() => setModal("add")}
                title="회원 선택"
                visible={modal === "user"}
                width={800}
            >
                <Form
                    form={userSearchForm}
                    onFinish={(values) => {
                        setModalUserName(values.name);
                        setModalUserPhone(values.phone);
                        setModalUserPage(1);
                    }}
                >
                    <SearchBarContainer>
                        <Form.Item name="phone" style={{ marginBottom: "0", marginRight: "0.5rem", flexGrow: 1 }}>
                            <Input placeholder="휴대폰번호" style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item name="name" style={{ marginBottom: "0", marginRight: "0.5rem", flexGrow: 1 }}>
                            <Input placeholder="이름" style={{ width: "100%" }} />
                        </Form.Item>
                        <Button disabled={loading} htmlType="submit">
                            검색
                        </Button>
                    </SearchBarContainer>
                    <Table columns={userColumns} dataSource={modalUserList} loading={loading} rowKey="id" />
                    {modalUserListTotal > 0 && (
                        <Pagination
                            current={modalUserPage}
                            disabled={loading}
                            onChange={(page: number) => setModalUserPage(page)}
                            pageSize={20}
                            total={modalUserListTotal}
                        />
                    )}
                </Form>
            </LargeModal>
            <LargeModal
                okText="출력"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    setModal(undefined);
                    if (modalOrder) {
                        handleClickPrint(modalOrder);
                    }
                }}
                title="주문서 출력"
                visible={modal === "print"}
                width={400}
            >
                주문서를 출력하시겠습니까?
            </LargeModal>
            <LargeModal
                cancelText="닫기"
                okButtonProps={{ style: { display: "none" } }}
                onCancel={() => setModal(undefined)}
                title="소리 권한"
                visible={modal === "sound"}
                width={400}
            >
                <p>소리를 재생하려면 권한이 필요합니다.</p>
                <p>상단의 URL바에 'i' 버튼을 클릭한 뒤, 사이트 설정에서 소리 권한을 허용해 주시기 바랍니다.</p>
            </LargeModal>
        </>
    );
}
