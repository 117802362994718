import React from "react";
import styled from "@emotion/styled";
import { Empty, Spin } from "antd";

type Align = "left" | "center" | "right";

const Table = styled.table`
    border-radius: 0.25rem;
    width: 100%;
    overflow: hidden;
    font-size: 14px;
`;

const Col = styled.col``;

const THeadTR = styled.tr`
    background-color: #f4f4f4;
`;

const TH = styled.th<{ align?: Align }>`
    padding: 1rem;
    text-align: ${(props) => props.align || "left"};
`;

const TBodyTR = styled.tr<{ disableHover?: boolean; isClickable?: boolean }>`
    cursor: ${(props) => (props.isClickable ? "pointer" : "auto")};
    &:nth-child(2n) {
        background-color: #f8f8f8;
    }
    ${(props) => !props.disableHover && "&:hover { background-color: #eeeeee; }"}
`;

const TD = styled.td<{ align?: Align }>`
    padding: 1rem;
    text-align: ${(props) => props.align || "left"};
`;

interface ExtraData {
    isRowExpanded?: boolean;
}

interface Expandable {
    isExpandable: (record: any, rowIndex: number) => boolean;
    render: (record: any, rowIndex: number) => React.ReactNode;
    type: "button" | "row";
}

interface Cell {
    children: string | React.ReactElement | React.ReactElement[] | undefined;
    rowSpan?: number;
}

interface Column {
    key: string;
    render: (record: any, rowIndex: number, extra: ExtraData) => Cell;
    title: string;
    width?: string;
    headerAlign?: Align;
    contentAlign?: Align;
}

interface Props {
    columns: Column[];
    dataSource: any[];
    expandable?: Expandable;
    loading: boolean;
    rowKey: string;
}

export default function Component(props: Props): React.ReactElement {
    const [isExpanded, setIsExpanded] = React.useState<boolean[]>([]);

    React.useEffect(() => {
        setIsExpanded(new Array(props.dataSource.length).fill(false));
    }, [props.dataSource]);

    let children;
    if (props.dataSource.length === 0) {
        children = (
            <TBodyTR disableHover>
                <TD colSpan={props.columns.length} style={{ textAlign: "center" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </TD>
            </TBodyTR>
        );
    } else {
        children = props.dataSource.map((row, rowIndex) => (
            <>
                <TBodyTR
                    isClickable={props.expandable && props.expandable.type === "row"}
                    key={row[props.rowKey]}
                    onClick={() => {
                        if (props.expandable && props.expandable.type === "row") {
                            const isExpandedNew = [...isExpanded];
                            isExpandedNew[rowIndex] = !isExpanded[rowIndex];
                            setIsExpanded(isExpandedNew);
                        }
                    }}
                >
                    {props.columns.map((column) => (
                        <TD align={column.headerAlign} key={column.key}>
                            {
                                column.render(row, rowIndex, {
                                    isRowExpanded: props.expandable ? isExpanded[rowIndex] : undefined,
                                }).children
                            }
                        </TD>
                    ))}
                </TBodyTR>
                {props.expandable && props.expandable.isExpandable(row, rowIndex) && isExpanded[rowIndex] && (
                    <TBodyTR disableHover key={`${row[props.rowKey]}-expandable`}>
                        <TD colSpan={props.columns.length}>{props.expandable.render(row, rowIndex)}</TD>
                    </TBodyTR>
                )}
            </>
        ));
    }

    return (
        <Spin spinning={props.loading} tip="불러오는 중...">
            <Table>
                <colgroup>
                    {props.columns.map((column) => (
                        <Col key={column.key} width={column.width} />
                    ))}
                </colgroup>
                <thead>
                    <THeadTR>
                        {props.columns.map((column) => (
                            <TH align={column.headerAlign} key={column.key}>
                                {column.title}
                            </TH>
                        ))}
                    </THeadTR>
                </thead>
                <tbody>{children}</tbody>
            </Table>
        </Spin>
    );
}
