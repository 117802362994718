import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Button } from "@mui/material";
import CheckIcon from "@/assets/img/ic_check_yellow.svg";

interface StyledBottomSheetCategoryButtonProps {
    selected?: boolean;
}

export const StyledBottomSheetCategoryButton = styled(Button)<StyledBottomSheetCategoryButtonProps>`
    width: calc(100% + 40px);
    display: flex;
    justify-content: space-between;
    height: 56px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0;

    color: #8f8f8f;
    font-size: 16px;

    ${({ selected }) =>
        selected &&
        css`
            color: #ff831e;
        `}
`;

interface BottomSheetCategoryButtonProps<ValueType> {
    value: ValueType;
    selected?: boolean;
    formatValue: (value: ValueType) => string;
    onChange: (newValue: ValueType) => void;
    className?: string;
}

export default function BottomSheetCategoryButton<ValueType>({
    value,
    selected,
    formatValue,
    onChange,
    className,
}: BottomSheetCategoryButtonProps<ValueType>) {
    return (
        <StyledBottomSheetCategoryButton className={className} selected={selected} onClick={() => onChange(value)}>
            {formatValue(value)} {selected && <img src={CheckIcon} alt="check icon" />}
        </StyledBottomSheetCategoryButton>
    );
}
