import { TextFieldProps } from "@mui/material";
import React from "react";
import { DateTextFieldBase } from "./style";

interface DateTextFieldProps {
    marginBottom?: number;
    last?: boolean;
}

export const DateTextField = (props: DateTextFieldProps & TextFieldProps) => {
    return <DateTextFieldBase variant="filled" {...props} />;
};
