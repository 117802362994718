import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ButtonBase } from "@mui/material";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 48px);
`;

interface ResultItemWrapperProps {
    open: boolean;
}

export const ResultItemWrapper = styled(ButtonBase)<ResultItemWrapperProps>`
    padding: 0 20px;
    position: relative;
    display: flex;
    align-items: unset;
    justify-content: unset;
    flex-direction: column;
    width: 100%;
    text-align: left;
    overflow: hidden;

    ${({ open }) =>
        open
            ? css`
                  background-color: #ff831e;
              `
            : css`
                  max-height: 100px;
              `};
`;

export const ResultItemTitleWrapper = styled.div`
    position: relative;
    width: 100%;
    border-bottom: 1px solid #d1d1d1;
    padding: 24px 0;
`;

export const ResultItemTopWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

interface ResultItemTopTag {
    type: "accept" | "answer";
}

const ResultItemTopTagStyle = (type: "accept" | "answer") => {
    if (type === "accept") {
        return css`
            border: 1px solid #ff831e;
            color: #ff831e;
        `;
    }
    if (type === "answer") {
        return css`
            border: 1px solid #ff831e;
            background-color: #ff831e;
            color: #000;
        `;
    }
};

export const ResultItemTopTag = styled.p<ResultItemTopTag>`
    line-height: 18px;
    padding: 0 6px;
    font-size: 10px;
    border-radius: 4px;
    margin: 0;

    ${({ type }) => ResultItemTopTagStyle(type)}
`;

export const ResultItemTopDate = styled.p`
    font-size: 12px;
    color: #757575;
    margin: 0 0 0 12px;
`;

export const ResultItemTitle = styled.p`
    font-size: 14px;
    color: #ff831e;
    margin: 0;
`;

export const ResultItemArrow = styled.img`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
`;

export const ResultItemContentWrapper = styled.div`
    width: 100%;
    padding: 24px 0 32px 0;

    > p {
        margin: 0;
    }
`;

export const ResultItemImage = styled.img`
    width: 100%;
    margin-top: 8px;
`;

export const ResultItemAnswerWrapper = styled.div`
    border-top: 1px solid #757575;
    padding-top: 32px;
    margin-top: 32px;
`;

export const ResultItemAnswerDate = styled.p`
    font-size: 12px;
    color: #757575;
    margin: 24px 0 0 0;
`;

export const QuestionDeleteButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const QuestionDeleteButton = styled(ButtonBase)`
    padding: 4px 10px;
    color: #ff831e;
    background-color: #ff831e;
    border: 1px solid #ff831e;
    border-radius: 8px;
    margin-top: 12px;
`;

export const DeleteModalText = styled.p`
    color: #0c0c0c;
    margin: 8px 0 30px 0;
    text-align: center;
`;
