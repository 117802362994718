import { formatISO } from "date-fns";

export const padStart = (data: any, length: number, target: any): string => {
    return data.toString().padStart(length, target.toString());
};

interface DateToObject {
    year: number;
    month: number;
    date: number;
    hour: number;
    minutes: number;
    day: number;
}

export const dateToObject = (date: Date): DateToObject => {
    // Convert Date To Object
    const targetDate = new Date(date);
    const result: DateToObject = {
        year: targetDate.getFullYear(),
        month: targetDate.getMonth() + 1,
        date: targetDate.getDate(),
        hour: targetDate.getHours(),
        minutes: targetDate.getMinutes(),
        day: targetDate.getDay(),
    };

    return result;
};

interface DateCalculatorParams {
    target: Date;
    date?: number;
    hour?: number;
    minutes?: number;
}

export const dateCalculator = ({ target, date = 0, hour = 0, minutes = 0 }: DateCalculatorParams): Date => {
    let result = target.getTime() || new Date().getTime();
    const ms_minutes = 1000 * 60;
    const ms_hour = ms_minutes * 60;
    const ms_date = ms_hour * 24;

    result += ms_minutes * minutes;
    result += ms_hour * hour;
    result += ms_date * date;

    return new Date(result);
};

export const dateFormatter = (dateObj: Date | null, format: string): string => {
    if (!dateObj) {
        return "";
    }

    const DAYS = ["일", "월", "화", "수", "목", "금", "토"] as const;
    const { year, month, date, hour, minutes, day } = dateToObject(dateObj);

    format = format.replace("YYYY", padStart(year, 4, "0"));

    format = format.replace("MM", padStart(month, 2, "0"));

    format = format.replace("DD", padStart(date, 2, "0"));

    format = format.replace("d", DAYS[day]);

    format = format.replace("hh", padStart(hour, 2, "0"));

    format = format.replace("d", padStart(DAYS[day], 4, "0"));

    format = format.replace("mm", padStart(minutes, 2, "0"));

    return format;
};

export function getDateIfExists(dateString?: string) {
    if (!dateString) {
        return;
    }

    return new Date(dateString);
}

export function formatISODate(date: Date) {
    return formatISO(date, {
        representation: "date",
    });
}
