import { FormikProps, useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SocialType } from "@ferda/rest-api-client/dist/api";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { Gender } from "@ferda/rest-api-client";
import { useSnackbar } from "notistack";
import useHistory from "@/hooks/useCustomHistory";
import {
    CheckAllCheckboxLabel,
    CodeInputErrorText,
    GuideTextWrapper,
    GuideText,
    LoginButton,
    LoginFinishModalText,
    LoginInputButton,
    LoginInputEndAdornment,
    LoginInputItem,
    LoginInputTitle,
    LoginInputWrapper,
    LoginSuccessModalText,
    LoginWrapper,
    TermsCheckbox,
    TermsWrapper,
    OtherInfoGuideText,
    OtherInfoGenderWrapper,
    OtherInfoGenderItem,
    OtherInfoGuideWarningText,
    OtherInfoInputTitleText,
    OtherInfoInputWrapper,
    OtherInfoJobItem,
    OtherInfoInputBase,
} from "./style";
import ModalCheckImage from "@/assets/img/img_confirm_modal_check.svg";
import ModalCongratsImage from "@/assets/img/img_congrats.svg";
import { StyledIconButton } from "@/components/IconButton";
import InputBase from "@/components/Input";
import Modal from "@/components/Modal";
import AppLayout from "@/containers/AppLayout";
import { useTimer } from "@/utils/hooks";
import { formatPhoneVerificationState, PhoneVerificationRequestStatusType } from "@/utils/phoneVerification";
import {
    generateUserTokenFetchRequestThunk,
    phoneVerificationFetchRequestThunk,
    verifyPhoneVerificationCodeFetchRequestThunk,
} from "@/redux/login/thunk";
import {
    getGenerateUserTokenFetchState,
    getIsFirstLogin,
    getUserExist,
    getVerifyPhoneVerificationCodeIsVerified,
} from "@/redux/login/selectors";
import { useQuery } from "@/hooks/useQuery";
import { checkUserExistFetchRequestThunk } from "../../redux/login/thunk";
import BtnBackIcon from "@/assets/img/ic_btn_back.svg";
import { GenerateUserTokenFetchRequestPayload } from "@/redux/login/types";
import { resetLoginState } from "@/redux/login/actions";
import { getAuthState, getRestApiClient } from "@/redux/auth/selectors";
import Checkbox from "@/components/Checkbox";
import Select, { SelectItemsType } from "@/components/Select";
import { editUserInfoThunk } from "@/redux/user/thunk";
import { getDateIfExists } from "@/utils/date";
import { getEditUserInfoFetchState } from "@/redux/user/selectors";
import { resetUserState } from "@/redux/user/actions";
import { loadAuthStateAndApiClientThunk } from "@/redux/auth/thunk";

export interface ILoginForm {
    phoneNumber: string;
    authNumber: string;
    agreeAll: boolean;
    servicePolicy: boolean;
    privacyPolicy: boolean;
    locationPolicy: boolean;
    marketingPolicy: boolean;
}

const OTHER_INFO_JOB_LIST: SelectItemsType = [
    { label: "경영/사무/기획", value: "경영/사무/기획" },
    { label: "마케팅/홍보/PR", value: "마케팅/홍보/PR" },
    { label: "영업/판매", value: "영업/판매" },
    { label: "IT/컴퓨터 관련", value: "IT/컴퓨터 관련" },
    { label: "디자인/예술", value: "디자인/예술" },
    { label: "건설/시공", value: "건설/시공" },
    { label: "의료/보건", value: "의료/보건" },
    { label: "교육/연구", value: "교육/연구" },
    { label: "제조/생산", value: "제조/생산" },
    { label: "기타 (입력)", value: "기타" },
];

export default function Login() {
    const dispatch = useDispatch();
    const { user } = useSelector(getAuthState);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const timer = useTimer(3 * 60);
    const location = useLocation();
    const verifyPhoneVerificationCodeIsVerified = useSelector(getVerifyPhoneVerificationCodeIsVerified);
    const KAKAO_CODE = useQuery(location.search, "code");
    const [requestStatus, setRequestStatus] = useState<PhoneVerificationRequestStatusType>("PREPARE");
    const [requestTime, setRequestTime] = useState<string | null>(null);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [finishModal, setFinishModal] = useState<boolean>(false);
    const [pauseRedirect, setPauseRedirect] = useState<boolean>(false);
    const [isShowOtherInfoModal, setIsShowOtherInputModal] = useState(false); // 기타 정보 입력 모달
    const [otherInfoGender, setOtherInfoGender] = useState<Gender | null>(null);
    const [otherInfoAge, setOtherInfoAge] = useState("");
    const [otherInfoJob, setOtherInfoJob] = useState<string>(OTHER_INFO_JOB_LIST[0].value as string);
    const [otherInfoJobUserValue, setOtherInfoJobUserValue] = useState<string>("");

    const IS_EXISTS = useSelector(getUserExist);
    const GENERATE_TOKEN_FETCH_STATE = useSelector(getGenerateUserTokenFetchState);
    const IS_FIRST_LOGIN = useSelector(getIsFirstLogin);
    const authState = useSelector(getAuthState);
    const editUserInfoFetchState = useSelector(getEditUserInfoFetchState);

    const handleSuccessModalClose = () => setSuccessModal(false);

    const handleClickBackButton = () => {
        history.push("/signup");
    };

    const handleFinishModalClose = () => history.replace("/");

    const formik: FormikProps<ILoginForm> = useFormik<ILoginForm>({
        initialValues: {
            authNumber: "",
            phoneNumber: "",
            agreeAll: false,
            servicePolicy: false,
            privacyPolicy: false,
            locationPolicy: false,
            marketingPolicy: false,
        },
        onSubmit: () => {
            //
        },
    });
    const { getFieldProps, setFieldValue, values } = formik;

    // 인증번호 전송
    const onClickRequest = useCallback(() => {
        timer.startTimer();
        setRequestStatus("IN_PROGRESS");
        dispatch(phoneVerificationFetchRequestThunk({ phoneNumber: values.phoneNumber }));
    }, [dispatch, timer]);

    // 인증번호 확인
    const onClickVerificationNumber = (): void => {
        const { phoneNumber, authNumber } = values;

        dispatch(verifyPhoneVerificationCodeFetchRequestThunk({ phoneNumber, verificationCode: authNumber }));
    };

    // 회원가입
    const onClickLogin = () => {
        const payload: GenerateUserTokenFetchRequestPayload = {
            username: KAKAO_CODE ? "KAKAO" : values.phoneNumber,
            password: KAKAO_CODE || values.authNumber,
            verificationCode: values.authNumber,
            phoneNumber: values.phoneNumber,
            consentReceiveEmail: values.marketingPolicy,
            consentReceiveSms: values.marketingPolicy,
            consentReceiveSnsMessage: values.marketingPolicy,
        };

        if (KAKAO_CODE) {
            payload.socialLoginAuthentication = {
                access_token: KAKAO_CODE,
                social_type: SocialType.KAKAO,
            };
        }
        setPauseRedirect(true);
        dispatch(generateUserTokenFetchRequestThunk(payload));
    };

    const getErrorCodeText = () => {
        if (requestStatus === "INVALID_AUTH_NUMBER") {
            return "인증번호가 올바르지 않습니다";
        }

        if (requestStatus === "TIME_OUT") {
            return "인증번호 확인 시간을 초과했습니다";
        }
    };

    const handleChangeOtherInfoJob = (e: SelectChangeEvent<unknown>) => {
        if (e.target.value !== "기타") {
            setOtherInfoJobUserValue("");
        }

        setOtherInfoJob(e.target.value as string);
    };

    const isDisabledSubmitOtherInfoForm = useMemo(() => {
        return !otherInfoGender || !otherInfoAge || (otherInfoJob === "기타" && !otherInfoJobUserValue);
    }, [otherInfoGender, otherInfoAge, otherInfoJob, otherInfoJobUserValue]);

    const handleClickSubmitOtherInfoForm = () => {
        if (isDisabledSubmitOtherInfoForm || !user) {
            return;
        }

        const year = new Date().getFullYear();
        const birthDate = new Date(`${year - Number(otherInfoAge)}-01-01`);

        const payload: {
            gender?: Gender;
            birthDate?: Date;
            job?: string;
        } = {
            birthDate,
            gender: otherInfoGender!,
        };

        if (otherInfoJob === "기타") {
            payload.job = otherInfoJobUserValue;
        } else {
            payload.job = otherInfoJob;
        }

        dispatch(editUserInfoThunk(payload));
    };

    const contentRenderer = () => {
        if (isShowOtherInfoModal) {
            return (
                <LoginWrapper>
                    <GuideTextWrapper>
                        <OtherInfoGuideText>
                            마지막 단계예요.
                            <br />
                            워크무드는 직장인을 위한 플랫폼으로 나아가기 위해 회원님들의 간단한 정보가 필요해요.
                            <br />
                            앞으로 직장인 커뮤니티 생성 및 더 나은 서비스 제공을 위해 간단한 소개 부탁드려요.
                        </OtherInfoGuideText>
                        <OtherInfoGuideWarningText>*단순 마케팅 정보 수집 및 회원 관리 목적</OtherInfoGuideWarningText>
                    </GuideTextWrapper>

                    <OtherInfoInputWrapper>
                        <OtherInfoInputTitleText>성별</OtherInfoInputTitleText>
                        <OtherInfoGenderWrapper>
                            <OtherInfoGenderItem>
                                <Checkbox
                                    iconType="circle"
                                    label={<CheckAllCheckboxLabel>남</CheckAllCheckboxLabel>}
                                    value={otherInfoGender === Gender.MALE}
                                    onClick={() => setOtherInfoGender(Gender.MALE)}
                                />
                            </OtherInfoGenderItem>
                            <OtherInfoGenderItem>
                                <Checkbox
                                    iconType="circle"
                                    label={<CheckAllCheckboxLabel>여</CheckAllCheckboxLabel>}
                                    value={otherInfoGender === Gender.FEMALE}
                                    onClick={() => setOtherInfoGender(Gender.FEMALE)}
                                />
                            </OtherInfoGenderItem>
                        </OtherInfoGenderWrapper>
                    </OtherInfoInputWrapper>

                    <OtherInfoInputWrapper>
                        <OtherInfoInputTitleText>나이</OtherInfoInputTitleText>
                        <OtherInfoGenderItem>
                            <OtherInfoInputBase
                                type="number"
                                maxLength={2}
                                placeholder="직접입력"
                                value={otherInfoAge}
                                onChange={(e) => setOtherInfoAge(e.target.value)}
                            />
                        </OtherInfoGenderItem>
                    </OtherInfoInputWrapper>

                    <OtherInfoInputWrapper>
                        <OtherInfoInputTitleText>직종</OtherInfoInputTitleText>
                        <OtherInfoJobItem>
                            <Select
                                showSelectValueType="label"
                                items={OTHER_INFO_JOB_LIST}
                                value={otherInfoJob}
                                onChange={handleChangeOtherInfoJob}
                                placeholder="유형"
                            />
                        </OtherInfoJobItem>
                        {otherInfoJob === "기타" && (
                            <OtherInfoJobItem>
                                <OtherInfoInputBase
                                    type="text"
                                    placeholder="직접입력"
                                    value={otherInfoJobUserValue}
                                    onChange={(e) => setOtherInfoJobUserValue(e.target.value)}
                                />
                            </OtherInfoJobItem>
                        )}
                    </OtherInfoInputWrapper>

                    <LoginButton
                        variant="contained"
                        disabled={isDisabledSubmitOtherInfoForm}
                        onClick={handleClickSubmitOtherInfoForm}
                    >
                        제출하기
                    </LoginButton>
                </LoginWrapper>
            );
        }

        return (
            <LoginWrapper>
                <GuideTextWrapper>
                    <GuideText>
                        휴대전화번호를 등록하셔야 서비스 이용이 가능합니다.
                        <br />
                        개인정보 수탁사:NHN
                        <br />
                        업무의 내용 : 인증번호 문자 발송 대행
                    </GuideText>
                </GuideTextWrapper>

                <LoginInputWrapper>
                    <LoginInputTitle>휴대전화번호 등록</LoginInputTitle>

                    <LoginInputItem>
                        <InputBase
                            type="number"
                            maxLength={11}
                            {...getFieldProps("phoneNumber")}
                            placeholder="휴대폰번호를 입력하세요"
                            readOnly={requestStatus !== "PREPARE"}
                        />
                        <LoginInputButton
                            onClick={onClickRequest}
                            disabled={values.phoneNumber.length < 11 || requestStatus === "CONFIRMED"}
                        >
                            {formatPhoneVerificationState(requestStatus)}
                        </LoginInputButton>
                    </LoginInputItem>
                    {["PREPARE"].indexOf(requestStatus) < 0 && (
                        <div>
                            <LoginInputItem>
                                <InputBase
                                    type="number"
                                    maxLength={6}
                                    {...getFieldProps("authNumber")}
                                    placeholder="인증번호"
                                    error={requestStatus === "INVALID_AUTH_NUMBER" || requestStatus === "TIME_OUT"}
                                    endAdornment={<LoginInputEndAdornment>{requestTime}</LoginInputEndAdornment>}
                                    readOnly={requestStatus === "CONFIRMED"}
                                />
                                <LoginInputButton
                                    onClick={onClickVerificationNumber}
                                    disabled={
                                        values.authNumber.length < 6 ||
                                        requestStatus === "TIME_OUT" ||
                                        requestStatus === "CONFIRMED"
                                    }
                                >
                                    인증확인
                                </LoginInputButton>
                            </LoginInputItem>
                            <CodeInputErrorText>{getErrorCodeText()}</CodeInputErrorText>
                        </div>
                    )}
                </LoginInputWrapper>

                <TermsWrapper>
                    <TermsCheckbox
                        iconType="circle"
                        label={<CheckAllCheckboxLabel>전체 동의</CheckAllCheckboxLabel>}
                        {...getFieldProps("agreeAll")}
                    />
                    <TermsCheckbox label="서비스 이용약관 (필수)" {...getFieldProps("servicePolicy")} />
                    <TermsCheckbox label="개인정보 처리방침 (필수)" {...getFieldProps("privacyPolicy")} />
                    <TermsCheckbox label="위치기반 서비스 이용 동의 (필수)" {...getFieldProps("locationPolicy")} />
                    <TermsCheckbox label="마케팅 활용 동의 (선택)" {...getFieldProps("marketingPolicy")} />
                </TermsWrapper>

                <LoginButton
                    variant="contained"
                    disabled={
                        !(
                            requestStatus === "CONFIRMED" &&
                            values.servicePolicy &&
                            values.privacyPolicy &&
                            values.locationPolicy
                        )
                    }
                    onClick={onClickLogin}
                >
                    다음 단계
                </LoginButton>
            </LoginWrapper>
        );
    };

    useEffect(() => {
        if (editUserInfoFetchState === "SUCCESS") {
            dispatch(
                loadAuthStateAndApiClientThunk(() => {
                    if (IS_FIRST_LOGIN) {
                        setFinishModal(true);
                        return;
                    }

                    history.replace("/");
                }),
            );
        }

        if (editUserInfoFetchState === "FAILURE") {
            enqueueSnackbar("잠시 후 다시 시도 해주세요.", {
                variant: "error",
            });
        }
    }, [editUserInfoFetchState]);

    useEffect(() => {
        return () => {
            dispatch(resetUserState());
        };
    }, []);

    useEffect(() => {
        if (!pauseRedirect && authState.type === "AUTHORIZED") {
            history.replace("/");
        }
    }, [authState, pauseRedirect, history]);

    // 카카오 로그인인 경우 Check User Exists
    useEffect(() => {
        if (KAKAO_CODE) {
            dispatch(
                checkUserExistFetchRequestThunk({
                    socialLoginData: { access_token: KAKAO_CODE, social_type: SocialType.KAKAO },
                }),
            );
        }
    }, [KAKAO_CODE]);

    // 카카오 로그인인 경우 Generate Token 처리
    useEffect(() => {
        if (IS_EXISTS === null) {
            return;
        }

        if (!KAKAO_CODE) {
            return;
        }

        if (requestStatus === "PREPARE" && IS_EXISTS) {
            const payload: GenerateUserTokenFetchRequestPayload = {
                username: "KAKAO",
                password: KAKAO_CODE,
                consentReceiveEmail: values.marketingPolicy,
                consentReceiveSms: values.marketingPolicy,
                consentReceiveSnsMessage: values.marketingPolicy,
            };
            dispatch(generateUserTokenFetchRequestThunk(payload));
        }
    }, [IS_EXISTS]);

    // 인증번호 확인 Thunk
    useEffect(() => {
        if (verifyPhoneVerificationCodeIsVerified === null) {
            return;
        }
        if (!verifyPhoneVerificationCodeIsVerified) {
            setRequestStatus("INVALID_AUTH_NUMBER");
            return;
        }

        setRequestTime(null);
        timer.stopTimer();
        setSuccessModal(true);
        setRequestStatus("CONFIRMED");
    }, [dispatch, verifyPhoneVerificationCodeIsVerified]);

    // 토큰 발급 완료
    useEffect(() => {
        if (GENERATE_TOKEN_FETCH_STATE === "SUCCESS") {
            setIsShowOtherInputModal(true);
        } else if (GENERATE_TOKEN_FETCH_STATE !== "FETCHING") {
            setPauseRedirect(false);
        }
    }, [GENERATE_TOKEN_FETCH_STATE, IS_FIRST_LOGIN]);

    // Timer
    useEffect(() => {
        const time = timer.timeDescription;

        if (time) {
            setRequestTime(time);
        }

        if (timer.timeLeft === 0) {
            setRequestStatus("TIME_OUT");
        }
    }, [timer.timeDescription]);

    // Checkbox
    useEffect(() => {
        const { servicePolicy, privacyPolicy, locationPolicy, marketingPolicy } = values;

        setFieldValue("agreeAll", servicePolicy && privacyPolicy && locationPolicy && marketingPolicy);
    }, [values.servicePolicy, values.privacyPolicy, values.locationPolicy, values.marketingPolicy]);

    // Checkbox
    useEffect(() => {
        const { agreeAll, servicePolicy, privacyPolicy, locationPolicy, marketingPolicy } = values;

        if (agreeAll) {
            setFieldValue("servicePolicy", true);
            setFieldValue("privacyPolicy", true);
            setFieldValue("locationPolicy", true);
            setFieldValue("marketingPolicy", true);
            return;
        }

        if (servicePolicy && privacyPolicy && locationPolicy && marketingPolicy) {
            setFieldValue("servicePolicy", false);
            setFieldValue("privacyPolicy", false);
            setFieldValue("locationPolicy", false);
            setFieldValue("marketingPolicy", false);
        }
    }, [values.agreeAll]);

    useEffect(() => {
        return () => {
            dispatch(resetLoginState());
        };
    }, []);

    return (
        <AppLayout
            title="회원가입"
            appBarBackgroundColor="#ff831e"
            titleColor="#fff"
            disableAppBarBorder={true}
            leadingIcon={<StyledIconButton onClick={handleClickBackButton} src={BtnBackIcon} alt="back" />}
        >
            <Modal
                open={successModal}
                handleClose={handleSuccessModalClose}
                buttonOnClick={handleSuccessModalClose}
                topImage={ModalCheckImage}
                titleText="본인 인증 완료!"
                titleMarginBottom={8}
                buttonText="확인"
                fullSizeButton
                imageHeight={90}
            >
                <LoginSuccessModalText>
                    본인인증이 완료되었습니다
                    <br />
                    다음 단계를 진행해주세요
                </LoginSuccessModalText>
            </Modal>
            <Modal
                open={finishModal}
                handleClose={handleFinishModalClose}
                buttonOnClick={handleFinishModalClose}
                topImage={ModalCongratsImage}
                titleText="가입을 환영해요!"
                titleMarginBottom={12}
                buttonText="홈으로"
                fullSize
                imageHeight={220}
            >
                <LoginFinishModalText>
                    회원가입이 완료되었습니다.
                    <br />
                    워크무드에서 다양한 혜택을 누려보세요
                </LoginFinishModalText>
            </Modal>
            {contentRenderer()}
        </AppLayout>
    );
}
