import { CheckboxProps as MuiCheckboxProps } from "@mui/material";
import React from "react";
import { CheckboxLabel, CheckboxWrapper, StyledCheckbox, Description } from "./style";
import IconCheckboxChecked from "@/assets/img/ic_checkbox_checked.svg";
import IconCheckboxUnChecked from "@/assets/img/ic_checkbox_unchecked.svg";
import IconCircleCheckboxChecked from "@/assets/img/ic_circle_checkbox_checked.svg";
import IconCircleCheckboxUnChecked from "@/assets/img/ic_circle_checkbox_unchecked.svg";
import IconSquareCircleCheckboxChecked from "@/assets/img/ic_square_checkbox_checked.svg";
import IconSquareCircleCheckboxUnChecked from "@/assets/img/ic_square_checkbox_unchecked.svg";
import IconRedSquareCircleCheckboxChecked from "@/assets/img/btn_checkbox_selected.svg";

type CheckboxIconType = "circle" | "filled" | "square" | "red_square";

interface CheckboxProps extends MuiCheckboxProps {
    label: string | React.ReactNode;
    iconType?: "circle" | "filled" | "square" | "red_square";
    value?: boolean;
    labelBold?: boolean;
    labelColor?: string;
    description?: string;
    marginBottom?: number;
}

function getCheckedIcon(iconType: CheckboxIconType) {
    switch (iconType) {
        case "circle":
            return IconCircleCheckboxChecked;
        case "filled":
            return IconCheckboxChecked;
        case "square":
            return IconSquareCircleCheckboxChecked;
        case "red_square":
            return IconRedSquareCircleCheckboxChecked;
    }
}

function getUncheckedIcon(iconType: CheckboxIconType) {
    switch (iconType) {
        case "circle":
            return IconCircleCheckboxUnChecked;
        case "filled":
            return IconCheckboxUnChecked;
        case "square":
            return IconSquareCircleCheckboxUnChecked;
        case "red_square":
            return IconSquareCircleCheckboxUnChecked;
    }
}

export default function Checkbox({
    label,
    iconType = "filled",
    value,
    labelBold,
    labelColor,
    className,
    description,
    marginBottom,
    ...args
}: CheckboxProps) {
    return (
        <CheckboxWrapper className={className} marginBottom={marginBottom}>
            <StyledCheckbox
                checked={value}
                icon={<img src={getUncheckedIcon(iconType)} />}
                checkedIcon={<img src={getCheckedIcon(iconType)} />}
                {...args}
            />
            <CheckboxLabel bold={labelBold} color={labelColor}>
                {label}
            </CheckboxLabel>
            {description && <Description>{description}</Description>}
        </CheckboxWrapper>
    );
}
