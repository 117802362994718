import styled from "@emotion/styled";

export const InfoContainer = styled.div`
    margin-bottom: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const InfoTitleContainer = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
`;

export const SearchBarContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const FormLabelText = styled.p`
    font-size: 1rem;
    font-weight: bold;
`;

export const SwitchContainer = styled.div`
    margin-bottom: 0.5rem;
    &:last-child {
        margin-bottom: 0;
    }
`;
