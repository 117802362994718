import React from "react";
import { PostResponse } from "@ferda/rest-api-client";
import styled from "@emotion/styled";
import { FetchState } from "@/interfaces/fetch";
import AppLayout from "@/containers/AppLayout";
import { getDateRangeString } from "@/utils/time";
import { BackButton } from "../IconButton";
import { ImageMaxWidthContentContainer } from "../ImageMaxWidthContentContainer";
import LoadingCircle from "../LoadingCircle";
import Divider from "../Divider";

const StyledAppLayout = styled(AppLayout)`
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 20px;
    padding-bottom: 42px;
    padding-right: 20px;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #0c0c0c;
`;

const DateContainer = styled.div`
    margin-top: 12px;

    color: #8f8f8f;
    font-size: 12px;
`;

const StyledDivider = styled(Divider)`
    margin-top: 16px;
    margin-bottom: 24px;
`;

const ContentContainer = styled.div`
    font-size: 14px;
    color: #0c0c0c;
`;

interface CommonPostDetailPageProps {
    title: string;
    post: PostResponse | null;
    fetchState: FetchState;
}

export default function CommonPostDetailPage({ title, post, fetchState }: CommonPostDetailPageProps) {
    return (
        <StyledAppLayout
            title={title}
            titleColor="#fff"
            disableAppBarBorder
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
        >
            {!post && fetchState === "FETCHING" && <LoadingCircle position="absolute" />}
            {post && (
                <>
                    <Title>{post.title}</Title>
                    <DateContainer>
                        {getDateRangeString(post.created_datetime, post.post_start_date, post.post_end_date)}
                    </DateContainer>
                    <StyledDivider />
                    <ContentContainer>
                        <ImageMaxWidthContentContainer dangerouslySetInnerHTML={{ __html: post.content }} />
                    </ContentContainer>
                </>
            )}
        </StyledAppLayout>
    );
}
