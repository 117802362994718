import styled from "@emotion/styled";
import Divider from "@/components/Divider";
import { Tab } from "@/components/Tab";
import Button from "@/components/Button";

interface OrderContainerProps {
    isGift?: boolean;
}

export const OrderContainer = styled.div<OrderContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    padding-top: ${({ isGift }) => (isGift ? 76 : 20)}px;
`;

export const ProductImageContainer = styled.div`
    position: relative;

    width: 100%;
    padding-bottom: 100%;
    margin-top: -76px;
`;

export const ProductImg = styled.img`
    position: absolute;
    height: 100%;
    width: 100%;
`;

export const MainContentContainer = styled.div`
    position: relative;

    margin-top: -16px;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;

    color: white;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: white;
`;

export const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: #0c0c0c;
`;

export const PriceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`;

export const PriceText = styled.p`
    font-size: 26px;
    color: #ff831e;
    font-weight: bold;
    line-height: 34px;
`;

export const PriceUnit = styled.span`
    font-size: 20px;
    color: #ff831e;
    line-height: 34px;
`;

export const Description = styled.div`
    margin-top: 8px;

    font-size: 14px;
    color: #8f8f8f;
`;

export const ColdnessTab = styled(Tab)`
    width: 100%;
    height: auto;
    margin-top: 16px;
`;

export const SeeNutritionFactsButton = styled(Button)`
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    padding: 16px;
    width: 100%;

    color: #e2e2e2;
    font-size: 16px;

    border-radius: 8px;
`;

export const RecommendContainer = styled.div`
    width: 100%;
    flex-grow: 1;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    overflow: hidden;

    background-color: #f7f7f7;
`;

export const RecommendTitle = styled.div`
    margin-bottom: 16px;

    color: #0c0c0c;
    font-size: 18px;
    font-weight: bold;
`;

export const BottomOrderButtonContainer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10000;

    width: 100%;
    padding-top: 8px;
    padding-left: 20px;
    padding-bottom: 8px;
    padding-right: 20px;

    background-color: #ffffff;
`;

export const FieldTitle = styled.div`
    margin-bottom: 16px;

    color: #0c0c0c;
`;

export const StyledDivider = styled(Divider)`
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const CheckBoxContainer = styled.div`
    > div {
        margin-bottom: 8px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
`;

export const BottomSheetSpacer = styled.div`
    width: 100%;
    padding-bottom: 80px;
`;
