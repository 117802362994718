import { POST_STATE_KEY_LIST } from "./slice";
import { makeSubStateSelectorMap } from "@/utils/redux/selector";

export const {
    getNoticePost,
    getNoticePostFetchState,
    getNoticePostListFetchState,
    getNoticePostList,
    getNoticePostListPage,
    getNoticePostListTotalPage,

    getEventContentPost,
    getEventContentPostFetchState,
    getEventContentPostListFetchState,
    getEventContentPostList,
    getEventContentPostListPage,
    getEventContentPostListTotalPage,

    getUnexpiredEventContentPostList,
    getUnexpiredEventContentPostListFetchState,
    getUnexpiredEventContentPostListPage,
    getUnexpiredEventContentPostListTotalPage,
} = makeSubStateSelectorMap("post", POST_STATE_KEY_LIST);
