import styled from "@emotion/styled";
import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import HomeBannerCard from "./HomeBannerCard";
import { getUnexpiredEventContentPostList } from "@/redux/post/selectors";

const StyledSlider = styled(Slider)`
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 40px;

    .slick-slide {
        display: flex;
        justify-content: center;

        > div {
            width: calc(100% - 40px);
        }
    }

    .slick-dots {
        padding-left: 20px;
        padding-right: 20px;
    }

    .slick-dots li {
        width: 6px;
        height: 6px;
        margin-left: 0;
        margin-right: 8px;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        background: #5b5b5b;

        border-radius: 3px;

        > button {
            width: 100%;
            height: 100%;
            padding: 0;

            background: transparent;

            &::before {
                display: none;
            }
        }

        transition: 300ms;
    }

    .slick-dots li.slick-active {
        width: 14px;

        background: #ff831e;
    }
`;

export default function HomeBanner() {
    const unexpiredEventContentPostList = useSelector(getUnexpiredEventContentPostList);

    if (!unexpiredEventContentPostList || !unexpiredEventContentPostList.length) {
        return null;
    }

    return (
        // eslint-disable-next-line react/no-unstable-nested-components
        <StyledSlider arrows={false} dots appendDots={(dots) => <ul>{dots}</ul>} autoplay autoplaySpeed={5000} infinite>
            {unexpiredEventContentPostList &&
                unexpiredEventContentPostList.map((post, i) => <HomeBannerCard key={i} post={post} />)}
        </StyledSlider>
    );
}
