import { createSlice } from "@reduxjs/toolkit";
import { MenuResponse } from "@ferda/rest-api-client";
import { MenuGroupListFetchSuccessPayload, MenuListFetchSuccessPayload, MenuState } from "./types";
import { makeAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: MenuState = {
    menuGroupList: null,
    menuGroupListFetchState: "READY",
    menuGroupListPage: null,
    menuGroupListTotalPage: null,

    menuList: null,
    menuListFetchState: "READY",
    menuListPage: null,
    menuListTotalPage: null,

    recommendedMenuList: null,
    recommendedMenuListFetchState: "READY",

    selectedMenu: null,
    selectedMenuFetchState: "READY",
};

export const MENU_STATE_KEY_LIST = getKeyListFromState(initialState);

export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        resetAllMenuState: () => initialState,
        resetMenuGroupListState: (state) => ({
            ...state,
            menuGroupList: null,
            menuGroupListFetchState: "READY",
            menuGroupListPage: null,
            menuGroupListTotalPage: null,
        }),
        resetMenuListState: (state) => ({
            ...state,
            menuList: null,
            menuListFetchState: "READY",
            menuListPage: null,
            menuListTotalPage: null,
        }),
        resetMenuState: ({ recommendedMenuList, recommendedMenuListFetchState }) => ({
            ...initialState,
            recommendedMenuList,
            recommendedMenuListFetchState,
        }),
        ...makeAsyncReducer<"menuGroupList", MenuState, void, MenuGroupListFetchSuccessPayload>("menuGroupList", {
            useListSuccessReducer: true,
        }),
        ...makeAsyncReducer<"menuList", MenuState, void, MenuListFetchSuccessPayload>("menuList", {
            useListSuccessReducer: true,
        }),
        ...makeAsyncReducer<"selectedMenu", MenuState, void, MenuResponse>("selectedMenu"),
        ...makeAsyncReducer<"recommendedMenuList", MenuState, void, MenuResponse[]>("recommendedMenuList"),
    },
});

export const menuReducer = menuSlice.reducer;
