import React from "react";
import { Button, message } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MenuGroupResponse } from "@ferda/rest-api-client";
import { getRestApiClient } from "@/redux/auth/selectors";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import { Clear, Right } from "@/components/Admin/Float";
import {
    MenuDraggable,
    MenuDraggableContainer,
    MenuDraggableDescription,
    MenuDraggableImageWrapper,
    MenuDraggableTitle,
} from "./MenusStyled";

interface Props {}

export default function Page(props: Props) {
    const history = useHistory();

    const { shopApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [menuGroupList, setMenuGroupList] = React.useState<MenuGroupResponse[]>([]);

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getMenuGroups(true)
            .then((res) => {
                setLoading(false);
                setMenuGroupList(res.data);
            })
            .catch(() => {
                setLoading(false);
                message.error("메뉴 그룹을 불러오는데 실패하였습니다.");
            });
    }, []);

    return (
        <>
            <PageTitle>메뉴 관리</PageTitle>
            <Container>
                <Right>
                    <Button
                        disabled={loading}
                        onClick={() => {
                            setLoading(true);
                            shopApi
                                .setMenuGroupOrderPriority(
                                    menuGroupList.map((menuGroup, index) => {
                                        return {
                                            menu_group_id: menuGroup.id,
                                            order_priority: index,
                                        };
                                    }),
                                )
                                .then((res) => {
                                    setLoading(false);
                                    message.success("저장하였습니다.", 1.5);
                                    history.push("/admin/menus");
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    message.error(error.response.data.detail, 1.5);
                                });
                        }}
                    >
                        저장
                    </Button>
                </Right>
                <Clear />
            </Container>
            <Container>
                <DragDropContext
                    onDragEnd={(result, provided) => {
                        if (!result.destination) {
                            return;
                        }

                        const newMenuGroupList = [...menuGroupList];
                        const [reorderedItem] = newMenuGroupList.splice(result.source.index, 1);
                        newMenuGroupList.splice(result.destination.index, 0, reorderedItem);

                        setMenuGroupList(newMenuGroupList);
                    }}
                >
                    <Droppable droppableId="menuGroups">
                        {(provided) => (
                            <MenuDraggableContainer {...provided.droppableProps} ref={provided.innerRef}>
                                {menuGroupList.map((menuGroup, menuGroupIndex) => (
                                    <Draggable
                                        key={menuGroup.id}
                                        draggableId={menuGroup.id.toString()}
                                        index={menuGroupIndex}
                                    >
                                        {(provided) => (
                                            <MenuDraggable
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <MenuDraggableImageWrapper>
                                                    {menuGroup.uploaded_image_set && (
                                                        <img
                                                            src={menuGroup.uploaded_image_set.original_image_url}
                                                            width={64}
                                                            height={64}
                                                        />
                                                    )}
                                                </MenuDraggableImageWrapper>
                                                <MenuDraggableTitle>{menuGroup.name}</MenuDraggableTitle>
                                                <MenuDraggableDescription>
                                                    {menuGroup.description}
                                                </MenuDraggableDescription>
                                            </MenuDraggable>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </MenuDraggableContainer>
                        )}
                    </Droppable>
                </DragDropContext>
            </Container>
        </>
    );
}
