import styled from "@emotion/styled";
import { format } from "date-fns";
import React from "react";
import { Button } from "@mui/material";
import StarIconImage from "@/assets/img/ic_noti_star.svg";
import BeverageIconImage from "@/assets/img/ic_noti_beverage.svg";

const NotificationCardContainer = styled(Button)`
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: left;
    align-items: start;

    color: white;
    text-align: left;

    border-radius: 0;
    border-bottom: 1px solid #d1d1d1;
`;

const ContentContainer = styled.div``;

const IconImg = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 16px;
`;

const Title = styled.div`
    font-size: 14px;
    color: #0c0c0c;
`;

const Description = styled.div`
    font-size: 14px;
    color: #8f8f8f;
`;

const DateContainer = styled.div`
    margin-top: 4px;

    font-size: 12px;
    color: #5b5b5b;
`;

interface NotificationCardProps {
    title: string;
    description: string;
    date: Date;
    type: "GIFT" | "EVENT" | "AD" | "ORDER" | "NOTICE";
    href?: string;
}

export default function NotificationCard({ title, description, date, type, href }: NotificationCardProps) {
    return (
        <NotificationCardContainer>
            <IconImg src={type === "ORDER" ? BeverageIconImage : StarIconImage} />
            <ContentContainer>
                <Title>{title}</Title>
                <Description>{description} </Description>
                <DateContainer>{format(date, "yyyy.MM.dd HH:mm")}</DateContainer>
            </ContentContainer>
        </NotificationCardContainer>
    );
}
