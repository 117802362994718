import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const GiftTabHeader = styled.div`
    width: 100%;
    display: flex;
    padding: 14px 20px;
    background-color: #ff831e;
`;

export const GiftTabCategoryWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 42px;
    align-items: center;
    background-color: #2d2d2d;
    padding: 0 24px;
`;

export const GiftTabCategoryText = styled(Button)`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #0c0c0c;

    > img {
        margin-left: 8px;
    }
`;

interface GiftTabCategoryButton {
    selected: boolean;
}

export const GiftTabCategoryButton = styled(Button)<GiftTabCategoryButton>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 56px;
    color: #e2e2e2;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;

    ${({ selected }) =>
        selected &&
        css`
            color: #ff831e;
        `}
`;

export const GiftTabContentWrapper = styled.div`
    padding: 32px 20px;
`;
