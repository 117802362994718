import { InputBaseProps as MuiInputBaseProps } from "@mui/material";
import React, { useCallback } from "react";
import { StyledInputBase } from "./style";

interface InputBaseProps extends Exclude<MuiInputBaseProps, "type"> {
    maxLength?: number;
    type?: "text" | "number";
    error?: boolean;
    maxNumber?: number;
}

export default function InputBase({ maxLength, maxNumber, type = "text", onChange, ...props }: InputBaseProps) {
    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
        (e) => {
            if (!onChange) {
                return;
            }
            const { value } = e.target;
            const regex = /^\d+$/;

            let result = value;

            if (type === "number") {
                if (!regex.test(value)) {
                    result = value.substr(0, value.length - 1);
                }
                if (maxLength && value.length > maxLength) {
                    result = value.substr(0, maxLength);
                }
                if (maxNumber && Number(value) > maxNumber) {
                    result = maxNumber.toString();
                }
            }
            e.target.value = result;

            onChange(e);
        },
        [onChange],
    );

    return <StyledInputBase size="small" type="text" onChange={handleChange} {...props} />;
}
