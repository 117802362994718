import { makeSubStateSelectorMap } from "@/utils/redux/selector";
import { QUESTION_STATE_KEY_LIST } from "./slice";

export const {
    getQuestionWriteFetchState,
    getQuestionDeleteFetchState,
    getQuestionListFetchState,
    getQuestionList,
    getQuestionListPage,
    getQuestionListTotalPage,
} = makeSubStateSelectorMap("question", QUESTION_STATE_KEY_LIST);
