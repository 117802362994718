import React from "react";
import {
    CoffeeMenuImageProps,
    CoffeeMenuImageWrapper,
    CoffeeMenuItemWrapper,
    CoffeeMenuItemWrapperProps,
    CoffeeMenuPriceNumber,
    CoffeeMenuPriceText,
    CoffeeMenuTitle,
} from "./style";
import { numberWithCommas } from "@/utils/number";

export interface CoffeeMenuProps {
    id: number;
    title: string;
    price: number;
    imageUrl: string;
}

interface CoffeeMenuComponentProps extends CoffeeMenuProps, CoffeeMenuItemWrapperProps, CoffeeMenuImageProps {
    href: string;
}

export default function CoffeeMenu({ title, price, imageUrl, itemWidth, href }: CoffeeMenuComponentProps) {
    return (
        <CoffeeMenuItemWrapper itemWidth={itemWidth} href={href}>
            <CoffeeMenuImageWrapper imageHeight={itemWidth}>
                <img src={imageUrl} alt="coffee" />
            </CoffeeMenuImageWrapper>
            <CoffeeMenuTitle>{title}</CoffeeMenuTitle>
            <CoffeeMenuPriceText>
                <CoffeeMenuPriceNumber>{numberWithCommas(price)}</CoffeeMenuPriceNumber>원
            </CoffeeMenuPriceText>
        </CoffeeMenuItemWrapper>
    );
}
