import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useLocation } from "react-router";
import { BackButton } from "@/components/IconButton";
import AppLayout from "@/containers/AppLayout";
import Subscribe from "./Subscribe";
import ServiceContent from "./ServiceContent";
import { getQueryStringObject } from "@/utils/global";

export default function Service() {
    const [currentTab, setCurrentTab] = useState(0);
    const { search } = useLocation();

    const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, newTab: number) => setCurrentTab(newTab);
    const query = getQueryStringObject(search);

    useEffect(() => {
        if (query?.tab) {
            setCurrentTab(Number(query?.tab));
        }
    }, []);

    return (
        <AppLayout
            disableAppBarBorder
            appBarBackgroundColor="#ff831e"
            leadingIcon={<BackButton />}
            titleColor="#fff"
            title="워크무드 서비스"
        >
            <Tabs variant="fullWidth" value={currentTab} onChange={handleTabChange}>
                <Tab label="서비스" />
                <Tab label="구독권" />
            </Tabs>
            {currentTab === 0 && <ServiceContent />}
            {currentTab === 1 && <Subscribe />}
        </AppLayout>
    );
}
