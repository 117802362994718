import React from "react";
import moment from "moment";
import "moment/locale/ko";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Empty, Form, Input, message, Modal, Row, Select } from "antd";
import {
    CupType,
    MenuResponse,
    OrderResponse,
    OrderStatus,
    ShopResponse,
    SubscribeStatusResponse,
    TemperatureType,
    UserResponse,
    UserType,
} from "@ferda/rest-api-client";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";
import { cupTypeToText, orderStatusToText, temperatureTypeToText } from "@/utils/enumToText";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import Table from "@/components/Admin/Table";
import FormItem from "@/components/Admin/FormItem";
import Pagination from "@/components/Admin/Pagination";
import StatusBadge from "@/components/Admin/StatusBadge";
import { Clear, Left, Right } from "@/components/Admin/Float";
import { TotalCountWrapper } from "@/components/Admin/Misc";

const { RangePicker } = DatePicker;
const { Option } = Select;

const SearchBarContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const ShopTitle = styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const OrderOptionContainer = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

const OrderOptionGroupTitle = styled.div`
    font-weight: bold;
`;

const OrderOptionList = styled.ul`
    flex-grow: 1;
    margin: 0;
    margin-left: 0.5rem;
    padding: 0;
    list-style: none;
`;

const OrderOptionListItem = styled.li`
    margin-bottom: 0.25rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

const SubscriptionContainer = styled.div``;

const SubscriptionHeader = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const SubscriptionTitle = styled.div`
    flex-grow: 1;
    margin-right: 0.5rem;
    font-weight: bold;
`;

const SubscriptionPrice = styled.div``;

const SubscriptionDate = styled.div`
    display: flex;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
`;

const SubscriptionDateText = styled.div`
    flex-grow: 1;
`;

const SubscriptionDateTime = styled.div`
    text-align: right;
`;

interface OrderStatusProgress {
    canProgress: boolean;
    message: string;
}

interface Props {}

export default function Page(props: Props) {
    const { serviceApi, shopApi, userApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [csvLoading, setCSVLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalOrder, setModalOrder] = React.useState<OrderResponse | undefined>();
    const [modalShopList, setModalShopList] = React.useState<ShopResponse[]>([]);
    const [modalShopListTotal, setModalShopListTotal] = React.useState<number>(0);
    const [modalShopListPage, setModalShopListPage] = React.useState<number>(1);
    const [modalShopKeyword, setModalShopKeyword] = React.useState<string | undefined>();
    const [modalMenuList, setModalMenuList] = React.useState<MenuResponse[]>([]);
    const [modalMenuListTotal, setModalMenuListTotal] = React.useState<number>(0);
    const [modalMenuListPage, setModalMenuPage] = React.useState<number>(1);
    const [modalMenuKeyword, setModalMenuKeyword] = React.useState<string | undefined>();
    const [modalUserList, setModalUserList] = React.useState<UserResponse[]>([]);
    const [modalUserListTotal, setModalUserListTotal] = React.useState<number>(0);
    const [modalUserName, setModalUserName] = React.useState<string | undefined>();
    const [modalUserPhone, setModalUserPhone] = React.useState<string | undefined>();
    const [modalUserPage, setModalUserPage] = React.useState<number>(1);
    const [modalSubscriptionStatus, setModalSubscriptionStatus] = React.useState<SubscribeStatusResponse | undefined>();

    const [orderList, setOrderList] = React.useState<OrderResponse[]>([]);
    const [orderListTotal, setOrderListTotal] = React.useState<number>(0);

    const [shop, setShop] = React.useState<ShopResponse | undefined>();
    const [menu, setMenu] = React.useState<MenuResponse | undefined>();
    const [user, setUser] = React.useState<UserResponse | undefined>();

    const [minCreatedDate, setMinCreatedDate] = React.useState<Date | undefined>();
    const [maxCreatedDate, setMaxCreatedDate] = React.useState<Date | undefined>();
    const [shopId, setShopId] = React.useState<number | undefined>();
    const [menuId, setMenuId] = React.useState<number | undefined>();
    const [username, setUsername] = React.useState<string | undefined>();
    const [orderStatus, setOrderStatus] = React.useState<OrderStatus[] | undefined>();
    const [temperatureType, setTemperatureType] = React.useState<TemperatureType[] | undefined>();
    const [cupType, setCupType] = React.useState<CupType[] | undefined>();
    const [page, setPage] = React.useState<number>(1);

    const [progressForm] = Form.useForm();
    const [cancelForm] = Form.useForm();

    const orderStatusProgressMessage = (orderStatus: OrderStatus): OrderStatusProgress => {
        switch (orderStatus) {
            case OrderStatus.ORDER_CANCELED:
                return { canProgress: false, message: "이미 취소가 되어 상태를 변경할 수 없습니다." };
            case OrderStatus.ORDER_COMPLETED:
                return { canProgress: true, message: "'제조중'으로 상태를 변경하시겠습니까?" };
            case OrderStatus.MAKE_IN_PROGRESS:
                return { canProgress: true, message: "'제조완료'로 상태를 변경하시겠습니까?" };
            case OrderStatus.MAKE_COMPLETED:
                return { canProgress: true, message: "'픽업완료'로 상태를 변경하시겠습니까?" };
            case OrderStatus.PICKUP_COMPLETED:
                return { canProgress: false, message: "이미 픽업이 완료가 되어 상태를 변경할 수 없습니다." };
        }
    };

    const orderStatusToColor = (orderStatus: OrderStatus): string => {
        switch (orderStatus) {
            case OrderStatus.ORDER_CANCELED:
                return "#d75959";
            case OrderStatus.ORDER_COMPLETED:
                return "#ffa84b";
            case OrderStatus.MAKE_IN_PROGRESS:
                return "#dda946";
            case OrderStatus.MAKE_COMPLETED:
                return "#d7ce46";
            case OrderStatus.PICKUP_COMPLETED:
                return "#8ed746";
        }
    };

    const temperatureTypeToColor = (temperatureType: TemperatureType): string => {
        switch (temperatureType) {
            case TemperatureType.HOT:
                return "#d75959";
            case TemperatureType.ICED:
                return "#59a8d7";
        }
    };

    const cupTypeToColor = (cupType: CupType): string => {
        switch (cupType) {
            case CupType.DISPOSABLE:
                return "#d75959";
            case CupType.INDIVIDUAL:
                return "#ffa84b";
            case CupType.REUSABLE:
                return "#59a8d7";
        }
    };

    const orderColumns = [
        {
            title: "주문번호",
            key: "number",
            render: (record: OrderResponse) => {
                return {
                    children: `${record.display_id} (${record.id.toLocaleString("ko")})`,
                };
            },
            width: "80px",
        },
        {
            title: "주문상태",
            key: "status",
            render: (record: OrderResponse) => {
                return {
                    children: (
                        <StatusBadge color={orderStatusToColor(record.order_status)}>
                            {orderStatusToText(record.order_status)}
                        </StatusBadge>
                    ),
                };
            },
            width: "100px",
        },
        {
            title: "주문시각",
            key: "createdDatetime",
            render: (record: OrderResponse) => {
                const time = moment(record.created_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "주문자",
            key: "user",
            render: (record: OrderResponse) => {
                let extraInfo;
                if (record.subscription) {
                    extraInfo = <p>{record.subscription.name}</p>;
                } else if (record.gift) {
                    extraInfo = <p>선물사용</p>;
                }
                return {
                    children: record.user.detail && (
                        <>
                            <p>{record.user.detail?.name}</p>
                            <p>{record.user.detail?.phone_number}</p>
                            {record.transaction && <p>단품 주문</p>}
                            {extraInfo}
                        </>
                    ),
                };
            },
            width: "130px",
        },
        {
            title: "구독권",
            key: "subscription",
            render: (record: OrderResponse) => {
                return {
                    children: (
                        <Button
                            onClick={() => {
                                setModal("subscription");
                                setModalSubscriptionStatus(undefined);

                                setLoading(true);
                                serviceApi
                                    .getSubscribeStatus(record.user.username)
                                    .then((res) => {
                                        setLoading(false);
                                        setModalSubscriptionStatus(res.data);
                                    })
                                    .catch(() => {
                                        setLoading(false);
                                        message.error("구독권 정보를 불러오는데 실패하였습니다", 1.5);
                                    });
                            }}
                        >
                            보기
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
        {
            title: "주문내역",
            key: "menu",
            render: (record: OrderResponse) => {
                return {
                    children: `${record.menu.name}: ${record.amount}잔`,
                };
            },
        },
        {
            title: "주문옵션",
            key: "options",
            render: (record: OrderResponse) => {
                return {
                    children: record.option_groups.map((group, groupIndex) => (
                        <OrderOptionContainer key={groupIndex}>
                            <OrderOptionGroupTitle>{group.name}</OrderOptionGroupTitle>
                            <OrderOptionList>
                                {group.options.map((option, optionIndex) => (
                                    <OrderOptionListItem key={optionIndex}>{option.name}</OrderOptionListItem>
                                ))}
                            </OrderOptionList>
                        </OrderOptionContainer>
                    )),
                };
            },
            width: "250px",
        },
        {
            title: "온도",
            key: "temperature",
            render: (record: OrderResponse) => {
                return {
                    children: record.temperature_type && (
                        <StatusBadge color={temperatureTypeToColor(record.temperature_type)}>
                            {temperatureTypeToText(record.temperature_type)}
                        </StatusBadge>
                    ),
                };
            },
            width: "80px",
        },
        {
            title: "컵",
            key: "cup",
            render: (record: OrderResponse) => {
                return {
                    children: record.cup_type && (
                        <StatusBadge color={cupTypeToColor(record.cup_type)}>
                            {cupTypeToText(record.cup_type)}
                        </StatusBadge>
                    ),
                };
            },
            width: "90px",
        },
        {
            title: "가맹점",
            key: "shop",
            render: (record: OrderResponse) => {
                return {
                    children: record.shop.name,
                };
            },
            width: "150px",
        },
        {
            title: "관리",
            key: "manage",
            render: (record: OrderResponse) => {
                return {
                    children: (
                        <Button
                            disabled={
                                loading ||
                                // record.order_status === OrderStatus.PICKUP_COMPLETED ||
                                record.order_status === OrderStatus.ORDER_CANCELED
                            }
                            onClick={() => {
                                setModal("progress");
                                setModalOrder(record);
                            }}
                        >
                            상태 변경
                        </Button>
                    ),
                };
            },
            width: "130px",
        },
    ];

    const shopColumns = [
        {
            title: "사진",
            key: "image",
            render: (record: ShopResponse) => {
                return {
                    children: record.uploaded_image_sets[0] && (
                        <img src={record.uploaded_image_sets[0].original_image_url} width={64} height={64} />
                    ),
                };
            },
            width: "100px",
        },
        {
            title: "정보",
            key: "info",
            render: (record: ShopResponse) => {
                return {
                    children: (
                        <>
                            <ShopTitle>{record.name}</ShopTitle>
                            <p>
                                {record.address} {record.address_detail}
                            </p>
                        </>
                    ),
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: ShopResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setShop(record);
                                setModal(undefined);
                                searchForm.setFieldsValue({ shop: record.name });
                            }}
                        >
                            선택
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    const menuColumns = [
        {
            title: "사진",
            key: "image",
            render: (record: MenuResponse) => {
                return {
                    children: record.uploaded_image_set && (
                        <img src={record.uploaded_image_set.original_image_url} width={64} height={64} />
                    ),
                };
            },
            width: "100px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: MenuResponse) => {
                return {
                    children: record.name,
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: MenuResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setMenu(record);
                                setModal(undefined);
                                searchForm.setFieldsValue({ menu: record.name });
                            }}
                        >
                            선택
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    const userColumns = [
        {
            title: "휴대폰번호",
            key: "phone",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.phone_number,
                };
            },
            width: "150px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.name,
                };
            },
            width: "125px",
        },
        {
            title: "아이디",
            key: "username",
            render: (record: UserResponse) => {
                return {
                    children: record.username,
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: UserResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setUser(record);
                                setModal(undefined);
                                searchForm.setFieldsValue({ user: record.detail!.name });
                            }}
                        >
                            선택
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    const [searchForm] = Form.useForm();
    const [shopForm] = Form.useForm();
    const [menuForm] = Form.useForm();
    const [userSearchForm] = Form.useForm();

    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const userId = searchParams.get("userId") || undefined;

        setLoading(true);
        shopApi
            .getOrders(
                shopId,
                menuId,
                orderStatus,
                temperatureType,
                cupType,
                minCreatedDate && moment(minCreatedDate).format("YYYY-MM-DD"),
                maxCreatedDate && moment(maxCreatedDate).format("YYYY-MM-DD"),
                page,
                username || userId,
            )
            .then((res) => {
                setLoading(false);
                setOrderList(res.data);
                setOrderListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("주문 목록을 불러오는데 실패하였습니다.");
            });
    }, [
        minCreatedDate,
        maxCreatedDate,
        shopId,
        menuId,
        username,
        orderStatus,
        temperatureType,
        cupType,
        page,
        rerender,
    ]);

    React.useEffect(() => {
        setLoading(true);
        userApi
            .getUsers(
                undefined,
                undefined,
                undefined,
                [UserType.USER, UserType.SHOP_OWNER, UserType.ADMIN],
                undefined,
                undefined,
                undefined,
                undefined,
                modalUserName,
                modalUserPhone,
                undefined,
                undefined,
                undefined,
                undefined,
                modalUserPage,
            )
            .then((res) => {
                setLoading(false);
                setModalUserList(res.data);
                setModalUserListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("회원 목록을 불러오는데 실패하였습니다.");
            });
    }, [modalUserName, modalUserPhone, modalUserPage]);

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getShops(undefined, undefined, modalShopKeyword, undefined, undefined, modalShopListPage)
            .then((res) => {
                setLoading(false);
                setModalShopList(res.data);
                setModalShopListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("가맹점 목록을 불러오는데 실패하였습니다.");
            });
    }, [modalShopKeyword, modalShopListPage]);

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getMenus(undefined, modalMenuKeyword, false, undefined, undefined, modalMenuListPage)
            .then((res) => {
                setLoading(false);
                setModalMenuList(res.data);
                setModalMenuListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("메뉴 목록을 불러오는데 실패하였습니다.");
            });
    }, [modalMenuKeyword, modalMenuListPage]);

    return (
        <>
            <PageTitle>주문 관리</PageTitle>
            <Container>
                <Form
                    form={searchForm}
                    onFinish={(values) => {
                        const minCreatedDate = values.date && values.date[0] ? values.date[0].toDate() : undefined;
                        const maxCreatedDate = values.date && values.date[1] ? values.date[1].toDate() : undefined;
                        const shopId = shop ? shop.id : undefined;
                        const menuId = menu ? menu.id : undefined;
                        const username = user ? user.username : undefined;
                        const orderStatus = values.orderStatus || undefined;
                        const temperatureType = values.temperatureType || undefined;
                        const cupType = values.cupType || undefined;

                        setMinCreatedDate(minCreatedDate);
                        setMaxCreatedDate(maxCreatedDate);
                        setShopId(shopId);
                        setMenuId(menuId);
                        setUsername(username);
                        setOrderStatus(orderStatus);
                        setTemperatureType(temperatureType);
                        setCupType(cupType);
                        setPage(1);
                    }}
                >
                    <Left>
                        <Row style={{ marginBottom: "0.5rem" }}>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="shop">
                                    <Input
                                        disabled={loading}
                                        onFocus={(e) => {
                                            setModal("shop");
                                            setModalShopKeyword(undefined);
                                            setModalShopListPage(1);
                                            shopForm.resetFields();
                                            e.target.blur();
                                        }}
                                        placeholder="가맹점"
                                        readOnly
                                        style={{ width: "250px" }}
                                        value={shop?.name}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="menu">
                                    <Input
                                        disabled={loading}
                                        onFocus={(e) => {
                                            setModal("menu");
                                            setModalMenuKeyword(undefined);
                                            setModalMenuPage(1);
                                            menuForm.resetFields();
                                            e.target.blur();
                                        }}
                                        placeholder="메뉴"
                                        readOnly
                                        style={{ width: "200px" }}
                                        value={menu?.name}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="user">
                                    <Input
                                        disabled={loading}
                                        onFocus={(e) => {
                                            setModal("user");
                                            setModalUserName(undefined);
                                            setModalUserPhone(undefined);
                                            setModalUserPage(1);
                                            userSearchForm.resetFields();
                                            e.target.blur();
                                        }}
                                        placeholder="주문자"
                                        readOnly
                                        style={{ width: "200px" }}
                                        value={user && user.detail?.name}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="date">
                                    <RangePicker
                                        disabled={loading}
                                        placeholder={["시작 일자", "끝 일자"]}
                                        style={{ width: "300px" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="orderStatus">
                                    <Select
                                        mode="multiple"
                                        disabled={loading}
                                        placeholder="상태"
                                        style={{ width: "200px" }}
                                    >
                                        <Option value={OrderStatus.MAKE_COMPLETED}>
                                            {orderStatusToText(OrderStatus.MAKE_COMPLETED)}
                                        </Option>
                                        <Option value={OrderStatus.MAKE_IN_PROGRESS}>
                                            {orderStatusToText(OrderStatus.MAKE_IN_PROGRESS)}
                                        </Option>
                                        <Option value={OrderStatus.ORDER_CANCELED}>
                                            {orderStatusToText(OrderStatus.ORDER_CANCELED)}
                                        </Option>
                                        <Option value={OrderStatus.ORDER_COMPLETED}>
                                            {orderStatusToText(OrderStatus.ORDER_COMPLETED)}
                                        </Option>
                                        <Option value={OrderStatus.PICKUP_COMPLETED}>
                                            {orderStatusToText(OrderStatus.PICKUP_COMPLETED)}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="temperatureType">
                                    <Select
                                        mode="multiple"
                                        disabled={loading}
                                        placeholder="온도"
                                        style={{ width: "200px" }}
                                    >
                                        <Option value={TemperatureType.HOT}>
                                            {temperatureTypeToText(TemperatureType.HOT)}
                                        </Option>
                                        <Option value={TemperatureType.ICED}>
                                            {temperatureTypeToText(TemperatureType.ICED)}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="cupType">
                                    <Select
                                        mode="multiple"
                                        disabled={loading}
                                        placeholder="컵 종류"
                                        style={{ width: "200px" }}
                                    >
                                        <Option value={CupType.DISPOSABLE}>{cupTypeToText(CupType.DISPOSABLE)}</Option>
                                        <Option value={CupType.INDIVIDUAL}>{cupTypeToText(CupType.INDIVIDUAL)}</Option>
                                        <Option value={CupType.REUSABLE}>{cupTypeToText(CupType.REUSABLE)}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Left>
                    <Right>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        htmlType="reset"
                                        onClick={() => {
                                            searchForm.resetFields();

                                            setShop(undefined);
                                            setUser(undefined);
                                            setMenu(undefined);
                                            setShopId(undefined);
                                            setUsername(undefined);
                                            setMenuId(undefined);
                                            setMinCreatedDate(undefined);
                                            setMaxCreatedDate(undefined);
                                            setPage(1);
                                        }}
                                    >
                                        초기화
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button disabled={loading} htmlType="submit">
                                        검색
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        loading={csvLoading}
                                        onClick={() => {
                                            setLoading(true);
                                            setCSVLoading(true);
                                            shopApi
                                                .downloadOrdersCsv({ responseType: "blob" })
                                                .then((res) => {
                                                    setLoading(false);
                                                    setCSVLoading(false);
                                                    const url = URL.createObjectURL(
                                                        new Blob([res.data], { type: res.headers["content-type"] }),
                                                    );
                                                    const link = document.createElement("a");
                                                    link.href = url;
                                                    link.setAttribute("download", "주문.csv");
                                                    document.body.appendChild(link);
                                                    link.click();
                                                })
                                                .catch(() => {
                                                    setLoading(false);
                                                    setCSVLoading(false);
                                                    message.error("엑셀 파일을 불러오는데 실패하였습니다.");
                                                });
                                        }}
                                    >
                                        CSV 다운로드
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Right>
                    <Clear />
                </Form>
            </Container>
            <Container>
                <TotalCountWrapper>주문 수 : {orderListTotal.toLocaleString("ko")}</TotalCountWrapper>
                <Table columns={orderColumns} dataSource={orderList} loading={loading} rowKey="id" />
                {orderListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={orderListTotal}
                    />
                )}
            </Container>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    style: { display: "none" },
                }}
                onCancel={() => setModal(undefined)}
                title="가맹점 선택"
                visible={modal === "shop"}
                width={800}
            >
                <Form
                    form={shopForm}
                    onFinish={(values) => {
                        if (!values.keyword) {
                            return message.error("검색어를 입력해주세요.", 1.5);
                        }

                        setModalShopKeyword(values.keyword);
                        setModalShopListPage(1);
                    }}
                >
                    <SearchBarContainer>
                        <Form.Item name="keyword" style={{ marginBottom: "0", marginRight: "0.5rem", width: "100%" }}>
                            <Input placeholder="검색어" style={{ width: "100%" }} />
                        </Form.Item>
                        <Button disabled={loading} htmlType="submit">
                            검색
                        </Button>
                    </SearchBarContainer>
                </Form>
                <Table columns={shopColumns} dataSource={modalShopList} loading={loading} rowKey="id" />
                {modalShopListTotal > 0 && (
                    <Pagination
                        current={modalShopListPage}
                        disabled={loading}
                        onChange={(page: number) => setModalShopListPage(page)}
                        pageSize={20}
                        total={modalShopListTotal}
                    />
                )}
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    style: { display: "none" },
                }}
                onCancel={() => setModal(undefined)}
                title="메뉴 선택"
                visible={modal === "menu"}
                width={800}
            >
                <Form
                    form={menuForm}
                    onFinish={(values) => {
                        if (!values.keyword) {
                            return message.error("검색어를 입력해주세요.", 1.5);
                        }

                        setModalMenuKeyword(values.keyword);
                        setModalMenuPage(1);
                    }}
                >
                    <SearchBarContainer>
                        <Form.Item name="keyword" style={{ marginBottom: "0", marginRight: "0.5rem", width: "100%" }}>
                            <Input placeholder="검색어" style={{ width: "100%" }} />
                        </Form.Item>
                        <Button disabled={loading} htmlType="submit">
                            검색
                        </Button>
                    </SearchBarContainer>
                </Form>
                <Table columns={menuColumns} dataSource={modalMenuList} loading={loading} rowKey="id" />
                {modalMenuListTotal > 0 && (
                    <Pagination
                        current={modalMenuListPage}
                        disabled={loading}
                        onChange={(page: number) => setModalMenuPage(page)}
                        pageSize={20}
                        total={modalMenuListTotal}
                    />
                )}
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    disabled: modalOrder && !orderStatusProgressMessage(modalOrder.order_status).canProgress,
                }}
                onCancel={() => setModal(undefined)}
                footer={
                    <>
                        <Left>
                            <Button
                                danger
                                onClick={() => {
                                    setModal("cancel");
                                    cancelForm.resetFields();
                                }}
                                type="primary"
                                style={{ marginRight: "0.25rem" }}
                            >
                                주문 취소
                            </Button>
                        </Left>
                        <Right>
                            <Button onClick={() => setModal(undefined)} style={{ marginRight: "0.25rem" }}>
                                취소
                            </Button>
                            <Button form="progressForm" htmlType="submit" loading={loading} type="primary">
                                상태 변경
                            </Button>
                        </Right>
                        <Clear />
                    </>
                }
                title="주문 상태 변경"
                visible={modal === "progress"}
                width={800}
            >
                <Form
                    form={progressForm}
                    name="progressForm"
                    onFinish={(values) => {
                        if (!modalOrder) {
                            return message.error("주문이 선택되지 않았습니다.", 1.5);
                        }
                        if (!values.status) {
                            return message.error("상태를 선택해주세요.", 1.5);
                        }

                        setLoading(true);
                        shopApi
                            .setOrderStatus(modalOrder.id, { order_status: values.status })
                            .then(() => {
                                setModal(undefined);
                                setModalOrder(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("주문 상태를 변경하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="상태" name="status">
                        <Select disabled={loading} placeholder="상태" style={{ width: "100%" }}>
                            <Option value={OrderStatus.MAKE_IN_PROGRESS}>
                                {orderStatusToText(OrderStatus.MAKE_IN_PROGRESS)}
                            </Option>
                            <Option value={OrderStatus.MAKE_COMPLETED}>
                                {orderStatusToText(OrderStatus.MAKE_COMPLETED)}
                            </Option>
                            <Option value={OrderStatus.PICKUP_COMPLETED}>
                                {orderStatusToText(OrderStatus.PICKUP_COMPLETED)}
                            </Option>
                        </Select>
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                    htmlType: "submit",
                    form: "cancelForm",
                }}
                onCancel={() => setModal(undefined)}
                title="주문 취소"
                visible={modal === "cancel"}
                width={800}
            >
                <Form
                    form={cancelForm}
                    name="cancelForm"
                    onFinish={(values) => {
                        if (!modalOrder) {
                            return message.error("주문이 선택되지 않았습니다.", 1.5);
                        }
                        if (!values.reason) {
                            return message.error("취소 사유를 입력해주세요.", 1.5);
                        }

                        setLoading(true);
                        shopApi
                            .setOrderStatus(modalOrder.id, {
                                order_status: OrderStatus.ORDER_CANCELED,
                                order_cancel_reason: values.reason,
                            })
                            .then(() => {
                                setModal(undefined);
                                setModalOrder(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("주문을 취소하였습니다", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="취소 사유" name="reason">
                        <Input disabled={loading} placeholder="취소 사유" />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    style: { display: "none" },
                }}
                onCancel={() => setModal("add")}
                title="회원 선택"
                visible={modal === "user"}
                width={800}
            >
                <Form
                    form={userSearchForm}
                    onFinish={(values) => {
                        setModalUserName(values.name);
                        setModalUserPhone(values.phone);
                        setModalUserPage(1);
                    }}
                >
                    <SearchBarContainer>
                        <Form.Item name="phone" style={{ marginBottom: "0", marginRight: "0.5rem", flexGrow: 1 }}>
                            <Input placeholder="휴대폰번호" style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item name="name" style={{ marginBottom: "0", marginRight: "0.5rem", flexGrow: 1 }}>
                            <Input placeholder="이름" style={{ width: "100%" }} />
                        </Form.Item>
                        <Button disabled={loading} htmlType="submit">
                            검색
                        </Button>
                    </SearchBarContainer>
                    <Table columns={userColumns} dataSource={modalUserList} loading={loading} rowKey="id" />
                    {modalUserListTotal > 0 && (
                        <Pagination
                            current={modalUserPage}
                            disabled={loading}
                            onChange={(page: number) => setModalUserPage(page)}
                            pageSize={20}
                            total={modalUserListTotal}
                        />
                    )}
                </Form>
            </Modal>
            <Modal
                footer={
                    <>
                        <Right>
                            <Button
                                onClick={() => {
                                    setModal(undefined);
                                    setModalSubscriptionStatus(undefined);
                                }}
                            >
                                닫기
                            </Button>
                        </Right>
                        <Clear />
                    </>
                }
                onCancel={() => {
                    setModal(undefined);
                    setModalSubscriptionStatus(undefined);
                }}
                title="구독권 사용 여부"
                visible={modal === "subscription"}
                width={600}
            >
                {modalSubscriptionStatus && (
                    <SubscriptionContainer>
                        {modalSubscriptionStatus.subscription_in_use ? (
                            <>
                                <SubscriptionHeader>
                                    <SubscriptionTitle>
                                        {modalSubscriptionStatus.subscription_in_use.name}
                                    </SubscriptionTitle>
                                    <SubscriptionPrice>
                                        {modalSubscriptionStatus.subscription_in_use.price.toLocaleString("ko")}원
                                    </SubscriptionPrice>
                                </SubscriptionHeader>
                                <SubscriptionDate>
                                    <SubscriptionDateText>만료시각</SubscriptionDateText>
                                    <SubscriptionDateTime>
                                        {moment(modalSubscriptionStatus.expire_datetime)
                                            .locale("ko")
                                            .format("YY. MM. DD. (dd) A hh:mm:ss")}
                                    </SubscriptionDateTime>
                                </SubscriptionDate>
                                <SubscriptionDate>
                                    <SubscriptionDateText>다음 주문 가능 시각</SubscriptionDateText>
                                    <SubscriptionDateTime>
                                        {moment(modalSubscriptionStatus.next_order_available_datetime)
                                            .locale("ko")
                                            .format("YY. MM. DD. (dd) A hh:mm:ss")}
                                    </SubscriptionDateTime>
                                </SubscriptionDate>
                            </>
                        ) : (
                            <Empty description="사용 중인 구독권이 없습니다." image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    </SubscriptionContainer>
                )}
            </Modal>
        </>
    );
}
