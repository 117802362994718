import { notificationSlice } from "./slice";

const actions = notificationSlice.actions;

export const {
    reset: resetNotificationState,
    notificationListFetchRequest,
    notificationListFetchSuccess,
    notificationListFetchFailure,
} = actions;
