import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonBase, ButtonProps } from "@mui/material";
import React from "react";

export const TabButtonContainer = styled.div`
    width: 100%;
    height: 44px;
    padding: 5px;

    background: #ff831e;
    border-radius: 1000px;
`;

interface TabButtonProps extends ButtonProps {
    selected?: boolean;
    backgroundColor?: string;
    textColor?: string;
    selectedTextColor?: string;
}

export const TabButton = styled(
    ({ selected, backgroundColor, textColor, selectedTextColor, ...props }: TabButtonProps) => (
        <ButtonBase {...props} />
    ),
)`
    width: calc(50% - 5px);
    height: 34px;
    margin-right: 10px;

    &:nth-last-child(1) {
        margin-right: 0;
    }

    font-weight: bold;
    ${({ selected, backgroundColor, textColor, selectedTextColor }) =>
        selected
            ? css`
                  background: ${backgroundColor ?? "white"};
                  color: ${selectedTextColor ?? "black"};
              `
            : css`
                  color: ${textColor ?? "#ffffff"};
              `}

    border-radius: 1000px;
`;
