import React, { useCallback, useEffect, useState } from "react";
import { FetchState } from "@/interfaces/fetch";
import { useIntersect } from "@/utils/hooks";
import LoadingCircle from "../LoadingCircle";
import { NoResult } from "@/components/NoResult";

interface InfiniteScrollListProps<ItemType> {
    list: ItemType[] | null;
    fetchState: FetchState;
    page: number | null;
    totalPage: number | null;
    renderItem: (item: ItemType, key: string | number) => React.ReactNode;
    onLoadMore: () => void;
    showNoResultMessage?: boolean;
    noResultMessage?: string;
    disableInitialize?: boolean;
}

export default function InfiniteScrollList<ItemType>({
    list = [],
    fetchState,
    page,
    totalPage,
    renderItem,
    onLoadMore,
    showNoResultMessage,
    noResultMessage = "결과가 없어요",
    disableInitialize,
}: InfiniteScrollListProps<ItemType>) {
    const [ref, entry] = useIntersect<HTMLDivElement>({
        root: null,
    });

    const [requestCheck, setRequestCheck] = useState(false);

    const handleLoadMore = useCallback(() => {
        if (fetchState === "FETCHING") {
            return;
        }

        onLoadMore();
    }, [fetchState]);

    useEffect(() => {
        if (entry && entry.isIntersecting && fetchState !== "FETCHING" && !requestCheck) {
            handleLoadMore();
            setRequestCheck(true);
        }
        if (!entry || !entry.isIntersecting) {
            setRequestCheck(false);
        }
    }, [entry, handleLoadMore, fetchState, requestCheck]);

    useEffect(() => {
        if (fetchState !== "READY" || disableInitialize) {
            return;
        }

        handleLoadMore();
    }, [fetchState, disableInitialize]);

    if (!list && (fetchState === "READY" || fetchState === "FETCHING")) {
        return <LoadingCircle position="absolute" />;
    }

    if (!list || (!list.length && fetchState === "SUCCESS")) {
        return showNoResultMessage ? <NoResult message={noResultMessage} /> : null;
    }

    return (
        <>
            {!list && fetchState === "FETCHING" && <LoadingCircle position="absolute" />}
            {list.map((item, i) => renderItem(item, i))}
            {(totalPage ?? 0) + 1 > (page ?? 1) && <div ref={ref} />}
            {fetchState === "FETCHING" && <LoadingCircle />}
        </>
    );
}
