import React from "react";
import { comma } from "@/utils/text";
import IconCheckboxChecked from "@/assets/img/ic_checkbox_checked.svg";
import IconCheckboxUnchecked from "@/assets/img/ic_checkbox_unchecked.svg";
import {
    TitleContainer,
    DescriptionContainer,
    DescriptionRow,
    Title,
    SubTitle,
    OptionButtonContainer,
    Price,
    AvailableMenuContainer,
    AvailableMenuTitleContainer,
    AvailableMenuContentContainer,
    CheckboxIconImg,
} from "./style";

interface SubscribeOptionButtonProps {
    title: string;
    subTitle?: string;
    price?: number;
    priceNode?: React.ReactNode;
    availableMenuList?: string[];
    hideMenu?: boolean;
    selected?: boolean;
    onClick?: () => void;
    hidePerWeek?: boolean;
}

export default function SubscriptionOptionButton({
    title,
    subTitle,
    price,
    priceNode,
    availableMenuList = [],
    selected,
    hideMenu,
    onClick,
    hidePerWeek,
}: SubscribeOptionButtonProps) {
    return (
        <OptionButtonContainer selected={selected} onClick={onClick}>
            <TitleContainer selected={selected}>
                <Title>
                    {title}
                    {subTitle && <SubTitle> ({subTitle})</SubTitle>}
                </Title>
                {priceNode}
                {price && (
                    <Price>
                        {!hidePerWeek && "1주 "}
                        {comma(price)}원
                    </Price>
                )}
            </TitleContainer>

            {!hideMenu && (
                <>
                    <AvailableMenuContainer selected={selected}>
                        <AvailableMenuTitleContainer>이용 가능한 메뉴</AvailableMenuTitleContainer>
                        <AvailableMenuContentContainer>{availableMenuList.join(", ")}</AvailableMenuContentContainer>
                    </AvailableMenuContainer>

                    <DescriptionContainer selected={selected}>
                        {[
                            "5일권은 평일 기준 5일이며, 주말은 자유롭게 사용하세요. (총 7일 서비스 제공)",
                            "연차 홀딩 가능 (5일권 - 최대 1일 / 30일권 - 최대 4일)",
                            "법정 공휴일 자동 추가 연장",
                            "매장 운영 종료 시 사용불가",
                        ].map((description, i) => (
                            <DescriptionRow key={i}>
                                <CheckboxIconImg src={selected ? IconCheckboxUnchecked : IconCheckboxChecked} />
                                {description}
                            </DescriptionRow>
                        ))}
                    </DescriptionContainer>
                </>
            )}
        </OptionButtonContainer>
    );
}
