import { css } from "@emotion/react";

const BottomSheetGlobalStyle = css`
    [data-rsbs-overlay],
    [data-rsbs-backdrop],
    [data-rsbs-root]:after {
        z-index: 1200;
    }

    [data-rsbs-header]:before {
        width: 77px;
        height: 5px;
        border-radius: 100px;
    }
`;

export default BottomSheetGlobalStyle;
