import { NotificationType } from "@ferda/rest-api-client";
import { makeFetchMoreListThunkAction } from "@/utils/redux/thunk";
import { notificationListFetchRequest, notificationListFetchSuccess, notificationListFetchFailure } from "./actions";

export const fetchMoreNotificationListThunk = makeFetchMoreListThunkAction(
    notificationListFetchRequest,
    notificationListFetchSuccess,
    notificationListFetchFailure,
    {
        getResponseListFunction: (
            {
                state: {
                    auth: {
                        restApiClient: { serviceApi },
                    },
                },
                page,
            },
            notiTypeList: NotificationType[] | undefined,
        ) => serviceApi.getNotifications(notiTypeList, page, "me"),
        pageSelectFunction: ({ notification: { notificationListPage, notificationListTotalPage } }) => ({
            page: notificationListPage,
            totalPage: notificationListTotalPage,
        }),
    },
);
