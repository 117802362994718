import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "@/components/LoadingPage";
import useHistory from "@/hooks/useCustomHistory";
import { getQueryStringObject } from "@/utils/global";
import { getSubscribeFetchState } from "@/redux/subscription/selectors";
import { fetchSubscribeCallbackThunk } from "@/redux/subscription/thunk";

export default function SubscribeCallback() {
    const history = useHistory();
    const { search } = useLocation();
    const query = useMemo(() => getQueryStringObject(search), [search]);
    const dispatch = useDispatch();
    const subscribeFetchState = useSelector(getSubscribeFetchState);

    useEffect(() => {
        if (subscribeFetchState === "FETCHING") {
            return;
        }

        if (!query.params) {
            history.reset();
        }

        const params: { merchant_uid: string; id: string } = JSON.parse(decodeURIComponent(query.params));

        if (typeof params.merchant_uid === "undefined" || typeof params.id === "undefined") {
            history.reset();
            history.push("/");
        }

        dispatch(fetchSubscribeCallbackThunk({ merchantUid: params.merchant_uid, subscriptionId: Number(params.id) }));
    }, [subscribeFetchState, query]);

    return <LoadingPage />;
}
