import React from "react";
import { useSelector } from "react-redux";
import { ArrowForwardIos } from "@mui/icons-material";
import Link from "@/components/CustomLink";
import {
    TodayStatisticsTitleContainer,
    TodayStatisticsContainer,
    CoffeeIconImg,
    DescriptionContainer,
    AmountContainer,
} from "./style";
import CoffeeIconImage from "@/assets/icons/coffee.svg";
import { getSubscribeStatus } from "@/redux/subscription/selectors";

export default function TodayStatistics() {
    const subscribeStatus = useSelector(getSubscribeStatus);

    return (
        <Link to="/history" style={{ width: "calc(100% - 48px)", maxWidth: "300px" }}>
            <TodayStatisticsContainer>
                <TodayStatisticsTitleContainer>
                    <CoffeeIconImg src={CoffeeIconImage} />
                    Today
                </TodayStatisticsTitleContainer>
                <DescriptionContainer>
                    <AmountContainer>{subscribeStatus?.today_order_count}</AmountContainer>잔
                    <ArrowForwardIos sx={{ ml: "8px", fontSize: "12px", color: "#757575" }} />
                </DescriptionContainer>
            </TodayStatisticsContainer>
        </Link>
    );
}
