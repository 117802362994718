import { SubscriptionResponse } from "@ferda/rest-api-client";
import { createSlice } from "@reduxjs/toolkit";
import { SubscriptionState } from "./types";
import { makeAsyncReducer } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: SubscriptionState = {
    subscribeFetchState: "READY",
    subscribeStatus: null,
    subscribeStatusFetchState: "READY",
    subscriptionList: null,
    subscriptionListFetchState: "READY",
    misc: null,
    miscFetchState: "READY",
    unSubscribeFetchState: "READY",
    holdSubscriptionFetchState: "READY",
};

export const SUBSCRIPTION_STATE_KEY_LIST = getKeyListFromState(initialState);

export const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        reset: () => initialState,
        resetHoldSubscriptionFetchState: (state) => {
            state.holdSubscriptionFetchState = "READY";
        },
        ...makeAsyncReducer<"subscribeStatus", SubscriptionState>("subscribeStatus"),
        ...makeAsyncReducer<"subscriptionList", SubscriptionState, void, SubscriptionResponse[]>("subscriptionList"),
        ...makeAsyncReducer<"subscribe", SubscriptionState>("subscribe"),
        ...makeAsyncReducer<"misc", SubscriptionState>("misc"),
        ...makeAsyncReducer<"unSubscribe", SubscriptionState>("unSubscribe"),
        ...makeAsyncReducer<"holdSubscription", SubscriptionState>("holdSubscription"),
    },
});

export const subscriptionReducer = subscriptionSlice.reducer;
