import React from "react";
import moment from "moment";
import "moment/locale/ko";
import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select } from "antd";
import { Gender, UserOrderByColumn, UserResponse, UserType } from "@ferda/rest-api-client";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";
import { genderToText, userOrderByColumnToText } from "@/utils/enumToText";

import PostTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import FormItem from "@/components/Admin/FormItem";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import { Clear, Left, Right } from "@/components/Admin/Float";
import { TotalCountWrapper } from "@/components/Admin/Misc";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props {}

export default function Page(props: Props) {
    const { userApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalUser, setModalUser] = React.useState<UserResponse | undefined>();
    const [modalUserIndex, setModalUserIndex] = React.useState<number | undefined>();

    const [userList, setUserList] = React.useState<UserResponse[]>([]);
    const [userListTotal, setUserListTotal] = React.useState<number>(0);

    const [minCreatedDate, setMinCreatedDate] = React.useState<Date | undefined>();
    const [maxCreatedDate, setMaxCreatedDate] = React.useState<Date | undefined>();
    const [name, setName] = React.useState<string | undefined>();
    const [phone, setPhone] = React.useState<string | undefined>();
    const [gender, setGender] = React.useState<Gender | undefined>();
    const [email, setEmail] = React.useState<string | undefined>();
    const [isDeleted, setIsDeleted] = React.useState<boolean | undefined>();
    const [order, setOrder] = React.useState<UserOrderByColumn | undefined>();
    const [page, setPage] = React.useState<number>(1);

    const [searchForm] = Form.useForm();
    const [editForm] = Form.useForm();
    const [phoneForm] = Form.useForm();
    const [passwordForm] = Form.useForm();

    const genderToString = (gender: Gender): string => {
        switch (gender) {
            case Gender.MALE:
                return "남성";
            case Gender.FEMALE:
                return "여성";
        }
    };

    const columns = [
        {
            title: "가입일자",
            key: "createdDatetime",
            render: (record: UserResponse) => {
                const time = moment(record.created_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "탈퇴일자",
            key: "deletedDatetime",
            render: (record: UserResponse) => {
                if (!record.deleted_datetime) {
                    return { children: undefined };
                }
                const time = moment(record.deleted_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "휴대폰번호",
            key: "phone",
            render: (record: UserResponse) => {
                return {
                    children: (
                        <>
                            <span>{record.detail?.phone_number}</span>
                            {record.detail?.phone_number && (
                                <Button
                                    onClick={() => {
                                        setModal("phone");
                                        setModalUser(record);
                                        phoneForm.setFieldsValue({ phone: record.detail?.phone_number });
                                    }}
                                    style={{ marginLeft: "0.5rem" }}
                                >
                                    변경
                                </Button>
                            )}
                        </>
                    ),
                };
            },
            width: "200px",
        },
        {
            title: "아이디",
            key: "username",
            render: (record: UserResponse) => {
                return {
                    children: record.username,
                };
            },
            width: "125px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.name,
                };
            },
            width: "125px",
        },
        {
            title: "성별",
            key: "gender",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.gender && genderToString(record.detail?.gender),
                };
            },
            width: "75px",
        },
        {
            title: "이메일",
            key: "email",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.email,
                };
            },
        },
        {
            title: "관리",
            key: "manage",
            render: (record: UserResponse, index: number) => {
                return {
                    children: (
                        <>
                            <Button
                                disabled={loading}
                                onClick={() => {
                                    setModal("edit");
                                    setModalUser(record);
                                    setModalUserIndex(index);

                                    const birthday = record.detail?.birth_date
                                        ? moment(record.detail?.birth_date)
                                        : null;

                                    editForm.setFieldsValue({
                                        name: record.detail?.name,
                                        phone: record.detail?.phone_number,
                                        gender: record.detail?.gender,
                                        birthday,
                                        email: record.detail?.email,
                                        username: record.username,
                                    });
                                }}
                                style={{ marginRight: "0.5rem" }}
                            >
                                정보 수정
                            </Button>
                            <Button
                                disabled={loading}
                                onClick={() => {
                                    setModal("password");
                                    setModalUser(record);
                                    passwordForm.resetFields();
                                }}
                                style={{ marginRight: "0.5rem" }}
                            >
                                비밀번호 변경
                            </Button>
                            <Button
                                danger
                                disabled={loading}
                                onClick={() => {
                                    setModal("delete");
                                    setModalUser(record);
                                }}
                            >
                                탈퇴 처리
                            </Button>
                        </>
                    ),
                };
            },
            width: "340px",
        },
    ];

    React.useEffect(() => {
        setLoading(true);
        userApi
            .getUsers(
                undefined,
                minCreatedDate && moment(minCreatedDate).format("YYYY-MM-DD"),
                maxCreatedDate && moment(maxCreatedDate).format("YYYY-MM-DD"),
                [UserType.ADMIN],
                undefined,
                undefined,
                undefined,
                undefined,
                name,
                phone,
                gender,
                email,
                isDeleted,
                order,
                page,
            )
            .then((res) => {
                setLoading(false);
                setUserList(res.data);
                setUserListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("어드민 목록을 불러오는데 실패하였습니다.");
            });
    }, [minCreatedDate, maxCreatedDate, name, phone, gender, email, isDeleted, order, page, rerender]);

    return (
        <>
            <PostTitle>어드민 관리</PostTitle>
            <Container>
                <Form
                    form={searchForm}
                    onFinish={(values) => {
                        const minCreatedDate = values.date && values.date[0] ? values.date[0].toDate() : undefined;
                        const maxCreatedDate = values.date && values.date[1] ? values.date[1].toDate() : undefined;
                        const name = values.name || undefined;
                        const phone = values.phone || undefined;
                        const gender = values.gender || undefined;
                        const email = values.email || undefined;
                        let isDeleted: boolean | undefined;
                        const order = values.order || undefined;

                        switch (values.isDeleted) {
                            case "false":
                                isDeleted = false;
                                break;
                            case "true":
                                isDeleted = true;
                                break;
                            default:
                                isDeleted = undefined;
                        }

                        setMinCreatedDate(minCreatedDate);
                        setMaxCreatedDate(maxCreatedDate);
                        setName(name);
                        setPhone(phone);
                        setGender(gender);
                        setEmail(email);
                        setIsDeleted(isDeleted);
                        setOrder(order);
                        setPage(1);
                    }}
                >
                    <Left>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="date">
                                    <RangePicker
                                        disabled={loading}
                                        placeholder={["가입 시작 일자", "가입 끝 일자"]}
                                        style={{ width: "300px" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="name">
                                    <Input disabled={loading} placeholder="이름" style={{ width: "125px" }} />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="phone">
                                    <Input disabled={loading} placeholder="휴대폰번호" style={{ width: "150px" }} />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="gender">
                                    <Select disabled={loading} placeholder="성별" style={{ width: "75px" }}>
                                        <Option value={Gender.MALE}>{genderToText(Gender.MALE)}</Option>
                                        <Option value={Gender.FEMALE}>{genderToText(Gender.FEMALE)}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="email">
                                    <Input disabled={loading} placeholder="이메일" style={{ width: "150px" }} />
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="isDeleted">
                                    <Select disabled={loading} placeholder="탈퇴 여부" style={{ width: "125px" }}>
                                        <Option value="true">여</Option>
                                        <Option value="false">부</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item name="order">
                                    <Select disabled={loading} placeholder="정렬" style={{ width: "150px" }}>
                                        <Option value={UserOrderByColumn.CREATED_DATETIME_ASC}>
                                            {userOrderByColumnToText(UserOrderByColumn.CREATED_DATETIME_ASC)}
                                        </Option>
                                        <Option value={UserOrderByColumn.CREATED_DATETIME_DESC}>
                                            {userOrderByColumnToText(UserOrderByColumn.CREATED_DATETIME_DESC)}
                                        </Option>
                                        <Option value={UserOrderByColumn.NAME_ASC}>
                                            {userOrderByColumnToText(UserOrderByColumn.NAME_ASC)}
                                        </Option>
                                        <Option value={UserOrderByColumn.NAME_DESC}>
                                            {userOrderByColumnToText(UserOrderByColumn.NAME_DESC)}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Left>
                    <Right>
                        <Row>
                            <Col style={{ marginRight: "0.5rem" }}>
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        htmlType="reset"
                                        onClick={() => {
                                            searchForm.resetFields();

                                            setMinCreatedDate(undefined);
                                            setMaxCreatedDate(undefined);
                                            setName(undefined);
                                            setPhone(undefined);
                                            setGender(undefined);
                                            setEmail(undefined);
                                            setIsDeleted(undefined);
                                            setOrder(undefined);
                                            setPage(1);
                                        }}
                                    >
                                        초기화
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button disabled={loading} htmlType="submit">
                                        검색
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Right>
                    <Clear />
                </Form>
            </Container>
            <Container>
                <TotalCountWrapper>어드민 수 : {userListTotal.toLocaleString("ko")}</TotalCountWrapper>
                <Table columns={columns} dataSource={userList} loading={loading} rowKey="id" />
                {userListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={userListTotal}
                    />
                )}
            </Container>
            <Modal
                footer={
                    <>
                        <Left>
                            <Button
                                danger
                                disabled={loading}
                                type="primary"
                                onClick={() => {
                                    setModal("deadmin");
                                }}
                            >
                                일반 회원으로 전환
                            </Button>
                        </Left>
                        <Right>
                            <Button
                                onClick={() => {
                                    setModal(undefined);
                                }}
                                style={{ marginRight: "0.5rem" }}
                            >
                                취소
                            </Button>
                            <Button
                                disabled={loading}
                                htmlType="submit"
                                form="editForm"
                                loading={loading}
                                type="primary"
                            >
                                수정
                            </Button>
                        </Right>
                        <Clear />
                    </>
                }
                onCancel={() => setModal(undefined)}
                title="정보 수정"
                visible={modal === "edit"}
                width={800}
            >
                <Form
                    form={editForm}
                    name="editForm"
                    onFinish={(values) => {
                        if (!modalUser || modalUserIndex === undefined) {
                            return message.error("회원을 선택해주세요", 1.5);
                        }
                        if (!values.username) {
                            return message.error("아이디를 입력해주세요", 1.5);
                        }

                        const birthday = moment(values.birthday);

                        setLoading(true);
                        userApi
                            .updateUser(modalUser.id.toString(), {
                                name: values.name !== userList[modalUserIndex].detail?.name ? values.name : undefined,
                                gender:
                                    values.gender !== userList[modalUserIndex].detail?.gender
                                        ? values.gender
                                        : undefined,
                                birth_date: birthday.isSame(moment(userList[modalUserIndex].detail?.birth_date))
                                    ? birthday.format("YYYY-MM-DD")
                                    : undefined,
                                email:
                                    values.email !== userList[modalUserIndex].detail?.email ? values.email : undefined,
                                username:
                                    values.username !== userList[modalUserIndex].username ? values.username : undefined,
                            })
                            .then(() => {
                                setModal(undefined);
                                setModalUser(undefined);
                                setModalUserIndex(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                editForm.resetFields();
                                message.success("수정하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="아이디" name="username">
                        <Input disabled={loading} placeholder="아이디" />
                    </FormItem>
                    <FormItem label="이름" name="name">
                        <Input disabled={loading} placeholder="이름" />
                    </FormItem>
                    <FormItem label="성별" name="gender">
                        <Select disabled={loading} placeholder="성별" style={{ width: "100%" }}>
                            <Option value={Gender.MALE}>{genderToText(Gender.MALE)}</Option>
                            <Option value={Gender.FEMALE}>{genderToText(Gender.FEMALE)}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="생일" name="birthday">
                        <DatePicker disabled={loading} placeholder="생일" style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem label="이메일" name="email">
                        <Input disabled={loading} placeholder="이메일" />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                }}
                okText="탈퇴"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!modalUser) {
                        return;
                    }

                    setLoading(true);
                    userApi
                        .deleteUser(modalUser.id.toString())
                        .then(() => {
                            setModal(undefined);
                            setModalUser(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("탈퇴하였습니다.", 1.5);
                        })
                        .catch(() => {
                            setLoading(false);
                            message.error("탈퇴를 실패하였습니다.", 1.5);
                        });
                }}
                title="탈퇴"
                visible={modal === "delete"}
                width={800}
            >
                회원을 탈퇴하시겠습니까?
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    htmlType: "submit",
                    form: "phoneForm",
                }}
                okText="변경"
                onCancel={() => setModal(undefined)}
                title="휴대폰번호 변경"
                visible={modal === "phone"}
                width={400}
            >
                <Form
                    form={phoneForm}
                    name="phoneForm"
                    onFinish={(values) => {
                        if (!modalUser) {
                            return;
                        }
                        if (!modalUser.detail?.phone_number) {
                            return;
                        }
                        if (!values.phone) {
                            return message.error("휴대폰번호를 입력해주세요.", 1.5);
                        }

                        setLoading(true);
                        userApi
                            .changePhoneNumber(modalUser.id.toString(), { phone_number: values.phone })
                            .then(() => {
                                setModal(undefined);
                                setModalUser(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("수정하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="휴대폰번호" name="phone">
                        <Input disabled={loading} placeholder="휴대폰번호" />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    form: "passwordForm",
                    htmlType: "submit",
                }}
                okText="변경"
                onCancel={() => setModal(undefined)}
                title="비밀번호 변경"
                visible={modal === "password"}
                width={800}
            >
                <Form
                    form={passwordForm}
                    name="passwordForm"
                    onFinish={(values) => {
                        if (!modalUser) {
                            return;
                        }

                        if (!values.passwordNew) {
                            return message.error("새 비밀번호를 입력해주세요", 1.5);
                        }

                        setLoading(true);
                        userApi
                            .changePassword(modalUser.id.toString(), { new_password: values.passwordNew })
                            .then(() => {
                                setModal(undefined);
                                setModalUser(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                editForm.resetFields();
                                message.success("변경하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="새 비밀번호" name="passwordNew">
                        <Input disabled={loading} placeholder="새 비밀번호" type="password" />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                }}
                okText="변경"
                onCancel={() => {
                    setModal(undefined);
                    setModalUser(undefined);
                }}
                onOk={() => {
                    if (!modalUser) {
                        return;
                    }

                    setLoading(true);
                    userApi
                        .updateUser(modalUser.id.toString(), { user_type: UserType.USER })
                        .then(() => {
                            setModal(undefined);
                            setModalUser(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("변경하였습니다.", 1.5);
                        })
                        .catch((error) => {
                            setLoading(false);
                            message.error(error.response.data.detail, 1.5);
                        });
                }}
                title="일반 회원으로 변경"
                visible={modal === "deadmin"}
                width={800}
            >
                일반 회원으로 변경하시겠습니까?
            </Modal>
        </>
    );
}
