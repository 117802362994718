import React, { useState } from "react";
import moment from "moment";
import { Button, Checkbox, Form, Input, message, Modal, Switch, TimePicker, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
    ShopBusinessHourResponse,
    ShopMoreInfo,
    ShopResponse,
    // UploadedImageSetResponse,
    UserResponse,
    UserType,
} from "@ferda/rest-api-client";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useHistory from "@/hooks/useCustomHistory";
import { getRestApiClient } from "@/redux/auth/selectors";
import { getOauth2TokenFromLocalStorage } from "@/redux/auth/utils";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import FormItem from "@/components/Admin/FormItem";
import KakaoMap from "@/components/Admin/KakaoMap";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import { Clear, Left, Right } from "@/components/Admin/Float";
import { FormLabelText, InfoContainer, InfoTitleContainer, SearchBarContainer, SwitchContainer } from "./style";

const { RangePicker } = TimePicker;
const { TextArea } = Input;

const MUST_HAVE_INFOS = ["전화번호", "서비스"];

interface Props {}

export default function PosShopInfo(props: Props) {
    const { posId } = useParams<{ posId: string }>();
    const history = useHistory();

    const { shopApi, userApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = useState<boolean>(false);

    const [token, setToken] = useState<string | undefined>();

    const [shop, setShop] = useState<ShopResponse | undefined>();
    const [shopHours, setShopHours] = useState<ShopBusinessHourResponse[]>([]);

    const [modal, setModal] = useState<string | undefined>();
    const [modalTimeDisabled, setModalTimeDisabled] = useState<boolean[]>([]);
    const [modalUser, setModalUser] = useState<UserResponse | undefined>();
    const [modalUserList, setModalUserList] = useState<UserResponse[]>([]);
    const [modalUserListTotal, setModalUserListTotal] = useState<number>(0);
    const [modalUserName, setModalUserName] = useState<string | undefined>();
    const [modalUserPhone, setModalUserPhone] = useState<string | undefined>();
    const [modalUserPage, setModalUserPage] = useState<number>(1);

    const [editName, setEditName] = useState<string | undefined>();
    const [editLat, setEditLat] = useState<number | undefined>();
    const [editLong, setEditLong] = useState<number | undefined>();
    const [editAddress, setEditAddress] = useState<string | undefined>();
    const [editAddressDetail, setEditAddressDetail] = useState<string | undefined>();
    const [editInfo, setEditInfo] = useState<ShopMoreInfo[]>([]);
    const [editUser, setEditUser] = useState<UserResponse | undefined>();

    const [businessClosure, setBusinessClosure] = useState<boolean | null>(null);
    const [disposableCup, setDisposableCup] = useState<boolean | null>(null);
    const [reusableCup, setReusableCup] = useState<boolean | null>(null);

    const [editForm] = Form.useForm();
    const [timeForm] = Form.useForm();
    const [userSearchForm] = Form.useForm();

    const userColumns = [
        {
            title: "휴대폰번호",
            key: "phone",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.phone_number,
                };
            },
            width: "150px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: UserResponse) => {
                return {
                    children: record.detail?.name,
                };
            },
            width: "125px",
        },
        {
            title: "아이디",
            key: "username",
            render: (record: UserResponse) => {
                return {
                    children: record.username,
                };
            },
        },
        {
            title: "선택",
            key: "select",
            render: (record: UserResponse) => {
                return {
                    children: (
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setModalUser(record);
                                setModal(undefined);
                                editForm.setFieldsValue({ user: record.detail!.name });
                            }}
                        >
                            선택
                        </Button>
                    ),
                };
            },
            width: "90px",
        },
    ];

    React.useEffect(() => {
        const tokens = getOauth2TokenFromLocalStorage();
        if (!tokens) {
            history.push("/pos/login");
            message.error("회원 정보를 불러오지 못하였습니다.");
            return;
        }
        setToken(tokens.token);

        setLoading(true);
        Promise.all([shopApi.getShop(parseInt(posId, 10)), shopApi.getBusinessHours(parseInt(posId, 10))])
            .then((values) => {
                const info =
                    values[0].data.more_infos.length > 0
                        ? values[0].data.more_infos
                        : [
                              {
                                  name: "전화번호",
                                  value: "",
                              },
                              {
                                  name: "서비스",
                                  value: "",
                              },
                          ];

                const files = [];
                values[0].data.uploaded_image_sets.forEach((file) => {
                    if (files.length >= 5) {
                        return;
                    }
                    files.push(file);
                });
                for (let i = files.length; i < 5; i += 1) {
                    files.push(undefined);
                }

                setLoading(false);
                setShop(values[0].data);
                setShopHours(values[1].data);
                setEditName(values[0].data.name);
                setEditLat(values[0].data.latitude);
                setEditLong(values[0].data.longitude);
                setEditAddress(values[0].data.address);
                setEditAddressDetail(values[0].data.address_detail);
                setEditInfo(info);
                setEditUser(values[0].data.shop_owner);
                setBusinessClosure(values[0].data.temporary_business_closure);
                setDisposableCup(values[0].data.disposable_cup_available);
                setReusableCup(values[0].data.reusable_cup_available);
                editForm.setFieldsValue({
                    user: values[0].data.shop_owner ? values[0].data.shop_owner.detail!.name : undefined,
                });
            })
            .catch(() => {
                setLoading(false);
                message.error("가맹점을 불러오는데 실패하였습니다.");
            });
    }, []);

    React.useEffect(() => {
        setLoading(true);
        userApi
            .getUsers(
                undefined,
                undefined,
                undefined,
                [UserType.USER, UserType.SHOP_OWNER, UserType.ADMIN],
                undefined,
                undefined,
                undefined,
                undefined,
                modalUserName,
                modalUserPhone,
                undefined,
                undefined,
                undefined,
                undefined,
                modalUserPage,
            )
            .then((res) => {
                setLoading(false);
                setModalUserList(res.data);
                setModalUserListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("회원 목록을 불러오는데 실패하였습니다.");
            });
    }, [modalUserName, modalUserPhone, modalUserPage]);

    return (
        <>
            <PageTitle>가맹점 관리</PageTitle>
            <Container>
                <Form form={editForm}>
                    <FormItem label="이름">
                        <Input
                            disabled={loading}
                            onChange={(event) => setEditName(event.target.value)}
                            placeholder="이름"
                            value={editName}
                        />
                    </FormItem>
                    <FormItem label="위치">
                        <KakaoMap
                            latLong={{
                                lat: editLat!,
                                long: editLong!,
                            }}
                            mapId="edit-map"
                            onPlaceSelect={(place) => {
                                setEditLat(place.lat);
                                setEditLong(place.long);
                                setEditAddress(place.roadAddressName);
                            }}
                        />
                    </FormItem>
                    <FormItem label="위도 / 경도">
                        <div style={{ display: "flex" }}>
                            <Input disabled={loading} readOnly value={editLat} style={{ marginRight: "0.25rem" }} />
                            <Input disabled={loading} readOnly value={editLong} style={{ marginLeft: "0.25rem" }} />
                        </div>
                    </FormItem>
                    <FormItem label="주소">
                        <Input disabled={loading} placeholder="주소" readOnly value={editAddress} />
                    </FormItem>
                    <FormItem label="상세 주소">
                        <Input
                            disabled={loading}
                            onChange={(event) => setEditAddressDetail(event.target.value)}
                            placeholder="상세 주소"
                            value={editAddressDetail}
                        />
                    </FormItem>
                    <FormItem label="상세 정보">
                        {editInfo.map((info, index) => (
                            <InfoContainer key={`${index}-${info.name}`}>
                                <InfoTitleContainer>
                                    <Input
                                        defaultValue={info.name}
                                        disabled={loading}
                                        onBlur={(event) => {
                                            const info = [...editInfo];
                                            info[index].name = event.target.value;
                                            setEditInfo(info);
                                        }}
                                        readOnly={MUST_HAVE_INFOS.findIndex((value) => value === info.name) !== -1}
                                        style={{ marginRight: "1rem" }}
                                    />
                                    <Button
                                        danger
                                        disabled={
                                            loading || MUST_HAVE_INFOS.findIndex((value) => value === info.name) !== -1
                                        }
                                        onClick={() => {
                                            const info = [...editInfo];
                                            info.splice(index, 1);
                                            setEditInfo(info);
                                        }}
                                    >
                                        삭제
                                    </Button>
                                </InfoTitleContainer>
                                <TextArea
                                    defaultValue={info.value}
                                    disabled={loading}
                                    onBlur={(event) => {
                                        const info = [...editInfo];
                                        info[index].value = event.target.value;
                                        setEditInfo(info);
                                    }}
                                    rows={3}
                                />
                            </InfoContainer>
                        ))}
                        <Button
                            disabled={loading}
                            style={{ width: "100%" }}
                            onClick={() => {
                                const info = [...editInfo];
                                info.push({
                                    name: "",
                                    value: "",
                                });
                                setEditInfo(info);
                            }}
                        >
                            추가
                        </Button>
                    </FormItem>
                    {/* <FormItem label="가맹점 오너" name="user">
                        <Input
                            disabled={loading}
                            placeholder="가맹점 오너"
                            onFocus={(e) => {
                                setModal("user");
                                setModalUserName(undefined);
                                setModalUserPhone(undefined);
                                setModalUserPage(1);
                                userSearchForm.resetFields();
                                e.target.blur();
                            }}
                            readOnly
                            value={modalUser?.detail!.name}
                        />
                    </FormItem> */}
                    <FormItem label="사진">
                        <Upload
                            action={`${process.env.REACT_APP_REST_API_BASE_URL}/uploaded-image-sets/upload-image`}
                            headers={{ Authorization: `bearer ${token}` }}
                            name="image"
                            listType="picture"
                            maxCount={5}
                            onChange={({ fileList }) => {
                                // setEditFiles(fileList.map((file) => {
                                //     return
                                // }));
                            }}
                        >
                            <Button icon={<UploadOutlined />}>사진 업로드 (최대 5개)</Button>
                        </Upload>
                    </FormItem>
                </Form>
            </Container>
            <Container>
                <SwitchContainer>
                    <Left>
                        <FormLabelText>휴업</FormLabelText>
                    </Left>
                    <Right>
                        <Switch
                            disabled={loading}
                            checked={businessClosure || false}
                            onChange={(value) => {
                                if (!posId || businessClosure === null) {
                                    return;
                                }
                                setLoading(true);
                                shopApi
                                    .updateShop(Number(posId), { temporary_business_closure: value })
                                    .then(() => {
                                        setLoading(false);
                                        setBusinessClosure(!businessClosure);
                                        message.success("휴업 여부를 수정하였습니다.", 1.5);
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        message.error(error.response.data.detail, 1.5);
                                    });
                            }}
                        />
                    </Right>
                    <Clear />
                </SwitchContainer>
                <SwitchContainer>
                    <Left>
                        <FormLabelText>뉴 컵 선택 가능 여부</FormLabelText>
                    </Left>
                    <Right>
                        <Switch
                            disabled={loading}
                            checked={disposableCup || false}
                            onChange={(value) => {
                                if (!posId || disposableCup === null) {
                                    return;
                                }
                                setLoading(true);
                                shopApi
                                    .updateShop(Number(posId), { disposable_cup_available: value })
                                    .then(() => {
                                        setLoading(false);
                                        setDisposableCup(!disposableCup);
                                        message.success("뉴 컵 선택 가능 여부를 수정하였습니다.", 1.5);
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        message.error(error.response.data.detail, 1.5);
                                    });
                            }}
                        />
                    </Right>
                    <Clear />
                </SwitchContainer>
                <SwitchContainer>
                    <Left>
                        <FormLabelText>리턴 컵 선택 가능 여부</FormLabelText>
                    </Left>
                    <Right>
                        <Switch
                            disabled={loading}
                            checked={reusableCup || false}
                            onChange={(value) => {
                                if (!posId || reusableCup === null) {
                                    return;
                                }
                                setLoading(true);
                                shopApi
                                    .updateShop(Number(posId), { reusable_cup_available: value })
                                    .then(() => {
                                        setLoading(false);
                                        setReusableCup(!reusableCup);
                                        message.success("리턴 컵 선택 가능 여부를 수정하였습니다.", 1.5);
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        message.error(error.response.data.detail, 1.5);
                                    });
                            }}
                        />
                    </Right>
                    <Clear />
                </SwitchContainer>
            </Container>
            <Container>
                <Left>
                    <Button
                        disabled={loading}
                        onClick={() => {
                            const disabled = new Array(7).fill(true);

                            const hours: { [index: string]: any } = {};
                            shopHours.forEach((hour) => {
                                const data = [moment(hour.start_time, "HH:mm:ss"), moment(hour.end_time, "HH:mm:ss")];
                                switch (hour.weekday) {
                                    case 0:
                                        hours.monday = data;
                                        disabled[0] = false;
                                        return;
                                    case 1:
                                        hours.tuesday = data;
                                        disabled[1] = false;
                                        return;
                                    case 2:
                                        hours.wednesday = data;
                                        disabled[2] = false;
                                        return;
                                    case 3:
                                        hours.thursday = data;
                                        disabled[3] = false;
                                        return;
                                    case 4:
                                        hours.friday = data;
                                        disabled[4] = false;
                                        return;
                                    case 5:
                                        hours.saturday = data;
                                        disabled[5] = false;
                                        return;
                                    case 6:
                                        hours.sunday = data;
                                        disabled[6] = false;
                                }
                            });

                            setModal("time");
                            setModalTimeDisabled(disabled);

                            timeForm.setFieldsValue(hours);
                        }}
                    >
                        영업 시간 설정
                    </Button>
                </Left>
                <Right>
                    <Button disabled={loading} onClick={() => setModal("finish")} type="primary">
                        저장
                    </Button>
                </Right>
                <Clear />
            </Container>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    htmlType: "submit",
                    form: "timeForm",
                }}
                okText="수정"
                onCancel={() => setModal(undefined)}
                title="가맹점 시간 수정"
                visible={modal === "time"}
                width={800}
            >
                <Form
                    form={timeForm}
                    name="timeForm"
                    onFinish={(values) => {
                        if (!shop) {
                            return message.error("가맹점이 존재하지않습니다.", 1.5);
                        }

                        const times = [];
                        if (values.monday && !modalTimeDisabled[0]) {
                            times.push({
                                weekday: 0,
                                start_time: values.monday[0].format("HH:mm:ss"),
                                end_time: values.monday[1].format("HH:mm:ss"),
                            });
                        }
                        if (values.tuesday && !modalTimeDisabled[1]) {
                            times.push({
                                weekday: 1,
                                start_time: values.tuesday[0].format("HH:mm:ss"),
                                end_time: values.tuesday[1].format("HH:mm:ss"),
                            });
                        }
                        if (values.wednesday && !modalTimeDisabled[2]) {
                            times.push({
                                weekday: 2,
                                start_time: values.wednesday[0].format("HH:mm:ss"),
                                end_time: values.wednesday[1].format("HH:mm:ss"),
                            });
                        }
                        if (values.thursday && !modalTimeDisabled[3]) {
                            times.push({
                                weekday: 3,
                                start_time: values.thursday[0].format("HH:mm:ss"),
                                end_time: values.thursday[1].format("HH:mm:ss"),
                            });
                        }
                        if (values.friday && !modalTimeDisabled[4]) {
                            times.push({
                                weekday: 4,
                                start_time: values.friday[0].format("HH:mm:ss"),
                                end_time: values.friday[1].format("HH:mm:ss"),
                            });
                        }
                        if (values.saturday && !modalTimeDisabled[5]) {
                            times.push({
                                weekday: 5,
                                start_time: values.saturday[0].format("HH:mm:ss"),
                                end_time: values.saturday[1].format("HH:mm:ss"),
                            });
                        }
                        if (values.sunday && !modalTimeDisabled[6]) {
                            times.push({
                                weekday: 6,
                                start_time: values.sunday[0].format("HH:mm:ss"),
                                end_time: values.sunday[1].format("HH:mm:ss"),
                            });
                        }

                        setLoading(true);
                        shopApi
                            .setBusinessHours(shop.id, times)
                            .then((values) => {
                                setModal(undefined);
                                message.success("시간을 수정하였습니다.", 1.5);

                                shopApi
                                    .getBusinessHours(parseInt(posId, 10))
                                    .then((res) => {
                                        setShopHours(res.data);
                                        setLoading(false);
                                    })
                                    .catch(() => {
                                        setLoading(false);
                                        message.error("시간 목록을 불러오는데 실패하였습니다.", 1.5);
                                    });
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem
                        label="월요일"
                        name="monday"
                        right={
                            <div>
                                휴무
                                <Checkbox
                                    checked={modalTimeDisabled[0]}
                                    disabled={loading}
                                    onChange={(value) => {
                                        const disabled = [...modalTimeDisabled];
                                        disabled[0] = value.target.checked;
                                        setModalTimeDisabled(disabled);
                                    }}
                                />
                            </div>
                        }
                    >
                        <RangePicker disabled={loading || modalTimeDisabled[0]} style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem
                        label="화요일"
                        name="tuesday"
                        right={
                            <div>
                                휴무
                                <Checkbox
                                    checked={modalTimeDisabled[1]}
                                    disabled={loading}
                                    onChange={(value) => {
                                        const disabled = [...modalTimeDisabled];
                                        disabled[1] = value.target.checked;
                                        setModalTimeDisabled(disabled);
                                    }}
                                />
                            </div>
                        }
                    >
                        <RangePicker disabled={loading || modalTimeDisabled[1]} style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem
                        label="수요일"
                        name="wednesday"
                        right={
                            <div>
                                휴무
                                <Checkbox
                                    checked={modalTimeDisabled[2]}
                                    disabled={loading}
                                    onChange={(value) => {
                                        const disabled = [...modalTimeDisabled];
                                        disabled[2] = value.target.checked;
                                        setModalTimeDisabled(disabled);
                                    }}
                                />
                            </div>
                        }
                    >
                        <RangePicker disabled={loading || modalTimeDisabled[2]} style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem
                        label="목요일"
                        name="thursday"
                        right={
                            <div>
                                휴무
                                <Checkbox
                                    checked={modalTimeDisabled[3]}
                                    disabled={loading}
                                    onChange={(value) => {
                                        const disabled = [...modalTimeDisabled];
                                        disabled[3] = value.target.checked;
                                        setModalTimeDisabled(disabled);
                                    }}
                                />
                            </div>
                        }
                    >
                        <RangePicker disabled={loading || modalTimeDisabled[3]} style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem
                        label="금요일"
                        name="friday"
                        right={
                            <div>
                                휴무
                                <Checkbox
                                    checked={modalTimeDisabled[4]}
                                    disabled={loading}
                                    onChange={(value) => {
                                        const disabled = [...modalTimeDisabled];
                                        disabled[4] = value.target.checked;
                                        setModalTimeDisabled(disabled);
                                    }}
                                />
                            </div>
                        }
                    >
                        <RangePicker disabled={loading || modalTimeDisabled[4]} style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem
                        label="토요일"
                        name="saturday"
                        right={
                            <div>
                                휴무
                                <Checkbox
                                    checked={modalTimeDisabled[5]}
                                    disabled={loading}
                                    onChange={(value) => {
                                        const disabled = [...modalTimeDisabled];
                                        disabled[5] = value.target.checked;
                                        setModalTimeDisabled(disabled);
                                    }}
                                />
                            </div>
                        }
                    >
                        <RangePicker disabled={loading || modalTimeDisabled[5]} style={{ width: "100%" }} />
                    </FormItem>
                    <FormItem
                        label="일요일"
                        name="sunday"
                        right={
                            <div>
                                휴무
                                <Checkbox
                                    checked={modalTimeDisabled[6]}
                                    disabled={loading}
                                    onChange={(value) => {
                                        const disabled = [...modalTimeDisabled];
                                        disabled[6] = value.target.checked;
                                        setModalTimeDisabled(disabled);
                                    }}
                                />
                            </div>
                        }
                    >
                        <RangePicker disabled={loading || modalTimeDisabled[6]} style={{ width: "100%" }} />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                okButtonProps={{ danger: true }}
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    history.back();
                }}
                title="취소"
                visible={modal === "cancel"}
                width={800}
            >
                수정을 취소하시겠습니까?
            </Modal>
            <Modal
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!shop) {
                        return message.error("가맹점이 불러와지지 않았습니다.", 1.5);
                    }

                    if (!editName) {
                        return message.error("가맹점 이름을 입력해주세요.", 1.5);
                    }
                    if (editLat === undefined || editLong === undefined || !editAddress) {
                        return message.error("지도에서 주소를 선택해주세요.", 1.5);
                    }
                    if (!editAddressDetail) {
                        return message.error("상세 주소를 입력해주세요.", 1.5);
                    }
                    for (let i = 0; i < editInfo.length; i += 1) {
                        if (!editInfo[i].name) {
                            return message.error("상세 정보 제목을 입력해주세요.", 1.5);
                        }
                        if (!editInfo[i].value) {
                            return message.error("상세 정보 내용을 입력해주세요.", 1.5);
                        }
                    }
                    const promises: any[] = [];

                    setLoading(true);
                    Promise.all(promises)
                        .then((promiseValues) => {
                            const imageIds: number[] = [];
                            promiseValues.forEach((res: any) => {
                                if (res && res.data) {
                                    imageIds.push(res.data.id);
                                } else if (res && res.id !== undefined) {
                                    imageIds.push(res.id);
                                }
                            });

                            let user;
                            if (modalUser) {
                                user = modalUser.username;
                            } else if (editUser) {
                                user = editUser.username;
                            }

                            shopApi
                                .updateShop(shop.id, {
                                    name: editName,
                                    uploaded_image_set_ids: imageIds,
                                    address: editAddress,
                                    address_detail: editAddressDetail,
                                    latitude: editLat,
                                    longitude: editLong,
                                    more_infos: editInfo,
                                    shop_owner_username_or_id_or_me: user,
                                })
                                .then(() => {
                                    message.success("수정하였습니다.", 1.5);
                                    setModal(undefined);
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    message.error(error.response.data.detail, 1.5);
                                });
                        })
                        .catch((error) => {
                            setLoading(false);
                            message.error(error.response.data.detail, 1.5);
                        });
                }}
                title="저장"
                visible={modal === "finish"}
                width={800}
            >
                저장하시겠습니까?
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    style: { display: "none" },
                }}
                onCancel={() => setModal(undefined)}
                title="회원 선택"
                visible={modal === "user"}
                width={800}
            >
                <Form
                    form={userSearchForm}
                    onFinish={(values) => {
                        if (!values.name && !values.phone) {
                            return message.error("검색어를 입력해주세요.", 1.5);
                        }

                        setModalUserName(values.name);
                        setModalUserPhone(values.phone);
                        setModalUserPage(1);
                    }}
                >
                    <SearchBarContainer>
                        <Form.Item name="phone" style={{ marginBottom: "0", marginRight: "0.5rem", flexGrow: 1 }}>
                            <Input placeholder="휴대폰번호" style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item name="name" style={{ marginBottom: "0", marginRight: "0.5rem", flexGrow: 1 }}>
                            <Input placeholder="이름" style={{ width: "100%" }} />
                        </Form.Item>
                        <Button disabled={loading} htmlType="submit">
                            검색
                        </Button>
                    </SearchBarContainer>
                    <Table columns={userColumns} dataSource={modalUserList} loading={loading} rowKey="id" />
                    {modalUserListTotal > 0 && (
                        <Pagination
                            current={modalUserPage}
                            disabled={loading}
                            onChange={(page: number) => setModalUserPage(page)}
                            pageSize={20}
                            total={modalUserListTotal}
                        />
                    )}
                </Form>
            </Modal>
        </>
    );
}
