import { ButtonBase } from "@mui/material";
import styled from "@emotion/styled";
import React from "react";
import { MenuResponse } from "@ferda/rest-api-client";
import Link from "@/components/CustomLink";
import PlaceHolderImage from "@/assets/img/place_holder.png";
import { comma } from "@/utils/text";

const RecommendedMenuCardContainer = styled(ButtonBase)`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    width: 145px;
    color: black;
`;

const ThumbnailImg = styled.img`
    width: 145px;
    height: 145px;
`;

const TextContainer = styled.div<{ hidePrice?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    height: ${({ hidePrice }) => (hidePrice ? "58px" : "82px")};
    width: 100%;

    text-align: left;

    background: #ffffff;
`;

const MenuNameContainer = styled.div`
    font-size: 14px;
    color: #0c0c0c;
    text-align: center;
`;

const PriceContainer = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: white;
`;

const PriceUnitContainer = styled.span`
    font-weight: normal;
    font-size: 12px;
`;

interface RecommendedMenuCardProps {
    menu: MenuResponse;
    hidePrice?: boolean;
}

export default function RecommendedMenuCard({ menu, hidePrice }: RecommendedMenuCardProps) {
    return (
        <Link to={`/order/${menu.menu_group.id}/${menu.id}`}>
            <RecommendedMenuCardContainer>
                <ThumbnailImg src={menu.uploaded_image_set?.original_image_url ?? PlaceHolderImage} />
                <TextContainer hidePrice={hidePrice}>
                    <MenuNameContainer>{menu.name}</MenuNameContainer>
                    {!hidePrice && (
                        <PriceContainer>
                            {comma(menu.price)}
                            <PriceUnitContainer>원</PriceUnitContainer>
                        </PriceContainer>
                    )}
                </TextContainer>
            </RecommendedMenuCardContainer>
        </Link>
    );
}
