import { createSlice } from "@reduxjs/toolkit";
import {
    CheckUserExistFetchRequestSuccessPayload,
    LoginState,
    VerifyPhoneVerificationCodeFetchRequestSuccessPayload,
} from "./types";
import { makeAsyncReducer, setValueReducerList } from "@/utils/redux/reducer";
import { getKeyListFromState } from "@/utils/redux/state";

const initialState: LoginState = {
    phoneVerificationFetchState: "READY",
    verifyPhoneVerificationCodeFetchState: "READY",
    verifyPhoneVerificationCodeIsVerified: null,
    checkUserExistFetchState: "READY",
    userExist: null,
    generateUserTokenFetchState: "READY",
    isFirstLogin: false,
};

export const LOGIN_STATE_KEY_LIST = getKeyListFromState(initialState);

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        ...makeAsyncReducer<"phoneVerification", LoginState>("phoneVerification"),
        ...makeAsyncReducer<
            "verifyPhoneVerificationCode",
            LoginState,
            void,
            VerifyPhoneVerificationCodeFetchRequestSuccessPayload
        >("verifyPhoneVerificationCode", {
            successReducer: (state, { payload: { verified } }) => {
                state.verifyPhoneVerificationCodeFetchState = "SUCCESS";
                state.verifyPhoneVerificationCodeIsVerified = verified;
            },
        }),
        ...makeAsyncReducer<"checkUserExist", LoginState, void, CheckUserExistFetchRequestSuccessPayload>(
            "checkUserExist",
            {
                successReducer: (state, { payload: { exists } }) => {
                    state.checkUserExistFetchState = "SUCCESS";
                    state.userExist = exists;
                },
            },
        ),
        ...makeAsyncReducer<"generateUserToken", LoginState>("generateUserToken"),
        ...setValueReducerList<
            LoginState,
            ["verifyPhoneVerificationCodeIsVerified", "userExist", "isFirstLogin", "checkUserExistFetchState"]
        >(["verifyPhoneVerificationCodeIsVerified", "userExist", "isFirstLogin", "checkUserExistFetchState"]),
        reset: () => initialState,
    },
});

export const loginReducer = loginSlice.reducer;
