import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { LogTabHeader, LogTabHeaderButton, LogTabHeaderWrapper } from "./style";
import { getQueryStringObject } from "@/utils/global";
import LogMenu from "./LogMenu";
import LogSubscribe from "./LogSubscribe";

type HeaderTabType = "menu" | "subscribe";

export default function LogTab() {
    const [headerTab, setHeaderTab] = useState<HeaderTabType>("menu");
    const { search } = useLocation();
    const query = useMemo(() => getQueryStringObject(search), [search]);

    useEffect(() => {
        if (query.headerTypeTab && headerTab !== query.headerTypeTab) {
            setHeaderTab(query.headerTypeTab as HeaderTabType);
        }
    }, [query]);

    return (
        <div>
            <LogTabHeaderWrapper>
                <LogTabHeader>
                    <LogTabHeaderButton selected={headerTab === "menu"} onClick={() => setHeaderTab("menu")}>
                        단품메뉴
                    </LogTabHeaderButton>
                    <LogTabHeaderButton selected={headerTab === "subscribe"} onClick={() => setHeaderTab("subscribe")}>
                        구독권
                    </LogTabHeaderButton>
                </LogTabHeader>
            </LogTabHeaderWrapper>

            {headerTab === "menu" && <LogMenu />}
            {headerTab === "subscribe" && <LogSubscribe />}
        </div>
    );
}
