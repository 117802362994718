import React from "react";
import { Button, message, Form, Input, Modal, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MenuGroupResponse } from "@ferda/rest-api-client";
import { getRestApiClient } from "@/redux/auth/selectors";
import { getOauth2TokenFromLocalStorage } from "@/redux/auth/utils";

import PageTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import FormItem from "@/components/Admin/FormItem";
import { Clear, Right } from "@/components/Admin/Float";
import { MenuGroupInfoTitle } from "./MenusStyled";
import { TotalCountWrapper } from "@/components/Admin/Misc";

interface Props {}

export default function Page(props: Props) {
    const history = useHistory();

    const { shopApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const [token, setToken] = React.useState<string | undefined>();

    const [modal, setModal] = React.useState<string | undefined>();
    const [modalMenuGroup, setModalMenuGroup] = React.useState<MenuGroupResponse | undefined>();
    const [modalEditFiles, setModalEditFiles] = React.useState<Array<UploadFile<any>>>([]);

    const [menuGroupList, setMenuGroupList] = React.useState<MenuGroupResponse[]>([]);
    const [menuGroupListTotal, setMenuGroupListTotal] = React.useState<number>(0);

    const [page, setPage] = React.useState<number>(1);

    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();

    const columns = [
        {
            title: "사진",
            key: "image",
            render: (record: MenuGroupResponse) => {
                return {
                    children: record.uploaded_image_set && (
                        <img src={record.uploaded_image_set.original_image_url} width={64} height={64} />
                    ),
                };
            },
            width: "100px",
        },
        {
            title: "정보",
            key: "info",
            render: (record: MenuGroupResponse) => {
                return {
                    children: (
                        <>
                            <MenuGroupInfoTitle onClick={() => history.push(`/admin/menus/view/${record.id}`)}>
                                {record.name}
                            </MenuGroupInfoTitle>
                            <p>{record.description}</p>
                        </>
                    ),
                };
            },
        },
        {
            title: "관리",
            key: "manage",
            render: (record: MenuGroupResponse) => {
                return {
                    children: (
                        <>
                            <Button
                                disabled={loading}
                                onClick={() => {
                                    setModal("edit");
                                    setModalMenuGroup(record);
                                    if (record.uploaded_image_set) {
                                        setModalEditFiles([
                                            {
                                                uid: record.uploaded_image_set.id.toString(),
                                                url: record.uploaded_image_set.original_image_url,
                                                status: "done",
                                                name: "메뉴 그룹 사진",
                                            },
                                        ]);
                                    } else {
                                        setModalEditFiles([]);
                                    }
                                    editForm.setFieldsValue({
                                        name: record.name,
                                        description: record.description,
                                    });
                                }}
                                style={{ marginRight: "0.5rem" }}
                            >
                                그룹 수정
                            </Button>
                            <Button
                                danger
                                disabled={loading}
                                onClick={() => {
                                    setModal("delete");
                                    setModalMenuGroup(record);
                                }}
                            >
                                그룹 삭제
                            </Button>
                        </>
                    ),
                };
            },
            width: "220px",
        },
    ];

    React.useEffect(() => {
        const tokens = getOauth2TokenFromLocalStorage();
        if (!tokens) {
            history.push("/admin/login");
            message.error("회원 정보를 불러오지 못하였습니다.");
            return;
        }
        setToken(tokens.token);
    }, []);

    React.useEffect(() => {
        setLoading(true);
        shopApi
            .getMenuGroups(false, page)
            .then((res) => {
                setLoading(false);
                setMenuGroupList(res.data);
                setMenuGroupListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("메뉴 그룹을 불러오는데 실패하였습니다.");
            });
    }, [page, rerender]);

    return (
        <>
            <PageTitle>메뉴 관리</PageTitle>
            <Container>
                <Right>
                    <Button
                        disabled={loading}
                        onClick={() => history.push("/admin/menus/order")}
                        style={{ marginRight: "0.5rem" }}
                    >
                        그룹 순서 변경
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={() => {
                            setModal("add");
                            addForm.resetFields();
                        }}
                    >
                        그룹 추가
                    </Button>
                </Right>
                <Clear />
            </Container>
            <Container>
                <TotalCountWrapper>메뉴 그룹 수 : {menuGroupListTotal.toLocaleString("ko")}</TotalCountWrapper>
                <Table columns={columns} dataSource={menuGroupList} loading={loading} rowKey="id" />
                {menuGroupListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={menuGroupListTotal}
                    />
                )}
            </Container>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    form: "addForm",
                    htmlType: "submit",
                }}
                okText="추가"
                onCancel={() => setModal(undefined)}
                title="그룹 추가"
                visible={modal === "add"}
                width={800}
            >
                <Form
                    form={addForm}
                    name="addForm"
                    onFinish={(values) => {
                        if (!values.name) {
                            return message.error("이릅을 입력해주세요.", 1.5);
                        }
                        if (!values.description) {
                            return message.error("설명을 입력해주세요.", 1.5);
                        }

                        setLoading(true);
                        shopApi
                            .createMenuGroup({
                                name: values.name,
                                description: values.description,
                            })
                            .then(() => {
                                setModal(undefined);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("메뉴 그룹을 추가하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="이름" name="name">
                        <Input disabled={loading} placeholder="이름" />
                    </FormItem>
                    <FormItem label="설명" name="description">
                        <Input disabled={loading} placeholder="설명" />
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                }}
                okText="삭제"
                onCancel={() => setModal(undefined)}
                onOk={() => {
                    if (!modalMenuGroup) {
                        return message.error("그룹을 선택해주세요.", 1.5);
                    }

                    setLoading(true);
                    shopApi
                        .deleteMenuGroup(modalMenuGroup.id)
                        .then(() => {
                            setModal(undefined);
                            setModalMenuGroup(undefined);
                            setLoading(false);
                            setRerender(!rerender);
                            message.success("메뉴 그룹을 삭제하였습니다.", 1.5);
                        })
                        .catch((error) => {
                            setLoading(false);
                            message.error(error.response.data.detail, 1.5);
                        });
                }}
                title="그룹 삭제"
                visible={modal === "delete"}
                width={800}
            >
                그룹을 삭제하시겠습니까?
            </Modal>
            <Modal
                confirmLoading={loading}
                okButtonProps={{
                    form: "editForm",
                    htmlType: "submit",
                }}
                okText="수정"
                onCancel={() => setModal(undefined)}
                title="그룹 수정"
                visible={modal === "edit"}
                width={800}
            >
                <Form
                    form={editForm}
                    name="editForm"
                    onFinish={(values) => {
                        if (!modalMenuGroup) {
                            return message.error("그룹을 선택해주세요.", 1.5);
                        }
                        if (!values.name) {
                            return message.error("이릅을 입력해주세요.", 1.5);
                        }
                        if (!values.description) {
                            return message.error("설명을 입력해주세요.", 1.5);
                        }

                        let fileId = null;
                        if (modalEditFiles.length > 0 && modalEditFiles[0].status === "done") {
                            if (modalEditFiles[0].response) {
                                fileId = modalEditFiles[0].response.id;
                            } else {
                                fileId = parseInt(modalEditFiles[0].uid, 10);
                            }
                        }

                        setLoading(true);
                        shopApi
                            .updateMenuGroup(modalMenuGroup.id, {
                                name: values.name,
                                description: values.description,
                                uploaded_image_set_id: fileId,
                            })
                            .then(() => {
                                setModal(undefined);
                                setModalMenuGroup(undefined);
                                setModalEditFiles([]);
                                setLoading(false);
                                setRerender(!rerender);
                                message.success("메뉴 그룹을 수정하였습니다.", 1.5);
                            })
                            .catch((error) => {
                                setLoading(false);
                                message.error(error.response.data.detail, 1.5);
                            });
                    }}
                >
                    <FormItem label="이름" name="name">
                        <Input disabled={loading} placeholder="이름" />
                    </FormItem>
                    <FormItem label="설명" name="description">
                        <Input disabled={loading} placeholder="설명" />
                    </FormItem>
                    <FormItem label="사진">
                        <Upload
                            action={`${process.env.REACT_APP_REST_API_BASE_URL}/uploaded-image-sets/upload-image`}
                            beforeUpload={(file) => {
                                if (file.type === "image/png" || file.type === "image/jpeg") {
                                    return true;
                                } else {
                                    message.error("사진 형식의 파일을 업로드해주세요.", 1.5);
                                    return Upload.LIST_IGNORE;
                                }
                            }}
                            fileList={modalEditFiles}
                            headers={{ Authorization: `bearer ${token}` }}
                            listType="picture"
                            maxCount={1}
                            name="image"
                            onChange={({ fileList }) => {
                                setModalEditFiles(fileList);
                            }}
                            onRemove={(file) => {
                                const files = [...modalEditFiles];
                                const index = files.findIndex((f) => f.uid === file.uid);

                                if (index === -1) {
                                    return;
                                }

                                files.splice(index, 1);
                                setModalEditFiles(files);
                            }}
                        >
                            <Button icon={<UploadOutlined />}>사진 업로드</Button>
                        </Upload>
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}
