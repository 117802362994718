import React, { useCallback, useEffect, useState } from "react";
import { MenuOrderByColumn } from "@ferda/rest-api-client";
import { useDispatch, useSelector } from "react-redux";
import { GiftTabCategoryButton, GiftTabCategoryText, GiftTabCategoryWrapper, GiftTabContentWrapper } from "./style";
import ArrowDownIcon from "@/assets/img/ic_arrow_down.svg";
import CheckIcon from "@/assets/img/ic_check_yellow.svg";
import BottomSheet from "@/components/BottomSheet";
import CoffeeMenu from "@/components/CoffeeMenu";
import Grid from "@/components/Grid";
import { getMenuList, getMenuListFetchState, getMenuListPage, getMenuListTotalPage } from "@/redux/menu/selectors";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import { fetchMoreMenuListThunk } from "@/redux/menu/thunk";
import { resetMenuState } from "@/redux/menu/actions";
import PlaceHolderImage from "@/assets/img/place_holder.png";
import { reset } from "@/redux/giftLog/actions";

function formatMenuOrder(order: MenuOrderByColumn) {
    switch (order) {
        case MenuOrderByColumn.ORDER_PRIORITY_ASC: {
            return "추천순";
        }
        case MenuOrderByColumn.PRICE_ASC: {
            return "낮은 가격순";
        }
        case MenuOrderByColumn.PRICE_DESC: {
            return "높은 가격순";
        }
    }
}

export default function GiftMenu() {
    const dispatch = useDispatch();
    const menuListFetchState = useSelector(getMenuListFetchState);
    const menuList = useSelector(getMenuList);
    const menuListPage = useSelector(getMenuListPage);
    const menuListTotalPage = useSelector(getMenuListTotalPage);

    const [menuOrderSettingOpen, setMenuOrderSettingOpen] = useState<boolean>(false);
    const [menuOrder, setMenuOrder] = useState<MenuOrderByColumn>(MenuOrderByColumn.ORDER_PRIORITY_ASC);

    const toggleMenuOrderSettingOpen = (newValue: boolean) => () => setMenuOrderSettingOpen(newValue);

    const handleMenuOrderChange = (newOrder: MenuOrderByColumn) => () => {
        setMenuOrder(newOrder);
        setMenuOrderSettingOpen(false);
    };

    const handleFetchMore = useCallback(() => {
        dispatch(
            fetchMoreMenuListThunk({
                order: menuOrder,
            }),
        );
    }, [menuOrder]);

    useEffect(() => {
        dispatch(resetMenuState());
        dispatch(
            fetchMoreMenuListThunk({
                order: menuOrder,
            }),
        );

        return () => {
            dispatch(reset());
        };
    }, [menuOrder]);

    return (
        <>
            <BottomSheet open={menuOrderSettingOpen} onDismiss={toggleMenuOrderSettingOpen(false)} title="정렬">
                <GiftTabCategoryButton
                    selected={menuOrder === "ORDER_PRIORITY_ASC"}
                    onClick={handleMenuOrderChange(MenuOrderByColumn.ORDER_PRIORITY_ASC)}
                >
                    추천순 {menuOrder === "ORDER_PRIORITY_ASC" && <img src={CheckIcon} alt="check icon" />}
                </GiftTabCategoryButton>
                <GiftTabCategoryButton
                    selected={menuOrder === "PRICE_ASC"}
                    onClick={handleMenuOrderChange(MenuOrderByColumn.PRICE_ASC)}
                >
                    낮은 가격순 {menuOrder === "PRICE_ASC" && <img src={CheckIcon} alt="check icon" />}
                </GiftTabCategoryButton>
                <GiftTabCategoryButton
                    selected={menuOrder === "PRICE_DESC"}
                    onClick={handleMenuOrderChange(MenuOrderByColumn.PRICE_DESC)}
                >
                    높은 가격순 {menuOrder === "PRICE_DESC" && <img src={CheckIcon} alt="check icon" />}
                </GiftTabCategoryButton>
            </BottomSheet>
            <GiftTabCategoryWrapper>
                <GiftTabCategoryText onClick={toggleMenuOrderSettingOpen(true)}>
                    {formatMenuOrder(menuOrder)} <img src={ArrowDownIcon} alt="arrow down" />
                </GiftTabCategoryText>
            </GiftTabCategoryWrapper>

            <GiftTabContentWrapper>
                <Grid itemWidth={160} rowGap={38} columnGap={15}>
                    {[
                        <InfiniteScrollList
                            fetchState={menuListFetchState}
                            list={menuList}
                            page={menuListPage}
                            totalPage={menuListTotalPage}
                            disableInitialize
                            renderItem={(menu) => (
                                <CoffeeMenu
                                    href={`/gift/${menu.id}`}
                                    itemWidth={160}
                                    imageHeight={160}
                                    key={menu.id}
                                    price={menu.price}
                                    imageUrl={menu.uploaded_image_set?.original_image_url ?? PlaceHolderImage}
                                    id={menu.id}
                                    title={menu.name}
                                />
                            )}
                            onLoadMore={handleFetchMore}
                        />,
                    ]}
                </Grid>
            </GiftTabContentWrapper>
        </>
    );
}
