import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const DateHeader = styled.div`
    width: 100%;
    height: 42px;
    background-color: #d1d1d1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
`;

export const DateHeaderText = styled.p`
    color: #0c0c0c;
    font-size: 14px;
    margin: 0;
`;

export const DateHeaderButton = styled(Button)`
    font-size: 14px;
    color: #0c0c0c;

    > img {
        margin-left: 4px;
    }
`;
