import { FormikProps, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHistory from "@/hooks/useCustomHistory";
import {
    CodeInputErrorText,
    LoginButton,
    LoginInputButton,
    LoginInputEndAdornment,
    LoginInputItem,
    LoginInputTitle,
    LoginInputWrapper,
    LoginSuccessModalText,
    LoginWrapper,
} from "./style";
import ModalCheckImage from "@/assets/img/img_confirm_modal_check.svg";
import { StyledIconButton } from "@/components/IconButton";
import InputBase from "@/components/Input";
import Modal from "@/components/Modal";
import AppLayout from "@/containers/AppLayout";
import { useTimer } from "@/utils/hooks";
import { formatPhoneVerificationState, PhoneVerificationRequestStatusType } from "@/utils/phoneVerification";
import { phoneVerificationFetchRequestThunk, verifyPhoneVerificationCodeFetchRequestThunk } from "@/redux/login/thunk";
import { getVerifyPhoneVerificationCodeIsVerified } from "@/redux/login/selectors";
import BtnBackIcon from "@/assets/img/ic_btn_back.svg";
import { editPhoneNumberThunk } from "@/redux/user/thunk";
import { getEditPhoneNumberFetchState } from "@/redux/user/selectors";
import { resetUserState } from "@/redux/user/actions";
import { loadAuthStateAndApiClientThunk } from "@/redux/auth/thunk";

export interface ILoginForm {
    phoneNumber: string;
    verificationCode: string;
}

export default function EditPhoneNumber() {
    const dispatch = useDispatch();
    const history = useHistory();
    const timer = useTimer(3 * 60);
    const [requestStatus, setRequestStatus] = useState<PhoneVerificationRequestStatusType>("PREPARE");
    const [requestTime, setRequestTime] = useState<string | null>(null);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const verifyPhoneVerificationCodeIsVerified = useSelector(getVerifyPhoneVerificationCodeIsVerified);
    const editPhoneNumber = useSelector(getEditPhoneNumberFetchState);

    const resetState = () => {
        dispatch(resetUserState());
        setRequestStatus("PREPARE");
        setRequestTime(null);
        setSuccessModal(false);
        timer.stopTimer();
    };

    const handleSuccessModalClose = useCallback(() => {
        resetState();
        history.push("/me/edit");
        dispatch(loadAuthStateAndApiClientThunk());
    }, [history, resetState]);

    const handleClickBackButton = useCallback(() => {
        resetState();
        history.push("/me/edit");
    }, [history, resetState]);

    const { handleSubmit, getFieldProps, values }: FormikProps<ILoginForm> = useFormik<ILoginForm>({
        initialValues: {
            verificationCode: "",
            phoneNumber: "",
        },
        onSubmit: (args) => {
            dispatch(editPhoneNumberThunk(args));
        },
    });

    // 인증번호 전송
    const onClickRequest = useCallback(() => {
        timer.startTimer();
        setRequestStatus("IN_PROGRESS");
        dispatch(phoneVerificationFetchRequestThunk({ phoneNumber: values.phoneNumber }));
    }, [dispatch, timer]);

    // 인증번호 확인
    const onClickVerificationNumber = (): void => {
        const { phoneNumber, verificationCode } = values;

        dispatch(verifyPhoneVerificationCodeFetchRequestThunk({ phoneNumber, verificationCode }));
    };

    const getErrorCodeText = () => {
        if (requestStatus === "INVALID_AUTH_NUMBER") {
            return "인증번호가 올바르지 않습니다";
        }

        if (requestStatus === "TIME_OUT") {
            return "인증번호 확인 시간을 초과했습니다";
        }
    };

    // 인증번호 확인 Thunk
    useEffect(() => {
        if (verifyPhoneVerificationCodeIsVerified === null) {
            return;
        }
        if (!verifyPhoneVerificationCodeIsVerified) {
            setRequestStatus("INVALID_AUTH_NUMBER");
            return;
        }

        setRequestTime(null);
        timer.stopTimer();
        setRequestStatus("CONFIRMED");
    }, [dispatch, verifyPhoneVerificationCodeIsVerified]);

    // Timer
    useEffect(() => {
        const time = timer.timeDescription;

        if (time) {
            setRequestTime(time);
        }

        if (timer.timeLeft === 0) {
            setRequestStatus("TIME_OUT");
        }
    }, [timer.timeDescription]);

    useEffect(() => {
        if (editPhoneNumber !== "SUCCESS") {
            return;
        }

        setSuccessModal(true);
    }, [editPhoneNumber, history]);

    return (
        <AppLayout
            title="휴대폰번호 변경"
            appBarBackgroundColor="#ff831e"
            titleColor="#fff"
            disableAppBarBorder={true}
            leadingIcon={<StyledIconButton onClick={handleClickBackButton} src={BtnBackIcon} alt="back" />}
        >
            <Modal
                open={successModal}
                handleClose={() => {
                    //
                }}
                buttonOnClick={handleSuccessModalClose}
                topImage={ModalCheckImage}
                titleText="휴대폰번호 변경 완료!"
                titleMarginBottom={8}
                buttonText="확인"
                fullSizeButton
                imageHeight={90}
            >
                <LoginSuccessModalText>휴대폰번호 변경이 완료되었습니다</LoginSuccessModalText>
            </Modal>
            <LoginWrapper onSubmit={handleSubmit}>
                <LoginInputWrapper>
                    <LoginInputTitle>휴대폰번호 변경</LoginInputTitle>

                    <LoginInputItem>
                        <InputBase
                            type="number"
                            maxLength={11}
                            {...getFieldProps("phoneNumber")}
                            placeholder="휴대폰번호를 입력하세요"
                            readOnly={requestStatus !== "PREPARE"}
                        />
                        <LoginInputButton
                            onClick={onClickRequest}
                            disabled={values.phoneNumber.length < 11 || requestStatus === "CONFIRMED"}
                        >
                            {formatPhoneVerificationState(requestStatus)}
                        </LoginInputButton>
                    </LoginInputItem>
                    {["PREPARE"].indexOf(requestStatus) < 0 && (
                        <div>
                            <LoginInputItem>
                                <InputBase
                                    type="number"
                                    maxLength={6}
                                    {...getFieldProps("verificationCode")}
                                    placeholder="인증번호"
                                    error={requestStatus === "INVALID_AUTH_NUMBER" || requestStatus === "TIME_OUT"}
                                    endAdornment={<LoginInputEndAdornment>{requestTime}</LoginInputEndAdornment>}
                                />
                                <LoginInputButton
                                    onClick={onClickVerificationNumber}
                                    disabled={
                                        values.verificationCode.length < 6 ||
                                        requestStatus === "TIME_OUT" ||
                                        requestStatus === "CONFIRMED"
                                    }
                                >
                                    인증확인
                                </LoginInputButton>
                            </LoginInputItem>
                            <CodeInputErrorText>{getErrorCodeText()}</CodeInputErrorText>
                        </div>
                    )}
                </LoginInputWrapper>

                <LoginButton variant="contained" disabled={requestStatus !== "CONFIRMED"} type="submit">
                    휴대폰번호 변경
                </LoginButton>
            </LoginWrapper>
        </AppLayout>
    );
}
