import styled from "@emotion/styled";
import React, { useEffect, useMemo } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { MenuResponse } from "packages/ferda-rest-api-client/dist";
import { getRecommendedMenuList, getRecommendedMenuListFetchState } from "@/redux/menu/selectors";
import RecommendedMenuCard from "./RecommendedMenuCard";
import { fetchRecommendedMenuThunk } from "@/redux/menu/thunk";

const RecommendedMenuTitle = styled.div`
    margin-bottom: 20px;

    color: #0c0c0c;
    font-size: 18px;
    font-weight: bold;
`;

const RecommendedMenuContainer = styled.div`
    widdth: 100px;
`;

const StyledSlider = styled(Slider)`
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    overflow: hidden;
`;

const MenuGroupContainer = styled.div`
    width: 100%;
    display: flex !important;
    justify-content: space-around;
    > a {
        display: block;
    }
`;

interface RecommendedMenuProps {
    hidePrice?: boolean;
}

export default function RecommendedMenu({ hidePrice }: RecommendedMenuProps) {
    const dispatch = useDispatch();
    const menuList = useSelector(getRecommendedMenuList);
    const menuListFetchState = useSelector(getRecommendedMenuListFetchState);

    useEffect(() => {
        if (menuListFetchState !== "READY") {
            return;
        }

        dispatch(fetchRecommendedMenuThunk({}));
    }, [menuListFetchState]);

    const menuCardGroupList = useMemo(() => {
        if (!menuList) {
            return null;
        }

        return menuList
            .reduce<MenuResponse[][]>((prev, curr) => {
                const last = prev[prev.length - 1] ?? [];
                if (last.length === 2) {
                    return [...prev, [curr]];
                }
                return [...prev.slice(0, prev.length - 1), [...last, curr]];
            }, [])
            .map((menuGroup, i) => (
                <MenuGroupContainer key={i}>
                    {menuGroup.map((menu, j) => (
                        <RecommendedMenuCard key={j} hidePrice={hidePrice} menu={menu} />
                    ))}
                </MenuGroupContainer>
            ));
    }, [menuList]);

    return (
        <StyledSlider infinite={false} arrows={false} slidesToShow={1}>
            {menuCardGroupList}
        </StyledSlider>
    );
}

interface RecommendedMenuProps {
    hidePrice?: boolean;
}

export function RecommendedMenuWithTitle({ hidePrice }: RecommendedMenuProps) {
    return (
        <RecommendedMenuContainer>
            <RecommendedMenuTitle>추천 메뉴</RecommendedMenuTitle>
            <RecommendedMenu hidePrice={hidePrice} />
        </RecommendedMenuContainer>
    );
}
