import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { message } from "antd";
import AdminLogin from "@/components/Admin/Login";
import { adminAuthenticateThunk } from "@/redux/admin/thunk";
import { getAdminType, getAdminTypeFetchState } from "@/redux/admin/selectors";

interface Props {}

export default function Page(props: Props) {
    const history = useHistory();

    const dispatch = useDispatch();

    const adminType = useSelector(getAdminType);
    const adminFetchState = useSelector(getAdminTypeFetchState);

    React.useEffect(() => {
        switch (adminType) {
            case "UNLOADED":
                return;
            case "AUTHORIZED": {
                history.push("/admin/users");
                break;
            }
            case "UNAUTHORIZED": {
                switch (adminFetchState) {
                    case "FAILURE": {
                        message.error("계정이 존재하지 않습니다.");
                        return;
                    }
                    case "SUCCESS": {
                        message.error("권한이 없습니다.");
                        return;
                    }
                    default: {
                        message.error("알 수 없는 에러.");
                    }
                }
                break;
            }
        }
    }, [adminType]);

    return (
        <AdminLogin
            onSubmit={(loginData) => {
                dispatch(
                    adminAuthenticateThunk({
                        username: loginData.username,
                        password: loginData.password,
                    }),
                );
            }}
        />
    );
}
