import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import { Radio } from "@mui/material";
import React from "react";
import { ko } from "date-fns/locale";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Global } from "@emotion/react";
import Modal from "../Modal";
import { DateTextField } from "./component";
import {
    DatePickerModalTooltip,
    DatePickerModalWrapper,
    DatePickerRadioButton,
    DatePickerRadioGroup,
    GlobalStyle,
} from "./style";

interface DatePickerProps {
    open: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
    startDate: Date | null;
    endDate: Date | null;
    dateCategory?: string;
    handleDateCategoryChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
    handleStartDateChange: (value: Date | null) => void;
    handleEndDateChange: (value: Date | null) => void;
    tooltip?: string;
    maxDateFromToday?: {
        year?: number;
        month?: number;
        date?: number;
        hour?: number;
        minutes?: number;
    };
    minDate?: Date;
    maxDate?: Date;
    disabledRadio?: boolean;
}

export default function DatePicker({
    open,
    handleClose,
    handleSubmit,
    startDate,
    endDate,
    dateCategory,
    handleDateCategoryChange,
    handleStartDateChange,
    handleEndDateChange,
    tooltip,
    minDate,
    maxDate,
    disabledRadio,
}: DatePickerProps) {
    return (
        <Modal
            open={open}
            titleText="기간 설정"
            titleMarginBottom={32}
            handleClose={handleClose}
            buttonText="조회"
            buttonOnClick={handleSubmit}
            fullSizeButton
            showCloseButton
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                <Global styles={GlobalStyle} />
                <DatePickerModalWrapper>
                    <MobileDatePicker
                        value={startDate}
                        label="시작일"
                        disabled={!disabledRadio && dateCategory !== "custom"}
                        onChange={handleStartDateChange}
                        inputFormat="yyyy.MM.dd"
                        renderInput={(params) => <DateTextField marginBottom={8} {...params} />}
                        minDate={minDate}
                        maxDate={endDate as Date}
                        mask="____.__.__"
                        toolbarFormat="yyyy.MM.dd"
                        cancelText="취소"
                        okText="확인"
                    />
                    <MobileDatePicker
                        value={endDate}
                        label="종료일"
                        disabled={!disabledRadio && dateCategory !== "custom"}
                        onChange={handleEndDateChange}
                        inputFormat="yyyy.MM.dd"
                        renderInput={(params) => <DateTextField marginBottom={24} {...params} />}
                        minDate={startDate as Date}
                        maxDate={maxDate}
                        mask="____.__.__"
                        toolbarFormat="yyyy.MM.dd"
                        cancelText="취소"
                        okText="확인"
                    />
                    {!disabledRadio && (
                        <DatePickerRadioGroup value={dateCategory} onChange={handleDateCategoryChange} row>
                            <DatePickerRadioButton value="month" control={<Radio />} label="1개월" />
                            <DatePickerRadioButton value="year" control={<Radio />} label="1년" />
                            <DatePickerRadioButton value="custom" control={<Radio />} label="기간설정" />
                        </DatePickerRadioGroup>
                    )}
                    {tooltip && <DatePickerModalTooltip>{tooltip}</DatePickerModalTooltip>}
                </DatePickerModalWrapper>
            </LocalizationProvider>
        </Modal>
    );
}
