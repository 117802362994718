import React from "react";
import moment from "moment";
import "moment/locale/ko";
import { Button, message } from "antd";
import { ServiceCancelReasonResponse } from "@ferda/rest-api-client";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { getRestApiClient } from "@/redux/auth/selectors";
import { serviceCancelReasonTypeToText } from "@/utils/enumToText";

import PostTitle from "@/components/Admin/PageTitle";
import Container from "@/components/Admin/Container";
import Table from "@/components/Admin/Table";
import Pagination from "@/components/Admin/Pagination";
import { TotalCountWrapper } from "@/components/Admin/Misc";

interface Props {}

export default function Page(props: Props) {
    const history = useHistory();

    const { serviceApi } = useSelector(getRestApiClient);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [deletedList, setDeletedList] = React.useState<ServiceCancelReasonResponse[]>([]);
    const [deletedListTotal, setDeletedListTotal] = React.useState<number>(0);

    const [page, setPage] = React.useState<number>(1);

    const columns = [
        {
            title: "탈퇴일자",
            key: "deletedDatetime",
            render: (record: ServiceCancelReasonResponse) => {
                const time = moment(record.created_datetime);
                time.locale("ko-kr");
                return {
                    children: (
                        <>
                            {time.format("YY. MM. DD. (dd)")}
                            <br />
                            {time.format("A hh:mm:ss")}
                        </>
                    ),
                };
            },
            width: "120px",
        },
        {
            title: "이름",
            key: "name",
            render: (record: ServiceCancelReasonResponse) => {
                return {
                    children: record.user.detail?.name,
                };
            },
            width: "100px",
        },
        {
            title: "전화번호",
            key: "phone",
            render: (record: ServiceCancelReasonResponse) => {
                return {
                    children: record.user.detail?.phone_number,
                };
            },
            width: "125px",
        },
        {
            title: "종류",
            key: "type",
            render: (record: ServiceCancelReasonResponse) => {
                return {
                    children: serviceCancelReasonTypeToText(record.service_cancel_reason_type),
                };
            },
            width: "80px",
        },
        {
            title: "사유",
            key: "reason",
            render: (record: ServiceCancelReasonResponse) => {
                return {
                    children: record.reason.split("\n").map((item) => <p>{item}</p>),
                };
            },
        },
        {
            title: "관리",
            key: "manage",
            render: (record: ServiceCancelReasonResponse) => {
                return {
                    children: (
                        <Button
                            onClick={() => {
                                history.push(`/admin/users?name=${record.user.detail?.name}`);
                            }}
                        >
                            회원 보기
                        </Button>
                    ),
                };
            },
            width: "120px",
        },
    ];

    React.useEffect(() => {
        setLoading(true);
        serviceApi
            .getServiceCancelReasons(page)
            .then((res) => {
                setLoading(false);
                setDeletedList(res.data);
                setDeletedListTotal(parseInt(res.headers["x-total-count"], 10));
            })
            .catch(() => {
                setLoading(false);
                message.error("탈퇴 사유 목록을 불러오는데 실패하였습니다.");
            });
    }, [page]);

    return (
        <>
            <PostTitle>탈퇴 사유</PostTitle>
            <Container>
                <TotalCountWrapper>탈퇴 회원 수 : {deletedListTotal.toLocaleString("ko")}</TotalCountWrapper>
                <Table columns={columns} dataSource={deletedList} loading={loading} rowKey="id" />
                {deletedListTotal > 0 && (
                    <Pagination
                        current={page}
                        disabled={loading}
                        onChange={(page: number) => setPage(page)}
                        pageSize={20}
                        total={deletedListTotal}
                    />
                )}
            </Container>
        </>
    );
}
