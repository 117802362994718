import { Modal as MuiModal, ModalProps as MuiModalProps, Backdrop, Fade } from "@mui/material";
import React from "react";
import {
    ModalButton,
    ModalButtonContainer,
    ModalCloseButton,
    ModalContent,
    ModalFullButtonContainer,
    ModalFullsizeButton,
    ModalTitleText,
    ModalTopImage,
    ModalWrapper,
} from "./style";
import CloseIcon from "@/assets/img/ic_btn_close.svg";

interface ModalProps extends MuiModalProps {
    topImage?: string;
    titleText?: string | React.ReactNode;
    titleMarginBottom?: number;
    showCloseButton?: boolean;
    handleClose: () => void;
    fullSizeButton?: boolean;
    buttonText?: string;
    cancelButtonText?: string;
    buttonOnClick?: () => void;
    cancelButtonOnClick?: () => void;
    fullSize?: boolean;
    fade?: boolean;
    imageHeight?: number;
    wrapperPadding?: string;
}

export default function Modal({
    open,
    handleClose,
    showCloseButton,
    topImage,
    titleText,
    titleMarginBottom,
    fullSizeButton,
    buttonText,
    cancelButtonText,
    buttonOnClick = () => false,
    cancelButtonOnClick = () => false,
    fullSize,
    fade,
    imageHeight,
    children,
    wrapperPadding = "34px 16px 0 16px",
    ...args
}: ModalProps) {
    return (
        <MuiModal
            {...args}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            hideBackdrop={fullSize}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            tabIndex={0}
        >
            {fade ? (
                <Fade in={open}>
                    <ModalWrapper fullSize={fullSize}>
                        {showCloseButton && (
                            <ModalCloseButton onClick={handleClose}>
                                <img src={CloseIcon} alt="close icon" />
                            </ModalCloseButton>
                        )}
                        <ModalContent fullSize={fullSize} padding={wrapperPadding}>
                            {topImage && (
                                <ModalTopImage imageHeight={imageHeight}>
                                    <img src={topImage} alt="modal icon" />
                                </ModalTopImage>
                            )}
                            {titleText && <ModalTitleText marginBottom={titleMarginBottom}>{titleText}</ModalTitleText>}
                            {children}
                        </ModalContent>

                        {!fullSizeButton && (
                            <ModalButtonContainer>
                                {buttonText && <ModalButton onClick={buttonOnClick}>{buttonText}</ModalButton>}
                                {cancelButtonText && (
                                    <ModalButton onClick={cancelButtonOnClick} cancel>
                                        {cancelButtonText}
                                    </ModalButton>
                                )}
                            </ModalButtonContainer>
                        )}
                        {fullSizeButton && (
                            <ModalFullButtonContainer>
                                {cancelButtonText && (
                                    <ModalFullsizeButton onClick={cancelButtonOnClick} cancel>
                                        {cancelButtonText}
                                    </ModalFullsizeButton>
                                )}
                                {buttonText && (
                                    <ModalFullsizeButton onClick={buttonOnClick}>{buttonText}</ModalFullsizeButton>
                                )}
                            </ModalFullButtonContainer>
                        )}
                    </ModalWrapper>
                </Fade>
            ) : (
                <ModalWrapper fullSize={fullSize}>
                    {showCloseButton && (
                        <ModalCloseButton onClick={handleClose}>
                            <img src={CloseIcon} alt="close icon" />
                        </ModalCloseButton>
                    )}
                    <ModalContent fullSize={fullSize} padding={wrapperPadding}>
                        {topImage && (
                            <ModalTopImage imageHeight={imageHeight}>
                                <img src={topImage} alt="modal icon" />
                            </ModalTopImage>
                        )}
                        {titleText && <ModalTitleText marginBottom={titleMarginBottom}>{titleText}</ModalTitleText>}
                        {children}
                    </ModalContent>

                    {!fullSizeButton && (
                        <ModalButtonContainer>
                            {buttonText && <ModalButton onClick={buttonOnClick}>{buttonText}</ModalButton>}
                            {cancelButtonText && (
                                <ModalButton onClick={cancelButtonOnClick} cancel={true}>
                                    {cancelButtonText}
                                </ModalButton>
                            )}
                        </ModalButtonContainer>
                    )}
                    {fullSizeButton && (
                        <ModalFullButtonContainer>
                            {cancelButtonText && (
                                <ModalFullsizeButton onClick={cancelButtonOnClick} cancel>
                                    {cancelButtonText}
                                </ModalFullsizeButton>
                            )}
                            {buttonText && (
                                <ModalFullsizeButton onClick={buttonOnClick}>{buttonText}</ModalFullsizeButton>
                            )}
                        </ModalFullButtonContainer>
                    )}
                </ModalWrapper>
            )}
        </MuiModal>
    );
}
