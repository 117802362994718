import React from "react";
import styled from "@emotion/styled";

import { Form } from "antd";

const Container = styled.div`
    position: relative;
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }
`;

const Label = styled.div`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
`;

const HintContainer = styled.ul`
    margin: 0.5rem 0;
    padding-left: 1rem;

    font-size: 0.75rem;
    color: #444444;
`;

const RightContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const Hint = styled.li``;

interface Props {
    children?: React.ReactNode;
    hints?: string[];
    label?: string;
    name?: string;
    right?: React.ReactNode;
    style?: React.CSSProperties;
}

export default function Component(props: Props): React.ReactElement {
    return (
        <Container style={props.style}>
            {props.label && <Label>{props.label}</Label>}
            {props.right && <RightContainer>{props.right}</RightContainer>}
            <Form.Item name={props.name} style={{ marginBottom: 0 }}>
                {props.children}
            </Form.Item>
            {props.hints && props.hints.length > 0 && (
                <HintContainer>
                    {props.hints.map((hint, hintIndex) => (
                        <Hint key={hintIndex}>{hint}</Hint>
                    ))}
                </HintContainer>
            )}
        </Container>
    );
}
