import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getOauth2TokenFromLocalStorage } from "@/redux/auth/utils";

class UploadAdapter {
    private loader: any;
    private xhr: XMLHttpRequest | null;

    public constructor(loader: any) {
        this.loader = loader;
        this.xhr = null;
    }

    public upload() {
        return this.loader.file.then(
            (file: any) =>
                new Promise((resolve, reject) => {
                    this.initRequest();
                    this.initListeners(resolve, reject, file);
                    this.sendRequest(file);
                }),
        );
    }

    public abort() {
        if (this.xhr !== null) {
            this.xhr.abort();
        }
    }

    private initRequest() {
        this.xhr = new XMLHttpRequest();
        this.xhr.open(
            "POST",
            `${process.env.REACT_APP_REST_API_BASE_URL!}/uploaded-image-sets/upload-image-from-ckeditor5`,
            true,
        );
        this.xhr.responseType = "json";
    }

    private initListeners(resolve: any, reject: any, file: File) {
        if (this.xhr === null) {
            return reject("파일 업로드 기능이 준비되지 않았습니다.");
        }

        const genericErrorMessage = `파일 업로드를 실패하였습니다. (${file.name})`;

        this.xhr.addEventListener("error", () => reject(genericErrorMessage));
        this.xhr.addEventListener("abort", () => reject());
        this.xhr.addEventListener("load", () => {
            if (this.xhr === null) {
                return reject("파일 업로드 기능이 업로드 중 연결이 끊겼습니다.");
            }

            if (!this.xhr.response || this.xhr.response.error) {
                return reject(
                    this.xhr.response && this.xhr.response.error
                        ? this.xhr.response.error.message
                        : genericErrorMessage,
                );
            }

            return resolve({
                default: this.xhr.response.url,
            });
        });

        if (this.xhr.upload) {
            this.xhr.addEventListener("progress", (event: any) => {
                if (event.lengthComputable) {
                    this.loader.uploadTotal = event.total;
                    this.loader.uploaded = event.loaded;
                }
            });
        }

        const token = getOauth2TokenFromLocalStorage();
        if (!token) {
            return reject("권한이 없습니다.");
        }
        this.xhr.setRequestHeader("Authorization", `bearer ${token.token}`);
    }

    private sendRequest(file: string | Blob) {
        const data = new FormData();

        data.append("file", file);

        this.xhr!.send(data);
    }
}

interface Props {
    data?: string;
    onChange: (event: any, editor: any) => void;
    onReady?: (editor: any) => void;
}

export default function Component(props: Props) {
    return (
        <CKEditor
            data={props.data}
            editor={ClassicEditor}
            onChange={(event, editor) => props.onChange(event, editor)}
            onReady={(editor) => {
                editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
                    return new UploadAdapter(loader);
                };
                if (props.onReady) {
                    props.onReady(editor);
                }
            }}
        />
    );
}
